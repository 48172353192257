import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  CarFront,
  Sunglasses,
  Dropbox,
  TrainFront,
  Clock,
} from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { indexClosest, Utils } from '../../helpers';
import { getAllDevicesData } from '../../store/reducers/AccountReducer';
import ImageFetch from '../cameras/components/ImageFetch';
import timezones from '../../data/support/timezone.json';
import { useOrganizations } from '../../store/OrganizationsStore';
import { ReactComponent as CarEventDuclo } from '../../assets/images/CarEventDuclo.svg';
import { ReactComponent as CarEventMegatron } from '../../assets/images/CarEventMegatron.svg';
import { ReactComponent as FaceEventDuclo } from '../../assets/images/FaceEventDuclo.svg';
import { ReactComponent as FaceEventMegatron } from '../../assets/images/FaceEventMegatron.svg';
import { ReactComponent as PeopleEventDuclo } from '../../assets/images/PeopleEventDuclo.svg';
import { ReactComponent as PeopleEventMegatron } from '../../assets/images/PeopleEventMegatron.svg';
import { ReactComponent as LicensePlateDuclo } from '../../assets/images/LicensePlateDuclo.svg';
import { ReactComponent as LicensePlateMegatron } from '../../assets/images/LicensePlateMegatron.svg';
import { ReactComponent as AudioDuclo } from '../../assets/images/AudioDuclo.svg';
import { ReactComponent as AudioMegatron } from '../../assets/images/AudioMegatron.svg';
import { ReactComponent as MotionDuclo } from '../../assets/images/MotionDuclo.svg';
import { ReactComponent as MotionMegatron } from '../../assets/images/MotionMegatron.svg';
import { AppDefaults } from './../../helpers';

const EventsWrapper = ({
  data,
  dataForEvents,
  cdnValue,
  timezone,
  selectedEvent,
  handleOnClick,
  handleNextPreviousIcon,
}) => {
  const [eventData, setEventData] = useState([]);
  const allDevices = useSelector(getAllDevicesData);
  const getCustomerOrgData = useOrganizations(
    (state) => state.getCustomerOrgData
  );
  let custOrg = getCustomerOrgData();
  const custOrgData = custOrg[0];
  const [indexEvent, setIndexEvent] = useState(null);

  const fetchIcon = (obj) => {
    if(process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON){
      switch (obj) {
        case 'person':
        case 'top':
        case 'hat':
        case 'bottom':
          return (
            <div className="event-image">
              <PeopleEventMegatron height="36" width="36" />
            </div>
          ); 
        case 'face':
          return (
            <div className="event-image">
              <FaceEventMegatron height="36" width="36" />
            </div>
          );      
        case 'car':
        case 'vehicle':
        case 'truck':
        case 'motorcycle':
        case 'bicycle':
          return (
            <div className="event-image">
              <CarEventMegatron height="36" width="36" />
            </div>
          );
        case 'glasses':
          return (
            <div className="event-image">
              <Sunglasses height="36" width="36" />
            </div>
          );
        case 'train':
          return (
            <div className="event-image">
              <TrainFront height="36" width="36" />
            </div>
          );
        case 'licenseplate':
          return (
            <div className="event-image">
              <LicensePlateMegatron height="36" width="36" />
            </div>
          );
        case 'sound':
        case 'glass-breaking':
        case 'explosion':
        case 'scream':
        case 'gun-shot':
          return (
            <div className="event-image">
              <AudioMegatron height="36" width="36" />
            </div>
          );
        case 'motion':
        case 'motion-start':
        case 'motion-end':
          return (
            <div className="event-image">
              <MotionMegatron height="36" width="36" />
            </div>
          );
          
        default:
          return (
            <div className="event-image">
              <Dropbox height="36" width="36" />
            </div>
          );
      }
    }else{
      switch (obj) {
        case 'person':
        case 'top':
        case 'hat':
        case 'bottom':
          return (
            <div className="event-image">
              <PeopleEventDuclo height="36" width="36" />
            </div>
          ); 
        case 'face':
          return (
            <div className="event-image">
              <FaceEventDuclo height="36" width="36" />
            </div>
          );      
        case 'car':
        case 'vehicle':
        case 'truck':
        case 'motorcycle':
        case 'bicycle':
          return (
            <div className="event-image">
              <CarEventDuclo height="36" width="36" />
            </div>
          );
        case 'glasses':
          return (
            <div className="event-image">
              <Sunglasses height="36" width="36" />
            </div>
          );
        case 'train':
          return (
            <div className="event-image">
              <TrainFront height="36" width="36" />
            </div>
          );
        case 'licenseplate':
          return (
            <div className="event-image">
              <LicensePlateDuclo height="36" width="36" />
            </div>
          );
          case 'sound':
          case 'glass-breaking':
          case 'explosion':
          case 'scream':
          case 'gun-shot':
          return (
            <div className="event-image">
              <AudioDuclo height="36" width="36" />
            </div>
          );
        case 'motion':
        case 'motion-start':
        case 'motion-end':
          return (
            <div className="event-image">
              <MotionDuclo height="36" width="36" />
            </div>
          );
          
        default:
          return (
            <div className="event-image">
              <Dropbox height="36" width="36" />
            </div>
          );
      }
    }
   
  };

  const fetchDeviceInfo = (deviceId) => {
    const info = {
      deviceName: null,
      locationName: null,
      areaName: null,
      deviceId: deviceId,
      timezone: moment.tz.guess(),
    };
    if (allDevices && allDevices.length) {
      const device = allDevices?.filter((dev) => dev.deviceId === deviceId);
      const deviceTimeZone = custOrgData?.devices?.find((dev) => dev?.deviceId === device[0]?.parentId);
      if (device && device?.length) {

        info.deviceName = device[0]?.deviceName;
        info.locationName = device[0]?.locationName;
        info.timezone = deviceTimeZone?.properties?.timezone;
        const area = device[0]?.areas?.filter(
          (item) => item.isDefault === true
        );
        if (area && area.length) {
          info.areaName = area[0]?.areaName;
        }
      }
    }
    return [info];
  };

  const getLocationTimezone = (timezone) => {
    const timezoneLocation = timezones?.data?.find(
      (zone) => zone.value === timezone
    );
    return timezoneLocation?.location;
  };

  const displayDateOnWrapper = (timestamp, locationTimeZone) => {    
    const date = new Date(timestamp);
    const location = getLocationTimezone(locationTimeZone);
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
      timeZone: location,
      timeZoneName: 'short',
    };    
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    return formattedDate;
  }

  useEffect(() => {
    let result = eventData?.map((a, i) => i);
    const data = indexClosest(result, indexEvent);
    if (data) {
      const nextIndex = data?.dataAfter[0]?.date;
      const previousIndex = data?.dataBefore[0]?.date;
      if (handleNextPreviousIcon === 'next') {
        setIndexEvent(nextIndex);
        handleOnClick(eventData[nextIndex], nextIndex);
      }
      if (handleNextPreviousIcon === 'previous') {
        setIndexEvent(previousIndex);
        handleOnClick(eventData[previousIndex], previousIndex);
      }
    }
  }, [handleNextPreviousIcon, JSON.stringify(eventData)]);

  useEffect(() => {
    setEventDataFillter(data)
  }, [data]);

  const setEventDataFillter = async (data) => {
    let allDataEvents = [];

    data?.events?.map((insideEvent) => {
      insideEvent?.eventMeta?.events?.map((actualEvent) => {
        const eDataEvents = {
          actualEventData: actualEvent?.event,
          timestamp: insideEvent?.eventTimestamp,
          device: fetchDeviceInfo(insideEvent.deviceId),
        };
        allDataEvents.push(eDataEvents);
      });
    });
    const listData = allDataEvents?.map((itemEvent) => {
      let deviceIdsString = '';
      itemEvent?.actualEventData?.map((actualEventDataEvent, index) => {
        deviceIdsString += `${actualEventDataEvent.eventClass}${
          itemEvent?.actualEventData?.length !== index + 1 ? ', ' : ''
        }`;
      });
      return { ...itemEvent, class: `${deviceIdsString}` };
    });
    let finalList = await Promise.all(listData);
     setEventData(finalList);
  };

  return (
    <div className="row">
      {eventData?.map((event, i) => {
         if (event) {
          return (
            <div
              key={i}
              className={`Col col-md-4 ${
                selectedEvent === event.timestamp ||
                selectedEvent ===
                  Utils.getUnixDate(Utils.getDate(event.timestamp / 1000)) *
                    1000
                  ? 'selectedEvent'
                  : ''
              } event-wrapper-list`}
              onClick={() => {
                setIndexEvent(i);
                handleOnClick(event, i);
              }}
            >
              <div className="event-list-image-area">
                <ImageFetch
                  time={event?.timestamp}
                  cdnValue={cdnValue}
                  deviceId={event?.device[0]?.deviceId}
                />
                <div className="event-image-time">
                  <Clock height="12" width="12" />
                  {displayDateOnWrapper(event?.timestamp, event?.device[0]?.timezone)}
                </div>
              </div>
              <div className="event-list-content">
                {fetchIcon(Utils.getObjectClassEventsValue(event.class))}
                <div className="event-content-list-wrapper">
                  <div className="event-name">{event.device[0].deviceName}</div>
                  <div className="event-timestamp">
                  {`${event.device[0].areaName}`} 
                    <span className="icon-separator"></span> 
                  {`${event.device[0].locationName}`}
                  </div>
                </div>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default EventsWrapper;
