import { useCallback, useLayoutEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment/moment';
import { isObject } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { constants, Utils } from '../../helpers';
import { CommonBlock, ProgressBar, SiteSpinner } from '../../components/common';
import { Section, ThreeColumnLayout } from './components/';

import './RetailPerformance.scss';
import { useCustomerOrgDevices } from '../../store/CustomerOrgDevicesStore';

const RetailPerformance = ({ custOrgData, devices }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [totalCapacity, setTotalCapacity] = useState(0);
  const [retailDate, setRetailDate] = useState(
    constants.ANALYTICS_DASHBOARD_DATE_TEXT
  );
  const { getSelectedDeviceFromFillter } = useCustomerOrgDevices();
  const [retailList, setRetailList] = useState([]);
  const linkHandler = () => {
    console.log('linkHandler clicked!');
  };

  const getDeviceIdsString = (devicList) => {
    const deviceSelected = getSelectedDeviceFromFillter();
    let deviceIdsString = '';

    devicList?.forEach((device, index) => {
      if (deviceSelected?.length !== 0) {
        const deviIdExist = deviceSelected?.find(
          (item) => item === device?.deviceId
        );
        if (deviIdExist) {
          deviceIdsString += `${device?.deviceId},`;
        }
      } else {
        deviceIdsString += `${device?.deviceId},`;
      }
    });
    return deviceIdsString?.substring(0, deviceIdsString.length - 1);
  };

  const getDeviceDetails = (deviceId) => {
    let locationAreaName = '--';
    const devicesList = custOrgData?.devices;
    const deviceData = devicesList?.find(
      (device) => device?.deviceId === deviceId
    );

    if (deviceData) {
      const locationsList = custOrgData?.locations;
      const location = locationsList?.find(
        (location) => location?.locationId === deviceData?.locationId
      );
      if (location) {
        locationAreaName = `${location?.locationName}`;
      }
    }
    return locationAreaName;
  };

  const topLinks = (
    <div className="d-flex justify-content-between">
      <span
        className="view-details-link"
        onClick={() => {
          linkHandler();
        }}
      >
        {constants.ANALYTICS_DASHBOARD_ALL_CATEGORIES_LABEL}
      </span>
    </div>
  );

  useLayoutEffect(() => {
    retailApiCall(devices);
  }, [devices]);

  const retailApiCall = useCallback(async (devicesList) => {
    if (devicesList?.length === 0){
      setTotalCount(0);
      setTotalCapacity(0);
      setRetailList([]);
      return;
    }

    setShowLoader(true);
    const requestId = uuidv4();

    let response = await axios.get(
      `metrics/orgs/${custOrgData?.orgId}/latest/device-queue`,
      {
        params: {
          orgId: custOrgData?.orgId,
          deviceIds: getDeviceIdsString(devicesList),
          requestTime: Date.now(),
          requestId: requestId,
        },
        ...Utils.requestHeader(requestId),
      }
    );
    const responseData = response?.data;
    if (responseData?.meta?.code === 200) {
      setShowLoader(false);
      let count = 0;
      let capacity = 0;
      let dateTimeStamp = 0;
      const retailListData = responseData?.data?.static;
      retailListData?.map((data, i) => {
        count = count + data.count;
        capacity = capacity + data.capacity;
        if (dateTimeStamp < data.timestamp) {
          dateTimeStamp = data.timestamp;
        }
        retailListData[i].dpercentage = Math.round(
          (data.count / data.capacity) * 100
        );
      });
      if (dateTimeStamp != 0) {
        let date = new Date(dateTimeStamp);
        setRetailDate(date);
      } else {
        setRetailDate(constants.ANALYTICS_DASHBOARD_DATE_TEXT);
      }
      setTotalCount(count);
      setTotalCapacity(capacity);
      setRetailList(retailListData);
    } else {
      setShowLoader(false);
      // setRetailData(false);
    }
  }, []);

  const queuesNearingCapacityContent = (retailList) => {
    let queueList = retailList
      ?.filter(
        (retail) => retail?.dpercentage >= 60 && retail?.dpercentage < 100
      )
      .sort((a, b) => b.dpercentage - a.dpercentage)
      ?.slice(0, 3);

    return (
      <>
        {queueList.length > 0 ? (
          queueList.map((listItem, listItemIndex) => (
            <div
              key={`list-item-${listItemIndex}`}
              className="container-que-labels"
            >
              <div className="label-container">
                <div className="que-left-label">
                  {constants.ANALYTICS_DASHBOARD_QUEUES_LEFT}
                </div>
                <div className="location-text">
                  {getDeviceDetails(listItem.deviceId)}
                </div>
              </div>
              <div className="percentage-container">
                {listItem.dpercentage}%{' '}
              </div>
            </div>
          ))
        ) : (
          <div className="no-queue-data">
            {
              constants.ANALYTICS_DASHBOARD_RETAIL_PERFORMANCE_NO_QUEUES_NEARING_CAPACITY
            }
          </div>
        )}
      </>
    );
  };

  const queueAtOrOverCapacityContent = (retailList) => {
    let queueList = retailList
      ?.filter((retail) => retail?.dpercentage >= 100)
      .sort((a, b) => b.dpercentage - a.dpercentage)
      ?.slice(0, 3);

    return (
      <>
        {queueList.length > 0 ? (
          queueList.map((listItem, listItemIndex) => (
            <div key={listItemIndex} className="container-que-labels">
              <div className="label-container">
                <div className="que-left-label">
                  {constants.ANALYTICS_DASHBOARD_QUEUES_OVER_LABEL}
                </div>
                <div className="location-text">
                  {getDeviceDetails(listItem.deviceId)}
                </div>
              </div>
              <div className="percentage-container gain">
                {listItem.dpercentage}%
              </div>
            </div>
          ))
        ) : (
          <div className="no-queue-data">
            {
              constants.ANALYTICS_DASHBOARD_RETAIL_PERFORMANCE_NO_QUEUES_AT_OR_OVER_CAPACITY
            }
          </div>
        )}
      </>
    );
  };

  return (
    <Section
      bgColor={`light-blue-bg ${retailList?.length <= 0 && 'no-data'}`}
      analyticsTitle={
        constants.ANALYTICS_DASHBOARD_RETAIL_PERFORMANCE_SECTION_TITLE
      }
      analyticsDescription={`${
        retailList?.length > 0
          ? constants.ANALYTICS_DASHBOARD_RETAIL_PERFORMANCE_SECTION_DESCRIPTION
          : ''
      } ${retailDate}`}
      topRightLinks={topLinks}

    >
      {/* Pass appropriate props */}

      <div className="retail-container">
        {showLoader ? (
          <div className="no-data-available">
            <SiteSpinner height="50px" width="50px"></SiteSpinner>
          </div>
        ) : retailList.length > 0 ? (
          <div className="row scroll-container">
            <div className="Col col-md-4">
              <div className="card-layout">
                <div className="first-card-container">
                  <div className="que-header">
                    <div className="que-count">
                      {constants.ANALYTICS_DASHBOARD_QUEUE_COUNT}
                    </div>
                    <div className="compare-btn">
                      {constants.ANALYTICS_DASHBOARD_COMPARE}
                    </div>
                  </div>
                  <div className="count-graph-container">
                    <div className="left-container">
                      <div className="que-count">{`${totalCount} / ${totalCapacity}`}</div>
                      <div className="capacity">
                        {Math.round((totalCount / totalCapacity) * 100)}
                        {constants.ANALYTICS_DASHBOARD_PERCENT_CAPACITY}
                      </div>
                    </div>
                    <div className="right-container">
                      <div className="progress-bar-events">
                        <CommonBlock
                          width={70}
                          height={70}
                          blockType="error"
                          backgroundColor="transparent"
                          contentWrapperClass="text-start"
                          icon={
                            <ProgressBar
                              maxValue={totalCapacity}
                              strokeWidth={25}
                              width="90px"
                              trailStroke={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--primary_96')}
                              background={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--primary_48')}
                              percentage={totalCount}
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Col col-md-4">
              <div className="card-layout">
                <div className={`second-card-container`}>
                  <div className={`nearing-capacity-label`}>
                    {constants.ANALYTICS_DASHBOARD_QUEUES_NEARING}
                  </div>
                  {queuesNearingCapacityContent(retailList)}
                </div>
              </div>
            </div>
            <div className="Col col-md-4">
              <div className="card-layout">
                <div className={`second-card-container`}>
                  <div className={`nearing-capacity-label`}>
                    {constants.ANALYTICS_DASHBOARD_QUEUES_OVER}
                  </div>
                  {queueAtOrOverCapacityContent(retailList)}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="no-data-available">
            {constants.ANALYTICS_DASHBOARD_NO_DATA_AVAILABLE_TEXT}
          </div>
        )}
      </div>
    </Section>
  );
};

export default RetailPerformance;
