import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// TODO: See line 344 below.  Only delete this import if it will never be used at all
// import reportWebVitals from './reportWebVitals';
import megatron from './assets/css/megatron.scss';
import PrivateRoute from './auth/PrivateRoute';
import SignIn from './auth/SignIn';
import AccountVerify from './auth/AccountVerify';
import PageNotFound from './pages/PageNotFound';
import { withTranslation } from 'react-i18next';
import { AnalyticsDashboard } from './pages/analytics';
import { ClipDetails, Clips, ClipsList } from './pages/clips';
import { AccessClip, LinkExpired } from './pages/external/';
import { Incidents, IncidentDetails, ManageIncidents } from './pages/incidents';
import PartnerInvitation from './pages/organizations/PartnerInvitation';
import {
  EditLocation,
  ManageLocations,
  NewLocation,
  Locations,
} from './pages/locations';
import { Products, ProductListing } from './pages/products';
import {
  ManageOrganizations as ManageCustomers,
  OrganizationDashboard as CustomerDashboard,
  Organizations as Customers,
  EditOrganization,
} from './pages/organizations';
import {
  Purchase,
  PurchaseConfirmation,
  PurchaseSummary,
} from './pages/purchase';
import { Orders, OrderDetails, OrderHistory } from './pages/orders';
import { Licenses, ManageLicenses } from './pages/licenses';
import { LiveView as Live, MultiViewSelector } from './pages/live';
import { Users, ManageUsers, NewUser } from './pages/users';
import { ManagePartnerUsers } from './pages/partnerUsers';
import { ManageTechnicians } from './pages/technicians';
import { Device, Devices, DeviceDashboard, DeviceList } from './pages/devices';
import { AccountSettings, OrganizationSettings,UpdatePassword } from './pages/settings';
import { AllEvents, SearchDashboard } from './pages/search';
import { Events } from './pages/events';
import { AppDefaults, constants } from './helpers';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/App.scss';
import './assets/css/styles.scss';
import megatronFavicon from './assets/images/megatronFavicon.png';
import BrandFavicon from './assets/images/BrandFavicon.svg';
import {
  VideoWall,
  CameraDashboard,
  CameraList,
  Cameras,
  CamerasPage,
} from './pages/cameras';
// import { useKeycloak } from '@react-keycloak/web';
// const { keycloak } = useKeycloak();

import { Utils } from './helpers/';
import ComingSoon from './pages/ComingSoon';
import NotificationDashboard from './pages/notifications/NotificationDashboard';
import { NotificationSetting } from './pages/notifications';
import ViewDetails from './pages/analytics/ViewDetails';
import OrganizationDetails from './pages/organizations/OrganizationDetails';
import { Provider } from 'react-redux';
import Store from './store/Store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './auth/Keycloak';
import { checkMQTTConnection } from './utils/connection/mqttConnection';
import { checkWSSConnection } from './utils/connection/wssConnection';
import {
  setMQTTConnection,
  setWSSConnection,
} from './store/reducers/StreamingReducer';
import colorCodes from './assets/css/themes';
import { ManageRoles, NewRole, Roles } from './pages/customRoles';
import CustomRoleDetails from './pages/customRoles/CustomRoleDetails';
import BecameAcustomer from './pages/marketing/BecameAcustomer';
import BecameApartner from './pages/marketing/BecameApartner';
import SuccessPartner from './pages/marketing/SuccessPartner';
import SuccessCustomer from './pages/marketing/SuccessCustomer';
import ChannelDetails from './pages/devices/listDevice/ChannelDetails';
import ExportIncidentBundle from './pages/incidents/ExportIncidentBundle';
import NewLandingPage from './pages/marketing/NewLandingPage';
import PosIntegration from './pages/organizations/PosIntegration';
import DiscoveredDevices from './pages/devices/listDevice/DiscoveredDevices';
import POSlinking from './pages/organizations/POSlinking';

const App = () => {
  const moduleStatus = Utils.getModuleStatus();
  let persistor = persistStore(Store);


  useEffect(()=>{
    localStorage.setItem("isSession", 0)
  },[])

  const onEvent = (event, error) => {
    if (typeof error !== 'undefined') {
      switch (event) {
        case 'onAuthSuccess':
          localStorage.setItem('authenticated', true);
          break;

        case 'onAuthLogout':
        case 'onTokenExpired':
          localStorage.setItem('authenticated', false);
          localStorage.setItem('vmsAuthToken', null);
          break;

        default:
      }
    }
    // If user is logged in - create mqtt connection
    if (!!keycloak.authenticated) {
      if (!checkMQTTConnection()) {
        Store.dispatch(setMQTTConnection(false));
      }
      if (!checkWSSConnection()) {
        Store.dispatch(setWSSConnection(false));
      }
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON) {
      let link = document.querySelector("link[rel~='icon']");
      link.href = megatronFavicon;
    } else if (process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_DUCLO) {
      let link = document.querySelector("link[rel~='icon']");
      link.href = BrandFavicon;
    } else {
      let link = document.querySelector("link[rel~='icon']");
      link.href = BrandFavicon;
    }

    if (process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON) {
      colorCodes.colors.map((colorCode) =>
        document.documentElement.style.setProperty(
          `--${colorCode}`,
          megatron[colorCode]
        )
      );
    }
  }, []);

  return (
    <Provider store={Store}>
      <PersistGate loading={null} persistor={persistor}>
        <ReactKeycloakProvider
          authClient={keycloak}
          onEvent={onEvent}
          onTokens={({ token }) => {
            localStorage.setItem('vmsAuthToken', token);
            if(token){
              localStorage.setItem('sessionAlive', '0');
            }
          }}
        >
          <BrowserRouter>
            <Routes>
              <Route
                path="/accountVerify"
                element={
                  <PrivateRoute>
                    <AccountVerify />
                  </PrivateRoute>
                }
              />
              <Route
                path="/accountVerify/:invitationCode"
                element={
                  <PrivateRoute>
                    <AccountVerify />
                  </PrivateRoute>
                }
              />
              <Route
                path="/accountVerify.html/:invitationCode"
                element={
                  <PrivateRoute>
                    <AccountVerify />
                  </PrivateRoute>
                }
              />
              <Route
                path="/accountsettings.html"
                element={
                  <PrivateRoute>
                    <AccountSettings />
                  </PrivateRoute>
                }
              />
                <Route
                path="/updatepassword.html"
                element={
                  <PrivateRoute>
                    <UpdatePassword />
                  </PrivateRoute>
                }
              />
              {moduleStatus?.OPERATION_METRICES && (
                <Route
                  path="/reports.html"
                  element={
                    <PrivateRoute>
                      <AnalyticsDashboard />
                    </PrivateRoute>
                  }
                />
              )}
              {moduleStatus?.OPERATION_METRICES && (
                <Route
                  path="/reports.html/details"
                  element={
                    <PrivateRoute>
                      <ViewDetails />
                    </PrivateRoute>
                  }
                />
              )}
              <Route
                path="/organizationsettings.html"
                element={
                  <PrivateRoute>
                    <OrganizationSettings />
                  </PrivateRoute>
                }
              />
              <Route
                path="customers"
                element={
                  <PrivateRoute>
                    <Customers />
                  </PrivateRoute>
                }
              >
                <Route
                  path="manage.html"
                  element={
                    <PrivateRoute>
                      <ManageCustomers />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="dashboard.html"
                  element={
                    <PrivateRoute>
                      <OrganizationDetails />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="editOrganization.html"
                  element={
                    <PrivateRoute>
                      <EditOrganization />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route
                path="/app/pos"
                element={
                  <PrivateRoute>
                    <PosIntegration />
                  </PrivateRoute>
                }
              />
              <Route
                path="/pos"
                element={
                  <POSlinking />
                }
              ></Route>
              <Route
                path="editOrganization.html"
                element={
                  <PrivateRoute>
                    <EditOrganization />
                  </PrivateRoute>
                }
              />
              <Route
                path="licenses"
                element={
                  <PrivateRoute>
                    <Licenses />
                  </PrivateRoute>
                }
              >
                <Route
                  path="listing.html"
                  element={
                    <PrivateRoute>
                      <ManageLicenses />
                    </PrivateRoute>
                  }
                />
              </Route>
              {/* BEGIN: Clip Management */}
              <Route
                path="clips"
                element={
                  <PrivateRoute>
                    <Clips />
                  </PrivateRoute>
                }
              >
                <Route
                  path="details.html"
                  element={
                    <PrivateRoute>
                      <ClipDetails />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="list.html"
                  element={
                    <PrivateRoute>
                      <ClipsList />
                    </PrivateRoute>
                  }
                />
                {/* <Route path="shared-clip.html" element={<LinkExpired />} /> */}
              </Route>
              {/* END: Clip Management */}
              {moduleStatus?.INCIDENT_MANAGEMENT && (
                <Route
                  path="incidents"
                  element={
                    <PrivateRoute>
                      <Incidents />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path="manage.html"
                    element={
                      <PrivateRoute>
                        <ManageIncidents />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="details.html"
                    element={
                      <PrivateRoute>
                        <IncidentDetails />
                      </PrivateRoute>
                    }
                  />
                </Route>
              )}
              <Route
                path="locations"
                element={
                  <PrivateRoute>
                    <Locations />
                  </PrivateRoute>
                }
              >
                <Route
                  path="manage.html"
                  element={
                    <PrivateRoute>
                      <ManageLocations />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="new.html"
                  element={
                    <PrivateRoute>
                      <NewLocation />
                    </PrivateRoute>
                  }
                  exact
                />
                <Route
                  path="edit.html:locationId"
                  element={
                    <PrivateRoute>
                      <EditLocation />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route
                path="users"
                element={
                  <PrivateRoute>
                    <Users />
                  </PrivateRoute>
                }
              >
                <Route
                  path="manage.html"
                  element={
                    <PrivateRoute>
                      <ManageUsers />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="new.html"
                  element={
                    <PrivateRoute>
                      <NewUser />
                    </PrivateRoute>
                  }
                  exact
                />
              </Route>
              <Route
                path="roles"
                element={
                  <PrivateRoute>
                    <Roles />
                  </PrivateRoute>
                }
              >
                <Route
                  path="manage.html"
                  element={
                    <PrivateRoute>
                      <ManageRoles />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="new.html"
                  element={
                    <PrivateRoute>
                      <NewRole />
                    </PrivateRoute>
                  }
                  exact
                />
                <Route
                  path="dashboard.html"
                  element={
                    <PrivateRoute>
                      <CustomRoleDetails />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route
                path="products"
                element={
                  <PrivateRoute>
                    <Products />
                  </PrivateRoute>
                }
              >
                {moduleStatus?.PRODUCT_LISTING && (
                  <Route
                    path="listing.html"
                    element={
                      <PrivateRoute>
                        <ProductListing />
                      </PrivateRoute>
                    }
                  />
                )}
              </Route>
              {moduleStatus?.PRODUCT_LISTING && (
                <Route
                  path="purchase"
                  element={
                    <PrivateRoute>
                      <Purchase />
                    </PrivateRoute>
                  }
                />
              )}
              {moduleStatus?.PRODUCT_LISTING && (
                <Route
                  path="/purchase/summary.html"
                  element={
                    <PrivateRoute>
                      <PurchaseSummary />
                    </PrivateRoute>
                  }
                />
              )}

              {moduleStatus?.PRODUCT_LISTING && (
                <Route
                  path="/purchase/confirmation.html"
                  element={
                    <PrivateRoute>
                      <PurchaseConfirmation />
                    </PrivateRoute>
                  }
                />
              )}
              {/* BEGIN: Purchase Orders */}
              {moduleStatus?.PRODUCT_LISTING && (
                <Route
                  path="orders"
                  element={
                    <PrivateRoute>
                      <Orders />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path="/orders/history.html"
                    element={
                      <PrivateRoute>
                        <OrderHistory />
                      </PrivateRoute>
                    }
                  />
                  {/* <Route
                    path="/orders/details.html"
                    element={<OrderDetails />}
                  /> */}
                  <Route path="/orders/details.html" element={<ComingSoon />} />
                </Route>
              )}
              {/* END: Purchase Orders */}
              <Route
                path="devices"
                element={
                  <PrivateRoute>
                    <Devices />
                  </PrivateRoute>
                }
              >
                <Route
                  path="listing.html"
                  element={
                    <PrivateRoute>
                      <Device />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="dashboard.html"
                  element={
                    <PrivateRoute>
                      <DeviceDashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="channel-details.html"
                  element={
                    <PrivateRoute>
                      <ChannelDetails />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="discovered-devices.html"
                  element={
                    <PrivateRoute>
                      <DiscoveredDevices />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route
                path="cameras"
                element={
                  <PrivateRoute>
                    <CamerasPage />
                  </PrivateRoute>
                }
              >
                <Route
                  path="devices.html"
                  element={
                    <PrivateRoute>
                      <Cameras />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="video-wall.html"
                  element={
                    <PrivateRoute>
                      <VideoWall />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="listing.html"
                  element={
                    <PrivateRoute>
                      <CameraList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="dashboard.html"
                  element={
                    <PrivateRoute>
                      <CameraDashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="live.html"
                  element={
                    <PrivateRoute>
                      <Live />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="multiview-select.html"
                  element={
                    <PrivateRoute>
                      <MultiViewSelector />
                    </PrivateRoute>
                  }
                />
              </Route>
              {process.env.REACT_APP_HIDE_LOGIN === 'true' ? (
                <Route path="/" element={<NewLandingPage />} />
              ) : (
                <Route path="/" element={<SignIn />} />
              )}
              <Route path="/login" element={<SignIn />} />
              <Route
                path="/managepartnerusers.html"
                element={
                  <PrivateRoute>
                    <ManagePartnerUsers />
                  </PrivateRoute>
                }
              />
              <Route
                path="/managetechnicians.html"
                element={
                  <PrivateRoute>
                    <ManageTechnicians />
                  </PrivateRoute>
                }
              />
              {moduleStatus?.SEARCH_DASHBOARD && (
                <Route
                  path="/searchdashboard.html"
                  element={
                    <PrivateRoute>
                      <SearchDashboard />
                    </PrivateRoute>
                  }
                />
              )}
               {moduleStatus?.EVENTS && (
                <Route
                  path="/events.html"
                  element={
                    <PrivateRoute>
                      <Events />
                    </PrivateRoute>
                  }
                />
              )}
              {moduleStatus?.NOTIFICATION && (
                <Route
                  path="/notificationdashboard.html"
                  element={
                    <PrivateRoute>
                      <NotificationDashboard />
                    </PrivateRoute>
                  }
                />
              )}
              {moduleStatus?.NOTIFICATION && (
                <Route
                  path="/notificationsetting.html"
                  element={
                    <PrivateRoute>
                      <NotificationSetting />
                    </PrivateRoute>
                  }
                />
              )}
              {moduleStatus?.SEARCH_DASHBOARD && (
                <Route
                  path="/searchdashboard/searchevents.html"
                  element={
                    <PrivateRoute>
                      <AllEvents />
                    </PrivateRoute>
                  }
                />
              )}
              <Route
                path="/partnerinvite"
                element={<PartnerInvitation />}
              ></Route>
              <Route
                path="/incidentBundle"
                element={
                  <PrivateRoute>
                    <ExportIncidentBundle />
                  </PrivateRoute>
                }
              ></Route>
              <Route path="/createpartnerorg" element={<BecameApartner />} />
              <Route path="/becomeacustomer" element={<BecameAcustomer />} />
              <Route path="/successpartner" element={<SuccessPartner />} />
              <Route path="/successcustomer" element={<SuccessCustomer />} />
              <Route path="/404.html" element={<PageNotFound />} />
              {/* Public Route */}
              <Route
                path="/external/clips/access-clip.html"
                element={<AccessClip />}
              />
              <Route path="/clip" element={<AccessClip />} />
              <Route
                path="/external/expired-link.html"
                element={<LinkExpired />}
              />
              {/* <Route
                path="/shared-clip.html"
                element={<PageNotFound />}
              /> */}
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </BrowserRouter>
        </ReactKeycloakProvider>
      </PersistGate>
    </Provider>
  );
};

export default withTranslation()(App);
