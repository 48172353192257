import { useEffect, useState } from 'react';
import './stepspos.scss';
import PosIndicator from './PosIndicator';
import { useSearchParams } from 'react-router-dom';
import { AppDefaults, Utils, constants } from '../../../helpers';
import { PrimaryButton } from '../../../components/common';
import { PosStore } from '../../../store/PosStore';
import { useOrganizations } from '../../../store/OrganizationsStore';
import { TbCircleMinus, TbCirclePlus } from 'react-icons/tb';
import LocationSelectorPos from './LocationSelectorPos';
import { error, success } from '../../../assets/images';
import axios from 'axios';

const StepFiveLocation = (props) => {
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const [locationStoreList, setLocationStoreList] = useState([
    {
      storeIdCheck: AppDefaults?.POS_DEFUALT_STORE_ID,
      storeId: '',
      locationId: '',
    },
  ]);
  const [currentOrgLocation, setCurrentOrgLocation] = useState([]);
  const getOrganizations = useOrganizations((state) => state.getOrganizations);
  const [showLoader, setShowLoader] = useState(false);
  const getPosDataList = PosStore((state) => state.getPosDataList);
  const setPosDataList = PosStore((state) => state.setPosDataList);
  const [disableMapBtn, setDisableMapBtn] = useState(true);
  const [showMapPostStatus, setShowMapPostStatus] = useState(0);
  const [posDataListFromCSV, setPosDataListFromCSV] = useState(
    getPosDataList()
  );
  const getCustomerOrgData = useOrganizations(
    (state) => state.getCustomerOrgData
  );
  const getIsHeaderChecked = PosStore((state) => state.getIsHeaderChecked);
  useEffect(() => {
    const currentOrg = getCustomerOrgData().find(
      (user) => user?.orgId === orgId
    );
    setCurrentOrgLocation(currentOrg?.locations);
  }, []);

  const updateFormate = async (selectedFormate) => {
    let posData = locationStoreList?.map((posItem) => {
      if (posItem?.storeIdCheck === selectedFormate?.storeIdCheck) {
        return { ...posItem, ...selectedFormate };
      } else {
        return { ...posItem };
      }
    });
    let updatedPosList = await Promise.all(posData);
    setLocationStoreList(updatedPosList);
  };

  const onHandleChange = async (e, data) => {
    if (e != undefined && e?.target?.value?.length <= 30) {
      let posData = locationStoreList?.map((posItem) => {
        if (posItem?.storeIdCheck === data?.storeIdCheck) {
          return { ...posItem, storeId: e?.target?.value };
        } else {
          return { ...posItem };
        }
      });
      let updatedPosList = await Promise.all(posData);
      setLocationStoreList(updatedPosList);
    }
  };

  useEffect(() => {
    checkBtnStatus();
  }, [locationStoreList]);

  const checkBtnStatus = () => {
    const filteredList = locationStoreList?.filter((item, index) => {
      if (item?.storeIdCheck === AppDefaults?.POS_DEFUALT_STORE_ID) {
        if (item?.storeId === '' || item?.locationId === '') {
          return true;
        } else {
          return false;
        }
      } else if (item?.storeId !== '' && item?.locationId === '') {
        return true;
      } else if (item?.storeId === '' && item?.locationId !== '') {
        return true;
      } else {
        return false;
      }
    });

    if (filteredList?.length > 0) {
      setDisableMapBtn(true);
    } else if (hasDuplicates(locationStoreList)) {
      setDisableMapBtn(true);
    } else {
      setDisableMapBtn(false);
    }
  };

  const hasDuplicates = (list) => {
    const seen = new Set();
    for (const item of list) {
      if (item.storeId != '') {
        if (seen.has(item?.storeId?.toLowerCase())) {
          return true; // Found a duplicate ID
        }
        seen.add(item?.storeId?.toLowerCase()); // Add the ID to the Set
      }
    }
    return false; // No duplicate IDs found
  };

  const addStoreLocation = (item) => {
    let locStoreArray = {
      storeIdCheck: Utils.generateUUID(),
      storeId: '',
      locationId: '',
    };
    setLocationStoreList((prev) => [...prev, locStoreArray]);
    // setLocationStoreList(...)
  };

  const removeLocationStore = (itemRemove) => {
    const filterList = locationStoreList?.filter(
      (item) => item?.storeIdCheck !== itemRemove?.storeIdCheck
    );
    setLocationStoreList(filterList);
  };


  const putStoreLocation = async () => {
    let storeList = [];
    locationStoreList?.map((item) => {
      if (item?.storeId != '' && item?.locationId != '') {
        storeList.push({
          storeId: item?.storeId,
          locationId: item?.locationId,
        });
      }
    });
    if (storeList?.length === 0) {
      return;
    }

    const reqBody = {
      stores: storeList,
    };
    let responseStoreLocation = await axios.put(
      `/partner/orgs/${orgId}/stores`,
      reqBody,
      Utils.requestHeader()
    );
    let resData = responseStoreLocation?.data;

    if (resData?.meta.code === 200) {
      setShowMapPostStatus(1);
      setShowLoader(false);
    } else {
      setShowMapPostStatus(2);
      setShowLoader(false);
    }
  };

  const postMapping = async () => {
    let fieldsList = [];
    const posAtributeList = posDataListFromCSV?.filter(
      (itemAttribute) => itemAttribute?.isSelected && itemAttribute?.id
    );
    posAtributeList?.map((item) => {
      if (item?.type === AppDefaults?.DATA_TYPE_DATE) {
        fieldsList.push({
          cName: item?.Column,
          id: item?.id,
          order: item?.order,
          format: item?.formateSelected,
          columnDescription: item?.value,
          keyComponent:item?.keyComponent === 1 ? true : false
        });
      } else if (item?.custom) {
        fieldsList.push({
          cName: item?.Column,
          id: item?.id,
          order: item?.order,
          columnDescription: item?.value,
          customAttributeName: item?.customValue,
          keyComponent:item?.keyComponent === 1 ? true : false
        });
      } else {
        fieldsList.push({
          cName: item?.Column,
          id: item?.id,
          order: item?.order,
          columnDescription: item?.value,
          keyComponent:item?.keyComponent === 1 ? true : false
        });
      }
    });
    const reqBody = {
      header: getIsHeaderChecked() == 1 ? true : false,
      fields: fieldsList,
    };
   
    let response;
    if(props?.isFromEdit){
      response = await axios.put(
        `/partner/orgs/${orgId}/pos/mapping/fields`,
        reqBody,
        Utils.requestHeader()
      );
      
    }
    else{
      response = await axios.post(
        `/partner/orgs/${orgId}/pos/mapping/fields`,
        reqBody,
        Utils.requestHeader()
      );
    }
    
     

    let resData = response?.data;

    if (resData?.meta.code === 200) {
      putStoreLocation()
      await getOrganizations(`/partner/account/self`);
    } else {
      setShowMapPostStatus(2);
      setShowLoader(false);
    }
  };

  return (
    <div className="pos-integration">
      <PosIndicator showMapPostStatus={showMapPostStatus} {...props} />
      {showMapPostStatus === 0 && (
        <div className={`manage-pos-height`}>
          <div className="step-five-locations">
            <div className="lable-upload-file">
              {constants.POS_SELECT_LOCATION}
            </div>
            <div className="extract-message">
              {constants.POS_SELECT_LOCATION_MESSAGE}
            </div>
            <div className="main-container-store-loc">
              {locationStoreList &&
                locationStoreList?.length > 0 &&
                locationStoreList?.map((item, index) => {
                  return (
                    <div className="store-location-layout">
                      <input
                        className="form-control require shadow-none input-type-box-container"
                        autoComplete="off"
                        name="defaultArea"
                        type="text"
                        onChange={(e) => onHandleChange(e, item, index)}
                      />
                      <LocationSelectorPos         
                        isFromEdit={false}
                        posItem={item}
                        updatePosStoreWithAttribute={(selectedFormate) => {
                          updateFormate(selectedFormate);
                        }}
                        currentOrgLocation={currentOrgLocation}
                      />
                      {item?.storeIdCheck ===
                      AppDefaults?.POS_DEFUALT_STORE_ID ? (
                        <TbCirclePlus
                          size={50}
                          className="create-area-icon align-self-center"
                          onClick={() => addStoreLocation(item)}
                          style={{ cursor: 'pointer' }}
                        />
                      ) : (
                        <TbCircleMinus
                          size={50}
                          className="remove-area-icon align-self-center"
                          onClick={() => removeLocationStore(item)}
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}

      {showMapPostStatus === 0 && (
        <PrimaryButton
          className="adddevice-btn"
          fontSize="14px"
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_40')}
          height="44px"
          color="#FFFFFF"
          type="submit"
          disabled={disableMapBtn}
          loader={showLoader}
          onClick={() => {
            setShowLoader(true)
            postMapping()
          }}
        >
          {constants.POS_COMPLETE_SETUP}
        </PrimaryButton>
      )}
      {showMapPostStatus === 0 && (
        <PrimaryButton
          className="adddevice-btn"
          fontSize="14px"
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--brand_white')}
          height="44px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--primary_40'
          )}
          borderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_40')}
          type="submit"
          onClick={() => {
            props.prevstep();
          }}
        >
          {constants.ORGANIZATION_LDAP_GO_BACK}
        </PrimaryButton>
      )}

      {(showMapPostStatus === 1 || showMapPostStatus === 2) && (
        <div className={`manage-pos-height`}>
          <div className="org-map-setup-content modal-succ-failure">
            <img src={showMapPostStatus === 1 ? success : error} alt="" />
            <div className="title-setup-completed">
              {showMapPostStatus === 1
                ? constants.POS_SETEUP_COMPLETED
                : constants.POS_SETEUP_ERROR}
            </div>
            <PrimaryButton
              className="adddevice-btn btn-margin-try"
              fontSize="14px"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              height="44px"
              color="#FFFFFF"
              type="submit"
              onClick={() => {
                showMapPostStatus === 1 ? props?.hideModal() : postMapping();
              }}
            >
              {showMapPostStatus === 1
                ? constants.POS_SETEUP_COMPLETED_BTN
                : constants.POS_SETEUP_ERROR_BTN}
            </PrimaryButton>
            {showMapPostStatus === 2 && (
              <PrimaryButton
                className="adddevice-btn"
                fontSize="14px"
                backgroundColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--brand_white')}
                height="44px"
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--primary_40')}
                borderColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--primary_40')}
                type="submit"
                onClick={() => {
                  props.hideModal();
                }}
              >
                {constants.CANCEL_BUTTON_TITLE}
              </PrimaryButton>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default StepFiveLocation;
