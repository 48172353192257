import { useCallback, useEffect, useState } from 'react';
import { useRefresh } from 'muuri-react';
import Tooltip from '@mui/material/Tooltip';
import { v4 as uuidv4 } from 'uuid';

import { constants, Utils } from '../../helpers/';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { devicesMQTTStore } from '../../store/DevicesMQTTStore';
import {
  mqttPublish,
  mqttSubscribe,
} from '../../utils/connection/mqttConnection';

import { FiCameraOff } from 'react-icons/fi';
import { HiOutlinePlay } from 'react-icons/hi2';
import ZoomView from './ZoomView';
import FakeSnapshot from '../../assets/images/cameras/snapshots/fake-snapshot.png';
import './CameraItem.scss';

const CameraItem = ({
  orgId,
  cdnInfo,
  deviceId,
  deviceElemId,
  deviceName,
  locationAreaName = '',
  imageURL = FakeSnapshot,
  showZoomOptions = false,
  currentLayoutStatus = false,
  zoomCallback = null,
  clickHandler = null,
  conStatus,
  hubId,
  ...props
}) => {
  const [appTopic, setAppTopic] = useState(`a/notify/${hubId}`);
  const [zoomLevel, setZoomLevel] = useState('1x');
  const [currentZoomLevel, setCurrentZoomLevel] = useState('');
  const [initialLayoutStatus, setInitialLayoutStatus] =
    useState(currentLayoutStatus);
  const [latestSnapshots, setLatestSnapshots] = useState({});
  const [snapshotURL, setSnapshotURL] = useState(imageURL);
  const [highlighted, setHighlighted] = useState(false);
  const refreshWall = useRefresh();

  const { getState, subscribe } = devicesMQTTStore;
  const state = getState();
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );

  const accountId = loggedInUserData.accountId;

  useEffect(() => {
    setCurrentZoomLevel(zoomLevel);

    if (state.getAccountId() !== accountId) {
      state.setAccountId(accountId);
    }

    if (!state.getSessionId()) {
      state.setSessionId(uuidv4());
    }

    const appSubscription = {
      topic: appTopic,
      qos: 0,
    };

    const deviceSubscription = {
      topic: `d/notify/${accountId}/${state.getSessionId()}`,
      qos: 0,
    };

    // Subscribe to the app topic
    //mqttSubscribe(appSubscription);
    state.setIsPublisherSubscribed(true);

    // Subscribe  to the device topic
    mqttSubscribe(deviceSubscription);
    state.setIsReceiverSubscribed(true);

    subscribe((latestSnapshots) => setLatestSnapshots(latestSnapshots));

    // Retrieve latest snapshot every 10 seconds
    const interval = setInterval(() => {
      publishSnapshotRequest();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const fetchLatestSnapshot = async (lastSnapshot) => {
      if (!lastSnapshot || !cdnInfo) {
        return;
      }

      let bucket = cdnInfo?.bucket?.replace('${deviceId}', deviceId);

      const snapshotImage = new Image();

      // Assign the valid URL to the snapshot URL state
      // variable
      snapshotImage.onload = () => {
        setSnapshotURL(snapshotImage.src);
      };

      // Handle the image loading error (e.g. 404 or 403 error)
      snapshotImage.onerror = () => {
        setSnapshotURL(imageURL);
      };
      const date = Utils.fetchDateInUnix(lastSnapshot);

      snapshotImage.src = `${cdnInfo.protocol}://${cdnInfo.host}/${bucket}/${date}/${lastSnapshot}.jpg`;
    };

    const lastSnapshot = state.latestSnapshots[deviceId];
    const lastEvent = state.latestEvents[deviceId];
    const deviceSnapshot = lastSnapshot > lastEvent ? lastSnapshot : lastEvent;

    // Check if the device still has an unseen
    // event
    if (!isNaN(lastEvent)) {
      setHighlighted(true);
    }

    fetchLatestSnapshot(deviceSnapshot);
  }, [latestSnapshots]);

  useEffect(() => {
    refreshWall(zoomLevel);
  }, [zoomLevel, refreshWall]);

  const publishSnapshotRequest = useCallback(() => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const sessionId = state.getSessionId();

    if (!accountId) {
      return;
    }

    // Send the request
    const context = {
      topic: appTopic,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${hubId}`,
        from: `${accountId}`,
        msg: {
          action: 'get',
          resource: `ch/${deviceId}/camera/last-snap-timestamp`,
          publish: `d/notify/${accountId}/${sessionId}`,
        },
      }),
      qos: 0,
    };
    if (deviceId) {
      mqttPublish(context);
    }
  }, []);

  const playHandler = (deviceId) => {
    if (!deviceId) return;

    const accountId = state.getAccountId();
    const sessionId = state.getSessionId();

    state.deleteEventByDeviceId(accountId, sessionId, deviceId);
    setHighlighted(false);

    clickHandler && clickHandler();
  };

  const changeZoom = (level) => {
    if (isNaN(level)) {
      return;
    }

    const newLevel = level + 'x';

    if (!initialLayoutStatus) {
      zoomCallback(currentZoomLevel !== newLevel);
      setInitialLayoutStatus(currentZoomLevel !== newLevel);
    }

    setZoomLevel(newLevel);
  };

  return (
    <div key={deviceId} id={deviceElemId} className={`item item-${zoomLevel}`}>
      <div className="item-content">
        {/* Safe zone, enter your custom markup */}
        <div
          id={`${deviceElemId}Snapshot`}
          className={`${
            conStatus === 'offline' ? 'hidden' : 'item-snapshot'
          } item-snapshot-${zoomLevel}`}
        >
          <picture className="item-snapshot-wrapper">
            <img
              src={snapshotURL}
              className={`item-snapshot-image item-snapshot-image-${zoomLevel}${
                highlighted ? ' item-snapshot-image-highlighted' : ''
              }`}
              alt={`Snapshot of ${deviceName}`}
            />
          </picture>
        </div>
        <div
          id={`${deviceElemId}Overlay`}
          className={`${
            conStatus === 'offline' ? ' item-offline' : 'item-overlay'
          } item-overlay-${zoomLevel}`}
        >
          {conStatus === 'offline' ? (
            <div className="item-offline-content">
              <div className="camera-offline-icon">
                <FiCameraOff
                  stroke={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--greyscale_56')}
                  size={22}
                  onClick={() => {
                    let devId = deviceId;

                    playHandler(devId);
                  }}
                />
              </div>
              <div className="camera-offline-label">
                {constants.CAMERAS_VIDEO_WALL_CAMERA_OFFLINE_TITLE}
              </div>
            </div>
          ) : (
            <div className="item-overlay-content">
              <div
                className={`zoom-options${!showZoomOptions ? ' hidden' : ''}`}
              >
                <ZoomView zoomLevel={zoomLevel} clickHandler={changeZoom} />
              </div>
              <div className="play-location-wrapper">
                <div className="play-button">
                  <HiOutlinePlay
                    size={'1.5rem'}
                    onClick={() => {
                      let devId = deviceId;

                      playHandler(devId);
                    }}
                  />
                </div>
                <div className="device-location-wrapper">
                  <div className="item-overlay-name">{deviceName}</div>
                  <div className="item-overlay-location">
                    {locationAreaName}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <Tooltip title={locationAreaName} placement="bottom">
          <div className={`item-location item-location-${zoomLevel}`}>
            {locationAreaName}
          </div>
        </Tooltip>
        {/* Safe zone ends */}
      </div>
    </div>
  );
};

export default CameraItem;
