import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  Container,
  ToggleButtonGroup,
  ToggleButton,
  Card,
  Row,
  Col,
} from 'react-bootstrap';
import { HiOutlineCheckCircle, HiOutlineShoppingCart } from 'react-icons/hi';
import axios from 'axios';
import { useCart } from '../../store/CartStore';
import {
  Header,
  BreadcrumbList,
  QuantityModifier,
  SiteSpinner,
  SiteToast,
  Tabs,
} from '../../components/common';
import { constants, Utils, roles } from '../../helpers';
import { useTranslation } from 'react-i18next';
import './ProductListing.scss';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';

const localConstants = {
  GLOBAL_SERVICE_DURATION: 'SERVICE_DURATION',
};

const ProductListing = () => {
  const { t } = useTranslation();
  const [productList, setProductList] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [serviceDurations, setServiceDurations] = useState([]);
  const [termFilter, setTermFilter] = useState(null);
  const [isOrderPending, setIsOrderPending] = useState(false);
  const [serviceDurationDisplayValue, setServiceDurationDisplayValue] =
    useState(null);
  const [serviceDurationUnit, setServiceDurationUnit] = useState(null);
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const orgName = searchParams.get('orgName');
  const navigate = useNavigate();
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData
  );
  const loggedInUser = getLoggedInUserData();

  const breadList = [
    {
      url: '/customers/manage.html',
      title: loggedInUser.role !== roles.ROLE2VMS ?  
            constants.MANAGE_ORG_PAGE_TITLE : 
            constants.MANAGE_ORG_PAGE_ORG_TITLE,
    },
    {
      url: `/customers/dashboard.html?orgId=${orgId}&orgName=${orgName}`,
      title: `${orgName}`,
    },
    {
      url: `/licenses/listing.html?orgId=${orgId}&orgName=${orgName}`,
      title: constants.LICENSES_PAGE_TITLE,
    },
    { url: '', title: constants.PURCHASE_BREADCRUMB_TITLE },
  ];

  let licenses = useCart((state) => state.cart);
  let products = useCart((state) => state.products);
  let pendingOrder = useCart((state) => state.isOrderPending);
  const getProducts = useCart((state) => state.getProducts);
  const resetProducts = useCart((state) => state.resetProducts);
  const addToCart = useCart((state) => state.addToCart);
  const getCartItemQuantity = useCart((state) => state.getCartItemQuantity);
  const getCartTotalQuantity = useCart((state) => state.getCartTotalQuantity);
  const deleteCart = useCart((state) => state.deleteCart);
  const setOrder = useCart((state) => state.setOrder);
  const setOrgId = useCart((state) => state.setOrgId);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setTotalQuantity(parseInt(getCartTotalQuantity()));
  }, [licenses]);

  useEffect(() => {
    filterProductList(termFilter);
  }, [termFilter]);

  // Get Products
  const fetchData = async () => {
    let productLoadSuccess = false;

    try {
      setShowLoader(true);
      productLoadSuccess = await getProducts(`/user/orgs/${orgId}/products`);
      if (productLoadSuccess?.status === 'success') {
        setProductList([...products]);
        setServiceDurations([...getServiceDurations()]);
        filterProductList(termFilter);
      } else {
        // If no products are associateed with current organization,
        // we'll clear the product list and shopping cart.
        resetProducts();
        deleteCart();
        setUserMsg(productLoadSuccess.msg);
        setShowToast(true);
      }

      let duration =
        serviceDurations[Math.floor([...getServiceDurations()].length / 2)];
      let durationDisplayValue, unit;
      // Set default filter

      setTermFilter(duration);

      if (duration / 365 >= 1) {
        durationDisplayValue = Math.round(duration / 365);
        unit = constants.PRODUCTS_PAGE_DURATION_YEAR;
      } else {
        durationDisplayValue = Math.round(duration / 31);
        unit = constants.PRODUCTS_PAGE_DURATION_MONTH;
      }

      setServiceDurationDisplayValue(durationDisplayValue);
      setServiceDurationUnit(unit);
      setShowLoader(false);
    } catch (error) {
      console.error('ERROR: ', error);
      setUserMsg(error);
      setShowToast(true);
    }
  };

  const getServiceDurationfeatureTypeId = (featureTypes) => {
    return featureTypes.find(
      (featureType) =>
        featureType.featureType === localConstants.GLOBAL_SERVICE_DURATION
    )?.featureTypeId;
  };

  const getServiceDurations = () => {
    const durations = new Set();
    let featureTypeId;

    products.forEach((product) => {
      featureTypeId = getServiceDurationfeatureTypeId(product.featureTypes);
      product.features.forEach((feature) => {
        if (feature.featureTypeId === featureTypeId && feature.durationInDays) {
          durations.add(feature.durationInDays);
        }
      });
    });

    return [...durations].sort((a, b) => a - b);
  };

  const filterProductList = (termFilter) => {
    if (termFilter !== null && termFilter !== undefined) {
      const filteredProducts = products.filter((product) => {
        return (
          Utils.getFeatureValue(
            localConstants.GLOBAL_SERVICE_DURATION,
            product
          ) === termFilter
        );
      });
      setProductList([...filteredProducts]);
    } else {
      setProductList([...products]);
    }
  };

  const getDurationFilters = () => {
    let durationDisplayValue, unit;

    return serviceDurations.map((duration) => {
      if (duration / 365 >= 1) {
        durationDisplayValue = Math.round(duration / 365);
        unit = constants.PRODUCTS_PAGE_DURATION_YEAR;
      } else {
        durationDisplayValue = Math.round(duration / 31);
        unit = constants.PRODUCTS_PAGE_DURATION_MONTH;
      }

      return {
        icon: null,
        label: `${durationDisplayValue} ${unit}`,
        clickHandler: () => {
          let dv, u;
          if (duration / 365 >= 1) {
            dv = Math.round(duration / 365);
            u = constants.PRODUCTS_PAGE_DURATION_YEAR;
          } else {
            dv = Math.round(duration / 31);
            u = constants.PRODUCTS_PAGE_DURATION_MONTH;
          }

          setTermFilter(duration);
          setServiceDurationDisplayValue(dv);
          setServiceDurationUnit(u);
        },
      };
    });
  };

  return (
    <div className="App product-listing">
      <Header numOfICartItems={totalQuantity} />
      <Container className="container-top-offset mw-100" fluid>
        <Row className="mb-5">
          <Col>
            <Container className='mw-100'>
              {showLoader ? (
                <SiteSpinner height="100px" width="100px" />
              ) : (
                <div className="page-header mt-4 mb-5">
                  <Row xs={1} md={2} className="g-4">
                    <Col md={6} lg={6} xl={6} xs={12}>
                      <BreadcrumbList list={breadList} />
                    </Col>
                  </Row>
                  <Row>
                    <div className="product-list-toast-container">
                      <SiteToast
                        customCss="product-list-toast"
                        position="top-end"
                        show={showToast}
                        title="Uh-oh!"
                        body={userMsg}
                        delay={5000}
                      />
                      <span className="page-title">
                        {t('PRODUCTS_PAGE_TITLE')}
                      </span>
                      <p className="page-description">
                        {t('PRODUCTS_PAGE_OVERVIEW')}
                      </p>
                      <ul className="plan-features">
                        {t('PRODUCTS_PAGE_HIGHLIGHTS').split(' - ').map(
                          (highlight, highlightIndex) => (
                            <li
                              key={`highlight${highlightIndex}`}
                              className="page-description"
                            >
                              {highlight}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </Row>
                  <Row className="product-listing-section">
                    <Col>
                      <Tabs
                        tabCollection={getDurationFilters()}
                        firstTabDefaultActive={false}
                        defaultActive={Math.floor(serviceDurations.length / 2)}
                        fullWidth={false}
                        customTabWidth={112}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <div className="selected-plan-name">
                      {termFilter
                        ? `${serviceDurationDisplayValue} ${serviceDurationUnit} Cloud Access`
                        : constants.PRODUCTS_PAGE_MULTIYEAR_CLOUD_ACCESS}
                    </div>
                  </Row>
                  <Row className="product-listing-section">
                    {Array.isArray(productList)
                      ? productList.map((product) => {
                          const features =
                            product?.productDescription.split(' - ') || [];

                          return (
                            <Col key={product.sku} className="card-column">
                              <Card md={8} className="features-card">
                                <Card.Body className="features-card-body">
                                  <Card.Title className="features-card-title">
                                    {product.displayName}
                                  </Card.Title>
                                  <div className="features-card-body-wrapper">
                                    <div className="features-card-body-wrapper-left">
                                      <h4 className="bold">
                                        {Utils.formatCurrencyWithSmallDecimals(
                                          product.displayPrice
                                        )}
                                      </h4>
                                      <ul className="feature-list">
                                        <li key={`product-sku-${product.sku}`}>
                                          <HiOutlineCheckCircle
                                            fontSize={18}
                                            className="feature-check-circle"
                                          />
                                          <span className="feature-list-item">
                                            SKU: {product.sku}
                                          </span>
                                        </li>
                                        {Array.isArray(features)
                                          ? features.map(
                                              (feature, featureIndex) => (
                                                <li key={featureIndex}>
                                                  <HiOutlineCheckCircle
                                                    fontSize={18}
                                                    className="feature-check-circle"
                                                  />
                                                  <span className="feature-list-item">
                                                    {feature}
                                                  </span>
                                                </li>
                                              )
                                            )
                                          : null}
                                      </ul>
                                    </div>
                                  </div>
                                </Card.Body>
                                <Card.Footer className="features-card-footer">
                                  {getCartItemQuantity(product.sku).quantity <
                                  1 ? (
                                    <Button
                                      variant="primary"
                                      id={`btn${product.sku}`}
                                      className="add-service-btn"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        addToCart(product.sku);
                                      }}
                                    >
                                      <HiOutlineShoppingCart className="shopping-cart-icon" />
                                      <span className="add-service-btn-label">
                                        {
                                          constants.PRODUCTS_PAGE_ADD_SERVICE_BUTTON
                                        }
                                      </span>
                                    </Button>
                                  ) : (
                                    <div className="quantity-modifier-wrapper">
                                      <QuantityModifier
                                        sku={product.sku}
                                        quantity={
                                          getCartItemQuantity(product.sku)
                                            .quantity
                                        }
                                        quantityClassName="product-list"
                                      />
                                    </div>
                                  )}
                                </Card.Footer>
                              </Card>
                            </Col>
                          );
                        })
                      : null}
                  </Row>
                </div>
              )}
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProductListing;
