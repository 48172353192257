import { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import axios from 'axios';

import { constants, Utils } from '../../helpers/';

import { useOrganizations } from '../../store/OrganizationsStore';

import { HiOutlineDotsCircleHorizontal, HiPlus } from 'react-icons/hi';
import { IoCloseOutline } from 'react-icons/io5';

import './Tags.scss';
import mockTags from '../../data/mocks/tags.json';
const USE_MOCKED_DATA = false;
const NUM_OF_TAGS_PER_SET = 5;

const Tags = ({
  variant = 'default',
  tagCategory = 'IM',
  fullWidth = false,
  preSelectedTags = [],
  systemTagsOnly = false,
  selectedTagsOnly = false,
  addTagsCallback = null,
  removeTagCallback = null,
  isReadOnly = false,
}) => {
  const [showInputError, setShowInputError] = useState(false);
  const [tags, setTags] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);
  const [systemTags, setSystemTags] = useState([]);
  const [currentTagFilter, setCurrentTagFilter] = useState(null);
  const [currentTagCategory, setCurrentTagCategory] = useState(
    tagCategory?.toUpperCase() || 'IM'
  );
  const [tagListLimit, setTagListLimit] = useState(NUM_OF_TAGS_PER_SET);
  const [loadMoreLimit, setLoadMoreLimit] = useState(0);
  const { getCustomerOrgData } = useOrganizations();
  const orgDetails = getCustomerOrgData()[0];
  const tagInputRef = new useRef();

  const TagsRoute = {
    IM: `/partner/orgs/${orgDetails?.orgId}/tags/incidents`,
    CLIP: `/partner/orgs/${orgDetails?.orgId}/tags/clips`,
  };

  useEffect(() => {
    const fetchTags = async () => {
      try {
        let responseData;

        if (!USE_MOCKED_DATA) {
          const res = await axios.get(
            `/partner/orgs/${orgDetails?.orgId}/tags?category=${currentTagCategory}`,
            Utils.requestHeader()
          );
          responseData = res?.data;
        } else {
          responseData = mockTags;
        }

        // Set tags if request is successful
        if (responseData?.meta?.code === 200) {
          const categorizedTags = responseData.data?.tags;
          let sortedCategorizedTags;

          // First sort the tags by count number in descending order
          sortedCategorizedTags = [
            ...categorizedTags.sort((tag1, tag2) => {
              if (tag1.count > tag2.count) {
                return -1;
              }

              if (tag1.count < tag2.count) {
                return 1;
              }

              return 0;
            }),
          ];

          if (Array.isArray(sortedCategorizedTags)) {
            const numOfSets =
              sortedCategorizedTags.length / NUM_OF_TAGS_PER_SET;

            setTags([...sortedCategorizedTags]);
            setAvailableTags([...sortedCategorizedTags]);

            if (systemTagsOnly === true) {
              setSystemTags([
                ...sortedCategorizedTags.filter(
                  (tag) => tag.isReadOnly === true
                ),
              ]);
              setFilteredTags([
                ...sortedCategorizedTags.filter(
                  (tag) => tag.isReadOnly === true
                ),
              ]);
            } else {
              // Remove the preselected tags from the list of available tags
              if (
                Array.isArray(preSelectedTags) &&
                preSelectedTags.length > 0
              ) {
                const tagsNotSelected = sortedCategorizedTags.filter((tag) => {
                  return !preSelectedTags.some((selectedTagId) => {
                    return selectedTagId === tag?.tagId;
                  });
                });

                setFilteredTags([...tagsNotSelected]);
                setAvailableTags([...tagsNotSelected]);
              } else {
                setFilteredTags([...sortedCategorizedTags]);
              }
            }

            setLoadMoreLimit(numOfSets > 0 ? numOfSets : 0);

            if (Array.isArray(preSelectedTags) && preSelectedTags.length > 0) {
              const currentSelectedTags = [
                ...preSelectedTags.map((preSelectedTagId) => {
                  return getTagObject(sortedCategorizedTags, preSelectedTagId);
                }),
              ];

              setSelectedTags([...currentSelectedTags]);
            } else {
              setFilteredTags([...sortedCategorizedTags]);
            }
          } else {
            throw new Error('ERROR: unable to retrieve tags');
          }
        } else {
          if (responseData?.meta?.code) {
            console.error(
              `${responseData?.meta?.code}: ${responseData?.meta?.message}`
            );
          } else if (responseData?.data) {
            console.error(responseData?.data?.userMsg);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchTags();
  }, []);

  const getTagObject = (tagsArray, tagId) => {
    if (!tagId) return;

    const tagObject = tagsArray.find((tag) => tag?.tagId === tagId + '');

    return tagObject;
  };

  const addTag = async (selectedTag) => {
    if (!selectedTag || !selectedTag?.tagId) return;

    const currentTags = [...selectedTags];
    const currentAvailableTags = [...availableTags];
    let newAvailableTags;

    const tagIndex = currentAvailableTags.findIndex(
      (tag) => tag?.tagId === selectedTag?.tagId
    );

    if (tagIndex !== -1) {
      newAvailableTags = currentAvailableTags
        .slice(0, tagIndex)
        .concat(currentAvailableTags.slice(tagIndex + 1));

      setAvailableTags([...newAvailableTags]);
      setFilteredTags([...newAvailableTags]);
    }

    if (
      currentTags.length < 1 ||
      currentTags.findIndex((tag) => tag?.tagId === selectedTag?.tagId) === -1
    ) {
      currentTags.push(selectedTag);

      setSelectedTags([...currentTags]);

      // Pass updated assigned tags to calling component
      addTagsCallback && addTagsCallback([selectedTag?.tagId]);
    }
  };

  const removeTag = (selectedTag) => {
    const currentTags = [...selectedTags];
    const currentAvailableTags = [...availableTags];
    let modifiedTags;

    if (!selectedTag) return;

    const tagIndex = currentTags.findIndex(
      (tag) => tag?.tagId === selectedTag?.tagId
    );

    if (tagIndex !== -1) {
      modifiedTags = currentTags
        .slice(0, tagIndex)
        .concat(currentTags.slice(tagIndex + 1));

      setSelectedTags([...modifiedTags]);

      currentAvailableTags.push(selectedTag);
      setAvailableTags([...currentAvailableTags]);
      setFilteredTags([...currentAvailableTags]);

      removeTagCallback && removeTagCallback(selectedTag?.tagId);
    }
  };

  const createTag = async (tagName) => {
    try {
      let newTag;

      if (!tagName) return;

      // First, check if the tag name entered doesn't already exist
      const tagIndex = availableTags.findIndex(
        (tag) => tag.name?.toLowerCase() === tagName?.toLowerCase()
      );

      // If it does then we don't save it
      if (!isNaN(tagIndex) && tagIndex !== -1) {
        // show tag input error
        setShowInputError(true);
        // Then exit out
        return;
      }

      // Get the current tags available
      const currentTags = [
        ...tags.map((tag) => {
          return {
            name: tag.name,
            category: currentTagCategory,
            tagId: tag?.tagId,
          };
        }),
      ];

      // Add the new tag to the array
      currentTags.push({
        name: tagName,
        category: currentTagCategory,
      });

      const reqBody = {
        tags: [...currentTags],
      };

      const res = await axios.put(
        TagsRoute[currentTagCategory],
        reqBody,
        Utils.requestHeader()
      );
      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        const categorizedTags = responseData.data?.tags;
        let currentSelectedTags = [];

        if (Array.isArray(categorizedTags)) {
          const numOfSets = categorizedTags.length / NUM_OF_TAGS_PER_SET;

          if (Array.isArray(selectedTags) && selectedTags.length > 0) {
            currentSelectedTags = [...selectedTags];
          }

          newTag = categorizedTags.find((tag) => tag.name === tagName);

          if (!newTag) {
            throw new Error(`ERROR: failed to add ${tagName}`);
          }

          // Add the new tag to the current selected tags
          currentSelectedTags.push(newTag);
          setSelectedTags([...currentSelectedTags]);

          // Filter the remaining tags available
          const unselectedTags = categorizedTags.filter((tag) => {
            return !currentSelectedTags.some((selectedTag) => {
              return selectedTag?.tagId === tag?.tagId;
            });
          });

          // Clear tag input field
          tagInputRef.current.value = null;
          setTags([...categorizedTags]);
          setFilteredTags([...unselectedTags]);
          setAvailableTags([...unselectedTags]);
          setLoadMoreLimit(numOfSets > 0 ? numOfSets : 0);

          if (addTagsCallback) {
            addTagsCallback([newTag?.tagId]);
          }
        } else {
          throw new Error('ERROR: tags retrieval failed');
        }
      } else {
        if (responseData?.meta?.code) {
          console.error(
            `${responseData?.meta?.code}: ${responseData?.meta?.message}`
          );
        } else if (responseData?.data) {
          console.error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const filterTags = (e) => {
    const filter = e?.target?.value;

    if (currentTagFilter === filter) {
      return;
    }

    setShowInputError(false);
    setCurrentTagFilter(filter);

    if (filter) {
      // First ensure that we're only filtering from a list of tags
      // that doesn't include selected tags
      const unassignedTags = [
        ...tags.filter((tag) => {
          return !selectedTags.some((selectedTag) => {
            return selectedTag?.tagId === tag?.tagId;
          });
        }),
      ];
      const newTags = unassignedTags.filter((tag) =>
        tag.name.startsWith(filter)
      );
      setAvailableTags([...newTags]);
      setFilteredTags([...newTags]);
    } else {
      const unSelectedTags = [
        ...tags.filter((tag) => {
          return !selectedTags.some((selectedTag) => {
            return selectedTag?.tagId === tag?.tagId;
          });
        }),
      ];

      setAvailableTags([...unSelectedTags]);
      setFilteredTags([...unSelectedTags]);
    }
  };

  const renderTagsList = () => {
    const tagListArray = [];
    const tagInputField = tagInputRef?.current;
    let filteredTag;

    if (!Array.isArray(filteredTags) || filteredTags.length < 1) {
      return;
    }

    if (systemTagsOnly === true) {
      return systemTags.map((systemTag) => (
        <div
          key={`filteredTag-${systemTag?.tagId}`}
          className="tag-item system-tag read-only"
        >
          <span className="tag-label">{systemTag?.name}</span>
        </div>
      ));
    } else {
      if (availableTags.length < tagListLimit) {
        return availableTags.map((availableTag) => (
          <div
            key={`filteredTag-${availableTag?.tagId}`}
            className={`tag-item ${variant === 'dark' ? 'dark-theme' : ''}`}
          >
            <span className="tag-label">{availableTag?.name}</span>
            <span
              data-tag={JSON.stringify(availableTag)}
              className="tag-add-icon"
              onClick={(e) => {
                e.preventDefault();

                const tag = e?.currentTarget?.dataset?.tag;

                if (!tag || isReadOnly) return;

                addTag(JSON.parse(tag));
              }}
            >
              <HiPlus size={'0.875rem'} />
            </span>
          </div>
        ));
      } else {
        for (let i = 0; i < tagListLimit; i++) {
          filteredTag = filteredTags[i];

          tagListArray.push(
            selectedTagsOnly ? (
              <div
                key={filteredTag?.tagId}
                className="tag-item system-tag read-only"
              >
                <span className="tag-label">{filteredTag?.name}</span>
              </div>
            ) : (
              <div
                key={filteredTag?.tagId}
                className={`tag-item ${variant === 'dark' ? 'dark-theme' : ''}`}
              >
                <span className="tag-label">{filteredTag?.name}</span>
                <span
                  data-tag={JSON.stringify(filteredTag)}
                  className="tag-add-icon"
                  onClick={(e) => {
                    e.preventDefault();

                    const tag = e?.currentTarget?.dataset?.tag;

                    if (!tag || isReadOnly) return;

                    addTag(JSON.parse(tag));
                  }}
                >
                  <HiPlus size={'0.875rem'} />
                </span>
              </div>
            )
          );
        }
      }
    }

    return <>{tagListArray}</>;
  };

  const debouncedOnChange = debounce(filterTags, 500);

  return (
    <div
      className={`tags-wrapper ${selectedTagsOnly ? 'no-padding' : ''} ${
        fullWidth ? 'full-width' : ''
      }`}
    >
      {systemTagsOnly === false && selectedTags.length > 0 && (
        <div className="tags-selected-wrapper">
          {selectedTags.map((tag) => (
            <div key={`selectedTag-${tag?.tagId}`} className="tag-added-item">
              <span className="tag-label">{tag?.name}</span>
              <span
                data-tag={JSON.stringify(tag)}
                className="tag-delete-icon"
                onClick={(e) => {
                  e.preventDefault();
                  const tag = e?.currentTarget?.dataset?.tag;

                  if (!tag || isReadOnly) return;

                  removeTag(JSON.parse(tag));
                }}
              >
                {!isReadOnly && <IoCloseOutline size={'1.2rem'} />}
              </span>
            </div>
          ))}
        </div>
      )}
      {!selectedTagsOnly ? (
        <>
          <div className="tag-filter">
            <input
              ref={tagInputRef}
              className={`tag-filter-input ${
                variant === 'dark' ? 'dark-theme' : ''
              } ${showInputError ? 'error' : ''}`}
              type="text"
              placeholder={
                isReadOnly
                  ? constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_SEARCH_DISABLED_PLACEHOLDER
                  : constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_ENTER_TAG_PLACEHOLDER
              }
              onChange={debouncedOnChange}
              maxLength={25}
              readonly={isReadOnly}
              disabled={isReadOnly}
            />
          </div>
          {showInputError && (
            <div className="tag-filter-input-error">
              {constants.CLIP_DETAILS_TAG_INPUT_ERROR}
            </div>
          )}
          <div className="tags-list-wrapper">
            {!isReadOnly && availableTags?.length > 0 ? (
              <>
                {renderTagsList()}
                {availableTags.length > NUM_OF_TAGS_PER_SET &&
                  filteredTags.length > 0 &&
                  tagListLimit < availableTags.length && (
                    <div
                      className="tag-load-more"
                      onClick={() => {
                        if (tagListLimit / NUM_OF_TAGS_PER_SET > 0) {
                          setTagListLimit((prev) => prev + NUM_OF_TAGS_PER_SET);
                        }
                      }}
                    >
                      <HiOutlineDotsCircleHorizontal
                        className="tag-load-more-icon"
                        size={'1.00rem'}
                      />
                      <span className="tag-load-more-label">
                        {
                          constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_LOAD_MORE_TEXT
                        }
                      </span>
                    </div>
                  )}
                {tagInputRef?.current?.value && (
                  <div
                    className="tag-add-new"
                    onClick={() => {
                      if (!isReadOnly) {
                        createTag(currentTagFilter);
                      }
                    }}
                  >
                    <HiPlus className="tag-add-new-icon" size={'0.875rem'} />
                    <span className="tag-add-new-label">
                      {
                        constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_ADD_NEW_TAG_TEXT
                      }
                    </span>
                  </div>
                )}
              </>
            ) : (
              <>
                {tagInputRef?.current?.value && (
                  <div
                    className="tag-add-new"
                    onClick={() => {
                      if (!isReadOnly) {
                        createTag(currentTagFilter);
                      }
                    }}
                  >
                    <HiPlus className="tag-add-new-icon" size={'0.875rem'} />
                    <span className="tag-add-new-label">
                      {
                        constants.CAMERAS_LIVE_CREATE_CLIP_MODAL_ADD_NEW_TAG_TEXT
                      }
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <div className="tags-list-wrapper no-padding">{renderTagsList()}</div>
      )}
    </div>
  );
};

export default Tags;
