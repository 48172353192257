import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import './ViewDetails.scss';
import CameraAvailableDetails from './AnalyticsDetails/CameraAvailableDetails';
import { Row, Container, Col } from 'react-bootstrap';
import { RxCross1 } from 'react-icons/rx';
import CameraBitrateDetails from './AnalyticsDetails/CameraBitrateDetails';
import { useMemo } from 'react';
import OccupacyDetails from './AnalyticsDetails/OccupacyDetails';
import UserLoginDetials from './AnalyticsDetails/UserLoginDetials';
import { useOrganizations } from '../../store/OrganizationsStore';
import { useSelector } from 'react-redux';
import { getAllDevicesData } from '../../store/reducers/AccountReducer';
import { isObject } from 'lodash';
import { AppDefaults } from '../../helpers';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';


const ViewDetails = () => {
  let [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const location = useLocation();
  const orgId = searchParams.get('orgId');
  const getCustomerOrgData = useOrganizations(
    (state) => state.getCustomerOrgData
  );
  const custOrgData = orgId
    ? getCustomerOrgData().find((user) => user.orgId === orgId)
    : getCustomerOrgData()[0];
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const { getSelectedLocation } = useCustomerOrgLocations();
  const accountId = loggedInUserData?.accountId;

  let currentSelectedLocation = getSelectedLocation(accountId);
  const devicesDetails = useSelector(getAllDevicesData);
  const [devices, setDevices] = useState(location?.state?.devices ? location?.state?.devices : []);


  const navigate = useNavigate();

  const getCurrentLocationDevices = () => {
    const orgDevice = devicesDetails;
    const location = currentSelectedLocation;
    if (isObject(location)) {
      const devicesList =
        location?.locationId === AppDefaults.ALL_LOCATIONS_ID
          ? orgDevice?.filter((device) => device?.deviceStatus === 'CLAIMED')
          : orgDevice?.filter(
              (device) =>
                device?.locationId === location?.locationId &&
                device?.deviceStatus === 'CLAIMED'
            );
      return devicesList;
    } else {
      return orgDevice?.filter((device) => device?.deviceStatus === 'CLAIMED');
    }
  };

  useEffect(() => {
    const deviceList = getCurrentLocationDevices();
    if (!devices?.length && deviceList?.length > 0) {
      setDevices(deviceList);
    } 
  }, []);

  const columnsData = useMemo(
    () => [
      {
        Header: 'Month, Year',
        accessor: 'monthYear',
        Cell: (props) => {
          if (props.value) {
            return <div>{props.value}</div>;
          } else return '-';
        },
      },
      {
        Header: 'Location, Area',
        accessor: 'locationArea',
        Cell: (props) => {
          if (props.value) {
            return <div>{props.value}</div>;
          } else return '-';
        },
      },
      {
        Header: 'Camera',
        accessor: 'deviceName',
        Cell: (props) => {
          if (props.value) {
            return <div>{props.value}</div>;
          } else return '-';
        },
      },
      {
        Header:
          id == 1
            ? 'Online Percentage'
            : id == 2
            ? 'Minutes Offline'
            : id == 3
            ? 'Average Bitrate'
            : id == 4 && 'People Count',
        accessor: 'percentageOnline',
        Cell: (props) => {
          if (props.value) {
            return <div>{props.value}</div>;
          } else return '-';
        },
      },
    ],
    []
  );

  const columnsDataLogin = useMemo(
    () => [
      {
        Header: 'Month, Year',
        accessor: 'monthYear',
        Cell: (props) => {
          if (props.value) {
            return <div>{props.value}</div>;
          } else return '-';
        },
      },
      {
        Header: 'Name',
        accessor: 'userName',
        Cell: (props) => {
          if (props.value) {
            return <div>{props.value}</div>;
          } else return '-';
        },
      },
      {
        Header: 'Login Count',
        accessor: 'userCount',
        Cell: (props) => {
          if (props.value) {
            return <div>{props.value}</div>;
          } else return '-';
        },
      },
    ],
    []
  );

  return (
    <div className="App analytics">
      <div className="main-wrapper">
        <div className="analytics-background">
          <RxCross1
            className="icon-cross-btn"
            size={25}
            onClick={() =>
              navigate(`/reports.html?orgId=${custOrgData?.orgId}`)
            }
          />
          <Container className='mw-100'>
            <Row className="justify-content-md-center">
              <Col xs lg="12">
                <Row className="analytics-wrapper">
                  <div className="analytics-container">
                    {/* Retail Performance */}
                    {(id == 1 || id == 2) && (
                      <CameraAvailableDetails
                        custOrgData={custOrgData}
                        devices={devices}
                        id={id}
                        columnsData={columnsData}
                      />
                    )}
                    {id == 3 && (
                      <CameraBitrateDetails
                        custOrgData={custOrgData}
                        devices={devices}
                        columnsData={columnsData}
                      />
                    )}
                    {id == 4 && (
                      <OccupacyDetails
                        custOrgData={custOrgData}
                        devices={devices}
                        columnsData={columnsData}
                      />
                    )}
                    {id == 5 && (
                      <UserLoginDetials
                        custOrgData={custOrgData}
                        devices={devices}
                        columnsData={columnsDataLogin}
                      />
                    )}
                  </div>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ViewDetails;
