// import { useState } from 'react'; TODO : use later
import { useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
// import { SiteToast } from './'; TODO : use later
import { RxCross1 } from 'react-icons/rx';

function SiteModal({
  modalFooter = '',
  modalTitle = '',
  modalHeader = false,
  isFromLocation = false,
  shouldScrollToTop = false,
  showModal,
  hideModal,
  classes,
  children,
  errorMsg,
  wrapperClass,
  size,
  ...props
}) {
  // TODO - will work on this after demo 2 for showing error messages globally
  // const [errorMsgData, setErrorMsgData] = useState(errorMsg);
  const modalBodyRef = useRef();

  useEffect(() => {
    const modalBody = modalBodyRef.current;
    // Ensure the modal body is scrolled to the top by default
    if (modalBody && shouldScrollToTop) {
      modalBody.scrollTop = 0;
    }
  }, [shouldScrollToTop]);

  return (
    <div className={`modal-wrapper${wrapperClass ? ' ' + wrapperClass : ''}`}>
      <Modal
        show={showModal?.toString() === 'true' ? showModal : false}
        onHide={hideModal}
        size={size || 'md'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        className={classes}
      >
        {!modalHeader && (
          <Modal.Header>
            <Modal.Title
              className={`modal-title${isFromLocation ? ' location-font' : ''}`}
            >
              {modalTitle}
            </Modal.Title>
            <RxCross1
              className="site-modal-close"
              size={20}
              onClick={hideModal}
            />
          </Modal.Header>
        )}
        <Modal.Body ref={modalBodyRef}>{children}</Modal.Body>
        {modalFooter && <Modal.Footer>{modalFooter}</Modal.Footer>}
      </Modal>
      {/* TODO - will work on this after demo 2 for showing error messages globally 
       <SiteToast title="Error" show={!!errorMsg} body={errorMsg} onDelay={() => } /> */}
    </div>
  );
}

export default SiteModal;
