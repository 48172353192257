import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Col, Container, Row } from 'react-bootstrap';
import { Card, CardContent, OutlinedInput } from '@mui/material';

import axios from 'axios';

import { Header, PrimaryButton } from '../../components/common';
import { AppDefaults, constants, Utils } from '../../helpers';

import {
  HiOutlineLockClosed,
  HiOutlineExclamationCircle,
  HiOutlineEye,
  HiOutlineEyeOff,
} from 'react-icons/hi';

import './AccessClip.scss';

const AccessClip = () => {
  const [showLoading, setShowLoading] = useState(false);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showErrorIcon, setShowErrorIcon] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [responseReceived, setResponseReceived] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [apiResponseContent, setApiResponseContent] = useState('');
  const [errorTitle, setErrorTitle] = useState(
    constants.CLIP_LANDING_PAGE_CLIP_NO_LONGER_EXISTS_TITLE
  );
  const [errorMessage, setErrorMessage] = useState(
    constants.CLIP_LANDING_PAGE_CLIP_NO_LONGER_EXISTS_MESSAGE
  );
  const [loginAttempts, setLoginAttempts] = useState(3);

  const navigate = useNavigate();
  const passwordInputRef = useRef();
  const [searchParams] = useSearchParams();
  const senderFirstName = searchParams.get('senderName');
  const orgId = searchParams.get('orgId');
  const orgName = searchParams.get('orgName');
  const isExternal = searchParams.get('isExternal');
  const clipId = searchParams.get('clipId');
  const oai = searchParams.get('oai');
  const deviceId = searchParams.get('deviceId');
  const startTime = searchParams.get('startTime');
  const clipStatus = searchParams.get('clipStatus');
  const timezone = searchParams.get('timezone');
  const shareId = searchParams.get('shareId');
  const tenantId = searchParams.get('tenantId');

  const togglerPasswordVisibility = () => {
    let inputRef = passwordInputRef.current;

    const type =
      inputRef.getAttribute('type') === 'password' ? 'text' : 'password';
    inputRef.setAttribute('type', type);

    setShowPassword((prev) => !prev);
  };

  const handleButtonClick = async () => {
    try {
      if (!orgId || !clipId || !shareId || !tenantId) {
        throw new Error('Missing Required Parameters');
      }

      const appID =
        !process.env.REACT_APP_PROJECT && !AppDefaults.PROJECT_MEGATRON
          ? 'vms'
          : process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON
          ? 'oncloud'
          : 'vms';

      const reqBody = {
        password: password,
      };

      const reqHeaders = {
        headers: {
          ...Utils.requestHeader(),
          'X-App-Id': appID,
          'X-tenant-Id': tenantId,
        },
      };

      setShowLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}clip/orgs/${orgId}/clips/${clipId}/share/${shareId}/signedURL`,
        reqBody,
        reqHeaders
      );

      const responseData = res?.data;
      const responseCode = responseData?.meta?.code;

      if (responseCode === 200) {
        // Show the video clip
        setVideoUrl(responseData?.data?.url);
      } else {
        if (responseCode === 3203) {
          // Incorrect Password
          setLoginAttempts((prev) => prev - 1);
          setShowPasswordError(true);
        } else {
          setLoginAttempts(3);
          setShowPasswordError(false);
          setResponseReceived(true);

          switch (responseCode) {
            case 3226: // Clip doesn't exist
              setErrorTitle(
                constants.CLIP_LANDING_PAGE_CLIP_NO_LONGER_EXISTS_TITLE
              );
              setErrorMessage(
                constants.CLIP_LANDING_PAGE_CLIP_NO_LONGER_EXISTS_MESSAGE
              );
              break;

            case 3228:
              setErrorTitle(constants.CLIP_LANDING_PAGE_LINK_EXPIRED_TITLE);
              setErrorMessage(constants.CLIP_LANDING_PAGE_LINK_EXPIRED_MESSAGE);
              break;

            default:
          }
        }

        if (res?.code) {
          console.error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          console.error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowLoading(false);
    }
  };

  const videoContent = (
    <div className="video-clip-container">
      <video controls>
        <source src={videoUrl} type="video/mp4" />
      </video>
    </div>
  );

  const defaultContent = (
    <CardContent>
      <div className="access-clip-card-title">
        {Utils.replaceStringWithKeyValueArray(
          constants.CLIP_LANDING_PAGE_ACCESS_CLIP_TITLE,
          [
            {
              key: '$firstName',
              value: senderFirstName,
            },
            {
              key: '$orgName',
              value: orgName,
            },
          ]
        )}
      </div>
      <div className="access-clip-card-message">
        <div>{constants.CLIP_LANDING_PAGE_ACCESS_CLIP_BODY_CONTENT1}</div>
        <div>{constants.CLIP_LANDING_PAGE_ACCESS_CLIP_BODY_CONTENT2}</div>
        {showPasswordError && (
          <div className="access-clip-card-password-error-message">
            <HiOutlineExclamationCircle
              className="icon-exclamation"
              size={'1.4rem'}
            />
            {Utils.replaceStringValues(
              constants.CLIP_LANDING_PAGE_ACCESS_CLIP_PASSWORD_ATTEMPT_MESSAGE,
              '$numOfAttempts',
              loginAttempts
            )}
          </div>
        )}
        <div className="access-clip-card-input-wrapper">
          <OutlinedInput
            className="password-input-field"
            inputRef={passwordInputRef}
            placeholder="Password"
            type="password"
            required={true}
            error={showPasswordError}
            inputComponent="input"
            startAdornment={
              <HiOutlineLockClosed
                className="icon-lock"
                color={'#7D8BA1'}
                size={'24px'}
              />
            }
            endAdornment={
              showPassword ? (
                <HiOutlineEye
                  className="icon-eye"
                  color={'#7D8BA1'}
                  size={'24px'}
                  onClick={(e) => {
                    e?.preventDefault();
                    togglerPasswordVisibility();
                  }}
                />
              ) : (
                <HiOutlineEyeOff
                  className="icon-eye"
                  color={'#7D8BA1'}
                  size={'24px'}
                  onClick={(e) => {
                    e?.preventDefault();
                    togglerPasswordVisibility();
                  }}
                />
              )
            }
            onChange={(e) => {
              const value = e.target.value;
              if (value) {
                setPassword(value);
              }
            }}
            fullWidth
          />
        </div>
        <div className="access-clip-card-button-wrapper">
          <PrimaryButton
            loader={showLoading}
            fontSize={'18px'}
            fontWeight={600}
            height={'56px'}
            onClick={() => {
              handleButtonClick();
            }}
          >
            {constants.CLIP_LANDING_PAGE_ACCESS_CLIP_BUTTON_LABEL}
          </PrimaryButton>
        </div>
      </div>
    </CardContent>
  );

  // 'content' input parameter can be a string or JSX
  const responseContent = (
    <CardContent className="access-clip-card-content">
      {showErrorIcon && (
        <div className="access-clip-card-alert-icon">
          <HiOutlineLockClosed
            className="icon-lock"
            color={'#C92C2C'}
            size={'32px'}
          />
        </div>
      )}
      <div className="access-clip-card-error-title">{errorTitle}</div>
      <div className="access-clip-card-error-message">{errorMessage}</div>
    </CardContent>
  );

  useEffect(() => {
    // We should not be landing here if the invitee is an
    // internal user, so this is just a safety net.  Internal
    // users should be taken directly to the Clip Details
    // page route
    if (
      (typeof isExternal === 'string' &&
        isExternal.toLowerCase() === 'false') ||
      (typeof isExternal === 'boolean' && isExternal === false)
    ) {
      navigate(`/clips/details.html?o=email&clipId=${clipId}`);
    }
  }, []);

  if (videoUrl) {
    return videoContent;
  }

  return (
    <div className="App access-clip">
      <Header showNavigation={false} />
      <Container>
        <Row>
          <Col>
            <div className="access-clip-card-container">
              <Card className="access-clip-card">
                {responseReceived ? responseContent : defaultContent}
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AccessClip;
