import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  People,
  CarFront,
  Sunglasses,
  Dropbox,
  TrainFront,
  Clock,
} from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { indexClosest, Utils } from '../../helpers';
import { getAllDevicesData } from '../../store/reducers/AccountReducer';
import ImageFetch from '../cameras/components/ImageFetch';
import timezones from '../../data/support/timezone.json';
import { useOrganizations } from '../../store/OrganizationsStore';

const SearchEventsWrapper = ({
  data,
  cdnValue,
  timezone,
  selectedEvent,
  handleOnClick,
  handleNextPreviousIcon,
}) => {
  const [eventData, setEventData] = useState([]);
  const allDevices = useSelector(getAllDevicesData);
  const getCustomerOrgData = useOrganizations(
    (state) => state.getCustomerOrgData
  );
  let custOrg = getCustomerOrgData();
  const custOrgData = custOrg[0];
  const [indexEvent, setIndexEvent] = useState(null);

  const fetchIcon = (obj) => {
    switch (obj) {
      case 'person':
      case 'top':
      case 'hat':
      case 'bottom':
      case 'face':
        return (
          <div className="event-image">
            <People height="16" width="16" />
          </div>
        );
      case 'car':
      case 'vehicle':
      case 'truck':
      case 'motorcycle':
      case 'bicycle':
        return (
          <div className="event-image">
            <CarFront height="16" width="16" />
          </div>
        );
      case 'glasses':
        return (
          <div className="event-image">
            <Sunglasses height="16" width="16" />
          </div>
        );
      case 'train':
        return (
          <div className="event-image">
            <TrainFront height="16" width="16" />
          </div>
        );
      default:
        return (
          <div className="event-image">
            <Dropbox height="16" width="16" />
          </div>
        );
    }
  };

  const fetchDeviceInfo = (deviceId) => {
    const info = {
      deviceName: null,
      locationName: null,
      areaName: null,
      deviceId: deviceId,
      timezone: moment.tz.guess(),
    };
    if (allDevices && allDevices.length) {
      const device = allDevices?.filter((dev) => dev.deviceId === deviceId);
      const deviceTimeZone = custOrgData?.devices?.find((dev) => dev?.deviceId === device[0]?.parentId);
      if (device && device?.length) {

        info.deviceName = device[0]?.deviceName;
        info.locationName = device[0]?.locationName;
        info.timezone = deviceTimeZone?.properties?.timezone;
        const area = device[0]?.areas?.filter(
          (item) => item.isDefault === true
        );
        if (area && area.length) {
          info.areaName = area[0]?.areaName;
        }
      }
    }
    return [info];
  };

  const getLocationTimezone = (timezone) => {
    const timezoneLocation = timezones?.data?.find(
      (zone) => zone.value === timezone
    );
    return timezoneLocation?.location;
  };

  useEffect(() => {
    let result = eventData?.map((a, i) => i);
    const data = indexClosest(result, indexEvent);
    if (data) {
      const nextIndex = data?.dataAfter[0]?.date;
      const previousIndex = data?.dataBefore[0]?.date;
      if (handleNextPreviousIcon === 'next') {
        setIndexEvent(nextIndex);
        handleOnClick(eventData[nextIndex], nextIndex);
      }
      if (handleNextPreviousIcon === 'previous') {
        setIndexEvent(previousIndex);
        handleOnClick(eventData[previousIndex], previousIndex);
      }
    }
  }, [handleNextPreviousIcon, JSON.stringify(eventData)]);

  useEffect(() => {
    setEventDataFillter(data)
  }, [data]);

  const setEventDataFillter = async (data) => {
    let allDataEvents = [];

    data?.events?.map((insideEvent) => {
      insideEvent?.eventMeta?.events?.map((actualEvent) => {
        const eDataEvents = {
          actualEventData: actualEvent?.event,
          timestamp: insideEvent?.eventTimestamp,
          device: fetchDeviceInfo(insideEvent.deviceId),
        };
        allDataEvents.push(eDataEvents);
      });
    });
    const listData = allDataEvents?.map((itemEvent) => {
      let deviceIdsString = '';
      itemEvent?.actualEventData?.map((actualEventDataEvent, index) => {
        deviceIdsString += `${actualEventDataEvent.eventClass}${
          itemEvent?.actualEventData?.length !== index + 1 ? ', ' : ''
        }`;
      });
      return { ...itemEvent, class: `${deviceIdsString}` };
    });
    let finalList = await Promise.all(listData);
    setEventData(finalList);
  };

  return (
    <div className="row">
      {eventData?.map((event, i) => {
        if (event) {
          return (
            <div
              key={i}
              className={`Col col-md-4 ${
                selectedEvent === event.timestamp ||
                selectedEvent ===
                  Utils.getUnixDate(Utils.getDate(event.timestamp / 1000)) *
                    1000
                  ? 'selectedEvent'
                  : ''
              } event-wrapper-list`}
              onClick={() => {
                setIndexEvent(i);
                handleOnClick(event, i);
              }}
            >
              <div className="event-list-image">
                <ImageFetch
                  time={event?.timestamp}
                  cdnValue={cdnValue}
                  deviceId={event?.device[0]?.deviceId}
                />
                <div className="event-image-time">
                  <Clock height="12" width="12" />
                  {moment
                    .tz(
                      moment(event.timestamp),
                      getLocationTimezone(event?.device[0]?.timezone)
                    )
                    .format('hh:mm:ss A z')}
                </div>
              </div>
              <div className="event-list-content">
                {fetchIcon(Utils.getObjectClassEvents(event.class))}
                <div className="event-content-list-wrapper">
                  <div className="event-name">{event.device[0].deviceName}</div>
                  <div className="event-timestamp">
                    {`${event.device[0].areaName} - ${event.device[0].locationName}`}
                  </div>
                </div>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default SearchEventsWrapper;
