import React, { useEffect, useMemo, useState } from 'react';
import { constants, roles, Utils } from '../../helpers';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import {
  BreadcrumbList,
  Header,
  PrimaryButton,
  SiteModal,
} from '../../components/common';
import { useSearchParams } from 'react-router-dom';
import { useOrganizations } from '../../store/OrganizationsStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import PageWrapper from '../PageWrapper';
import { Col, Container, Row } from 'react-bootstrap';
import { RxPlus } from 'react-icons/rx';
import RoleListTable from './RoleListTable';
import './customRoles.scss';
import { HiUserCircle } from 'react-icons/hi2';
import NewRole from './NewRole';
import { useKeycloak } from '@react-keycloak/web';
import useCustomRoles from '../../store/CustomRolesStore';
import { usePoliciesStore } from '../../store/policiesStore';
import { useCustomerOrgUsers } from '../../store/CustomerOrgUsersStore';
import { orgUserData } from '../../store/reducers/AccountReducer';

const ManageRoles = () => {
  const { keycloak } = useKeycloak();
  const token = keycloak.token;
  const [openCreateModel, setOpenCreateModel] = useState(false);
  const [customRole, setCustomRole] = useState([]);
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const customerOrgData = useOrganizations((state) => state.customerOrgData);
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData
  );
  const loggedInUser = getLoggedInUserData();
  const orgData = customerOrgData?.find((org) => org.orgId === orgId);
  const orgNameBread = orgData?.orgName;
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies
  );
  const { getCustomRoleEntitlements, roleList, getCustomRoleList } =
    useCustomRoles();
  const setCustomerOrgUsers = useCustomerOrgUsers(
    (state) => state.setCustomerOrgUsers
  )
  const dispatch = useDispatch();
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    getCustomRoleEntitlements(`/partner/orgs/${orgId}/groupPolicies`);
  }, []);

  useEffect(() => {
    getCustomRoleList(`partner/orgs/${orgId}/roles`);
  }, []);

  useEffect(() => {
    if (roleList.length > 0) {
      const mergedArr = [];
      if (userData?.length > 0) {
        roleList.forEach((role) => {
          let obj = {
            ...role,
            totalAssignees: 0
          };
          const assignedRoles = userData.filter(
            (user) =>
              user.role === role.roleId &&
              loggedInUser?.accountId !== user?.accountId
          );
          if (assignedRoles.length > 0) {
            obj.totalAssignees = assignedRoles.length
          }

          if (role.roleId !== roles.ROLE1VMS) {
            mergedArr.push(obj);
          }
        })
      }
      setCustomRole(mergedArr);
    }
  }, [roleList, userData]);

  useEffect(() => {
    fetchUser();
  }, [roleList]);

  const fetchUser = async () => {
    try {
      await axios
        .get(`partner/orgs/${orgId}/accounts`, Utils.requestHeader())
        .then((response) => {
          let resultData = response?.data?.data;
          setUserData(resultData);
          setCustomerOrgUsers(resultData);
          dispatch(orgUserData(resultData));
        });
    } catch (error) {
      // TODO : to print the error will update this later
      console.error(error);
    }
  };

  const breadList = [
    {
      url: '/customers/manage.html',
      title: loggedInUser.role !== roles.ROLE2VMS ?  
            constants.MANAGE_ORG_PAGE_TITLE : 
            constants.MANAGE_ORG_PAGE_ORG_TITLE,
    },
    {
      url: `/customers/dashboard.html?orgId=${orgId}`,
      title: orgNameBread,
    },
    {
      url: `/roles/manage.html?orgId=${orgId}`,
      title: constants.ORG_LABELS_ROLES,
    },
  ];

  const columnsData = useMemo(() => [
    {
      Header: constants.MANAGE_ROLE_TABLE_HEADER,
      accessor: 'roleName',
      maxWidth: 200,
      minWidth: 50,
      width: 180,
      Cell: (props) => {
        const isCustomRole = props?.row?.original?.customRole;
        return (
          <>
            <div className="role-column">
              <HiUserCircle style={{ height: '20px', width: '20px' }} />
              {!isCustomRole && props.value === 'cust_org_admin'
                ? constants.ADMIN_USER_CUSTOM_ROLE_LABEL
                : !isCustomRole && props.value === 'cust_org_user'
                  ? constants.NORMAL_USER_ROLE_LABEL
                  : !isCustomRole && props.value === 'cust_org_installer'
                    ? constants.INSTALLER_USER_ROLE_LABEL
                    : props.value}
              {props.value === 'cust_org_admin' ||
                props.value === 'cust_org_user' ||
                props.value === 'cust_org_installer' ? (
                <span className="default-label">{constants.DEFAULT_LABEL}</span>
              ) : null}
            </div>
          </>
        );
      },
      sortType: 'basic',
    },
    {
      Header: constants.TOTAL_ASSIGNEE_TEXT,
      accessor: 'totalAssignees',
      maxWidth: 200,
      minWidth: 50,
      width: 180,
      Cell: (props) => {
        return props.value;
      },
      sortType: 'basic',
    },
  ]);

  const fetchData = async (token) => {
    getCustomRoleList(`partner/orgs/${orgId}/roles`);
  };

  return (
    <div className="App">
      <Header breadcrumbData={breadList} />

      <PageWrapper className="mw-100">
        <div className="page-header">
          <Container className='mw-100'>
            <Row>
              <Col className="text-start">
                <BreadcrumbList list={breadList} />
              </Col>
            </Row>
            <Row>
              <Col
                md={6}
                lg={6}
                xl={6}
                xs={12}
                className="page-title text-start"
              >
                {constants.ORG_LABELS_ROLES}
              </Col>
              {getCustomerOrgPolicies()?.create_custom_roles ? (
                <Col
                  md={6}
                  lg={6}
                  xl={6}
                  xs={12}
                  className="text-end page-header-buttons"
                >
                  <PrimaryButton
                    className="btn btn-primary"
                    type="button"
                    width="auto"
                    onClick={() => {
                      setOpenCreateModel(true);
                    }}
                  >
                    <RxPlus
                      className="plus-icon-btn"
                      strokeWidth={'1.5px'}
                      size={13.33}
                    />
                    {constants.CREATE_NEW_ROLE}
                  </PrimaryButton>
                </Col>
              ) : null}
            </Row>
          </Container>
        </div>
        <div className="list-block">
          <Container className="h-100 mw-100">
            <RoleListTable
              columns={columnsData}
              data={customRole}
            />
          </Container>
        </div>
      </PageWrapper>

      <SiteModal
        modalTitle={constants.CREATE_NEW_ROLE_MODEL_TITLE}
        showModal={openCreateModel}
        hideModal={() => setOpenCreateModel(false)}
      >
        <NewRole
          reloadData={() => {
            fetchData(token);
          }}
          hideModal={() => setOpenCreateModel(false)}
        />
      </SiteModal>
    </div>
  );
};

export default ManageRoles;
