import React from 'react';
import { constants } from '../../helpers';
import { HiUserCircle } from 'react-icons/hi2';
import { Col, Form } from 'react-bootstrap';
import {
  AssignLocations,
  SelectField,
  TextField,
  ToggleInput,
} from '../../components/forms';
import { Formik } from 'formik';
import { FaCircle } from 'react-icons/fa';
import { Avatar } from '../../components/common';

const ViewUserDetails = (props) => {
  const initialValues = {
    firstName: props?.userDetails?.firstName,
    lastName: props?.userDetails?.lastName,
    phoneNumber: props?.userDetails?.phoneNumber,
    email: props?.userDetails?.email,
    role: props?.userDetails?.role,
    externalShare: props?.userDetails?.canShareAndDownload ?? false,
    location: props?.userDetails?.location,
  };
  return (
    <>
      <div className="text-start mb-2 account-profile-icon">
        {props?.userDetails?.accountStatus === 'ACTIVE' && props?.userDetails?.isLocked ? (
          <>
            <Col className="text-start mb-4">
              <span className="activeIcon">
                <FaCircle size={10} className="status-icon-offline" />
                {constants.LOCKED_ACCOUNT_TEST}
              </span>
            </Col>
          </>
        ) : props?.userDetails?.accountStatus === 'ACTIVE' ? (
          <Col className="text-start mb-4">
            <span className="activeIcon">
              <FaCircle size={10} className="status-icon-active" />{' '}
              {constants.EDIT_CUSTOMER_ACTIVE_STATUS_TITLE}
            </span>
          </Col>
        ) : props?.userDetails?.accountStatus === 'PENDING_ACTIVATION' ? (
          <>
            <Col className="text-start mb-4">
              <span className="activeIcon">
                <FaCircle size={10} className="status-icon-invited" />{' '}
                {constants.EDIT_CUSTOMER_INACTIVE_STATUS_TITLE}
              </span>
            </Col>
          </>
        ) : (
          <Col className="text-start mb-4">
            <span className="activeIcon">
              <FaCircle size={10} className="status-icon-disabled" />{' '}
              {constants.EDIT_CUSTOMER_INACTIVE_STATUS_TITLE}
            </span>
          </Col>
        )}
        {props?.userDetails?.image?.url ? (
          <Avatar
            valueType="icon"
            value={props?.userDetails?.image?.url}
            size="extraLarge"
            avatarStyle="roundedCircle"
          />
        ) : (
          <HiUserCircle size={88} />
        )}
      </div>
      <Formik initialValues={initialValues} enableReinitialize={false}>
        <Form className="modal-form">
          <>
            <TextField
              required={true}
              removebottommargin="true"
              removetopmargin="true"
              label=""
              placeholder={constants.FIRST_NAME_FIELD}
              name="firstName"
              type="text"
              disabled={true}
            />
            <TextField
              required={true}
              removebottommargin="true"
              removetopmargin="true"
              label=""
              placeholder={constants.LAST_NAME_FIELD}
              name="lastName"
              type="text"
              disabled={true}
            />
            <TextField
              removebottommargin="true"
              removetopmargin="true"
              label=""
              placeholder={constants.PHONE_NUMBER_FIELD}
              name="phoneNumber"
              type="text"
              disabled={true}
            />
            <TextField
              required={true}
              removebottommargin="true"
              removetopmargin="true"
              label=""
              placeholder={constants.EMAIL_ADDRESS_FIELD}
              name="email"
              type="text"
              disabled={true}
            />
            <SelectField
              name="role"
              key="role"
              options={props.roleOption}
              defaultOptionLabel={constants.NEW_USER_SELECT_ROLE_DROPDOWN_DEFAULT_LABEL}
              disabled={true}
              isFromOrg={true}
            />
            <div className='toggledisable'>
              <ToggleInput
                label={constants.CUSTOM_ROLE_EXTERNAL_TOGGLE_TEXT}
                name="externalShare"
                value={initialValues?.externalShare}
                disabled={true}
              />
            </div>
            <AssignLocations
              hideIcon={true}
              disabled={true}
              label={constants.SIDEBAR_LOCATION_AND_AREAS_LABEL}
            />
          </>
        </Form>
      </Formik>
    </>
  );
};

export default ViewUserDetails;
