import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import axios from 'axios';
import { Utils } from '../helpers';

export const useAppsStore = create(
    persist(
        (set, get) => ({
            appList: [],
            getAppsData: () => {
                return get().appList;
            },
            getApps: async (route) => {
                if (!route) return;

                const res = await axios.get(
                  route,
                  Utils.requestHeader()
                );
                const response = res?.data;
                let result = { status: 'failure', msg: 'Unknown internal API error' };

                // Set appList if request is successful
                if (response?.meta?.code === 200) {
                  const sortedAppList = response?.data?.apps?.sort((a, b) => a.oid - b.oid);
                  set({
                    appList: [...sortedAppList],
                  });
                  result = { status: 'success', msg: 'Apps retrieved`' };
                } else {
                  if (res?.code) {
                    result = { status: 'failure', msg: `${res.code}: ${res.message}` };
                  } else if (response?.data) {
                    result = { status: 'failure', msg: response?.data?.userMsg };
                  }
                }
                return result;
              },
        }),
        {
            name: 'net.duclo.vms.policies',
            version: '1.0',
        }
    )
);
