import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Utils } from '../../helpers';
import { SiteSpinner } from '../../components/common';

function ExportIncidentBundle() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showLoader, setShowLoader] = useState(true);
  let bundleId = searchParams.get('bundleId');
  let orgId = searchParams.get('orgId');
  let incidentId = searchParams.get('incidentId');

  const apiCallInvitation = async () => {
    let response = await axios.get(
      `incident/orgs/${orgId}/incidents/${incidentId}/bundle/${bundleId}`,
      Utils.requestHeader()
    );
    const responseData = response?.data;
    if (responseData?.meta?.code === 200) {
      localStorage.removeItem('incidentExport');
      localStorage.setItem('incidentExportUrl', responseData?.data?.url);
      navigate(`/customers/manage.html`);
    } else {
      localStorage.setItem('incidentExportUrl', '0');
      localStorage.removeItem('incidentExport');
      navigate(`/customers/manage.html`);
    }
  };

  useEffect(() => {
    const tokenVms = localStorage.getItem('vmsAuthToken');
    const incidentExport = localStorage.getItem('incidentExport');
    if (bundleId != null) {
      const incidentExport = {
        bundleId: bundleId,
        orgId: orgId,
        incidentId: incidentId,
      };
      localStorage.setItem('incidentExport', JSON.stringify(incidentExport));
    }
    if (Utils.detectBrowser()) {
      localStorage.setItem('vmsAuthToken', null);
      setShowLoader(false);
      navigate(`/`);
      return;
    }
    if (tokenVms == 'null' || tokenVms == 'undefined') {
      setShowLoader(false);
      navigate(`/`);
    } else if (incidentExport && tokenVms) {
      apiCallInvitation();
    }
  }, []);

  return (
    <div className="App">
      {showLoader && (
        <div className="app-margin-patner-invite">
          <SiteSpinner height="100px" width="100px"></SiteSpinner>
        </div>
      )}
    </div>
  );
}

export default ExportIncidentBundle;
