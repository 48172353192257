import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MuuriComponent } from 'muuri-react';
import 'moment-timezone';
import {
  getLiveCameraIds,
  setLiveCameraIds,
} from '../../../../store/reducers/StreamingReducer';
import { getAllDevicesData } from '../../../../store/reducers/AccountReducer';
import LiveCamera from '../live/LiveCamera';
import '../../../../assets/css/timeline.scss';
import { useRef } from 'react';

const MultiControlsLive = ({ muteAudio, onClick }) => {
  const liveCameraIds = useSelector(getLiveCameraIds);
  const devicesData = useSelector(getAllDevicesData);
  const dispatch = useDispatch();
  const gridRef = useRef();

  const getDeviceInfo = (deviceId) => {
    if (devicesData?.length) {
      const deviceData = devicesData.filter(
        (device) => device.deviceId === deviceId
      );
      return `${deviceData[0]?.deviceName} - ${deviceData[0]?.locationName} - ${deviceData[0]?.areas?.[0]?.areaName}`;
    }
  };

  const getHubInfo = (deviceId) => {
    if (devicesData?.length) {
      const deviceData = devicesData.filter(
        (device) => device.deviceId === deviceId
      );
      return `${deviceData[0]?.gatewayId}`;
    }
  };

  const handleDragEnd = () => {
    if (gridRef.current) {
      const reorderedCameraIds = gridRef.current
        .getItems()
        .map((elem) => elem.getKey());
      dispatch(setLiveCameraIds(reorderedCameraIds));
    }
  };

  return (
    <div className="multi-live-camera-wrapper grid-container">
      {liveCameraIds?.length > 0 && (
        <MuuriComponent
          ref={gridRef}
          dragEnabled={liveCameraIds.length > 1}
          instantLayout
          //dragContainer={document.body}
          columnRef="swap"
          layout={{
            fillGaps: false,
            horizontal: false,
            alignRight: false,
            alignBottom: false,
            rounding: true,
          }}
          onDragEnd={handleDragEnd}
        >
          {liveCameraIds.map((liveCameraId, index) => (
            <div key={liveCameraId} className="liveone-wrapper grid-item">
              <LiveCamera
                layout={index + 1}
                deviceName={getDeviceInfo(liveCameraId)}
                device={liveCameraId}
                hubId={getHubInfo(liveCameraId)}
                muteAudio={muteAudio}
                onLiveClick={() => onClick(liveCameraId)}
              />
            </div>
          ))}
        </MuuriComponent>
      )}
    </div>
  );
};

export default MultiControlsLive;
