import { useEffect, useState } from 'react';
import './stepspos.scss';
import { Utils, constants } from '../../../helpers';
import { PrimaryButton } from '../../../components/common';
import { useSearchParams } from 'react-router-dom';
import { warningroundwhite } from '../../../assets/images';
import { RxCross1 } from 'react-icons/rx';

const UploadWarning = (props) => {
  return (
    <div className="pos-integration">
      <div className="steps-header-conatiner">
        <div className="header-container">
          <div className="left-section-layout">
            <div className="header-title">
              {constants.POS_UPLOAD_POS_WARNING}
            </div>
          </div>
          <RxCross1 size={24} onClick={() => props.hideModal()} />
        </div>
      </div>
      <div className={`manage-pos-height`}>
        <div className="org-map-setup-content mt-3">
          <img className="mt-4" src={warningroundwhite} alt="" />
          <div className="title-setup-completed">
            {constants.POS_UPLOAD_POS_WARNING_MESSAGE}
          </div>

          {
            <PrimaryButton
              className="btn btn-outline-danger mt-4"
              type="button"
              height="44px"
              fontSize="0.875rem"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_primary')}
              borderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_primary')}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              borderWidth="1.5px"
              hoverBorderWidth="1.5px"
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_32')}
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_32')}
              onClick={() => {
                props.uploadStart();
              }}
            >
              {constants.DEVICES_CONTINUE_TEXT}
            </PrimaryButton>
          }
        </div>
      </div>
    </div>
  );
};
export default UploadWarning;
