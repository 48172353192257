import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { TextField } from '../../components/forms';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  CssBaseline,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
} from '@mui/material';

import IconButton from '@mui/material/IconButton';

import { HiArrowDownTray } from 'react-icons/hi2';
import { PiQuestionLight } from 'react-icons/pi';
import { FiCameraOff } from 'react-icons/fi';
import { TbTrash } from 'react-icons/tb';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { HiOutlinePencil } from 'react-icons/hi';
import { HiOutlineCheckCircle } from 'react-icons/hi';
import { MdOutlineAddAPhoto } from 'react-icons/md';
import { editViewButton } from '../../assets/images';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { ReactComponent as CameraIcon } from '../../assets/images/icons/Camera.svg';
import { ReactComponent as CameraDisabled } from '../../assets/images/icons/CameraDisabled.svg';
import { ReactComponent as VideoCameraIcon } from '../../assets/images/icons/VideoCamera.svg';
import { ReactComponent as VideoCameraIconDisabled } from '../../assets/images/icons/VideoCameraDisabled.svg';
import { ReactComponent as CheckedIcon } from '../../assets/images/CheckedBackgroundIcon.svg';
import { ReactComponent as CrossBackgroudIcon } from '../../assets/images/CrossBackgroudIcon.svg';

import axios from 'axios';
import {
  Header,
  PrimaryButton,
  SiteSpinner,
  SiteModal,
  SiteToast,
} from '../../components/common';
import { constants, Utils } from '../../helpers';

import { useDispatch, useSelector } from 'react-redux';

import { useOrganizations } from '../../store/OrganizationsStore';
import useEventsStore from '../../store/EventsStore';

import {
  getCDNInfo,
  getPlatformInfo,
  getWSSConnections,
  removeMQTTPeerConnections,
  removeRemoteStreams,
  removeRemoteStreamsAudio,
  setMetaData,
  getMQTTConnection,
  resetRecievedOffers,
  setPlatformInfo,
} from '../../store/reducers/StreamingReducer';
import { devicesMQTTStore } from '../../store/DevicesMQTTStore';

import { AppDefaults, IncidentTypeEnum } from '../../helpers/enums';

import './Cameras.scss';
import CameraWallDevices from './CameraWallDevices';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import {
  getIsEditModeActive,
  getSelectedView,
  getViewList,
  resetViewsData,
  setCurrentViewDeviceList,
  setIsEditModeActive,
  setIsLeftMenuOpen,
  setIsOnDeviceWallPage,
  setSelectedView,
  setViewList,
} from '../../store/reducers/ViewsReducer';
import DeviceWallTimeline from './components/DeviceWallTimeline';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import {
  getLatestEventsForDots,
  getMetaDataForEvents,
  resetEvents,
  setLatestEventsForDots,
  setMetaDataForEvents,
} from '../../store/reducers/EventsReducer';
import {
  disconnectWithMQTT,
  mqttSubscribe,
  mqttPublish,
  mqttUnsubscribe,
} from '../../utils/connection/mqttConnection';
import {
  connectWithWebSocket,
  disconnectWithWebSocket,
  sendPauseCVR,
} from '../multilive/components/playback/wssConnection/wssConnection';
import {
  getAllAreas,
  getAllDevicesData,
  setSelectedOrganization,
  setKeepAliveRequest,
  getKeepAliveRequest,
} from '../../store/reducers/AccountReducer';
import DeleteView from './modalForms/DeleteView';
import MaxViewDevices from './modalForms/MaxViewDevices';
import { useCustomerOrgUsers } from '../../store/CustomerOrgUsersStore';
import { useCustomerOrgDevices } from '../../store/CustomerOrgDevicesStore';
import DefaultPolicies from '../../store/reducers/DefaultPolicies';
import { usePoliciesStore } from '../../store/policiesStore';
import IdleTimer from 'react-idle-timer';
import { CiGrid41 } from 'react-icons/ci';
const drawerWidth = 224;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const Cameras = () => {
  const formikRef = useRef();
  let fullscreenRef = useRef(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handle = useFullScreenHandle();
  const [searchParams] = useSearchParams();
  const cdnInfo = useSelector(getCDNInfo);
  const viewList = useSelector(getViewList);
  const selectedView = useSelector(getSelectedView);
  const eventCategory = useEventsStore((state) => state.eventCategory);
  const eventsData = useSelector(getLatestEventsForDots);
  const deviceMetaData = useSelector(getMetaDataForEvents);
  const isEditModeActive = useSelector(getIsEditModeActive);
  const devicesDetails = useSelector(getAllDevicesData);
  const { getCustomerOrgDevices, getCameraWallDevices } =
    useCustomerOrgDevices();
  const customerOrgDevices = getCustomerOrgDevices();
  const childDevices = Utils.getTotalChildDevices(customerOrgDevices);
  const allAreasStore = useSelector(getAllAreas);
  const wssConnections = useSelector(getWSSConnections);
  const [allStartDate, setAllStartDate] = useState([]);
  const [showLocation, setShowLocation] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [categoryDashboard, setCategoryDashboard] = useState([]);
  const [open, setOpen] = useState(true);
  const [isOpenBeforeDragOver, setIsOpenBeforeDragOver] = useState(true);
  const [isDevicesOptionSelected, setIsDevicesOptionSelected] = useState(true);
  const [isAddNewViewClicked, setIsAddNewViewClicked] = useState(false);
  const [currentSession, setCurrentSession] = useState(uuidv4());
  const [editView, setEditView] = useState(null);
  const [selectedViewDevices, setSelectedViewDevices] = useState(
    selectedView?.devices?.length ? selectedView?.devices : []
  );
  const [totalDeviceCount, setTotalDeviceCount] = useState(0);
  const [isUpdatingView, setIsUpdatingView] = useState(false);
  const [isAddingView, setIsAddingView] = useState(false);
  const [isRenamingView, setIsRenamingView] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showMaxDevciesModal, setShowMaxDevciesModal] = useState(false);
  const [showJumpToDateModal, setShowJumpToDateModal] = useState(false);
  const [isDragged, setIsDragged] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [newViewDragMode, setNewViewDragMode] = useState(false);
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const accountId = loggedInUserData?.accountId;
  const getCustomerOrgData = useOrganizations(
    (state) => state.getCustomerOrgData
  );
  const getCustomerOrgUsers = useCustomerOrgUsers(
    (state) => state.getCustomerOrgUsers
  );

  const setCustomerOrgPolicies = usePoliciesStore(
    (state) => state.setCustomerOrgPolicies
  );
  const { getLoggedInUserPolicies } = usePoliciesStore();
  const userPolicies = getLoggedInUserPolicies();
  const setUserPushData = useOrganizations((state) => state.setUserPushData);
  const {
    getEventCVRMode,
    setSelectedEventStore,
    setEventFromSearch,
    setEventCVRMode,
  } = useEventsStore();
  const orgDetails = getCustomerOrgData()[0];
  const getPlatformDetails = useSelector(getPlatformInfo);
  const viewId = searchParams.get('viewId');
  const cameraWallDevices = getCameraWallDevices();
  const MQTTConnectionStatus = useSelector(getMQTTConnection);
  const { getState } = devicesMQTTStore;
  const state = getState();
  const keepAlive = useSelector(getKeepAliveRequest);
  let keepAliveTimer = null;
  const isTimerRunning = useRef(false);

  useEffect(()=>{
    const posOrgId = localStorage.getItem("posOrgId")
    if(posOrgId != null && posOrgId != 'null' && posOrgId != 'undefined' && posOrgId != ''){
      navigate(
        `/app/pos?orgId=${posOrgId}`
      );
    }
  })

  useEffect(() => {
    if (orgDetails?.orgId) dispatch(setSelectedOrganization(orgDetails));
  }, [orgDetails?.orgId]);

  useEffect(() => {
    setEditView(isEditModeActive);
  }, [isEditModeActive]);

  useEffect(() => {
    fetchSavedViews();
  }, [cameraWallDevices]);

  useEffect(() => {
    isTimerRunning.current = true;
    fetchOrgUsers();

    if (Array.isArray(getCustomerOrgData()) && getCustomerOrgData()[0]) {
      let policies = getCustomerOrgData()[0]?.policies;
      let updatedPoliciesData = Utils.mapUserPolicies(
        DefaultPolicies,
        policies
      );
      setCustomerOrgPolicies(updatedPoliciesData);
    }
    setCurrentSession(uuidv4());
    dispatch(setIsOnDeviceWallPage(true));

    if (wssConnections) {
      Object.keys(wssConnections).forEach((key) => {
        dispatch(removeRemoteStreams(key));
        dispatch(removeRemoteStreamsAudio(key));
        dispatch(removeMQTTPeerConnections(key));
        disconnectWithWebSocket(key);
      });
    }

    return () => {
      dispatch(resetViewsData());
      if (wssConnections) {
        Object.keys(wssConnections).forEach((key) => {
          dispatch(removeRemoteStreams(key));
          dispatch(removeRemoteStreamsAudio(key));
          dispatch(removeMQTTPeerConnections(key));
          disconnectWithWebSocket(key);
        });
      }
      isTimerRunning.current = false;
      dispatch(setKeepAliveRequest(false));
      cameraWallDevices?.forEach((cameraWallDevice) => {
        deleteRequest(cameraWallDevice);
      });
      clearTimeout(keepAliveTimer);
    };
  }, []);

  useEffect(() => {
    if (!wssConnections) {
      checkTokenSendWebSocketRequest();
    }
  }, [wssConnections]);

  useEffect(() => {
    const tempDevices = selectedView?.devices?.length
      ? selectedView?.devices
      : [];
    setSelectedViewDevices(tempDevices);
    const editViewItems =
      Utils.getRemainingItemsCount(tempDevices.length) || [];
    dispatch(setCurrentViewDeviceList([...tempDevices, ...editViewItems]));
  }, [selectedView?.devices]);

  useEffect(() => {
    setIsDevicesOptionSelected(!viewId);
  }, [viewId]);

  useEffect(() => {
    setCategoryDashboard(eventCategory);
  }, [eventCategory]);

  useEffect(() => {
    if (cameraWallDevices.length && MQTTConnectionStatus && !keepAlive) {
      cameraWallDevices?.forEach((cameraWallDevice) => {
        subscribeRequest(cameraWallDevice);
        dispatch(setKeepAliveRequest(true));
      });
    }
  }, [MQTTConnectionStatus, cameraWallDevices]);

  const checkTokenSendWebSocketRequest = () => {
    const token_expiry = getPlatformDetails?.timeline_server?.expiry;
    if (token_expiry < new Date() / 1000) {
      axios
        .get(`/partner/platform`, {
          withCredentials: true,
          ...Utils.requestHeader(),
        })
        .then((response) => {
          if (response?.data?.data) {
            dispatch(setPlatformInfo(response?.data?.data));
            // if (!checkMQTTConnection() && accountIdRes) {
            //   connectWithMQTT(accountIdRes);
            // }
            selectedViewDevices?.forEach((deviceId) => {
              connectWithWebSocket(
                response?.data?.data,
                deviceId,
                orgDetails?.orgId,
                accountId
              );
            });
          }
        });
    } else {
      selectedViewDevices?.forEach((deviceId) => {
        connectWithWebSocket(
          getPlatformDetails,
          deviceId,
          orgDetails?.orgId,
          accountId
        );
      });
    }
  };

  const subscribeRequest = (device) => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const sessionId = state.getSessionId();
    if (!accountId) {
      return;
    }

    console.log('add request subscribe', device.deviceId);
    // Send the request
    const context = {
      topic: `a/rt-events/${device.gatewayId}`,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${device.gatewayId}`,
        from: `${accountId}`,
        msg: {
          resource: `ch/${device.deviceId}/camera/events/live-events`,
          action: 'add',
          properties: {
            sessionId: sessionId,
            events: [
              {
                detection: [
                  'motion',
                  'person',
                  'vehicle',
                  'lp',
                  'face',
                  'audio',
                  'SoundRecognition',
                ],
                notifications: [
                  'queue-threshold',
                  'tamper',
                  'defocus',
                  'shock',
                ],
                stats: ['queue-count', 'person-count', 'vehicle-count'],
              },
            ],
          },
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
    keepAliveSetTimer(device);
  };

  const keepAliveSetTimer = (device) => {
    //Retrieve keep alive session every 90 seconds
    keepAliveTimer = setTimeout(() => {
      if (isTimerRunning.current) {
        keepAliveRequest(device);
      }
    }, 90000);
  };

  const keepAliveRequest = (device) => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const sessionId = state.getSessionId();

    if (!accountId) {
      return;
    }

    console.log('keep alive request1', device.deviceId);

    // Send the request
    const context = {
      topic: `a/rt-events/${device.gatewayId}`,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${device.gatewayId}`,
        from: `${accountId}`,
        msg: {
          action: 'set',
          resource: `ch/${device.deviceId}/camera/events/live-events`,
          properties: {
            sessionId: sessionId,
          },
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
    if (isTimerRunning.current) {
      keepAliveSetTimer(device);
    }
  };

  const deleteRequest = (device) => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const sessionId = state.getSessionId();

    if (!accountId) {
      return;
    }

    // Send the request
    const context = {
      topic: `a/rt-events/${device.gatewayId}`,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${device.gatewayId}`,
        from: `${accountId}`,
        msg: {
          resource: `ch/${device.deviceId}/camera/events/live-events`,
          action: 'delete',
          properties: {
            sessionId: sessionId,
          },
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
  };

  useEffect(() => {
    if (selectedViewDevices?.length > 0) {
      const dateArray = selectedViewDevices?.map(async (deviceId) => {
        const device = devicesDetails?.find(
          (device) => device.deviceId === deviceId
        );
        const url = device?.entitlement?.url;
        if (!url) {
          const entitlementDate = new Date().getTime();
          return entitlementDate;
        }
        const date = await fetch(url, {
          withCredentials: true,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data) {
              const expiryDate = data?.term?.expiry;
              const durationInDays = data?.term?.data?.durationInDays;
              const startDate =
                expiryDate - (durationInDays + 1) * 24 * 60 * 60 * 1000;
              return startDate;
            }
          });
        return date;
      });
      Promise.all(dateArray).then((values) => {
        const sortValue = Math.min(...values);
        setAllStartDate(sortValue);
      });
      checkTokenSendWebSocketRequest();
    }

    return () => {
      // dispatch(clearRTCPeerConnections());
      // dispatch(clearLiveStreams());
      // dispatch(clearMQTTPeerConnections());
      // dispatch(clearRemoteStreams());
      // dispatch(clearRemoteStreamsAudio());
      // disconnectWithMQTT();
      setEventCVRMode('null');
      setEventFromSearch(false);
      // selectedViewDevices?.forEach((deviceId) => {
      //   dispatch(removeRemoteStreams(deviceId));
      //   dispatch(removeRemoteStreamsAudio(deviceId));
      //   dispatch(removeMQTTPeerConnections(deviceId));
      //   disconnectWithWebSocket(deviceId);
      // });
    };
  }, [selectedView?.devices]);

  useEffect(() => {
    if (selectedView?.devices?.length) {
      dispatch(setLatestEventsForDots(null));
      dispatch(setMetaDataForEvents(null));
      dispatch(setMetaData(null));
      let subscribeForEvents = {};
      const eventTopics = [];

      selectedView?.devices?.forEach((device) => {
        const subscribeForMetaData = {
          topic: `b/streams/${device}`,
          qos: 0,
        };
        // Subscribe to the app topic
        mqttSubscribe(subscribeForMetaData);

        const deviceDetail = cameraWallDevices.find(
          (cameraWallDevice) => cameraWallDevice.deviceId === device
        );
        if (deviceDetail) {
          eventTopics.push(`d/rt-events/${deviceDetail?.gatewayId}`);
        }
      });

      if (eventTopics.length) {
        subscribeForEvents = {
          topic: eventTopics,
          qos: 0,
        };
        // Subscribe  to the device topic
        mqttSubscribe(subscribeForEvents);
      }

      return () => {
        mqttUnsubscribe(subscribeForEvents);
        selectedViewDevices?.forEach((device) => {
          const subscribeForMetaData = {
            topic: `b/streams/${device}`,
            qos: 0,
          };
          // Unsubscribe to the app topic
          mqttUnsubscribe(subscribeForMetaData);
        });
        dispatch(resetEvents);
      };
    }
  }, [selectedView?.devices]);

  useEffect(() => {
    if (selectedView && !viewId) {
      handleDevicesHeaderClick();
    }
  }, [viewId]);

  useEffect(() => {
    dispatch(setIsLeftMenuOpen(open));
  }, [open]);

  const handleDevicesHeaderClick = async () => {
    disconnectWithMQTT();
    if (selectedView?.devices) {
      await selectedView?.devices?.forEach((deviceId) => {
        sendPauseCVR(deviceId);
        disconnectWithWebSocket(deviceId);
      });
    }
    setEditView(false);
    dispatch(setIsEditModeActive(false));
    dispatch(setSelectedView(null));
    setSelectedViewDevices([]);
    setIsAddNewViewClicked(false);
    setNewViewDragMode(false);
  };

  const showErrorToaster = (mesg) => {
    setShowToast(true);
    setUserMsg(mesg);
    setTimeout(() => {
      setShowToast(false);
      setUserMsg('');
    }, 5500);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    setIsOpenBeforeDragOver(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setIsOpenBeforeDragOver(false);
  };

  const navigateFromNotification = async (notificationData, navigateStatus) => {
    const isFromPush = searchParams.get('fromPush');
    const param = {
      notificationIds: [notificationData?.nId],
    };
    let response = await axios.put(
      `partner/orgs/${orgDetails?.orgId}/notifications/status/read`,
      param,
      Utils.requestHeader()
    );
    const responseData = response?.data;
    if (responseData?.meta?.code === 200) {
    }

    if (isFromPush || navigateStatus) {
      if (notificationData?.orgId === orgDetails?.orgId) {
        if (
          notificationData?.nType === 'motion_detection' ||
          notificationData?.nType === 'object_detection' ||
          notificationData?.nType === 'system_action' ||
          notificationData?.nType === 'audio_detection'
        ) {
          moveToCamDetails(notificationData);
        } else if (
          notificationData?.nType === 'connectivity' ||
          notificationData?.nType === IncidentTypeEnum?.NOTIFICATION_TYPE
        ) {
          setUserPushData({});
          navigate(`/notificationdashboard.html`);
        }
      }
    }
  };

  const fetchOrgUsers = useCallback(async () => {
    await getCustomerOrgUsers(`partner/orgs/${orgDetails?.orgId}/accounts`);
  });

  const getSavedViews = useCallback(async () => {
    if (!orgDetails?.orgId) {
      return;
    }
    let userViews = [];
    try {
      const response = await axios.get(
        `/partner/orgs/${orgDetails?.orgId}/userViews`,
        {
          params: {
            clientPlatform: 'web',
            orgId: orgDetails?.orgId,
            locationId: '',
            requestTime: Date.now(),
            sessionId: currentSession,
          },
          ...Utils.requestHeader(currentSession),
          timeout: 30000,
          credentials: 'include',
          withCredentials: true,
        }
      );

      if (response?.status === 200) {
        userViews = response.data?.data;
      } else {
        // setUserMsg(response?.data?.data.msg);
      }
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      console.error(err);
    } finally {
      setShowLoader(false);
      return userViews;
    }
  });

  const fetchSavedViews = useCallback(async () => {
    try {
      setShowLoader(true);
      // We always fetch all the saved views for the user irrespective
      // of the current location selected. We'll filter the saved views
      // on the client side.
      const userViews = JSON.parse(JSON.stringify(await getSavedViews()));
      const updatedViews = userViews.map((view) => {
        const devicesInView = JSON.parse(atob(view?.file));
        const cameraWallDeviceIds = [];
        const devices = [];
        cameraWallDevices.forEach((device) => {
          const displayDeviceStatus = Utils.getDeviceStatus(
            device.deviceStatus,
            device.connectionStatus
          );
          if (
            displayDeviceStatus !== constants.DEVICES_RETURN_CLAIMING_STATUS &&
            device?.apps?.vms
          ) {
            cameraWallDeviceIds.push(device.deviceId);
          }
        });
        devicesInView.forEach((deviceId) => {
          if (cameraWallDeviceIds.includes(deviceId)) {
            devices.push(deviceId);
          }
        });
        return {
          ...view,
          devices:
            devices.length > 0 && typeof devices[0] === 'string' ? devices : [],
        };
      });
      setShowLoader(false);
      await new Promise((resolve) => {
        dispatch(setViewList([...updatedViews]));
        dispatch(
          setSelectedView(updatedViews.find((v) => v.viewId === viewId))
        );
        resolve();
      });
    } catch (err) {
      setShowLoader(false);
      // setUserMsg(err.msg);
    }
  });

  const addView = async (newViewName) => {
    try {
      const deviceList = isAddNewViewClicked ? [] : selectedViewDevices;
      setIsAddingView(true);
      const reqBody = {
        clientPlatform: 'web',
        file: btoa(JSON.stringify(deviceList)),
        name: newViewName,
        locationId: AppDefaults.ALL_LOCATIONS_ID,
        viewType: 'DASHBOARD',
      };
      let response = await axios.post(
        `/partner/orgs/${orgDetails?.orgId}/userViews`,
        reqBody,
        {
          params: {
            orgId: orgDetails?.orgId,
          },
          ...Utils.requestHeader(),
        }
      );

      let resData = response?.data;

      if (resData?.meta.code === 200) {
        let newDevice = resData?.data;
        newDevice.devices = JSON.parse(atob(newDevice?.file));
        dispatch(setViewList([newDevice, ...viewList]));
        if (newDevice?.name !== 'Untitled View') {
          setIsAddNewViewClicked(false);
        } else {
          setIsRenamingView(true);
        }
        formikRef?.current?.resetForm();
        setIsAddingView(false);
        handleViewSelect(newDevice);
        setIsDragged(false);
        setNewViewDragMode(false);
        handleDrawerOpen();
      } else {
        setIsAddingView(false);
        setIsDragged(false);
        // setUserMsg(response.data.data.msg);
      }
    } catch (err) {
      setIsAddingView(false);
      setIsDragged(false);
      // setUserMsg(err.msg);
    }
  };

  const updateView = async () => {
    try {
      if (
        selectedViewDevices.length >
        AppDefaults.CAMERAS_VIDEO_WALL_MAX_VIEW_DEVICES_COUNT
      ) {
        setShowMaxDevciesModal(true);
        return;
      }
      setIsUpdatingView(true);
      const reqBody = {
        clientPlatform: 'web',
        file: btoa(JSON.stringify(selectedViewDevices)),
        name: selectedView?.name,
        locationId: AppDefaults.ALL_LOCATIONS_ID,
        viewType: 'DASHBOARD',
      };
      let response = await axios.put(
        `/partner/orgs/${orgDetails?.orgId}/userViews/${selectedView.viewId}`,
        reqBody,
        {
          params: {
            orgId: orgDetails?.orgId,
          },
          ...Utils.requestHeader(),
        }
      );

      let resData = response?.data;

      if (resData?.meta.code === 200) {
        handleDrawerClose();
        setNewViewDragMode(false);
        setEditView(false);
        dispatch(setIsEditModeActive(false));
        const newViewList = JSON.parse(JSON.stringify(viewList));
        newViewList.forEach((view) => {
          if (view.viewId === selectedView.viewId) {
            view.devices = selectedViewDevices;
            view.file = resData?.data?.file;
          }
        });
        dispatch(setViewList(newViewList));
        let newView = resData?.data;
        newView.devices = selectedViewDevices;
        dispatch(setSelectedView(newView));
        setIsUpdatingView(false);
        setIsDragged(false);
      } else {
        // setUserMsg(response.data.data.msg);
        setIsUpdatingView(false);
        setEditView(true);
        setIsDragged(false);
      }
    } catch (err) {
      // setUserMsg(err.msg);
      setIsUpdatingView(false);
      setEditView(true);
      setIsDragged(false);
    }
  };

  const deleteView = async () => {
    try {
      let result = await axios.delete(
        `/partner/orgs/${orgDetails?.orgId}/userViews/${selectedView?.viewId}`,
        Utils.requestHeader()
      );

      let resData = result?.data;

      if (resData?.meta.code === 200) {
        fetchSavedViews();
        dispatch(setSelectedView(null));
        setEditView(false);
        dispatch(setIsEditModeActive(false));
        setNewViewDragMode(false);
        setSelectedViewDevices([]);
        setShowDeleteModal(false);
        setIsRenamingView(false);
        handleDrawerOpen();
        navigate({
          pathname: '/cameras/devices.html',
          search: '',
        });
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renameView = async (newName) => {
    try {
      setIsAddingView(true);
      const reqBody = {
        clientPlatform: 'web',
        file: selectedView.file,
        name: newName,
        locationId: AppDefaults.ALL_LOCATIONS_ID,
        viewType: 'DASHBOARD',
      };
      let response = await axios.put(
        `/partner/orgs/${orgDetails?.orgId}/userViews/${selectedView.viewId}`,
        reqBody,
        {
          params: {
            orgId: orgDetails?.orgId,
          },
          ...Utils.requestHeader(),
        }
      );

      let resData = response?.data;

      if (resData?.meta.code === 200) {
        setIsRenamingView(false);
        let renamedDevice;
        const newViewList = JSON.parse(JSON.stringify(viewList));
        newViewList.forEach((view) => {
          if (view.viewId === selectedView.viewId) {
            view.name = resData?.data?.name;
            renamedDevice = view;
          }
        });
        dispatch(setViewList(newViewList));
        dispatch(setSelectedView(renamedDevice));
        setIsAddingView(false);
      } else {
        // setUserMsg(response.data.data.msg);
        setIsAddingView(false);
      }
    } catch (err) {
      // setUserMsg(err.msg);
      setIsAddingView(false);
    }
  };
  const moveToCamDetails = (notificationData) => {
    const endTime = new Date().getTime();
    const eventTimestamp = parseFloat(notificationData?.eventTimestamp);
    const startTime = eventTimestamp - 259200000;
    if (startTime && endTime) {
      axios
        .get(
          `timeline/device/${notificationData?.deviceId}/metadata?startTime=${startTime}&endTime=${endTime}`,
          Utils.requestHeader()
        )
        .then((response) => {
          if (response?.data?.data) {
            let largest = 0;
            const listMeta = response?.data?.data;
            for (let i = 0; i < listMeta?.length; i++) {
              if (listMeta[i].end > largest) {
                largest = listMeta[i].end;
              }
            }
            setUserPushData({});
            if (eventTimestamp <= parseFloat(largest)) {
              setSelectedEventStore(eventTimestamp);
              setEventFromSearch(true);
              setEventCVRMode(true);
              navigate(`/cameras/dashboard.html`, {
                state: {
                  id: notificationData?.deviceId,
                  cdnInfo: cdnInfo ? cdnInfo : {},
                },
              });
            } else if (eventTimestamp > parseFloat(largest)) {
              setSelectedEventStore('null');
              setEventCVRMode('null');
              setEventFromSearch(false);
              navigate(`/cameras/dashboard.html`, {
                state: {
                  id: notificationData?.deviceId,
                  cdnInfo: cdnInfo ? cdnInfo : {},
                },
              });
            }
          }
        });
    }
  };

  const onMessageReceive = (notificationData) => {
    navigateFromNotification(notificationData, true);
  };

  const handleNewViewClick = () => {
    setIsAddNewViewClicked(true);
  };

  const handleCrossClick = () => {
    setIsAddNewViewClicked(false);
    setIsRenamingView(false);
  };

  const handleViewSelect = async (view) => {
    if (view.viewId === viewId) {
      return;
    }
    // Every time when view changes, old web socket and mqtt connection will close.
    disconnectWithMQTT();
    if (selectedView?.devices) {
      await selectedView?.devices?.forEach((deviceId) => {
        sendPauseCVR(deviceId);
        disconnectWithWebSocket(deviceId);
      });
    }
    handleDrawerClose();
    setNewViewDragMode(false);
    setIsAddingView(false);
    setIsAddNewViewClicked(false);
    setSelectedViewDevices(view?.devices);
    dispatch(resetRecievedOffers());
    dispatch(setSelectedView(view));
    navigate({
      pathname: '/cameras/devices.html',
      search: `?viewId=${view.viewId}`,
    });
  };

  const handleDevicesButtonClick = async () => {
    disconnectWithMQTT();
    if (selectedView?.devices) {
      await selectedView?.devices?.forEach((deviceId) => {
        sendPauseCVR(deviceId);
        disconnectWithWebSocket(deviceId);
      });
    }
    if (!viewId) {
      return;
    }
    setEditView(false);
    dispatch(setIsEditModeActive(false));
    navigate({
      pathname: '/cameras/devices.html',
      search: '',
    });
    dispatch(setSelectedView(null));
    setSelectedViewDevices([]);
    setIsAddNewViewClicked(false);
    setNewViewDragMode(false);
  };

  const validateForm = Yup.object({
    newView: Yup.string()
      .max(40, constants.VIEW_NAME_MAX_LENGTH_MESSAGE)
      .required(constants.VIEW_NAME_REQUIRED_ERROR_MESSAGE)
      .test(
        'is-unique',
        constants.VIEW_NAME_DUPLICATE_ERROR_MESSAGE,
        (value = '') => {
          const list = isRenamingView
            ? viewList.filter(
                (v) =>
                  v.name.trim().toLowerCase() !==
                  selectedView.name.trim().toLowerCase()
              )
            : viewList;
          const trimmedLowerCaseValue = value.trim().toLowerCase();
          return !list.some(
            (view) => view.name.trim().toLowerCase() === trimmedLowerCaseValue
          );
        }
      ),
  });

  const onBeforeCapture = () => {
    setIsDragging(true);
  };
  const handleDragEnd = (result) => {
    setIsDragging(false);
    if (
      selectedViewDevices.length >
      AppDefaults.CAMERAS_VIDEO_WALL_MAX_VIEW_DEVICES_COUNT
    ) {
      setShowMaxDevciesModal(true);
      return;
    }
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (destination.droppableId !== source?.droppableId) {
      setIsDragged(true);
      setNewViewDragMode(false);
      setEditView(false);
      dispatch(setIsEditModeActive(false));
      if (viewId) {
        updateView();
      } else {
        const uniqueName = getUniqueViewName('Untitled View');
        addView(uniqueName);
      }
    }
  };

  const getUniqueViewName = (viewName) => {
    let newName = viewName;
    let count = 1;

    const existingNames = viewList.map((view) => view.name);
    while (existingNames.includes(newName)) {
      newName = `${viewName} ${count}`;
      count++;
    }

    return newName;
  };

  const getParentDeviceTimeZone = (deviceId) => {
    const parentDevices = Utils.getTotalParentDevices(customerOrgDevices);
    const curDevice = devicesDetails.find(
      (device) => device.deviceId === deviceId
    );
    const parentDevice = parentDevices.find(
      (device) => device.deviceId === curDevice?.gatewayId
    );
    return parentDevice?.properties?.timezone;
  };

  return (
    <div className="App device-wall">
      <Header
        isFromVideoWall={true}
        onPushReceive={(value) => {
          onMessageReceive(value);
        }}
        showLocation={showLocation}
        showCart={false}
      />
      <SiteToast
        customCss="licenses-list-toast"
        position="top-end"
        show={showToast}
        title="Uh-oh!"
        body={userMsg}
        delay={5000}
      />
      <DragDropContext
        onBeforeCapture={onBeforeCapture}
        onDragEnd={handleDragEnd}
      >
        <div className="main-wrapper">
          {childDevices ? (
            <Box sx={{ display: 'flex' }}>
              <CssBaseline />
              <Drawer
                className={`device-wall-drawer ${
                  showDeleteModal || showMaxDevciesModal || showJumpToDateModal
                    ? 'popup-opacity'
                    : ''
                }`}
                variant="permanent"
                open={open}
                onMouseEnter={() => setOpen(true)}
                onDragOver={() => {
                  setOpen(!isOpenBeforeDragOver);
                }}
                onDragLeave={() => {
                  setOpen(isOpenBeforeDragOver);
                }}
              >
                <DrawerHeader className="device-wall-drawer-header">
                  <div
                    className={`device-wall-drawer-header-title ${
                      open ? 'visible' : 'hidden'
                    } ${isRenamingView || editView ? 'disabled' : ''}`}
                  >
                    {constants.CAMERAS_VIDEO_WALL_TITLE}
                  </div>
                  <IconButton
                    className="device-wall-drawer-header-toggle-icon"
                    onClick={() => {
                      open ? handleDrawerClose() : handleDrawerOpen();
                    }}
                    disabled={isRenamingView || editView}
                  >
                    {open ? (
                      <HiArrowDownTray
                        className="device-wall-drawer-toggle-icon toggle-close"
                        size={'24'}
                        color={
                          isRenamingView || editView
                            ? getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--greyscale_56')
                            : getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--brand_white')
                        }
                      />
                    ) : (
                      <HiArrowDownTray
                        className="device-wall-drawer-toggle-icon toggle-open"
                        size={'24'}
                        color={
                          isRenamingView || editView
                            ? getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--greyscale_56')
                            : getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--brand_white')
                        }
                      />
                    )}
                  </IconButton>
                </DrawerHeader>
                <List className="device-wall-drawer-list mt-2">
                  <ListItem
                    className={`device-wall-drawer-list-item 
                  ${isDevicesOptionSelected ? `active-list-item` : ''}
                  ${open ? 'visible' : 'icon-only'}`}
                    disablePadding
                    sx={{ display: 'block' }}
                  >
                    <ListItemButton
                      className={`device-wall-drawer-list-item-button device-wall-drawer-list-item-not-active-button ${
                        isRenamingView || editView ? 'disabled' : ''
                      }`}
                      sx={{
                        minHeight: 40,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                      }}
                      onClick={() => handleDevicesButtonClick()}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        {isRenamingView || editView ? (
                          <CameraDisabled />
                        ) : (
                          <>
                            {process.env.REACT_APP_PROJECT ===
                            AppDefaults.PROJECT_MEGATRON ? (
                              <CiGrid41 color="white" size={24} />
                            ) : (
                              <CameraIcon />
                            )}
                          </>
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={constants.CAMERAS_VIDEO_WALL_DEVICES_LABEL}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
                <Divider
                  className={`device-wall-drawer-divider ${
                    open ? 'visible' : 'hidden'
                  }`}
                />
                <div
                  className={`device-wall-drawer-section-item ${
                    !isDevicesOptionSelected ? `active-list-item` : ''
                  }`}
                >
                  {isRenamingView || editView ? (
                    <VideoCameraIconDisabled />
                  ) : (
                    <VideoCameraIcon
                      onClick={() => handleDrawerOpen()}
                      className={!open ? 'cam-pointer' : ''}
                    />
                  )}
                  <div
                    className={`device-wall-drawer-section-title ${
                      open ? 'visible' : 'hidden'
                    } ${isRenamingView || editView ? 'disabled' : ''}`}
                  >
                    {constants.CAMERAS_VIDEO_WALL_MY_VIEWS_TITLE}
                  </div>
                </div>
                {!isAddNewViewClicked ? (
                  <Droppable droppableId={'drawer'} key={'drawer'}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <Button
                          className={`device-wall-drawer-new-view-btn ${
                            open ? 'visible' : 'hidden'
                          } ${isRenamingView || editView ? 'disabled' : ''}
                      ${isDragging && !viewId ? 'drag-over' : ''}`}
                          disabled={isRenamingView || editView}
                          onClick={() => handleNewViewClick()}
                          onDragOver={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            const el = e.currentTarget;
                            el.classList.add('drag-over');
                          }}
                        >
                          <span
                            className={`device-wall-drawer-new-view-btn-label-plus ${
                              open ? 'visible' : 'hidden'
                            } ${isRenamingView || editView ? 'disabled' : ''}`}
                            disabled={isRenamingView || editView}
                          >
                            +
                          </span>
                          <span
                            className={`device-wall-drawer-new-view-btn-label ${
                              open ? 'visible' : 'hidden'
                            } ${isRenamingView || editView ? 'disabled' : ''}`}
                            disabled={isRenamingView || editView}
                          >
                            {constants.CAMERAS_VIDEO_WALL_NEW_VIEW_BUTTON_LABEL}
                          </span>
                        </Button>
                      </div>
                    )}
                  </Droppable>
                ) : (
                  <Formik
                    innerRef={formikRef}
                    validationSchema={validateForm}
                    initialValues={{
                      newView: '',
                    }}
                    onSubmit={(values) => {
                      addView(values.newView);
                    }}
                  >
                    {({ handleSubmit, submitForm }) => (
                      <Form
                        className="device-wall-drawer-add-view-form"
                        onSubmit={handleSubmit}
                      >
                        <TextField
                          removebottommargin="0"
                          removetopmargin="0"
                          afterinputicon={
                            !isAddingView ? (
                              <div className="icon-container">
                                <CrossBackgroudIcon
                                  className="cross-icon"
                                  onClick={() => {
                                    handleCrossClick();
                                  }}
                                />
                                <CheckedIcon
                                  className={`checked-icon`}
                                  onClick={() => {
                                    submitForm();
                                  }}
                                />
                              </div>
                            ) : (
                              <div>
                                <SiteSpinner
                                  height="20px"
                                  width="20px"
                                  backgroundColor={getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue('--brand_white')}
                                />
                              </div>
                            )
                          }
                          autoFocused={true}
                          placeholder={
                            constants.CREATE_NEW_VIEW_FIELD_PLACEHOLDER
                          }
                          name="newView"
                          type="text"
                        />
                      </Form>
                    )}
                  </Formik>
                )}
                <List
                  className={`device-wall-drawer-list view-list ${
                    open ? 'visible' : 'hidden'
                  }`}
                >
                  {/* Saved Views */}
                  {viewList.map((view) =>
                    isRenamingView &&
                    selectedView &&
                    view.viewId === selectedView?.viewId ? (
                      <Formik
                        innerRef={formikRef}
                        validationSchema={validateForm}
                        initialValues={{
                          newView: selectedView?.name ? selectedView?.name : '',
                        }}
                        enableReinitialze={true}
                        onSubmit={(values) => {
                          renameView(values.newView);
                        }}
                      >
                        {({ handleSubmit, submitForm }) => (
                          <Form
                            className="device-wall-drawer-add-view-form"
                            onSubmit={handleSubmit}
                          >
                            <TextField
                              removebottommargin="0"
                              removetopmargin="0"
                              afterinputicon={
                                !isAddingView ? (
                                  <div className="icon-container">
                                    <CrossBackgroudIcon
                                      className="cross-icon"
                                      onClick={() => {
                                        handleCrossClick();
                                      }}
                                    />
                                    <CheckedIcon
                                      className={`checked-icon`}
                                      onClick={() => {
                                        submitForm();
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div>
                                    <SiteSpinner
                                      height="20px"
                                      width="20px"
                                      backgroundColor={getComputedStyle(
                                        document.documentElement
                                      ).getPropertyValue('--brand_white')}
                                    />
                                  </div>
                                )
                              }
                              autoFocused={true}
                              placeholder={
                                constants.CREATE_NEW_VIEW_FIELD_PLACEHOLDER
                              }
                              name="newView"
                              type="text"
                            />
                          </Form>
                        )}
                      </Formik>
                    ) : (
                      <Droppable
                        droppableId={view.viewId}
                        key={view?.viewId}
                        isDropDisabled={view?.viewId !== selectedView?.viewId}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            <ListItem
                              className="device-wall-drawer-list-item"
                              key={view?.viewId}
                              disablePadding
                              sx={{ display: 'block' }}
                            >
                              <ListItemButton
                                className={`device-wall-drawer-list-item-button ${
                                  view.viewId === selectedView?.viewId
                                    ? 'active'
                                    : ''
                                } ${isRenamingView ? 'disabled' : ''}
                                ${
                                  editView &&
                                  view?.viewId !== selectedView?.viewId
                                    ? 'disabled'
                                    : ''
                                }
                                  ${
                                    isDragging &&
                                    view?.viewId === selectedView?.viewId
                                      ? 'drag-over'
                                      : ''
                                  }`}
                                sx={{
                                  justifyContent: open ? 'initial' : 'center',
                                  px: 2.5,
                                }}
                                data-role="drag-drop-container"
                                onDragOver={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  // TODO: need to disable firing on drag over event from the drawer

                                  const el = e.currentTarget;
                                  // TODO: change color
                                  console.log('drag over event received');
                                  el.classList.add('drag-over');
                                  console.log('el.classList: ', el.classList);
                                }}
                                onDrop={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  // TODO: invoke on-drop handler
                                  console.log('drop event reveived');
                                }}
                                onClick={() => handleViewSelect(view)}
                              >
                                <ListItemText
                                  primary={view?.name}
                                  className="list-item-span"
                                  sx={{ opacity: open ? 1 : 0, margin: 0 }}
                                ></ListItemText>
                                {view?.numOfNotices > 0 && (
                                  <div className="device-wall-drawer-list-item-notifications">
                                    {view?.numOfNotices}
                                  </div>
                                )}
                              </ListItemButton>
                            </ListItem>
                          </div>
                        )}
                      </Droppable>
                    )
                  )}
                </List>
                <div
                  className={`device-wall-drawer-help-container  ${
                    open ? 'visible' : 'icon-only'
                  }`}
                >
                  <div className="device-wall-drawer-help-section">
                    <PiQuestionLight
                      className="device-wall-drawer-help-icon"
                      size={24}
                    />
                    <span
                      className={`device-wall-drawer-help-text ${
                        open ? 'visible' : 'hidden'
                      }`}
                    >
                      {constants.CAMERAS_VIDEO_WALL_HELP_LABEL}
                    </span>
                  </div>
                </div>
              </Drawer>
              <div className="camera-wall-wrapper" ref={fullscreenRef}>
                <FullScreen handle={handle}>
                  <Box
                    className={`device-wall-main-container ${
                      handle?.active ? 'fullscreen-mode' : ''
                    }`}
                    component="main"
                    sx={{ flexGrow: 1, p: 3 }}
                  >
                    {/* Header Section */}
                    <div className="device-wall-main-container-header">
                      <div className="page-title">
                        {viewId && selectedView && !editView
                          ? selectedView?.name
                            ? selectedView?.name
                            : 'Untitled View'
                          : selectedView && editView
                          ? `Edit <${selectedView.name}> View`
                          : constants.CAMERAS_VIDEO_WALL_TITLE}
                      </div>
                      <div className="page-monitor-container">
                        {viewId && selectedView && editView ? (
                          <PrimaryButton
                            className="btn btn-primary device-wall-drawer-edit-view-btn"
                            type="submit"
                            width="91px"
                            height="32px"
                            fontSize="0.75rem"
                            lineHeight="16px"
                            borderWidth="0px"
                            hoverBorderWidth="0px"
                            backgroundColor={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--primary_40')}
                            color={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--brand_white')}
                            onClick={() => updateView()}
                            loader={isUpdatingView}
                            loaderClassName={'edit-view-loader'}
                          >
                            {
                              constants.CAMERAS_VIDEO_WALL_SAVE_VIEW_BUTTON_LABEL
                            }
                          </PrimaryButton>
                        ) : null}
                        {(viewId && selectedView && editView) ||
                        newViewDragMode ? (
                          <div className="page-monitor">
                            <span>
                              {selectedViewDevices?.length}/
                              {
                                AppDefaults.CAMERAS_VIDEO_WALL_MAX_VIEW_DEVICES_COUNT
                              }
                            </span>
                            selected
                            <HiOutlineCheckCircle
                              size={16}
                              color={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--primary_64')}
                            />
                          </div>
                        ) : null}
                        {viewId && selectedView && editView ? (
                          <PrimaryButton
                            className="btn btn-primary px-2"
                            type="submit"
                            width="55px"
                            height="32px"
                            fontSize="0.75rem"
                            lineHeight="16px"
                            onClick={() => {
                              setEditView(false);
                              dispatch(setIsEditModeActive(false));
                              setNewViewDragMode(false);
                              setSelectedViewDevices(selectedView?.devices);
                              dispatch(setSelectedView(selectedView));
                            }}
                            backgroundColor={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--greyscale_08')}
                            hoverBackgroundColor={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--greyscale_08')}
                            borderWidth="0px"
                            hoverBorderWidth="0px"
                            color={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--brand_white')}
                            hoverColor={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--brand_white')}
                          >
                            {constants.CAMERAS_LIVE_ACTION_BUTTONS_CANCEL_LABEL}
                          </PrimaryButton>
                        ) : null}
                      </div>
                      {!handle?.active &&
                      viewId &&
                      selectedView &&
                      !editView ? (
                        <NavDropdown
                          className="edit-view-dropdown"
                          title={
                            <div>
                              <img src={editViewButton} alt=""></img>
                            </div>
                          }
                        >
                          <NavDropdown.Item
                            onClick={() => {
                              setEditView(true);
                              setNewViewDragMode(false);
                              handleDrawerOpen();
                            }}
                            className={isRenamingView ? 'disabled' : ''}
                            disabled={isRenamingView}
                          >
                            <HiOutlinePencil
                              size={20}
                              color={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--greyscale_64')}
                            />
                            {constants.CAMERAS_VIDEO_EDIT_VIEW_LABEL}
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onClick={() => {
                              handleDrawerOpen();
                              setIsRenamingView(true);
                            }}
                          >
                            <HiOutlinePencilAlt
                              size={20}
                              color={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--greyscale_64')}
                            />
                            {constants.CAMERAS_VIDEO_RENAME_VIEW_LABEL}
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            onClick={() => setShowDeleteModal(true)}
                          >
                            <TbTrash
                              size={20}
                              color={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--error_64')}
                            />
                            {constants.CAMERAS_VIDEO_DELETE_VIEW_LABEL}
                          </NavDropdown.Item>
                        </NavDropdown>
                      ) : null}
                    </div>
                    {(!selectedView && !isDragged) ||
                    (viewId && selectedView && editView && !isDragged) ? (
                      <>
                        <div className="device-wall-main-container-content-body">
                          <CameraWallDevices
                            viewDetails={editView ? selectedView : null}
                            setSelectedViewDevices={setSelectedViewDevices}
                            setTotalDeviceCount={setTotalDeviceCount}
                            setShowMaxDevciesModal={setShowMaxDevciesModal}
                            newViewDragMode={newViewDragMode}
                            setNewViewDragMode={setNewViewDragMode}
                            editView={editView}
                            isDragging={isDragging}
                          />
                        </div>
                      </>
                    ) : viewId &&
                      selectedView?.devices?.length &&
                      !isDragged ? (
                      <>
                        <DeviceWallTimeline
                          internalEventFromSearch={false}
                          category={categoryDashboard}
                          eventTimestamp={null}
                          eventCVRMode={getEventCVRMode()}
                          showErrorToaster={showErrorToaster}
                          timezone={
                            selectedViewDevices.length > 1
                              ? moment.tz.guess()
                              : getParentDeviceTimeZone(selectedViewDevices[0])
                          }
                          startDate={allStartDate}
                          // deviceId={deviceId}
                          viewId={selectedView?.viewId}
                          enterFullScreen={() => handle.enter()}
                          exitFullScreen={() => handle.exit()}
                          // handleClick={handleClick}
                          customMetaData={deviceMetaData}
                          eventDotsData={eventsData}
                          allDeviceIds={selectedViewDevices}
                          appliedFilter={''}
                          setShowJumpToDateModal={setShowJumpToDateModal}
                        />
                      </>
                    ) : viewId && selectedView && !isDragged ? (
                      <>
                        <div className="no-cameras-container">
                          <div className="no-cameras-icon-container">
                            <FiCameraOff
                              stroke={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--greyscale_56')}
                              size={32}
                            />
                          </div>
                          <div className="no-cameras-notice">
                            {
                              constants.CAMERAS_VIDEO_WALL_EDIT_VIEW_BUTTON_DESCRIPTION
                            }
                          </div>
                          <div className="no-cameras-cta">
                            <Button
                              className="no-cameras-edit-view-btn"
                              onClick={(e) => {
                                setEditView(true);
                                setNewViewDragMode(false);
                              }}
                            >
                              <span>
                                <HiOutlinePencil
                                  stroke={getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue('--brand_white')}
                                  size={16}
                                />
                              </span>
                              {
                                constants.CAMERAS_VIDEO_WALL_EDIT_VIEW_BUTTON_LABEL
                              }
                            </Button>
                          </div>
                        </div>
                      </>
                    ) : isDragged ? (
                      <div className="spinner-container">
                        <SiteSpinner
                          height="100px"
                          width="100px"
                          backgroundColor={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--brand_white')}
                        />
                      </div>
                    ) : null}
                  </Box>
                </FullScreen>
              </div>
            </Box>
          ) : (
            <div className="no-cameras-container">
              <div className="no-cameras-icon-container">
                <FiCameraOff
                  stroke={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--greyscale_56')}
                  size={32}
                />
              </div>
              <div className="no-cameras-notice">
                {constants.CAMERAS_VIDEO_WALL_NO_CAMERAS_AVAILABLE_LABEL}
              </div>
              {userPolicies.install_device && userPolicies.claim_device && (
                <div className="no-cameras-cta">
                  <Button
                    className="no-cameras-add-device-btn"
                    onClick={(e) => {
                      navigate(
                        `/devices/listing.html?orgId=${orgDetails?.orgId}`
                      );
                    }}
                  >
                    <span>
                      <MdOutlineAddAPhoto
                        stroke={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--brand_white')}
                        size={16}
                      />
                    </span>
                    {constants.CAMERAS_VIDEO_WALL_ADD_CAMERA_LABEL}
                  </Button>
                </div>
              )}
            </div>
          )}
          <SiteModal
            key={'delete-view-modal'}
            classes="save-view-modal"
            wrapperClass="save-view-modal"
            modalTitle={constants.CAMERAS_VIDEO_WALL_DELETE_VIEW_MODAL_TITLE}
            showModal={showDeleteModal}
            hideModal={() => setShowDeleteModal(false)}
          >
            <DeleteView
              deleteView={deleteView}
              cancelDeleting={() => setShowDeleteModal(false)}
              selectedView={selectedView}
            ></DeleteView>
          </SiteModal>
          <SiteModal
            key={'view-max-device-modal'}
            classes="view-max-device-modal"
            wrapperClass="view-max-device-modal"
            showModal={showMaxDevciesModal}
            hideModal={() => setShowMaxDevciesModal(false)}
            modalHeader={true}
          >
            <MaxViewDevices
              closeModal={() => setShowMaxDevciesModal(false)}
              selectedViewDevices={selectedViewDevices}
            ></MaxViewDevices>
          </SiteModal>
        </div>
      </DragDropContext>
    </div>
  );
};

export default Cameras;
