import { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { constants } from '../../../helpers';
import './stepspos.scss';

const DTAttributeSelector = ({formats,updateDateSelected ,isFromEdit = false, posItem }) => {
  const attribute = formats;
  const [selectedAttribute, setSelectedAttribute] = useState(
    constants.POS_SELECT_DATE_LABEL
  );
  useEffect(()=>{
    if(posItem?.format){
      setSelectedAttribute(posItem?.format)
    }
    else if(posItem?.formateSelected){
      setSelectedAttribute(posItem?.formateSelected)
    }
  },[])

  const onSelectAreaHandler = (evtKey) => {
    if (!evtKey) return;
    if(evtKey === constants.POS_SELECT_DATE_LABEL) return;
    let selectedAT = attribute.find((atItem) => atItem === evtKey);
    updateDateSelected(selectedAT)
    setSelectedAttribute(selectedAT);
  };

  const dateTimeAttributeContent =
    attribute?.length > 0 &&
    attribute?.map((atItems) => (
      <Dropdown.Item
        key={`attribute-${atItems}`}
        className={
          `area-selector-menu-item` +
          (selectedAttribute === atItems ? ' active' : '')
        }
        eventKey={atItems}
      >
        {atItems}
      </Dropdown.Item>
    ));

  return (
    <div className="selection-wrapper">
      <Dropdown className="area-selector" onSelect={onSelectAreaHandler}>
        <Dropdown.Toggle
          variant="outline-secondary"
          id="dropdown-area"
          className="area-selector-toggle"
        >
          <div className="area-selected-wrapper">
            <span className="area-selected-name text-selected">{selectedAttribute}</span>
          </div>
          <HiOutlineChevronDown size={20} />
        </Dropdown.Toggle>
        <Dropdown.Menu className="area-selector-menu">
          {dateTimeAttributeContent}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default DTAttributeSelector;
