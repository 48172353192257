import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  step2of2IconLicense,
  LicenseSuccesIcon,
  LicenseSuccesMarkIcon,
} from '../../../assets/images';
import { PrimaryButton } from '../../../components/common/';
import { useOrganizations } from '../../../store/OrganizationsStore';
import { useLoggedInUserData } from '../../../store/LoggedInAccountStore';
import useLicensesStore from '../../../store/LicensesStore';
import { constants } from '../../../helpers/';
import { Formik, Form, Field } from 'formik';
import { RxCross1 } from 'react-icons/rx';
import * as Yup from 'yup';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

function Two(props) {
  const [showLoader, setShowLoader] = useState(false);
  const [validConfirmationCode, setValidConfirmationCode] = useState(false);
  const [responseAfterOTP, setResponseAfterOTP] = useState('');
  const [invalidOTPCodeError, setInvalidOTPCodeError] = useState('');
  const [resendCodeClicked, setResendCodeClicked] = useState(false);
  const [validPasteData, setValidPasteData] = useState(false);
  const navigate = useNavigate();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const orgName = query.get('orgName');
  const orgId = query.get('orgId');

  const reloadData = props?.reloadData;

  const validate = Yup.object().shape({
    confirmationCode: Yup.array().of(
      Yup.string()
        .matches(/^\d$/, constants.LICENSE_CONF_CODE_INVALID)
        .required(constants.LICENSE_CONF_CODE_REQUIRED)
    ),
  });

  const getCustomerOrgData = useOrganizations(
    (state) => state.getCustomerOrgData
  );

  let { setLicenses } = useLicensesStore();

  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );

  const handleResendCodeClick = async () => {
    setResendCodeClicked(true);
    await axios
      .post(
        `/partner/orgs/${orgId}/orders/${props.licenseValuesForSelectedOrderNumber}/claim/resend`
      )
      .then((response) => {
        setResendCodeClicked(false);
      })
      .catch((error) => {
        console.error(error);
        setResendCodeClicked(false);
      });
  };

  const maskEmail = (email) => {
    if (!email) {
      return '';
    }

    const atIndex = email.indexOf('@');
    const firstChar = email.slice(0, 1);
    const maskedPart = email.slice(1, atIndex).replace(/./g, '*');
    const domainPart = email.slice(atIndex);

    return firstChar + maskedPart + domainPart;
  };

  const updateLicensesStore = async () => {
    await setLicenses(`/user/orgs/${orgId}/licenses`);
    await new Promise((resolve) => {
      reloadData && reloadData();
      resolve();
    });
  };

  return (
    <div className="ctn-box innner">
      <div id={'headerText'} className="header-view title-container">
        <div className="titlename-header">
          {constants.LICENSE_TAB_LICENSE_INFO}
        </div>
        <RxCross1
          className="site-modal-close"
          onClick={async () => {
            await updateLicensesStore();
            props.hideModal();
          }}
        />
      </div>
      {!validConfirmationCode &&
        props.flowValue === 'Flow1' &&
        props.valueForFirstFlow &&
        !props.valueForSecondFlow && (
          <>
            <div className="stepIcon">
              <img src={step2of2IconLicense} alt="step2icon" />
            </div>
            <div className="steptext">{constants.LICENSE_STEP_TWO_OF_TWO}</div>
            <Formik
              enableReinitialize
              initialValues={{
                confirmationCode: Array(6).fill(''),
              }}
              validationSchema={validate}
              onSubmit={async (values) => {
                const reqBody = {
                  otp: values?.confirmationCode.join(''),
                };
                await axios
                  .put(
                    `/partner/orgs/${orgId}/orders/${props.licenseValuesForSelectedOrderNumber}/licenses/verify`,
                    reqBody
                  )
                  .then((response) => {
                    if (response?.data?.meta?.code === 200) {
                      setValidConfirmationCode(true);
                      setResponseAfterOTP(response?.data);
                    } else {
                      if (response?.data?.meta?.desc) {
                        setInvalidOTPCodeError(response?.data?.meta?.desc);
                      }
                      return;
                    }
                  })
                  .catch((error) => {
                    setValidConfirmationCode(false);
                    return;
                  });
              }}
            >
              {({ values, handleChange, setFieldValue, dirty, isValid }) => (
                <Form className="modal-form">
                  <div className="cofirmationCodeMessageStyle">
                    {constants.LICENSE_CONF_CODE_SENT_TEXT}{' '}
                    {maskEmail(props.recipientEmailAddress)}
                    {'. '}
                    {constants.LICENSE_CONF_CODE_ENTER_TEXT} the claim.
                  </div>
                  <br />
                  <div className="mb-3 license-name">
                    <Row>
                      {Array(6)
                        .fill(0)
                        .map((_, index) => (
                          <div className="col-md-2" key={index}>
                            <Field
                              type="text"
                              name={`confirmationCode[${index}]`}
                              className="form-control large-text-box"
                              maxLength="1"
                              value={values.confirmationCode[index]}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (!/^\d*$/.test(value)) {
                                  setInvalidOTPCodeError(
                                    constants.LICENSE_OTP_INVALID
                                  );
                                  return;
                                }
                                setFieldValue(
                                  `confirmationCode[${index}]`,
                                  value
                                );
                                if (value && index < 5) {
                                  document
                                    .getElementsByName(
                                      `confirmationCode[${index + 1}]`
                                    )[0]
                                    .focus();
                                }
                              }}
                              onKeyDown={(e) => {
                                if (
                                  (e.key === 'Backspace' ||
                                    e.key === 'Delete') &&
                                  !e.target.value &&
                                  index > 0
                                ) {
                                  document
                                    .getElementsByName(
                                      `confirmationCode[${index - 1}]`
                                    )[0]
                                    .focus();
                                }
                              }}
                              onPaste={(e) => {
                                e.preventDefault();
                                const pastedData =
                                  e.clipboardData.getData('text/plain');
                                const cleanData = pastedData.replace(/\s/g, '');
                                if (!/^\d*$/.test(cleanData)) {
                                  setInvalidOTPCodeError(
                                    constants.LICENSE_OTP_INVALID
                                  );
                                  return;
                                }
                                if (cleanData.length === 6) {
                                  for (let i = 0; i < 6; i++) {
                                    setFieldValue(
                                      `confirmationCode[${i}]`,
                                      cleanData[i]
                                    );
                                  }
                                  setValidPasteData(true);
                                } else {
                                  setValidPasteData(false);
                                }
                              }}
                            />
                          </div>
                        ))}
                    </Row>
                  </div>
                  {invalidOTPCodeError?.length !== 0 && (
                    <div className="errorStyle1">{invalidOTPCodeError}</div>
                  )}
                  <Row>
                    <Col className="col-9 d-flex justify-content-start">
                      <div className="didNotGetCodeTextStyle">
                        {constants.LICENSE_DID_NOT_GET_CODE_TEXT}
                      </div>
                      <p></p>
                      <div>
                        <p
                          className={`${
                            resendCodeClicked
                              ? 'clickToResendTextStyle1'
                              : 'clickToResendTextStyle'
                          }`}
                          onClick={handleResendCodeClick}
                        >
                          {constants.LICENSE_RESEND_CODE_TEXT}
                        </p>{' '}
                      </div>
                    </Col>
                    <Col className="col-3 d-flex justify-content-end">
                      <PrimaryButton
                        className="addlicense-btn"
                        fontSize="14px"
                        backgroundColor={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--primary_40')}
                        height="44px"
                        color={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--brand_white')}
                        type="submit"
                        loader={showLoader}
                        disabled={
                          !(
                            dirty &&
                            values?.confirmationCode?.join('').length === 6
                          )
                        }
                      >
                        {constants.LICENSE_SECOND_STEP_SUBMIT_BUTTON_TEXT}
                      </PrimaryButton>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </>
        )}
      {(validConfirmationCode ||
        (!props.valueForFirstFlow && props.valueForSecondFlow)) && (
        <>
          <div className="d-flex align-items-center justify-content-center">
            <img
              className="LicenseSuccessRoundStyle"
              src={LicenseSuccesIcon}
              alt="success-icon"
            />
            <img
              className="LicenseSuccessMarkStyle"
              src={LicenseSuccesMarkIcon}
              alt="success-mark-icon"
            />
          </div>
          {responseAfterOTP && (
            <div className="LicenseSuccessMessageStyle">
              {constants.LICENSE_SUCCESSFULLY_ADDED}{' '}
              {responseAfterOTP?.data?.length}{' '}
              {constants.LICENSE_SUCCESSFULLY_LICENSE_TO} {orgName}
            </div>
          )}
          {props?.valueFromOneOfManualLicense && (
            <div className="LicenseSuccessMessageStyle">
              {constants.LICENSE_SUCCESSFULLY_ADDED}{' '}
              {props?.valueFromOneOfManualLicense?.length}{' '}
              {constants.LICENSE_SUCCESSFULLY_LICENSE_TO} {orgName}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Two;
