import {
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Container,
  Col,
  Dropdown,
  Row,
} from 'react-bootstrap';
import { MuuriComponent as Cameras } from 'muuri-react';
import axios from 'axios';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
  setRemoteStream,
  getCDNInfo,
  setIsReloadedStream,
} from '../../store/reducers/StreamingReducer';
import PageWrapper from '../PageWrapper';
import { AppDefaults, Utils, constants } from '../../helpers';
import {
  DevicesFilter,
  Header,
  SiteModal,
  SiteToast,
  Spinner,
} from '../../components/common/';
import {
  HiAdjustmentsVertical,
  HiChevronDown,
  HiChevronUp,
  HiOutlineTrash,
} from 'react-icons/hi2';

// Sub-components
import CameraItem from './CameraItem';
import SaveView from './modalForms/SaveView';
import SaveAsNewView from './modalForms/SaveAsNewView';
import UpdateView from './modalForms/UpdateView';

import { useOrganizations } from '../../store/OrganizationsStore';
import { useCustomerOrgUsers } from '../../store/CustomerOrgUsersStore';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { useCustomerOrgDevices } from '../../store/CustomerOrgDevicesStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { usePoliciesStore } from '../../store/policiesStore';
import useEventsStore from '../../store/EventsStore';
import { devicesMQTTStore } from '../../store/DevicesMQTTStore';

import { useDispatch, useSelector } from 'react-redux';
import {
  setAllDevicesData,
  setAllDevicesDataNotification,
} from '../../store/reducers/AccountReducer';
import { disconnectWithMQTT } from '../../utils/connection/mqttConnection';

import './VideoWall.scss';
import { IncidentTypeEnum } from '../../helpers/enums';

const VideoWall = () => {
  const RELOAD = 1;
  const location = useLocation();
  const [searchParams] = useSearchParams();

  // Video Wall States
  const [isContentReady, setIsContentReady] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [currentSession, setCurrentSession] = useState(uuidv4());
  const cdnInfo = useSelector(getCDNInfo);
  const [devices, setDevices] = useState([]);
  const [updatedDevice, setUpdatedDevice] = useState([]);
  const [deviceLocationAreas, setDeviceLocationAreas] = useState([]);
  const [initialDevices, setInitialDevices] = useState([]);
  const [hasDevices, setHasDevices] = useState(false);
  const [layoutChanged, setLayoutChanged] = useState(false);
  const [initialLayoutChanged, setInitialLayoutChanged] = useState(false);
  const [savedViews, setSavedViews] = useState([]);
  const [initialSavedViews, setInitialSavedViews] = useState([]);
  const [viewIndex, setViewIndex] = useState(null);
  const [currentViewIndex, setCurrentViewIndex] = useState(null);
  const [viewName, setViewName] = useState(
    constants.CAMERAS_VIDEO_WALL_DEFAULT_VIEW_TITLE
  );
  const [currentView, setCurrentView] = useState();
  const [isViewReset, setIsViewReset] = useState(false);
  const [initialStyles, setInitialStyles] = useState([]);
  const [initialStylesLoaded, setInitialStylesLoaded] = useState(false);
  const [currentStyles, setCurrentStyles] = useState([]);
  const [customViewVisible, setCustomViewVisible] = useState(false);
  const [currentViewId, setCurrentViewId] = useState(null);
  const [showSaveViewModal, setShowSaveViewModal] = useState(false);
  const [showUpdateViewModal, setShowUpdateViewModal] = useState(false);
  const [showSaveAsNewViewModal, setShowSaveAsNewViewModal] = useState(false);
  const [showApplyFiltersModal, setShowApplyFiltersModal] = useState(false);

  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const getUserPushData = useOrganizations((state) => state.getUserPushData);
  const setUserPushData = useOrganizations((state) => state.setUserPushData);

  const {
    getCustomerOrgLocations,
    getCustomerOrgLocationsData,
    getSelectedLocation,
    setLocationSelection,
  } = useCustomerOrgLocations();

  const {
    fetchCustomerOrgDevices,
    getCameraWallDevices,
    setSelectedDeviceFromFillter,
  } = useCustomerOrgDevices();

  const getCustomerOrgUsers = useCustomerOrgUsers(
    (state) => state.getCustomerOrgUsers
  );

  const getCustomerOrgData = useOrganizations(
    (state) => state.getCustomerOrgData
  );
  const setSelfStatus = useOrganizations((state) => state.setSelfStatus);
  const { setSelectedEventStore, setEventFromSearch, setEventCVRMode } =
    useEventsStore();
  const { getLoggedInUserPolicies } = usePoliciesStore();
  const [showLocation, setShowLocation] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const { getState } = devicesMQTTStore;

  const navigate = useNavigate();
  const videoWallRef = useRef(null);
  const dispatch = useDispatch();

  const state = getState();
  const userPolicies = getLoggedInUserPolicies();
  const accountId = loggedInUserData?.accountId;
  let orgDetails = getCustomerOrgData()[0];
  let custOrgLocations = getCustomerOrgLocationsData();
  let selectedLocation = getSelectedLocation(accountId);
  let currentSelectedLocation;

  useEffect(() => {
    const notificationData =
      Object.keys(getUserPushData()).length > 0
        ? getUserPushData()
        : location?.state?.notificationData;
    navigateFromNotification(notificationData, false);
    setCurrentSession(uuidv4());
    fetchCameraWallData();
  }, []);

  useLayoutEffect(() => {
    const fetchData = async () => {
      let filteredDevices;
      let filteredViews;

      const fetchUserSavedViews = await getSavedViews();
      // Filter devices
      if (_.isObject(currentSelectedLocation)) {
        if (
          currentSelectedLocation?.locationId !== AppDefaults.ALL_LOCATIONS_ID
        ) {
          // Filter the devices by location ID, if any
          filteredDevices = initialDevices.filter(
            (device) =>
              device.locationId === currentSelectedLocation?.locationId
          );
          // Filter the saved views by location ID, if any
          filteredViews = initialSavedViews.filter(
            (savedView) =>
              savedView.locationId === currentSelectedLocation?.locationId
          );
        } else {
          if (initialDevices.length > 0) {
            filteredDevices = [...initialDevices];
          } else {
            filteredDevices = getCameraWallDevices();
          }

          if (initialSavedViews.length > 0) {
            filteredViews = [...initialSavedViews];
          } else {
            const userSavedViews = fetchUserSavedViews;
            filteredViews =
              userSavedViews.length > 0 ? [...userSavedViews] : [];
          }
        }
      } else {
        // fetchDevices();
        filteredDevices = getCameraWallDevices();
        filteredViews = fetchUserSavedViews;
      }

      if (filteredDevices.length > 0) {
        setDevices([...filteredDevices]);
        setHasDevices(true);
      } else {
        setHasDevices(false);
      }

      setDevices([...filteredDevices]);
      setUpdatedDevice([...filteredDevices]);
      setSavedViews(filteredViews.length > 0 ? [...filteredViews] : []);
      setIsInitialLoad(false);
    };
    fetchData();
  }, [accountId, currentSelectedLocation]);

  useEffect(() => {
    const custOrgLocations = getCustomerOrgLocationsData();
    if (Array.isArray(custOrgLocations) || custOrgLocations?.length) {
      combineDeviceLocationDetails();
    }
  }, [JSON.stringify(custOrgLocations)]);

  useEffect(() => {
    filterDevices();
  }, [JSON.stringify(selectedLocation)]);

  const updateList = (deviceStepData, status) => {
    if (deviceStepData?.length > 0) {
      getDeviceSelected(deviceStepData, status);
    }
  };
  const getDeviceSelected = (devicesSelected, status) => {
    if (status) {
      setSelectedDeviceFromFillter([]);
      setDevices(updatedDevice);
    } else {
      if (devicesSelected?.length !== 0) {
        let deviceData = [];
        updatedDevice?.map((deviceItem, index) => {
          const deviIdExist = devicesSelected?.find(
            (item) => item === deviceItem?.deviceId
          );
          if (deviIdExist) {
            deviceData.push(deviceItem);
          }
        });
        setSelectedDeviceFromFillter(devicesSelected);
        setDevices(deviceData);
      }
    }
    setShowApplyFiltersModal(false);
  };

  const navigateFromNotification = async (notificationData, navigateStatus) => {
    const isFromPush = searchParams.get('fromPush');
    const param = {
      notificationIds: [notificationData?.nId],
    };
    let response = await axios.put(
      `partner/orgs/${orgDetails?.orgId}/notifications/status/read`,
      param,
      Utils.requestHeader()
    );
    const responseData = response?.data;
    if (responseData?.meta?.code === 200) {
    }

    if (isFromPush || navigateStatus) {
      if (notificationData?.orgId === orgDetails?.orgId) {
        if (
          notificationData?.nType === 'motion_detection' ||
          notificationData?.nType === 'object_detection' ||
          notificationData?.nType === 'system_action' ||
          notificationData?.nType === 'audio_detection'
        ) {
          moveToCamDetails(notificationData);
        } else if (
          notificationData?.nType === 'connectivity' ||
          notificationData?.nType === IncidentTypeEnum?.NOTIFICATION_TYPE
        ) {
          setUserPushData({});
          navigate(`/notificationdashboard.html`);
        }
      }
    }
  };

  const moveToCamDetails = (notificationData) => {
    const endTime = new Date().getTime();
    const eventTimestamp = parseFloat(notificationData?.eventTimestamp);
    const startTime = eventTimestamp - 259200000;
    if (startTime && endTime) {
      axios
        .get(
          `timeline/device/${notificationData?.deviceId}/metadata?startTime=${startTime}&endTime=${endTime}`,
          Utils.requestHeader()
        )
        .then((response) => {
          if (response?.data?.data) {
            let largest = 0;
            const listMeta = response?.data?.data;
            for (let i = 0; i < listMeta?.length; i++) {
              if (listMeta[i].end > largest) {
                largest = listMeta[i].end;
              }
            }
            setUserPushData({});
            if (eventTimestamp <= parseFloat(largest)) {
              setSelectedEventStore(eventTimestamp);
              setEventFromSearch(true);
              setEventCVRMode(true);
              navigate(`/cameras/dashboard.html`, {
                state: {
                  id: notificationData?.deviceId,
                  cdnInfo: cdnInfo ? cdnInfo : {},
                },
              });
            } else if (eventTimestamp > parseFloat(largest)) {
              setSelectedEventStore('null');
              setEventCVRMode('null');
              setEventFromSearch(false);
              navigate(`/cameras/dashboard.html`, {
                state: {
                  id: notificationData?.deviceId,
                  cdnInfo: cdnInfo ? cdnInfo : {},
                },
              });
            }
          }
        });
    }
  };

  const CustomToggle = forwardRef(({ children, onClick }, ref) => (
    <a
      className="button-nav-item"
      href="void();"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick(e);
      }}
    >
      {children}
      <HiChevronDown size={15} />
      <HiChevronUp size={15} className="hidden" />
    </a>
  ));


  const fetchDevices = useCallback(async () => {
    try {
      if (orgDetails?.orgId) {
        // Fetch the latest list of organization locations
        await getCustomerOrgLocations(
          `partner/orgs/${orgDetails?.orgId}/locations`
        );

        // Retrieve current location selected
        currentSelectedLocation = getSelectedLocation(accountId);

        // Fetch the list of devices associated with
        // the user
        let fetchResult = await fetchCustomerOrgDevices(
          `device/orgs/${orgDetails?.orgId}/devices`
        );

        if (fetchResult.status === 'success') {
          setShowLocation(true);
          setSelfStatus('1');
          combineDeviceLocationDetails();
        } else {
          setSelfStatus('1');
          if (!fetchResult?.msg?.includes(AppDefaults.ERR_CANCELED)) {
            setShowToast(true);
            setUserMsg(fetchResult?.msg);
          }
        }
        if (getSelectedLocation(accountId) == undefined) {
          setLocationSelection(accountId, AppDefaults.ALL_LOCATIONS_ID);
        }
      } else {
        throw new Error('ERROR: Could not retrieve organization details');
      }
    } catch (err) {
      setSelfStatus('1');
      if (!err?.msg?.includes(AppDefaults.ERR_CANCELED)) {
        setShowToast(true);
        setUserMsg(err?.msg);
      }
      setIsContentReady(false);
    }
  });

  const combineDeviceLocationDetails = async () => {
    const locationIds = new Set();
    let deviceArea,
      currentArea,
      cameraWallDevices,
      cameraDevice,
      filteredLocationArea,
      filteredDevices;
    const userOrgLocations = getCustomerOrgLocationsData();
    const locationAreas = [];
    let deviceAreaName;

    cameraWallDevices = JSON.parse(JSON.stringify(getCameraWallDevices()));

    // Retrieve the location name for each device
    for (let i = 0; i < cameraWallDevices.length; i++) {
      cameraDevice = cameraWallDevices[i];
      locationIds.add(cameraDevice?.locationId);
      deviceAreaName = '';

      currentArea = userOrgLocations.find(
        (orgLocation) => orgLocation.locationId === cameraDevice?.locationId
      );

      if (
        locationAreas.findIndex(
          (locationArea) => locationArea.locationId === cameraDevice?.locationId
        ) === -1
      ) {
        deviceArea = {
          deviceId: cameraDevice?.deviceId,
          areas: currentArea?.areas.length > 0 ? [...currentArea?.areas] : [],
        };

        locationAreas.push({
          locationId: cameraDevice?.locationId,
          areas: [...deviceArea.areas],
        });
      }

      cameraWallDevices[i].locationName = currentArea?.locationName;
      deviceAreaName = currentArea?.areas.find(
        (area) => area.areaId === cameraWallDevices[i].areaId
      )?.areaName;

      cameraWallDevices[i].areaName = deviceAreaName;
      cameraWallDevices[i].locationAreaNames =
        currentArea?.locationName + ' - ' + deviceAreaName;

      filteredLocationArea = locationAreas.find(
        (locationArea) =>
          locationArea?.locationId === cameraWallDevices[i]?.locationId
      ).areas;

      cameraWallDevices[i].areas = filteredLocationArea
        ? [...filteredLocationArea]
        : [];

      cameraWallDevices[i].style = null;
    }

    await new Promise((resolve) => {
      setInitialDevices(
        cameraWallDevices?.length > 0 ? [...cameraWallDevices] : []
      );
      dispatch(
        setAllDevicesData(
          cameraWallDevices?.length > 0 ? [...cameraWallDevices] : []
        )
      );
      dispatch(
        setAllDevicesDataNotification(
          cameraWallDevices?.length > 0 ? [...cameraWallDevices] : []
        )
      );
      resolve();
    });

    if (_.isObject(currentSelectedLocation)) {
      if (
        currentSelectedLocation?.locationId !== AppDefaults.ALL_LOCATIONS_ID
      ) {
        filteredDevices = cameraWallDevices.filter(
          (device) => device?.locationId === currentSelectedLocation?.locationId
        );
      } else {
        filteredDevices =
          cameraWallDevices?.length > 0 ? [...cameraWallDevices] : [];
      }
    } else {
      filteredDevices =
        cameraWallDevices?.length > 0 ? [...cameraWallDevices] : [];
    }

    // If there is a specific location that was
    // selected previously, we filter the list of
    // devices to only those belonging to that location.
    const fillterByChannel = [...filteredDevices];

    await new Promise((resolve) => {
      setDevices(fillterByChannel?.length > 0 ? [...fillterByChannel] : []);
      setUpdatedDevice(
        fillterByChannel?.length > 0 ? [...fillterByChannel] : []
      );
      resolve();
    });

    setDeviceLocationAreas(locationAreas?.length > 0 ? [...locationAreas] : []);
    setHasDevices(filteredDevices?.length > 0);
    setIsContentReady(true);
  };

  const filterDevices = async () => {
    try {
      // Retrieve current location selected
      currentSelectedLocation = getSelectedLocation(accountId);
      const locationIds = new Set();
      let deviceArea,
        currentArea,
        cameraWallDevices,
        cameraDevice,
        filteredLocationArea,
        filteredDevices;

      const userOrgLocations = getCustomerOrgLocationsData();
      const locationAreas = [];
      let deviceAreaName;

      cameraWallDevices = JSON.parse(JSON.stringify(getCameraWallDevices()));

      // Retrieve the location name for each device
      for (let i = 0; i < cameraWallDevices.length; i++) {
        cameraDevice = cameraWallDevices[i];
        locationIds.add(cameraDevice?.locationId);
        deviceAreaName = '';

        currentArea = userOrgLocations.find(
          (orgLocation) => orgLocation.locationId === cameraDevice?.locationId
        );

        if (
          locationAreas.findIndex(
            (locationArea) =>
              locationArea.locationId === cameraDevice?.locationId
          ) === -1
        ) {
          deviceArea = {
            deviceId: cameraDevice?.deviceId,
            areas: currentArea?.areas.length > 0 ? [...currentArea?.areas] : [],
          };

          locationAreas.push({
            locationId: cameraDevice?.locationId,
            areas: [...deviceArea.areas],
          });
        }

        cameraWallDevices[i].locationName = currentArea?.locationName;
        deviceAreaName = currentArea?.areas.find(
          (area) => area.areaId === cameraWallDevices[i].areaId
        )?.areaName;

        cameraWallDevices[i].areaName = deviceAreaName;
        cameraWallDevices[i].locationAreaNames =
          currentArea?.locationName + ' - ' + deviceAreaName;

        filteredLocationArea = locationAreas.find(
          (locationArea) =>
            locationArea?.locationId === cameraWallDevices[i]?.locationId
        ).areas;

        cameraWallDevices[i].areas = filteredLocationArea
          ? [...filteredLocationArea]
          : [];

        cameraWallDevices[i].style = null;
      }

      await new Promise((resolve) => {
        setInitialDevices(
          cameraWallDevices?.length > 0 ? [...cameraWallDevices] : []
        );
        resolve();
      });

      if (_.isObject(currentSelectedLocation)) {
        if (
          currentSelectedLocation?.locationId !== AppDefaults.ALL_LOCATIONS_ID
        ) {
          filteredDevices = cameraWallDevices.filter(
            (device) =>
              device?.locationId === currentSelectedLocation?.locationId
          );
        } else {
          filteredDevices =
            cameraWallDevices?.length > 0 ? [...cameraWallDevices] : [];
        }
      } else {
        filteredDevices =
          cameraWallDevices?.length > 0 ? [...cameraWallDevices] : [];
      }

      // If there is a specific location that was
      // selected previously, we filter the list of
      // devices to only those belonging to that location.
      await new Promise((resolve) => {
        setDevices(filteredDevices?.length > 0 ? [...filteredDevices] : []);
        setUpdatedDevice(
          filteredDevices?.length > 0 ? [...filteredDevices] : []
        );
        resolve();
      });

      setDeviceLocationAreas(
        locationAreas?.length > 0 ? [...locationAreas] : []
      );
      setHasDevices(filteredDevices?.length > 0);
      setIsContentReady(true);
    } catch (err) {
      if (!err?.msg?.includes(AppDefaults.ERR_CANCELED)) {
        setUserMsg(err?.msg);
      }
      setIsContentReady(false);
    }
  };

  const fetchOrgUsers = useCallback(async () => {
    await getCustomerOrgUsers(`partner/orgs/${orgDetails?.orgId}/accounts`);
  });

  const getSavedViews = useCallback(async () => {
    let userViews = [];
    try {
      const response = await axios.get(
        `/partner/orgs/${orgDetails?.orgId}/userViews`,
        {
          params: {
            clientPlatform: 'web',
            orgId: orgDetails?.orgId,
            locationId: '',
            requestTime: Date.now(),
            sessionId: currentSession,
          },
          ...Utils.requestHeader(currentSession),
          timeout: 30000,
          credentials: 'include',
          withCredentials: true,
        }
      );

      if (response?.status === 200) {
        userViews = response.data?.data;
      } else {
        setShowToast(true);
        setUserMsg(response?.data?.data.msg);
      }
    } catch (err) {
      console.error(err);
    } finally {
      return userViews;
    }
  });

  const fetchSavedViews = useCallback(async () => {
    try {
      // We always fetch all the saved views for the user irrespective
      // of the current location selected. We'll filter the saved views
      // on the client side.
      const userViews = await getSavedViews();
      let filteredUserViews;

      setInitialSavedViews(userViews.length > 0 ? [...userViews] : []);

      if (_.isObject(currentSelectedLocation)) {
        if (
          currentSelectedLocation?.locationId !== AppDefaults.ALL_LOCATIONS_ID
        ) {
          filteredUserViews = userViews.filter(
            (userView) =>
              userView?.locationId === currentSelectedLocation?.locationId
          );
        } else {
          filteredUserViews = [...userViews];
        }
      } else {
        filteredUserViews = [...userViews];
      }

      await new Promise((resolve) => {
        setSavedViews([...filteredUserViews]);
        resolve();
      });
    } catch (err) {
      setShowToast(true);
      setUserMsg(err.msg);
    }
  });

  const fetchCameraWallData = useCallback(async () => {
    if (!orgDetails) {
      orgDetails = getCustomerOrgData()[0];
    }
    // Retrieve all devices when the video wall
    // component mounts
    fetchDevices();
    // fetchSavedViews();
    // Retrieve all organization users
    fetchOrgUsers();
    dispatch(setRemoteStream(null));
    disconnectWithMQTT();

    const onPageLoad = () => {
      setTimeout(() => {
        saveInitialLayout(videoWallRef.current);
        setInitialStylesLoaded(true);
      }, 2750);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
    }

    if (window?.performance) {
      // While performance.navigation has been deprecated,
      // it is the only solution that works.  The updated
      // PerformanceNavigationTiming interface does not seem
      // to work for what we're trying t accomplish.
      if (performance?.navigation?.type === RELOAD) {
        const accountId = loggedInUserData?.accountId;
        const sessionId = state?.getSessionId();

        if (!accountId || !sessionId) {
          return;
        }
        // Delete all unseen events in order to remove
        // all the snapshot highlights
        state?.resetEvents(accountId, sessionId);
      }
    }
  }, []);

  const checkZoomChange = (zoomLevel) => {
    const newStyles = saveStylesAndClasses(videoWallRef?.current?.getItems());

    videoWallRef.current.refreshItems();

    // Set the loaded style config as the current styles
    setCurrentStyles([...newStyles]);
    setLayoutChanged(zoomLevel);
    setLayoutChanged(true);
  };

  const saveStylesAndClasses = (gridItems) => {
    let currentGridStyles = [];

    if (!gridItems) {
      return currentGridStyles;
    }

    try {
      let itemElement, itemElementStyle;

      gridItems.forEach((item) => {
        itemElement = item.getElement();

        itemElementStyle = {
          id: itemElement.id,
          style: itemElement.getAttribute('style'),
          className: itemElement?.className,
          snapshotClassName: itemElement.querySelector(
            `#${itemElement.id}Snapshot`
          )?.className,
          overlayClassName: itemElement.querySelector(
            `#${itemElement.id}Overlay`
          )?.className,
          imageClassName: itemElement.querySelector('img.item-snapshot-image')
            .className,
        };

        currentGridStyles.push(itemElementStyle);
      });
    } catch (err) {
      setShowToast(true);
      setUserMsg(err.msg);
    } finally {
      return currentGridStyles;
    }
  };

  const saveInitialLayout = useCallback((grid) => {
    if (!grid) return;

    let gridItems = grid.getItems();

    if (!initialLayoutChanged) {
      let newStyles = saveStylesAndClasses(gridItems);
      if (Array.isArray(newStyles) && newStyles.length > 0) {
        setInitialStyles([...newStyles]);
        setInitialLayoutChanged(true);
      }
    }
  });

  const applyLayout = (savedStyles) => {
    if (!savedStyles) return;

    try {
      let gridItemElement,
        snapshotElement,
        overlayElement,
        imageElement,
        snapshotClassName,
        overlayClassName,
        imageClassName;

      savedStyles.forEach((savedStyle) => {
        gridItemElement = document.getElementById(savedStyle.id);
        gridItemElement.setAttribute('style', savedStyle.style);
        gridItemElement.setAttribute('class', savedStyle.className);

        // Apply initial snapshot class name
        snapshotElement = gridItemElement.children[0]?.children[0];
        snapshotClassName = savedStyle.snapshotClassName;

        if (snapshotElement && snapshotClassName) {
          snapshotElement.className = snapshotClassName;
        }

        // Apply initial overlay class name
        overlayElement = gridItemElement.children[0]?.children[1];
        overlayClassName = savedStyle.overlayClassName;

        if (overlayElement && overlayClassName) {
          overlayElement.className = overlayClassName;
        }

        // Apply initial image class name
        imageElement =
          gridItemElement.children[0]?.children[0]?.children[0]?.children[0];
        imageClassName = savedStyle.imageClassName;

        if (imageElement && imageClassName) {
          imageElement.className = imageClassName;
        }
      });

      videoWallRef.current.refreshItems();
      // videoWallRef.current.synchronize();
    } catch (err) {
      setShowToast(true);
      setUserMsg(err.msg);
    }
  };

  const loadInitialView = () => {
    applyLayout(initialStyles);

    videoWallRef?.current?.refreshItems();

    // Set the loaded style config as the current styles
    setCurrentStyles([...initialStyles]);

    setCustomViewVisible(false);

    setViewName(constants.CAMERAS_VIDEO_WALL_DEFAULT_VIEW_TITLE);
    setCurrentView({
      id: 'view0',
      name: constants.CAMERAS_VIDEO_WALL_DEFAULT_VIEW_TITLE,
      locationId: 'location0',
    });
    // Reset the layout change flag
    setLayoutChanged(false);
  };

  const loadView = (viewIndex) => {
    let gridItemStyles;

    if (isNaN(viewIndex) || !videoWallRef || !videoWallRef.current) {
      return;
    }

    const customView = savedViews[viewIndex];

    gridItemStyles = JSON.parse(atob(customView.file));
    setCurrentViewId(customView.viewId);
    setCurrentViewIndex(viewIndex);
    setViewName(customView.name);
    setCurrentView({
      id: customView.viewId,
      name: customView.name,
      locationId: customView.locationId,
    });

    // Apply the inline styles and classes to the
    // current grid item elements
    applyLayout(gridItemStyles);
    videoWallRef?.current?.refreshItems();
    const newStyles = saveStylesAndClasses(videoWallRef?.current?.getItems());

    // Set the loaded style config as the current styles
    setCurrentStyles([...newStyles]);
    setCustomViewVisible(true);

    // Reset the layout change flag
    setLayoutChanged(false);
  };

  // Resets the current view to its last saved state
  const resetView = () => {
    if (!customViewVisible) {
      applyLayout(initialStyles);
    } else {
      let gridItemStyles = JSON.parse(atob(savedViews[currentViewIndex].file));
      applyLayout(gridItemStyles);
    }

    videoWallRef.current.refreshItems();

    setIsViewReset(true);
    setLayoutChanged(false);
  };

  const saveView = async (cb) => {
    let isSuccessful = false;
    try {
      let stylesToSave = saveStylesAndClasses(videoWallRef.current.getItems());

      const reqBody = {
        clientPlatform: 'web',
        file: btoa(JSON.stringify(stylesToSave)),
        name: viewName,
        locationId:
          currentSelectedLocation?.locationId || AppDefaults.ALL_LOCATIONS_ID,
        viewType: 'DASHBOARD',
      };

      let response = await axios.post(
        `/partner/orgs/${orgDetails?.orgId}/userViews`,
        reqBody,
        {
          params: {
            orgId: orgDetails?.orgId,
          },
          ...Utils.requestHeader(),
        }
      );

      let resData = response?.data;

      if (resData?.meta.code === 200) {
        // Add the saved view to our savedViews array
        await new Promise((resolve) => {
          setSavedViews((prev) => {
            let retVal, currentIndex;

            if (prev.length === 0) {
              retVal = [resData?.data];
            } else {
              retVal = [...prev, resData?.data];
            }

            currentIndex = retVal.length - 1;

            setCurrentViewId(retVal[currentIndex].viewId);
            setViewIndex(currentIndex);
            setCurrentViewIndex(currentIndex);
            setViewName(retVal[currentIndex].name);
            setCurrentView({
              id: retVal[currentIndex].viewId,
              name: retVal[currentIndex].name,
              locationId: retVal[currentIndex].locationId,
            });
            setCustomViewVisible(true);
            return retVal;
          });
          resolve();
        });

        setLayoutChanged(false);
        isSuccessful = true;
      } else {
        setShowToast(true);
        setUserMsg(response.data.data.msg);
      }
    } catch (err) {
      setShowToast(true);
      setUserMsg(err.msg);
    } finally {
      cb && cb(false);
    }

    return isSuccessful;
  };

  const updateView = async (cb) => {
    let isSuccessful = false;

    try {
      cb && cb(true);

      let stylesToSave = saveStylesAndClasses(videoWallRef.current.getItems());

      const reqBody = {
        clientPlatform: 'web',
        file: btoa(JSON.stringify(stylesToSave)),
        name: currentView.name,
      };

      let response = await axios.put(
        `/partner/orgs/${orgDetails?.orgId}/userViews/${currentViewId}`,
        reqBody,
        {
          params: {
            orgId: orgDetails?.orgId,
            viewId: currentView.viewId,
          },
          ...Utils.requestHeader(),
        }
      );

      let resData = response?.data;

      if (resData?.meta?.code === 200) {
        // Update the saved view in our savedViews array
        await new Promise((resolve) => {
          savedViews[currentViewIndex] = resData?.data;
          resolve();
        });

        setLayoutChanged(false);
        isSuccessful = true;
      } else {
        setShowToast(true);
        setUserMsg(resData?.data?.msg);
      }
    } catch (err) {
      setShowToast(true);
      setUserMsg(err.msg);
    } finally {
      cb && cb(false);
    }

    return isSuccessful;
  };

  const deleteView = async (viewId) => {
    if (!viewId) return;

    try {
      let result = await axios.delete(
        `/partner/orgs/${orgDetails?.orgId}/userViews/${viewId}`,
        Utils.requestHeader()
      );

      let resData = result?.data;

      if (resData?.meta.code === 200) {
        fetchSavedViews();
      } else {
        setShowToast(true);
        setUserMsg(resData?.data?.msg);
      }
    } catch (err) {
      setShowToast(true);
      setUserMsg(err.msg);
    }
  };

  const actionButtonGroup = customViewVisible ? (
    <>
      <Button
        variant="primary"
        className="action-button"
        onClick={() => setShowUpdateViewModal(true)}
      >
        {constants.CAMERAS_VIDEO_WALL_UPDATE_VIEW_BUTTON_LABEL}
      </Button>
      <Button
        variant="primary"
        className="action-button"
        onClick={() => setShowSaveAsNewViewModal(true)}
      >
        {constants.CAMERAS_VIDEO_WALL_SAVE_AS_NEW_VIEW_BUTTON_LABEL}
      </Button>
      <Button variant="primary" className="action-button" onClick={resetView}>
        {constants.CAMERAS_VIDEO_WALL_RESET_VIEW_BUTTON_LABEL}
      </Button>
    </>
  ) : (
    <>
      <Button
        variant="primary"
        className="action-button"
        onClick={() => setShowSaveViewModal(true)}
      >
        {constants.CAMERAS_VIDEO_WALL_SAVE_VIEW_BUTTON_LABEL}
      </Button>{' '}
      <Button variant="primary" className="action-button" onClick={resetView}>
        {constants.CAMERAS_VIDEO_WALL_RESET_VIEW_BUTTON_LABEL}
      </Button>
    </>
  );

  const cameraWallContent = hasDevices ? (
    <Cameras
      id="videoWall"
      ref={videoWallRef}
      instantLayout
      dragEnabled
      dragContainer={document.body}
      // The dragStartPredicate allows us to suppress the drag start event
      // temporarily so that the click event can be triggered immediately.
      // DO NOT remove this prop or set the 'distance' property to a number
      // less than 1.
      dragStartPredicate={{ distance: 1, delay: 0 }}
      layout={{
        fillGaps: false,
        horizontal: false,
        alignRight: false,
        alignBottom: false,
        rounding: true,
      }}
      containerClass={'cameras'}
      itemClass={'cameras-item'}
      itemVisibleClass={'cameras-item-shown'}
      itemHiddenClass={'cameras-item-hidden'}
      itemPositioningClass={'cameras-item-positioning'}
      itemDraggingClass={'cameras-item-dragging'}
      itemReleasingClass={'cameras-item-releasing'}
      itemPlaceholderClass={'cameras-item-placeholder'}
      onDragEnd={(item) => {
        setLayoutChanged(true);
      }}
    >
      {Array.isArray(devices) &&
        devices.map((device, deviceIndex) => (
          <CameraItem
            key={device.deviceId}
            deviceElemId={`camera${deviceIndex}`}
            inlineStyle={device?.style}
            orgId={orgDetails?.orgId}
            cdnInfo={cdnInfo}
            locationAreaName={device.locationAreaNames}
            isHighlighted={false}
            showZoomOptions={true}
            zoomCallback={checkZoomChange}
            currentLayoutStatus={layoutChanged}
            conStatus={device.connectionStatus || 'offline'}
            hubId={device.gatewayId}
            clickHandler={() => {
              setSelectedEventStore('null');
              setEventCVRMode('null');
              setEventFromSearch(false);
              dispatch(setIsReloadedStream(false));
              navigate(`/cameras/dashboard.html`, {
                state: {
                  id: device?.deviceId,
                  cdnInfo: cdnInfo,
                },
              });
            }}
            {...device}
          />
        ))}
    </Cameras>
  ) : (
    <div className="no-cameras-container">
      <div className="no-cameras-notice">
        {constants.CAMERAS_VIDEO_WALL_NO_CAMERAS_AVAILABLE_LABEL}
      </div>
      {userPolicies.install_device && userPolicies.claim_device && (
        <div className="no-cameras-cta">
          <Button
            className="no-cameras-add-device-btn"
            variant="outline-secondary"
            onClick={(e) => {
              navigate(`/devices/listing.html?orgId=${orgDetails?.orgId}`);
            }}
          >
            {constants.CAMERAS_VIDEO_WALL_ADD_DEVICE_BUTTON_LABEL}
          </Button>
        </div>
      )}
    </div>
  );

  const onMessageRecieve = (notificationData) => {
    navigateFromNotification(notificationData, true);
  };

  return (
    <div className="App video-wall">
      <Header
        isFromVideoWall={true}
        onPushReceive={(value) => {
          onMessageRecieve(value);
        }}
        showLocation={showLocation}
        showCart={false}
      />
      <div className="main-wrapper">
        <PageWrapper className="video-wall-background" fluid>
          <Row className="video-wall-wrapper">
            <Col>
              <Container className="video-wall-container">
                <div className="page-header mt-4 mb-5">
                  <Row>
                    <div className="toast-wrapper">
                      <SiteToast
                        customCss="licenses-list-toast"
                        position="top-end"
                        show={showToast}
                        title="Uh-oh!"
                        body={userMsg}
                        delay={5000}
                      />
                      <span className="page-title">
                        {constants.CAMERAS_VIDEO_WALL_TITLE}
                      </span>
                      <div className="button-nav">
                        <Button
                          className="button-nav-item filter-button"
                          onClick={() => setShowApplyFiltersModal(true)}
                        >
                          <HiAdjustmentsVertical
                            size={15}
                            className="filter-icon"
                          />{' '}
                          {constants.CAMERAS_VIDEO_WALL_FILTER_BUTTON_LABEL}
                        </Button>
                        {savedViews.length > 0 && (
                          <Dropdown>
                            <Dropdown.Toggle
                              as={CustomToggle}
                              id="dropdown-custom-components"
                              className="button-nav-item saved-view-button"
                            >
                              {viewName}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                eventKey="1"
                                className=""
                                onClick={() => loadInitialView()}
                              >
                                {
                                  constants.CAMERAS_VIDEO_WALL_DEFAULT_VIEW_TITLE
                                }
                              </Dropdown.Item>
                              <Dropdown.ItemText className="">
                                {constants.CAMERAS_VIDEO_WALL_MY_VIEWS_TITLE}
                              </Dropdown.ItemText>
                              {savedViews.map((savedView, savedViewIndex) => (
                                <Dropdown.Item
                                  key={savedViewIndex}
                                  eventKey={savedViewIndex + 2}
                                  className=""
                                >
                                  <div className="dropdown-item-wrapper">
                                    <div
                                      className="load-view"
                                      onClick={() => {
                                        loadView(savedViewIndex);
                                      }}
                                    >
                                      {savedView.name}
                                    </div>
                                    <HiOutlineTrash
                                      onClick={(e) => {
                                        e?.stopPropagation();
                                        deleteView(savedView.viewId);
                                      }}
                                    />
                                  </div>
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </div>
                    </div>
                  </Row>
                  <Row>
                    {/* <Col className="cameras-container">{renderDevices()}</Col> */}
                    <Col className="cameras-container">
                      {!isContentReady ? (
                        <Spinner
                          height="25"
                          width="25"
                          strokeColor={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--brand_white')}
                          strokeWidth="5"
                          fill="none"
                        />
                      ) : (
                        cameraWallContent
                      )}
                    </Col>
                  </Row>
                </div>
              </Container>
            </Col>
          </Row>
        </PageWrapper>
        <div className={`action-buttons${!layoutChanged ? ' hidden' : ''}`}>
          <ButtonToolbar aria-label="Toolbar with button groups">
            <ButtonGroup className="me-2" aria-label="First group">
              {actionButtonGroup}
            </ButtonGroup>
          </ButtonToolbar>
        </div>
        {/* Modals */}
        {/* Save View Modal */}
        <SiteModal
          key={'save-view-modal'}
          classes="save-view-modal"
          wrapperClass="save-view-modal"
          modalTitle={constants.CAMERAS_VIDEO_WALL_SAVE_VIEW_MODAL_TITLE}
          showModal={showSaveViewModal}
          hideModal={() => setShowSaveViewModal(false)}
        >
          <SaveView
            setViewName={setViewName}
            callBack={saveView}
            openModal={setShowSaveViewModal}
          ></SaveView>
        </SiteModal>
        {/* Update View Modal */}
        <SiteModal
          key={'update-view-modal'}
          classes="update-view-modal"
          wrapperClass="update-view-modal"
          modalTitle={constants.CAMERAS_VIDEO_WALL_UPDATE_VIEW_MODAL_TITLE}
          showModal={showUpdateViewModal}
          hideModal={() => setShowUpdateViewModal(false)}
        >
          <UpdateView
            setViewName={setCurrentView}
            currentViewLayout=""
            saveAsNewCallBack={setShowSaveViewModal}
            updateViewCallback={(cb) => updateView(cb)}
            openModal={setShowUpdateViewModal}
          ></UpdateView>
        </SiteModal>
        {/* Save As New View Modal */}
        <SiteModal
          key={'save-as-new-view-modal'}
          classes="save-as-new-view-modal"
          wrapperClass="save-as-new-view-modal"
          modalTitle={constants.CAMERAS_VIDEO_WALL_SAVE_VIEW_MODAL_TITLE}
          showModal={showSaveAsNewViewModal}
          hideModal={() => setShowSaveAsNewViewModal(false)}
        >
          <SaveAsNewView
            setViewName={setViewName}
            callBack={saveView}
            openModal={setShowSaveAsNewViewModal}
          ></SaveAsNewView>
        </SiteModal>
        {/* Apply Filters Modal */}
        <SiteModal
          modalTitle={constants.CAMERAS_VIDEO_WALL_FILTER_MODAL_TITLE}
          showModal={showApplyFiltersModal}
          hideModal={() => {
            setShowApplyFiltersModal(false);
          }}
          classes="apply-filters-modal"
          width={'582px'}
        >
          <DevicesFilter
            callback={(deviceData, status) => {
              updateList(deviceData, status);
            }}
            devices={updatedDevice}
            filterDescription={
              constants.CAMERAS_VIDEO_WALL_FILTER_MODAL_DESCRIPTION
            }
            applyButtonLabel={
              constants.CAMERAS_VIDEO_WALL_FILTER_MODAL_APPLY_FILTER_LABEL
            }
            clearFilterPlacement="top"
          />
        </SiteModal>
      </div>
    </div>
  );
};

export default VideoWall;
