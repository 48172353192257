import { useCallback, useEffect, useState, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Button } from 'react-bootstrap';
import { IoIosSearch } from 'react-icons/io';
import { FaCircle } from 'react-icons/fa';
import { deviceOptions } from '../../assets/images';
import { constants, Utils, AppDefaults, roles } from '../../helpers/';
import { useOrganizations } from '../../store/OrganizationsStore';
import { useCustOrgLocationArea } from '../../store/CustOrgLocationAreaStore';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { useCustomerOrgDevices } from '../../store/CustomerOrgDevicesStore';
import {
  setRemoteStream,
  getCDNInfo,
  setIsReloadedStream,
} from '../../store/reducers/StreamingReducer';
import {
  setDeviceInformation,
  setAllDevicesData,
  setAllDevicesDataNotification,
} from '../../store/reducers/AccountReducer';
import { SiteSpinner } from '../../components/common/';
import { disconnectWithMQTT } from '../../utils/connection/mqttConnection';
import './CameraWallDevices.scss';
import NavDropdown from 'react-bootstrap/NavDropdown';
import CameraWallSnapshot from './CameraWallSnapshot';
import CameraWallTable from './CameraWallTable';
import LocationAreaSelector from './LocationAreaSelector';
import { usePoliciesStore } from '../../store/policiesStore';
import { FiCameraOff } from 'react-icons/fi';
import { MdOutlineAddAPhoto } from 'react-icons/md';
import { HiOutlineVideoCamera } from 'react-icons/hi2';
import { MdOutlineDragIndicator } from 'react-icons/md';
import { FiSettings } from 'react-icons/fi';
import { RxCrossCircled } from 'react-icons/rx';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';

const CameraWallDevices = ({
  viewDetails,
  setSelectedViewDevices,
  setTotalDeviceCount,
  newViewDragMode,
  setNewViewDragMode,
  editView,
  isDragging,
}) => {
  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [currentSession, setCurrentSession] = useState(uuidv4());
  const [devices, setDevices] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const cdnInfo = useSelector(getCDNInfo);
  const [hasDevices, setHasDevices] = useState(false);
  let [searchParams] = useSearchParams();
  const viewId = searchParams.get('viewId');
  const [viewDevices, setViewDevices] = useState([]);
  const [viewDevicesDetails, setViewDevicesDetails] = useState({});
  const dispatch = useDispatch();
  const {
    getDeviceWallSelectedLocation,
    setDeviceWallSelectedLocation,
    getDeviceWallSelectedArea,
    setDeviceWallSelectedArea,
  } = useCustOrgLocationArea();
  const { getCustomerOrgLocations, getCustomerOrgLocationsData } =
    useCustomerOrgLocations();

  const selectedLocation = getDeviceWallSelectedLocation();
  const selectedArea = getDeviceWallSelectedArea();
  const getCustomerOrgData = useOrganizations(
    (state) => state.getCustomerOrgData
  );
  let orgDetails = getCustomerOrgData()[0];
  const setSelfStatus = useOrganizations((state) => state.setSelfStatus);
  const navigate = useNavigate();
  const {
    fetchCustomerOrgDevices,
    getCameraWallDevices,
    getCustomerOrgDevices,
  } = useCustomerOrgDevices();
  const custOrgLocations = getCustomerOrgLocationsData();
  const custOrgDevices = getCameraWallDevices();
  const allDevices = getCustomerOrgDevices();
  const { getLoggedInUserPolicies } = usePoliciesStore();
  const userPolicies = getLoggedInUserPolicies();
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData
  );
  const loggedInUser = getLoggedInUserData();
  useEffect(() => {
    setViewDevices(viewDetails?.devices ? viewDetails?.devices : []);
    updateViewDevicesDetils(viewDetails?.devices ? viewDetails?.devices : []);
    combineDeviceAndLocationData();
    setNewViewDragMode(false);
  }, [viewDetails]);

  useEffect(() => {
    if (custOrgLocations?.length) {
      combineDeviceAndLocationData();
    }
  }, [custOrgLocations, custOrgDevices]);

  useEffect(() => {
    if (devices?.length) {
      filterDevicesByLocationAndArea(devices, viewDevices);
    }
  }, [selectedLocation, selectedArea, searchText]);

  useEffect(() => {
    setCurrentSession(uuidv4());
    if (!selectedLocation.locationId) {
      setDeviceWallSelectedLocation({
        locationId: AppDefaults.ALL_LOCATIONS_ID,
      });
    }
    if (!selectedArea.areaId) {
      setDeviceWallSelectedArea({
        areaId: constants.LOCATION_DROPDOWN_ALL_AREAS_ID,
      });
    }
    fetchCameraWallData();
  }, []);

  const updateViewDevicesDetils = (viewDevices) => {
    const data = {};
    viewDevices.forEach((deviceId) => {
      const childDevice = devices.find(
        (device) => device.deviceId === deviceId
      );
      data[deviceId] = childDevice;
    });
    setViewDevicesDetails(data);
  };
  const fetchCameraWallData = useCallback(async () => {
    setIsLoading(true);
    if (!orgDetails) {
      orgDetails = getCustomerOrgData()[0];
    }
    fetchLocations();
    fetchDevices();
    dispatch(setRemoteStream(null));
    disconnectWithMQTT();
  }, []);

  const fetchDevices = useCallback(async () => {
    try {
      if (orgDetails?.orgId) {
        let fetchResult = await fetchCustomerOrgDevices(
          `device/orgs/${orgDetails?.orgId}/devices`
        );

        if (fetchResult.status === 'success') {
          setSelfStatus('1');
        } else {
          setSelfStatus('1');
          if (!fetchResult?.msg?.includes(AppDefaults.ERR_CANCELED)) {
            setShowToast(true);
            setUserMsg(fetchResult?.msg);
          }
          setIsLoading(false);
        }
      } else {
        throw new Error('ERROR: Could not retrieve organization details');
      }
    } catch (err) {
      setSelfStatus('1');
      if (!err?.msg?.includes(AppDefaults.ERR_CANCELED)) {
        setShowToast(true);
        setUserMsg(err?.msg);
      }
      setIsLoading(false);
    }
  });

  const fetchLocations = useCallback(async () => {
    try {
      if (orgDetails?.orgId) {
        let fetchResult;
        if (
          !custOrgLocations?.length ||
          (custOrgLocations?.length &&
            custOrgLocations[0].orgId !== orgDetails?.orgId)
        ) {
          fetchResult = await getCustomerOrgLocations(
            `partner/orgs/${orgDetails?.orgId}/locations`
          );
          if (fetchResult.status === 'success') {
          } else {
            if (!fetchResult?.msg?.includes(AppDefaults.ERR_CANCELED)) {
              setShowToast(true);
              setUserMsg(fetchResult?.msg);
            }
            setIsLoading(false);
          }
        }
      } else {
        throw new Error('ERROR: Could not retrieve organization details');
      }
    } catch (err) {
      if (!err?.msg?.includes(AppDefaults.ERR_CANCELED)) {
        setShowToast(true);
        setUserMsg(err?.msg);
      }
      setIsLoading(false);
    }
  });

  const combineDeviceAndLocationData = () => {
    setIsLoading(true);
    let deviceArea,
      currentArea,
      cameraWallDevices,
      cameraDevice,
      filteredLocationArea;
    const locationAreas = [];
    const checkedStatusArray = [];
    let deviceAreaName;
    cameraWallDevices = JSON.parse(JSON.stringify(custOrgDevices));

    // Retrieve the location name for each device
    for (let i = 0; i < cameraWallDevices.length; i++) {
      cameraDevice = cameraWallDevices[i];
      deviceAreaName = '';

      currentArea = custOrgLocations.find(
        (orgLocation) => orgLocation.locationId === cameraDevice?.locationId
      );

      if (
        locationAreas.findIndex(
          (locationArea) => locationArea.locationId === cameraDevice?.locationId
        ) === -1
      ) {
        deviceArea = {
          deviceId: cameraDevice?.deviceId,
          areas: currentArea?.areas.length > 0 ? [...currentArea?.areas] : [],
        };

        locationAreas.push({
          locationId: cameraDevice?.locationId,
          areas: [...deviceArea.areas],
        });
      }

      cameraWallDevices[i].locationName = currentArea?.locationName;
      deviceAreaName = currentArea?.areas.find(
        (area) => area.areaId === cameraWallDevices[i].areaId
      )?.areaName;

      cameraWallDevices[i].areaName = deviceAreaName;
      cameraWallDevices[i].locationAreaNames =
        currentArea?.locationName + ' - ' + deviceAreaName;

      filteredLocationArea = locationAreas.find(
        (locationArea) =>
          locationArea?.locationId === cameraWallDevices[i]?.locationId
      ).areas;

      cameraWallDevices[i].areas = filteredLocationArea
        ? [...filteredLocationArea]
        : [];

      const viewStatus = viewDetails?.devices?.includes(cameraDevice?.deviceId);
      cameraWallDevices[i].viewChecked = viewStatus ? viewStatus : false;
      cameraWallDevices[i].style = null;
      cameraWallDevices[i].displayDeviceStatus = Utils.getDeviceStatus(
        cameraWallDevices[i].deviceStatus,
        cameraWallDevices[i].connectionStatus
      );
      if (viewStatus) {
        checkedStatusArray.push(cameraDevice?.deviceId);
      }
    }
    setViewDevices(checkedStatusArray);
    updateViewDevicesDetils(checkedStatusArray);
    const updatedAllDevices = allDevices.map((d) => {
      const foundDevice = cameraWallDevices.find(
        (c) => c.deviceId === d.deviceId
      );
      return foundDevice ? foundDevice : d;
    });
    dispatch(
      setAllDevicesData(
        updatedAllDevices?.length > 0 ? [...updatedAllDevices] : []
      )
    );
    dispatch(
      setAllDevicesDataNotification(
        cameraWallDevices?.length > 0 ? [...cameraWallDevices] : []
      )
    );
    setDevices(cameraWallDevices?.length > 0 ? [...cameraWallDevices] : []);
    setTotalDeviceCount(
      cameraWallDevices?.length > 0 ? cameraWallDevices?.length : 0
    );
    filterDevicesByLocationAndArea(cameraWallDevices, checkedStatusArray);
  };

  const filterDevicesByLocationAndArea = async (
    devciesList,
    checkedStatusArray
  ) => {
    setIsLoading(true);
    let filteredDeviceData;
    if (_.isObject(selectedLocation) && selectedLocation?.locationId) {
      if (selectedLocation?.locationId !== AppDefaults.ALL_LOCATIONS_ID) {
        filteredDeviceData = devciesList.filter(
          (device) => device?.locationId === selectedLocation?.locationId
        );
      } else {
        filteredDeviceData = devciesList?.length > 0 ? [...devciesList] : [];
      }
    } else {
      filteredDeviceData = devciesList?.length > 0 ? [...devciesList] : [];
    }

    if (_.isObject(selectedArea) && selectedArea?.areaId) {
      if (selectedArea?.areaId !== constants.LOCATION_DROPDOWN_ALL_AREAS_ID) {
        filteredDeviceData = filteredDeviceData.filter(
          (device) => device?.areaId === selectedArea?.areaId
        );
      } else {
        filteredDeviceData =
          filteredDeviceData?.length > 0 ? [...filteredDeviceData] : [];
      }
    } else {
      filteredDeviceData =
        filteredDeviceData?.length > 0 ? [...filteredDeviceData] : [];
    }
    if (searchText) {
      filteredDeviceData = filteredDeviceData.filter((device) =>
        device?.deviceName?.toUpperCase().includes(searchText.toUpperCase())
      );
    }
    filteredDeviceData = filteredDeviceData.filter(
      (d) =>
        d.displayDeviceStatus === constants.DEVICES_RETURN_DEACTIVATED_STATUS ||
        (d.displayDeviceStatus !== constants.DEVICES_RETURN_CLAIMING_STATUS &&
          d?.apps?.vms)
    );
    const fillterByChannel = JSON.parse(
      JSON.stringify([...filteredDeviceData])
    );
    fillterByChannel.forEach((device) => {
      if (checkedStatusArray.includes(device?.deviceId)) {
        device.viewChecked = true;
      } else {
        device.viewChecked = false;
      }
    });
    setFilteredDevices(
      fillterByChannel?.length > 0 ? [...fillterByChannel] : []
    );
    setHasDevices(fillterByChannel?.length > 0 ? true : false);
    setIsLoading(false);
  };

  const onChangeSearchText = (e) => {
    setSearchText(e?.target?.value);
  };
  const OnClickCameraDetails = (device) => {
    dispatch(setIsReloadedStream(false));
    dispatch(setDeviceInformation(device));
    navigate(`/cameras/dashboard.html`, {
      state: {
        id: device?.deviceId,
        cdnInfo: cdnInfo ? cdnInfo : {},
      },
    });
  };
  const OnClickCameraSettings = (device) => {
    dispatch(setDeviceInformation(device));
    device?.deviceId &&
      navigate(`/devices/dashboard.html?deviceId=${device?.deviceId}`);
  };

  const onChanegCheckBox = (e, device) => {
    const { checked } = e.target;
    if (filteredDevices.length) {
      const filteredList = JSON.parse(JSON.stringify(filteredDevices));
      const checkedDevice = filteredList.find(
        (abc) => abc.deviceId === device?.deviceId
      );
      const checkedDeviceIndex = filteredList?.indexOf(checkedDevice, 0);
      if (checkedDeviceIndex > -1) {
        filteredList[checkedDeviceIndex].viewChecked = checked;
        setFilteredDevices(filteredList);
      }
      let viewList = JSON.parse(JSON.stringify(viewDevices));
      if (checked) {
        viewList.push(device?.deviceId);
        if (!newViewDragMode) {
          setNewViewDragMode(true);
        }
      } else {
        const index = viewList.indexOf(device?.deviceId, 0);
        if (index > -1) {
          viewList.splice(index, 1);
        }
      }
      setViewDevices(viewList);
      updateViewDevicesDetils(viewList);
      setSelectedViewDevices(viewList);
    }
  };
  const getDeviceStatus = (device) => {
    if (
      device?.deviceStatus?.toUpperCase() ===
      constants.DEVICES_DEACTIVATED_DEVICE_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-ready-to-claim" />
          {constants.DEVICES_RETURN_DEACTIVATED_STATUS}
        </div>
      );
    } else if (
      device?.displayDeviceStatus === constants.DEVICES_RETURN_OFFLINE_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-offline" />{' '}
          {device?.displayDeviceStatus}
        </div>
      );
    } else if (
      device?.displayDeviceStatus === constants.DEVICES_RETURN_ONLINE_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-online" />{' '}
          {device?.displayDeviceStatus}
        </div>
      );
    } else if (
      device?.displayDeviceStatus ===
      constants.DEVICES_RETURN_READY_TO_CLAIM_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-ready-to-claim" />{' '}
          {device?.displayDeviceStatus}
        </div>
      );
    } else if (
      device?.displayDeviceStatus === constants.DEVICES_RETURN_ENTERED_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-entered" />{' '}
          {device?.displayDeviceStatus}
        </div>
      );
    } else if (
      device?.displayDeviceStatus === constants.DEVICES_RETURN_CLAIMING_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-claiming" />
          {device?.displayDeviceStatus}
        </div>
      );
    } else if (
      device?.displayDeviceStatus ===
      constants.DEVICES_RETURN_DEACTIVATED_STATUS
    ) {
      return (
        <div>
          <FaCircle className="status-indicator status-offline" />
          {device?.displayDeviceStatus}
        </div>
      );
    }
  };
  const columnsData1 = useMemo(
    () => [
      {
        id: 'viewChecked',
        Header: '',
        accessor: 'viewChecked',
        disableSortBy: true,
        Cell: ({ row }) => {
          let device = row.original;
          return (
            <div className="viewCheckbox">
              {device?.viewChecked ? (
                <MdOutlineDragIndicator className="drag-icon" />
              ) : null}
              <input
                id={`checkbox-${device.deviceId}`}
                type="checkbox"
                checked={device?.viewChecked}
                onChange={(e) => onChanegCheckBox(e, device)}
              />
            </div>
          );
        },
      },
      {
        Header: '',
        accessor: 'snapshot',
        disableSortBy: true,
        Cell: ({ row }) => {
          let device = row.original;
          return (
            <span className="snapshot-wrapper">
              <CameraWallSnapshot
                key={device.deviceId}
                deviceElemId={`camera${device.deviceId}`}
                orgId={orgDetails?.orgId}
                cdnInfo={cdnInfo}
                locationAreaName={device.locationAreaNames}
                isHighlighted={false}
                showZoomOptions={false}
                conStatus={device.connectionStatus || 'offline'}
                hubId={device.deviceId}
                {...device}
              />
            </span>
          );
        },
      },
      {
        Header: constants.DEVCIE_WALL_HEADER_DEVICE,
        accessor: 'deviceName',
        sortType: (sortPrev, sortAfter) => {
          if (
            sortPrev.values['deviceName']?.toLowerCase() >
            sortAfter.values['deviceName']?.toLowerCase()
          ) {
            return -1;
          } else if (
            sortPrev.values['deviceName']?.toLowerCase() <
            sortAfter.values['deviceName']?.toLowerCase()
          ) {
            return 1;
          } else {
            return 0;
          }
        },
      },
      {
        Header: constants.DEVCIE_WALL_HEADER_lOCATION,
        accessor: 'locationName',
        sortType: 'basic',
      },
      {
        Header: constants.DEVCIE_WALL_HEADER_AREA,
        accessor: 'areaName',
        sortType: 'basic',
      },
      {
        Header: constants.DEVCIE_WALL_HEADER_STATUS,
        accessor: 'displayDeviceStatus',
        Cell: ({ row }) => {
          let device = row.original;
          return <>{getDeviceStatus(device)}</>;
        },
        sortType: (sortPrev, sortAfter) => {
          if (
            sortPrev.values['displayDeviceStatus']?.toLowerCase() >
            sortAfter.values['displayDeviceStatus']?.toLowerCase()
          ) {
            return -1;
          } else if (
            sortPrev.values['displayDeviceStatus']?.toLowerCase() <
            sortAfter.values['displayDeviceStatus']?.toLowerCase()
          ) {
            return 1;
          } else {
            return 0;
          }
        },
      },
    ],
    [filteredDevices, viewDevices, viewId, newViewDragMode]
  );

  const columnsData2 = useMemo(
    () => [
      {
        id: 'viewChecked',
        Header: '',
        accessor: 'viewChecked',
        disableSortBy: true,
        Cell: ({ row }) => {
          let device = row.original;
          return (
            <div className="viewCheckbox">
              {device?.viewChecked ? (
                <MdOutlineDragIndicator className="drag-icon" />
              ) : null}
              <input
                id={`checkbox-${device.deviceId}`}
                type="checkbox"
                checked={device?.viewChecked}
                onChange={(e) => onChanegCheckBox(e, device)}
              />
            </div>
          );
        },
      },
      {
        Header: '',
        accessor: 'snapshot',
        disableSortBy: true,
        Cell: ({ row }) => {
          let device = row.original;
          return (
            <span className="snapshot-wrapper">
              <CameraWallSnapshot
                key={device.deviceId}
                deviceElemId={`camera${device.deviceId}`}
                orgId={orgDetails?.orgId}
                cdnInfo={cdnInfo}
                locationAreaName={device.locationAreaNames}
                isHighlighted={false}
                showZoomOptions={false}
                conStatus={device.connectionStatus || 'offline'}
                hubId={device.gatewayId}
                {...device}
              />
            </span>
          );
        },
      },
      {
        Header: constants.DEVCIE_WALL_HEADER_DEVICE,
        accessor: 'deviceName',
        disableSortBy: false,
        Cell: ({ row }) => {
          let device = row.original;
          return (
            <span
              className="deviceNameClick"
              onClick={() => OnClickCameraDetails(row.original)}
            >
              {device.deviceName}
            </span>
          );
        },
        sortType: (sortPrev, sortAfter) => {
          if (
            sortPrev.values['deviceName']?.toLowerCase() >
            sortAfter.values['deviceName']?.toLowerCase()
          ) {
            return -1;
          } else if (
            sortPrev.values['deviceName']?.toLowerCase() <
            sortAfter.values['deviceName']?.toLowerCase()
          ) {
            return 1;
          } else {
            return 0;
          }
        },
      },
      {
        Header: constants.DEVCIE_WALL_HEADER_lOCATION,
        accessor: 'locationName',
        disableSortBy: false,
        sortType: 'basic',
      },
      {
        Header: constants.DEVCIE_WALL_HEADER_AREA,
        accessor: 'areaName',
        disableSortBy: false,
        sortType: 'basic',
      },
      {
        Header: constants.DEVCIE_WALL_HEADER_STATUS,
        accessor: 'displayDeviceStatus',
        Cell: ({ row }) => {
          let device = row.original;
          return <>{getDeviceStatus(device)}</>;
        },
        sortType: (sortPrev, sortAfter) => {
          if (
            sortPrev.values['displayDeviceStatus']?.toLowerCase() >
            sortAfter.values['displayDeviceStatus']?.toLowerCase()
          ) {
            return -1;
          } else if (
            sortPrev.values['displayDeviceStatus']?.toLowerCase() <
            sortAfter.values['displayDeviceStatus']?.toLowerCase()
          ) {
            return 1;
          } else {
            return 0;
          }
        },
        disableSortBy: false,
      },
      {
        Header: '',
        accessor: 'cameraOptions',
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <NavDropdown
              className="table-action-dropdown"
              title={
                <div>
                  <img src={deviceOptions} alt=""></img>
                </div>
              }
            >
              <NavDropdown.Item
                onClick={() => OnClickCameraDetails(row.original)}
              >
                <HiOutlineVideoCamera
                  size={20}
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--greyscale_64')}
                />
                {constants.DEVCIE_WALL_DEVICE_OPTION_DETAILS}
              </NavDropdown.Item>
              {
                userPolicies.view_device_settings && userPolicies.claim_device && userPolicies.assign_license && <NavDropdown.Item
                onClick={() => OnClickCameraSettings(row.original)}
              >
                <FiSettings
                  size={20}
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--greyscale_64')}
                />
                {constants.DEVCIE_WALL_DEVICE_OPTION_SETTINGS}
              </NavDropdown.Item>
                }
            </NavDropdown>
          );
        },
      },
    ],
    [filteredDevices, viewDevices, viewId, newViewDragMode]
  );

  const sortingData = useMemo(
    () => [
      {
        id: 'deviceName',
        desc: true,
      },
      {
        id: 'locationName',
        desc: true,
      },
      {
        id: 'areaName',
        desc: true,
      },
      {
        id: 'connectionStatus',
        desc: true,
      },
    ],
    []
  );

  return (
    <div className="camera-wall-devices">
      <div className="main-page-wrapper">
        <div className="filter-wrapper">
          <div className="search-all-container">
            <IoIosSearch className="search-camera-icon" />
            <input
              id="deviceSearch"
              type="text"
              placeholder="Search All"
              className="search-camera-input"
              onChange={(e) => onChangeSearchText(e)}
            />
            {searchText ? (
              <RxCrossCircled
                className="search-close"
                onClick={() => {
                  setSearchText('');
                  document.getElementById('deviceSearch').value = '';
                }}
              />
            ) : null}
          </div>
          <LocationAreaSelector locations={custOrgLocations} />
        </div>
        <div className="table-scroll">
          {isLoading ? (
            <div className="loading-container">
              <SiteSpinner height="50px" width="50px" />
              <div className="mt-2">{constants.LOADING}</div>
            </div>
          ) : !isLoading && hasDevices ? (
            <CameraWallTable
              columns={viewId ? columnsData1 : columnsData2}
              data={filteredDevices}
              defaultSortByData={sortingData}
              viewDevices={viewDevices}
              viewId={viewId}
              newViewDragMode={newViewDragMode}
              viewDevicesDetails={viewDevicesDetails}
              cdnInfo={cdnInfo}
              getDeviceStatus={getDeviceStatus}
              editView={editView}
              isDragging={isDragging}
            />
          ) : !isLoading && !hasDevices ? (
            <div className="no-cameras-container">
              <div className="no-cameras-icon-container">
                <FiCameraOff
                  stroke={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--greyscale_56')}
                  size={32}
                />
              </div>
              <div className="no-cameras-notice">
                {constants.CAMERAS_VIDEO_WALL_NO_CAMERAS_AVAILABLE_LABEL}
              </div>
              {userPolicies.install_device && userPolicies.claim_device && (
                <div className="no-cameras-cta">
                  <Button
                    className="no-cameras-add-device-btn"
                    onClick={(e) => {
                      navigate(
                        `/devices/listing.html?orgId=${orgDetails?.orgId}`
                      );
                    }}
                  >
                    <span>
                      <MdOutlineAddAPhoto
                        stroke={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--brand_white')}
                        size={16}
                      />
                    </span>
                    {constants.CAMERAS_VIDEO_WALL_ADD_CAMERA_LABEL}
                  </Button>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CameraWallDevices;
