import React, { useState, useRef, useEffect } from 'react';
import { Calendar } from 'react-multi-date-picker';
import moment from 'moment';
import { PrimaryButton } from '.';
import DatePicker from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import { LuClock5 } from 'react-icons/lu';
import { constants } from '../../helpers';
import { Row, Col } from 'react-bootstrap';
import Tabs from './Tabs';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { TbCloudLock } from 'react-icons/tb';
import './TimePickerEvents.scss';
import ReactSlider from 'react-slider';

const TimePickerEvents = ({
  selectedDateTOShow,
  date,
  minDate,
  time,
  onSubmit,
  onValueChange,
  startTimeToPass,
  endTimeToPass,
}) => {
  const [disableButton, setDisableButton] = useState(false);
  const today = new Date();
  const yesterday = new Date(today);
  let [selectedDate, setSelectedDate] = useState(date);
  const [rangeDate,setRangeDate] = useState(selectedDateTOShow?.length > 15);
  useEffect(() => {
    if((Number.isNaN(startTimeToPass[0]) || Number.isNaN(endTimeToPass[0])) && !rangeDate){
      startTimeToPass[0] = 0;
      startTimeToPass[1] = 0;
      startTimeToPass[2] = 0;
      endTimeToPass[0] = 23;
      endTimeToPass[1] = 59;
      endTimeToPass[2] = 59;
      setSelectedStartTime(yesterday.setHours(0, 0, 0))
      setSelectedEndTime(yesterday.setHours(23, 59, 59))
      setRangeValues([0,86399])
    }
  },[startTimeToPass, endTimeToPass]);

  let [selectedStartTime, setSelectedStartTime] = useState(
    startTimeToPass
      ? new Date().setHours(
          startTimeToPass[0],
          startTimeToPass[1],
          startTimeToPass[2]
        )
      : yesterday.setHours(0, 0, 0)
  );
  let [selectedEndTime, setSelectedEndTime] = useState(
    endTimeToPass
      ? new Date().setHours(
          endTimeToPass[0],
          endTimeToPass[1],
          endTimeToPass[2]
        )
      : yesterday.setHours(23, 59, 59)
  );
  const [activeSection, setActiveSection] = useState(0);
  const timePickerRef = useRef();
  let [rangeValue, setRangeValue] = useState(0);

  const convertTimeToSeconds = (hours, minutes, seconds) => {
    return hours * 3600 + minutes * 60 + seconds;
  };
  const startTimeConverted = convertTimeToSeconds(
    startTimeToPass[0],
    startTimeToPass[1],
    startTimeToPass[2]
  );
  const endTimeConverted = convertTimeToSeconds(
    endTimeToPass[0],
    endTimeToPass[1],
    endTimeToPass[2]
  );

  const [rangeValues, setRangeValues] = useState([
    startTimeToPass ? startTimeConverted : 0,
    endTimeToPass ? endTimeConverted : 86399,
  ]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    const formattedTime = `${String(hours).padStart(2, '0')}:${String(
      minutes
    ).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    return formattedTime;
  };

  const handleRangeChange = (values) => {
    if(rangeDate){
      setDisableButton(false)
    }else{
      if(values[1] < values[0] || values[1] === values[0]){
        setDisableButton(true)
      }else{
        setDisableButton(false)
      }
    }
    
    onStartTimeChangeByRange(values[0]);
    onEndTimeChangeByRange(values[1]);
    setRangeValues(values);
  };

  const onApply = () => {
    onSubmit({
      selectedStartTime: selectedStartTime,
      selectedEndTime: selectedEndTime,
    });
  };

  const onClickTimePicker = () => {
    if (!timePickerRef.current.isOpen) {
      timePickerRef.current.openCalendar();
    } else {
      timePickerRef.current.closeCalendar();
    }
  };

  const onStartTimeChange = (e) => {
    const convertedSecondsOfStartTime = e
      ? convertTimeToSeconds(e.hour, e.minute, e.second)
      : convertTimeToSeconds(
          selectedStartTime?.hour,
          selectedStartTime?.minute,
          selectedStartTime?.second
        );
    const convertedSecondsOfEndTime = convertTimeToSeconds(
      selectedEndTime?.hour,
      selectedEndTime?.minute,
      selectedEndTime?.second
    );
    onValueChange({
      selectedStartHour: e.hour,
      selectedStartMinute: e.minute,
      selectedStartSecond: e.second,
      fromRange: false,
    });
    setSelectedStartTime(e ? e : new Date().setHours(0, 0, 0));
    setRangeValues([convertedSecondsOfStartTime, convertedSecondsOfEndTime]);
  };

  const onStartTimeChangeByRange = (startDateInSeconds) => {
    const hour = Math.floor(startDateInSeconds / 3600);
    const minutes = Math.floor((startDateInSeconds % 3600) / 60);
    const remainingSeconds = startDateInSeconds % 60;
    onValueChange({
      selectedStartTime: { hour, minutes, remainingSeconds },
      fromRange: true,
    });
    setSelectedStartTime(new Date().setHours(hour, minutes, remainingSeconds));
  };

  const compareStartTimeWithEndTime = (e) => {
    if(rangeDate){
      setDisableButton(false);
      onStartTimeChange(e);
    }else{
      const endTimestampInSeconds = Math.floor(selectedEndTime / 1000);
      const hours = e.hour;
      const minutes = e.minute;
      const seconds = e.second;
      const currentDate = new Date();
      currentDate.setHours(hours, minutes, seconds, 0);
      const timestamp = currentDate.getTime();
      const timestampInSeconds = Math.floor(timestamp / 1000);
  
      if((endTimestampInSeconds === timestampInSeconds || endTimestampInSeconds < timestampInSeconds)){
        setDisableButton(true);
      }else{
        setDisableButton(false);
        onStartTimeChange(e);
      }
    }
  }

  const compareEndTimeWithStartTime = (e) => {
    if(rangeDate){
      setDisableButton(false);
      onStartTimeChange(e);
    }else{
      const startTimestampInSeconds = Math.floor(selectedStartTime / 1000);
      const hours = e.hour;
      const minutes = e.minute;
      const seconds = e.second;
      const currentDate = new Date();
      currentDate.setHours(hours, minutes, seconds, 0);
      const timestamp = currentDate.getTime();
      const timestampInSeconds = Math.floor(timestamp / 1000);
  
      if(timestampInSeconds === startTimestampInSeconds || timestampInSeconds < startTimestampInSeconds){
        setDisableButton(true);
      }else{
        setDisableButton(false);
        onEndTimeChange(e);
      }
    }    
  }

  const onEndTimeChange = (e) => {
    const convertedSecondsOfStartTime = convertTimeToSeconds(
      selectedStartTime?.hour,
      selectedStartTime?.minute,
      selectedStartTime?.second
    );
    const convertedSecondsOfEndTime = e
      ? convertTimeToSeconds(e.hour, e.minute, e.second)
      : convertTimeToSeconds(
          selectedEndTime?.hour,
          selectedEndTime?.minute,
          selectedEndTime?.second
        );
    onValueChange({
      selectedEndHour: e.hour,
      selectedEndMinute: e.minute,
      selectedEndSecond: e.second,
      fromRange: false,
    });
    setSelectedEndTime(e ? e : new Date().setHours(0, 0, 0));
    setRangeValues([convertedSecondsOfStartTime, convertedSecondsOfEndTime]);
  };

  const onEndTimeChangeByRange = (endDateInSeconds) => {
    const hour = Math.floor(endDateInSeconds / 3600);
    const minutes = Math.floor((endDateInSeconds % 3600) / 60);
    const remainingSeconds = endDateInSeconds % 60;
    onValueChange({
      selectedEndTime: { hour, minutes, remainingSeconds },
      fromRange: true,
    });
    setSelectedEndTime(new Date().setHours(hour, minutes, remainingSeconds));
  };

  const setSectionBlock = (index) => {
    setActiveSection(index);
  };

  const tabs = [
    {
      label: constants.EVENT_SIGNLE_DAY_BUTTON,
      clickHandler: (index) => {
        setSectionBlock(index);
        setSelectedDate(null);
        setSelectedDate(new Date());
      },
    },
    {
      label: constants.EVENT_DATE_RANGE_BUTTON,
      clickHandler: (index) => {
        setSectionBlock(index);
        setSelectedDate(null);
      },
    },
  ];

  return (
    <div className="date-time-picker" style={{ padding: '18px' }}>
      <div className="time-range-label-style">
        {constants.EVENT_SELECT_TIME_RANGE}
      </div>
      <Row>
        <Col md={6}>
          <div className="time-range-box">
            <Row className="time-range-inner-section-label">
              <Col>Start</Col>
            </Row>
            <Row className="time-range-inner-section-value">
              <Col>
                <DatePicker
                  value={selectedStartTime}
                  onChange={(e, { input, isTyping }) => {
                    compareStartTimeWithEndTime(e);
                  }}
                  disableDayPicker
                  format="hh:mm:ss A"
                  plugins={[<TimePicker />]} 
                  className="custom-timepicker"
                  ref={timePickerRef}
                  multiple={false}
                  editable={false}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={6}>
          <div className="time-range-box">
            <Row className="time-range-inner-section-label">
              <Col>End</Col>
            </Row>
            <Row className="time-range-inner-section-value">
              <Col>
                <DatePicker
                  value={selectedEndTime}
                  onChange={(e, { input, isTyping }) => {
                    compareEndTimeWithStartTime(e);
                  }}
                  disableDayPicker
                  format="hh:mm:ss A"
                  plugins={[<TimePicker />]}
                  className="custom-timepicker"
                  ref={timePickerRef}
                  multiple={false}
                  editable={false}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div className={`range-field-wrap-event-time`}>
        <ReactSlider
          name={'name'}
          value={rangeValues}
          min={0}
          max={86399}
          className="range-slider"
          trackClassName="range-slider-track"
          thumbClassName="range-slider-thumb"
          renderThumb={(props, state) => (
            <div {...props}>
              <span className="range-slider-thumb-value">
                {/* {state.valueNow} */}
              </span>
            </div>
          )}
          onAfterChange={handleRangeChange}
          range={true}
        />
      </div>
      <div>
        <PrimaryButton
          className="btn btn-primary mt-4"
          type="submit"
          width="100%"
          height="44px"
          fontSize="0.875rem"
          lineHeight="24px"
          onClick={onApply}
          disabled={disableButton}
        >
          {constants.CAMERA_APPLY_DATE_FILTER}
        </PrimaryButton>
      </div>
    </div>
  );
};
export default TimePickerEvents;
