import { useEffect, useState } from 'react';
import './stepspos.scss';
import { AppDefaults, constants ,Utils } from '../../../helpers';
import { PrimaryButton } from '../../../components/common';
import { useSearchParams } from 'react-router-dom';
import { warninground } from '../../../assets/images';
import { RxCross1 } from 'react-icons/rx';
import DTAttributeSelector from './DTAttributeSelector';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import AttributeSelector from './AttributeSelector';
import { PosStore } from '../../../store/PosStore';
import axios from 'axios';
import EditPosIndicator from './EditPosIndicator';

const EditMappingModal = (props) => {
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const [posDataListFromCSV, setPosDataListFromCSV] = useState(
    props?.mappingData
  );
  const getAtrributeList = PosStore((state) => state.getAtrributeList);
  const getIsHeaderChecked = PosStore((state) => state.getIsHeaderChecked);
  const [showLoader, setShowLoader] = useState(false);
  const [disableMapBtn, setDisableMapBtn] = useState(true);

  useEffect(()=>{
    setPosDataListFromCSV(props?.mappingData)
  },[])

  useEffect(() => {
    getMappingStatus();
  }, [posDataListFromCSV]);

  const getMappingStatus = () => {
    const removeOptionalAttributeList = getAtrributeList()?.filter(
      (itemAttribute) => !itemAttribute?.optional
    );
    const posAtributeList = posDataListFromCSV?.filter(
        (itemAttribute) =>  itemAttribute?.id
    );

    let mandetoryAttribute = [];
    let nonOptioanlList = [];
    let disableBtn = false;

    for (let i = 0; i < posAtributeList?.length; i++) {
      if (!mandetoryAttribute?.includes(posAtributeList[i]?.id)) {
        if (posAtributeList[i]?.type === AppDefaults.DATA_TYPE_DATE) {
          if (posAtributeList[i]?.format) {
            disableBtn = false;
            mandetoryAttribute.push(posAtributeList[i]?.id);
            nonOptioanlList.push(posAtributeList[i]);
          }
        } else if (posAtributeList[i]?.custom) {
          if (
            posAtributeList[i]?.customAttributeName &&
            posAtributeList[i]?.customAttributeName != ''
          ) {
            disableBtn = false;
            mandetoryAttribute.push(posAtributeList[i]?.id);
            nonOptioanlList.push(posAtributeList[i]);
          } else {
            disableBtn = false;
            mandetoryAttribute.push(posAtributeList[i]?.id);
            const param = { ...posAtributeList[i], isvalid: '1' };
            nonOptioanlList.push(param);
          }
        } else {
          disableBtn = false;
          mandetoryAttribute.push(posAtributeList[i]?.id);
          nonOptioanlList.push(posAtributeList[i]);
        }
      } else {
        disableBtn = true;
        break;
      }
    }
    const isValid = nonOptioanlList?.filter((item) => item?.isvalid === '1');
    if (isValid?.length > 0) {
      setDisableMapBtn(true);
    } else if (disableBtn) {
      setDisableMapBtn(true);
    } else if (
      nonOptioanlList?.filter((itemAttribute) => !itemAttribute?.optional)
        ?.length === removeOptionalAttributeList?.length
    ) {
      setDisableMapBtn(false);
    } else {
      setDisableMapBtn(true);
    }
  };


  const updatePosWithAttribute = async (data) => {
    let posData = posDataListFromCSV?.map((posItem, index) => {
      if (posItem?.order === data?.order) {
        return { ...posItem, ...data };
      } else {
        return { ...posItem };
      }
    });
    let updatedPosList = await Promise.all(posData);
    setPosDataListFromCSV(updatedPosList);
    props.updateList(updatedPosList)
  };

  const updateFormate = async (selectedFormate) => {
    let posData = posDataListFromCSV?.map((posItem) => {
      if (posItem?.type === AppDefaults?.DATA_TYPE_DATE) {
        return { ...posItem, format: selectedFormate };
      } else {
        return { ...posItem };
      }
    });
    let updatedPosList = await Promise.all(posData);
    setPosDataListFromCSV(updatedPosList);
    props.updateList(updatedPosList)
  };

 

  const onHandleChange = async (e, data) => {
    if (e != undefined && e?.target?.value?.length <= 30) {
      let posData = posDataListFromCSV?.map((posItem) => {
        if (posItem?.id === data?.id) {
          return { ...posItem, customAttributeName: e?.target?.value };
        } else {
          return { ...posItem };
        }
      });
      let updatedPosList = await Promise.all(posData);
      setPosDataListFromCSV(updatedPosList);
      props.updateList(updatedPosList)

    }
  };

  const postMapping = async () => {
    setShowLoader(true)
    let fieldsList = [];
    const posAtributeList = posDataListFromCSV?.filter(
      (itemAttribute) => itemAttribute?.id
    );
    posAtributeList?.map((item) => {
      if (item?.type === AppDefaults?.DATA_TYPE_DATE) {
        fieldsList.push({
          cName: item?.cName,
          id: item?.id,
          order: item?.order,
          format: item?.format,
          columnDescription: item?.columnDescription,
        });
      } else if (item?.custom) {
        fieldsList.push({
          cName: item?.cName,
          id: item?.id,
          order: item?.order,
          columnDescription: item?.columnDescription,
          customAttributeName: item?.customAttributeName,
        });
      } else {
        fieldsList.push({
          cName: item?.cName,
          id: item?.id,
          order: item?.order,
          columnDescription: item?.columnDescription,
        });
      }
    });
    const reqBody = {
      header: getIsHeaderChecked() === 1 ? true : false,
      fields: fieldsList,
    };
    let response = await axios.put(
      `/partner/orgs/${orgId}/pos/mapping/fields`,
      reqBody,
      Utils.requestHeader()
    );

    let resData = response?.data;

    if (resData?.meta.code === 200) {
      setShowLoader(false);
      props.hideModal()
    } else {
      setShowLoader(false);
    }
  };


  return (
    <div className="pos-integration">
       <EditPosIndicator showMapPostStatus={0} {...props} />
        <div className={`manage-pos-height`}>
        <div className="step-three-Attributes edit-modal-mapping">
            <div className="lable-upload-file">
              {constants.POS_MAP_ATTRIBUTE}
            </div>
            <div className="extract-message">
              {constants.POS_SELECT_ATTRIBUTE}
            </div>
            {posDataListFromCSV &&
              posDataListFromCSV?.map((item) => {
                return (
                   (
                    <div
                      className={`list-container ${
                        item?.type === AppDefaults?.DATA_TYPE_DATE ||
                        (item?.custom && 'date-time-selected')
                      }`}
                    >
                      <div
                        className={`left-layout-col ${
                          item?.type === AppDefaults?.DATA_TYPE_DATE ||
                          (item?.custom && 'date-time-selected')
                        }`}
                      >
                        <div className="col-name-heading">{item.cName}</div>
                        <div className="sub-content-label">{item.columnDescription}</div>
                      </div>
                      <div className="right-layout-col">
                        <AttributeSelector
                          updatePosStoreWithAttribute={(posAttributeData) => {
                            updatePosWithAttribute(posAttributeData);
                          }}
                          posItem={item}
                          isFromEdit={true}
                          posDataListFromCSV={posDataListFromCSV}
                          getAtrributeList={getAtrributeList()}
                        />
                        {item?.type === AppDefaults?.DATA_TYPE_DATE && (
                          
                          <DTAttributeSelector
                            posItem={item}
                            isFromEdit={true}
                            formats={getAtrributeList()?.find((item)=>item?.type === AppDefaults?.DATA_TYPE_DATE)?.formats}
                            updateDateSelected={(selectedFormate) => {
                              updateFormate(selectedFormate);
                            }}
                          />
                        )}
                        
                        {item?.custom && (
                          <div className="textbox-pos">
                            <input
                              value={item?.customAttributeName}
                              onChange={(e) => onHandleChange(e, item)}
                              style={{ border: 'none', width: '100%' }}
                              className={`form-control require shadow-none ${
                                item?.customAttributeName ? 'has-value' : ''
                              }`}
                              placeholder={
                                constants.POS_ATTRIBUTE_NAME_PLACHOLDER
                              }
                              autoComplete="off"
                              beforeinputicon=""
                              afterinputicon=""
                            />
                            {
                            (item?.customAttributeName === undefined || item?.customAttributeName == '') && <div className="asterisk">*</div>
                            }
                          </div>
                        )}
                        {item?.custom && (
                          <div className="bottom-strip-container custom-attribute">
                            <div className="custom-attibute-value-length">{`${
                              item?.customAttributeName ? item?.customAttributeName?.length : 0
                            } / 30`}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                );
              })}
              
          </div>
          
        </div>

        {(
        <div className={`manage-pos-height`}>
          <div className="org-map-setup-content">
            <PrimaryButton
              className="adddevice-btn btn-margin-try"
              fontSize="14px"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              height="44px"
              color="#FFFFFF"
              type="submit"
              loader={showLoader}
              disabled={disableMapBtn}
              onClick={() => {
                props.nextStep();
              }}
            >
              {
                constants.DISCOVERED_DEVICES_NEXT
               }
            </PrimaryButton>
            {(
              <PrimaryButton
                className="adddevice-btn"
                fontSize="14px"
                backgroundColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--brand_white')}
                height="44px"
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--primary_40')}
                borderColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--primary_40')}
                type="submit"
                onClick={() => {
                  props.goTOSetup();               
                }}
              >
                {constants.POS_NEW_MAPPING_UPLOAD_BTN}
              </PrimaryButton>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default EditMappingModal;
