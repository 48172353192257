import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accountId: '',
  selectedOrganization: null,
  deviceInformation: null,
  locationsData: null,
  userdata: [],
  orgUsers: [],
  allDevices: [],
  notificationDevices: [],
  updateByMqttStatus: false,
  allMqttDataFromResponse: null,
  allAreas: [],
  subscribeAreasRequest: false,
  keepAliveRequest: false,
  passedDeviceInfoFromDeviceList: null,
  doNotRefreshDeviceList: false,
  notificationFlag: false,
};

export const accounts = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccountId: (state, action) => {
      state.accountId = action.payload;
    },
    setAllAreas: (state, action) => {
      state.allAreas = action.payload;
    },
    updateUserData: (state, action) => {
      state.userdata = action.payload;
    },
    setSelectedOrganization: (state, action) => {
      state.selectedOrganization = action.payload;
    },
    setDeviceInformation: (state, action) => {
      state.deviceInformation = action.payload;
    },
    setLocationsData: (state, action) => {
      state.locationsData = action.payload;
    },
    setuserData: (state, action) => {
      const updatedorg = state.userdata.findIndex(
        (obj) => obj.orgId === action.payload.orgId
      );

      for (const [key, value] of Object.entries(action.payload)) {
        state.userdata[updatedorg][key] = value;
      }
    },
    orgUserData: (state, action) => {
      state.orgUsers = action.payload;
    },
    updateOrguserData: (state, action) => {
      state.orgUsers.push(action.payload);
    },
    setAllDevicesData: (state, action) => {
      const devices = action.payload;
      // sort data by device name
      devices?.sort(function (a, b) {
        if (a.deviceName < b.deviceName) {
          return -1;
        }
        if (a.deviceName > b.deviceName) {
          return 1;
        }
        return 0;
      });
      state.allDevices = devices;
      state.allDevices = action.payload;
    },
    setAllDevicesDataNotification: (state, action) => {
      const devices = action.payload;
      // sort data by device name
      devices?.sort(function (a, b) {
        if (a.deviceName < b.deviceName) {
          return -1;
        }
        if (a.deviceName > b.deviceName) {
          return 1;
        }
        return 0;
      });
      state.notificationDevices = devices;
      state.notificationDevices = action.payload;
    },
    setAllMqttDataFromResponse: (state, action) => {
      state.allMqttDataFromResponse = action.payload;
    },
    setSubscribeAreasRequest: (state, action) => {
      state.subscribeAreasRequest = action.payload;
    },
    setKeepAliveRequest: (state, action) => {
      state.keepAliveRequest = action.payload;
    },
    setMqttUpdateStatusFromResponse: (state, action) => {
      state.updateByMqttStatus = action.payload;
    },
    resetAllMqttDataFromResponse: (state) => {
      state.allMqttDataFromResponse = initialState;
    },
    resetAccount: () => initialState,
    setPassedDeviceInfoFromDeviceList: (state, action) => {
      state.passedDeviceInfoFromDeviceList = action.payload;
    },
    setDoNotRefreshDeviceList: (state, action) => {
      state.doNotRefreshDeviceList = action.payload;
    },
    setOpenSourceConditions: (state, action) => {
      state.openSourceConditions = action.payload;
    },
    setNotificationFlag: (state, action) => {
      state.notificationFlag = action.payload;
    },
  },
});

export const {
  setAccountId,
  setAllAreas,
  updateUserData,
  setSelectedOrganization,
  setDeviceInformation,
  setLocationsData,
  setuserData,
  orgUserData,
  updateOrguserData,
  setAllDevicesData,
  setAllDevicesDataNotification,
  setSubscribeAreasRequest,
  setKeepAliveRequest,
  setAllMqttDataFromResponse,
  setMqttUpdateStatusFromResponse,
  resetAccount,
  resetAllMqttDataFromResponse,
  setPassedDeviceInfoFromDeviceList,
  setDoNotRefreshDeviceList,
  setOpenSourceConditions,
  setNotificationFlag,
} = accounts.actions;

export const getAccountId = (state) => state.accounts.accountId;
export const getAllAreas = (state) => state.accounts.allAreas;
export const getSelectedOrganization = (state) =>
  state.accounts.selectedOrganization;
export const getDeviceInformation = (state) => state.accounts.deviceInformation;
export const getLocationsData = (state) => state.accounts.locationsData;
export const getAllDevicesData = (state) => state.accounts.allDevices;
export const getAllDevicesDataNotifiction = (state) => state.accounts.notificationDevices;

export const getAllMqttDataFromResponse = (state) =>
  state.accounts.allMqttDataFromResponse;
export const getMqttUpdateStatus = (state) => state.accounts.updateByMqttStatus;
export const getSubscribeAreasRequest = (state) =>
  state.accounts.subscribeAreasRequest;
export const getKeepAliveRequest = (state) => state.accounts.keepAliveRequest;
export const getPassedDeviceInfoFromDeviceList = (state) =>
  state.accounts.passedDeviceInfoFromDeviceList;
export const getDoNotRefreshDeviceList = (state) =>
  state.accounts.doNotRefreshDeviceList;
export const getOpenSourceConditions = (state) => state.accounts.openSourceConditions;
export const getNotificationFlag = (state) => state.accounts.notificationFlag;


export default accounts.reducer;
