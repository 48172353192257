import React, { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import brandLogo from '../assets/images/BrandLogo.svg';
import MegatronLogo from '../assets/images/MegatronLogo.svg';
import { Navigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { constants, AppDefaults } from '../helpers';
import { Utils } from '../helpers/';
import { useSearchParams } from 'react-router-dom';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import './SignIn.scss';
import { Header, SiteModal } from '../components/common';
import { useAccountStatus } from '../store/AccountStatusStore';
import AccountNotEnabledModal from './AccountNotEnabledModal';
import AccountNotVerifiedModal from './AccountNotVerifiedModal';
import { useOrganizations } from '../store/OrganizationsStore';
import MobileBrowser from './MobileBrowser';
import { useTranslation } from 'react-i18next';
import { useLoggedInUserData } from '../store/LoggedInAccountStore';
import { useNotificationStore } from '../store/CreateNotificationStore';
import { useCustomerOrgLocations } from '../store/CustomerOrgLocationsStore';
import { resetCDNInfo } from '../store/reducers/StreamingReducer';
import { useDispatch } from 'react-redux';
import { useCustomerOrgDevices } from '../store/CustomerOrgDevicesStore';
import axios from 'axios';
import { setOpenSourceConditions } from '../store/reducers/AccountReducer';

function SignIn(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let [searchParams] = useSearchParams();
  const resetLoggedInUserData = useLoggedInUserData(
    (state) => state.resetLoggedInUserData
  );
  const sessionexpire = searchParams.get('sessionexpire');
  const { keycloak } = useKeycloak();
  const location = useLocation();
  const [show, setShow] = useState(true);
  const deviceId = searchParams.get('deviceId');
  const orgId = searchParams.get('orgId');
  const nType = searchParams.get('nType');
  const eventTimestamp = searchParams.get('eventTimestamp');
  const notificationData = {
    deviceId: deviceId,
    orgId: orgId,
    nType: nType,
    eventTimestamp: eventTimestamp,
  };
  const tenantID =
    !process.env.REACT_APP_PROJECT && !AppDefaults.PROJECT_MEGATRON
      ? 'dc'
      : process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON
      ? 'hva'
      : 'dc';
  const appID =
      !process.env.REACT_APP_PROJECT && !AppDefaults.PROJECT_MEGATRON
        ? 'vms'
        : process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON
        ? 'oncloud'
        : 'vms';
  const setUserPushData = useOrganizations((state) => state.setUserPushData);
  const setSelfStatus = useOrganizations((state) => state.setSelfStatus);
  const resetCustomerOrgData = useOrganizations(
    (state) => state.resetCustomerOrgData
  );
  const resetNotificationData = useNotificationStore(
    (state) => state.resetStepData
  );
  const resetCustomerOrgLocations = useCustomerOrgLocations(
    (state) => state.resetCustomerOrgLocations
  );
  const resetCameraWallDevices = useCustomerOrgDevices(
    (state) => state.resetCameraWallDevices
  );
  const[privacyPolicy, setPrivacyPolicy] = useState('');
  const[termServices, setTermServices] = useState('');
  
  useEffect(()=>{getPolicies();},[])

  const getPolicies = async () => {
    const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}partner/policies`,
            {
              headers: {
                'X-Tenant-Id': tenantID,
                'X-App-Id': appID,
              },
            }
          );
  
          const responseData = response?.data;
          if (responseData?.meta?.code === 200) {
            dispatch(setOpenSourceConditions(responseData.data));
            setPrivacyPolicy(responseData.data.ppLink);
            setTermServices(responseData.data.tosLink);
          }

      };


  const {
    getIsActNotEnabled,
    getIsActNotActivated,
    setIsActNotActivated,
    setIsActNotEnabled,
  } = useAccountStatus();
  const isActNotEnabled = getIsActNotEnabled();
  const isActNotActivated = getIsActNotActivated();
  /* If logged in and someone tries to hit signin url redirect to the Dashboard. */
  const tokenWeb = localStorage.getItem('vmsAuthToken');
  if (tokenWeb != null && tokenWeb != 'undefined' && !Utils.detectBrowser()) {
    if (notificationData && nType) {
      setUserPushData(notificationData);
    } else {
      setUserPushData({});
    }
    return <Navigate to="/customers/manage.html" push={true} />;
  } else if (notificationData && nType) {
    setUserPushData(notificationData);
  } else {
    setUserPushData({});
  }

  const handleLoginButtonClick = async () => {
    localStorage.removeItem('tokenSend');
    localStorage.setItem('authenticated', false);
    localStorage.setItem('vmsAuthToken', null);
    localStorage.setItem('isSessionApiCall', false);
    await resetCustomerOrgData();
    await resetNotificationData();
    await resetCustomerOrgLocations();
    await resetCameraWallDevices();
    await dispatch(resetCDNInfo());
    setSelfStatus('0');
    await resetLoggedInUserData();
    Notification.requestPermission().then(function (status) {
      if (status === 'denied') {
        loginUser();
      } else if (status === 'granted') {
        loginUser();
      }
    });
  };

  const loginUser = () => {
    keycloak.login({
      redirectUri: process.env.REACT_APP_KEYCLOAK_LOGIN_REDIRECT_URL,
    });
  };

  const invalidateSessionApi = () => {
    const reqBody = {
      refresh_token: keycloak?.refreshToken,
    };
    axios
      .post('/user/token/invalidate/app', reqBody, Utils.requestHeader())
      .then((res) => {
        if (res?.data?.meta?.code === 200) {
          keycloak.logout({
            redirectUri: process.env.REACT_APP_KEYCLOAK_LOGOUT_REDIRECT_URL,
          });
        }
      });
  };

  return (
    <div className="App">
      {Utils.detectBrowser() ? (
        <MobileBrowser appLogo={brandLogo} />
      ) : tokenWeb != null && tokenWeb != 'undefined' ? (
        ''
      ) : (
        <div>
          <Header showHeader={false} cartFetch={false} />

          <header className="App-header">
            <Container className="h-100">
              <Row bsPrefix="d-flex justify-content-center align-items-center h-100">
                <Col md={6} lg={6} xl={7}>
                  <div className="duclo_logo">
                    <img
                      src={
                        process.env.REACT_APP_PROJECT ===
                        AppDefaults.PROJECT_MEGATRON
                          ? MegatronLogo
                          : brandLogo
                      }
                      className="mt-4 mb-4"
                      alt="Duclo"
                    />
                  </div>
                  <div className="card ">
                    <div className="card-body text-center">
                      <h5
                        style={{ marginTop: 40 }}
                        className={`${sessionexpire ? 'mb-2' : 'mb-4'}`}
                      >
                        {t('SIGN_IN_PAGE_TITLE')}
                      </h5>
                      {sessionexpire && (
                        <div className="login-error-container mb-4">
                          <HiOutlineExclamationCircle
                            size={15}
                            stroke={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--error_24')}
                          />

                          <div className="error-text">
                            {constants.SESSION_EXPIRE_MESSAGE}
                          </div>
                        </div>
                      )}
                      {!keycloak.authenticated && (
                        <button
                          type="button"
                          className="btn btn-primary signin-btn w-25 mb-2"
                          onClick={() => handleLoginButtonClick()}
                        >
                          {constants.LOGIN_BUTTON_CONTENT}
                        </button>
                      )}

                      {!!keycloak.authenticated && (
                        <button
                          type="button"
                          className="btn-primary"
                          onClick={() => {
                            invalidateSessionApi();
                          }}
                        >
                          {constants.TOP_HEADER_LOGOUT_SETTINGS_NAV_TITLE} (
                          {keycloak.tokenParsed.preferred_username})
                        </button>
                      )}

                      <p>
                        {location?.state !== null && show && (
                          <Alert
                            variant={location?.state?.status}
                            className="text-start"
                            onClose={() => setShow(false)}
                            dismissible
                          >
                            {location?.state?.msg}
                          </Alert>
                        )}
                      </p>
                      <p className="mb-5" style={{ fontSize: 14 }}>
                        {t('AGREES_DUCLO_CONTENT')}
                        <strong className="term-donction-text">
                          <a
                            href={privacyPolicy?privacyPolicy:''}
                            target="_blank"
                            className="primary_anchor"
                            rel="noreferrer"
                          >
                            {constants.FOOTER_NAV_PRIVACY_POLICY}
                          </a>
                        </strong>
                        and
                        <strong className="term-donction-text">
                          <a
                            href={termServices ? termServices :''}
                            target="_blank"
                            className="primary_anchor"
                            rel="noreferrer"
                          >
                            {constants.FOOTER_NAV_TERMS_CONDITION}
                          </a>
                        </strong>
                        {constants.AGREES_DUCLO_END_CONTENT}
                      </p>
                    </div>
                  </div>

                  <p style={{ fontSize: 14 }} className="mt-3">
                    {constants.DONT_HAVE_ACCOUNT_CONTENT}
                    <br />
                    {constants.NO_ORGANIZATION}{' '}
                    <strong>
                      <a
                        href={`${process.env.REACT_APP_KEYCLOAK_LOGOUT_REDIRECT_URL}/becomeacustomer`}
                        className="primary_anchor"
                      >
                        {constants.CREATE_ORGANIZATION}
                      </a>
                    </strong>
                    <br />
                    {constants.GOT_NEW_CUSTOMER}{' '}
                    <strong>
                      <a
                        href={`${process.env.REACT_APP_KEYCLOAK_LOGOUT_REDIRECT_URL}/createpartnerorg`}
                        className="primary_anchor"
                      >
                        {process.env.REACT_APP_PROJECT ===
                        AppDefaults.PROJECT_MEGATRON
                          ? constants.BECOME_STEP_PARTNER
                          : constants.BECOME_PARTNER}
                      </a>
                    </strong>
                  </p>
                </Col>
              </Row>
            </Container>
            <SiteModal
              key={'account-status-modal1'}
              classes="account-status-modal"
              wrapperClass="account-status-modal"
              showModal={isActNotEnabled}
              hideModal={() => setIsActNotEnabled(false)}
              modalHeader={true}
            >
              <AccountNotEnabledModal
                onClickLogIn={() => {
                  setIsActNotEnabled(false);
                  Notification.requestPermission().then(function (status) {
                    if (status === 'denied') {
                      loginUser();
                    } else if (status === 'granted') {
                      loginUser();
                    }
                  });
                }}
              ></AccountNotEnabledModal>
            </SiteModal>
            <SiteModal
              key={'account-status-modal2'}
              classes="account-status-modal"
              wrapperClass="account-status-modal"
              showModal={isActNotActivated}
              hideModal={() => setIsActNotActivated(false)}
              modalHeader={true}
            >
              <AccountNotVerifiedModal
                onClickLogIn={() => {
                  setIsActNotActivated(false);
                  Notification.requestPermission().then(function (status) {
                    if (status === 'denied') {
                      loginUser();
                    } else if (status === 'granted') {
                      loginUser();
                    }
                  });
                }}
              ></AccountNotVerifiedModal>
            </SiteModal>
          </header>
        </div>
      )}
    </div>
  );
}

export default SignIn;
