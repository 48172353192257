import { Section, SingleColumnLayout } from '../components';
import { useCallback, useLayoutEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useCustomerOrgDevices } from '../../../store/CustomerOrgDevicesStore';
import {
  DevicesFilter,
  DropdownList,
  MetricCard,
  SiteSpinner,
} from '../../../components/common';
import { constants, Utils } from '../../../helpers';
import BaseChartConfig from '../shared/BaseChartConfig';
import { useNavigate } from 'react-router';
import '../CameraAvailability.scss';
import { Row } from 'react-bootstrap';
import CamAvailableListTable from './camAvailableListTable';
import { useMemo } from 'react';
import moment from 'moment';

const pastTimeSeries = [
  {
    itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_24_HOURS,
    itemValue: 1,
  },
  {
    itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_48_HOURS,
    itemValue: 2,
  },
  {
    itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_7_DAYS,
    itemValue: 7,
  },
  {
    itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_14_DAYS,
    itemValue: 14,
  },
  {
    itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_30_DAYS,
    itemValue: 30,
  },
  {
    itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_YEAR,
    itemValue: 365,
  },
  {
    itemName: constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_2_YEARS,
    itemValue: 730,
  },
];

const frequencies = [
  {
    itemName: constants.ANALYTICS_DASHBOARD_DURATION_ITEM_HOUR,
    itemValue: 'hourly',
  },
  {
    itemName: constants.ANALYTICS_DASHBOARD_DURATION_ITEM_DAY,
    itemValue: 'daily',
  },
  {
    itemName: constants.ANALYTICS_DASHBOARD_DURATION_ITEM_WEEK,
    itemValue: 'weekly',
  },
  {
    itemName: constants.ANALYTICS_DASHBOARD_DURATION_ITEM_MONTH,
    itemValue: 'monthly',
  },
];

const frequencyGetter = {
  hourly: Utils.getHourFromEpoch,
  daily: Utils.getDateFromEpoch,
  weekly: Utils.getWeekFromEpoch,
  monthly: Utils.getMonthFromEpoch,
};

const CameraAvailableDetails = ({ custOrgData, devices, id, columnsData }) => {
  const [hasData, setHasData] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [rangeYear, setRangeYear] = useState(false);
  const [rangeDay, setRangeDay] = useState(false);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [options, setOptions] = useState({
    ...BaseChartConfig,
    xaxis: {
      categories: ['12/29', '12/30', '12/31', '1/1', '1/2', '1/3', '1/4'],
    },
    yaxis: {
      opposite: true,
      min: 0,
      tickAmount: 5,
      labels: {
        formatter: (value) => {
          let retVal =
            id == 1
              ? parseInt(value) === 0
                ? value
                : `${value.toFixed(2)}%`
              : `${value.toFixed(2)}m`;

          return retVal;
        },
      },
    },
    colors: [
      getComputedStyle(document.documentElement).getPropertyValue(
        '--primary_56'
      ),
    ]
  });
  const [series, setSeries] = useState([
    {
      name: constants.ANALYTICS_DASHBOARD_CAMERA_AVAILABILITY_SERIES_LABEL,
      data: [100, 100, 80, 100, 100, 100],
    },
  ]);
  const [highlight, setHighlight] = useState({
    value: '0%',
    description:
      constants.ANALYTICS_DASHBOARD_CAMERA_AVAILABILITY_HIGHLIGHT_DESCRIPTION,
  });

  const [averageCameraOnlineTime, setAverageCameraOnlineTime] = useState(98);
  const [pastRange, setPastRange] = useState(-7);
  const [pastRangeLabel, setPassRangeLabel] = useState(
    constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_7_DAYS
  );
  const [duration, setDuration] = useState('daily');
  const {
    getCustomerOrgDevices,
    setSelectedDeviceFromFillter,
    getSelectedDeviceFromFillter,
  } = useCustomerOrgDevices();
  const navigate = useNavigate();

  const defaultValue = (
    <div className="d-flex justify-content-around align-content-center no-content">
      {constants.ANALYTICS_DASHBOARD_NO_DATA_AVAILABLE_TEXT}
    </div>
  );

  const updateList = (deviceDataList, status) => {
    if (deviceDataList?.length > 0) {
      if (status) {
        setSelectedDeviceFromFillter([]);
        fetchData(pastRange, duration, devices);
      } else {
        if (deviceDataList?.length !== 0) {
          let deviceData = [];
          devices?.map((deviceItem, index) => {
            const deviIdExist = deviceDataList?.find(
              (item) => item === deviceItem?.deviceId
            );
            if (deviIdExist) {
              deviceData.push(deviceItem);
            }
          });
          setSelectedDeviceFromFillter(deviceDataList);
          fetchData(pastRange, duration, deviceData);
        }
      }
    } else {
      let deviceData = [];
      devices?.map((deviceItem, index) => {
        deviceData.push(deviceItem?.deviceId);
      });
      setSelectedDeviceFromFillter(deviceData);
      fetchData(pastRange, duration, deviceData);
    }
  };
  const filterContent = (
    <>
      <DevicesFilter
        devices={devices}
        filterDescription={
          constants.CAMERAS_VIDEO_WALL_FILTER_MODAL_DESCRIPTION
        }
        applyButtonLabel={
          constants.CAMERAS_VIDEO_WALL_FILTER_MODAL_APPLY_FILTER_LABEL
        }
        disableBackgroundColor={true}
        clearFilterPlacement="bottom"
        callback={(deviceData, status) => {
          updateList(deviceData, status);
          setShow(false);
        }}
      />
    </>
  );

  const chartContent = (
    <>
      <Chart
        options={options}
        series={series}
        type={id == 1 ? 'line' : 'bar'}
        height={200}
        width={'100%'}
      />
    </>
  );

  const topRightLinks = (
    <div className="filter-group">
      {/* Filter */}
      <DropdownList
        defaultLabel={constants.ANALYTICS_DASHBOARD_ALL_CAMERAS_LINK}
        hasSecondaryDropdown={true}
        items={filterContent}
        show={show}
        setShow={(status) => {
          setShow(status);
        }}
      />
      {/* Past Range */}
      <DropdownList
        defaultLabel={pastRangeLabel}
        items={pastTimeSeries}
        onSelectHandler={(selectedKey) => {
          setPastRange(-Math.abs(selectedKey));
        }}
        show={rangeYear}
        setShow={(status) => {
          setRangeYear(status);
        }}
      />
      {/* Duration */}
      <DropdownList
        defaultLabel={constants.ANALYTICS_DASHBOARD_DURATION_ITEM_DAY}
        items={frequencies}
        show={rangeDay}
        setShow={(status) => {
          setRangeDay(status);
        }}
        onSelectHandler={(selectedKey) => {
          setDuration(selectedKey);

          switch (selectedKey.toUpperCase()) {
            case 'HOURLY':
              setPassRangeLabel(
                constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_48_HOURS
              );
              break;

            case 'DAILY':
              setPassRangeLabel(
                constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_7_DAYS
              );
              break;

            case 'WEEKLY':
              //setPastRange(-52);
              setPassRangeLabel(
                constants.ANALYTICS_DASHBOARD_RANGE_ITEM_PAST_YEAR
              );
              break;

            default:
            // Do not do anything
          }
        }}
      />
    </div>
  );

  const getDeviceIdsString = (devicList) => {
    const deviceSelected = getSelectedDeviceFromFillter();
    const devicesHub = custOrgData?.devices;
    let deviceIdsString = '';

    devicesHub?.forEach((device, index) => {
      if (deviceSelected?.length !== 0) {
        const deviIdExist = deviceSelected?.find(
          (item) => item === device?.deviceId
        );
        if (deviIdExist) {
          deviceIdsString += `${device?.parentId},`;
        }
      } else {
        deviceIdsString += `${device?.deviceId},`;
      }
    });
    return deviceIdsString?.substring(0, deviceIdsString.length - 1);
  };

  const getDeviceLocationName = (device, isForDevice) => {
    let deviceLocation = '--';
    const deviceData = custOrgData?.devices.find(
      (item) => item.deviceId === device?.deviceId
    );
    if (deviceData) {
      if (isForDevice) {
        deviceLocation = deviceData?.deviceName;
      } else {
        const location = custOrgData?.locations.find(
          (item) => item.locationId === deviceData?.locationId
        );
        if (location) {
          const areas = location?.areas.find(
            (item) => item.locationId === location?.locationId
          );
          if (areas) {
            deviceLocation = `${location?.locationName} - ${areas?.areaName}`;
          }
        }
      }
    }
    return deviceLocation;
  };

  const fetchData = useCallback(
    async (newPastRange, newDuration, devicesList) => {
      if (devicesList?.length === 0) return;

      try {
        setShowLoader(true);
        const baseUrl = `metrics/orgs/${custOrgData?.orgId}/trend/device-connection-status`;
        const requestId = uuidv4();
        let date = new Date();
        date.setDate(date.getDate() + newPastRange);
        const fetchResult = await axios.get(baseUrl, {
          params: {
            orgId: custOrgData?.orgId,
            startTime: date.getTime(),
            endTime: new Date().getTime(),
            rangeBy: newDuration,
            deviceIds: getDeviceIdsString(devicesList),
            requestTime: Date.now(),
            requestId: requestId,
          },
          ...Utils.requestHeader(requestId),
          timeout: 30000,
          credentials: 'include',
          withCredentials: true,
        });

        const xAxis = [];
        const yValues = [];
        let respData,
          timestampGetter,
          averagePercentageOnline,
          totalPercentageOnline,
          totalDevicesOnlinePercentages,
          timeSeriesData,
          offlineTimeAverage,
          offlineTimeTotal,
          timestamp;

        if (fetchResult?.data?.meta?.code === 200) {
          setShowLoader(false);
          respData = fetchResult.data.data?.timeSeries;
          if (Array.isArray(respData)) {
            // Get x axis values for past 7 days
            switch (newPastRange) {
              case -1:
                timeSeriesData = respData.slice(-24);
                timestampGetter = frequencyGetter['hourly'];
                break;

              case -2:
                timeSeriesData = respData.slice(-48);
                timestampGetter = frequencyGetter['hourly'];
                break;

              case -365:
                timeSeriesData = respData.slice(-12);
                timestampGetter = frequencyGetter['monthly'];
                break;

              case -730:
                timeSeriesData = respData.slice(-24);
                timestampGetter = frequencyGetter['monthly'];
                break;

              default:
                timeSeriesData = respData.slice(newPastRange);
                timestampGetter = frequencyGetter[newDuration];
            }
            const tableList = [];
            timeSeriesData?.sort((a, b) => a.timestamp - b.timestamp)
            if (id == 1) {
              totalDevicesOnlinePercentages = 0;
              timeSeriesData.forEach((time) => {
                averagePercentageOnline = 0;
                totalPercentageOnline = 0;
                timestamp = timestampGetter(time.timestamp);
                xAxis.push(timestamp);
                if (Array.isArray(time.devices) && time.devices.length > 1) {
                  time.devices.forEach((device) => {
                    totalPercentageOnline += device.percentageOnline;
                    const tableItem = {
                      monthYear: moment(new Date(time.timestamp)).format(
                        'MMM yyyy'
                      ),
                      deviceName: getDeviceLocationName(device, true),
                      locationArea: getDeviceLocationName(device, false),
                      percentageOnline:
                        device?.percentageOnline + `${id == 1 ? '%' : 'm'}`,
                    };
                    tableList.push(tableItem);
                  });

                  averagePercentageOnline =
                    totalPercentageOnline / time.devices.length;
                } else {
                  averagePercentageOnline = time.devices[0].percentageOnline;
                  const tableItem = {
                    monthYear: moment(new Date(time.timestamp)).format(
                      'MMM yyyy '
                    ),
                    deviceName: getDeviceLocationName(time?.devices[0], true),
                    locationArea: getDeviceLocationName(
                      time?.devices[0],
                      false
                    ),
                    percentageOnline: time?.devices[0]?.percentageOnline + '%',
                  };
                  tableList.push(tableItem);
                }
                yValues.push(averagePercentageOnline);

                totalDevicesOnlinePercentages += averagePercentageOnline;
              });

              setTimeSeriesData(tableList);

              setAverageCameraOnlineTime(
                (totalDevicesOnlinePercentages / timeSeriesData.length).toFixed(
                  2
                )
              );

              setHighlight((highlight) => ({
                value: `${(
                  totalDevicesOnlinePercentages / timeSeriesData.length
                ).toFixed(2)}%`,
                description: highlight?.description,
              }));
            } else {
              timeSeriesData.forEach((time) => {
                offlineTimeAverage = 0;
                offlineTimeTotal = 0;
                timestamp = timestampGetter(time.timestamp);

                xAxis.push(timestamp);

                if (Array.isArray(time.devices) && time.devices.length > 0) {
                  time.devices.forEach((device) => {
                    offlineTimeTotal += device.minutesOffline;
                    offlineTimeAverage =
                      offlineTimeTotal / time?.devices.length / 60;
                    const value = parseFloat(
                      device.minutesOffline / time.devices.length / 1000
                    );

                    const tableItem = {
                      monthYear: moment(new Date(time.timestamp)).format(
                        'DD MMM yyyy hh:mm '
                      ),
                      deviceName: getDeviceLocationName(device, true),
                      locationArea: getDeviceLocationName(device, false),
                      percentageOnline: value?.toFixed(2) + `m`,
                    };
                    tableList.push(tableItem);
                  });
                }
                yValues.push(offlineTimeAverage);
              });
              setTimeSeriesData(tableList);
            }

            // Set the y value of each tick on
            // x axis.
            setSeries([
              {
                name: '',
                data: yValues,
              },
            ]);
            // Update the chart options with new data
            setOptions({
              ...options,
              xaxis: {
                categories: [...xAxis],
              },
            });

            setHasData(true);
          }
        } else {
          setHasData(false);
          setShowLoader(false);
        }
      } catch (err) {
        setHasData(false);
        setShowLoader(false);
      }
    },
    []
  );

  useLayoutEffect(() => {
    fetchData(pastRange, duration, devices);

    new Promise((resolve) => {
      setHighlight((highlight) => ({
        value: '98%',
        description: highlight?.description,
      }));
      resolve();
    });
  }, [pastRange, duration, devices]);

  return (
    <div>
      <Row className="analytics-row">
        <Section
          analyticsTitle={
            id == 1
              ? constants.ANALYTICS_DASHBOARD_CAMERA_AVAILABILITY_SECTION_TITLE
              : constants.ANALYTICS_DASHBOARD_CAMERA_MINUTES_UNAVAILABLE_SECTION_TITLE
          }
          analyticsDescription={
            id == 1
              ? constants.ANALYTICS_DASHBOARD_CAMERA_AVAILABILITY_SECTION_DESCRIPTION
              : constants.ANALYTICS_DASHBOARD_CAMERA_MINUTES_UNAVAILABLE_SECTION_DESCRIPTION
          }
          topRightLinks={topRightLinks}
        >
          {showLoader ? (
            <div className="no-data-available">
              <SiteSpinner height="50px" width="50px"></SiteSpinner>
            </div>
          ) : (
            <div>
              {hasData ? (
                <SingleColumnLayout chart={chartContent} />
              ) : (
                defaultValue
              )}
            </div>
          )}
        </Section>
      </Row>
      <Row className="analytics-row">
        <div className={`section-wrapper default-bg`}>
          <CamAvailableListTable columns={columnsData} data={timeSeriesData} />
        </div>
      </Row>
    </div>
  );
};

export default CameraAvailableDetails;
