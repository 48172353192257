import { useState, useEffect } from 'react';
import { useTable, useSortBy } from 'react-table';
import { Dropdown } from 'react-bootstrap';
import { IoIosArrowDown } from 'react-icons/io';
import { VscChevronDown, VscChevronUp } from 'react-icons/vsc';
import { TbTrash } from 'react-icons/tb';
import { LuGlobe2 } from 'react-icons/lu';
import { RiVideoAddLine } from 'react-icons/ri';
import { FaCircle } from 'react-icons/fa';
import { Tooltip, tooltipClasses } from '@mui/material';
import { constants, DeviceTypeEnum } from '../../../helpers';
import { usePoliciesStore } from '../../../store/policiesStore';

const DiscoveredDevicesTable = ({
  columns,
  data,
  setShowDevcieSetupModal,
  setShowDeviceDeleteModal,
  renameId,
  selectedDevices,
  setSortDetails,
  onUnselectAllDevices,
  onSelectAllDevices,
  sortDetails,
  addDiscoveredDevices,
}) => {
  const [addEligibleDevices, setAddEligibleDevices] = useState([]);
  const [selectEligibleDevices, setSelectEligibleDevices] = useState([]);
  const [nonIpcamDevices, setNonIpcamDevices] = useState([]);
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies
  );
  // Use the useTable Hook to send the columns and data to build the table
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        renameId,
        selectedDevices,
      },
      useSortBy
    );

  useEffect(() => {
    const list = [];
    data.forEach((device) => {
      if (
        device &&
        device.status === 'ENTER' &&
        device.connectionStatus !== constants.DEVICES_OFFLINE_CONNECTION_STATUS
      ) {
        list.push(device);
      }
    });
    setSelectEligibleDevices(list);
  }, [selectedDevices]);

  const getTooltipData = (row, cell) => {
    if (cell.column.id === 'areaName' && !row.original.areaName) {
      return constants.DISCOVERED_DEVICES_SELECT_LOCATION_TOOLTIP;
    }

    if (cell.column.id === 'connectionStatus') {
      return (
        <div className="text-capitalize">
          {row.original?.connectionStatus ===
          constants.DEVICES_OFFLINE_CONNECTION_STATUS ? (
            <div>
              <FaCircle
                className="status-indicator status-offline"
                style={{
                  color: `${getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--error_48')}`,
                }}
              />
              {row.original?.connectionStatus}
            </div>
          ) : row.original?.connectionStatus ===
            constants.DEVICES_ONLINE_CONNECTION_STATUS ? (
            <div>
              <FaCircle
                className="status-indicator status-online"
                style={{
                  color: `${getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--success_48')}`,
                }}
              />
              {row.original?.connectionStatus}
            </div>
          ) : null}
        </div>
      );
    }
    return cell.render('Cell');
  };

  const getBulkOperationDropdown = () => {
    return (
      <Dropdown
        className="device-status-selector"
        autoClose={true}
        onToggle={() => {
          const list = [];
          const nonIpcam = [];
          selectedDevices.forEach((deviceId) => {
            const device = data.find((d) => d.deviceId === deviceId);
            if (
              device &&
              ((device.deviceType === DeviceTypeEnum.IPCam.toLowerCase() &&
                device.locationId &&
                device.areaId) ||
                (device.deviceType !== DeviceTypeEnum.IPCam.toLowerCase() &&
                  device.locationId)) &&
              device.connectionStatus !==
                constants.DEVICES_OFFLINE_CONNECTION_STATUS
            ) {
              list.push(device);
            }
            if (device.deviceType !== DeviceTypeEnum.IPCam.toLowerCase()) {
              nonIpcam.push(device);
            }
          });
          setNonIpcamDevices(nonIpcam);
          setAddEligibleDevices(list);
        }}
      >
        <Dropdown.Toggle
          variant="outline-secondary"
          id="dropdown-basic"
          className={`device-status-selector-toggle ${
            selectedDevices.length < 2 ? 'disabled' : ''
          }`}
          disabled={selectedDevices.length < 2}
        >
          <div className="device-status-selected-wrapper">
            <span className="device-status-selected-name">
              {constants.DISCOVERED_DEVICES_BILK_ACTIONS}{' '}
              {`(${selectedDevices.length})`}
            </span>
          </div>
          <IoIosArrowDown size={16} />
        </Dropdown.Toggle>
        <Dropdown.Menu className="device-status-selector-menu">
          <Dropdown.Item
            className="device-status-selector-item"
            onClick={() => setShowDevcieSetupModal(true)}
          >
            <LuGlobe2
              size={16}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--greyscale_08')}
            />
            {selectedDevices.length === nonIpcamDevices.length
              ? constants.DISCOVERED_DEVICES_SET_LOCATION
              : constants.DISCOVERED_DEVICES_SET_LOC_AREA}
          </Dropdown.Item>
          {selectedDevices.length === addEligibleDevices.length ? (
            <Dropdown.Item
              className={`device-status-selector-item ${
                !getCustomerOrgPolicies()?.install_device ? 'disabled' : ''
              }`}
              onClick={() => addDiscoveredDevices(addEligibleDevices)}
              disabled={!getCustomerOrgPolicies().install_device}
            >
              <RiVideoAddLine
                size={16}
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--greyscale_08')}
              />
              {constants.DISCOVERED_DEVICES_ADD_DEVICES}
            </Dropdown.Item>
          ) : null}
          <Dropdown.Item
            className={`device-status-selector-item ${
              !getCustomerOrgPolicies()?.delete_device ? 'disabled' : ''
            }`}
            onClick={() => setShowDeviceDeleteModal(true)}
            disabled={!getCustomerOrgPolicies().delete_device}
          >
            <TbTrash
              size={16}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--greyscale_08')}
            />
            {constants.DISCOVERED_DEVICES_REMOVE_DEVICES}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const getCellWithTooltip = (row, cell) => {
    return (
      <Tooltip
        placement={
          cell.column.id === 'locationName' || cell.column.id === 'areaName'
            ? 'left'
            : 'top-start'
        }
        arrow
        title={getTooltipData(row, cell)}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -20],
                },
              },
            ],
            sx: {
              [`& .${tooltipClasses.tooltip}`]: {
                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '16px',
                color: `var(--brand_white)`,
                background: `var(--primary_08)`,
                padding: '10px',
                fontFamily: 'Noto Sans',
                borderRadius: '8px',
              },
              [`& .${tooltipClasses.arrow}`]: {
                color: `var(--primary_08)`,
              },
            },
          },
        }}
      >
        <td className={cell.column.id} {...cell.getCellProps()}>
          {cell.render('Cell')}
        </td>
      </Tooltip>
    );
  };

  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    <div className="table-responsive">
      <table className="table table-borderless" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className={`${column.id} ${
                    column.isSorted
                      ? column.isSortedDesc
                        ? 'columnheader-asc'
                        : 'columnheader-desc'
                      : ''
                  }`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.id === 'deviceChecked' ? (
                    <>
                      <div className="viewCheckbox">
                        <input
                          type="checkbox"
                          checked={
                            selectedDevices.length &&
                            selectedDevices.length ===
                              selectEligibleDevices?.length
                          }
                          onChange={(e) => {
                            const { checked } = e.target;
                            if (checked) {
                              onSelectAllDevices();
                            } else {
                              onUnselectAllDevices();
                            }
                          }}
                        />
                      </div>
                    </>
                  ) : column.id === 'deviceOptions' ? (
                    <>
                      {selectedDevices.length < 2 ? (
                        <Tooltip
                          placement="left"
                          arrow
                          title={
                            constants.DISCOVERED_DEVICES_DISABLE_BULK_TOOLTIP
                          }
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [0, -5],
                                  },
                                },
                              ],
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  fontSize: '12px',
                                  fontWeight: '500',
                                  lineHeight: '16px',
                                  color: `var(--brand_white)`,
                                  background: `var(--primary_08)`,
                                  padding: '10px',
                                  fontFamily: 'Noto Sans',
                                  borderRadius: '8px',
                                  width: '234px',
                                },
                                [`& .${tooltipClasses.arrow}`]: {
                                  color: `var(--primary_08)`,
                                },
                              },
                            },
                          }}
                        >
                          {getBulkOperationDropdown()}
                        </Tooltip>
                      ) : (
                        getBulkOperationDropdown()
                      )}
                    </>
                  ) : (
                    <>
                      {column.render('Header')}
                      <span>
                        {sortDetails.orderBy === column.id &&
                        sortDetails.isAsc ? (
                          <VscChevronDown
                            className="header-arrow"
                            color={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--greyscale_56')}
                            onClick={() =>
                              setSortDetails({
                                orderBy: column.id,
                                isAsc: false,
                              })
                            }
                          />
                        ) : sortDetails.orderBy === column.id &&
                          !sortDetails.isAsc ? (
                          <VscChevronUp
                            className="header-arrow"
                            color={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--greyscale_56')}
                            onClick={() =>
                              setSortDetails({
                                orderBy: column.id,
                                isAsc: true,
                              })
                            }
                          />
                        ) : (
                          <VscChevronDown
                            className="header-arrow"
                            color={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--greyscale_56')}
                            onClick={() =>
                              setSortDetails({
                                orderBy: column.id,
                                isAsc: true,
                              })
                            }
                          />
                        )}
                      </span>
                    </>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows?.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return cell.column.id !== 'deviceChecked' &&
                    cell.column.id !== 'ipAddress' &&
                    cell.column.id !== 'connectionStatus' &&
                    cell.column.id !== 'areaName' &&
                    cell.column.id !== 'locationName' &&
                    cell.column.id !== 'deviceOptions' &&
                    cell.column.id !== 'deviceName' ? (
                    getCellWithTooltip(row, cell)
                  ) : cell.column.id === 'deviceName' &&
                    renameId !== row.original.deviceId ? (
                    getCellWithTooltip(row, cell)
                  ) : cell.column.id === 'areaName' &&
                    row.original.deviceType ===
                      DeviceTypeEnum.IPCam.toLowerCase() &&
                    (!row.original.locationName || row.original.areaName) ? (
                    getCellWithTooltip(row, cell)
                  ) : cell.column.id === 'locationName' &&
                    row.original.locationName ? (
                    getCellWithTooltip(row, cell)
                  ) : (cell.column.id === 'ipAddress' &&
                      row.original.ipAddress) ||
                    (cell.column.id === 'connectionStatus' &&
                      row.original.connectionStatus) ? (
                    getCellWithTooltip(row, cell)
                  ) : (
                    <td className={cell.column.id} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DiscoveredDevicesTable;
