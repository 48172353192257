import { useEffect, useState } from 'react';
import './stepspos.scss';
import PosIndicator from './PosIndicator';
import { useSearchParams } from 'react-router-dom';
import { AppDefaults, Utils, constants } from '../../../helpers';
import { PrimaryButton } from '../../../components/common';
import { PosStore } from '../../../store/PosStore';
import { useOrganizations } from '../../../store/OrganizationsStore';
import { TbCircleMinus, TbCirclePlus } from 'react-icons/tb';
import LocationSelectorPos from './LocationSelectorPos';
import { error, success } from '../../../assets/images';
import axios from 'axios';
import { RxCross1 } from 'react-icons/rx';

const EditStoreLocation = (props) => {
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const [locationStoreList, setLocationStoreList] = useState(props?.locationStoreData);
  const [currentOrgLocation, setCurrentOrgLocation] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [disableMapBtn, setDisableMapBtn] = useState(true);

  const getCustomerOrgData = useOrganizations(
    (state) => state.getCustomerOrgData
  );
  useEffect(() => {
    const currentOrg = getCustomerOrgData().find(
      (user) => user?.orgId === orgId
    );
    setCurrentOrgLocation(currentOrg?.locations);
    updateItemAtIndex()
  }, []);


  const updateItemAtIndex = async () => {
    let posData = locationStoreList?.map((posItem, index) => {
      if (index === 0) {
        return { ...posItem, storeIdCheck: AppDefaults?.POS_DEFUALT_STORE_ID };
      } else {
        return { ...posItem };
      }
    });
    let updatedPosList = await Promise.all(posData);
    setLocationStoreList(updatedPosList);
  };

  const updateFormate = async (selectedFormate) => {
    let posData = locationStoreList?.map((posItem) => {
      if (posItem?.storeIdCheck === selectedFormate?.storeIdCheck) {
        return { ...posItem, ...selectedFormate };
      } else {
        return { ...posItem };
      }
    });
    let updatedPosList = await Promise.all(posData);
    setLocationStoreList(updatedPosList);
  };

  const onHandleChange = async (e, data) => {
    if (e != undefined && e?.target?.value?.length <= 30) {
      let posData = locationStoreList?.map((posItem) => {
        if (posItem?.storeUniqeKey === data?.storeUniqeKey) {
          return { ...posItem, storeId: e?.target?.value };
        }
         else {
          return { ...posItem };
        }
      });
      let updatedPosList = await Promise.all(posData);
      setLocationStoreList(updatedPosList);
    }
  };

  useEffect(() => {
    checkBtnStatus();
  }, [locationStoreList]);

  const checkBtnStatus = () => {
    const filteredList = locationStoreList?.filter((item, index) => {
      if (item?.storeIdCheck === AppDefaults?.POS_DEFUALT_STORE_ID) {
        if (item?.storeId === '' || item?.locationId === '') {
          return true;
        } else {
          return false;
        }
      } else if (item?.storeId !== '' && item?.locationId === '') {
        return true;
      } else if (item?.storeId === '' && item?.locationId !== '') {
        return true;
      } else {
        return false;
      }
    });

    if (filteredList?.length > 0) {
      setDisableMapBtn(true);
    } else if (hasDuplicates(locationStoreList)) {
      setDisableMapBtn(true);
    } else {
      setDisableMapBtn(false);
    }
  };

  const hasDuplicates = (list) => {
    const seen = new Set();
    for (const item of list) {
      if (item.storeId != '') {
        if (seen.has(item?.storeId?.toLowerCase())) {
          return true; // Found a duplicate ID
        }
        seen.add(item?.storeId?.toLowerCase()); // Add the ID to the Set
      }
    }
    return false; // No duplicate IDs found
  };

  const addStoreLocation = (item) => {
    let locStoreArray = {
      storeIdCheck: Utils.generateUUID(),
      storeId: '',
      locationId: '',
      storeUniqeKey:Utils.generateUUID()
    };
    setLocationStoreList((prev) => [...prev, locStoreArray]);
    // setLocationStoreList(...)
  };

  const removeLocationStore = (itemRemove) => {
    const filterList = locationStoreList?.filter(
      (item) => item?.storeUniqeKey !== itemRemove?.storeUniqeKey
    );
    setLocationStoreList(filterList);
  };

  const putStoreLocation = async () => {
    let storeList = [];
    locationStoreList?.map((item) => {
      if (item?.storeId != '' && item?.locationId != '') {
        storeList.push({
          storeId: item?.storeId,
          locationId: item?.locationId,
        });
      }
    });
    if (storeList?.length === 0) {
      return;
    }

    const reqBody = {
      stores: storeList,
    };
    let responseStoreLocation = await axios.put(
      `/partner/orgs/${orgId}/stores`,
      reqBody,
      Utils.requestHeader()
    );
    let resData = responseStoreLocation?.data;

    if (resData?.meta.code === 200) {
      setShowLoader(false);
      props?.hideModal();
    } else {
      setShowLoader(false);
    }
  };
  return (
    <div className="pos-integration">
      <div className="steps-header-conatiner">
        <div className="header-container">
          <div className="left-section-layout">
            <div className="header-title">{constants.POS_LOCATION_MAPPING}</div>
          </div>
          <RxCross1 size={24} onClick={() => props.hideModal()} />
        </div>
      </div>
      {
        <div className={`manage-pos-height`}>
          <div className="step-five-locations">
            <div className="lable-upload-file">
              {constants.POS_SELECT_LOCATION}
            </div>
            <div className="extract-message">
              {constants.POS_SELECT_LOCATION_MESSAGE}
            </div>
            <div className="main-container-store-loc">
              {locationStoreList &&
                locationStoreList?.length > 0 &&
                locationStoreList?.map((item, index) => {
                  return (
                    <div className="store-location-layout">
                      <input
                        className="form-control require shadow-none input-type-box-container"
                        autoComplete="off"
                        name="defaultArea"
                        type="text"
                        value={item?.storeId}
                        onChange={(e) => onHandleChange(e, item)}
                      />
                      <LocationSelectorPos
                        isFromEdit={true}
                        posItem={item}
                        updatePosStoreWithAttribute={(selectedFormate) => {
                          updateFormate(selectedFormate);
                        }}
                        currentOrgLocation={currentOrgLocation}
                      />
                      {item?.storeIdCheck ===
                      AppDefaults?.POS_DEFUALT_STORE_ID ? (
                        <TbCirclePlus
                          size={50}
                          className="create-area-icon align-self-center"
                          onClick={() => addStoreLocation(item)}
                          style={{ cursor: 'pointer' }}
                        />
                      ) : (
                        <TbCircleMinus
                          size={50}
                          className="remove-area-icon align-self-center"
                          onClick={() => removeLocationStore(item)}
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      }

      {
        <PrimaryButton
          className="adddevice-btn"
          fontSize="14px"
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_40')}
          height="44px"
          color="#FFFFFF"
          type="submit"
          disabled={disableMapBtn}
          loader={showLoader}
          onClick={() => {
            setShowLoader(true);
            putStoreLocation();
          }}
        >
          {constants.ACCOUNT_SETTINGS_PROFILE_BUTTON_LABEL}
        </PrimaryButton>
      }
    </div>
  );
};
export default EditStoreLocation;
