import React from 'react';
import { ReactComponent as CameraPlusIcon } from '../../../assets/images/CameraPlus.svg';
import videoProcessing from '../../../assets/images/cameras/videoProcessing.svg';
import { constants } from '../../../helpers';
import { useDispatch } from 'react-redux';
import { setIsEditModeActive } from '../../../store/reducers/ViewsReducer';

const EditCamera = () => {
  const dispatch = useDispatch();

  const handlEditViewClick = () => {
    dispatch(setIsEditModeActive(true));
  };

  return (
    <>
      <div className="not-active-camera-details">
        <CameraPlusIcon className='no-active-icon' />
        <div
          className="not-active-camera-button"
          onClick={() => handlEditViewClick()}
        >
          {constants.CAMERAS_VIDEO_EDIT_VIEW_LABEL}
        </div>
      </div>
      <img
        className="hide-image"
        src={videoProcessing}
        alt="video processing"
      />
    </>
  );
};

export default EditCamera;
