import { useEffect, useState } from 'react';
import { AppDefaults, Utils, constants } from '../../../helpers';
import { PrimaryButton } from '../../../components/common';
import PosIndicator from './PosIndicator';
import { PosStore } from '../../../store/PosStore';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import {checkUnslect} from '../../../assets/images'
import { ImCheckboxChecked } from 'react-icons/im';
import './stepspos.scss';

const StepThreeAtributes = (props) => {
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const getPosDataList = PosStore((state) => state.getPosDataList);
  const [posDataListFromCSV, setPosDataListFromCSV] = useState(
    getPosDataList()
  );
  const [showLoader, setShowLoader] = useState(false);

  const resetPosDataList = PosStore((state) => state.resetPosDataList);
  const setPosDataList = PosStore((state) => state.setPosDataList);
  const setAtrributeList = PosStore((state) => state.setAtrributeList);
  const getAttributes = async () => {
    try {
      const res = await axios.get(`/partner/orgs/${orgId}/pos/attributes`, {
        ...Utils.requestHeader(),
        credentials: 'include',
        withCredentials: true,
      });

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        if (responseData?.data?.attributes?.length > 0) {
          setAtrributeList(responseData?.data?.attributes);
          props.nextStep();
        } else {
          setAtrributeList([]);
        }
        setShowLoader(false);
      } else {
        setAtrributeList([]);
        setShowLoader(false);
        if (res?.code) {
          console.error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          console.error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onHandleChange = async (item) => {
    let posData = posDataListFromCSV?.map((posItem, index) => {
      if (posItem?.Column === item?.Column) {
        if (item?.isSelected) {
          return { ...posItem, isSelected: false };
        } else {
          return { ...posItem, isSelected: true };
        }
      } else {
        return { ...posItem };
      }
    });
    let updatedPosList = await Promise.all(posData);
    setPosDataList(updatedPosList);
    setPosDataListFromCSV(updatedPosList);
  };

  return (
    <div className="pos-integration">
      <PosIndicator showMapPostStatus={0} {...props} />
      <div className="manage-pos-height">
        <div className="step-two-posdata-container">
          <div className="lable-upload-file">
            {constants.POS_SELECT_COLUMNS}
          </div>
          <div className="extract-message">
            {constants.POS_SELECT_COLUMNS_MESSAGE.replace(
              '${appname}',
              `${
                process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON
                  ? constants.POS_ON_CLOUD_CONNECT
                  : constants.POS_DUCLO_CONNECT
              }`
            )}
          </div>
          <div className="selected-column-container">
            <div className="selected-col-count">
              {
                posDataListFromCSV?.filter((posItem) => posItem?.isSelected)
                  ?.length
              }
            </div>
            <div className="all-col-count">{`/${getPosDataList()?.length} ${
              constants.POS_COLUMNS_SELECTED
            }`}</div>
          </div>

          {posDataListFromCSV &&
            posDataListFromCSV?.map((item, index) => {
              return (
                <div key={`${item.Column}-${index}`} className="list-container">
                  <div className="left-layout-col">
                    <div className="col-name-heading">{item.Column}</div>
                    <div className="sub-content-label">{item.value}</div>
                  </div>
                  <div className="right-layout-col">
                    {/* <input
                      key={item.Column}
                      checked={item?.isSelected}
                      className="input-check-events"
                      type="checkbox"
                      onChange={(e) => onHandleChange(item)}
                    /> */}
                    {!item?.isSelected && (
                      <img
                        onClick={() => {
                          onHandleChange(item);
                        }}
                        alt=""
                        className="img-unselected"
                        src={checkUnslect}
                      ></img>
                    )}
                    {item?.isSelected && (
                      <ImCheckboxChecked
                        className="img-selected"
                        color={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--primary_40')}
                        onClick={() => onHandleChange(item)}
                        size={20}
                      />
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="col">
        <PrimaryButton
          className="adddevice-btn"
          fontSize="14px"
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_40')}
          height="44px"
          color="#FFFFFF"
          type="submit"
          disabled={
            posDataListFromCSV?.filter((posItem) => posItem?.isSelected)
              ?.length <= 0
          }
          loader={showLoader}
          onClick={() => {
            setPosDataList(posDataListFromCSV);
            setShowLoader(true);
            getAttributes();
          }}
        >
          {constants.DEVICES_CONTINUE_TEXT}
        </PrimaryButton>
        <PrimaryButton  
          className="adddevice-btn"
          fontSize="14px"
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--brand_white')}
          height="44px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--primary_40'
          )}
          borderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_40')}
          type="submit"
          onClick={() => {
            props.prevstep();
            resetPosDataList([]);
          }}
        >
          {constants.ORGANIZATION_LDAP_GO_BACK}
        </PrimaryButton>
      </div>
    </div>
  );
};
export default StepThreeAtributes;
