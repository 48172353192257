import { useEffect, useState } from 'react';
import './stepspos.scss';
import { constants, AppDefaults, Utils } from '../../../helpers';
import { PrimaryButton } from '../../../components/common';
import PosIndicator from './PosIndicator';
import { PosStore } from '../../../store/PosStore';
import AttributeSelector from './AttributeSelector';
import DTAttributeSelector from './DTAttributeSelector';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { error, success } from '../../../assets/images';
import { useOrganizations } from '../../../store/OrganizationsStore';
import { HiOutlineExclamationCircle } from 'react-icons/hi';

const StepThreeAtributes = (props) => {
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const getPosDataList = PosStore((state) => state.getPosDataList);
  const setPosDataList = PosStore((state) => state.setPosDataList);
  const [posDataListFromCSV, setPosDataListFromCSV] = useState(
    getPosDataList()
  );
  const getOrganizations = useOrganizations((state) => state.getOrganizations);
  const [showLoader, setShowLoader] = useState(false);
  const getIsHeaderChecked = PosStore((state) => state.getIsHeaderChecked);
  const [disableMapBtn, setDisableMapBtn] = useState(true);
  const [showMapPostStatus, setShowMapPostStatus] = useState(0);
  const [userMessage, setUserMessage] = useState('');
  const getAtrributeList = PosStore((state) => state.getAtrributeList);

  const updatePosWithAttribute = async (data) => {
    let posData = posDataListFromCSV?.map((posItem, index) => {
      if (posItem?.Column === data?.Column) {
        return { ...posItem, ...data };
      } else {
        return { ...posItem };
      }
    });
    let updatedPosList = await Promise.all(posData);
    setPosDataListFromCSV(updatedPosList);
  };

  const updateFormate = async (selectedFormate) => {
    setUserMessage('');
    let posData = posDataListFromCSV?.map((posItem) => {
      if (posItem?.type === AppDefaults?.DATA_TYPE_DATE) {
        return { ...posItem, formateSelected: selectedFormate };
      } else {
        return { ...posItem };
      }
    });
    let updatedPosList = await Promise.all(posData);
    setPosDataListFromCSV(updatedPosList);
  };

  useEffect(() => {
    getMappingStatus();
  }, [posDataListFromCSV]);

  const getMappingStatus = () => { 
    const removeOptionalAttributeList = getAtrributeList()?.filter(
      (itemAttribute) => !itemAttribute?.optional
    );
    const posAtributeList = posDataListFromCSV?.filter(
      (itemAttribute) => itemAttribute?.isSelected && itemAttribute?.id
    );

    let mandetoryAttribute = [];
    let nonOptioanlList = [];
    let disableBtn = false;

    for (let i = 0; i < posAtributeList?.length; i++) {
      if (!mandetoryAttribute?.includes(posAtributeList[i]?.id)) {
        if (posAtributeList[i]?.type === AppDefaults.DATA_TYPE_DATE) {
          if (posAtributeList[i]?.formateSelected) {
            disableBtn = false;
            mandetoryAttribute.push(posAtributeList[i]?.id);
            nonOptioanlList.push(posAtributeList[i]);
          }
        } else if (posAtributeList[i]?.custom) {
          if (
            posAtributeList[i]?.customValue &&
            posAtributeList[i]?.customValue != ''
          ) {
            disableBtn = false;
            mandetoryAttribute.push(posAtributeList[i]?.id);
            nonOptioanlList.push(posAtributeList[i]);
          } else {
            disableBtn = false;
            mandetoryAttribute.push(posAtributeList[i]?.id);
            const param = { ...posAtributeList[i], isvalid: '1' };
            nonOptioanlList.push(param);
          }
        } else {
          disableBtn = false;
          mandetoryAttribute.push(posAtributeList[i]?.id);
          nonOptioanlList.push(posAtributeList[i]);
        }
      } else {
        disableBtn = true;
        break;
      }
    }
    const isValid = nonOptioanlList?.filter((item) => item?.isvalid === '1');
    if (isValid?.length > 0) {
      setDisableMapBtn(true);
    } else if (disableBtn) {
      setDisableMapBtn(true);
    } else if (
      nonOptioanlList?.filter((itemAttribute) => !itemAttribute?.optional)
        ?.length === removeOptionalAttributeList?.length
    ) {
      setDisableMapBtn(false);
    } else {
      setDisableMapBtn(true);
    }
  };

  const postMapping = async () => {
    let fieldsList = [];
    const posAtributeList = posDataListFromCSV?.filter(
      (itemAttribute) => itemAttribute?.isSelected && itemAttribute?.id
    );
    posAtributeList?.map((item) => {
      if (item?.type === AppDefaults?.DATA_TYPE_DATE) {
        fieldsList.push({
          cName: item?.Column,
          id: item?.id,
          order: item?.order,
          format: item?.formateSelected,
          columnDescription: item?.value,
        });
      } else if (item?.custom) {
        fieldsList.push({
          cName: item?.Column,
          id: item?.id,
          order: item?.order,
          columnDescription: item?.value,
          customAttributeName: item?.customValue,
        });
      } else {
        fieldsList.push({
          cName: item?.Column,
          id: item?.id,
          order: item?.order,
          columnDescription: item?.value,
        });
      }
    });
    const reqBody = {
      header: getIsHeaderChecked() === 1 ? true : false,
      fields: fieldsList,
    };
    let response;
    if(props?.isFromEdit){
      response = await axios.put(
        `/partner/orgs/${orgId}/pos/mapping/fields`,
        reqBody,
        Utils.requestHeader()
      );
    }
    else{
      response = await axios.post(
        `/partner/orgs/${orgId}/pos/mapping/fields`,
        reqBody,
        Utils.requestHeader()
      );
    }
     

    let resData = response?.data;

    if (resData?.meta.code === 200) {
      await getOrganizations(`/partner/account/self`);
      setShowMapPostStatus(1);
      setShowLoader(false);
    } else {
      setShowMapPostStatus(2);
      setShowLoader(false);
    }
  };

  const onHandleChange = async (e, data) => {
    if (e != undefined && e?.target?.value?.length<=30) {
      let posData = posDataListFromCSV?.map((posItem) => {
        if (posItem?.id === data?.id) {
          return { ...posItem, customValue: e?.target?.value };
        } else {
          return { ...posItem };
        }
      });
      let updatedPosList = await Promise.all(posData);
      setPosDataListFromCSV(updatedPosList);
    }
  };

  return (
    <div className="pos-integration">
      <PosIndicator showMapPostStatus={showMapPostStatus} {...props} />
      {showMapPostStatus === 0 && (
        <div className={`manage-pos-height`}>
          <div className="step-three-Attributes">
            <div className="lable-upload-file">
              {constants.POS_MAP_ATTRIBUTE}
            </div>
            <div className="extract-message">
              {constants.POS_SELECT_ATTRIBUTE}
            </div>
            {userMessage != '' && (
              <div className="validation-message">{userMessage}</div>
            )}
            {posDataListFromCSV &&
              posDataListFromCSV?.map((item) => {
                return (
                  item?.isSelected && (
                    <div
                      className={`list-container ${
                        item?.type === AppDefaults?.DATA_TYPE_DATE ||
                        (item?.custom && 'date-time-selected')
                      }`}
                    >
                      <div
                        className={`left-layout-col ${
                          item?.type === AppDefaults?.DATA_TYPE_DATE ||
                          (item?.custom && 'date-time-selected')
                        }`}
                      >
                        <div className="col-name-heading">{item?.Column}</div>
                        <div className="sub-content-label">{item?.value}</div>
                      </div>
                      <div className="right-layout-col">
                        <AttributeSelector
                          updatePosStoreWithAttribute={(posAttributeData) => {
                            updatePosWithAttribute(posAttributeData);
                          }}
                          posItem={item}
                          posDataListFromCSV={posDataListFromCSV}
                          getAtrributeList={getAtrributeList()}
                        />
                        {item?.type === AppDefaults?.DATA_TYPE_DATE && (
                          <DTAttributeSelector
                            posItem={item}
                            formats={item?.formats}
                            updateDateSelected={(selectedFormate) => {
                              updateFormate(selectedFormate);
                            }}
                          />
                        )}
                        {item?.custom && (
                          <div className="textbox-pos">
                            <input
                              value={item?.customValue}
                              onChange={(e) => onHandleChange(e, item)}
                              style={{ border: 'none', width: '100%' }}
                              className={`form-control require shadow-none${
                                item?.customValue ? 'has-value' : ''
                              }`}
                              placeholder={`${constants.POS_ATTRIBUTE_NAME_PLACHOLDER}`}
                              autoComplete="off"
                              beforeinputicon=""
                              afterinputicon=""
                            />
                            {
                            (item?.customValue === undefined || item?.customValue == '') && <div className="asterisk">*</div>
                            }
                          </div>
                        )}
                        {item?.custom && (
                          <div className="bottom-strip-container custom-attribute">
                            <div className="custom-attibute-value-length">{`${
                              item?.customValue ? item?.customValue?.length : 0
                            } / 30`}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                );
              })}
          </div>
        </div>
      )}

      {(showMapPostStatus === 1 || showMapPostStatus === 2) && (
        <div className={`manage-pos-height`}>
          <div className="org-map-setup-content modal-succ-failure">
            <img src={showMapPostStatus === 1 ? success : error} alt="" />
            <div className="title-setup-completed">
              {showMapPostStatus === 1
                ? constants.POS_SETEUP_COMPLETED
                : constants.POS_SETEUP_ERROR}
            </div>
            <PrimaryButton
              className="adddevice-btn btn-margin-try"
              fontSize="14px"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              height="44px"
              color="#FFFFFF"
              type="submit"
              onClick={() => {
                showMapPostStatus === 1 ? props?.hideModal() : postMapping();
              }}
            >
              {showMapPostStatus === 1
                ? constants.POS_SETEUP_COMPLETED_BTN
                : constants.POS_SETEUP_ERROR_BTN}
            </PrimaryButton>
            {showMapPostStatus === 2 && (
              <PrimaryButton
                className="adddevice-btn"
                fontSize="14px"
                backgroundColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--brand_white')}
                height="44px"
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--primary_40')}
                borderColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--primary_40')}
                type="submit"
                onClick={() => {
                  props.hideModal();
                }}
              >
                {constants.CANCEL_BUTTON_TITLE}
              </PrimaryButton>
            )}
          </div>
        </div>
      )}

      {showMapPostStatus === 0 && (
        <PrimaryButton
          className="adddevice-btn"
          fontSize="14px"
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_40')}
          height="44px"
          color="#FFFFFF"
          type="submit"
          disabled={disableMapBtn}
          loader={showLoader}
          onClick={() => {
            setPosDataList(posDataListFromCSV)
            props.nextStep()
          }}
        >
          {constants.TOS_PP_CONTINUE}
        </PrimaryButton>
      )}
      {showMapPostStatus === 0 && (
        <PrimaryButton
          className="adddevice-btn"
          fontSize="14px"
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--brand_white')}
          height="44px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--primary_40'
          )}
          borderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_40')}
          type="submit"
          onClick={() => {
            props.prevstep();
          }}
        >
          {constants.ORGANIZATION_LDAP_GO_BACK}
        </PrimaryButton>
      )}
    </div>
  );
};
export default StepThreeAtributes;
