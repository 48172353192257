import { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { constants } from '../../../helpers';
import './stepspos.scss';

const LocationSelectorPos = ({posItem,currentOrgLocation,updatePosStoreWithAttribute,isFromEdit = false}) => {
  const attribute = currentOrgLocation;
  const [selectedAttribute, setSelectedAttribute] = useState(
    constants.SELECT_LOCATION_TITLE
  );
  const [currentList, setCurrentList] = useState(
    []
  );

  useEffect(()=>{
    if(isFromEdit && posItem?.locationId && posItem?.locationId != ''){
      let selectedAT = currentOrgLocation?.find((atItem) => atItem?.locationId === posItem?.locationId);
      setSelectedAttribute(selectedAT?.locationName)
    }
    setCurrentList(currentOrgLocation)

  },[currentOrgLocation])

  const onSelectAreaHandler = (evtKey) => {
    if (!evtKey) return;
    if(evtKey === constants.SELECT_LOCATION_TITLE) return;
    let selectedAT = attribute.find((atItem) => atItem?.locationId === evtKey);
    const obj = {...posItem, ...selectedAT}
    updatePosStoreWithAttribute(obj)
    setSelectedAttribute(selectedAT.locationName);
  };

  const attributeContent =
    attribute?.length > 0 &&
    attribute?.map((atItems) => (
      <Dropdown.Item
        key={`area-${atItems.locationId}`}
        className={
          `area-selector-menu-item` +
          (selectedAttribute === atItems.locationName ? ' active' : '')
        }
        eventKey={atItems.locationId}
      >
        {`${atItems.locationName}`}
      </Dropdown.Item>
    ));

  return (
    <div className="selection-wrapper">
      <Dropdown className="area-selector" onSelect={onSelectAreaHandler}>
        <Dropdown.Toggle
          variant="outline-secondary"
          id="dropdown-area"
          className="area-selector-toggle"
        >
          <div className="area-selected-wrapper">
            <span className="area-selected-name text-selected">{selectedAttribute}</span>
          </div>
          <HiOutlineChevronDown size={20} />
        </Dropdown.Toggle>
        <Dropdown.Menu className={`${currentList?.length > 2 ? 'area-selector-menu' : 'area-selector-minList'}`}>
         
          {attributeContent}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default LocationSelectorPos;
