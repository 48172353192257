import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { PrimaryButton, SiteToast } from '../../components/common';
import { SelectField, TextField } from '../../components/forms';
import { constants, Utils, roles } from '../../helpers';
import ManageAreas from './ManageAreas';
import { useSearchParams } from 'react-router-dom';
import Autocomplete from 'react-google-autocomplete';
import axios from 'axios';
import timezones from '../../data/support/timezone.json';
import { useEffect } from 'react';
import { useOrganizations } from '../../store/OrganizationsStore';
import { PiWarningCircleBold } from 'react-icons/pi';
import { IoClose } from "react-icons/io5";

// Schema for yup
const validationSchema = Yup.object().shape({
  locationName: Yup.string()
    .max(32, constants.CANT_LONGER_ERROR_MESSAGE)
    .required(constants.NAME_REQUIRED_ERROR_MESSAGE),
  addressLine1: Yup.string().required(constants.ADDRESS_REQUIRED_ERROR_MESSAGE),
  timezone: Yup.string().required(constants.TIMEZONE_REQUIRED_ERROR_MESSAGE),
});

function NewLocations({
  AddLocationformRef,
  onFormValidityChange,
  onFormSubmittingChange,
  ...props
}) {
  const [variant, setVariant] = useState('error');
  const [userMsg, setUserMsg] = useState('');
  const [visibleLocHelperText, setVisibleLocHelperText] = useState(false);
  const [locHelperText, setLocHelperText] = useState('');
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const [newLocaAreas, setNewLocaAreas] = useState([]);
  const childFunc = React.useRef(null);
  const zoneTimeList = timezones?.data;
  const [currentUserTimezone, setCurrentUserTimezone] = useState({});
  const [orgContact, setOrgContact] = useState([]);
  const getOrganizationContact = useOrganizations(
    (state) => state.getOrganizationContact
  );
  const loggedInUserRole = useOrganizations((state) => state.loggedInUserRole);
  const customerOrgData = useOrganizations((state) => state.customerOrgData);
  const partnerOrgData = useOrganizations((state) => state.partnerOrgData);
  const partnerOrgId =
    loggedInUserRole === roles.ROLE4VMS || loggedInUserRole === roles.ROLE5VMS
      ? partnerOrgData?.orgId
      : customerOrgData[0]?.orgId;

      
  const disableInput = (event, values) => {
    if (values.addressLine1) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  useEffect(() => {
    console.log('currentUserTimezone', currentUserTimezone);
  }, [currentUserTimezone]);

  useEffect(() => {
    if (partnerOrgId) {
      // get data for organization contact dropdown
      const roleParam = encodeURIComponent(roles.ROLE2VMS);
      getOrganizationContact(`/partner/orgs/${orgId}/accounts/v2?roleIds=${roleParam}`)
        .then((res) => {
          setOrgContact(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [orgId]);

  const changeTimezoneValue = (evebr, setFieldValue) => {
    let selectedVal = evebr?.target?.value;
    const getLocationSelected = zoneTimeList?.find(
      (zone) => zone.value === selectedVal
    );
    setCurrentUserTimezone(getLocationSelected);
    setFieldValue('timezone', getLocationSelected?.value);
    setVisibleLocHelperText(false);
  };

  const checkAddedAreas = () => {
    if(newLocaAreas.some((area) => area.isDefault && !area.areaName)){
      return true;
    }
    const nameEmpty = newLocaAreas.filter((area) => !area.areaName);
    if(nameEmpty.length === newLocaAreas.length){
      return true;
    }
    return false;
  }
  
  const getValidation = (values,areas) => {
    const notEmptyArea = areas?.filter((area) => area.areaName);
    const notEmptyLocalArea = newLocaAreas?.filter((area) => area.areaName);

    const validation = values.locationName.trim().length !== 0 &&
    ((notEmptyArea && notEmptyArea?.length !== 0) ||
     (notEmptyLocalArea && notEmptyLocalArea?.length !== 0) ) &&
    values?.displayDetails?.toString()?.trim()?.length !== 0 &&
    values.addressLine1?.toString()?.trim()?.length !== 0 &&
    values.city?.toString()?.trim()?.length !== 0 &&
    values.state?.toString()?.trim()?.length !== 0 &&
    values.country?.toString()?.trim()?.length !== 0 &&
    values.zipcode
    onFormValidityChange(validation)
  }

  return (
    <section className="modal-main-content">
      <Formik
        innerRef={AddLocationformRef}
        initialValues={{
          locationName: '',
          displayDetails: '',
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          country: '',
          zipcode: '',
          locationStatus: 'Active',
          lat: 0,
          lng: 0,
          contactId: '',
          isDefault: false,
          areas: [],
          timezone: currentUserTimezone?.value,
          // bandwidth: ''
        }}
        // Hooks up our validationSchema to Formik
        validate={(values) => {
          getValidation({ ...values, locationName: values.locationName});
        }}
        validationOnChange={true}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const areaSelected = childFunc.current();
          // When button submits form and form is in the process of submitting, submit button is disabled
          setSubmitting(true);
          onFormSubmittingChange(true);
          setUserMsg('');
          if (orgId !== null && orgId !== undefined) {
            // Simulate submitting to database, shows us values submitted, resets form
            values.areas = areaSelected !== undefined ? areaSelected : [];
            axios
              .post(`partner/orgs/${orgId}/locationAreas`, values, {
                ...Utils.requestHeader(),
              })
              .then((res) => {
                let response = res.data;
                if (response.meta.code === 200) {
                  setSubmitting(false);
                  onFormSubmittingChange(false);
                  props.reloadData();
                  props.hideModal();
                } else {
                  setVariant('error');
                  setUserMsg(response?.meta?.userMsg);
                  setSubmitting(false);
                  onFormSubmittingChange(false);
                }
              })
              .catch(function (error) {
                setVariant('error');
                setUserMsg(error?.message);
              });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          isValid,
          dirty,
        }) => (
          <>
            <div className='manage-height'>
              <TextField
                required={true}
                removebottommargin="true"
                removetopmargin="true"
                label=""
                placeholder={constants.LOCATION_NAME_FIELD}
                name="locationName"
                type="text"
                onChange={(e) =>{
                  setFieldValue('locationName',e.target.value) 
                  getValidation({ ...values, locationName: e.target.value });
                }}
              />

              <div className="row">
                <div className="col position-relative mt-2 address-with-reset">
                  <div>
                    <Autocomplete
                      name="displayDetails"
                      placeholder=""
                      apiKey={process.env.REACT_APP_GOOGLE_PLACE_URL}
                      onChange={(evt) => {
                        if (!evt.target.value) {
                          setFieldValue('zipcode', '');
                          setFieldValue('country', '');
                          setFieldValue('city', '');
                          setFieldValue('state', '');
                          setFieldValue('addressLine1', '');
                        }
                      }}
                      onKeyDown={(e) => {
                        disableInput(e, values)}
                      }
                      onBlur={(e) => getValidation({ ...values, displayDetails: e.target.value })}
                      // onFocus={
                      //   (e) => e.target.setAttribute("autoComplete", "none")
                      // }
                      id="displayDetails"
                      options={{
                        fields: [
                          'formatted_address',
                          'geometry',
                          'name',
                          'address_components',
                        ],
                        strictBounds: false,
                        types: ['address'],
                      }}
                      onPlaceSelected={(place) => {
                        if(place?.address_components?.length){
                        setFieldValue('lat', place?.geometry?.location?.lat());
                        setFieldValue('lng', place?.geometry?.location?.lng());

                        let addressComponent, addressComponentType, rawzipcode, rawcountry, rawcity, rawstate, rawaddline;
                        for (
                          let i = 0;
                          i < place?.address_components?.length;
                          i++
                        ) {
                          addressComponent = place.address_components[i];
                          let j = 0;
                          addressComponentType = addressComponent.types[j];
                          if (addressComponentType === 'postal_code') {
                            rawzipcode = addressComponent.long_name ? addressComponent.long_name : '';
                          }
                          if (addressComponentType === 'country') {
                            rawcountry = addressComponent.long_name ? addressComponent.short_name : '';
                          }
                          if (addressComponentType === 'administrative_area_level_1') {
                            rawstate = addressComponent.long_name  ? addressComponent.short_name : '';
                          }
                          if (addressComponentType === 'locality') {
                            rawcity = addressComponent.long_name  ? addressComponent.long_name : '';
                          }
                          
                          if (
                            addressComponentType === 'street_number' || 
                            addressComponentType === 'route' || 
                            addressComponentType === 'neighborhood' || 
                            addressComponentType.includes('sublocality')
                          ){
                            rawaddline = `${rawaddline ? rawaddline : ''}${addressComponent.long_name}, `;
                          }
                        }
                        setFieldValue('zipcode', rawzipcode);
                        setFieldValue('country', rawcountry);
                        setFieldValue('city', rawcity);
                        setFieldValue('state', rawstate);
                        setFieldValue('addressLine1', rawaddline);
                        setFieldValue('displayDetails', 
                        (rawaddline ? rawaddline : '') + 
                        (rawcity ? rawcity + ', ' : '') + 
                        (rawstate ? rawstate + ', ' : '') + 
                        (rawcountry ? rawcountry + ', ' : '') + 
                        (rawzipcode ? rawzipcode : ''));

                        if(place?.geometry?.location?.lat() && place?.geometry?.location?.lng()){

                          var config = {
                            method: 'get',
                            url: `https://maps.googleapis.com/maps/api/timezone/json?location=${place?.geometry?.location?.lat()},${place?.geometry?.location?.lng()}&timestamp=${
                              new Date().getTime() / 1000
                            }&language=es&key=${
                              process.env.REACT_APP_GOOGLE_TIMEZONE_API_KEY
                            }`,
                            headers: {},
                          };

                          fetch(config?.url)
                          .then(function (response) {
                            return response.json();
                          })
                          .then(function (json) {
                            if (json?.status === 'OK') {
                              const data = zoneTimeList?.find(
                                (zone) => zone?.location === json?.timeZoneId
                              );
                              if (data) {
                                setCurrentUserTimezone(data);
                                setFieldValue('timezone', data?.value);
                                setLocHelperText(
                                  constants.LOCATION_TIMEZONE_ORGANIZATION_MESSAGE
                                );
                              } else {
                                setLocHelperText(
                                  constants.AUTO_TIMEZONE_FAIL_MESSAGE
                                );
                                setFieldValue('timezone', '');
                              }
                              setVisibleLocHelperText(true);
                            } else {
                              setVisibleLocHelperText(true);
                              setLocHelperText(
                                constants.AUTO_TIMEZONE_FAIL_MESSAGE
                              );
                            }
                          });
                        }
                      }
                      }}
                      className={`form-control shadow-none autoaddress ${
                        touched.displayDetails &&
                        (errors.addressLine1 || !values.displayDetails) &&
                        'is-invalid'
                      } ${values.addressLine1 && 'has-value'}`}
                    />
                    {!document.getElementById("displayDetails")?.value ?
                    <span className={`placeholder-wrapper required`}>
                      {constants.ADDRESS_FIELD}
                    </span> : null }
                    {values.addressLine1 ? 
                      <span className={`reset-address-wrapper`}>
                        <IoClose size={15}
                          onClick={()=> {  
                          setFieldValue('zipcode', '');
                          setFieldValue('country', '');
                          setFieldValue('city', '');
                          setFieldValue('state', '');
                          setFieldValue('addressLine1', '');   
                          setFieldValue('displayDetails', ''); 
                          setFieldValue('timezone', ''); 
                          document.getElementById("displayDetails").value='';
                          getValidation({ 
                            ...values, 
                            displayDetails:'',
                            zipcode: '',
                            city: '',
                            state:'',
                            country:''
                          });
                          setFieldTouched('displayDetails', true, false);
                        }}/>
                      </span> : null 
                    }
                  </div>
                  <ErrorMessage
                    component="div"
                    name="addressLine1"
                    className="error"
                  />
                  {touched.displayDetails &&
                    (!values.addressLine1 ||
                    !values.city || !values.state || !values.country || !values.zipcode) && (
                      <div className="error">
                        {constants.ADDRESS_FIELD_ERROR_MESSAGE}
                      </div>
                    )}
                </div>
              </div>
              <div className="row">
                <div className="col position-relative mt-2">
                  <div>
                    <SelectField
                      isFromOrg={true}
                      name="timezone"
                      options={zoneTimeList}
                      label=""
                      defaultOptionLabel="Time Zone"
                      value={values.timezone}
                      onChange={(evebr) =>
                        changeTimezoneValue(evebr, setFieldValue)
                      }
                    />
                  </div>

                  {visibleLocHelperText ? (
                    <div className="info-timezone">
                      <PiWarningCircleBold
                        color={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--greyscale_56')}
                        size={16}
                      />
                      <div className="message-info">{locHelperText}</div>
                    </div>
                  ) : null}
                </div>
              </div>

              <SelectField
                isFromOrg={true}
                name="contactId"
                key="contactId"
                options={orgContact}
                defaultOptionLabel={constants.LOCATION_CONTACT_DROPDOWN_LABEL}
              />

              <ManageAreas
                childFunc={childFunc}
                disabledState={!(dirty && isValid)}
                areasData={newLocaAreas}
                updateAreaData={(areas) => {
                  setNewLocaAreas(areas);
                  getValidation(values,areas)
                }}
              />
              </div>

              {/* TODO: will update once get some backend value <label className="pt-2 mt-4">
                <strong className='location-modal-heading'>{constants.BANDWIDTH_USAGE_BUDGET}</strong>

                <TextBlock
                  fontWeight="400"
                  fontSize="0.875rem"
                  lineHeight="20px"
                  color={getComputedStyle(document.documentElement).getPropertyValue('--brand_primary')}
                >
                  {constants.BANDWIDTH_HELPER_TEXT}
                </TextBlock>
              </label> */}

              {/* TODO: will update once get some backend value <div>
                <div className="d-flex align-items-center flex-row mt-3">
                  <input
                    className="form-control require shadow-none"
                    placeholder='(Optional)'
                    autoComplete="off"
                    name="bandwidth"
                    type="text"
                    value={values.bandwidth}
                    onChange={(event) => setFieldValue('bandwidth', event.target.value)}
                  />
                  <TextBlock
                    className="ml-2"
                    fontWeight="500"
                    fontSize="1rem"
                    lineHeight="20px"
                    color={getComputedStyle(document.documentElement).getPropertyValue('--greyscale_40')}
                  >
                    kbps
                  </TextBlock>
                </div>
                <div className="info-timezone mt-1">
                  <div className="message-info">
                    {constants.BANDWIDTH_MAX_CONTENT_HELPER_TEXT}
                  </div>
                </div>
              </div> */}
            {/* Show error messages */}
            <SiteToast
              title={
                variant === 'error'
                  ? constants.ERROR_TOAST_TITLE
                  : constants.SUCCESS_TOAST_TITLE
              }
              show={!!userMsg}
              body={userMsg}
              variant={variant}
              position="top-center"
            />
          </>
        )}
      </Formik>
    </section>
  );
}

export default NewLocations;
