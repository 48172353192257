import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { WiTime4 } from 'react-icons/wi';
import {
  clearWSSConnections,
  getCDNInfo,
  getLiveStreams,
  getWSSConnections,
  removeMQTTPeerConnections,
  removeRemoteStreams,
  removeRemoteStreamsAudio,
  setIsReloadedStream,
  setWSSConnection,
} from '../../../store/reducers/StreamingReducer';
import { setDeviceInformation } from '../../../store/reducers/AccountReducer';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { HiOutlineVideoCamera } from 'react-icons/hi2';
import { FiSettings } from 'react-icons/fi';
import OfflineCamera from './OfflineCamera';
import LoadingCamera from './LoadingCamera';
import { Utils, constants } from '../../../helpers';
import { ReactComponent as ThreeDotIcon } from '../../../assets/images/VerticalThreeDots.svg';
import videoProcessing from '../../../assets/images/cameras/FootageThumb.svg';
import useEventsStore from '../../../store/EventsStore';
import { getNoPlaybackContentAvailable } from '../../../store/reducers/ViewsReducer';
import { disconnectWithWebSocket } from '../../multilive/components/playback/wssConnection/wssConnection';
import NoVideoContent from './NoVideoContent';

const PlaybackGridItem = ({
  deviceId,
  layout,
  device,
  activeTime = new Date(),
  timeZone,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cdnInfo = useSelector(getCDNInfo);
  const remoteStream = useSelector(getLiveStreams)?.[deviceId];
  const noPlaybackAvailableList = useSelector(getNoPlaybackContentAvailable);
  const wssConnections = useSelector(getWSSConnections);
  const [isNoPlaybackAvailable, setIsNoPlaybackAvailable] = useState(false);
  const remoteVideoRef = useRef();
  const [loading, setLoading] = useState(true);
  const { setSelectedEventStore, setEventCVRMode } = useEventsStore();

  useEffect(() => {
    if (remoteStream?.active && remoteVideoRef.current) {
      setIsNoPlaybackAvailable(false);
      const remoteVideo = remoteVideoRef.current;
      if (remoteVideo) remoteVideo.srcObject = remoteStream;
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  }, [remoteStream, remoteVideoRef, isNoPlaybackAvailable]);

  useEffect(() => {
    if (noPlaybackAvailableList?.length > 0) {
      setIsNoPlaybackAvailable(noPlaybackAvailableList.includes(deviceId));
    } else {
      setIsNoPlaybackAvailable(false);
    }
  }, [noPlaybackAvailableList]);

  const OnClickCameraDetails = () => {
    if (wssConnections) {
      Object.keys(wssConnections).forEach((key) => {
        dispatch(removeRemoteStreams(key));
        dispatch(removeRemoteStreamsAudio(key));
        dispatch(removeMQTTPeerConnections(key));
        disconnectWithWebSocket(key);
      });
    }
    dispatch(clearWSSConnections());
    dispatch(setIsReloadedStream(false));
    dispatch(setWSSConnection(false));
    setSelectedEventStore(Utils.getUnixDate(activeTime) * 1000);
    setEventCVRMode(true);
    navigate(`/cameras/dashboard.html`, {
      state: {
        id: deviceId,
        cdnInfo: cdnInfo ? cdnInfo : {},
      },
    });
  };

  const OnClickCameraSettings = () => {
    dispatch(setDeviceInformation(device));
    deviceId && navigate(`/devices/dashboard.html?deviceId=${deviceId}`);
  };

  const handleDoubleClick = () => {
    OnClickCameraDetails();
  };

  return (
    <>
      {isNoPlaybackAvailable ? (
        <NoVideoContent
          deviceId={deviceId}
          device={device}
          activeTime={activeTime}
          timeZone={timeZone}
        />
      ) : (
        <>
          {remoteStream?.active && !isNoPlaybackAvailable ? (
            <>
              <div
                className={`device-overlay hovered`}
                onDoubleClick={() => handleDoubleClick()}
              >
                <div className="device-title-container">
                  <div className="device-name">{device?.deviceName}</div>
                  <div className="device-location">
                    {device?.locationName} • {device?.areaName}
                  </div>
                </div>
                <div className="date-time-wrapper">
                  <div className="date-time">
                    <WiTime4 size={14} />
                    {moment
                      .tz(moment(activeTime), timeZone)
                      .format('hh:mm:ss A z')}
                  </div>
                </div>
                <div className="menu-icon">
                  <NavDropdown
                    className="devices-dropdown"
                    title={<ThreeDotIcon />}
                  >
                    <NavDropdown.Item
                      className="devices-dropdown-options"
                      onClick={() => OnClickCameraDetails()}
                    >
                      <HiOutlineVideoCamera size={20} />
                      <span className="devices-dropdown-options-label">
                        {constants.CAMERAS_VIDEO_CAMERA_DETAILS_LABEL}
                      </span>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className="devices-dropdown-options"
                      onClick={() => OnClickCameraSettings()}
                    >
                      <FiSettings size={20} />
                      <span className="devices-dropdown-options-label">
                        {constants.CAMERAS_VIDEO_SETTINGS_LABEL}
                      </span>
                    </NavDropdown.Item>
                  </NavDropdown>
                </div>
              </div>
              <div className="primary-circle"></div>
              <div className="streaming-container">
                <video
                  id={`video${layout}`}
                  width="764"
                  height="450"
                  ref={remoteVideoRef}
                  autoPlay={true}
                  playsInline={true}
                  muted={true}
                />
                <canvas
                  id={`canvas${layout}`}
                  width="764"
                  height="450"
                  className="d-none"
                />
              </div>
              <img
                className="hide-image"
                src={videoProcessing}
                alt="video processing"
              />
            </>
          ) : (
            <>
              {loading ? (
                <LoadingCamera />
              ) : (
                <NoVideoContent
                  deviceId={deviceId}
                  device={device}
                  activeTime={activeTime}
                  timeZone={timeZone}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default PlaybackGridItem;
