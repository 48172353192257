import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { Container, Dropdown, Row } from 'react-bootstrap';
import { HiOutlineChartPie, HiOutlineMinus } from 'react-icons/hi';
import { useDispatch } from 'react-redux';

import { useOrganizations } from '../../store/OrganizationsStore';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { useCustomerOrgDevices } from '../../store/CustomerOrgDevicesStore';
import { setAllDevicesData } from '../../store/reducers/AccountReducer';

import { AppDefaults, Utils, constants, roles } from '../../helpers';
import {
  Header,
  LocationSelector,
  PrimaryButton,
  SiteModal,
  SiteToast,
} from '../../components/common/';

import PageWrapper from '../PageWrapper';
import CameraAvailability from './CameraAvailability';
import CameraMinutesUnavailable from './CameraMinutesUnavailable';
import CameraAveStreamingBitrate from './CameraAveStreamingBitrate';
import RetailPerformance from './RetailPerformance';

import './AnalyticsDashboard.scss';
import OccupancyAnalysis from './OccupancyAnalysis';
import UserLogin from './UserLogin';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { useEffect } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { icontick } from '../../assets/images';
import { SlArrowDown, SlArrowUp } from 'react-icons/sl';

const AnalyticsDashboard = (props) => {
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [showCameraAvailabilityModal, setShowCameraAvailabilityModal] =
    useState(false);
  const [devices, setDevices] = useState([]);
  const [parentDevices, setParentDevices] = useState([]);
  const [updatedDevice, setUpdatedDevice] = useState([]);
  const [updatedParentDevice, setUpdatedParentDevice] = useState([]);
  const loggedInUserRole = useOrganizations((state) => state.loggedInUserRole);
  // const [deviceLocationAreas, setDeviceLocationAreas] = useState([]);
  const getCustomerOrgData = useOrganizations(
    (state) => state.getCustomerOrgData
  );
  const getSelectedCategories = useOrganizations(
    (state) => state.getSelectedCategories
  );
  const setSelectedCategories = useOrganizations(
    (state) => state.setSelectedCategories
  );
  const dropdownRef = useRef(null);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const currentSelectedLocationId = useRef('');

  const getOptions = () => {
    let options = [];
    if (
      loggedInUserRole === roles.ROLE1VMS ||
      loggedInUserRole === roles.ROLE2VMS ||
      loggedInUserRole === roles.ROLE3VMS
    ) {
      options.push(constants.ANALYTICS_DASHBOARD_CATEGORIES_USER_ANALYSIS);
    }
    return options;
  };

  const allCategoriesOptions = [
    // constants.ANALYTICS_DASHBOARD_CATEGORIES_RETAIL_PERFORMANCE,
    constants.ANALYTICS_DASHBOARD_CATEGORIES_CAMERA_PERFORMANCE,
    // constants.ANALYTICS_DASHBOARD_CATEGORIES_OCCUPANCY_ANALYSIS,
    ...getOptions(),
  ];
  const [selectedOptions, setSelectedOptions] = useState(allCategoriesOptions);
  const [tempSelectedOptions, setTempSelectedOptions] = useState([]);
  const [showAllOptions, setShowAllOptions] = useState(true);

  const {
    getCustomerOrgLocations,
    getCustomerOrgLocationsData,
    getSelectedLocation,
    setUserCustomer,
  } = useCustomerOrgLocations();

  const {
    fetchCustomerOrgDevices,
    getCustomerOrgDevices,
    setSelectedDeviceFromFillter,
  } = useCustomerOrgDevices();

  const dispatch = useDispatch();

  const orgDetailsData = getCustomerOrgData()[0];
  const orgDetails = orgId
    ? getCustomerOrgData().find((user) => user.orgId === orgId)
    : orgDetailsData;
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  // const accountId = loggedInUserData?.accountId;

  // let currentSelectedLocation = getSelectedLocation(accountId);

  useEffect(() => {
    setSelectedCategories(allCategoriesOptions);
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowAllCategories(false);
        setShowAllOptions(true);
        setSelectedOptions(getSelectedCategories());
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const fetchUser = async () => {
    try {
      await axios
        .get(
          `partner/orgs/${orgDetails?.orgId}/accounts`,
          Utils.requestHeader()
        )
        .then((response) => {
          let resultData = response?.data?.data;
          setUserCustomer(resultData);
          // setCustomerOrgUsers(resultData);
          // dispatch(orgUserData(resultData));
        });
    } catch (error) {
      // TODO : to print the error will update this later
      console.error(error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    setSelectedDeviceFromFillter([]);
    const deviceList = getCurrentLocationDevices(updatedDevice);
    const parentDeviceList = getCurrentLocationDevices(updatedParentDevice);
    if (deviceList?.length > 0) {
      setDevices(deviceList);
    } else {
      setDevices([]);
    }
    setParentDevices(parentDeviceList?.length > 0 ? parentDeviceList : []);
  }, [getSelectedLocation(loggedInUserData?.accountId), updatedDevice]);

  const getCurrentLocationDevices = (orgDevice) => {
    if (currentSelectedLocationId?.current) {
      const devicesList =
        currentSelectedLocationId?.current === AppDefaults.ALL_LOCATIONS_ID
          ? orgDevice?.filter((device) => device?.deviceStatus === 'CLAIMED')
          : orgDevice?.filter(
              (device) =>
                device?.locationId === currentSelectedLocationId?.current &&
                device?.deviceStatus === 'CLAIMED'
            );

      return devicesList;
    } else {
      return orgDevice?.filter((device) => device?.deviceStatus === 'CLAIMED');
    }
  };

  const fetchLocations = useCallback(async () => {
    // Get all locations for customer's organization
    await getCustomerOrgLocations(
      `partner/orgs/${orgDetails?.orgId}/locations`
    );
  }, []);

  const fetchDevices = useCallback(async () => {
    try {
      // Fetch the list of devices associated with
      // the user
      let fetchResult = await fetchCustomerOrgDevices(
        `device/orgs/${orgDetails?.orgId}/devices`
      );
      const orgLocations = getCustomerOrgLocationsData();
      // const deviceLocationNames = new Set();
      // const deviceAreaIds = new Set();
      const locationIds = new Set();
      // const locations = [];
      // const areas = [];
      // const locationAreaDeviceMappings = [];
      let deviceArea, currentArea, cameraDevice, parentDevice;

      if (fetchResult.status === 'success') {
        const devicesCamera = getCustomerOrgDevices();
        const cameraDevices = Utils.getTotalChildDevices(devicesCamera);
        const parentDevices = Utils.getTotalParentDevices(devicesCamera);

        const locationAreas = [];

        // Retrieve the location name for each device
        for (let i = 0; i < cameraDevices.length; i++) {
          cameraDevice = cameraDevices[i];
          locationIds.add(cameraDevice?.locationId);

          currentArea = orgLocations.find(
            (orgLocation) => orgLocation.locationId === cameraDevice?.locationId
          );

          if (
            locationAreas.findIndex(
              (locationArea) =>
                locationArea.locationId === cameraDevice?.locationId
            ) === -1
          ) {
            deviceArea = {
              deviceId: cameraDevice?.deviceId,
              areas: [...currentArea?.areas],
            };

            locationAreas.push({
              locationId: cameraDevice?.locationId,
              areas: [deviceArea],
            });
          }

          cameraDevices[i].locationName = currentArea.locationName;

          cameraDevices[i].areas = [
            ...locationAreas.find(
              (locationArea) =>
                locationArea.locationId === cameraDevices[i].locationId
            ).areas[0].areas,
          ];

          cameraDevices[i].style = null;
        }
        // Retrieve the location name for each device
        for (let i = 0; i < parentDevices.length; i++) {
          parentDevice = parentDevices[i];
          locationIds.add(parentDevice?.locationId);

          currentArea = orgLocations.find(
            (orgLocation) => orgLocation.locationId === parentDevice?.locationId
          );

          if (
            locationAreas.findIndex(
              (locationArea) =>
                locationArea.locationId === parentDevice?.locationId
            ) === -1
          ) {
            deviceArea = {
              deviceId: parentDevice?.deviceId,
              areas: [...currentArea?.areas],
            };

            locationAreas.push({
              locationId: parentDevice?.locationId,
              areas: [deviceArea],
            });
          }

          parentDevices[i].locationName = currentArea.locationName;

          parentDevices[i].areas = [
            ...locationAreas.find(
              (locationArea) =>
                locationArea.locationId === parentDevices[i].locationId
            ).areas[0].areas,
          ];

          parentDevices[i].style = null;
        }

        await new Promise((resolve) => {
          setUpdatedDevice(cameraDevices?.length > 0 ? [...cameraDevices] : []);
          setUpdatedParentDevice(
            parentDevices?.length > 0 ? [...parentDevices] : []
          );
          dispatch(
            setAllDevicesData(
              cameraDevices?.length > 0 ? [...cameraDevices] : []
            )
          );
          resolve();
        });

        let filteredDevices = [];
        let filteredParentDevices = [];
        if (currentSelectedLocationId?.current) {
          if (
            currentSelectedLocationId?.current !== AppDefaults.ALL_LOCATIONS_ID
          ) {
            filteredDevices = cameraDevices.filter(
              (device) =>
                device?.locationId === currentSelectedLocationId?.current &&
                device?.deviceStatus === 'CLAIMED'
            );
            filteredParentDevices = parentDevices.filter(
              (device) =>
                device?.locationId === currentSelectedLocationId?.current &&
                device?.deviceStatus === 'CLAIMED'
            );
          } else {
            const list = cameraDevices?.filter(
              (device) => device?.deviceStatus === 'CLAIMED'
            );
            filteredDevices = cameraDevices?.length > 0 ? [...list] : [];
            const parentDeviceList = parentDevices?.filter(
              (device) => device?.deviceStatus === 'CLAIMED'
            );
            filteredParentDevices =
              parentDevices?.length > 0 ? [...parentDeviceList] : [];
          }
        }

        await new Promise((resolve) => {
          setDevices(
            filteredDevices && filteredDevices?.length > 0
              ? [...filteredDevices]
              : []
          );
          setParentDevices(
            filteredParentDevices && filteredParentDevices?.length > 0
              ? [...filteredParentDevices]
              : []
          );
          // setDeviceLocationAreas([...locationAreas]);
        });
      } else {
        setShowToast(true);
        setUserMsg(fetchResult.msg);
      }
    } catch (err) {
      setShowToast(true);
      setUserMsg(err.msg);
    }
  }, []);

  useLayoutEffect(() => {
    fetchLocations();
  }, []);

  useLayoutEffect(() => {
    fetchDevices();
  }, [currentSelectedLocationId?.current]);

  const handleOptionToggle = (option) => {
    if (option === constants.ANALYTICS_DASHBOARD_CATEGORIES_ALL_ANALYTICS) {
      setTempSelectedOptions(
        tempSelectedOptions.length === allCategoriesOptions.length
          ? []
          : [...allCategoriesOptions]
      );
    } else {
      setTempSelectedOptions((prevSelected) =>
        prevSelected.includes(option)
          ? prevSelected.filter((item) => item !== option)
          : [...prevSelected, option]
      );
    }
  };

  const handleApply = () => {
    setSelectedOptions([...tempSelectedOptions]); // Apply the temporary selected options
    setSelectedCategories([...tempSelectedOptions]);
    setShowAllCategories(false);
    setShowAllOptions(true);
  };

  return (
    <div className="App analytics">
      <Header showCart={false} />
      <div className="main-wrapper">
        <div className="analytics-header">
          <PageWrapper className="mw-100">
          <Container className='mw-100'>
            <Row className="my-3">
              <div className="toast-wrapper">
                <SiteToast
                  customCss="analytics-toast"
                  position="top-end"
                  show={showToast}
                  title="Uh-oh!"
                  body={userMsg}
                  delay={5000}
                />
              </div>
              <div className="header-wrapper">
                <span className="page-title">
                  {constants.ANALYTICS_DASHBOARD_TITLE}
                </span>
                <div className="button-nav analytics-button-nav">
                  <LocationSelector
                    variant="default"
                    minWidth={'178px'}
                    persist={false}
                    callback={(locationId) => {
                      if (!locationId) return;
                      currentSelectedLocationId.current = locationId;
                    }}
                  />
                  <div ref={dropdownRef}>
                    <PrimaryButton
                      variant="outline-secondary"
                      className="button-nav-item filter-button"
                      onClick={() => {
                        setShowAllCategories(!showAllCategories);
                        setShowAllOptions(true);
                        setTempSelectedOptions(selectedOptions);
                      }}
                      backgroundColor="transparent"
                      borderWidth="1.5"
                      hoverBorderWidth="1.5"
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--greyscale_40')}
                      hoverColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--greyscale_40')}
                      hoverBackgroundColor="transparent"
                      borderColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--greyscale_88')}
                      hoverBorderColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--primary_48')}
                    >
                      <HiOutlineChartPie size={16} className="filter-icon" />
                      {constants.ANALYTICS_DASHBOARD_CATEGORY_DEFAULT_LABEL}
                      <SlArrowDown
                        className="filter-down-arrow"
                        size={12}
                        color={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--brand_black')}
                      />
                    </PrimaryButton>
                    {showAllCategories && (
                      <Dropdown.Menu
                        className="dropdown-cat-options right-aligned-dropdown-menu"
                        show={showAllCategories}
                        onHide={() => {
                          setShowAllCategories(false);
                          setShowAllOptions(true);
                        }}
                      >
                        <div
                          key={
                            constants.ANALYTICS_DASHBOARD_CATEGORIES_ALL_ANALYTICS
                          }
                          className="option-container"
                        >
                          <div className="left-section">
                            {showAllOptions ? (
                              <SlArrowUp
                                color={getComputedStyle(
                                  document.documentElement
                                ).getPropertyValue('--brand_black')}
                                onClick={() =>
                                  setShowAllOptions(!showAllOptions)
                                }
                              />
                            ) : (
                              <SlArrowDown
                                color={getComputedStyle(
                                  document.documentElement
                                ).getPropertyValue('--brand_black')}
                                onClick={() =>
                                  setShowAllOptions(!showAllOptions)
                                }
                              />
                            )}
                            <div
                              className="option-title"
                              onClick={() =>
                                handleOptionToggle(
                                  constants.ANALYTICS_DASHBOARD_CATEGORIES_ALL_ANALYTICS
                                )
                              }
                            >
                              {
                                constants.ANALYTICS_DASHBOARD_CATEGORIES_ALL_ANALYTICS
                              }
                            </div>
                          </div>
                          <div
                            className={`right-section ${
                              tempSelectedOptions.length > 0
                                ? 'selected-tick'
                                : ''
                            }`}
                            onClick={() =>
                              handleOptionToggle(
                                constants.ANALYTICS_DASHBOARD_CATEGORIES_ALL_ANALYTICS
                              )
                            }
                          >
                            {tempSelectedOptions.length ===
                            allCategoriesOptions.length ? (
                              <img
                                className="icon-image"
                                alt="tick-icon"
                                src={icontick}
                              />
                            ) : (
                              <HiOutlineMinus
                                size={14}
                                color={getComputedStyle(
                                  document.documentElement
                                ).getPropertyValue('--brand_white')}
                              />
                            )}
                          </div>
                        </div>
                        {showAllOptions &&
                          allCategoriesOptions.map((option) => (
                            <div
                              key={option}
                              className="option-container"
                              onClick={() => handleOptionToggle(option)}
                            >
                              <div className="left-section">{option}</div>
                              <div
                                className={`right-section ${
                                  tempSelectedOptions.includes(option)
                                    ? 'selected-tick'
                                    : ''
                                }`}
                              >
                                <img
                                  className="icon-image"
                                  alt="tick-icon"
                                  src={icontick}
                                />
                              </div>
                            </div>
                          ))}
                        <PrimaryButton
                          className="apply-button mt-2"
                          height="32px"
                          borderRadius="10px"
                          disabled={tempSelectedOptions.length === 0}
                          onClick={handleApply}
                        >
                          {constants.APPLY_BUTTON_LABEL}
                        </PrimaryButton>
                      </Dropdown.Menu>
                    )}
                  </div>
                </div>
              </div>
            </Row>
            </Container>
          </PageWrapper>
        </div>
        <div className="analytics-background">
          <PageWrapper className="mw-100 d-flex justify-content-center">
            <Row className="analytics-wrapper">
              <div className="analytics-container">
                {/* Retail Performance */}
                {selectedOptions.includes(
                  constants.ANALYTICS_DASHBOARD_CATEGORIES_RETAIL_PERFORMANCE
                ) &&
                  (loggedInUserRole === roles.ROLE1VMS ||
                    loggedInUserRole === roles.ROLE2VMS ||
                    loggedInUserRole === roles.ROLE3VMS) && (
                    <Row className="d-flex justify-content-around analytics-row">
                      <RetailPerformance
                        custOrgData={orgDetails}
                        devices={devices}
                      />
                    </Row>
                  )}
                {selectedOptions.includes(
                  constants.ANALYTICS_DASHBOARD_CATEGORIES_CAMERA_PERFORMANCE
                ) && (
                  <>
                    {/* Camera Availability */}
                    <Row className="d-flex justify-content-around analytics-row">
                      <CameraAvailability
                        custOrgData={orgDetails}
                        devices={parentDevices}
                      />
                    </Row>
                    {/* Camera Minutes Unavailable */}
                    <Row className="d-flex justify-content-around analytics-row">
                      <CameraMinutesUnavailable
                        custOrgData={orgDetails}
                        devices={parentDevices}
                      />
                    </Row>
                    {/* TODO: Camera Average Streaming Bitrate */}
                    <Row className="d-flex justify-content-around analytics-row">
                      <CameraAveStreamingBitrate
                        custOrgData={orgDetails}
                        devices={devices}
                      />
                    </Row>
                  </>
                )}
                {/* Occupancy Analysis */}
                {selectedOptions.includes(
                  constants.ANALYTICS_DASHBOARD_CATEGORIES_OCCUPANCY_ANALYSIS
                ) &&
                  (loggedInUserRole === roles.ROLE1VMS ||
                    loggedInUserRole === roles.ROLE2VMS ||
                    loggedInUserRole === roles.ROLE3VMS) && (
                    <Row className="d-flex justify-content-around analytics-row">
                      <OccupancyAnalysis
                        custOrgData={orgDetails}
                        devices={devices}
                      />
                    </Row>
                  )}

                {/* User Logins */}
                {selectedOptions.includes(
                  constants.ANALYTICS_DASHBOARD_CATEGORIES_USER_ANALYSIS
                ) &&
                  (loggedInUserRole === roles.ROLE1VMS ||
                    loggedInUserRole === roles.ROLE2VMS ||
                    loggedInUserRole === roles.ROLE3VMS) && (
                    <Row className="d-flex justify-content-around analytics-row user-login">
                      <UserLogin custOrgData={orgDetails} devices={devices} />
                    </Row>
                  )}
              </div>
            </Row>
          </PageWrapper>
        </div>
        {/* Modals */}
        {/* Camera Availability Modal */}
        <SiteModal
          key={'camera-availability-modal'}
          classes="camera-availability-modal"
          wrapperClass="camera-availability-modal"
          modalTitle={
            constants.ANALYTICS_DASHBOARD_CAMERA_AVAILABILITY_MODAL_TITLE
          }
          showModal={showCameraAvailabilityModal}
          hideModal={() => setShowCameraAvailabilityModal(false)}
        >
          Testing 1 2 3
        </SiteModal>
      </div>
    </div>
  );
};

export default AnalyticsDashboard;
