import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import RemoteVideoView from '../common/RemoteVideoView';
import '../../assets/css/timeline.scss';
import videoProcessing from '../../assets/images/cameras/videoProcessing.svg';
import { pause } from '../../assets/images';
import { useDispatch, useSelector } from 'react-redux';
import {
  subscribeWithMQTT,
  publishWithMQTT,
  disconnectWithMQTT,
} from '../../utils/connection/mqttConnection';
import {
  getCDNInfo,
  getIsLiveViewEntitlementExist,
  getIsLiveViewEntitlementExpired,
  getLoadingStream,
  getMQTTConnection,
  getPlatformInfo,
  getRemoteStream,
  getStreamBitmapData,
  setLoadingStream,
  setRemoteStream,
  setOfferrecived,
  getOfferRecivedStatus
} from '../../store/reducers/StreamingReducer';
import constants from '../../helpers/en';
import {
  getAccountId,
  getDeviceInformation,
} from '../../store/reducers/AccountReducer';
import { usePoliciesStore } from '../../store/policiesStore';
import { useIdleTimer } from 'react-idle-timer';
import { TbCertificate2Off } from 'react-icons/tb';
import { CiStreamOn } from 'react-icons/ci';
import { TbLoader } from 'react-icons/tb';
import { FiCameraOff } from 'react-icons/fi';
import useLicensesStore from '../../store/LicensesStore';

const Live = ({ handleClick, customer, page, isStopStreamClick = false }) => {
  const intervalRef = useRef();
  const platformDetails = useSelector(getPlatformInfo);
  const deviceDetails = useSelector(getDeviceInformation);
  const remoteStream = useSelector(getRemoteStream);
  const bitmapObject = useSelector(getStreamBitmapData);
  const offerStatus = useSelector(getOfferRecivedStatus);
  const isLiveViewEntitlementExist = useSelector(getIsLiveViewEntitlementExist);
  const isLiveViewEntitlementExpired = useSelector(
    getIsLiveViewEntitlementExpired
  );
  const loadingStream = useSelector(getLoadingStream);
  const mqttConnection = useSelector(getMQTTConnection);
  const accountId = useSelector(getAccountId);
  const dispatch = useDispatch();
  const { licenses, getLicenses } = useLicensesStore();

  const [activeLicenses, setActiveLicenses] = useState([...getLicenses(true)]);
  const [deviceStatus, setDeviceStatus] = useState({});
  const [streamStatus, setStreamStatus] = useState(false);
  const [isDeviceRestart, setIsDeviceRestart] = useState(false);
  const [state, setState] = useState('Active');
  const [remaining, setRemaining] = useState(0);
  const onIdle = () => setState('Idle');
  const onActive = () => setState('Active');
  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    timeout: 3000,
    throttle: 500,
  });

  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies
  );
  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    // TODO: Delete Later
    const currentActiveLicenses = getLicenses(true);
    setActiveLicenses([...currentActiveLicenses]);
    const activeLicensesData = [...currentActiveLicenses];

    if (Array.isArray(activeLicensesData) && activeLicensesData.length > 0) {
      let deviceStatus = {};
      let licenses = activeLicensesData.find(
        (licenses) => licenses.deviceId === deviceDetails?.deviceId
      );

      if (!isLiveViewEntitlementExist || isLiveViewEntitlementExpired) {
        deviceStatus = {
          isStream: false,
          icon: (
            <TbCertificate2Off
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--greyscale_56')}
              size={60}
            />
          ),
          label: constants.DEVICES_TAB_LICENSES_NO_LICENSE_ATTACHED,
        };
        setDeviceStatus(deviceStatus);
      }
    } else {
      // What is the device status if there are no active licenses available?
    }
  }, [JSON.stringify(licenses)]);

  useEffect(() => {
    if (!streamStatus) {
      let deviceStatus = {};
      if (
        deviceDetails?.connectionStatus ===
          constants.DEVICES_ONLINE_CONNECTION_STATUS &&
        isLiveViewEntitlementExist &&
        !isLiveViewEntitlementExpired
      ) {
        setStreamStatus(true);
        publishWithMQTT(
          platformDetails?.mqtt,
          platformDetails?.p2p_server,
          deviceDetails,
          accountId
        );
        deviceStatus = {
          isStream: true,
        };
        setDeviceStatus(deviceStatus);
      }
    }
  }, [streamStatus, isLiveViewEntitlementExist, isLiveViewEntitlementExpired]);

  useEffect(() => {
    dispatch(setRemoteStream(null));
    if (mqttConnection) {
      streamState();
    }
  }, [mqttConnection]);

  useEffect(() => {
    //TODO DELETE LATER
    console.log('restart>>>>>>>>>>>>>>>>>>>>>>>>>',deviceDetails?.properties?.restart);
    console.log('live-stream>>>>>>>>>>>>>>>>>>>>>>>>>',deviceDetails?.properties?.['live-stream']);

    if (deviceDetails?.properties?.restart === 'in-progress') {
      setIsDeviceRestart(true);
      dispatch(setOfferrecived(false));
    } else {
      setIsDeviceRestart(false);
    }
    streamState();
  }, [deviceDetails]);

  const streamState = () => {
    let deviceStatus = {};
    if (deviceDetails?.properties?.restart === 'in-progress') {
      deviceStatus = {
        isStream: false,
        icon: (
          <CiStreamOn
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--greyscale_56'
            )}
            size={60}
          />
        ),
        label: constants.DEVICE_STATE_RESTARTING_MESSAGE,
      };
      setDeviceStatus(deviceStatus);
    } else if (
      deviceDetails?.properties?.['fw-update'] ===
      ('in-progress' || 'completed')
    ) {
      deviceStatus = {
        isStream: false,
        icon: (
          <TbLoader
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--greyscale_56'
            )}
            size={60}
          />
        ),
        label: constants.DEVICE_STATE_UPDATE_FIRMWARE,
      };
      setDeviceStatus(deviceStatus);
    } else if (
      deviceDetails?.connectionStatus ===
      constants.DEVICES_OFFLINE_CONNECTION_STATUS
    ) {
      if (
        deviceDetails?.properties?.restart !== 'in-progress' &&
        deviceDetails?.properties?.['fw-update'] !==
          ('in-progress' || 'completed')
      ) {
        deviceStatus = {
          isStream: false,
          icon: (
            <FiCameraOff
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--greyscale_56')}
              size={60}
            />
          ),
          label: constants.DEVICE_STATE_CAMERA_OFFLINE,
        };
        setDeviceStatus(deviceStatus);
      }
    } else if (deviceDetails?.properties?.['live-stream'] === 'stop') {
      deviceStatus = {
        isStream: false,
      };
      setDeviceStatus(deviceStatus);
    } else if (getStreamStatus(deviceDetails)) {
      setIsDeviceRestart(false);
      if (!offerStatus) {
        const id = setInterval(() => {
          publishWithMQTT(
            platformDetails?.mqtt,
            platformDetails?.p2p_server,
            deviceDetails,
            accountId
          );
          deviceStatus = {
            isStream: true,
          };
          setDeviceStatus(deviceStatus);
        }, 10000);
        intervalRef.current = id;
      } 
    } else {
      deviceStatus = {
        isStream: true,
      };
      setDeviceStatus(deviceStatus);
    }
  };

  const getStreamStatus = (deviceDetails) => {
    if (isDeviceRestart) {
      if (
        deviceDetails?.connectionStatus ===
        constants.DEVICES_ONLINE_CONNECTION_STATUS
      ) {
        if (
          deviceDetails?.properties?.['live-stream'] === 'idle' ||
          deviceDetails?.properties?.['live-stream'] === 'in-progress'
        ) {
          return true;
        }
      }
    } else {
      if (offerStatus) {
        clearInterval(intervalRef?.current);
      }
      return false;
    }
  };

  const pauseClick = () => {
    if (isStopStreamClick) return;
    disconnectWithMQTT();
    handleClick(constants.DEVICES_SWITCH_TIMELINE_TITLE);
  };

  return (
    <div
      className="wrapper-app"
      onClick={() => {
        getCustomerOrgPolicies()?.view_camera_history && pauseClick();
      }}
    >
      {deviceStatus.isStream ? (
        <div className="stream-timeline-wrapper device-setting">
          {page !== 'settings' && (
            <div
              className={`timeline-icons live-stream ${
                remaining === 0 && state === 'Idle' ? 'hide-controls' : ''
              }`}
            >
              <button className="live-button" onClick={() => pauseClick()}>
                <img src={pause} alt="pause icon" />
              </button>
            </div>
          )}
          <div
            className={`timeline-icons live ${
              remaining === 0 && state === 'Idle' ? 'hide-controls' : ''
            }`}
          >
            <button className="live-button">
              {constants.LIVE_STREAM_OVERLAY_LABEL}
            </button>
          </div>
          {remoteStream && (
            <RemoteVideoView
              deviceDetails={deviceDetails}
              remoteStream={remoteStream}
              bitmapObject={bitmapObject}
            />
          )}
          {loadingStream && (
            <div className="video-processing">
              <img src={videoProcessing} alt="video" />
            </div>
          )}
        </div>
      ) : (
        <div className="device-status-container">
          {deviceStatus.icon}
          <div className="title-status">{deviceStatus.label}</div>
        </div>
      )}
    </div>
  );
};

export default Live;
