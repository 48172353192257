import { memo, useLayoutEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './Tabs.scss';
import { useEffect } from 'react';

/**
 * @param {Array<Object>} tabCollection
 *
 * Object must have the following:
 * - icon (from react-icons)
 * - label (the tab label as a string)
 * - clickHandler (callback function receiving index of tab)
 */
const Tabs = memo(
  ({
    tabCollection = [],
    defaultActive = 0,
    firstTabDefaultActive = true,
    fullWidth = true,
    customTabWidth,
    customTabHeight,
    customFontSize,
    customWidth,
  }) => {
    const [tabs, setTabs] = useState(
      Array.isArray(tabCollection) ? [...tabCollection] : []
    );
    const [activeTabIndex, setActiveTabIndex] = useState(
      firstTabDefaultActive ? 0 : defaultActive ? defaultActive : null
    );

    useEffect(() => {
      setActiveTabIndex(defaultActive);
    }, [defaultActive]);

    useLayoutEffect(() => {
      const rehydrate = () => {
        // This ensures the tabs are redrawn on a resize event
        setTabs((prevTabs) => {
          return [...prevTabs];
        });
      };
      window.addEventListener('resize', rehydrate);
      return () => window.removeEventListener('resize', rehydrate);
    }, []);

    const onClickHandler = (e, cb) => {
      let index = e?.currentTarget?.dataset?.tabIndex;

      if (index === null || index === undefined) {
        return;
      }

      setActiveTabIndex(parseInt(index));
      cb(parseInt(index));
    };

    const isChildOverflown = (id) => {
      const childEl = document.querySelector(`#tab${id}`);
      return childEl?.offsetWidth < childEl?.scrollWidth;
    };

    return (
      <div
        className={`section-tabs device-tabs  space-around space-md-between
          ${fullWidth ? ' full-width' : ''}`}
        style={customWidth ? { width: customWidth } : {}}
      >
        {Array.isArray(tabs)
          ? tabs.map((tab, tabIndex) => (
              <div
                id={`tab${tabIndex}`}
                key={`col section-tab-${tabIndex}`}
                className={`section-tab${
                  activeTabIndex === tabIndex ? ' active' : ''
                } px-sm-3 py-sm-2 px-md-2 py-md-2`}
                data-tab-index={tabIndex}
                onClick={(e) => {
                  e.preventDefault();
                  onClickHandler(e, tab?.clickHandler);
                }}
                style={{
                  width: customTabWidth || 'auto',
                  height: customTabHeight || 'auto',
                }}
              >
                {tab?.icon}
                {isChildOverflown(tabIndex) ? (
                  <OverlayTrigger
                    key={`triggerHeader${tab?.label}`}
                    placement="top"
                    overlay={
                      <Tooltip key={`tooltipHeader${tab?.label}`}>
                        {tab?.label}
                      </Tooltip>
                    }
                  >
                    <div
                      className="d-none d-md-inline section-tab-label"
                      style={customFontSize ? { fontSize: customFontSize } : {}}
                    >
                      {tab?.label}
                    </div>
                  </OverlayTrigger>
                ) : (
                  <div
                    className="d-none d-md-inline section-tab-label"
                    style={customFontSize ? { fontSize: customFontSize } : {}}
                  >
                    {tab?.label}
                  </div>
                )}
              </div>
            ))
          : null}
      </div>
    );
  }
);

export default Tabs;
