import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Container, Col, Row } from 'react-bootstrap';
import axios from 'axios';
import { isObject } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getPlatformInfo, getWSSConnection, getCDNInfo } from '../../store/reducers/StreamingReducer';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { useOrganizations } from '../../store/OrganizationsStore';
import { Header, SiteModal, SiteSpinner } from '../../components/common';
import { AppDefaults, Utils, constants } from '../../helpers';
import { BsFillMicFill } from 'react-icons/bs';
import { AiOutlineSearch } from 'react-icons/ai';
import ImageFetch from '../cameras/components/ImageFetch';
import TopEvents from './TopEvents';
import SearchModal from './SearchModal';
import './searchdashboard.scss';
import { disconnectWithWebSocket, sendPauseCVR, sendRegisterCVR } from '../../utils/connection/wssConnection';
import EventTimelineControls from './eventPreview/EventTimelineControls';
import useEventsStore from '../../store/EventsStore';

const SearchDashboard = () => {
  const navigate = useNavigate();
  const [suggestedSearches, setsuggestedSearches] = useState([]);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showVideoModel, setShowVideoModel] = useState(false);
  const [isMic, setIsMic] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [recentDataList, setRecentDataList] = useState([]);
  const [modalContent, setModalContent] = useState();
  const getPlatformDetails = useSelector(getPlatformInfo);
  const wssConnection = useSelector(getWSSConnection);
  const getCustomerOrgData = useOrganizations(
    (state) => state.getCustomerOrgData
  );
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const { getSelectedLocation } = useCustomerOrgLocations();
  const selectedLocation = getSelectedLocation(loggedInUserData?.accountId);
  let custOrg = getCustomerOrgData();
  const custOrgData = custOrg[0];
  const cdnInfo = useSelector(getCDNInfo);
  const {
    setSelectedEventStore,
    setEventFromSearch,
    setEventCVRMode,
  } = useEventsStore();

  const getDeviceId = () => {
    let deviceID = '';
    const orgDevices = custOrgData?.devices;
    const orgDevice = Utils.getTotalChildDevices(orgDevices)
    const location = selectedLocation;
    if (isObject(location)) {
      const devicesList =
        location?.locationId === AppDefaults.ALL_LOCATIONS_ID
          ? orgDevice
          : orgDevice?.filter(
              (device) => device.locationId === location?.locationId
            );
      devicesList && devicesList.length > 0
        ? devicesList?.forEach((device, i) => {
            deviceID += '&deviceIds=' + device.deviceId;
          })
        : (deviceID = '&deviceIds=');
      return deviceID;
    } else {
      orgDevice &&
        orgDevice.length > 0 &&
        orgDevice?.forEach((device, i) => {
          deviceID += '&deviceIds=' + device.deviceId;
        });
      return deviceID;
    }
  };

  useEffect(() => {
    if (custOrgData) {
      setRecentDataList([]);
      setShowLoader(true);
      apiCallEventsSuggestions();
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (isMic) {
      setShowSearchModal(true);
    }
  }, [isMic]);

  useEffect(() => {
    if (getPlatformDetails && wssConnection && modalContent) {
      sendRegisterCVR(modalContent?.modalContent?.device[0]?.deviceId, loggedInUserData?.accountId, custOrgData?.orgId);
    }
  }, [getPlatformDetails, wssConnection, modalContent]);

  const apiCallEventsSuggestions = async () => {
    fetchRecentEvents(cdnInfo);
  };

  const fetchRecentEvents = async (cdnInformation) => {
    if (getDeviceId() != '') {
      let responseRecentEvent = await axios.get(
        `/timeline/orgs/${
          custOrgData?.orgId
        }/events/recent?endTime=${Math.floor(
          new Date().getTime()
        )}&size=15${getDeviceId()}`,
        Utils.requestHeader()
      );
      const responseRecent = responseRecentEvent?.data;
      if (responseRecent?.meta?.code === 200) {
        const requireDataForVideo = [];
        responseRecent?.data?.events?.forEach((device, i) => {
          let eventClass; 
          device?.eventMeta?.events?.forEach((insideEvent) => {
            insideEvent?.event?.forEach((ev) => {
              if(ev?.eventClass){
                eventClass = ev.eventClass;
              }
            })
          })
          const obj = {
            eventTimestamp: device.eventTimestamp,
            deviceId: device.deviceId,
            modalContent: {
              class: eventClass,
              timestamp: device.eventTimestamp,
              device: [
                {
                  deviceName: device.deviceName,
                  locationName: device.locationName,
                  areaName: device.areaName,
                  deviceId: device.deviceId,
                },
              ],
            },
            indexContent: i,
            streaming: {
              cdnInfo: cdnInformation,
            },
          };
          requireDataForVideo.push(obj);
        });
        setRecentDataList(requireDataForVideo);
        setShowLoader(false);
      } else {
        setShowLoader(false);
      }
      let responseSuggestionEvents = await axios.get(
        `/timeline/orgs/${
          custOrgData?.orgId
        }/search/suggestion?startTime=${Utils.getHourPast(
          1
        )}&endTime=${new Date().getTime()}&size=7${getDeviceId()}`,
        Utils.requestHeader()
      );
      const responseSuggestion = responseSuggestionEvents?.data;
      if (responseSuggestion?.meta?.code === 200) {
        setShowLoader(false);
        setsuggestedSearches(responseSuggestion?.data?.suggestedSearches);
      } else {
        setShowLoader(false);
      }
    }
  }
  const goToSearchModal = (isVoice, e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsMic(isVoice);
    if (!isVoice) {
      setShowSearchModal(true);
    }
  };

  const onSnapshotClick = (data) => {
    if (data) {
      setModalContent(data);
      setShowVideoModel(true);
    }
  }

  const onCameraDetailsClick = () => {
    setSelectedEventStore(modalContent?.eventTimestamp);
    setEventFromSearch(true);
    setEventCVRMode(true);
    navigate(`/cameras/dashboard.html`, {
      state: {
        id: modalContent?.modalContent?.device[0]?.deviceId,
        cdnInfo: modalContent?.streaming?.cdnInfo,
      },
    });
  };

  return (
    <div className="App">
      <Header />
      <SearchModal
        modalTitle=""
        showModal={showSearchModal}
        hideModal={() => {
          setShowSearchModal(false);
          setIsMic(false);
        }}
        isMic={isMic}
        custOrgData={custOrgData}
      />
      <Container>
        <Row className="justify-content-md-center mt-5">
          <Col lg="12">
            <div
              onClick={(e) => goToSearchModal(false, e)}
              className="search-dashboard-container"
            >
              <div className="serach-box">
                <AiOutlineSearch
                  size={25}
                  fill={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--greyscale_40')}
                />
                <div className="textbox">
                  {constants.SEARCH_DASHBOARD_SEARCH_TEXT}
                </div>
              </div>
              <div
                onClick={(e) => goToSearchModal(true, e)}
                className="mic-btn"
              >
                <BsFillMicFill
                  size={25}
                  fill={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--brand_white')}
                />
              </div>
            </div>
            <div className="events-conatiner">
              <div className="recent-events">
                <div className="recent-events-header">
                  <div className="recent-events-label">
                    {constants.SEARCH_DASHBOARD_RECENT_EVENTS}
                  </div>
                  {recentDataList && recentDataList.length > 0 && (
                    <div
                      onClick={() =>
                        navigate(`/searchdashboard/searchevents.html`)
                      }
                      className="show-all-label"
                    >
                      {constants.SEARCH_DASHBOARD_SHOW_ALL}
                    </div>
                  )}
                </div>
                <div className="recent-event-conatiner">
                  {recentDataList && recentDataList.length > 0 ? (
                    <div className="container">
                      <div className=" row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5">
                        {recentDataList.map((data, index) => (
                          <div className="inner-container" key={index} onClick={() => onSnapshotClick(data)}>
                            {data?.streaming?.cdnInfo && (
                              <ImageFetch
                                time={data?.eventTimestamp}
                                cdnValue={data?.streaming?.cdnInfo}
                                deviceId={data?.deviceId}
                                isFromSearchDashboard={true}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="no-event-conatiner">
                      {showLoader ? (
                        <SiteSpinner height="50px" width="50px" />
                      ) : (
                        <div className="no-data-label">
                          {constants.SEARCH_DASHBOARD_NO_RECENT_EVENTS}
                          <span
                            className="link"
                            onClick={() => {
                              navigate(`/searchdashboard/searchevents.html`);
                            }}
                          >
                            {`${constants.SEARCH_DASHBOARD_CLICK_HERE}`}
                          </span>
                          {constants.SEARCH_DASHBOARD_TO_SEE}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {suggestedSearches && suggestedSearches.length > 0 && (
                  <div className="suggested-event-container">
                    <div className="suggested-level">
                      {constants.SEARCH_DASHBOARD_SUGGESTED_LABEL}
                    </div>
                    <div className="container">
                      <div className="row">
                        {suggestedSearches.slice(0, 7).map((data, index) => (
                          <div className="s-container" key={index}>
                            {data.text}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <TopEvents custOrgData={custOrgData} />
            </div>
          </Col>
        </Row>
      </Container>

      <SiteModal
        modalTitle=""
        showModal={showVideoModel}
        hideModal={() => {
          sendPauseCVR(modalContent?.modalContent?.device?.[0]?.deviceId);
          disconnectWithWebSocket();
          setShowVideoModel(false);
        }}
        size="lg"
        classes="event-preview-modal"
      >
        {modalContent && modalContent?.modalContent.device?.length && (
          <EventTimelineControls
            eventTimestamp={modalContent?.eventTimestamp}
            timezone={modalContent?.modalContent?.device[0]?.timezone}
            deviceId={modalContent?.modalContent?.device[0]?.deviceId}
            // onNextPreviousClick={onNextPreviousClick}
            modalContent={modalContent?.modalContent}
            indexContent={modalContent?.indexContent}
            streaming={modalContent?.streaming}
            onCameraDetailsClick={onCameraDetailsClick}
            isFromSearchDashboard={true}
            platformDetail={getPlatformDetails}
          />
        )}
      </SiteModal>
    </div>
  );
};

export default SearchDashboard;
