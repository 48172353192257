import { useEffect, useState } from 'react';
import './stepspos.scss';
import { Utils, constants } from '../../../helpers';
import { PrimaryButton } from '../../../components/common';
import { useSearchParams } from 'react-router-dom';
import { warningroundwhite } from '../../../assets/images';
import { RxCross1 } from 'react-icons/rx';
import axios from 'axios';

const DeletePosFileModal = (props) => {
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const [itemSelected,setItemSelected] = useState({});
  const [showLoader, setShowLoader] = useState(false);

  useEffect(()=>{
    setItemSelected(props?.itemSelected)
  },[props?.itemSelected])

  const deleteRecord = async()=>{
    setShowLoader(true)
    let response = await axios.delete(
      `/partner/orgs/${orgId}/pos/${itemSelected?.id}`,
      Utils.requestHeader()
    );
    let resData = response?.data;
    if (resData?.meta.code === 200) { 
      setShowLoader(false)
      props?.deleteRecords()
    }
    else{
      setShowLoader(false) 
    }
  }

  

  return (
    <div className="pos-integration">
      <div className="steps-header-conatiner">
        <div className="header-container">
          <div className="left-section-layout">
            <div className="header-title">{constants.DELETE_POS_TITLE}</div>
          </div>
          <RxCross1 size={24} onClick={() => props.hideModal()} />
        </div>
      </div>
      <div className={`manage-pos-height`}>
        <div className="org-map-setup-content">
          <img className="mt-4" src={warningroundwhite} alt="" />
          <div className="title-setup-completed">
            {constants.DELETE_POS_MESSAGE.replace(
              '${file}',
              ` "${itemSelected?.fileName}"`
            )}
          </div>
          <PrimaryButton
            className="adddevice-btn btn-margin-try"
            fontSize="14px"
            backgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            height="44px"
            color="#FFFFFF"
            type="submit"
            onClick={() => {
              props.hideModal();
            }}
          >
            {constants.CANCEL_BUTTON_TITLE}
          </PrimaryButton>
          {
            <PrimaryButton
              className="btn btn-outline-secondary w-100 mt-2"
              type="submit"
              loader={showLoader}
              loaderClassName="delete-view-loader"
              height="44px"
              backgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              borderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
             
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_96')}
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              onClick={() => {
                deleteRecord();
              }}
            >
              {constants.DEVICES_TAB_NETWORK_MAXIMUM_DELETE}
            </PrimaryButton>
          }
        </div>
      </div>
    </div>
  );
};
export default DeletePosFileModal;
