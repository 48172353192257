import React, { useRef, useState, useEffect } from 'react';
import { LineDirectionEnum } from '../../../../helpers/enums';

const Line = (props) => {
  const canvasRef = useRef(null);
  const [startX, setStartX] = useState(props.selectedLine.startPoint.x);
  const [startY, setStartY] = useState(props.selectedLine.startPoint.y);
  const [endX, setEndX] = useState(props.selectedLine.endPoint.x);
  const [endY, setEndY] = useState(props.selectedLine.endPoint.y);
  const [isDragging, setIsDragging] = useState(false);
  const [draggedPoint, setDraggedPoint] = useState(null);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [containerWidth, setContainerWidth] = useState(props.containerWidth);
  const [containerHeight, setContainerHeight] = useState(props.containerHeight);

  useEffect(() => {
    createLine();
    props.onLineChange({
      startPoint: { x: startX, y: startY },
      endPoint: { x: endX, y: endY },
    });
  }, [startX, startY, endX, endY]);

  useEffect(() => {
    setContainerWidth(props.containerWidth);
    setContainerHeight(props.containerHeight);
    setTimeout(() => {
      setStartX(props.selectedLine.startPoint.x);
      setStartY(props.selectedLine.startPoint.y);
      setEndX(props.selectedLine.endPoint.x);
      setEndY(props.selectedLine.endPoint.y);
      createLine();
    }, 100);
  }, [
    props.containerWidth,
    props.selectedLine.startPoint.x,
    props.selectedLine.lineDirection,
  ]);

  const createLine = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);

    // Draw dot at startPoint
    context.beginPath();
    context.arc(startX, startY, 5, 0, 2 * Math.PI);
    context.fillStyle = props.selectedLine.lineColor; // Color of the dot
    context.fill();
    context.closePath();

    // Draw dot at endPoint
    context.beginPath();
    context.arc(endX, endY, 5, 0, 2 * Math.PI);
    context.fillStyle = props.selectedLine.lineColor; // Color of the dot
    context.fill();
    context.closePath();

    // Draw the line between the points
    context.beginPath();
    context.setLineDash([0, 0]);
    context.moveTo(startX, startY);
    context.lineTo(endX, endY);
    context.strokeStyle = props.selectedLine.lineColor;
    context.lineWidth = 2; // Set line width directly to 2
    context.stroke();
    context.closePath();

    // Calculate the direction vector of the existing line
    const dx = endX - startX;
    const dy = endY - startY;

    // Calculate the magnitude of the existing line
    const magnitude = Math.sqrt(dx * dx + dy * dy);

    // Calculate the normalized direction vector
    const nx = dx / magnitude;
    const ny = dy / magnitude;

    // Calculate the perpendicular direction vector
    let px = -ny;
    let py = nx;
    if (props.selectedLine.lineDirection === LineDirectionEnum.LEFT) {
      px = ny;
      py = -nx;
    }

    // Calculate the length of the perpendicular line
    const perpendicularLength = magnitude / 4;

    // Calculate midpoint of the line
    const midX = (startX + endX) / 2;
    const midY = (startY + endY) / 2;

    const perpendicularStartX = midX - perpendicularLength * px;
    const perpendicularStartY = midY - perpendicularLength * py;
    const perpendicularEndX = midX + perpendicularLength * px;
    const perpendicularEndY = midY + perpendicularLength * py;

    // Draw the perpendicular line
    context.beginPath();
    context.setLineDash([5, 5]);
    context.moveTo(perpendicularStartX, perpendicularStartY);
    context.lineTo(perpendicularEndX, perpendicularEndY);
    context.strokeStyle = props.selectedLine.lineColor;
    context.lineWidth = 2;
    context.stroke();
    context.closePath();
    if (props.selectedLine.lineDirection === LineDirectionEnum.LEFT) {
      drawArrowhead(
        context,
        perpendicularEndX,
        perpendicularEndY,
        perpendicularStartX,
        perpendicularStartY
      );
    } else if (props.selectedLine.lineDirection === LineDirectionEnum.RIGHT) {
      drawArrowhead(
        context,
        perpendicularEndX,
        perpendicularEndY,
        perpendicularStartX,
        perpendicularStartY
      );
    } else if (props.selectedLine.lineDirection === LineDirectionEnum.BOTH) {
      drawArrowhead(
        context,
        perpendicularEndX,
        perpendicularEndY,
        perpendicularStartX,
        perpendicularStartY
      );
      drawArrowhead(
        context,
        perpendicularStartX,
        perpendicularStartY,
        perpendicularEndX,
        perpendicularEndY
      );
    }
  };

  const drawArrowhead = (context, x0, y0, x1, y1) => {
    const arrowSize = 12;

    const angle = Math.atan2(y1 - y0, x1 - x0);

    const x2 = x1 - arrowSize * Math.cos(angle - Math.PI / 6);
    const y2 = y1 - arrowSize * Math.sin(angle - Math.PI / 6);
    const x3 = x1 - arrowSize * Math.cos(angle + Math.PI / 6);
    const y3 = y1 - arrowSize * Math.sin(angle + Math.PI / 6);

    context.beginPath();
    context.moveTo(x1, y1);
    context.lineTo(x2, y2);
    context.lineTo(x3, y3);
    context.closePath();
    context.fillStyle = props.selectedLine.lineColor;
    context.fill();
  };

  const getMidpoint = () => ({
    x: (startX + endX) / 2,
    y: (startY + endY) / 2,
  });

  const handleMouseDown = (e) => {
    setIsDragging(true);

    const canvasRect = canvasRef.current.getBoundingClientRect();
    const mouseX = e.clientX - canvasRect.left;
    const mouseY = e.clientY - canvasRect.top;

    // Calculate distance from click to start and end points
    const distanceToStartPoint = Math.sqrt(
      Math.pow(mouseX - startX, 2) + Math.pow(mouseY - startY, 2)
    );
    const distanceToEndPoint = Math.sqrt(
      Math.pow(mouseX - endX, 2) + Math.pow(mouseY - endY, 2)
    );

    // Check if click is near the start or end point (within a certain threshold)
    const threshold = 10; // Adjust as needed

    if (distanceToStartPoint < threshold) {
      if (e.detail > 1) {
        return;
      }
      // Handle start point click
      setDraggedPoint('start');
    } else if (distanceToEndPoint < threshold) {
      if (e.detail > 1) {
        return;
      }
      // Handle end point click
      setDraggedPoint('end');
    } else {
      // Calculate distance from click to the line
      const distanceToLine =
        Math.abs(
          (endY - startY) * mouseX -
            (endX - startX) * mouseY +
            endX * startY -
            endY * startX
        ) / Math.sqrt(Math.pow(endY - startY, 2) + Math.pow(endX - startX, 2));

      // Check if click is near the line (within a certain threshold)
      if (distanceToLine < threshold) {
        setDraggedPoint('center');
        const midpoint = getMidpoint();
        setDragOffset({ x: mouseX - midpoint.x, y: mouseY - midpoint.y });
        // if (e.detail === 2) {
        if (
          Math.abs(mouseX - midpoint.x) < threshold &&
          Math.abs(mouseY - midpoint.y) < threshold
        ) {
          props.onChangeDirection();
        }
      } else {
        setIsDragging(false);
      }
    }

    const arrowheadThreshold = 12;

    const isNearArrowhead = (x, y, arrowX, arrowY) => {
      return (
        Math.sqrt(Math.pow(x - arrowX, 2) + Math.pow(y - arrowY, 2)) <
        arrowheadThreshold
      );
    };

    const { perpendicularStartX, perpendicularStartY } =
      getPerpendicularPoints();
    if (
      isNearArrowhead(mouseX, mouseY, perpendicularStartX, perpendicularStartY)
    ) {
      props.onChangeDirection();
      setDraggedPoint('arrowheadStart');
    }
    const { perpendicularEndX, perpendicularEndY } = getPerpendicularPoints();
    if (isNearArrowhead(mouseX, mouseY, perpendicularEndX, perpendicularEndY)) {
      props.onChangeDirection();
      setDraggedPoint('arrowheadEnd');
    }
  };

  const getPerpendicularPoints = () => {
    const dx = endX - startX;
    const dy = endY - startY;
    const magnitude = Math.sqrt(dx * dx + dy * dy);
    const nx = dx / magnitude;
    const ny = dy / magnitude;
    const px = -ny;
    const py = nx;
    const perpendicularLength = magnitude / 4;
    const midX = (startX + endX) / 2;
    const midY = (startY + endY) / 2;
    const perpendicularStartX = midX - perpendicularLength * px;
    const perpendicularStartY = midY - perpendicularLength * py;
    const perpendicularEndX = midX + perpendicularLength * px;
    const perpendicularEndY = midY + perpendicularLength * py;

    return {
      perpendicularStartX,
      perpendicularStartY,
      perpendicularEndX,
      perpendicularEndY,
    };
  };

  const handleMouseMove = (e) => {
    if (!isDragging || !draggedPoint) return;

    const canvasRect = canvasRef.current.getBoundingClientRect();
    const mouseX = e.clientX - canvasRect.left;
    const mouseY = e.clientY - canvasRect.top;
    const sensitivity = 1; // Adjust the sensitivity factor

    if (draggedPoint === 'start') {
      const x = (mouseX - dragOffset.x) * sensitivity;
      const y = (mouseY - dragOffset.y) * sensitivity;

      const newStartX = Math.min(Math.max(x, 0), containerWidth);
      const newStartY = Math.min(Math.max(y, 0), containerHeight);
      const newEndX = endX + (startX - newStartX);
      const newEndY = endY + (startY - newStartY);

      setStartX(newStartX);
      setStartY(newStartY);
      setEndX(newEndX);
      setEndY(newEndY);
    } else if (draggedPoint === 'end') {
      const x = (mouseX - dragOffset.x) * sensitivity;
      const y = (mouseY - dragOffset.y) * sensitivity;

      const newEndX = Math.min(Math.max(x, 0), containerWidth);
      const newEndY = Math.min(Math.max(y, 0), containerHeight);
      const newStartX = startX + (endX - newEndX);
      const newStartY = startY + (endY - newEndY);

      setStartX(newStartX);
      setStartY(newStartY);
      setEndX(newEndX);
      setEndY(newEndY);
    } else if (draggedPoint === 'center') {
      const x = (mouseX - dragOffset.x) * sensitivity;
      const y = (mouseY - dragOffset.y) * sensitivity;

      const newMidpointX = Math.min(Math.max(x, 0), containerWidth);
      const newMidpointY = Math.min(Math.max(y, 0), containerHeight);

      const deltaX = newMidpointX - getMidpoint().x;
      const deltaY = newMidpointY - getMidpoint().y;

      setStartX(startX + deltaX);
      setStartY(startY + deltaY);
      setEndX(endX + deltaX);
      setEndY(endY + deltaY);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setDraggedPoint(null);
  };

  return (
    <div className="line-container">
      <canvas
        ref={canvasRef}
        className={isDragging ? 'grabbing' : 'grab'}
        width={containerWidth}
        height={containerHeight}
        style={{ border: '1px solid #ccc' }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        Your browser does not support the canvas element.
      </canvas>
    </div>
  );
};

export default Line;
