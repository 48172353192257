import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { HiOutlineVideoCamera } from 'react-icons/hi2';
import { FiSettings } from 'react-icons/fi';
import OfflineCamera from './OfflineCamera';
import moment from 'moment';
import { WiTime4 } from 'react-icons/wi';
import {
  getCDNInfo,
  getSnapshotImage,
  getSnapshotImages,
  setIsRemoteStreamPlay,
} from '../../../store/reducers/StreamingReducer';
import { Utils, constants } from '../../../helpers';
import useEventsStore from '../../../store/EventsStore';
import nosnapshot from '../../../assets/images/nosnapshot.svg';
import videoProcessing from '../../../assets/images/cameras/FootageThumb.svg';
import NoSnapshot from './NoSnapshot';
import { ReactComponent as ThreeDotIcon } from '../../../assets/images/VerticalThreeDots.svg';
import { setDeviceInformation } from '../../../store/reducers/AccountReducer';

const ImageGridItem = ({
  time,
  imageURL = null,
  cdnValue,
  deviceId,
  device,
  liveSnapshot,
  camera,
  isFromSearchDashboard = false,
  timeZone,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const imageRef = useRef();
  const [newSrc, setNewSrc] = useState('');
  const [selectedSnapSrc, setSelectedSnapSrc] = useState('');
  const [snapshot, setSnapshot] = useState(null);
  const snapshotImage = useSelector(getSnapshotImage);
  const snapshotImages = useSelector(getSnapshotImages);
  const { getSelectedEvent } = useEventsStore();
  const cdnInfo = useSelector(getCDNInfo);

  useEffect(() => {
    const newTime = Utils.getUnixDate(Utils.getDate(time / 1000));

    if (
      deviceId &&
      newTime &&
      !imageURL &&
      cdnValue?.protocol &&
      cdnValue?.host
    ) {
      const bucket = (cdnValue?.bucket).replace('${deviceId}', deviceId);
      const date = Utils.fetchDateInUnix(newTime);

      fetch(
        `${cdnValue?.protocol}://${cdnValue?.host}/${bucket}/${date}/${newTime}.jpg`,
        {
          credentials: 'include',
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          if (
            getSelectedEvent() &&
            Utils.getUnixDate(Utils.getDate(getSelectedEvent() / 1000)) ===
              newTime
          ) {
            setSelectedSnapSrc(URL.createObjectURL(blob));
          } else {
            setNewSrc(URL.createObjectURL(blob));
          }
        })
        .catch(() => {
          console.log('Error got', deviceId);
        });
    } else if (imageURL) {
      setNewSrc(imageURL);
    }
  }, [time, deviceId]);

  useEffect(() => {
    if (camera) {
      setSnapshot(snapshotImages?.[deviceId]);
    } else {
      setSnapshot(snapshotImage);
    }
  }, [snapshotImage, deviceId, camera]);

  const OnClickCameraDetails = () => {
    navigate(`/cameras/dashboard.html`, {
      state: {
        id: deviceId,
        cdnInfo: cdnInfo ? cdnInfo : {},
      },
    });
  };

  const handleDoubleClick = () => {
    OnClickCameraDetails();
  };

  const OnClickCameraSettings = () => {
    dispatch(setDeviceInformation(device));
    deviceId && navigate(`/devices/dashboard.html?deviceId=${deviceId}`);
  };

  return (
    <>
      {device?.displayDeviceStatus ===
        constants.DEVICES_RETURN_OFFLINE_STATUS ||
      device?.displayDeviceStatus ===
        constants.DEVICES_RETURN_DEACTIVATED_STATUS ? (
        <OfflineCamera
          deviceId={deviceId}
          device={device}
          activeTime={time}
          timeZone={timeZone}
          onRefresh={() => {}}
        />
      ) : (
        <>
          {liveSnapshot ? (
            snapshot ? (
              <>
                <div
                  className={`device-overlay hovered`}
                  onDoubleClick={() => handleDoubleClick()}
                >
                  <div className="device-title-container">
                    <div className="device-name">{device?.deviceName}</div>
                    <div className="device-location">
                      {device?.locationName} • {device?.areaName}
                    </div>
                  </div>
                  <div className="date-time-wrapper">
                    <div className="date-time">
                      <WiTime4 size={14} />
                      {moment.tz(moment(time), timeZone).format('hh:mm:ss A z')}
                    </div>
                  </div>
                  <div className="menu-icon">
                    <NavDropdown
                      className="devices-dropdown"
                      title={<ThreeDotIcon />}
                    >
                      <NavDropdown.Item
                        className="devices-dropdown-options"
                        onClick={() => OnClickCameraDetails()}
                      >
                        <HiOutlineVideoCamera size={20} />
                        <span className="devices-dropdown-options-label">
                          {constants.CAMERAS_VIDEO_CAMERA_DETAILS_LABEL}
                        </span>
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        className="devices-dropdown-options"
                        onClick={() => OnClickCameraSettings()}
                      >
                        <FiSettings size={20} />
                        <span className="devices-dropdown-options-label">
                          {constants.CAMERAS_VIDEO_SETTINGS_LABEL}
                        </span>
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                </div>
                <div className="primary-circle"></div>
                <img
                  ref={imageRef}
                  id="img-snapshot"
                  src={snapshot}
                  alt=""
                  className="live-snapshot main-snapshot"
                  onLoad={() => {
                    dispatch(setIsRemoteStreamPlay(true));
                  }}
                  onError={() => {
                    setSnapshot(null);
                  }}
                />
                <img
                  className="hide-image"
                  src={videoProcessing}
                  alt="video processing"
                />
              </>
            ) : (
              <NoSnapshot
                deviceId={deviceId}
                device={device}
                activeTime={time}
                timeZone={timeZone}
              />
            )
          ) : (
            <>
              {selectedSnapSrc || newSrc ? (
                <>
                  <div
                    className={`device-overlay hovered`}
                    onDoubleClick={() => handleDoubleClick()}
                  >
                    <div className="device-title-container">
                      <div className="device-name">{device?.deviceName}</div>
                      <div className="device-location">
                        {device?.locationName} • {device?.areaName}
                      </div>
                    </div>
                    <div className="date-time-wrapper">
                      <div className="date-time">
                        <WiTime4 size={14} />
                        {moment
                          .tz(moment(time), timeZone)
                          .format('hh:mm:ss A z')}
                      </div>
                    </div>
                    <div className="menu-icon">
                      <NavDropdown
                        className="devices-dropdown"
                        title={<ThreeDotIcon />}
                      >
                        <NavDropdown.Item
                          className="devices-dropdown-options"
                          onClick={() => OnClickCameraDetails()}
                        >
                          <HiOutlineVideoCamera size={20} />
                          <span className="devices-dropdown-options-label">
                            {constants.CAMERAS_VIDEO_CAMERA_DETAILS_LABEL}
                          </span>
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          className="devices-dropdown-options"
                          onClick={() => OnClickCameraSettings()}
                        >
                          <FiSettings size={20} />
                          <span className="devices-dropdown-options-label">
                            {constants.CAMERAS_VIDEO_SETTINGS_LABEL}
                          </span>
                        </NavDropdown.Item>
                      </NavDropdown>
                    </div>
                  </div>
                  <div className="primary-circle"></div>
                  <img
                    ref={imageRef}
                    id="img-snapshot"
                    className={`${
                      isFromSearchDashboard ? ' img-event' : ''
                    } main-snapshot`}
                    src={
                      Utils.getUnixDate(
                        Utils.getDate(getSelectedEvent() / 1000)
                      ) === Utils.getUnixDate(Utils.getDate(time / 1000)) &&
                      selectedSnapSrc
                        ? selectedSnapSrc
                        : newSrc
                        ? newSrc
                        : nosnapshot
                    }
                    alt=""
                    onLoad={(event) => {
                      // event.target.style.display = 'inline-block';
                      dispatch(setIsRemoteStreamPlay(true));
                    }}
                    onError={(event) => {
                      if (isFromSearchDashboard) {
                        event.target.src = nosnapshot;
                      } else {
                        setNewSrc('');
                        setSelectedSnapSrc('');
                      }
                    }}
                  />
                  <img
                    className="hide-image"
                    src={videoProcessing}
                    alt="video processing"
                  />
                </>
              ) : (
                <NoSnapshot
                  deviceId={deviceId}
                  device={device}
                  activeTime={time}
                  timeZone={timeZone}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ImageGridItem;
