import { useState, useEffect } from 'react';
import axios from 'axios';
import './systemnotification.scss';
import { Utils, constants } from '../../helpers';
import { ToggleInput } from '../../components/forms';
import { useOrganizations } from '../../store/OrganizationsStore';
import { SiteSpinner } from '../../components/common';

const SystemNotification = () => {
  const [marketingEmail, setMarketingEmail] = useState(false);
  const [marketingPush, setMarketingPush] = useState(false);
  const [newDeviceEmail, setNewDeviceEmail] = useState(false);
  const [newDevicePush, setNewDevicePush] = useState(false);
  const [newFeatureEmail, setNewFeatureEmail] = useState(false);
  const [newFeaturePush, setNewFeaturePush] = useState(false);
  const [sendStaus, setSendStaus] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const getCustomerOrgData = useOrganizations(
    (state) => state.getCustomerOrgData
  );
  let custOrg = getCustomerOrgData();
  const custOrgData = custOrg[0];

  const apiCallSystemNotification = async () => {
    setSendStaus(false);
    const requestParam = {
      marketingMaterialPush: marketingPush,
      marketingMaterialEmail: marketingEmail,
      newDevicePush: newDevicePush,
      newDeviceEmail: newDeviceEmail,
      newFeatureEmail: newFeatureEmail,
      newFeaturePush: newFeaturePush,
    };
    try {
      const response = await axios.post(
        `partner/orgs/${custOrgData?.orgId}/systemNotification`,
        requestParam,
        Utils.requestHeader()
      );
      console.log('response', response);
    } catch (error) {
      // TODO : to print the error will update this later
      console.error(error);
    }
  };

  const getSystemNotificationStatus = async () => {
    try {
      const response = await axios.get(
        `partner/orgs/${custOrgData?.orgId}/systemNotification`,
        Utils.requestHeader()
      );
      setShowLoader(false);
      setMarketingEmail(response?.data?.data?.marketingMaterialEmail);
      setMarketingPush(response?.data?.data?.marketingMaterialPush);
      setNewDeviceEmail(response?.data?.data?.newDeviceEmail);
      setNewDevicePush(response?.data?.data?.newDevicePush);
      setNewFeatureEmail(response?.data?.data?.newFeatureEmail);
      setNewFeaturePush(response?.data?.data?.newFeaturePush);
    } catch (error) {
      // TODO : to print the error will update this later
      console.error(error);
      setShowLoader(false);
    }
  };

  useEffect(() => {
    setShowLoader(true);
    getSystemNotificationStatus();
  }, []);

  useEffect(() => {
    if (sendStaus) {
      apiCallSystemNotification();
    }
  }, [sendStaus]);

  return (
    <div className="system-notification">
      <div className="header-layout ">
        <div className="system-notification-label">
          {constants.NOTIFICATION_SYSTEM_NOTIFICATION}
          {showLoader && <SiteSpinner height="30px" width="30px" />}
        </div>
      </div>

      <div className="email-push-layout">
        <div className="email">{constants.NOTIFICATION_EMAIL}</div>
        <div className="push">{constants.NOTIFICATION_PUSH}</div>
      </div>
      <div className="marketing-material">
        <div className="label-title">
          {constants.NOTIFICATION_MARKETING_MATERIAL}
        </div>
        <div className="toggle-checkbox">
          <div className="toggle-email">
            <ToggleInput
              changeHandler={(e) => {
                {
                  setMarketingEmail(!!!JSON.parse(e?.target?.value));
                  setSendStaus(true);
                }
              }}
              value={marketingEmail}
              classes={'toggle-bg'}
            />
          </div>
          <ToggleInput
            changeHandler={(e) => {
              {
                setMarketingPush(!!!JSON.parse(e?.target?.value));
                setSendStaus(true);
              }
            }}
            value={marketingPush}
            classes={'toggle-bg'}
          />
        </div>
      </div>
      <div className="new-added-devices">
        <div className="label-title">{constants.NOTIFICATION_NEW_DEVICES}</div>
        <div className="toggle-checkbox">
          <div className="toggle-email">
            <ToggleInput
              changeHandler={(e) => {
                {
                  setNewDeviceEmail(!!!JSON.parse(e?.target?.value));
                  setSendStaus(true);
                }
              }}
              value={newDeviceEmail}
              classes={'toggle-bg'}
            />
          </div>
          <ToggleInput
            changeHandler={(e) => {
              {
                setNewDevicePush(!!!JSON.parse(e?.target?.value));
                setSendStaus(true);
              }
            }}
            value={newDevicePush}
            classes={'toggle-bg'}
          />
        </div>
      </div>
      <div className="new-available-features">
        <div className="label-title">{constants.NOTIFICATION_NEW_FEATURES}</div>
        <div className="toggle-checkbox">
          <div className="toggle-email">
            <ToggleInput
              changeHandler={(e) => {
                {
                  setNewFeatureEmail(!!!JSON.parse(e?.target?.value));
                  setSendStaus(true);
                }
              }}
              value={newFeatureEmail}
              classes={'toggle-bg'}
            />
          </div>
          <ToggleInput
            changeHandler={(e) => {
              {
                setNewFeaturePush(!!!JSON.parse(e?.target?.value));
                setSendStaus(true);
              }
            }}
            value={newFeaturePush}
            classes={'toggle-bg'}
          />
        </div>
      </div>
    </div>
  );
};

export default SystemNotification;
