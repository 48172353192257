import { useCallback, useEffect, useRef, useState } from 'react';
import { useRefresh } from 'muuri-react';
import Checkbox from '@mui/material/Checkbox';
import { v4 as uuidv4 } from 'uuid';
import { AppDefaults, Utils, constants } from '../../helpers';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { devicesMQTTStore } from '../../store/DevicesMQTTStore';
import {
  mqttPublish,
  mqttSubscribe,
} from '../../utils/connection/mqttConnection';
import { FiCameraOff } from 'react-icons/fi';

import FakeSnapshot from '../../assets/images/cameras/snapshots/fake-snapshot.png';
import { ReactComponent as DisabledCheckIcon } from '../../assets/images/icons/BlankCheckbox.svg';
import { ReactComponent as CheckIcon } from '../../assets/images/icons/BlueCheckmark.svg';
import { ReactComponent as OrangeCheckIcon } from '../../assets/images/icons/OrangeCheckmark.svg';
import { ReactComponent as CheckboxIcon } from '../../assets/images/icons/Checkbox.svg';

import './DeviceItem.scss';

const DeviceItem = ({
  orgId,
  cdnInfo,
  deviceId,
  deviceElemId,
  deviceName,
  locationAreaName = '',
  imageURL = FakeSnapshot,
  initialChecked = false,
  disabled = false,
  showZoomOptions = false,
  currentLayoutStatus = false,
  zoomCallback = null,
  clickHandler = null,
  connectionStatus,
  checkboxCallback,
  numOfSelectedDevices,
  selectedDevices,
  conStatus,
  hubId,
  ...props
}) => {
  const [zoomLevel, setZoomLevel] = useState('1x');
  const [latestSnapshots, setLatestSnapshots] = useState({});
  const [snapshotURL, setSnapshotURL] = useState(imageURL);
  const [checked, setChecked] = useState(initialChecked);
  const refreshWall = useRefresh();

  const { getState, subscribe } = devicesMQTTStore;
  const state = getState();
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );

  const accountId = loggedInUserData.accountId;
  const appTopic = `a/notify/${hubId}`;
  const checkboxRef = useRef();

  useEffect(() => {
    if (state.getAccountId() !== accountId) {
      state.setAccountId(accountId);
    }

    if (!state.getSessionId()) {
      state.setSessionId(uuidv4());
    }

    const appSubscription = {
      topic: appTopic,
      qos: 0,
    };

    const deviceSubscription = {
      topic: `d/notify/${accountId}/${state.getSessionId()}`,
      qos: 0,
    };

    // Subscribe to the app topic
    mqttSubscribe(appSubscription);
    state.setIsPublisherSubscribed(true);

    // Subscribe  to the device topic
    mqttSubscribe(deviceSubscription);
    state.setIsReceiverSubscribed(true);

    subscribe((latestSnapshots) => setLatestSnapshots(latestSnapshots));

    // Retrieve latest snapshot every 10 seconds
    const interval = setInterval(() => {
      publishSnapshotRequest();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const fetchLatestSnapshot = async (lastSnapshot) => {
      if (!lastSnapshot || !cdnInfo) {
        return;
      }

      let bucket = cdnInfo?.bucket?.replace('${deviceId}', deviceId);

      const snapshotImage = new Image();

      // Assign the valid URL to the snapshot URL state
      // variable
      snapshotImage.onload = () => {
        setSnapshotURL(snapshotImage.src);
      };

      // Handle the image loading error (e.g. 404 or 403 error)
      snapshotImage.onerror = () => {
        setSnapshotURL(imageURL);
      };
      const date = Utils.fetchDateInUnix(lastSnapshot);
      snapshotImage.src = `${cdnInfo.protocol}://${cdnInfo.host}/${bucket}/${date}/${lastSnapshot}.jpg`;
    };

    const lastSnapshot = state.latestSnapshots[deviceId];
    const lastEvent = state.latestEvents[deviceId];
    const deviceSnapshot = lastSnapshot > lastEvent ? lastSnapshot : lastEvent;

    fetchLatestSnapshot(deviceSnapshot);
  }, [latestSnapshots]);

  useEffect(() => {
    refreshWall(zoomLevel);
  }, [zoomLevel, refreshWall]);

  const publishSnapshotRequest = useCallback(() => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const sessionId = state.getSessionId();

    if (!accountId) {
      return;
    }

    // Send the request
    const context = {
      topic: appTopic,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${hubId}`,
        from: `${accountId}`,
        msg: {
          action: 'get',
          resource: `ch/${deviceId}/camera/last-snap-timestamp`,
          publish: `d/notify/${accountId}/${sessionId}`,
        },
      }),
      qos: 0,
    };

    mqttPublish(context);
  }, []);

  const handleChange = (event) => {
    let isChecked = event.target.checked;
    setChecked(isChecked);
    checkboxCallback && checkboxCallback(deviceId, isChecked);
  };

  return (
    <div key={deviceId} id={deviceElemId} className="live-item live-item-1x">
      <div className="live-item-content">
        {/* Safe zone, enter your custom markup */}
        <div
          id={`${deviceElemId}Snapshot`}
          className={`${
            connectionStatus === 'offline' ? 'hidden' : 'live-item-snapshot'
          } live-item-snapshot-1x`}
        >
          <picture className="live-item-snapshot-wrapper">
            <img
              src={snapshotURL}
              className={`live-item-snapshot-image live-item-snapshot-image-1x${
                checked ? ' live-item-snapshot-image-checked' : ''
              }`}
              alt={`Snapshot of ${deviceName}`}
            />
          </picture>
        </div>
        {conStatus === 'offline' && (
          <div
            id={`${deviceElemId}Overlay`}
            className={`live-item-offline item-overlay-${zoomLevel}`}
          >
            <div className="live-item-offline-content">
              <div className="camera-offline-icon">
                <FiCameraOff
                  stroke={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--greyscale_56')}
                  size={22}
                />
              </div>
              <div className="camera-offline-label">
                {constants.CAMERAS_VIDEO_WALL_CAMERA_OFFLINE_TITLE}
              </div>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-between">
          <div className="d-block" style={{ width: '82%' }}>
            <div className="live-item-name">{deviceName}</div>
            <div className="live-item-location">{locationAreaName}</div>
          </div>
          <div>
            <Checkbox
              ref={checkboxRef}
              className="live-item-checkbox"
              inputProps={{
                'aria-label': 'Device Checkbox',
              }}
              icon={
                disabled === true ? <DisabledCheckIcon /> : <CheckboxIcon />
              }
              checkedIcon={
                process.env.REACT_APP_PROJECT ===
                AppDefaults.PROJECT_MEGATRON ? (
                  <OrangeCheckIcon />
                ) : (
                  <CheckIcon />
                )
              }
              checked={checked}
              onChange={handleChange}
              disabled={disabled}
            />
          </div>
        </div>
        {/* Safe zone ends */}
      </div>
    </div>
  );
};

export default DeviceItem;
