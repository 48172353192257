import { useEffect, useState } from 'react';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import { Utils, constants } from '../../helpers';
import { PrimaryButton, SiteToast, TextBlock } from '../../components/common';
import { useOrganizations } from '../../store/OrganizationsStore';
import axios from 'axios';
import './EditPartnerUsers.scss';
import { usePartnerOrgUsers } from '../../store/PartnerOrgUsersStore';

function AssignCustomers(props) {
  const accountId = props?.accountId;
  const assignedCustomerData = props?.assignedCustomerData;
  const [variant, setVariant] = useState('error');
  const [userMsg, setUserMsg] = useState('');
  const [submittingForm, setSubmittingForm] = useState(false);
  const [showMsg, setShowMsg] = useState(false);

  const customerOrgData = useOrganizations((state) => state.customerOrgData);
  const partnerOrgData = useOrganizations((state) => state.partnerOrgData);
  const partnerOrgId = partnerOrgData?.orgId;
  const [assignCustomerData, setAssignCustomerData] = useState(
    assignedCustomerData ? assignedCustomerData : []
  );
  const setEditPartnerAssignedCustomer = usePartnerOrgUsers(
    (state) => state.setEditPartnerAssignedCustomer
  );
  const [assignCustomerChangedFlag, setAssignCustomerChangedFlag] = useState(false);

  // === Get Location and Areas HTML
  const getAssignCustomerFields = (customers) => {
    if (customers?.length >= 1) {
      let customersHtml = customers.map((customer) => {
        return (
          <div className="partner-customers-list-item" key={customer.orgId}>
            <label
              className="partner-customers-list-item-label"
              htmlFor={`customer-${customer.orgId}`}
            >
              {customer.orgName}
            </label>
            <input
              className="partner-customers-list-item-input"
              type="checkbox"
              checked={
                getCustomersExists(customer.orgId)?.toString() === 'true'
                  ? true
                  : false
              }
              value=""
              id={`customer-${customer.orgId}`}
              onChange={(event) => {
                handleAddRemoveCustomers(event, customer.orgId);
              }}
            />
          </div>
        );
      });
      return customersHtml;
    }
  };

  //=== Check if Area is checked or not
  const getCustomersExists = (orgId) => {
    let assignedCustomers = [...assignCustomerData];
    let cutomer = false;
    // TODO : delete later
    // console.log('assignedCustomers', assignedCustomers);
    if (assignedCustomers?.length > 0) {
      const customerExists = assignedCustomers?.find(
        (customer) =>
          customer.orgId === orgId && customer.checked?.toString() === 'true'
      );
      if (customerExists) {
        return true;
      }
    }
    return cutomer;
  };

  useEffect(() => {
    const assignedData = props?.assignedCustomerData;
    const customerOrgsData = [];
    if (assignedData.length > 0) {
      assignedData.forEach((customer) => {
        customerOrgsData.push(customer.orgId);
      });
    }
    const newAssignedData = [];
    if (assignCustomerData.length > 0) {
      assignCustomerData.forEach((customer) => {
        newAssignedData.push(customer.orgId);
      });
    }
    if (newAssignedData.length >= 0 && customerOrgsData?.length >= 0) {
      if (newAssignedData.length != customerOrgsData.length)
        setAssignCustomerChangedFlag(true);
      else {
        const found = newAssignedData.some((r) => customerOrgsData.every((cd) => r !== cd));
        if (found) {
          setAssignCustomerChangedFlag(true);
        } else {
          setAssignCustomerChangedFlag(false);
        }
      }
    }
  }, [JSON.stringify(props?.assignedCustomerData), JSON.stringify(assignCustomerData)]);

  //=== Check/uncheck areas
  const handleAddRemoveCustomers = (event, orgId) => {
    let chkdValue = event.target.checked;
    let assignedCustomers = [...assignCustomerData];

    if (chkdValue) {
      //=== If checked
      if (assignedCustomers.length === 0) {
        //=== If blank
        assignedCustomers.push({ orgId: orgId, checked: true });
        setAssignCustomerData(assignedCustomers);
      } else {
        //=== If not blank
        const foundLoc = assignedCustomers.some((el) => el.orgId === orgId);
        if (foundLoc) {
          //=== if location already exits - add area into it
          setAssignCustomerData(assignedCustomers);
        } else {
          //=== if location not exits - add locaton & area both
          assignedCustomers.push({ orgId: orgId, checked: true });
          setAssignCustomerData(assignedCustomers);
        }
      }
    } else {
      //=== If checked off - remove value from array
      assignedCustomers = assignedCustomers.filter((obj) => {
        if (obj.orgId !== orgId) {
          return obj;
        }
      });
      setAssignCustomerData(assignedCustomers);
    }
  };

  const handleSubmitAssigncustomers = (event) => {
    event.preventDefault();
    setSubmittingForm(true);
    if (partnerOrgId !== null && accountId !== null) {
      let assignedOrgIds = [];
      if (assignCustomerData.length > 0) {
        assignCustomerData.forEach((customer) => {
          assignedOrgIds.push(customer.orgId);
        });
      }

      axios
        .put(`partner/orgs/${partnerOrgId}/accounts/${accountId}/assignOrgs`, {
          orgIds: assignedOrgIds,
        }, Utils.requestHeader())
        .then((res) => {
          let response = res?.data;
          if (response?.meta?.code === 200) {
            if(response?.data?.organizations?.length > 0){
              setEditPartnerAssignedCustomer(response?.data?.organizations)
            }
            props.reloadData();
            props.hideModal();
          } else {
            setVariant('error');
            setUserMsg(response?.meta?.userMsg);
            setSubmittingForm(false);
          }
        })
        .catch(function (error) {
          setSubmittingForm(false);
          setVariant('error');
          setShowMsg(true);
          setUserMsg(error);
        });
    } else {
      setSubmittingForm(false);
      setShowMsg(true);
      setUserMsg(constants.ACCOUNT_NOT_AVAILABLE_ERROR_MESSAGE);
    }
    setSubmittingForm(false);
  };

  return (
    <section className="modal-main-content">
      <div className={`parent-edit-modal`}>
        <Row className={`mt-2 mb-4 status-info`}>
          <Col className="text-start">
            <TextBlock
              className="mb-4"
              fontWeight="400"
              fontSize="14px"
              lineHeight="20px"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_primary')}
            >
              {constants.PARTNER_EDIT_ASSIGN_CUSTOMER_CONTENT}
            </TextBlock>
          </Col>
        </Row>

        <Form
          className="modal-form"
          onSubmit={(event) => handleSubmitAssigncustomers(event)}
        >
          <div className="partner-customers-list-wrap">
            {customerOrgData?.length >= 1 &&
              getAssignCustomerFields(customerOrgData)}
          </div>

          {showMsg && (
            <Form.Group as={Row} className="mt-3">
              <Col>
                <Alert
                  variant={'danger'}
                  onClose={() => setShowMsg(false)}
                  dismissible
                >
                  {userMsg}
                </Alert>
              </Col>
            </Form.Group>
          )}

          <Form.Group as={Row} className="mt-3">
            <Col>
              <PrimaryButton
                className="btn btn-primary"
                type="submit"
                loader={submittingForm}
                height="44px"
                fontSize="14px"
                disabled={!assignCustomerChangedFlag}
              >
                {constants.PARTNER_ASSIGN_CUSTOMER_BUTTON_TITLE}
              </PrimaryButton>
            </Col>
          </Form.Group>
        </Form>

        {/* Show error messages */}
        <SiteToast title="Error" show={!!userMsg} body={userMsg} variant={variant} position="top-center" />
      </div>
    </section>
  );
}

export default AssignCustomers;
