import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { SelectField, TextField } from '../../components/forms';
import { constants, Utils,AppDefaults } from '../../helpers';
import { PrimaryButton, SiteToast } from '../../components/common';
import Autocomplete from 'react-google-autocomplete';
import { BiWorld } from 'react-icons/bi';
import { AiOutlineLinkedin } from 'react-icons/ai';
import axios from 'axios';
import { useOrganizations } from '../../store/OrganizationsStore';
import { subscribeWithOrgIds } from '../../utils/connection/mqttConnection';
import timezones from '../../data/support/timezone.json';
import { PiWarningCircleBold } from 'react-icons/pi';
import { IoClose } from "react-icons/io5";
import { AiOutlineExclamationCircle } from "react-icons/ai";

// Schema for yup
const validationSchema = Yup.object().shape({
  orgName: Yup.string()
    .max(32, constants.CANT_LONGER_ERROR_MESSAGE)
    .required(constants.CUSTOMER_NAME_REQUIRED_ERROR_MESSAGE),
    shortName:Yup.string()
    .required(constants.SHORT_NAME_REQUIRED_ERROR_MESSAGE)
    .min(6, constants.SHORT_NAME_MIN_LENGTH_ERROR_MESSAGE)
    .max(12, constants.SHORT_NAME_MAX_LENGTH_ERROR_MESSAGE)
    .matches(/^[a-zA-Z0-9]+$/, constants.SHORT_NAME_NO_SPECIAL_CHARACTERS_ERROR_MESSAGE),
  addressLine1: Yup.string().required(constants.ADDRESS_REQUIRED_ERROR_MESSAGE),
  orgWebsite: Yup.string()
    .matches(
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/i,
      constants.WEBSITE_CORRECT_URL_MESSAGE
    ),
  orgLinkedInProfile: Yup.string().matches(
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/i,
    constants.WEBSITE_CORRECT_URL_MESSAGE
  ),
});

function NewOrganization(props) {
  const [variant, setVariant] = useState('error');
  const [userMsg, setUserMsg] = useState('');
  const [locHelperText, setLocHelperText] = useState('');
  const [visibleLocHelperText, setVisibleLocHelperText] = useState(false);
  const partnerOrgData = useOrganizations((state) => state.partnerOrgData);
  const partnerOrgId = partnerOrgData?.orgId;
  const zoneTimeList = timezones?.data;
  const [currentUserTimezone, setCurrentUserTimezone] = useState({});
  const [isFieldDisabled, setIsFieldDisabled] = useState(true);
  const [shortNameExistsError, setShortNameExistsError] = useState(false);

  const tenantID =
  !process.env.REACT_APP_PROJECT && !AppDefaults.PROJECT_MEGATRON
    ? 'dc'
    : process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON
    ? 'hva'
    : 'dc';
const appID =
  !process.env.REACT_APP_PROJECT && !AppDefaults.PROJECT_MEGATRON
    ? 'vms'
    : process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON
    ? 'oncloud'
    : 'vms';
const appId = 'vms';

  useEffect(() => {}, [currentUserTimezone]);

  const changeTimezoneValue = (evebr, setFieldValue) => {
    let selectedVal = evebr?.target?.value;
    const getLocationSelected = zoneTimeList?.find(
      (zone) => zone.value === selectedVal
    );
    setCurrentUserTimezone(getLocationSelected);
    setFieldValue('timezone', getLocationSelected?.value);
    setVisibleLocHelperText(false);
  };

  
  const disableInput = (event, values) => {
    if (values.addressLine1) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  
  /**
   * CALLING ORGNIAZATION SHORT NAME API 
   */

const callOrgShortNameApi = async (val) => {
  if (val) {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}partner/orgs/generateShortName?orgName=${val.orgName}`,
      {
        headers: {
          'X-Correlation-ID': Utils.generateUUID(),
          'X-tenant-id': tenantID,
          'X-app-Id': appID,
        },
      }
    );

    const responseData = response?.data;
    if (responseData?.meta?.code === 200) {
      val.shortName = responseData?.data?.shortName;
      setIsFieldDisabled(false);
    }
  }
};

/***
 * SHORT NAME VALIDATION API 
 */

const validateExistingName = async (changedValue, setFieldValue) => {
  try {
    let changedTextValue = changedValue.trim();
    setFieldValue('shortName', changedTextValue);
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}partner/orgs/${changedTextValue}/exists`,
      {
        headers: {
          'X-Correlation-ID': Utils.generateUUID(),
          'X-tenant-id': tenantID,
          'X-app-Id': appID,
        },
      }
    );

    const responseData = response?.data;
    if (responseData?.meta?.code === 200) {
      if (!responseData?.data?.shortNameExists) {
        setShortNameExistsError(false);
      } else {
        setShortNameExistsError(true);
      }
    }
  } catch (e) { console.error(e);}
};
  return (
    <section className="modal-main-content">
      <Formik
        initialValues={{
          orgName: '',
          displayDetails: '',
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          country: '',
          zipcode: '',
          orgWebsite: '',
          orgLinkedInProfile: '',
          lat: 0,
          lng: 0,
          timezone: currentUserTimezone?.value,
          shortName:'',
        }}
        // Hooks up our validationSchema to Formik
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          // When button submits form and form is in the process of submitting, submit button is disabled
          setSubmitting(true);
          setUserMsg('');
          if (partnerOrgId !== null) {
            // Simulate submitting to database, shows us values submitted, resets form
            axios
              .post(`partner/orgs/${partnerOrgId}/v2`, values, {
                ...Utils.requestHeader(),
              })
              .then((res) => {
                let response = res.data;
                if (response.meta.code === 200) {
                  setSubmitting(false);
                  props.reloadData();
                  props.hideModal();
                  // TODO : use later
                  // subscribeWithOrgIds([response.data]);
                } else {
                  setVariant('error');
                  setUserMsg(response?.meta?.userMsg);
                  setSubmitting(false);
                }
              })
              .catch(function (error) {
                setVariant('error');
                setUserMsg(error?.message);
                setSubmitting(false);
              });
          } else setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          handleBlur,
          handleSubmit,
          handleChange,
          isSubmitting,
          isValid,
          dirty,
          shortName,
        }) => (
          <>
            <Form className="modal-form org-short-name-text" onSubmit={handleSubmit}>
              <TextField
                required={true}
                removebottommargin="true"
                removetopmargin="true"
                label=""
                placeholder={constants.CUSTOMER_NAME_FIELD}
                name="orgName"
                type="text"
                onChange={(e) => {
                  handleChange(e);
                  setFieldTouched('shortName', false); 
                  setFieldValue(
                    'shortName',
                    ''
                  );
                  setIsFieldDisabled(true);
                  setShortNameExistsError(false);
                }}
                onBlur={(e) => {
                  callOrgShortNameApi(values);
                }}
              />

                                <TextField
                                  required={true}
                                  label=""
                                  placeholder={constants.ORG_SHORT_NAME}
                                  name="shortName"
                                  type="text"
                                  disabled={isFieldDisabled}
                                  hideError={true}
                                  onChange={(e) => {
                                    validateExistingName(
                                      e.target.value,
                                      setFieldValue
                                    );
                                  }}
                                />

                                  <div className="org-text-hint">
                                  <div>
                                  {shortNameExistsError && 
                                  <div className="error error-parent display-f m-t-15-m">
                                    <AiOutlineExclamationCircle size={12} style={{ color: '#dc3545' }} />
                                    <div className='short-text-error'>
                                   {constants.ORG_SHORT_EXISTS}
                                      </div>
                                  </div>
                                  }
                                   {touched?.shortName && errors?.shortName && (
                                     <div className='display-f'>
                                     <div>
                                       <AiOutlineExclamationCircle size={12} style={{ color: '#dc3545' }} />
                                     </div>
                                     <div className="short-text-error">
                                     <div className="error m-t-3">{errors.shortName}</div>
                                     </div>
                                   </div>
                                  )}
                                  {!(touched?.shortName && errors?.shortName || shortNameExistsError) && (
                                      <div className='display-f'>
                                        <div className='m-l-30'>
                                          <AiOutlineExclamationCircle size={15} stroke="#7D8BA1" />
                                        </div>
                                        <div>
                                          <div className='new-c-wdt-l'>{constants.ORG_SHORT_NAME_HINT}</div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>

              <div className="row">
                <div className="col position-relative address-with-reset">
                  <div>
                    <Autocomplete
                      name="displayDetails"
                      placeholder=""
                      apiKey={process.env.REACT_APP_GOOGLE_PLACE_URL}
                      onChange={(evt) => {
                        if (!evt?.target?.value) {
                          setFieldValue('zipcode', '');
                          setFieldValue('country', '');
                          setFieldValue('city', '');
                          setFieldValue('state', '');
                          setFieldValue('addressLine1', '');
                        }
                      }}
                      onBlur={handleBlur}
                      onKeyDown={(e) => disableInput(e, values)}
                      id="displayDetails"
                      // onFocus={
                      //   (e) => e.target.setAttribute("autoComplete", "none")
                      // }
                      options={{
                        fields: [
                          'formatted_address',
                          'geometry',
                          'name',
                          'address_components',
                        ],
                        strictBounds: false,
                        types: ['address'],
                      }}
                      onPlaceSelected={(place) => {
                        if(place?.address_components?.length){
                        setFieldValue('lat', place?.geometry?.location?.lat());
                        setFieldValue('lng', place?.geometry?.location?.lng());

                        let addressComponent,
                          addressComponentType,
                          rawzipcode,
                          rawcountry,
                          rawcity,
                          rawstate,
                          rawaddline;
                        for (
                          let i = 0;
                          i < place?.address_components?.length;
                          i++
                        ) {
                          addressComponent = place.address_components[i];
                          let j = 0;
                          addressComponentType = addressComponent.types[j];
                          if (addressComponentType === 'postal_code') {
                            rawzipcode = addressComponent.long_name
                              ? addressComponent.long_name
                              : '';
                          }
                          if (addressComponentType === 'country') {
                            rawcountry = addressComponent.long_name
                              ? addressComponent.short_name
                              : '';
                          }
                          if (
                            addressComponentType ===
                            'administrative_area_level_1'
                          ) {
                            rawstate = addressComponent.long_name
                              ? addressComponent.short_name
                              : '';
                          }
                          if (addressComponentType === 'locality') {
                            rawcity = addressComponent.long_name
                              ? addressComponent.long_name
                              : '';
                          }

                          if (
                            addressComponentType === 'street_number' ||
                            addressComponentType === 'route' ||
                            addressComponentType === 'neighborhood' ||
                            addressComponentType.includes('sublocality')
                          ) {
                            rawaddline = `${rawaddline ? rawaddline : ''}${
                              addressComponent.long_name
                            }, `;
                          }
                        }
                        setFieldValue('zipcode', rawzipcode);
                        setFieldValue('country', rawcountry);
                        setFieldValue('city', rawcity);
                        setFieldValue('state', rawstate);
                        setFieldValue('addressLine1', rawaddline);
                        setFieldValue(
                          'displayDetails',
                          (rawaddline ? rawaddline : '') +
                            (rawcity ? rawcity + ', ' : '') +
                            (rawstate ? rawstate + ', ' : '') +
                            (rawcountry ? rawcountry + ', ' : '') +
                            (rawzipcode ? rawzipcode : '')
                        );

                        if(place?.geometry?.location?.lat() && place?.geometry?.location?.lng()){
                          var config = {
                            method: 'get',
                            url: `https://maps.googleapis.com/maps/api/timezone/json?location=${place?.geometry?.location?.lat()},${place?.geometry?.location?.lng()}&timestamp=${
                              new Date().getTime() / 1000
                            }&language=es&key=${
                              process.env.REACT_APP_GOOGLE_TIMEZONE_API_KEY
                            }`,
                            headers: {},
                          };

                          fetch(config?.url)
                          .then(function (response) {
                            return response.json();
                          })
                          .then(function (json) {
                            if (json?.status === 'OK') {
                              const data = zoneTimeList?.find(
                                (zone) => zone?.location === json?.timeZoneId
                              );
                              setCurrentUserTimezone(data);
                              if (data) {
                                setFieldValue('timezone', data?.value);
                                setLocHelperText(
                                  constants.LOCATION_TIMEZONE_ORGANIZATION_MESSAGE
                                );
                              } else {
                                setLocHelperText(
                                  constants.AUTO_TIMEZONE_FAIL_MESSAGE
                                );
                                setFieldValue('timezone', '');
                              }
                              setVisibleLocHelperText(true);
                            } else {
                              setVisibleLocHelperText(true);
                              setLocHelperText(
                                constants.AUTO_TIMEZONE_FAIL_MESSAGE
                              );
                            }
                          });
                        }
                      }
                      }}
                      className={`form-control shadow-none autoaddress ${
                        touched.displayDetails &&
                        (errors.addressLine1 || !values.displayDetails) &&
                        'is-invalid'
                      } ${values.addressLine1 && 'has-value'}`}
                    />
                    {!document.getElementById("displayDetails")?.value ?
                    <span className="placeholder-wrapper required">
                      {constants.ADDRESS_FIELD}
                    </span> : null }
                    {values.addressLine1 ? 
                      <span className={`reset-address-wrapper`}>
                        <IoClose size={15}
                          onClick={()=> {  
                          setFieldValue('zipcode', '');
                          setFieldValue('country', '');
                          setFieldValue('city', '');
                          setFieldValue('state', '');
                          setFieldValue('addressLine1', '');   
                          setFieldValue('displayDetails', ''); 
                          document.getElementById("displayDetails").value='';
                          setFieldTouched('displayDetails', true, false);
                        }}/>
                      </span> : null 
                    }
                  </div>
                  <ErrorMessage
                    component="div"
                    name="addressLine1"
                    className="error"
                  />
                  {touched.displayDetails &&
                      (!values.addressLine1 ||
                      !values.city ||
                      !values.state ||
                      !values.country ||
                      !values.zipcode) && (
                      <div className="error">
                        {constants.ADDRESS_FIELD_ERROR_MESSAGE}
                      </div>
                    )}
                </div>
              </div>
              <div className="row">
                <div className="col position-relative">
                  <div>
                    <SelectField
                      isFromOrg={true}
                      name="timezone"
                      options={zoneTimeList}
                      label=""
                      defaultOptionLabel="Time Zone"
                      value={values.timezone}
                      onChange={(evebr) =>
                        changeTimezoneValue(evebr, setFieldValue)
                      }
                    />
                  </div>
                  <ErrorMessage
                    component="div"
                    name="timezone"
                    className="info-timezone"
                  />
                  {visibleLocHelperText ? (
                    <div className="info-timezone">
                      <PiWarningCircleBold
                        color={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--greyscale_56')}
                        size={16}
                      />
                      <div className="message-info">{locHelperText}</div>
                    </div>
                  ) : null}
                </div>
              </div>

              <TextField
                beforeinputicon={<BiWorld size={12} />}
                removebottommargin="true"
                removetopmargin="true"
                label=""
                placeholder={constants.WEBSITE_NAME_FIELD}
                name="orgWebsite"
                type="text"
              />

              <TextField
                beforeinputicon={<AiOutlineLinkedin size={12} />}
                removebottommargin="true"
                removetopmargin="true"
                label=""
                placeholder={constants.LINKEDIN_PROFILE_NAME_FIELD}
                name="orgLinkedInProfile"
                type="text"
              />

              <Form.Group as={Row} className="mt-3">
                <Col>
                  <PrimaryButton
                    className="btn btn-primary w-100"
                    type="submit"
                    disabled={
                      !(dirty && isValid) ||
                      currentUserTimezone === undefined ||
                      !values.city ||
                      !values.state ||
                      !values.zipcode ||
                      !values.country || shortNameExistsError
                    }
                    loader={isSubmitting}
                    height="44px"
                    fontSize="0.875rem"
                  >
                    {constants.NEW_CUSTOMER_ORG_SUBMIT_BUTTON_TITLE}
                  </PrimaryButton>
                </Col>
              </Form.Group>
            </Form>

            {/* Show error messages */}
            <SiteToast
              title={
                variant === 'error'
                  ? constants.ERROR_TOAST_TITLE
                  : constants.SUCCESS_TOAST_TITLE
              }
              show={!!userMsg}
              body={userMsg}
              variant={variant}
              position="top-center"
            />
          </>
        )}
      </Formik>
    </section>
  );
}

export default NewOrganization;
