import Modal from 'react-bootstrap/Modal';
import { Container, Col, Row } from 'react-bootstrap';
import { RxCross1, RxCross2 } from 'react-icons/rx';
import { AiOutlineSearch } from 'react-icons/ai';
import { BsFillMicFill } from 'react-icons/bs';
import { Utils, constants } from '../../helpers';
import { useEffect, useState } from 'react';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import axios from 'axios';
import { SiteSpinner, PrimaryButton } from '../../components/common';
import { useNavigate } from 'react-router';
import './searchaimodal.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TagInSearch } from '../../assets/images/TagInSearch.svg';
import { ReactComponent as Sparkles } from '../../assets/images/Sparkles.svg';

const SearchAiModal = ({
  showModal,
  hideModal,
  modalHeader = false,
  modalTitle = '',
  isMic,
  searchModal,
  custOrgData,
  aiSearchLabel,
  ...props
}) => {
  const [searchHistoryList, setSearchHistoryList] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const [searchInput, setSearchInput] = useState('');
  useEffect(() => {
    if(searchModal){
      getSearchHistory();
    }
  }, [searchModal]);

  useEffect(() => {
    setSearchInput(isMic ? (transcript !== '' ? transcript : '') : '');
  }, [transcript]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onKeyDownChange = (e) => {
    if (e != undefined) {
      if (
        e.key === 'Enter' &&
        searchInput !== '' &&
        searchInput?.replace(/\s/g, '').length
      ) {
        const selectedText = searchInput;
        setSearchInput('');
        hideModal(selectedText);

        navigate(
          `/events.html?searchtext=${selectedText}`
        );
      }
    }
  };

  const onSearchClick = (e) => {
    if (e != undefined) {
      if (
        searchInput !== '' &&
        searchInput?.replace(/\s/g, '').length
      ) {
        const selectedText = searchInput;
        setSearchInput('');
        hideModal(selectedText);

        navigate(
          `/events.html?searchtext=${selectedText}`
        );
      }
    }
  };

  const onHandleChange = (e) => {
    if (e != undefined) {
      if (e?.target?.value === '') {
        resetTranscript();
      }
      setSearchInput(e?.target?.value);
    }
  };

  const handleListing = (e) => {
    SpeechRecognition.stopListening();
    resetTranscript();
    SpeechRecognition.startListening({
      interimResults: true,
    });
  };

  const handleReset = (e) => {
    if(e === true){
      hideModal(true);
    }else{
      let text = e?.target?.innerText;
      let cleanedText = text?.replace(/['“”']/g, '');  
      e?.preventDefault();
      e?.stopPropagation();
      SpeechRecognition.stopListening();
      resetTranscript();
      hideModal(cleanedText);
    }
  };

  const deleteSearchItem = async (data, e) => {
    e.stopPropagation();
    if (data?.searchId) {
      const itemList = [...searchHistoryList];
      itemList.forEach((searchItem, i) => {
        if (searchItem?.searchId === data?.searchId) {
          itemList[i].isDelete = true;
          itemList[i].isSelectable = false;
        } else {
          itemList[i].isDelete = false;
        }
      });
      setSearchHistoryList(itemList);
      let result = await axios.delete(
        `/timeline/orgs/${custOrgData?.orgId}/search/history/${data?.searchId}`,
        Utils.requestHeader()
      );
      if (parseInt(result?.status) === 200) {
        getSearchHistory();
      }
    }
  };

  const getSearchHistory = async () => {
    let response = await axios.get(
      `timeline/orgs/${custOrgData?.orgId}/search/history`,
      Utils.requestHeader()
    );
    const responseData = response?.data;
    if (responseData?.meta?.code === 200) {
      setSearchHistoryList(responseData?.data?.searches);
    }
  };

  const gotoSearchFillter = (e, data) => {
    e.stopPropagation();
    handleReset(e);
    navigate(`/events.html?searchtext=${data.text}`);
  };

  return (
    <div className={`modal-wrapper`}>
      <Modal
        show={showModal?.toString() === 'true' ? showModal : false}
        onHide={(e) => handleReset(e)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={true}
        dialogClassName={'modal-dialog modal-fullscreen bg-color-search-pop-up'}
      >
        {!modalHeader && (
          <Modal.Header>
            <Modal.Title className={`modal-title`}>{modalTitle}</Modal.Title>
            <RxCross1
              className="events-ai-site-modal-close right-serach"
              size={20}
              onClick={(e) => handleReset(true)}
            />
          </Modal.Header>
        )}
        <Modal.Body>
          <Container>
            <Row className="justify-content-md-center mt-5">
              <Col lg="12">
                <div className="search-dashboard-container">
                  <div className="serach-box search-modal">
                    <AiOutlineSearch
                      size={25}
                      fill={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--greyscale_40')}
                    />
                    <div className="textbox">
                      <Col>
                        <Row className="search-label-style">
                          <span>{aiSearchLabel}</span>
                          <span className={aiSearchLabel === 'Duclo AI Search' ? 
                          'tag-in-search-duclo' : 'tag-in-search-oncloud'}>
                            <TagInSearch />
                          </span>
                          {windowWidth > 540 && (
                            <span>
                            <PrimaryButton
                              className="btn btn-primary w-100 event-search-button"
                              height="56px"
                              onClick={(e) => onSearchClick(e)
                              }                            >
                              <span className='icon-sparkles'><Sparkles /></span>
                              <span className='icon-style'>{constants.EVENT_AI_SEARCH_BUTTON_LABEL}</span>
                            </PrimaryButton>
                            </span>
                          )}
                        </Row>
                        <Row>
                          <input
                            value={searchInput}
                            onChange={(e) => onHandleChange(e)}
                            onKeyDown={(e) => onKeyDownChange(e)}
                            style={{ border: 'none', width: '100%' }}
                            className={`form-control require shadow-none input-style-event ${
                              searchInput ? 'has-value' : ''
                            }`}
                            placeholder={constants.SEARCH_DASHBOARD_SEARCH_TEXT}
                            autoComplete="off"
                            beforeinputicon=""
                            afterinputicon=""
                          />
                        </Row>
                        {windowWidth < 541 && (
                        <Row>
                          <span>
                            <PrimaryButton
                              className="btn btn-primary w-100 event-search-button"
                              height="56px"
                              onClick={(e) => onSearchClick(e)
                              }                            >
                              <span className='icon-sparkles'><Sparkles /></span>
                              <span className='icon-style'>{constants.EVENT_AI_SEARCH_BUTTON_LABEL}</span>
                            </PrimaryButton>
                          </span>
                        </Row>
                        )}
                      </Col>
                    </div>
                  </div>
                </div>
                {isMic && browserSupportsSpeechRecognition && (
                  <div>
                    <div className="search-label-container">
                      <div className="explore-text">
                        {transcript !== ''
                          ? listening
                            ? transcript
                            : searchInput
                          : t('SEARCH_DASHBOARD_EXPLORE')}
                      </div>
                      <div className="supported-lang">
                        {constants.SEARCH_DASHBOARD_SUPPORTED_LANG}
                      </div>
                    </div>
                    <div className="try-saying-something">
                      {transcript === ''
                        ? constants.SEARCH_DASHBOARD_TRY_SAYING
                        : ''}
                    </div>
                  </div>
                )}

                <div className="search-container-history">
                  <div className="search-history-label">
                    {constants.EVENT_AI_RECENT_SEARCH_LABEL}
                  </div>
                  {searchHistoryList && 
                  searchHistoryList?.slice(0, 4).map((data, index) => (
                      <div
                        onClick={(e) => gotoSearchFillter(e, data)}
                        key={index}
                        className="search-list-item"
                      >
                        <div className="search-history-text" key={index}>
                          {data.text}
                        </div>
                        {data?.isSelectable ? (
                          <RxCross2
                            className="site-modal-close right-serach"
                            size={18}
                            onClick={(e) => deleteSearchItem(data, e)}
                          />
                        ) : (
                          data?.isDelete && (
                            <SiteSpinner
                              height="30px"
                              width="30px"
                            ></SiteSpinner>
                          )
                        )}
                      </div>
                    ))}
                </div>

                {isMic && browserSupportsSpeechRecognition && (
                  <div
                    onClick={(e) => handleListing(e)}
                    className="voice-conatiner"
                  >
                    <div
                      className={`outer-view ${
                        listening ? 'voice-listening' : ''
                      }`}
                    >
                      <div className="voice-recognition-btn">
                        <BsFillMicFill
                          size={25}
                          fill={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--brand_white')}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      {/* TODO - will work on this after demo 2 for showing error messages globally
       <SiteToast title="Error" show={!!errorMsg} body={errorMsg} onDelay={() => } /> */}
    </div>
  );
};

export default SearchAiModal;
