import { useEffect, useRef, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
// import InfiniteScroll from 'react-infinite-scroll-component';

import axios from 'axios';
import moment from 'moment';

import { Avatar, TextBlock, Spinner } from '../../components/common';
import { constants, Utils } from '../../helpers';

import { useCustomerOrgUsers } from '../../store/CustomerOrgUsersStore';
import { useOrganizations } from '../../store/OrganizationsStore';

import './ClipDetailsLogs.scss';

const activityMap = {
  DOWNLOAD: constants.CLIP_DETAILS_LOGS_DOWNLOADED_THIS_CLIP_TEXT,
  VIEW: constants.CLIP_DETAILS_LOGS_VIEWED_THIS_CLIP_TEXT,
};

const ClipDetailsLogs = ({ clipId, parentRef }) => {
  const [hasData, setHasData] = useState(false);
  const [logs, setLogs] = useState([]);
  const [clipLogEntries, setClipLogEntries] = useState([]);
  const [nextIndex, setNextIndex] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);

  const getCustomerOrgUsersData = useCustomerOrgUsers(
    (state) => state.getCustomerOrgUsersData
  );
  const getCustomerOrgData = useOrganizations(
    (state) => state.getCustomerOrgData
  );

  const customerOrgUsers = getCustomerOrgUsersData();

  const logsPerPage = 10;
  const orgDetails = getCustomerOrgData()[0];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const clipLogs = await fetchCurrentLogsByClipId(clipId);
      const logEntriesWithAvatars = await fetchAvatarsForLogEntries(clipLogs);

      if (Array.isArray(logEntriesWithAvatars)) {
        if (logEntriesWithAvatars?.length > 0) {
          // Sort the log entries in descending chronological order
          logEntriesWithAvatars.sort((a, b) => {
            if (a?.createdDate < b?.createdDate) {
              return 1;
            }

            if (a?.createdDate > b?.createdDate) {
              return -1;
            }

            return 0;
          });

          setLogs([...logEntriesWithAvatars]);
          setClipLogEntries([...logEntriesWithAvatars.slice(0, logsPerPage)]);
          setNextIndex((prevIndex) => prevIndex + logsPerPage);
          setHasMoreData(logEntriesWithAvatars.length > logsPerPage);
          setHasData(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCurrentLogsByClipId = async (clipId) => {
    let currentLogs = [];

    try {
      const res = await axios.get(
        `/clip/orgs/${orgDetails?.orgId}/clips/${clipId}/logs`,
        Utils.requestHeader()
      );
      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        if (
          !Array.isArray(responseData?.data?.logs) ||
          responseData.data.logs.length < 1
        )
          return;

        currentLogs = [...responseData?.data?.logs];
        setLogs([...currentLogs]);
      } else {
        if (res?.code) {
          console.error(`${res.code}: ${res.message}`);
        } else if (responseData?.data) {
          console.error(responseData?.data?.userMsg);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      return currentLogs;
    }
  };

  const fetchUserInitials = (accountId) => {
    const user = customerOrgUsers.find(
      (orgUser) => orgUser.accountId === accountId
    );

    return user
      ? `${user.firstName?.toUpperCase()?.charAt(0)}${user.lastName
          ?.toUpperCase()
          .charAt(0)}`
      : '';
  };

  const fetchAvatarsForLogEntries = async (logEntries) => {
    let logEntriesWithAvatars;

    try {
      if (!Array.isArray(logEntries) || logEntries.length < 1) {
        logEntriesWithAvatars = [];
      } else {
        const clipLogEntriesPromises = logEntries?.map(async (logEntry) => {
          const getAccountAvatarURLResponse = await Utils?.getAccountAvatarURL(
            orgDetails?.orgId,
            logEntry?.accountId
          );

          const initials = fetchUserInitials(logEntry?.accountId);

          return {
            ...logEntry,
            initials: initials,
            avatarURL: getAccountAvatarURLResponse?.avatarURL,
          };
        });

        if (clipLogEntriesPromises) {
          logEntriesWithAvatars = await Promise.all(clipLogEntriesPromises);
        } else {
          logEntriesWithAvatars = [];
        }
      }
    } catch (error) {
      console.error(error);
      logEntriesWithAvatars = [];
    } finally {
      return logEntriesWithAvatars;
    }
  };

  const loadMoreLogs = () => {
    if (clipLogEntries.length < logs.length) {
      let endCount;

      if (nextIndex + logsPerPage - 1 < logs.length) {
        endCount = nextIndex + logsPerPage - 1;
      } else {
        endCount = nextIndex + logsPerPage - logs.length;
      }

      const updatedList = [
        ...clipLogEntries,
        ...logs.slice(nextIndex, endCount),
      ];

      setClipLogEntries([...updatedList]);
      setNextIndex((prevIndex) => {
        if (prevIndex + logsPerPage < logs.length) {
          return prevIndex + logsPerPage;
        } else {
          return logs.length - prevIndex;
        }
      });
      setHasMoreData(true);
    } else {
      setHasMoreData(false);
    }
  };

  return (
    <Container className="clip-details-log-detail-container">
      {hasData ? (
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMoreLogs}
          hasMore={hasMoreData}
          loader={
            <div className="clip-details-log-detail-loading">
              {constants.CLIP_DETAILS_LOADING_TEXT}
            </div>
          }
        >
          {Array.isArray(clipLogEntries) &&
            clipLogEntries.map((log, logIndex) => (
              <Row key={`row-${logIndex}-${log.createdDate}`}>
                <div
                  key={`wrapper-${logIndex}-log.createdDate`}
                  className="clip-details-log-detail-wrapper"
                >
                  <TextBlock className="clip-details-log-detail-content">
                    <div className="clip-details-log-detail-avatar">
                      <Avatar
                        valueType={log?.avatarURL ? 'icon' : 'text'}
                        value={log?.avatarURL || log?.initials}
                        size="semiMedium"
                        avatarStyle="roundedCircle"
                      />
                    </div>
                    <div className="clip-details-log-detail-creator">
                      <div className="clip-details-log-detail-creator-date">
                        {moment(log?.createdDate)
                          .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                          .format('LL LTS z')}
                      </div>
                      <div className="clip-details-log-detail-creator-name">
                        {Utils.replaceStringValues(
                          activityMap[log?.activity?.toUpperCase()],
                          '$fullname',
                          log?.activityBy
                        )}
                      </div>
                    </div>
                  </TextBlock>
                </div>
              </Row>
            ))}
        </InfiniteScroll>
      ) : (
        <div className="clip-details-log-no-entries">
          {constants.CLIP_DETAILS_NO_LOGS_TEXT}
        </div>
      )}
    </Container>
  );
};

export default ClipDetailsLogs;
