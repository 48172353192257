import { Row, Col } from 'react-bootstrap';
import { Loader, PrimaryButton } from '../../../components/common';
import { Formik, Form } from 'formik';
import { TextField } from '../../../components/forms';
import * as Yup from 'yup';
import { useEffect, useState, useRef } from 'react';
import {
  step1of2IconLicense,
  licenseRemoveIcon,
  markIcon,
  errorIcon,
  invalidKeyIcon,
} from '../../../assets/images';
import { RxCross1 } from 'react-icons/rx';
import { constants } from '../../../helpers';
import { useOrganizations } from '../../../store/OrganizationsStore';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

const One = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [orderIdError, setOrderIdError] = useState('');
  const [invalidObjectError, setInvalidObjectError] = useState('');
  const [initialValueOfButton, setInitialValueOfButton] = useState(false);
  let [licenseListForOrderId, setLicenseListForOrderId] = useState();
  const [orderNoSelected, setOrderNoSelected] = useState();
  const scollToRef = useRef();
  const navigate = useNavigate();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const orgId = query.get('orgId');

  useEffect(() => {
    addKey(0);
  }, []);

  const getCustomerOrgData = useOrganizations(
    (state) => state.getCustomerOrgData
  );

  const validateOrderNo = Yup.object({
    orderNo: Yup.string().required(constants.ADD_LICENSE_ORDERNO_REQUIRED),
  });

  const validateOrderNoWithLicense = Yup.object({
    orderNo: Yup.string().required(constants.ADD_LICENSE_ORDERNO_REQUIRED),
    licenseKeys: Yup.array().of(
      Yup.string().optional(constants.ADD_LICENSE_ORDERNO_REQUIRED)
    ),
  });

  const [isStepDetailComplete, setIsStepDetailComplete] = useState(false);

  let [keys, setKeys] = useState([]);
  const [deletedKeys, setDeletedKeys] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [validLicenseKey, setValidLicenseKey] = useState(false);
  const [validLicenseKeyObj, setValidLicenseKeyObj] = useState({});
  const [keysUsed, setKeysUsed] = useState([]);
  const [keyListToPass, setKeyListToPass] = useState([]);

  const addKey = (index) => {
    const newKey = `Key${index}`;
    const updatedKeys = keys.filter((key) => key !== newKey);
    setKeys([...updatedKeys, newKey]);
    setInputValues({ ...inputValues, [newKey]: '' });
  };

  const handleInputChange = async (key, value, index, orderId) => {
    inputValues[key] = value;
    if (keys?.length > 0) {
      const filteredKeys = keys.filter(
        (key) => key !== null && key !== undefined
      );
      keys = filteredKeys;
    }
    const filteredInputValues = Object.fromEntries(
      Object.entries(inputValues).filter(
        ([key, _]) => !deletedKeys.includes(key)
      )
    );
    if (filteredInputValues) {
      setInputValues({ ...filteredInputValues });
    }
    if (!filteredInputValues || deletedKeys?.length === 0) {
      setInputValues({ ...inputValues, [key]: value });
    }

    if (value?.length === 41) {
      if (!keysUsed.includes(key)) {
        setKeysUsed([...keysUsed, key]);
      }
      if (keys?.length === 1 || !keysUsed.includes(key)) {
        const tempKey = keys[keys?.length - 1];
        const lastChar = tempKey?.charAt(tempKey?.length - 1);
        addKey(parseInt(lastChar) + 1);
      }
    }

    Object.fromEntries(
      Object.entries(filteredInputValues).filter(
        ([key, _]) => !deletedKeys.includes(key)
      )
    );

    if (filteredInputValues) {
      const moreThanOneKeys = Object.entries(filteredInputValues).length > 1;
      const maxKey = Object.keys(filteredInputValues)
        .filter((key) => /^Key\d+$/.test(key))
        .map((key) => Number(key.slice(3)))
        .reduce((max, num) => Math.max(max, num), -Infinity);

      if (filteredInputValues[`Key${maxKey}`] === '' && moreThanOneKeys) {
        let cleanListToPass = Object.entries(filteredInputValues)
          .filter(([key, _]) => key !== `Key${maxKey}`)
          .reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
          }, {});
        setKeyListToPass(cleanListToPass);
      } else {
        setKeyListToPass(filteredInputValues);
      }
    } else {
      setKeyListToPass([]);
    }

    Object.fromEntries(
      Object.entries(validLicenseKeyObj).filter(
        ([key, _]) => !deletedKeys.includes(key)
      )
    );

    await allValuesCheckForLicense(
      orderId,
      filteredInputValues,
      validLicenseKeyObj
    );
  };

  const allValuesCheckForLicense = async (
    orderId,
    filteredInputValues,
    validLicenseKeyObjArg
  ) => {
    for (const key1 of Object.keys(filteredInputValues)) {
      try {
        await checkLicenseValue(orderId, filteredInputValues[key1], key1);
      } catch (error) {
        console.error(`API call failed for key ${key1}:`, error);
      }
    }
    const allValuesAreTrue = Object.values(validLicenseKeyObjArg).every(
      (value) => value === true
    );
    const allValuesAreFalse = Object.values(validLicenseKeyObjArg).every(
      (value) => value === false
    );

    if (Object.keys(validLicenseKeyObjArg).length === 0) {
      setValidLicenseKey(false);
      return;
    }
    if (Object.keys(validLicenseKeyObjArg).length === 1) {
      if (allValuesAreTrue) {
        setValidLicenseKey(true);
      } else {
        setValidLicenseKey(false);
      }
    } else if (Object.keys(validLicenseKeyObjArg).length === 2) {
      if (allValuesAreTrue) {
        setValidLicenseKey(true);
      } else if (allValuesAreFalse) {
        setValidLicenseKey(false);
      } else {
        const keyWithFalseValue = Object.entries(validLicenseKeyObjArg).find(
          ([key, value]) => value === false
        );

        const maxKey = Object.keys(validLicenseKeyObjArg)
          .filter((key) => /^Key\d+$/.test(key))
          .map((key) => Number(key.slice(3)))
          .reduce((max, num) => Math.max(max, num), -Infinity);

        if (
          filteredInputValues[`Key${maxKey}`] === '' &&
          keyWithFalseValue &&
          `Key${maxKey}` === keyWithFalseValue[0]
        ) {
          setValidLicenseKey(true);
        } else {
          setValidLicenseKey(false);
        }
      }
    } else {
      if (allValuesAreTrue) {
        setValidLicenseKey(true);
      } else {
        const countFalseValues = Object.values(validLicenseKeyObjArg).filter(
          (value) => value === false
        ).length;
        const onlyOneKeyWithFalseValue = countFalseValues === 1;

        const maxKey = Object.keys(validLicenseKeyObjArg)
          .filter((key) => /^Key\d+$/.test(key))
          .map((key) => Number(key.slice(3)))
          .reduce((max, num) => Math.max(max, num), -Infinity);

        if (
          filteredInputValues[`Key${maxKey}`] === '' &&
          onlyOneKeyWithFalseValue
        ) {
          setValidLicenseKey(true);
        } else {
          setValidLicenseKey(false);
        }
      }
    }
  };

  const checkLicenseValue = async (orderId, value, key) => {
    try {
      const response = await axios.get(
        `/partner/orgs/${orgId}/orders/${orderId}/licenses/${value}`
      );
      if (
        response &&
        response.data &&
        response.data.meta &&
        response.data.meta.code === 200
      ) {
        validLicenseKeyObj[key] = true;
      } else {
        validLicenseKeyObj[key] = false;
      }
    } catch (error) {
      validLicenseKeyObj[key] = false;
    }
  };

  const removeKeyAndField = async (index, key) => {
    let updatedKeyListToPass = [];
    deletedKeys.push(key);
    if (keys?.length > 1) {
      delete keys[index];
      setKeys(keys);
      const updatedInputValues = { ...inputValues };
      delete updatedInputValues[`Key${index}`];
      setInputValues(updatedInputValues);
      updatedKeyListToPass = updatedInputValues;
    }

    delete validLicenseKeyObj[`Key${index}`];

    updatedKeyListToPass = Object.fromEntries(
      Object.entries(updatedKeyListToPass).filter(
        ([key, _]) => !deletedKeys.includes(key)
      )
    );

    if (updatedKeyListToPass) {
      const moreThanOneKeys = Object.entries(updatedKeyListToPass).length > 1;
      const maxKey = Object.keys(updatedKeyListToPass)
        .filter((key) => /^Key\d+$/.test(key))
        .map((key) => Number(key.slice(3)))
        .reduce((max, num) => Math.max(max, num), -Infinity);

      if (updatedKeyListToPass[`Key${maxKey}`] === '' && moreThanOneKeys) {
        let cleanListToPass = Object.entries(updatedKeyListToPass)
          .filter(([key, _]) => key !== `Key${maxKey}`)
          .reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
          }, {});
        setKeyListToPass(cleanListToPass);
      } else {
        setKeyListToPass(updatedKeyListToPass);
      }
    } else {
      setKeyListToPass([]);
    }

    let validLicenseKeyObjTemp = Object.fromEntries(
      Object.entries(validLicenseKeyObj).filter(
        ([key, _]) => !deletedKeys.includes(key)
      )
    );

    await allValuesCheckForLicense(
      orderNoSelected,
      updatedKeyListToPass,
      validLicenseKeyObjTemp
    );
  };

  const handleManualOrderClick = () => {
    setIsStepDetailComplete(true);
  };

  const handleManualOrderClickWithKey = async () => {
    await axios
      .put(`/partner/orgs/${orgId}/orders/${orderNoSelected}/licenses`)
      .then((response) => {
        if (response?.data?.meta?.code === 200) {
          props.selectedOrderNumber(orderNoSelected);
          props.nextstepEvent(true);
          props.licenseResponseSuccessValue(true);
          scollToRef.current.scrollIntoView();
        } else {
          if (response?.data?.meta?.userMsg) {
            setOrderIdError(response?.data?.meta?.userMsg);
          }
          return;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const checkOrderNumber = async (event, cb) => {
    if (!event) return;

    let returnValue = false;
    const orderNum = event.target?.value;

    setOrderNoSelected(orderNum);
    setInitialValueOfButton(true);

    try {
      const response = await axios.get(`/partner/licenses/orders/${orderNum}`);

      switch (parseInt(response?.data?.meta?.code)) {
        case 200:
          let responseLicenseList = response?.data.data.filter(
            (item) => item.orderId === orderNum
          );
          if (
            Array.isArray(responseLicenseList) &&
            responseLicenseList.length > 0
          ) {
            // TODO: once the API starts supporting multiple recipients, we will need to redo this
            // part of the code to handle an array of email addresses instead of just one email
            // address instance.
            props?.setRecipientEmailAddress(
              responseLicenseList[0]?.recipientEmail
            );
          }

          setLicenseListForOrderId(responseLicenseList);
          setOrderIdError('');
          cb && cb(event);
          returnValue = true;
          break;

        case 1833:
          setOrderIdError(constants.LICENSE_ORDER_LICENSES_ALREADY_ADDED);
          break;

        case 1844:
          setOrderIdError(constants.LICENSE_ORDER_NUMBER_DOES_NOT_EXIST);
          break;

        default:
          setOrderIdError(constants.LICENSE_INVALID_ORDER_NUMBER);
      }
    } catch (error) {
      setOrderIdError(error);
      console.error(error);
    } finally {
      return returnValue;
    } 
  };

  return (
    <div ref={scollToRef} className="ctn-box innner">
      {showLoader && <Loader className="white-background" />}
      <div className="title-container">
        <div id={'headerText'} className="header-view">
          <div className="titlename-header">
            {constants.LICENSES_PAGE_ADD_BUTTON_LABEL}
          </div>
          <RxCross1
            className="site-modal-close"
            onClick={() => props.hideModal()}
          />
          {document.addEventListener('keydown', function (event) {
            if (
              event.key === 'Escape' ||
              event.key === 'Esc' ||
              event.keyCode === 27
            ) {
              props.hideModal();
            }
          })}
        </div>
        <div>
          <div className="stepIcon">
            <img src={step1of2IconLicense} alt="step1Icon" />
          </div>
          {!isStepDetailComplete && props.flowValue == 'Flow1' ? (
            <div className="steptext">{constants.LICENSE_STEP_ONE_OF_TWO}</div>
          ) : (
            <div className="steptext">{constants.LICENSE_STEP_TWO_OF_TWO}</div>
          )}
        </div>
      </div>

      {!isStepDetailComplete && props.flowValue == 'Flow1' ? (
        <div>
          <Formik
            initialValues={{
              orderNo: '',
            }}
            validationSchema={validateOrderNo}
            onSubmit={async (values, { setFieldError, setFieldTouched }) => {
              await axios
                .put(`/partner/orgs/${orgId}/orders/${values.orderNo}/licenses`)
                .then((response) => {
                  if (response?.data?.meta?.code === 200) {
                    setOrderIdError('');
                    props.selectedOrderNumber(values.orderNo);
                    props.nextstepEvent(true);
                    props.licenseResponseSuccessValue(true);
                    scollToRef.current.scrollIntoView();
                  } else {
                    if (response?.data?.meta?.userMsg) {
                      setOrderIdError(response?.data?.meta?.userMsg);
                    }
                    return;
                  }
                })
                .catch((error) => {
                  setOrderIdError(error);
                  console.error(error);
                });
            }}
          >
            {({ values, handleChange, handleSubmit, isValid, dirty }) => (
              <Form className="modal-form" onSubmit={handleSubmit}>
                <div className="titlename">
                  {constants.LICENSE_ORDER_NUMBER}
                </div>
                <br></br>
                <>
                  <div>
                    <TextField
                      placeholder={
                        <>
                          {constants.LICENSE_ORDER_NO_TEXT}{' '}
                          <span
                            style={{
                              color: getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--error_64'),
                            }}
                          >
                            {' '}
                            *
                          </span>
                        </>
                      }
                      name="orderNo"
                      type="text"
                      removebottommargin="true"
                      removetopmargin="true"
                      value={values.orderNo}
                      onKeyDown={(event) => {
                        if (!event) return;

                        if (event.keyCode === 13) {
                          // handleChange(event);
                          checkOrderNumber(event, handleChange);
                        } else {
                          setOrderIdError(null);
                        }
                      }}
                    />
                    {values.orderNo && (
                      <div className="error errorStyle">{orderIdError}</div>
                    )}
                  </div>
                </>
                <Row className="justify-content-end">
                  {dirty && isValid && orderIdError?.length === 0 ? (
                    <Col
                      className="enterTextManullyHighlightedStyle"
                      onClick={() => handleManualOrderClick()}
                    >
                      {constants.LICENSES_ENTER_TEXT}
                    </Col>
                  ) : (
                    <Col className="enterTextManullyStyle" disabled>
                      {constants.LICENSES_ENTER_TEXT}
                    </Col>
                  )}

                  <Col className="text-end">
                    <PrimaryButton
                      className="addlicense-btn btn2"
                      fontSize="14px"
                      height="44px"
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_white')}
                      width="auto"
                      type="submit"
                      disabled={
                        (dirty && isValid && orderIdError?.length !== 0) ||
                        !initialValueOfButton ||
                        !orderNoSelected
                      }
                    >
                      {constants.LICENSE_CONTINUE_TEXT}
                    </PrimaryButton>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <div>
          <Formik
            initialValues={{
              orderNo: '',
              licenseKeys: [''],
            }}
            validationSchema={validateOrderNoWithLicense}
            onSubmit={async () => {
              const requestData = {
                orderId: orderNoSelected,
                licenseKeys: Object.values(keyListToPass),
              };
              await axios
                .put(`/partner/orgs/${orgId}/licenses`, requestData)
                .then((response) => {
                  if (response?.data?.meta?.code === 200) {
                    props.selectedOrderNumber(orderNoSelected);
                    props.nextstepEvent();
                    props.licenseResponseSuccessFlag(true);
                    props.licenseResponseData(response?.data?.data);
                    scollToRef.current.scrollIntoView();
                  } else {
                    if (response?.data?.meta?.desc) {
                      setInvalidObjectError(response?.data?.meta?.desc);
                    }
                    return;
                  }
                })
                .catch((error) => {
                  console.error(error);
                  return;
                });
            }}
          >
            {({ values, handleChange, handleSubmit, isValid, dirty }) => (
              <Form className="modal-form" onSubmit={handleSubmit}>
                <div className="titlename">
                  {constants.LICENSE_ORDER_NUMBER}
                </div>
                <>
                  <div className="LicenseTextFieldStyle">
                    <TextField
                      placeholder={
                        <>
                          {constants.LICENSE_ORDER_NO_TEXT}{' '}
                          <span
                            style={{
                              color: getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--error_64'),
                            }}
                          >
                            {' '}
                            *
                          </span>
                        </>
                      }
                      name="orderNo"
                      type="text"
                      removebottommargin="true"
                      removetopmargin="true"
                      value={values.orderNo}
                      onKeyDown={(event) => {
                        if (!event) return;

                        if (event.keyCode === 13) {
                          // handleChange(event);
                          checkOrderNumber(event, handleChange);
                        }
                      }}
                    />
                    {values.orderNo && (
                      <div className="error errorStyle">{orderIdError}</div>
                    )}
                  </div>
                </>
                <div className="titlename LicenseTextFieldStyle">
                  {constants.LICENSE_KEYS_TEXT}
                </div>
                <>
                  {keys?.map((key, index) => (
                    <div className="LicenseTextFieldStyle">
                      <div class="row">
                        {index < keys?.length - 1 ? (
                          <div className="col-md-11 removableLicenseTextFieldStyle">
                            <TextField
                              type="text"
                              id={key}
                              name={key}
                              value={inputValues[key]}
                              maxLength="41"
                              beforeinputicon={
                                licenseListForOrderId.some(
                                  (item) =>
                                    item.licenseKey === inputValues[key] &&
                                    item.orderId === values.orderNo
                                ) ? (
                                  <img
                                    src={markIcon}
                                    className="mark-icon-class"
                                  />
                                ) : (
                                  <img
                                    src={errorIcon}
                                    className="mark-icon-class"
                                  />
                                )
                              }
                              onChange={(e) => {
                                handleInputChange(
                                  key,
                                  e.target.value,
                                  index,
                                  values.orderNo
                                );
                              }}
                            />
                            {licenseListForOrderId.some(
                              (item) =>
                                item.licenseKey === inputValues[key] &&
                                item.orderId === values.orderNo
                            ) ? (
                              <></>
                            ) : (
                              <div className="marginBottomKey">
                                <img
                                  className="erroMessageIconStyle"
                                  src={invalidKeyIcon}
                                />
                                <span className="erroMessage">
                                  {constants.ADD_LICENSE_INVALID_MESSAGE}
                                </span>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="col-md-12">
                            <TextField
                              type="text"
                              id={key}
                              name={key}
                              value={inputValues[key]}
                              maxLength="41"
                              beforeinputicon={
                                inputValues?.length > 0 ? (
                                  licenseListForOrderId.some(
                                    (item) =>
                                      item.licenseKey === inputValues[key] &&
                                      item.orderId === values.orderNo
                                  ) ? (
                                    <img
                                      src={markIcon}
                                      className="mark-icon-class"
                                    />
                                  ) : (
                                    <img
                                      src={errorIcon}
                                      className="mark-icon-class"
                                    />
                                  )
                                ) : null
                              }
                              onChange={(e) => {
                                handleInputChange(
                                  key,
                                  e.target.value,
                                  index,
                                  values.orderNo
                                );
                              }}
                            />
                            {inputValues?.length > 0 ? (
                              licenseListForOrderId.some(
                                (item) =>
                                  item.licenseKeys === inputValues[key] &&
                                  item.orderNo === values.orderNo
                              ) ? (
                                <></>
                              ) : (
                                <div className="marginBottomKey">
                                  <img
                                    className="erroMessageIconStyle"
                                    src={invalidKeyIcon}
                                  />
                                  <span className="erroMessage">
                                    {constants.ADD_LICENSE_INVALID_MESSAGE}
                                  </span>
                                </div>
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        )}

                        <div className="col-md-1">
                          {index < keys?.length - 1 && (
                            <img
                              src={licenseRemoveIcon}
                              alt="Remove Icon"
                              className="licenseRemoveIconStyle"
                              onClick={() => removeKeyAndField(index, key)}
                              style={{ cursor: 'pointer' }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
                <Row className="justify-content-end">
                  {dirty && isValid && invalidObjectError?.length !== 0 && (
                    <div className="error errorStyle">
                      {constants.LICENSES_VALIDATION_FAIL_ERROR}
                    </div>
                  )}
                </Row>
                <Row className="justify-content-end">
                  {dirty && isValid && orderIdError?.length === 0 ? (
                    <Col>
                      <p
                        className="enterTextManullyHighlightedStyle"
                        onClick={() => handleManualOrderClickWithKey()}
                      >
                        {constants.LICENSES_ENTER_BY_ORDER_TEXT}
                      </p>
                    </Col>
                  ) : (
                    <Col className="enterTextManullyStyle">
                      {constants.LICENSES_ENTER_BY_ORDER_TEXT}
                    </Col>
                  )}

                  <Col className="text-end">
                    <PrimaryButton
                      className="addlicense-btn btn2"
                      fontSize="14px"
                      height="44px"
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_white')}
                      width="auto"
                      type="submit"
                      disabled={
                        (dirty &&
                          isValid &&
                          orderIdError?.length !== 0 &&
                          keyListToPass?.length !== 0) ||
                        !initialValueOfButton ||
                        !orderNoSelected ||
                        !validLicenseKey
                      }
                    >
                      {constants.LICENSE_CONTINUE_TEXT}
                    </PrimaryButton>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default One;
