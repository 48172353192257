import { useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '../../../../components/forms';
import {
  DeviceStatusEnum,
  DeviceTypeEnum,
  constants,
} from '../../../../helpers';
import { PrimaryButton } from '../../../../components/common';
import { ReactComponent as InfoCircleIcon } from '../../../../assets/images/Info-circle.svg';
import DummyImageIcon from '../../../../assets/images/dummy-snapshot.svg';
import { moveDeviceToHubAPI } from '../addDeviceAPI';
import { getSelectedOrganization } from '../../../../store/reducers/AccountReducer';
import {
  getAllScannedDevices,
  getDevicesListOfCurrOrg,
  setAddedAndClaimedDeviceId,
  setDevicesListOfCurrOrg,
} from '../../../../store/reducers/NVRDeviceReducer';

const MoveDeviceControl = ({
  selectedDevice,
  nvrDeviceData,
  hubId,
  deviceSelectHandler,
  selectedDeviceLoaderHandler,
  errorMsgHandler,
  fetchDeviceListForOrg,
}) => {
  const formikRef = useRef();
  const devicesListOfCurrOrg = useSelector(getDevicesListOfCurrOrg);
  const allScannedDevices = useSelector(getAllScannedDevices);
  const dispatch = useDispatch();
  const orgDetails = useSelector(getSelectedOrganization);

  useEffect(() => {
    formikRef?.current?.resetForm();
  }, [selectedDevice?.channel]);

  const getDeviceIdByMac = (macAddress, serialNo) => {
    const device = devicesListOfCurrOrg.find(
      (a) =>
        a.macAddress &&
        macAddress &&
        a.macAddress.toUpperCase() === macAddress.toUpperCase() &&
        a.serialNo === serialNo
    );
    return device?.deviceId;
  };

  const handleMoveDeviceToHub = async (values) => {
    try {
      errorMsgHandler('');
      selectedDeviceLoaderHandler(true);
      let payload = [];
      if (nvrDeviceData.deviceType.toUpperCase() === DeviceTypeEnum.NVR) {
        const parentDevice = allScannedDevices.find(
          (a) =>
            a.macAddress &&
            selectedDevice.macAddress &&
            a.macAddress.toUpperCase() ===
              selectedDevice.macAddress.toUpperCase()
        );
        payload = {
          deviceId: getDeviceIdByMac(
            parentDevice.macAddress,
            parentDevice.serialNumber
          ),
          locationId: nvrDeviceData.locationId,
          deviceName: parentDevice.serialNumber,
          areaId: selectedDevice.areaId,
          channel: parentDevice.channel,
          channels: parentDevice?.channels?.map((ch) => ({
            deviceId: getDeviceIdByMac(ch.macAddress, ch.serialNumber),
            locationId: nvrDeviceData?.locationId,
            deviceName:
              selectedDevice.channel === ch.channel
                ? values.deviceName || ch.serialNumber
                : ch.serialNumber,
            areaId: selectedDevice.areaId,
            channel: ch.channel,
          })),
        };
      } else {
        payload = {
          deviceId: selectedDevice.deviceId,
          locationId: nvrDeviceData.locationId,
          deviceName: values.deviceName || selectedDevice.serialNumber,
          areaId: selectedDevice.areaId,
          channel: selectedDevice.channel,
          channels: selectedDevice?.channels?.map((ch) => ({
            deviceId: getDeviceIdByMac(ch.macAddress, ch.serialNumber),
            locationId: nvrDeviceData.locationId,
            deviceName: ch.serialNumber,
            areaId: selectedDevice.areaId,
            channel: ch.channel,
          })),
        };
      }
      const deviceResponse = await moveDeviceToHubAPI(
        orgDetails,
        payload,
        hubId
      );
      if (deviceResponse?.meta?.code === 200) {
        const data = deviceResponse?.data;
        const updatedDevices = devicesListOfCurrOrg.map((item) => {
          if (item.deviceId === selectedDevice.deviceId) {
            return {
              ...item,
              ...data.channels.find(
                (x) => x.deviceId === selectedDevice.deviceId
              ),
            };
          } else {
            return item;
          }
        });
        dispatch(setAddedAndClaimedDeviceId(selectedDevice.deviceId));
        dispatch(setDevicesListOfCurrOrg(updatedDevices));
        deviceSelectHandler(selectedDevice.deviceId);
        fetchDeviceListForOrg();
      } else {
        errorMsgHandler(deviceResponse?.userMsg);
      }
      selectedDeviceLoaderHandler(false);
    } catch (error) {
      selectedDeviceLoaderHandler(false);
      console.error('ERROR: ', error);
    }
  };

  const getHubName = (gatewayId) => {
    const device = devicesListOfCurrOrg.find(
      (item) => item.deviceId === gatewayId
    );
    return device.deviceName;
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        deviceName: selectedDevice?.deviceName,
      }}
      onSubmit={async (values) => {
        handleMoveDeviceToHub(values);
      }}
      innerRef={formikRef}
    >
      {({ isValid, handleSubmit, handleChange }) => (
        <Form className="modal-form" onSubmit={handleSubmit}>
          {selectedDevice?.deviceAuthStatus ===
            DeviceStatusEnum.ALREADY_ASSIGNED && (
            <div className="warning-container">
              <div className="warning-message">
                <InfoCircleIcon />
                {constants.DEVICES_ALREADY_ASSIGNED_TEXT.replace(
                  '${deviceId}',
                  getHubName(selectedDevice.gatewayId)
                )}
              </div>
            </div>
          )}
          <div className="device-image-wrapper">
            <img src={DummyImageIcon} alt="DummyImageIcon" />
          </div>
          <div className="fieldTitle mb-1 mt-3">
            {constants.DEVICES_DEVICE_NAME_TEXT}
          </div>
          <div className="">
            <TextField
              placeholder={constants.DEVICES_DEVICE_NAME_TEXT}
              name="deviceName"
              type="text"
              removebottommargin="true"
              removetopmargin="true"
              onChange={handleChange}
            />
          </div>
          <PrimaryButton
            className="mt-4"
            fontSize="14px"
            backgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            height="44px"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--brand_white'
            )}
            disabled={!isValid}
          >
            {constants.MOVE_DEVICE_TO_BUTTON_TEXT} {nvrDeviceData.deviceName}
          </PrimaryButton>
        </Form>
      )}
    </Formik>
  );
};

export default MoveDeviceControl;
