import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RiErrorWarningLine } from 'react-icons/ri';
import { RxCross1, RxPlus } from 'react-icons/rx';
import { PrimaryButton, SiteSpinner } from '../../../../components/common';
import { Utils, constants } from '../../../../helpers';
import { useSelector } from 'react-redux';
import { getAllMqttDataFromResponse } from '../../../../store/reducers/AccountReducer';
import { ReactComponent as CrossIcon } from '../../../../assets/images/icons/crossWithRound.svg';
import { ReactComponent as InvalidKeyIcon } from '../../../../assets/images/invalidKeyIcon.svg';
import publishDeviceSetting, {
  deleteDeviceSetting,
} from '../shared/PublishDeviceSettings';
import { getStreamBitmapData } from '../../../../store/reducers/StreamingReducer';
import nosnapshot from '../../../../assets/images/nosnapshot.svg';
import Line from './Line';
import { LineDirectionEnum } from '../../../../helpers/enums';

const LineCrossings = ({
  accountId,
  deviceId,
  deviceInfos,
  devicePublishId,
  uuid,
  refResolution,
  vehicleDetection,
  personDetection,
  capVirtualLine,
  lineCrossingsData,
  hideModelPopup,
}) => {
  const initialValues = {
    lineName: '',
    person: false,
    vehicle: false,
  };
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: initialValues,
  });
  const watchedFields = watch();
  const formRef = useRef();
  const expiredTime = 20;
  const resourceList = Utils.getDeviceSettingResource(7);
  const MAX_REGION_TO_BE_ADDED_COUNT =
    +capVirtualLine?.['virtual-line-index']?.max + 1 || 1;
  const colors = Utils.getAllColors();
  const [virtualLines, setVirtualLines] = useState(
    lineCrossingsData['virtual-lines'] || []
  );
  const [lines, setLines] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const [selectedLine, setSelectedLine] = useState(null);
  const publishPayload = {
    deviceId: deviceId,
    devicePublishId: devicePublishId,
    uuid: uuid,
    accountId: accountId,
    hubId: deviceInfos.gatewayId,
  };
  const [snapshot, setSnapshot] = useState('');
  const [imageWidth, setImageWidth] = useState(0);
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  const bitmapObject = useSelector(getStreamBitmapData);
  const [isDeleteTriggered, setIsDeleteTriggered] = useState(false);

  useEffect(() => {
    setVirtualLines(lineCrossingsData['virtual-lines'] || []);
  }, [lineCrossingsData?.['virtual-lines']]);

  useEffect(() => {
    const resource = allMqttData?.msg?.resource;
    if (resource?.includes(resourceList[6]) && resource?.includes(deviceId)) {
      const properties = allMqttData?.msg?.properties;
      const action = allMqttData?.msg?.action;
      if (action === 'error') {
        setRemainingTime(0);
        setErrorMsg(properties.desc);
      } else if (action === 'is') {
        setRemainingTime(0);
        if (isDeleteTriggered) {
          setIsDeleteTriggered(false);
        } else if (showLoader) {
          hideModelPopup();
        }
      }
    }
  }, [allMqttData]);

  useEffect(() => {
    const updatedLines = lines.map((reg) => {
      return {
        ...reg,
        lineStyle: {
          ...reg.lineStyle,
          display: selectedLine?.lineColor === reg.lineColor ? 'block' : 'none',
        },
      };
    });
    setLines(updatedLines);
  }, [selectedLine]);

  useEffect(() => {
    const snapshotURL = fetchSnapshotCanvas(`canvas`, `video`);
    setSnapshot(snapshotURL);
  }, []);

  const fetchSnapshotCanvas = (typeCanvas, typeVideo) => {
    let canvas = document.getElementById(typeCanvas);
    let video = document.getElementById(typeVideo);
    const context = canvas?.getContext('2d');
    const bitmapObjectHeight = bitmapObject ? bitmapObject?.StreamHeight : 450;
    const bitmapObjectWidth = bitmapObject ? bitmapObject?.StreamWidth : 764;
    context?.drawImage(video, 0, 0, bitmapObjectWidth, bitmapObjectHeight);
    const data = canvas?.toDataURL('image/png');
    return data;
  };

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (remainingTime > 0) {
        setRemainingTime((prevTime) => prevTime - 1);
      } else {
        setShowLoader(false);
        clearInterval(timerInterval);
      }
    }, 1000);
    return () => {
      clearInterval(timerInterval);
    };
  }, [remainingTime]);

  useEffect(() => {
    if (refResolution && imageWidth) {
      const lineList = virtualLines.map((r) => {
        const dimensions = getNormalCoordinates(
          ...r['virtual-line-coordinates']?.split(',')
        );
        const index = r['virtual-line-index'];
        const color = colors[index];
        return {
          index: index,
          lineName: r['virtual-line-name'],
          person:
            r['virtual-line-obj-person'] === constants.PROPERTY_STATUS_TRUE,
          vehicle:
            r['virtual-line-obj-vehicle'] === constants.PROPERTY_STATUS_TRUE,
          lineDirection: getLineDirectionValue(r['virtual-line-direction']),
          lineColor: color,
          lineStyle: {
            border: `2px solid ${color}`,
          },
          isChanging: false,
          ...dimensions,
        };
      });
      if (virtualLines.length === 0) {
        setSelectedLine(null);
        setLines([]);
      }
      if (lineList.length) {
        setLines(lineList);
        const updatedSelectedLine = lineList.find(
          (region) => region.lineColor === selectedLine?.lineColor
        );
        updateLineAndForm(
          updatedSelectedLine
            ? updatedSelectedLine
            : lineList.length > 0
            ? lineList[0]
            : null
        );
      }
    }
  }, [imageWidth, virtualLines]);

  const handleAddLineClick = () => {
    // If there is any region without name then it should not allow to add new region
    const withoutName = lines.filter(
      (x) => x.lineName === '' || x.lineName === 'Line Name'
    );
    if (withoutName.length > 0) {
      formRef.current.requestSubmit();
      return;
    }
    const occupiedIndices = virtualLines.map((x) => x['virtual-line-index']);
    const index = Utils.getMinAvailableIndex(occupiedIndices);
    const color = colors[index];
    const initialLine = {
      startPoint: { x: 100, y: 100 },
      endPoint: { x: 200, y: 100 },
      lineName: 'Line Name',
      person: false,
      vehicle: false,
      lineDirection: LineDirectionEnum.LEFT,
      lineColor: color,
      lineStyle: {
        border: `2px solid ${color}`,
      },
      isChanging: false,
      index: index,
    };
    setLines([...lines, initialLine]);
    updateLineAndForm(initialLine);
  };

  const onLineChange = (coordinates) => {
    const updatedLines = lines.map((x) => {
      if (x.lineColor === selectedLine?.lineColor) {
        return { ...x, ...coordinates };
      } else {
        return x;
      }
    });
    setLines(updatedLines);
  };

  const getNextDirection = (direction) => {
    return (direction % 3) + 1;
  };

  const getLineDirectionKeyByValue = (value) => {
    switch (value) {
      case LineDirectionEnum.LEFT:
        return 'left';
      case LineDirectionEnum.RIGHT:
        return 'right';
      case LineDirectionEnum.BOTH:
        return 'both';
      default:
        return null;
    }
  };

  const getLineDirectionValue = (value) => {
    switch (value) {
      case 'left':
        return LineDirectionEnum.LEFT;
      case 'right':
        return LineDirectionEnum.RIGHT;
      case 'both':
        return LineDirectionEnum.BOTH;
      default:
        return null;
    }
  };

  const onLineDirectionChange = () => {
    const updatedLines = lines.map((x) => {
      if (x.lineColor === selectedLine?.lineColor) {
        return { ...x, lineDirection: getNextDirection(x.lineDirection) };
      } else {
        return x;
      }
    });
    setLines(updatedLines);
    const updatedSelectedLine = updatedLines.find(
      (x) => x.lineColor === selectedLine?.lineColor
    );
    if (updatedSelectedLine) {
      setSelectedLine({
        ...updatedSelectedLine,
        lineDirection: getNextDirection(selectedLine?.lineDirection),
      });
    } else {
      setSelectedLine({
        ...selectedLine,
        lineDirection: getNextDirection(selectedLine?.lineDirection),
      });
    }
  };

  const getActualCoordinates = (startPoint, endPoint) => {
    const divResolutionWidth =
      document.getElementById('img-snapshot')?.offsetWidth;
    const divResolutionHeight =
      document.getElementById('img-snapshot')?.offsetHeight;
    const scaleX = refResolution[0] / divResolutionWidth;
    const scaleY = refResolution[1] / divResolutionHeight;

    const actualStartPoint = {
      x: Math.round(startPoint.x * scaleX),
      y: Math.round(startPoint.y * scaleY),
    };

    const actualEndPoint = {
      x: Math.round(endPoint.x * scaleX),
      y: Math.round(endPoint.y * scaleY),
    };

    return { startPoint: actualStartPoint, endPoint: actualEndPoint };
  };

  const getNormalCoordinates = (SPx, SPy, EPx, EPy) => {
    const divResolutionWidth =
      document.getElementById('img-snapshot')?.offsetWidth;
    const divResolutionHeight =
      document.getElementById('img-snapshot')?.offsetHeight;
    const scaleX = divResolutionWidth / refResolution[0];
    const scaleY = divResolutionHeight / refResolution[1];

    const normalStartPoint = {
      x: SPx * scaleX,
      y: SPy * scaleY,
    };

    const normalEndPoint = {
      x: EPx * scaleX,
      y: EPy * scaleY,
    };

    return { startPoint: normalStartPoint, endPoint: normalEndPoint };
  };

  const getMsgProperties = (line) => {
    const index = colors.indexOf(line.lineColor) || 0;
    const jsonObject = {};
    const keyPrefix = `virtual-line`;
    jsonObject[`${keyPrefix}-index`] = index;
    jsonObject[`${keyPrefix}-index-enable`] = 'true';
    jsonObject[`${keyPrefix}-name`] = line.lineName;
    jsonObject[
      `${keyPrefix}-coordinates`
    ] = `${line.startPoint.x},${line.startPoint.y},${line.endPoint.x},${line.endPoint.y}`;
    jsonObject[`${keyPrefix}-obj-person`] = line.person ? 'true' : 'false';
    jsonObject[`${keyPrefix}-obj-vehicle`] = line.vehicle ? 'true' : 'false';
    jsonObject[`${keyPrefix}-direction`] = getLineDirectionKeyByValue(
      line.lineDirection
    );
    return jsonObject;
  };

  const saveLineHandler = (values) => {
    setErrorMsg('');
    setShowLoader(true);
    setRemainingTime(expiredTime);
    try {
      const linesWithUpdatedCoordinates = lines.find(
        (line) => line.lineColor === selectedLine?.lineColor
      );
      const actualCoordinates = getActualCoordinates(
        linesWithUpdatedCoordinates.startPoint,
        linesWithUpdatedCoordinates.endPoint
      );
      const updatedSelectedLine = {
        ...linesWithUpdatedCoordinates,
        ...values,
        lineDirection: selectedLine?.lineDirection,
      };

      const msgProperties = getMsgProperties({
        ...updatedSelectedLine,
        ...actualCoordinates,
      });
      publishDeviceSetting(msgProperties, resourceList[6], publishPayload);
      setSelectedLine(updatedSelectedLine);
    } catch (error) {
      console.log('error', error);
      setShowLoader(false);
    }
  };

  const handleLineClick = (line) => {
    updateLineAndForm(line);
  };

  const updateLineAndForm = (line) => {
    reset();
    setValue('lineName', line.lineName === 'Line Name' ? '' : line.lineName);
    setValue('person', line.person);
    setValue('vehicle', line.vehicle);
    setSelectedLine(line);
  };

  const handleRemoveZone = (e, line) => {
    e.stopPropagation();
    try {
      const currentIndex = colors.indexOf(line.lineColor);
      if (virtualLines.find((q) => q['virtual-line-index'] === currentIndex)) {
        setErrorMsg('');
        setShowLoader(true);
        setRemainingTime(expiredTime);
        const msgProperties = {
          'virtual-line-index': currentIndex,
        };
        deleteDeviceSetting(msgProperties, resourceList[6], publishPayload);
        setIsDeleteTriggered(true);
      } else {
        const updatedLines = lines.filter(
          (item) => item.lineColor !== line.lineColor
        );
        setLines(updatedLines);
        setSelectedLine(updatedLines.length > 0 ? updatedLines[0] : null);
        updateLineAndForm(updatedLines.length > 0 ? updatedLines[0] : null);
      }
    } catch (error) {
      console.log('error', error);
      setShowLoader(false);
      setRemainingTime(0);
    }
  };

  const handleErrorMsgClose = () => {
    setErrorMsg('');
  };

  return (
    <div className="component-container">
      <div className={`${showLoader ? 'disable-bg' : ''}`}></div>
      {showLoader && (
        <SiteSpinner
          height="50px"
          width="50px"
          styles={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            bottom: '50%',
            right: '50%',
            zIndex: 15,
          }}
        ></SiteSpinner>
      )}
      <div className="activity-zone-info">
        {constants.LINE_CROSSING_POPUP_TITLE_INFO.replace('${linecount}', `${MAX_REGION_TO_BE_ADDED_COUNT} ${MAX_REGION_TO_BE_ADDED_COUNT < 2 ? 'line': 'lines'}`)}
      </div>
      <div className="hr-devider"></div>
      {errorMsg && (
        <div className="mb-4 error-conatiner">
          <div className="error-message-container">
            <RiErrorWarningLine className="icon-warning-col" />
            <div className="">{errorMsg}</div>
          </div>
          <RxCross1
            onClick={() => handleErrorMsgClose()}
            className="icon-warning-col"
          />
        </div>
      )}
      <div className="modal-sub-heading">
        {constants.LINE_CROSSING_POPUP_CREATE_LINES}
      </div>
      <div className="activity-zone-info">
        {constants.LINE_CROSSING_POPUP_CREATE_LINES_INFO}
      </div>
      <div className="regions-container mt-3 mb-3">
        {lines.map((line, index) => (
          <PrimaryButton
            key={index}
            className={`region-btn ${
              line.lineColor === selectedLine?.lineColor && 'selected-region'
            }`}
            type="button"
            fontSize="12px"
            width="fit-content"
            lineHeight="16px"
            borderWidth="0"
            hoverBorderWidth="0"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--primary_40'
            )}
            hoverColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            hoverBackgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_96')}
            backgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_96')}
            onClick={() => handleLineClick(line)}
          >
            <div
              className="circle-dot"
              style={{ backgroundColor: line.lineColor }}
            ></div>
            {line.lineColor === selectedLine?.lineColor
              ? watchedFields.lineName || 'Line Name'
              : line.lineName}
            <CrossIcon
              className="cross-icon"
              onClick={(e) => handleRemoveZone(e, line)}
            />
          </PrimaryButton>
        ))}
        {lines.length < MAX_REGION_TO_BE_ADDED_COUNT && (
          <PrimaryButton
            className="add-zone-btn"
            type="button"
            width="112px"
            fontSize="12px"
            lineHeight="16px"
            borderWidth="0"
            hoverBorderWidth="0"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--primary_40'
            )}
            hoverColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            disabledBackgroundColor="transparent"
            disabledColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--greyscale_72')}
            hoverBackgroundColor="transparent"
            backgroundColor="transparent"
            onClick={handleAddLineClick}
          >
            <RxPlus className="plus-icon-btn" strokeWidth={'1.5px'} size={16} />
            {constants.ANALYTICS_SETTINGS_POPUP_ADD_LINE}
          </PrimaryButton>
        )}
      </div>
      <div className="snap-shot-container">
        <div className="live-snapshot">
          <img
            id="img-snapshot"
            src={snapshot}
            alt=""
            className="live-snapshot-image"
            onLoad={() => {
              setImageWidth(
                document.getElementById('img-snapshot')?.offsetWidth
              );
            }}
            onError={(event) => {
              event.target.src = nosnapshot;
            }}
          />
        </div>
        {selectedLine && (
          <Line
            containerWidth={
              document.getElementById('img-snapshot')?.offsetWidth
            }
            containerHeight={
              document.getElementById('img-snapshot')?.offsetHeight
            }
            selectedLine={selectedLine}
            onLineChange={onLineChange}
            onChangeDirection={onLineDirectionChange}
          />
        )}
        {lines.length > 0 && (
          <svg
            width={document.getElementById('img-snapshot')?.offsetWidth}
            height={document.getElementById('img-snapshot')?.offsetHeight}
            style={{ position: 'absolute', top: 0, zIndex: 10 }}
          >
            {lines
              .filter((line) => line.lineColor !== selectedLine?.lineColor)
              .map((line) => (
                <line
                  key={line.lineColor}
                  x1={line.startPoint.x}
                  y1={line.startPoint.y}
                  x2={line.endPoint.x}
                  y2={line.endPoint.y}
                  stroke={line.lineColor}
                  strokeWidth="2"
                />
              ))}
          </svg>
        )}
      </div>
      <form ref={formRef} onSubmit={handleSubmit(saveLineHandler)}>
        {selectedLine && (
          <div className="add-zone-container">
            <div className="mb-4 mt-4 zone-input-wrapper">
              <div className="placeholder-wrap">
                <input
                  {...register('lineName', { required: true, maxLength: 15 })}
                  defaultValue={selectedLine?.lineName}
                  className="input-box"
                  autoFocus
                />
                {!watchedFields.lineName && (
                  <div className="custom-placeholder">
                    {constants.LINE_CROSSING_POPUP_CREATE_LINES_PLACEHOLDER}
                    <span className="required">*</span>
                  </div>
                )}
              </div>
              {errors.lineName && (
                <div className="error">
                  {constants.LINE_CROSSING_POPUP_CREATE_LINE_ERROR}
                </div>
              )}
            </div>
            <div className="modal-sub-heading">
              {constants.ANALYTICS_SETTINGS_POPUP_DETECTION_TYPE}
            </div>
            <div className="activity-zone-info">
              {constants.ANALYTICS_SETTINGS_POPUP_DETECTION_TYPE_INFO}
            </div>
            <div className="checkbox-container mt-3">
              <div>
                <label className="checkbox-container-label" htmlFor={`person`}>
                  {constants.ANALYTICS_SETTINGS_POPUP_PERSON_LABEL}
                </label>
                {!personDetection && (
                  <div className="info-message-container">
                    <InvalidKeyIcon />
                    <div className="info-message">
                      {constants.ANALYTICS_SETTINGS_POPUP_PERSON_INFO_MESSAGE}
                    </div>
                  </div>
                )}
              </div>
              <input
                className="checkbox-container-input-checkbox"
                {...register('person')}
                id={`person`}
                type="checkbox"
                defaultChecked={selectedLine?.person}
                disabled={!personDetection}
              />
            </div>
            <div className="checkbox-container mt-3">
              <div>
                <label className="checkbox-container-label" htmlFor={`vehicle`}>
                  {constants.ANALYTICS_SETTINGS_POPUP_VEHICLE_LABEL}
                </label>
                {!vehicleDetection && (
                  <div className="info-message-container">
                    <InvalidKeyIcon />
                    <div className="info-message">
                      {constants.ANALYTICS_SETTINGS_POPUP_VEHICLE_INFO_MESSAGE}
                    </div>
                  </div>
                )}
              </div>
              <input
                className="checkbox-container-input-checkbox"
                {...register('vehicle')}
                id={`vehicle`}
                type="checkbox"
                disabled={!vehicleDetection}
                defaultChecked={selectedLine?.vehicle}
              />
            </div>
          </div>
        )}
        <PrimaryButton
          className="btn btn-primary mt-4"
          type="submit"
          width="100%"
          disabled={
            !selectedLine ||
            !watchedFields.lineName ||
            watchedFields?.lineName?.length > 15 ||
            (!watchedFields.person && !watchedFields.vehicle)
          }
        >
          {constants.ANALYTICS_SETTINGS_POPUP_SAVE}
        </PrimaryButton>
      </form>
    </div>
  );
};

export default LineCrossings;
