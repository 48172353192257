import { Link, useNavigate } from 'react-router-dom';
import { Button, Navbar } from 'react-bootstrap';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import {
  Compass,
  House,
  Nut,
  PersonCircle,
  UiRadiosGrid,
} from 'react-bootstrap-icons';
import { constants } from '../../helpers';
import 'react-pro-sidebar/dist/css/styles.css';

function Sidebar() {
  const navigate = useNavigate();

  var vmsManageOrg = JSON.parse(localStorage.getItem('vmsManageOrg'));

  const handleRemoveManageOrg = (e) => {
    localStorage.removeItem('vmsManageOrg');
    navigate('/manageOrganizations');
  };

  return (
    <>
      <Navbar collapseOnSelect expand="lg">
        <Navbar.Toggle aria-controls="duclo-sidebar" className="mt-3 mb-3" />

        <Navbar.Collapse id="duclo-sidebar" className="mt-3">
          <ProSidebar className="mt-5">
            <Menu iconShape="square">
              {vmsManageOrg !== null && vmsManageOrg !== undefined ? (
                <>
                  <MenuItem
                    icon={<House height="30" width="30" />}
                    className="mb-5"
                  >
                    <Button
                      variant="link"
                      className=""
                      onClick={(e) => {
                        handleRemoveManageOrg(e);
                      }}
                    >
                      {constants.SIDEBAR_MENU_HOME_LABEL}
                    </Button>
                  </MenuItem>
                  <MenuItem className="sidebar-org-name">
                    {vmsManageOrg.orgName}
                  </MenuItem>
                </>
              ) : (
                <SubMenu
                  title={constants.SIDEBAR_MENU_HOME_LABEL}
                  icon={<House height="26" width="26" />}
                >
                  <MenuItem>
                    <Link to="/manageOrganizations">
                      {constants.SIDEBAR_HOME_SUBMENU_MANAGE_ORG_LABEL}
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="/newOrganization">
                      {constants.SIDEBAR_HOME_SUBMENU_NEW_ORG_LABEL}
                    </Link>
                  </MenuItem>
                </SubMenu>
              )}

              <SubMenu
                title={constants.SIDEBAR_MENU_USERS_LABEL}
                icon={<PersonCircle height="26" width="26" />}
                className={
                  vmsManageOrg !== null && vmsManageOrg !== undefined
                    ? ''
                    : 'pe-none'
                }
              >
                <MenuItem>
                  <Link to="/users">
                    {constants.SIDEBAR_USERS_SUBMENU_MANAGE_LABEL}
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/newUser">
                    {constants.SIDEBAR_USERS_SUBMENU_NEW_USER_LABEL}
                  </Link>
                </MenuItem>
              </SubMenu>
              <MenuItem
                icon={<UiRadiosGrid height="26" width="26" />}
                className={
                  vmsManageOrg !== null && vmsManageOrg !== undefined
                    ? ''
                    : 'pe-none'
                }
              >
                <Link to="#">Devices</Link>
              </MenuItem>
              <SubMenu
                title={constants.SIDEBAR_LOCATION_AND_AREAS_LABEL}
                icon={<Compass height="26" width="26" />}
                className={
                  vmsManageOrg !== null && vmsManageOrg !== undefined
                    ? ''
                    : 'pe-none'
                }
              >
                <MenuItem>
                  <Link to="/locations">
                    {constants.SIDEBAR_LOCATION_AND_AREAS_SUBMENU_MANAGE_LABEL}
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/newLocation">
                    {constants.SIDEBAR_LOCATION_AND_AREAS_SUBMENU_NEW_LABEL}
                  </Link>
                </MenuItem>
              </SubMenu>
              <MenuItem
                icon={<Nut height="26" width="26" />}
                className={
                  vmsManageOrg !== null && vmsManageOrg !== undefined
                    ? ''
                    : 'pe-none'
                }
              >
                <Link to="#">{constants.SIDEBAR_MENU_SERVICES_LABEL}</Link>
              </MenuItem>
            </Menu>
          </ProSidebar>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default Sidebar;
