import { useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import {
  PrimaryButton,
  SiteModal,
  TextBlock,
} from '../../../../components/common';
import { constants, Utils } from '../../../../helpers';
import {
  HiCheckCircle,
  HiExclamationCircle,
  HiOutlineExclamationCircle,
} from 'react-icons/hi';
import useLicensesStore from '../../../../store/LicensesStore';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import publishDeviceSetting from '../shared/PublishDeviceSettings';
import { ToggleInput } from '../../../../components/forms';
import timezones from '../../../../data/support/timezone.json';
import moment from 'moment';
import { useEffect } from 'react';
import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField } from '@mui/material';
import './DevicePasswordBlock.scss';
import { useTranslation } from 'react-i18next';

const DevicePasswordBlock = ({
  orgId,
  orgName,
  deviceId,
  deviceName,
  deviceInfos,
  devicePublishId,
  accountId,
  uuid,
  updateStore,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const diffrenceTime = 7200000;
  const [variant, setVariant] = useState('danger');
  const [userMsg, setUserMsg] = useState('');
  const [showMsg, setShowMsg] = useState(false);
  const dayids = deviceInfos?.properties?.['fw-updt-day'];
  const [licenseattachedStatus, setLicenseattachedStatus] = useState(false);
  const [showDeviceRemoveModal, setShowDeviceRemoveModal] = useState(false);
  const [deviceRemovalStatus, setDeviceRemovalStatus] = useState(false);
  const [deviceRemoveSuccessModal, setDeviceRemoveSuccessModal] =
    useState(false);
  const [deviceFactoryResetModal, setDeviceFactoryResetModal] = useState(false);
  const [deviceReclaimModal, setDeviceReclaimModal] = useState(false);
  const [deviceClaimedStatus, setDeviceClaimedStatus] = useState(false);
  const [deviceRestartModal, setDeviceRestartModal] = useState(false);
  const [licenceAttachedModal, setLicenceAttachedModal] = useState(false);
  const [updateDayStaus, setUpdateDayStaus] = useState(false);
  const [selectedIds, setSelectedIds] = useState(
    dayids ? dayids.split(',') : ['1']
  );
  const [autoUpdateEnable, setAutoUpdateEnable] = useState(
    deviceInfos?.properties?.['fw-updt-type'] ===
      constants.UPDATE_FIRMWARE_MANUAL
  );
  const zoneTimeList = timezones?.data;
  const timezone = deviceInfos?.properties?.timezone;
  const dayDefault =
    selectedIds?.length > 1 && selectedIds?.length < 7
      ? constants.UPDATE_FIRMWARE_MULTIPLE
      : selectedIds?.length === 7
      ? constants.UPDATE_FIRMWARE_ALL_DAYS
      : selectedIds?.length === 1
      ? Utils.getWeekDays(selectedIds)?.find(
          (day) => day?.id?.toString() === selectedIds[0]
        )?.day
      : 'Sunday';
  const [selectDay, setselectDay] = useState(dayDefault);
  const cDate = moment
    .tz(
      moment(),
      timezone
        ? zoneTimeList?.find((zone) => zone?.value === timezone)?.location
        : moment.tz.guess()
    )
    .format('MMM DD, YYYY');
  const [startTime, setStartTime] = useState(
    new Date(
      cDate +
        ' ' +
        (deviceInfos?.properties?.['fw-updt-st']
          ? Utils.getHoursFromValue(deviceInfos?.properties?.['fw-updt-st'])
          : '00:00:00')
    ).getTime()
  );
  const [endTime, setEndTime] = useState(startTime + diffrenceTime);
  const [daysList, setDaysList] = useState(
    Utils.getWeekDays(dayids ? dayids.split(',') : ['1'])
  );
  const { getLicenses } = useLicensesStore();
  const deviceState = deviceInfos?.deviceStatus;
  const connectionState = deviceInfos?.connectionStatus;
  const curDeviceStatus = Utils.getDeviceStatus(deviceState, connectionState);
  const deviceFactoryReset =
    deviceState === constants.DEVICES_CLAIMED_DEVICE_STATUS &&
    connectionState === constants.DEVICES_OFFLINE_CONNECTION_STATUS;
  const resourceList = Utils.getDeviceSettingResource(6);
  const publishPayload = {
    deviceId: deviceId,
    devicePublishId: devicePublishId,
    uuid: uuid,
    accountId: accountId,
    hubId: deviceInfos.gatewayId,
  };

  useEffect(() => {
    deviceInfos?.properties?.['fw-updt-type'] &&
      setAutoUpdateEnable(
        deviceInfos?.properties?.['fw-updt-type'] ===
          constants.UPDATE_FIRMWARE_AUTO
      );
    deviceInfos?.properties?.['fw-updt-day'] &&
      setSelectedIds(deviceInfos?.properties?.['fw-updt-day'].split(','));
    if (deviceInfos?.properties?.['fw-updt-st']) {
      setStartTime(
        new Date(
          cDate +
            ' ' +
            (deviceInfos?.properties?.['fw-updt-st']
              ? Utils.getHoursFromValue(deviceInfos?.properties?.['fw-updt-st'])
              : '00:00:00')
        ).getTime()
      );
    }
  }, [deviceInfos]);

  useEffect(() => {
    if (updateDayStaus) {
      const dayIds = [];
      daysList &&
        daysList.forEach((days) => {
          if (days.isSelected) {
            dayIds.push(days.id);
          }
        });
      if (dayIds?.length > 1 && dayIds?.length < 7) {
        setselectDay(constants.UPDATE_FIRMWARE_MULTIPLE);
      } else if (dayIds?.length === 7) {
        setselectDay(constants.UPDATE_FIRMWARE_ALL_DAYS);
      } else if (dayIds?.length === 1) {
        const day =
          dayIds?.length === 1
            ? Utils.getWeekDays(dayIds)?.find((day) => day?.id === dayIds[0])
                ?.day
            : 'Sunday';
        setselectDay(day);
      }
      const status = {
        'fw-updt-day': dayIds?.join(','),
      };
      setSelectedIds(dayIds);
      setUpdateDayStaus(false);
      updateStore(status);
      publishDeviceSetting(status, resourceList[2], publishPayload);
    }
  }, [updateDayStaus]);

  const onDeleteDevice = () => {
    const currentLicenses = getLicenses();

    if (Array.isArray(currentLicenses) && currentLicenses.length > 0) {
      let licenses = currentLicenses.find(
        (licenses) => licenses.deviceId === deviceId
      );
      if (
        licenses?.licenseStatus?.toUpperCase() === 'ACTIVE' &&
        !licenses?.trial
      ) {
        setLicenceAttachedModal(true);
      } else {
        setShowDeviceRemoveModal(true);
      }
    } else {
      setShowDeviceRemoveModal(true);
    }
  };

  const deleteDeviceCall = async () => {
    try {
      const res = await axios.delete(
        `/device/orgs/${orgId}/devices/${deviceId}`,
        Utils.requestHeader()
      );
      if (parseInt(res?.status) === 202) {
        setShowDeviceRemoveModal(false);
        navigate(`/devices/listing.html?orgId=${orgId}`);
      }
    } catch (error) {
      // TODO : to print the error will update this later
      console.error(error);
    }
  };

  const reclaimApiCall = async () => {
    const res = await axios.put(
      `/device/orgs/${orgId}/devices/${deviceId}/reclaim`,
      {},
      Utils.requestHeader()
    );
    if (res?.data?.meta?.code === 200) {
      setDeviceReclaimModal(true);
    } else {
      // TODO: show error in SiteToast
      console.error('ERROR: ', res?.data?.meta?.userMsg);
    }
  };

  const selectedDay = (dayData) => {
    const dayListfirm = [...daysList];
    dayListfirm.map((data, i) => {
      if (dayData.id == data.id) {
        if (data.isSelected && selectedIds?.length != 1) {
          daysList[i].isSelected = false;
        } else {
          daysList[i].isSelected = true;
        }
      }
    });
    setDaysList(dayListfirm);
    setUpdateDayStaus(true);
  };

  const setStartEndTime = (epochValue, isTimeStart) => {
    if (isNaN(epochValue)) return;
    if (isTimeStart) {
      setStartTime(epochValue);
      setEndTime(epochValue + diffrenceTime);
      const status = {
        'fw-updt-st': Utils.getStartEndMin(
          moment(epochValue).format('DD MMM YYYY HH:mm'),
          true
        ).toString(),
        'fw-updt-et': Utils.getStartEndMin(
          moment(epochValue + diffrenceTime).format('DD MMM YYYY HH:mm'),
          false
        ).toString(),
      };
      updateStore(status);
      publishDeviceSetting(status, resourceList[2], publishPayload);
    } else {
      setEndTime(epochValue);
      setStartTime(epochValue - diffrenceTime);
      const status = {
        'fw-updt-st': Utils.getStartEndMin(
          moment(epochValue - diffrenceTime).format('DD MMM YYYY HH:mm'),
          true
        ).toString(),
        'fw-updt-et': Utils.getStartEndMin(
          moment(epochValue).format('DD MMM YYYY HH:mm'),
          false
        ).toString(),
      };
      updateStore(status);
      publishDeviceSetting(status, resourceList[2], publishPayload);
    }
  };

  const onUpdateFirmware = () => {
    const properties = {
      'manual-update': constants.PROPERTY_STATUS_TRUE,
    };
    //updateStore(properties);
    publishDeviceSetting(properties, resourceList[2], publishPayload, false);
  };

  const renderTimePicker = (timeValue, isStart) => {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopTimePicker
          //ampm={false}
          readOnly={false}
          onChange={(newValue) => {
            let epochValue = newValue?.unix() * 1000; // milliseconds
            setStartEndTime(epochValue, isStart);
          }}
          renderInput={(params) => (
            <TextField
              sx={{
                svg: {
                  display: getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--greyscale_40'),
                },
                input: {
                  color: getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--brand_black'),
                  fontWeight: 500,
                },
              }}
              {...params}
              variant="outlined"
              margin="normal"
              fullWidth
            />
          )}
        />
      </LocalizationProvider>
    );
  };

  return (
    <div className="text-start device-settings-block">
      <div className="tab-title">
        <label>{constants.DEVICE_PASSWORD_TAB_TITLE}</label>
      </div>

      {showMsg && (
        <Alert variant={variant} onClose={() => setShowMsg(false)} dismissible>
          <Row>
            <Col md={1}>
              <HiOutlineExclamationCircle
                size={15}
                stroke={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--error_24')}
              />
            </Col>
            <Col>
              {userMsg ? (
                <p>{userMsg}</p>
              ) : (
                <>
                  <Alert.Heading>
                    {constants.UNABLE_TO_SAVE_DEVICE_SETTINGS_HEADING}
                  </Alert.Heading>
                  <p>{constants.UNABLE_TO_SAVE_DEVICE_SETTINGS_MESSAGE}</p>
                </>
              )}
            </Col>
          </Row>
        </Alert>
      )}

      <div className="device-password-content">
        {
          <Row className="settings-block">
            <Col>
              <PrimaryButton
                className="btn btn-primary-outline"
                type="button"
                width="100%"
                height="56px"
                fontSize="1.125rem"
                lineHeight="24px"
                backgroundColor="transparent"
                hoverBackgroundColor="transparent"
                borderColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--grayscale_96')}
                hoverBorderColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--primary_40')}
                borderWidth="1px"
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--greyscale_72')}
                hoverColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--primary_40')}
                onClick={() => setDeviceRestartModal(true)}
                disabled={
                  connectionState !== constants.DEVICES_ONLINE_CONNECTION_STATUS
                }
              >
                {constants.DEVICE_PASSWORD_TAB_RESTART_DEIVCE_BUTTON_TITLE}
              </PrimaryButton>
            </Col>
          </Row>
        }
        {
          <Row className="settings-block">
            <Col>
              <PrimaryButton
                className="btn btn-primary-outline"
                type="button"
                width="100%"
                height="56px"
                fontSize="1.125rem"
                lineHeight="24px"
                backgroundColor="transparent"
                hoverBackgroundColor="transparent"
                borderColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--grayscale_96')}
                hoverBorderColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--primary_40')}
                borderWidth="1px"
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--greyscale_72')}
                hoverColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--primary_40')}
                disabled={
                  connectionState !== constants.DEVICES_ONLINE_CONNECTION_STATUS
                }
                onClick={() => setDeviceFactoryResetModal(true)}
              >
                {constants.DEVICE_PASSWORD_TAB_FACTORY_RESET_BUTTON_TITLE}
              </PrimaryButton>
            </Col>
          </Row>
        }
        <Row className="settings-block">
          <Col>
            <PrimaryButton
              className="btn btn-primary-outline"
              type="button"
              width="100%"
              height="56px"
              fontSize="1.125rem"
              lineHeight="24px"
              backgroundColor="transparent"
              hoverBackgroundColor="transparent"
              borderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--grayscale_96')}
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              borderWidth="1px"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--greyscale_72')}
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              disabled={!deviceFactoryReset}
              onClick={() => reclaimApiCall()}
            >
              {constants.DEVICE_PASSWORD_TAB_RECLAIM_DEVICE_BUTTON_TITLE}
            </PrimaryButton>
          </Col>
        </Row>
        <Row className="settings-block">
          <Col>
            <PrimaryButton
              className="btn btn-primary-outline"
              type="button"
              width="100%"
              height="56px"
              fontSize="1.125rem"
              lineHeight="24px"
              backgroundColor="transparent"
              hoverBackgroundColor="transparent"
              borderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              borderWidth="1.5px"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              onClick={() => onDeleteDevice()}
            >
              {constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_BUTTON_TITLE}
            </PrimaryButton>
          </Col>
        </Row>
        {
          <Row className="settings-block">
            <Col>
              <div className="device-infodate-time-title duclo-firmware">
                {t('DUCLO_FIRMEARE_UPDATE')}
              </div>
            </Col>
            {connectionState === constants.DEVICES_ONLINE_CONNECTION_STATUS &&
              deviceInfos?.properties?.['avlbl-duclo-ver'] &&
              deviceInfos?.properties?.['edge-app-version'] !=
                deviceInfos?.properties?.['avlbl-duclo-ver'] && (
                <div className="firmware-update-section">
                  <div
                    className="alert alert-danger info-firmware"
                    role="alert"
                  >
                    <div className="alert-message">
                      <HiOutlineExclamationCircle
                        size={15}
                        stroke={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--error_24')}
                        className="alert-icon"
                      />
                    </div>
                    <p> {constants.FIRMWARE_UPDATE_MESSAGE}</p>
                  </div>
                </div>
              )}
          </Row>
        }
        <div
          className={`firmware-container ${
            curDeviceStatus !== constants.DEVICES_RETURN_ONLINE_STATUS
              ? ' editable-off-offline'
              : ''
          }`}
        >
          <div className="firmware-update">
            <Col>
              <ToggleInput
                label={t('AUTO_UPDATE_FIRMWARE_VERSION')}
                name="fw-updt-type"
                value={autoUpdateEnable}
                labelColor={true}
                changeHandler={(e) => {
                  setAutoUpdateEnable(!!!JSON.parse(e?.target?.value));
                  const status = {
                    'fw-updt-type': !!!JSON.parse(e?.target?.value)
                      ? constants.UPDATE_FIRMWARE_AUTO
                      : constants.UPDATE_FIRMWARE_MANUAL,
                  };
                  updateStore(status);
                  publishDeviceSetting(status, resourceList[2], publishPayload);
                }}
              />
            </Col>
          </div>
          {autoUpdateEnable && (
            <div>
              <div className="week-header">
                <div className="choose-day">{constants.CHOOSE_DAY}</div>
                <div className="week-day">{selectDay}</div>
              </div>
              <div className="select-day-container">
                {daysList?.map((days) => {
                  return (
                    <div
                      onClick={() => selectedDay(days)}
                      key={days.id}
                      className={`day-blocks ${
                        days.isSelected ? 'selected' : ''
                      }`}
                    >
                      <div className="label-value">{days.dayPrefix}</div>
                    </div>
                  );
                })}
              </div>
              <div className="week-header choose-time">
                <div className="choose-day">{constants.CHOOSE_TIME}</div>
              </div>
              <div className="time-container">
                <div className="timer-start-end">
                  {renderTimePicker(startTime, true)}
                </div>
                <div className="seprator">-</div>
                <div className="timer-start-end">
                  {renderTimePicker(endTime, false)}
                </div>
              </div>
              <div className="hour-diffrence">{constants.HOURS_DIFFERENCE}</div>
            </div>
          )}
        </div>
        {deviceInfos?.properties?.['avlbl-duclo-ver'] &&
          deviceInfos?.properties?.['edge-app-version'] !=
            deviceInfos?.properties?.['avlbl-duclo-ver'] && (
            <Row className="settings-block">
              <Col>
                <PrimaryButton
                  className="btn btn-primary mt-2"
                  type="button"
                  width="100%"
                  height="56px"
                  fontSize="1.125rem"
                  lineHeight="24px"
                  backgroundColor="transparent"
                  hoverBackgroundColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  borderColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  hoverBorderColor={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  borderWidth="2px"
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  disabled={
                    connectionState !==
                    constants.DEVICES_ONLINE_CONNECTION_STATUS
                  }
                  onClick={() => onUpdateFirmware()}
                >
                  {t('UPDATE_FIRMWARE')}
                </PrimaryButton>
              </Col>
            </Row>
          )}
      </div>

      {/* Want to remove device - modal  */}
      <SiteModal
        modalTitle={
          licenseattachedStatus
            ? constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_UNABLE_TO_REMOVE_MODAL_TITLE
            : constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_SURE_TO_REMOVE_MODAL_TITLE
        }
        showModal={showDeviceRemoveModal}
        hideModal={() => {
          setShowDeviceRemoveModal(false);
        }}
        classes="device-settings-restart-required"
      >
        <TextBlock
          className="mb-3"
          fontWeight="400"
          fontSize="1rem"
          lineHeight="24px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--greyscale_56'
          )}
        >
          {licenseattachedStatus
            ? constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_UNABLE_TO_REMOVE_MODAL_CONTENT
            : constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_SURE_TO_REMOVE_MODAL_CONTENT}
        </TextBlock>

        {licenseattachedStatus ? (
          <PrimaryButton
            className="btn btn-primary-outline text-capitalize mt-1"
            type="button"
            width="100%"
            borderWidth="1.5px"
            hoverBorderWidth="1.5px"
            height="56px"
            fontSize="1.125rem"
            lineHeight="24px"
            onClick={() => {
              setShowDeviceRemoveModal(false);
            }}
          >
            {constants.BACK_BUTTON_TITLE}
          </PrimaryButton>
        ) : (
          <>
            <div className="text-center">
              <HiExclamationCircle
                className="mb-4 icon-warning-color"
                size={96}
              />
            </div>
            <PrimaryButton
              className="btn btn-primary-outline mb-4"
              type="button"
              width="100%"
              borderWidth="1.5px"
              hoverBorderWidth="1.5px"
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              backgroundColor="transparent"
              height="56px"
              fontSize="1.125rem"
              lineHeight="24px"
              onClick={() => {
                setShowDeviceRemoveModal(false);
              }}
            >
              {
                constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CANCEL_BUTTON_TITLE
              }
            </PrimaryButton>
            <PrimaryButton
              className="btn btn-primary"
              type="button"
              width="100%"
              height="56px"
              fontSize="1.125rem"
              lineHeight="24px"
              backgroundColor="transparent"
              hoverBackgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              borderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              borderWidth="1.5px"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              onClick={() => {
                deleteDeviceCall();
              }}
            >
              {constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_MODAL_BUTTON_TITLE}
            </PrimaryButton>
          </>
        )}
      </SiteModal>

      {/* device removed - modal  */}
      <SiteModal
        modalTitle={
          deviceRemovalStatus
            ? constants.DEVICE_REMOVED_TITLE
            : constants.UNABLE_TO_REMOVE_DEVICE_TITLE
        }
        showModal={deviceRemoveSuccessModal}
        hideModal={() => {
          setDeviceRemoveSuccessModal(false);
        }}
        classes="device-settings-restart-required"
      >
        <TextBlock
          className="mb-3"
          fontWeight="400"
          fontSize="1rem"
          lineHeight="24px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--greyscale_56'
          )}
        >
          {deviceRemovalStatus
            ? constants.DEVICE_REMOVED_MODAL_CONTENT
            : constants.TRY_AGAIN_TITLE}
        </TextBlock>

        {deviceRemovalStatus ? (
          <>
            <div className="text-center">
              <HiCheckCircle className="mb-4 icon-succuess-color" size={96} />
            </div>

            <PrimaryButton
              className="btn btn-primary-outline text-capitalize"
              type="button"
              width="100%"
              borderWidth="1.5px"
              hoverBorderWidth="1.5px"
              height="56px"
              fontSize="1.125rem"
              lineHeight="24px"
              onClick={() => {
                setDeviceRemoveSuccessModal(false);
              }}
            >
              {constants.BACK_BUTTON_TITLE}
            </PrimaryButton>
          </>
        ) : (
          <>
            <PrimaryButton
              className="btn btn-primary-outline mt-1 mb-4"
              type="button"
              width="100%"
              borderWidth="1.5px"
              hoverBorderWidth="1.5px"
              height="56px"
              fontSize="1.125rem"
              lineHeight="24px"
              onClick={() => {
                setDeviceRemoveSuccessModal(false);
              }}
            >
              {constants.TRY_AGAIN_BUTTON_TITLE}
            </PrimaryButton>
            <PrimaryButton
              className="btn btn-primary-outline text-capitalize mt-1"
              type="button"
              width="100%"
              borderWidth="1.5px"
              hoverBorderWidth="1.5px"
              height="56px"
              fontSize="1.125rem"
              lineHeight="24px"
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              backgroundColor="transparent"
              onClick={() => {
                setDeviceRemoveSuccessModal(false);
              }}
            >
              {constants.BACK_BUTTON_TITLE}
            </PrimaryButton>
          </>
        )}
      </SiteModal>

      {/* device factory reset - modal  */}
      <SiteModal
        modalTitle={constants.FACTORY_RESET_MODAL_TITLE}
        showModal={deviceFactoryResetModal}
        hideModal={() => {
          setDeviceFactoryResetModal(false);
        }}
        classes="device-settings-restart-required"
      >
        <TextBlock
          className="mb-4"
          fontWeight="400"
          fontSize="1rem"
          lineHeight="24px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--greyscale_56'
          )}
        >
          {t('FACTORY_RESET_MODAL_CONTENT')}
          <br />
          <br />
          {constants.FACTORY_RESET_MODAL_SUB_CONTENT}
        </TextBlock>

        <PrimaryButton
          className="btn btn-primary-outline mb-4"
          type="button"
          width="100%"
          borderWidth="1.5px"
          hoverBorderWidth="1.5px"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          onClick={() => {
            setDeviceFactoryResetModal(false);
          }}
        >
          {constants.CANCEL_BUTTON_TITLE}
        </PrimaryButton>
        <PrimaryButton
          className="btn btn-primary-outline text-capitalize mt-1"
          type="button"
          width="100%"
          borderWidth="1.5px"
          hoverBorderWidth="1.5px"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          borderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--error_64')}
          hoverBorderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--error_64')}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--error_64'
          )}
          hoverColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--brand_white')}
          hoverBackgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--error_64')}
          backgroundColor="transparent"
          onClick={() => {
            publishDeviceSetting(false, resourceList[1], publishPayload, true);
            setDeviceFactoryResetModal(false);
          }}
        >
          {constants.RESET_FACTORY_DEFAULT_BUTTON_TITLE}
        </PrimaryButton>
      </SiteModal>

      {/* device reclaim - modal  */}
      <SiteModal
        modalTitle={constants.DEVICE_PASSWORD_TAB_RECLAIM_DEVICE_BUTTON_TITLE}
        showModal={deviceReclaimModal}
        hideModal={() => {
          setDeviceReclaimModal(false);
        }}
        classes="device-settings-reclaim-modal"
      >
        <TextBlock
          className="mb-1"
          fontWeight="700"
          fontSize="1.25rem"
          lineHeight="28px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--greyscale_08'
          )}
        >
          {constants.DEVICE_RECLAIM_IN_PROGRESS_TITLE}
        </TextBlock>

        <TextBlock
          className="mb-4"
          fontWeight="400"
          fontSize="1rem"
          lineHeight="24px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--greyscale_56'
          )}
        >
          {t('DEVICE_PASSWORD_TAB_RECLIAM_MODAL_CONTENT')}
        </TextBlock>

        <PrimaryButton
          className="btn btn-primary"
          type="button"
          width="100%"
          borderWidth="1.5px"
          hoverBorderWidth="1.5px"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          onClick={() => {
            setDeviceReclaimModal(false);
          }}
        >
          {constants.BACK_TO_DEVICE_MANAGEMENT_TITLE}
        </PrimaryButton>
      </SiteModal>

      {/* device reclaim success - modal  */}
      <SiteModal
        modalTitle={constants.DEVICE_CLAIMED_TITLE}
        showModal={deviceClaimedStatus}
        hideModal={() => {
          setDeviceClaimedStatus(false);
        }}
        classes="device-settings-claimed-modal"
      >
        <div className="text-center">
          <HiCheckCircle className="mb-4 icon-succuess-color" size={96} />
        </div>
        <PrimaryButton
          className="btn btn-primary"
          type="button"
          width="100%"
          borderWidth="1.5px"
          hoverBorderWidth="1.5px"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          onClick={() => {
            setDeviceClaimedStatus(false);
          }}
        >
          {constants.BACK_TO_DEVICE_MANAGEMENT_TITLE}
        </PrimaryButton>
      </SiteModal>

      {/* Restart device - modal  */}
      <SiteModal
        modalTitle={constants.RESTART_DEVICE_MODAL_TITLE}
        showModal={deviceRestartModal}
        hideModal={() => {
          setDeviceRestartModal(false);
        }}
        classes="device-settings-restart-required"
      >
        <TextBlock
          className="mb-3"
          fontWeight="400"
          fontSize="1rem"
          lineHeight="24px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--greyscale_56'
          )}
        >
          {constants.RESTART_DEVICE_MODAL_CONTENT}
        </TextBlock>
        <PrimaryButton
          className="btn btn-primary-outline mb-4"
          type="button"
          width="100%"
          borderWidth="1.5px"
          hoverBorderWidth="1.5px"
          hoverBorderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_40')}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--primary_40'
          )}
          hoverColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--brand_white')}
          hoverBackgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_40')}
          backgroundColor="transparent"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          onClick={() => {
            setDeviceRestartModal(false);
          }}
        >
          {
            constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CANCEL_BUTTON_TITLE
          }
        </PrimaryButton>
        <PrimaryButton
          className="btn btn-primary"
          type="button"
          width="100%"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          backgroundColor="transparent"
          hoverBackgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--error_64')}
          borderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--error_64')}
          hoverBorderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--error_64')}
          borderWidth="1.5px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--error_64'
          )}
          hoverColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--brand_white')}
          onClick={() => {
            publishDeviceSetting(false, resourceList[0], publishPayload, true);
            setDeviceRestartModal(false);
          }}
        >
          {constants.DEVICE_PASSWORD_TAB_RESTART_DEIVCE_BUTTON_TITLE}
        </PrimaryButton>
      </SiteModal>
      <SiteModal
        modalTitle={constants.UNABLE_TO_REMOVE_DEVICE_TITLE}
        showModal={licenceAttachedModal}
        hideModal={() => {
          setLicenceAttachedModal(false);
        }}
        classes="device-settings-reclaim-modal"
      >
        <TextBlock
          className="mb-4"
          fontWeight="400"
          fontSize="1rem"
          lineHeight="24px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--greyscale_56'
          )}
        >
          {
            constants.DEVICE_PASSWORD_TAB_REMOVE_DEVICE_UNABLE_TO_REMOVE_MODAL_CONTENT
          }
        </TextBlock>

        <PrimaryButton
          className="btn btn-primary"
          type="button"
          width="100%"
          borderWidth="1.5px"
          hoverBorderWidth="1.5px"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          onClick={() => {
            setLicenceAttachedModal(false);
          }}
        >
          {constants.DEVICES_TAB_ANALYTICS_BACK}
        </PrimaryButton>
      </SiteModal>
    </div>
  );
};

export default DevicePasswordBlock;
