import { useEffect, useState } from 'react';
import './stepspos.scss';
import PosIndicator from './PosIndicator';
import { useSearchParams } from 'react-router-dom';
import { AppDefaults, constants } from '../../../helpers';
import { PrimaryButton } from '../../../components/common';
import { PosStore } from '../../../store/PosStore';
import {checkUnslect} from '../../../assets/images'
import { ImCheckboxChecked } from "react-icons/im";
const StepFourUniqeIdentifier = (props) => {
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const getPosDataList = PosStore((state) => state.getPosDataList);
  const setPosDataList = PosStore((state) => state.setPosDataList);
  const [disableMapBtn, setDisableMapBtn] = useState(true);
  const [posDataListFromCSV, setPosDataListFromCSV] = useState(
    getPosDataList()
  );


    
  const onHandleChange = async (item) => {
    let posData = posDataListFromCSV?.map((posItem, index) => {
      if (posItem?.order === item?.order) {
        return {
          ...posItem,
          keyComponent:
            posItem?.keyComponent && posItem?.keyComponent === 0
              ? 1
              : posItem?.keyComponent && posItem?.keyComponent === 1
              ? 0
              : 1,
        };

      } else {
        return { ...posItem };
      }
    });
    let updatedPosList = await Promise.all(posData);
    setPosDataListFromCSV(updatedPosList);
    setPosDataList(updatedPosList)
  };


  useEffect(()=>{
    const keyComponentList = posDataListFromCSV?.filter(
      (itemAttribute) => itemAttribute?.keyComponent === 1
    );
    if(keyComponentList?.length > 0){
      setDisableMapBtn(false)
    }
    else{
      setDisableMapBtn(true)

    }

  },[posDataListFromCSV])

  return (
    <div className="pos-integration">
      <PosIndicator showMapPostStatus={0} {...props} />
      <div className={`manage-pos-height`}>
        <div className="step-four-unique-identifier">
          <div className="lable-upload-file">
            {constants.POS_SELECT_UNIQUE_IDENTIFIER}
          </div>
          <div className="extract-message">
            {constants.POS_SELECT_UNIQUE_IDENTIFIER_MESSAGE}
          </div>
          {posDataListFromCSV &&
            posDataListFromCSV?.map((item) => {
              return (
                item?.isSelected &&
                item?.id && (
                  <div className={`list-unique-identifier`}>
                    <div className={`left-layout-col`}>
                      <div className="col-name-heading">{item.Column}</div>
                      <div className="sub-content-label">{item.value}</div>
                    </div>
                    <div className="middle-layout">
                      <div className="unique-text">{item.label}</div>
                    </div>
                    <div className="right-layout-col">
                      {item?.keyComponent !== 1 && (
                        <img
                          onClick={() => {
                            onHandleChange(item);
                          }}
                          alt=""
                          className="img-unselected"
                          src={checkUnslect}
                        ></img>
                      )}
                       {item?.keyComponent === 1 && (
                        <ImCheckboxChecked className="img-selected" color={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--primary_40')} onClick={()=>onHandleChange(item)} size={20} />
                      )}
                    </div>
                  </div>
                )
              );
            })}
        </div>

       
      </div>
      <PrimaryButton
              className="adddevice-btn btn-margin-try"
              fontSize="14px"
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_40')}
          height="44px"
          color="#FFFFFF"
          type="submit"
          disabled={disableMapBtn}
          onClick={() => {
            props.nextStep();
          }}
        >
          {constants.TOS_PP_CONTINUE}
        </PrimaryButton>
        <PrimaryButton
              className="adddevice-btn btn-margin-try"
              fontSize="14px"
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--brand_white')}
          height="44px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--primary_40'
          )}
          borderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_40')}
          type="submit"
          onClick={() => {
            props.prevstep();
          }}
        >
          {constants.ORGANIZATION_LDAP_GO_BACK}
        </PrimaryButton>
    </div>
  );
};
export default StepFourUniqeIdentifier;
