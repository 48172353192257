import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { HiOutlineVideoCamera } from 'react-icons/hi2';
import { FiSettings } from 'react-icons/fi';
import moment from 'moment';
import { WiTime4 } from 'react-icons/wi';
import './LiveGridStructure.scss';
import {
  clearWSSConnections,
  getCDNInfo,
  getMQTTConnection,
  getPlatformInfo,
  getRecievedOffers,
  getRemoteStreamAudio,
  getRemoteStreams,
  getWSSConnections,
  removeMQTTPeerConnections,
  removeRemoteStreams,
  removeRemoteStreamsAudio,
  setIsReloadedStream,
  setRecievedOffers,
  setRemoteStreams,
  setRemoteStreamsAudio,
  setWSSConnection,
} from '../../../store/reducers/StreamingReducer';
import { disconnectWithMQTT, publishWithMQTTs } from '../../../utils/connection/mqttConnection';
import {
  getAccountId,
  setDeviceInformation,
} from '../../../store/reducers/AccountReducer';
import { ReactComponent as ThreeDotIcon } from '../../../assets/images/VerticalThreeDots.svg';
import videoProcessing from '../../../assets/images/cameras/FootageThumb.svg';
import OfflineCamera from './OfflineCamera';
import { constants } from '../../../helpers';
import LoadingCamera from './LoadingCamera';
import NoContentScreen from './NoContentScreen';
import { disconnectWithWebSocket } from '../../multilive/components/playback/wssConnection/wssConnection';

const LiveGridItem = ({
  layout,
  deviceId,
  device,
  hubId,
  activeTime,
  timeZone,
  uniqueId,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const remoteVideoRef = useRef();
  const remoteAudioRef = useRef();
  const cdnInfo = useSelector(getCDNInfo);
  const remoteStream = useSelector(getRemoteStreams)?.[deviceId];
  const remoteStreamAudio = useSelector(getRemoteStreamAudio)?.[deviceId];
  const mqttConnection = useSelector(getMQTTConnection);
  const accountId = useSelector(getAccountId);
  const platformDetails = useSelector(getPlatformInfo);
  const recievedOffers = useSelector(getRecievedOffers);
  const [loading, setLoading] = useState(false);
  const intervalRef = useRef();
  const tryAgainIntervalRef = useRef();
  const hideLoaderRef = useRef(null);
  const wssConnections = useSelector(getWSSConnections);

  useEffect(() => {
    if (recievedOffers?.[deviceId]) {
      clearInterval(intervalRef?.current);
      clearInterval(tryAgainIntervalRef?.current);
    }
  }, [recievedOffers]);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef?.current);
      clearInterval(tryAgainIntervalRef?.current);
    };
  }, []);

  useEffect(() => {
    if (
      device?.displayDeviceStatus !== constants.DEVICES_RETURN_OFFLINE_STATUS &&
      device?.displayDeviceStatus !==
        constants.DEVICES_RETURN_DEACTIVATED_STATUS
    ) {
      callTimeoutLoader();
    }
    return () => {
      clearTimeout(hideLoaderRef.current);
    };
  }, [uniqueId]);

  useEffect(() => {
    dispatch(setRemoteStreams({ id: deviceId, stream: null }));
    dispatch(setRemoteStreamsAudio({ id: deviceId, audio: null }));
    const deviceDetails = {
      deviceId: deviceId,
      gatewayId: hubId,
    };
    if (mqttConnection) {
      if (
        !recievedOffers?.[deviceId] &&
        device?.displayDeviceStatus?.toLowerCase() ===
          constants.DEVICES_ONLINE_CONNECTION_STATUS
      ) {
        publishWithMQTTs(
          platformDetails.mqtt,
          platformDetails.p2p_server,
          deviceDetails,
          accountId
        );
        const id = setInterval(() => {
          publishWithMQTTs(
            platformDetails.mqtt,
            platformDetails.p2p_server,
            deviceDetails,
            accountId
          );
        }, 10000);
        intervalRef.current = id;
      }
    }
    return () => {
      dispatch(removeRemoteStreams(deviceId));
      dispatch(removeRemoteStreamsAudio(deviceId));
      dispatch(removeMQTTPeerConnections(deviceId));
    };
  }, [mqttConnection, uniqueId]);

  useEffect(() => {
    if (remoteStream?.active) {
      const remoteVideo = remoteVideoRef.current;
      if (remoteVideo) {
        remoteVideo.srcObject = remoteStream;
        setLoading(false);
        clearTimeout(hideLoaderRef.current);
      }
    }
  }, [remoteStream]);

  useEffect(() => {
    try {
      if (remoteStreamAudio) {
        const remoteAudio = remoteAudioRef.current;
        if (remoteAudio) remoteAudio.srcObject = remoteStreamAudio;
      }
    } catch (error) {}
  }, [remoteStreamAudio]);

  const callTimeoutLoader = () => {
    clearTimeout(hideLoaderRef.current);
    setLoading(true);
    hideLoaderRef.current = setTimeout(() => {
      setLoading(false);
    }, 60000);
  };

  const OnClickCameraDetails = () => {
    disconnectWithMQTT();
    if (wssConnections) {
      Object.keys(wssConnections).forEach((key) => {
        dispatch(removeRemoteStreams(key));
        dispatch(removeRemoteStreamsAudio(key));
        dispatch(removeMQTTPeerConnections(key));
        disconnectWithWebSocket(key);
      });
    }
    dispatch(clearWSSConnections());
    dispatch(setIsReloadedStream(false));
    dispatch(setWSSConnection(false));
    dispatch(setIsReloadedStream(false));
    navigate(`/cameras/dashboard.html`, {
      state: {
        id: deviceId,
        cdnInfo: cdnInfo ? cdnInfo : {},
      },
    });
  };

  const handleDoubleClick = () => {
    OnClickCameraDetails();
  };

  const OnClickCameraSettings = () => {
    dispatch(setDeviceInformation(device));
    deviceId && navigate(`/devices/dashboard.html?deviceId=${deviceId}`);
  };

  const handleTryAgain = () => {
    dispatch(setRecievedOffers({ id: deviceId, value: false }));
    callTimeoutLoader();
    const deviceDetails = {
      deviceId: deviceId,
      gatewayId: hubId,
    };
    if (mqttConnection) {
      if (
        !recievedOffers?.[deviceId] &&
        device?.displayDeviceStatus?.toLowerCase() ===
          constants.DEVICES_ONLINE_CONNECTION_STATUS
      ) {
        publishWithMQTTs(
          platformDetails.mqtt,
          platformDetails.p2p_server,
          deviceDetails,
          accountId
        );
        const id = setInterval(() => {
          publishWithMQTTs(
            platformDetails.mqtt,
            platformDetails.p2p_server,
            deviceDetails,
            accountId
          );
        }, 10000);
        tryAgainIntervalRef.current = id;
      }
    }
  };

  return (
    <>
      {(device?.displayDeviceStatus ===
        constants.DEVICES_RETURN_OFFLINE_STATUS ||
        device?.displayDeviceStatus ===
          constants.DEVICES_RETURN_DEACTIVATED_STATUS) &&
      !loading ? (
        <OfflineCamera
          deviceId={deviceId}
          device={device}
          activeTime={activeTime}
          timeZone={timeZone}
          onRefresh={handleTryAgain}
        />
      ) : (
        <>
          {remoteStream?.active ? (
            <>
              <div
                className={`device-overlay hovered`}
                onDoubleClick={() => handleDoubleClick()}
              >
                <div className="device-title-container">
                  <div className="device-name">{device?.deviceName}</div>
                  <div className="device-location">
                    {device?.locationName} • {device?.areaName}
                  </div>
                </div>
                <div className="date-time-wrapper">
                  <div className="date-time">
                    <WiTime4 size={14} />
                    {moment
                      .tz(moment(activeTime), timeZone)
                      .format('hh:mm:ss A z')}
                  </div>
                </div>
                <div className="menu-icon">
                  <NavDropdown
                    className="devices-dropdown"
                    title={<ThreeDotIcon />}
                  >
                    <NavDropdown.Item
                      className="devices-dropdown-options"
                      onClick={() => OnClickCameraDetails()}
                    >
                      <HiOutlineVideoCamera size={20} />
                      <span className="devices-dropdown-options-label">
                        {constants.CAMERAS_VIDEO_CAMERA_DETAILS_LABEL}
                      </span>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className="devices-dropdown-options"
                      onClick={() => OnClickCameraSettings()}
                    >
                      <FiSettings size={20} />
                      <span className="devices-dropdown-options-label">
                        {constants.CAMERAS_VIDEO_SETTINGS_LABEL}
                      </span>
                    </NavDropdown.Item>
                  </NavDropdown>
                </div>
              </div>
              <div className="primary-circle"></div>
              <div className="streaming-container">
                <video
                  id={`video${layout}${layout}`}
                  width="auto"
                  height="100%"
                  ref={remoteVideoRef}
                  autoPlay={true}
                  playsInline={true}
                  muted={true}
                />
                <audio
                  id={`audio${layout}${layout}`}
                  ref={remoteAudioRef}
                  autoPlay={true}
                  playsInline={true}
                  controls={false}
                  muted={true}
                />
                <canvas
                  id={`canvas${layout}${layout}`}
                  width="764"
                  height="450"
                  className="d-none"
                />
              </div>
              <img
                className="hide-image"
                src={videoProcessing}
                alt="video processing"
              />
            </>
          ) : (
            <>
              {loading ? (
                <LoadingCamera />
              ) : (
                <NoContentScreen
                  deviceId={deviceId}
                  device={device}
                  activeTime={activeTime}
                  timeZone={timeZone}
                  onTryAgain={handleTryAgain}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default LiveGridItem;
