import React, { useState, useEffect, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
  Avatar,
  BreadcrumbList,
  Header,
  PrimaryButton,
  SiteModal,
  SiteSpinner,
  SiteToast,
  Tabs,
  TextBlock,
} from '../../components/common';
import PageWrapper from '../PageWrapper';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { constants, Utils, roles } from '../../helpers';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { useSelector } from 'react-redux';
import { getSelectedOrganization } from '../../store/reducers/AccountReducer';
import { FiUsers } from 'react-icons/fi';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TextField } from '../../components/forms';
import { PiWarningCircleBold } from 'react-icons/pi';
import { TbInfoSquareRounded } from "react-icons/tb";
import { HiUserCircle } from 'react-icons/hi2';
import { AiOutlineSearch } from 'react-icons/ai';
import { warninground, checked, unchecked, } from '../../assets/images';
import ViewUserDetails from './ViewUserDetails';
import useCustomRoles from '../../store/CustomRolesStore';
import { useCustomerOrgUsers } from '../../store/CustomerOrgUsersStore';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';
import { usePoliciesStore } from '../../store/policiesStore';

const CustomRoleDetails = () => {
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState(0);
  const [showRemoveRoleModal, setShowRemoveRoleModal] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const {
    entitlementData,
    getCustomRoleDetailWithEntitlement,
    roleList,
    getCustomRoleList,
    selectedCustomRole,
    getCustomRoleDetailWithUser,
    setSelectedCustomRole,
  } = useCustomRoles();
  const [entitlementList, setEntitltmentList] = useState([]);
  const [selectedEntitlementList, setSelectedEntitltmentList] = useState([]);
  const [variant, setVariant] = useState('error');
  const [userMsg, setUserMsg] = useState('');
  const [searchParams] = useSearchParams();
  const roleId =
    searchParams.get('roleId') === 'role2'
      ? roles?.ROLE2VMS
      : searchParams.get('roleId') === 'role3'
      ? roles.ROLE3VMS
      : searchParams.get('roleId');
  
  const roleParam = encodeURIComponent(roleId);
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData
  );
  const loggedInUser = getLoggedInUserData();
  const orgDetails = useSelector(getSelectedOrganization);
  const getCustomerOrgUsersData = useCustomerOrgUsers(
    (state) => state.getCustomerOrgUsersData
  );
  let resultData = getCustomerOrgUsersData();
  const [usersData, setUsersData] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [filterUserData, setFilterUserData] = useState();
  const [selectedUserList, setSelectedUserList] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const formikRef = useRef();
  const { keycloak } = useKeycloak();
  const token = keycloak.token;
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies
  );

  useEffect(() => {
    const users = resultData?.filter(
      (user) =>
        loggedInUser?.accountId !== user?.accountId
    );
    setUsersData(users);
    setAllUsers([]);
    setFilterUserData([]);
    setSelectedUserList([]);
  }, []);

  const validationSchema = Yup.object().shape({
    roleName: Yup.string().required(constants.ROLE_NAME_REQUIRE_MESSAGE),
  });

  const breadList = [
    {
      url: '/customers/manage.html',
      title: loggedInUser.role !== roles.ROLE2VMS ?  
            constants.MANAGE_ORG_PAGE_TITLE : 
            constants.MANAGE_ORG_PAGE_ORG_TITLE,
    },
    {
      url: `/customers/dashboard.html?orgId=${orgDetails?.orgId}`,
      title: `${orgDetails?.orgName}`,
    },
    {
      url: `/roles/manage.html?orgId=${orgDetails?.orgId}`,
      title: constants.ORG_LABELS_ROLES,
    },
    {
      url: `/roles/dashboard.html?roleId=${roleId}`,
      title: selectedCustomRole?.roleName === 'cust_org_admin'
        ? constants.ADMIN_USER_CUSTOM_ROLE_LABEL
        : selectedCustomRole?.roleName === 'cust_org_user'
          ? constants.NORMAL_USER_ROLE_LABEL
          : selectedCustomRole?.roleName,
    },
  ];

  useEffect(() => {
    if(token && selectedCustomRole?.customRole){
      fetchData();
    }
  }, [token]);

  useEffect(() => {
    if(usersData?.length){
      setShowLoader(true);
      getCustomRoleDetailWithUser(
        `/partner/orgs/${orgDetails?.orgId}/roles/${roleParam}/users`
      )
      .then((res) => {
        const selectedUsers = [];
        const unselectedUsers = [];
        usersData?.forEach((user) => {
          if(res?.toString()?.includes(user?.accountId)){
            selectedUsers.push(user)
          } else{
            if(selectedCustomRole?.customRole){
              if(user.role !== roles.ROLE2VMS){
                unselectedUsers.push(user)
              }
            } else{
              unselectedUsers.push(user)
            }
          }
        });
        selectedUsers?.sort((a, b) => a?.name.localeCompare(b?.name));
        unselectedUsers?.sort((a, b) => a?.name.localeCompare(b?.name));
        let combinedUsers = [...selectedUsers, ...unselectedUsers];
        setAllUsers(combinedUsers);
        setFilterUserData(combinedUsers);
        setSelectedUserList(selectedUsers);
        setShowLoader(false);
        })
        .catch((err) => { setShowLoader(false); });
      }
  }, [usersData]);

  useEffect(() => {
    if (selectedCustomRole && formikRef.current) {
      formikRef.current.setFieldValue('roleName', selectedCustomRole.roleName);
    }
  }, [selectedCustomRole]);

  useEffect(() => {
    const roleOption = [];
    roleList?.forEach((role) => {
      if (role?.roleId === roleId) {
        setSelectedCustomRole(role);
      }
      if (role?.roleId !== roles.ROLE1VMS) {
        let obj = {
          label:
            role.roleName === 'cust_org_admin'
              ? constants.ADMIN_USER_CUSTOM_ROLE_LABEL
              : role.roleName === 'cust_org_user'
                ? constants.NORMAL_USER_ROLE_LABEL
                : role.roleName,
          value: role?.roleId,
        };
        roleOption.push(obj);
      }
    });
    setRoleOptions(roleOption);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (selectedEntitlementList?.length > 0 && entitlementList?.length > 0) {
        selectedEntitlementList?.forEach((value) => {
          entitlementList?.forEach((data) => {
            if (value?.groupId === data?.groupId) {
              const ele = document?.getElementById(`entitlements${value?.groupId}`);
              if (ele && ele?.checked === false) {
                ele.click();
              }
            }
          });
        });
      }
    }, 600);
  }, [entitlementList, selectedEntitlementList]);

  const initialValues = {
    roleName: '',
    entitlements: [],
    users: [],
    selectedEntitlements: []
  };

  const setSectionBlock = (index) => {
    setActiveSection(index);
  };
  const tabs = [
    {
      icon: <TbInfoSquareRounded size={22} />,
      label: constants.ROLE_DETAILS_TAB_TITLE,
      clickHandler: (index) => setSectionBlock(index),
    },
    {
      icon: <FiUsers size={22} />,
      label: constants.ROLE_ASSIGNED_ACCOUNTS_TAB_TITLE,
      clickHandler: (index) => setSectionBlock(index),
    },
  ];

  const fetchData = async () => {
    setShowLoader(true);
    if (entitlementData?.length > 0) {
      await getCustomRoleDetailWithEntitlement(
        `/partner/orgs/${orgDetails?.orgId}/roles/${roleParam}/withGroupPolicies`
      )
      .then((res) => {
        processEnttls(res?.toString())
      })
      .catch((err) => {
        setShowLoader(false);
      });
    } else{
      entitlementData?.sort((a, b) => a?.groupName?.localeCompare(b?.groupName));
      setEntitltmentList(entitlementList);
    }
  };

  const processEnttls = (selectedEnt) => {
    const selectedEnttl = [];
    const unselectedEnttl = [];
    entitlementData?.forEach((group) => {
      if (selectedEnt?.includes(group?.groupId)) {
        selectedEnttl.push(group);
      } else{
        unselectedEnttl.push(group);
      }
    });
    selectedEnttl?.sort((a, b) => a?.groupName?.localeCompare(b?.groupName));
    unselectedEnttl?.sort((a, b) => a?.groupName?.localeCompare(b?.groupName));
    let combinedEnttl = [...selectedEnttl, ...unselectedEnttl];
    setSelectedEntitltmentList(selectedEnttl);
    setEntitltmentList(combinedEnttl);
    if (selectedCustomRole && formikRef.current) {
      formikRef.current.setFieldValue('selectedEntitlements', selectedEnttl);
    }
    setShowLoader(false);
  }

  const handleCheckboxChange = (e, entitlement) => {
    const { checked } = e.target;
    if (activeSection === 0) {
      let enttlList = JSON.parse(JSON.stringify(selectedEntitlementList))
      if (checked) {
        enttlList.push(entitlement);
      } else {
        const index = selectedEntitlementList.indexOf(entitlement, 0);
        if (index > -1) {
          enttlList.splice(index, 1);
        }
      }
      processEnttls(JSON.stringify(enttlList));
    }
  };

  const DetailsView = ({ values }) => (
    <div className="partner-customers-list-wrap mt-2">
      {entitlementList?.map((entitlement) => {
        return (
          <div
            className="partner-customers-list-item"
            key={entitlement.groupId}
          >
            <label
              className="partner-customers-list-item-label"
              htmlFor={`customer-${entitlement.groupId}`}
            >
              {entitlement.groupName}
            </label>
            <input
              className='role-checkbox'
              id={`entitlements${entitlement?.groupId}`}
              name={`entitlements${entitlement?.groupId}`}
              type="checkbox"
              checked={
                values?.selectedEntitlements?.some((enttl) => 
                enttl?.groupId === entitlement?.groupId)
              }
              onChange={(e) =>
                handleCheckboxChange(
                  e,
                  entitlement
                )
              }
              value={entitlement?.groupId}
            />
          </div>
        );
      })}
    </div>
  );

  const getUserView = (setFieldValue) => (
    <div className="partner-customers-list-wrap mt-3">
      <TextField
        beforeinputicon={
          <AiOutlineSearch style={{ height: '20px', width: '20px' }} />
        }
        label=""
        placeholder={constants.TOP_HEADER_SEARCH_NAV_TITLE}
        name="search"
        type="text"
        style={{ marginBottom: '0.5rem' }}
        onChange={(e) => handleSearchChange(e, setFieldValue)}
      />
      {filterUserData?.map((user) => {
        return (
          <div className="partner-customers-list-item " key={user?.accountId}>
            <label
              className="partner-customers-list-item-label w-100 text-left d-flex flex-row align-items-center gap-2"
              htmlFor={`customer-${user?.accountId}`}
              onClick={() => {
                setUserDetails(user);
                setShowUserDetails(true);
              }}
            >
              {user?.image?.url && user?.image?.url !== '' ? (
                <Avatar
                  valueType="icon"
                  value={user?.image?.url}
                  size="medium"
                  avatarStyle="roundedCircle"
                />
              ) : (
                <HiUserCircle
                  style={{
                    height: '32px',
                    width: '32px',
                  }}
                />
              )}
              {user?.name}
            </label>
            {
              JSON.stringify(selectedUserList).includes(user?.accountId) ? 
              <img src={checked} alt=''/> : 
              <img src={unchecked} alt=''/>
            }
          </div>
        );
      })}
    </div>
  );

  const handleSearchChange = (event, setFieldValue) => {
    setFieldValue('search', event.target.value);
    if (event.target.value !== '') {
      const searchedData = allUsers?.filter((user) =>
        user?.name?.toLocaleLowerCase()?.includes(event.target?.value?.toLowerCase())
      );
      if (searchedData !== undefined || searchedData !== null) {
        setFilterUserData(searchedData);
      }
    } else {
      setFilterUserData(allUsers);
    }
  };

  const handleTextChange = (e, setFieldValue) => {
    setFieldValue('roleName', e.target.value);
  };

  const handleDeleteCustomRole = async () => {
    try {
      axios
        .delete(`partner/orgs/${orgDetails?.orgId}/roles/${roleParam}`, Utils.requestHeader())
        .then((res) => {
          if (res?.data) {
            const response = res?.data;
            if (response?.meta?.code === 200) {
              setShowRemoveRoleModal(false);
              navigate(`/roles/manage.html?orgId=${orgDetails?.orgId}`);
            } else {
              setUserMsg(response?.meta?.userMsg);
            }
          }
        })
        .catch((err) => {
          setUserMsg(err);
        });
    } catch (err) {
      console.log('handleDeleteCustomRole', err);
    }
  };

  const submitUpdateRole = (values, setSubmitting) => {
    let obj;
    const policies = [];
    selectedEntitlementList?.forEach((enttl) => {
      policies.push(enttl?.groupId)
    })
    if (activeSection === 0) {
      obj = {
        roleName: values?.roleName,
        groupPolicies: policies,
      };
    }
    axios
      .put(
        `/partner/orgs/${orgDetails?.orgId}/roles/${roleParam}/withGroupPolicies`,
        obj,
        { ...Utils.requestHeader() }
      )
      .then(async (res) => {
        const response = res.data;
        if (response?.meta?.code === 200) {
          await getCustomRoleList(`partner/orgs/${orgDetails?.orgId}/roles`);
          setSubmitting(false);
          navigate(`/roles/manage.html?orgId=${orgDetails?.orgId}`);
        }
      })
      .catch((err) => {
        setSubmitting(false);
      });
  };

  return (
    <div className="App">
      <Header breadcrumbData={breadList} />

      <PageWrapper className="mw-100">
        <div className="page-header">
          <Container className='mw-100'>
            <Row>
              <Col className="text-start">
                <BreadcrumbList list={breadList} />
              </Col>
            </Row>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) =>
                submitUpdateRole(values, setSubmitting)
              }
              enableReinitialize={false}
            >
              {({
                values,
                setFieldValue,
                handleSubmit,
                isSubmitting,
                isValid,
                dirty,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Row className="justify-content-between">
                    <Col
                      md={6}
                      lg={6}
                      xl={6}
                      xs={12}
                      className="page-title text-start w-auto"
                    >
                      {selectedCustomRole?.roleName === 'cust_org_admin'
                        ? constants.ADMIN_USER_CUSTOM_ROLE_LABEL
                        : selectedCustomRole?.roleName === 'cust_org_user'
                          ? constants.NORMAL_USER_ROLE_LABEL
                          : selectedCustomRole?.roleName}
                      {selectedCustomRole?.customRole === false ? (
                        <TextBlock
                          className="mt-1"
                          fontWeight="400"
                          fontSize="0.875rem"
                          lineHeight="1.25rem"
                          color={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--greyscale_40')}
                        >
                          {selectedCustomRole?.roleName === 'cust_org_admin'
                            ? constants.CUSTOM_ROLE_EDIT_DEFAULT_ADMIN_HELPER_TEXT
                            : selectedCustomRole?.roleName === 'cust_org_user'
                              ? constants.CUSTOM_ROLE_EDIT_DEFAULT_USER_HELPER_TEXT
                              : constants.CUSTOM_ROLE_EDIT_DEFAULT_INSTALLER_HELPER_TEXT}
                        </TextBlock>
                      ) : null}
                    </Col>
                    <Col
                      md={6}
                      lg={6}
                      xl={6}
                      xs={12}
                      className="text-end page-header-buttons d-flex flex-row gap-2 justify-content-end align-items-center w-auto"
                    >
                      {getCustomerOrgPolicies().delete_custom_roles &&
                        selectedCustomRole?.customRole === true ? (
                        <PrimaryButton
                          className="btn btn-outline-danger"
                          type="button"
                          height="44px"
                          fontSize="0.875rem"
                          width="7.938rem"
                          disabled={selectedUserList?.length > 0}
                          backgroundColor={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--brand_white')}
                          borderColor={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--error_64')}
                          color={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--error_64')}
                          borderWidth="1.5px"
                          hoverBorderWidth="1.5px"
                          hoverColor={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--error_64')}
                          hoverBackgroundColor={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--brand_white')}
                          hoverBorderColor={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--error_64')}
                          onClick={() => {
                            setUserMsg('');
                            setShowRemoveRoleModal(true);
                          }}
                        >
                          {constants.CUSTOM_ROLE_EDIT_DELETE_BUTTON_TEXT}
                        </PrimaryButton>
                      ) : null}

                      {getCustomerOrgPolicies().update_custom_roles &&
                        activeSection == 0 ? (
                        <PrimaryButton
                          className="btn btn-primary"
                          type="submit"
                          width="7.938rem"
                          disabled={
                            selectedCustomRole.customRole === false ||
                            !(dirty && isValid)
                          }
                          loader={isSubmitting}
                        >
                          {constants.CUSTOM_ROLE_EDIT_SAVE_BUTTON_TEXT}
                        </PrimaryButton>
                      ) : null}
                    </Col>
                  </Row>
                  {selectedCustomRole?.customRole === true ? (
                    <Row className="device-tabs">
                      <Col>
                        <Tabs
                          defaultActive={activeSection}
                          tabCollection={tabs}
                          customTabWidth={'50%'}
                        />
                      </Col>
                    </Row>
                  ) : null}

                  <Row>
                    <div className="d-flex flex-column gap-1 custom-role-text">
                      {selectedCustomRole?.customRole === true &&
                        activeSection === 0 ? (
                        <>
                          <TextField
                            required={true}
                            removebottommargin="true"
                            removetopmargin="true"
                            label=""
                            placeholder={constants.NAME_TABLE_HEADER}
                            name="roleName"
                            onChange={(e) => handleTextChange(e, setFieldValue)}
                            type="text"
                            maxlength="20"
                            value={values.roleName}
                          />
                          <div className="d-flex flex-row gap-1">
                            <PiWarningCircleBold
                              color={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--greyscale_56')}
                              size={16}
                            />
                            <TextBlock
                              className="text-start"
                              fontWeight="500"
                              fontSize="0.75rem"
                              lineHeight="1rem"
                              color={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--greyscale_40')}
                            >
                              Helper
                            </TextBlock>
                          </div>
                        </>
                      ) : null}

                      <div className="d-flex flex-column text-start">
                        <TextBlock
                          className={activeSection === 0 ? 'mt-4' : 'mt-0'}
                          fontWeight="600"
                          fontSize="1.25rem"
                          lineHeight="1.75rem"
                          color={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--brand_black')}
                        >
                          {activeSection === 0 &&
                            selectedCustomRole?.customRole === true
                            ? constants.EDIT_ENTITLEMENT_TITLE
                            : constants.EDIT_USER_TITLE}
                        </TextBlock>

                        <TextBlock
                          className="mt-1"
                          fontWeight="400"
                          fontSize="0.875rem"
                          lineHeight="1.25rem"
                          color={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--greyscale_40')}
                        >
                          {activeSection === 0 &&
                            selectedCustomRole?.customRole === true
                            ? constants.CREATE_ROLE_ENTITLEMENT_TEXT
                            : constants.UPDATE_ROLE_USER_TEXT}
                        </TextBlock>
                      </div>

                      {showLoader ? (
                        <div className="text-center">
                          <SiteSpinner
                            height="100px"
                            width="100px"
                          ></SiteSpinner>
                        </div>
                      ) : (
                        <>
                          {activeSection === 0 &&
                            selectedCustomRole?.customRole === true ? (
                            <DetailsView
                              values={values}
                            />
                          ) : (
                            <>{getUserView(setFieldValue)}</>
                          )}
                        </>
                      )}
                    </div>
                  </Row>
                </Form>
              )}
            </Formik>
          </Container>
        </div>
      </PageWrapper>

      <SiteModal
        modalTitle={`${constants.CUSTOM_ROLE_DELETE_MODAL_BUTTON_TEXT} ${selectedCustomRole?.roleName}`}
        showModal={showRemoveRoleModal}
        hideModal={() => setShowRemoveRoleModal(false)}
        classes="remove-org-modal"
      >
        <div className="text-center">
          <Avatar
            value={warninground}
            valueType="icon"
            size="extraLarge"
            style="roundedCircle"
          />

          <div className="w-100 d-flex flex-column gap-2">
            <PrimaryButton
              className="btn btn-primary w-100 mt-4"
              type="button"
              height="44px"
              fontSize="0.875rem"
              width="100%"
              onClick={() => handleDeleteCustomRole()}
            >
              {constants.CUSTOM_ROLE_DELETE_CONFIRM_BUTTON_TEXT}
            </PrimaryButton>
            <PrimaryButton
                className="btn btn-outline-danger w-100"
                type="button"
                height="44px"
                fontSize="0.875rem"
                width="7.938rem"
                disabled={selectedUserList?.length > 0}
                backgroundColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--brand_white')}
                borderColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--error_64')}
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--error_64')}
                borderWidth="1.5px"
                hoverBorderWidth="1.5px"
                hoverColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--error_64')}
                hoverBackgroundColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--brand_white')}
                hoverBorderColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--error_64')}
                onClick={() => setShowRemoveRoleModal(false)}
              >
                {constants.CANCEL_BUTTON_TITLE}
            </PrimaryButton>
          </div>
        </div>
        <SiteToast
          title={
            variant === 'error'
              ? constants.ERROR_TOAST_TITLE
              : constants.SUCCESS_TOAST_TITLE
          }
          show={!!userMsg}
          body={userMsg}
          variant={variant}
          position="top-center"
        />
      </SiteModal>

      <SiteModal
        modalTitle={userDetails?.name}
        showModal={showUserDetails}
        hideModal={() => setShowUserDetails(false)}
        classes="custom-role-view-modal"
      >
        <ViewUserDetails userDetails={userDetails} roleOption={roleOptions} />
      </SiteModal>
    </div>
  );
};

export default CustomRoleDetails;
