import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLiveCameraIds,
  setLiveCameraIds,
} from '../../../../store/reducers/StreamingReducer';
import ImageFetch from '../../../cameras/components/ImageFetch';
import { MuuriComponent } from 'muuri-react';
import { useRef } from 'react';
import { getAllDevicesData } from '../../../../store/reducers/AccountReducer';

const MultiImageFetch = ({
  moveTimeline,
  liveSnapshot,
  time,
  cdnValue,
  onClick,
}) => {
  const liveCameraIds = useSelector(getLiveCameraIds);
  const devicesData = useSelector(getAllDevicesData);
  const dispatch = useDispatch();
  const gridRef = useRef();

  const getDeviceInfo = (deviceId) => {
    if (devicesData?.length) {
      const deviceData = devicesData.filter(
        (device) => device.deviceId === deviceId
      );
      return `${deviceData[0]?.deviceName} - ${deviceData[0]?.locationName} - ${deviceData[0]?.areas[0]?.areaName}`;
    }
  };

  const handleDragEnd = () => {
    if (gridRef.current) {
      const reorderedCameraIds = gridRef.current
        .getItems()
        .map((elem) => elem.getKey());
      dispatch(setLiveCameraIds(reorderedCameraIds));
    }
  };

  return (
    <div className="multi-live-camera-wrapper-image grid-container">
      {liveCameraIds?.length > 0 && (
        <MuuriComponent
          ref={gridRef}
          dragEnabled={liveCameraIds.length > 1}
          instantLayout
          //dragContainer={document.body}
          columnRef="swap"
          layout={{
            fillGaps: false,
            horizontal: false,
            alignRight: false,
            alignBottom: false,
            rounding: true,
          }}
          onDragEnd={handleDragEnd}
        >
          {liveCameraIds.map((liveCameraId, index) => (
            <div key={liveCameraId} className="liveone-wrapper grid-item">
              <ImageFetch
                moveTimeline={moveTimeline}
                liveSnapshot={liveSnapshot}
                time={time}
                cdnValue={cdnValue}
                deviceId={liveCameraId}
                camera={index + 1}
                deviceName={getDeviceInfo(liveCameraId)}
                onLiveClick={() => onClick(liveCameraId)}
              />
            </div>
          ))}
        </MuuriComponent>
      )}
    </div>
  );
};

export default MultiImageFetch;
