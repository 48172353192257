import {
  DevicesFilter,
  Header,
  SiteModal,
  SiteSpinner,
} from '../../components/common';
import DateTimePickerEvents from '../../components/common/DateTimePickerEvents';
import TimePickerEvents from '../../components/common/TimePickerEvents';
import { Col, Row } from 'react-bootstrap';
import {
  useEffect,
  useState,
  useRef,
  useCallback,
  useLayoutEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCDNInfo,
  getPlatformInfo,
  getWSSConnection,
} from '../../store/reducers/StreamingReducer';
import useEventsStore from '../../store/EventsStore';
import useEventSelectionStore from '../../store/EventSelectionStore';
import { AppDefaults, constants, Utils } from '../../helpers';
import axios from 'axios';
import moment from 'moment';
import StickyBox from 'react-sticky-box';
import InfiniteScroll from 'react-infinite-scroller';
import EventsWrapper from './EventsWrapper';
import 'react-multi-date-picker/styles/layouts/mobile.css';
import { useNavigate } from 'react-router-dom';
import { useOrganizations } from '../../store/OrganizationsStore';
import { useSearchParams } from 'react-router-dom';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import './events.scss';
import { TagStore } from '../../store/TagsStore';
import EventTimelineControls from './eventPreview/EventTimelineControls';
import './eventPreview/eventModal.scss';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { useCustomerOrgDevices } from '../../store/CustomerOrgDevicesStore';
import {
  setAllDevicesData,
  getAllDevicesData,
  getLocationsData,
  setLocationsData,
} from '../../store/reducers/AccountReducer';
import { isObject } from 'lodash';
import {
  connectWithWebSocket,
  disconnectWithWebSocket,
  sendPauseCVR,
  sendRegisterCVR,
} from '../../utils/connection/wssConnection';
import { ReactComponent as SearchIconEvents } from '../../assets/images/SearchIconEvents.svg';
import { ReactComponent as CalendarEventIcon } from '../../assets/images/CalendarEventIcon.svg';
import { ReactComponent as EventTimeClock } from '../../assets/images/EventTimeClock.svg';
import { ReactComponent as SearchAI } from '../../assets/images/SearchAI.svg';
import { ReactComponent as Tag } from '../../assets/images/Tag.svg';
import { ReactComponent as FilterIconTitle } from '../../assets/images/FilterIconTitle.svg';
import { ReactComponent as ChevronUp } from '../../assets/images/ChevronUp.svg';
import { ReactComponent as ChevronDown } from '../../assets/images/ChevronDown.svg';
import { ReactComponent as ChevronDownShowMore } from '../../assets/images/ChevronDownShowMore.svg';
import { ReactComponent as SearchLicensePlate } from '../../assets/images/SearchLicensePlate.svg';
import { ReactComponent as InformationCircle } from '../../assets/images/InformationCircle.svg';
import { ReactComponent as ScrollCalendar } from '../../assets/images/ScrollCalendar.svg';
import { ReactComponent as ArrowNarrowDown } from '../../assets/images/ArrowNarrowDown.svg';

import InputIcon from 'react-multi-date-picker/components/input_icon';
import SearchAiModal from './SearchAiModal';
import { RiErrorWarningLine } from 'react-icons/ri';
import { RxCross1, RxMagnifyingGlass } from 'react-icons/rx';
import { Drawer as MuiDrawer } from '@mui/material';
import { HiArrowDownTray } from 'react-icons/hi2';
import { styled } from '@mui/material/styles';
import { useKeycloak } from '@react-keycloak/web';

const Events = ({}) => {
  const { keycloak } = useKeycloak();
  const calendarRef = useRef(null);
  const timePickerRef = useRef(null);
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const today = new Date();
  const yesterday = new Date(today);
  let [searchParams] = useSearchParams();
  const searchParam = searchParams.get('searchtext');
  const itemsPerPage = 50;
  const [hasMoreItems, sethasMoreItems] = useState(true);
  const [showEventPreviewModal, setShowEventPreviewModal] = useState(false);
  const [searchtext, setSearchtext] = useState(searchParam);
  const [records, setrecords] = useState(itemsPerPage);
  let [eventsData, setEventsData] = useState([]);
  let [eventsTagData, setEventsTagData] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState();
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [showTimePicker, setShowTimePicker] = useState(false);
  const [aiSearchSection, setAiSearchSection] = useState(false);
  let [selectedDateTOShow, setSelectedDateTOShow] = useState(yesterday);

  let [startTimeToPass, setstartTimeToPass] = useState([0, 0, 0]);
  let [endTimeToPass, setEndTimeToPass] = useState([23, 59, 59]);

  let lastSelectedDate = useRef(yesterday);
  const requestedEventSize = 27;
  const lastSelectedTime = useRef({
    startTime: [0, 0, 0],
    endTime: [23, 59, 59],
  });
  let [lastSelectedDateSubmit, setLastSelectedDateSubmit] = useState(
    lastSelectedDate?.current
  );

  let [lastSelectedTimeSubmit, setLastSelectedTimeSubmit] = useState(
    lastSelectedTime?.current
  );
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  let [selectedAge, setSelectedAge] = useState([]);
  let [selectedFaceAcc, setSelectedFaceAcc] = useState([]);
  let [selectedGender, setSelectedGender] = useState([]);
  let [selectedAccessories, setSelectedAccessories] = useState([]);
  let [selectedPantColor, setSelectedPantColor] = useState([]);
  let [selectedShirtColor, setSelectedShirtColor] = useState([]);
  let [selectedVehicleColor, setSelectedVehicleColor] = useState([]);
  let [selectedVehicleType, setSelectedVehicleType] = useState([]);
  let [selectedSoundType, setSelectedSoundType] = useState([]);
  const cdnValue = useSelector(getCDNInfo);
  let [allLocationsData, setAllLocationsData] = useState([]);
  let [allLocationsDataInitial, setAllLocationsDataInitial] = useState([]);
  let [allDevciesData, setAllDevciesData] = useState([]);
  let [allDevciesDataInitial, setAllDevciesDataInitial] = useState([]);
  let [allAreasData, setAllAreasData] = useState([]);
  let [allAreasDataInitial, setAllAreasDataInitial] = useState([]);
  let [devicesListToPass, setDevicesListToPass] = useState([]);

  const allLocations = useSelector(getLocationsData);
  const devicesDetails = useSelector(getAllDevicesData);
  const [searchTermForLocation, setSearchTermForLocation] = useState('');
  const [searchTermForArea, setSearchTermForArea] = useState('');
  const [searchTermForDevice, setSearchTermForDevice] = useState('');
  const [visibleItemCountForLocation, setVisibleItemCountForLocation] =
    useState(10);
  const [visibleItemCountForArea, setVisibleItemCountForArea] = useState(10);
  const [visibleItemCountForDevice, setVisibleItemCountForDevice] =
    useState(10);

  const redirectToLoginPage = () => {
    localStorage.setItem('authenticated', false);
    localStorage.setItem('vmsAuthToken', null);
    keycloak.logout({
      redirectUri: process.env.REACT_APP_KEYCLOAK_LOGOUT_REDIRECT_URL,
    });
  };

  const sortList = (list) => {
    list = Array.from(new Set(list));
    return list.sort((a, b) => {
      if (typeof a === 'string' && typeof b === 'string') {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      }
      return 0;
    });
  };

  let filteredLocations =
    allLocationsData && allLocationsData?.length > 0
      ? sortList(allLocationsData?.map((location) => location.locationName))
          ?.filter((item) =>
            item.toLowerCase().includes(searchTermForLocation.toLowerCase())
          )
          .slice(0, visibleItemCountForLocation)
      : [];

  let filteredAreas =
    allAreasData && allAreasData?.length > 0
      ? sortList(allAreasData?.map((area) => area.areaName))
          ?.filter((item) =>
            item.toLowerCase().includes(searchTermForArea.toLowerCase())
          )
          .slice(0, visibleItemCountForArea)
      : [];

  let filteredDevices =
    allDevciesData && allDevciesData?.length > 0
      ? sortList(allDevciesData?.map((device) => device.deviceName))
          ?.filter((item) =>
            item.toLowerCase().includes(searchTermForDevice.toLowerCase())
          )
          .slice(0, visibleItemCountForDevice)
      : [];

  const getTagsData = TagStore((state) => state.getTagsData);
  const setTagsData = TagStore((state) => state.setTagsData);
  const getTagsList = TagStore((state) => state.getTagsList);
  const setTagsList = TagStore((state) => state.setTagsList);
  const setSelectedTags = TagStore((state) => state.setSelectedTags);
  // Entities categories
  const [young, setYoung] = useState(false);
  const [adult, setAdult] = useState(false);
  const [middle, setMiddle] = useState(false);
  const [senior, setSenior] = useState(false);
  const [male, setMale] = useState(false);
  const [female, setFemale] = useState(false);
  const [hat, setHat] = useState(false);
  const [bag, setBag] = useState(false);
  const [optical, setOptical] = useState(false);
  const [mask, setMask] = useState(false);
  const [car, setCar] = useState(false);
  const [bus, setBus] = useState(false);
  const [truck, setTruck] = useState(false);
  const [moterCycle, setMoterCycle] = useState(false);
  const [bicycle, setBicycle] = useState(false);
  const [glassBreak, setGlassBreak] = useState(false);
  const [scream, setScream] = useState(false);
  const [fireAlarm, setFireAlarm] = useState(false);
  const [gunshot, setGunshot] = useState(false);
  const [explosion, setExplosion] = useState(false);
  const [number, setNumber] = useState(false);

  const [pantColorRed, setPantColorRed] = useState(false);
  const [pantColorBlue, setPantColorBlue] = useState(false);
  const [pantColorYellow, setPantColorYellow] = useState(false);
  const [pantColorGreen, setPantColorGreen] = useState(false);
  const [pantColorBlack, setPantColorBlack] = useState(false);
  const [pantColorGray, setPantColorGray] = useState(false);
  const [pantColorWhite, setPantColorWhite] = useState(false);

  const [shirtColorRed, setShirtColorRed] = useState(false);
  const [shirtColorBlue, setShirtColorBlue] = useState(false);
  const [shirtColorYellow, setShirtColorYellow] = useState(false);
  const [shirtColorGreen, setShirtColorGreen] = useState(false);
  const [shirtColorBlack, setShirtColorBlack] = useState(false);
  const [shirtColorGray, setShirtColorGray] = useState(false);
  const [shirtColorWhite, setShirtColorWhite] = useState(false);

  const [vehicleColorRed, setVehicleColorRed] = useState(false);
  const [vehicleColorBlue, setVehicleColorBlue] = useState(false);
  const [vehicleColorYellow, setVehicleColorYellow] = useState(false);
  const [vehicleColorGreen, setVehicleColorGreen] = useState(false);
  const [vehicleColorBlack, setVehicleColorBlack] = useState(false);
  const [vehicleColorGray, setVehicleColorGray] = useState(false);
  const [vehicleColorWhite, setVehicleColorWhite] = useState(false);

  // Entities categories selected
  let [ageListSelected, setAgeListSelected] = useState([]);
  let [faceAccListSelected, setFaceAccListSelected] = useState([]);
  let [personAccListSelected, setPersonAccListSelected] = useState([]);
  let [personPantListSelected, setPersonPantListSelected] = useState([]);
  let [personShirtListSelected, setPersonShirtListSelected] = useState([]);
  let [vehicleColorListSelected, setVehicleColorListSelected] = useState([]);
  let [vehicleTypeListSelected, setVehicleTypeListSelected] = useState([]);
  let [soundTypeListSelected, setSoundTypeListSelected] = useState([]);
  let [genderListSelected, setGenderListSelected] = useState([]);

  let [tags, setTags] = useState({});
  let [person, setPerson] = useState({});
  let [face, setFace] = useState({});
  let [vehicle, setVehicle] = useState({});
  let [sound, setSound] = useState({});
  let [motion, setMotion] = useState({});
  let [licensePlate, setLicensePlate] = useState({});

  const [faceEventTypeIsActive, setFaceEventTypeIsActive] = useState(false);
  const [personEventTypeIsActive, setPersonEventTypeIsActive] = useState(false);
  const [vehicleEventTypeIsActive, setVehicleEventTypeIsActive] =
    useState(false);
  const [lPEventTypeIsActive, setLPEventTypeIsActive] = useState(false);
  const [audioEventTypeIsActive, setAudioEventTypeIsActive] = useState(false);
  const [motionEventTypeIsActive, setMotionEventTypeIsActive] = useState(false);

  const {
    getEvents,
    setEvents,
    removeEvents,
    setEventTotal,
    getEventTotal,
    setEventCategory,
    setSelectedEventStore,
    getSelectedEvent,
    setEventFromSearch,
    setModalEventContent,
    getModalEventContent,
    getModalIndexContent,
    setEventCVRMode,
    getEventCVRMode,
    getEventsLoading,
  } = useEventsStore();
  
  const {
    getPreSelectedLocation,
    setPreSelectedLocation,
    removePreSelectedLocations,
    getPreSelectedArea,
    setPreSelectedArea,
    removePreSelectedArea,
    getPreSelectedDevice,
    setPreSelectedDevice,
    removePreSelectedDevice,
    getPreSelectedDeviceListToPass,
    setPreSelectedDeviceListToPass,
    removePreSelectedDeviceListToPass,
    setNavigatedFromCameraDetail,
    getNavigatedFromCameraDetail,
    setSelectedData,
    getSelectedData,
    removeSelectedData,
    setPreSelectedAllLocationsData,
    setPreSelectedAllAreasData,
    setPreSelectedAllDevicesData,
    setPreSelectedIsLocationListVisible,
    setPreSelectedIsAreaListVisible,
    setPreSelectedIsDeviceListVisible,
    setPreSelectedFilteredLocations,
    setPreSelectedFilteredAreas,
    setPreSelectedFilteredDevices,
    setPreSelectedVisibleItemCountForLocation,
    setPreSelectedVisibleItemCountForArea,
    setPreSelectedVisibleItemCountForDevice,
    setPreSelectedLocations,
    setPreSelectedAreas,
    setPreSelectedDevices,    
    setPreSelectedEventsData,
    setPreSelectedEventTotal, 
    setPreSelectedDateToShow,
    setPreSelectedLastSelectedDate,
    setPreSelectedLastSelectedDateSubmit,
    setPreSelectedLastSelectedTimeSubmit,
    setPreSelectedFilterDate,
    setPreSelectedSelectedTime,
    setPreSelectedStartTimeOfEvent,
    setPreSelectedEndTimeOfEvent,
    setPreSelectedStartTimePeriod,
    setPreSelectedEndTimePeriod,
    setPreSelectedEndTimeToPass,
    setPreSelectedStartTimeToPass,
    getPreSelectedAllLocationsData,
    getPreSelectedAllAreasData,
    getPreSelectedAllDevicesData,
    getPreSelectedIsLocationListVisible,
    getPreSelectedIsAreaListVisible,
    getPreSelectedIsDeviceListVisible,
    getPreSelectedFilteredLocations,
    getPreSelectedFilteredAreas,
    getPreSelectedFilteredDevices,
    getPreSelectedVisibleItemCountForLocation,
    getPreSelectedVisibleItemCountForArea,
    getPreSelectedVisibleItemCountForDevice,
    getPreSelectedLocations,
    getPreSelectedAreas,
    getPreSelectedDevices,
    getPreSelectedEventsData,
    getPreSelectedDateToShow,
    getPreSelectedLastSelectedDate,
    getPreSelectedLastSelectedDateSubmit,
    getPreSelectedLastSelectedTimeSubmit,
    getPreSelectedFilterDate,
    getPreSelectedSelectedTime,
    getPreSelectedStartTimeOfEvent,
    getPreSelectedEndTimeOfEvent,
    getPreSelectedStartTimePeriod,
    getPreSelectedEndTimePeriod,
    getPreSelectedEndTimeToPass,
    getPreSelectedStartTimeToPass,
    resetStore,
  } = useEventSelectionStore();

  const [filterDate, setFilterDate] = useState([]);
  const [showCustomRange, setShowCustomRange] = useState(false);
  const [tagRemove, setTagRemove] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [timezone, setTimezone] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [startTimeEvent, setStartTimeEvent] = useState(null);
  const [cdnValueInfo, setCDNValueInfo] = useState(null);
  const [nextPreviousIcon, setNextPreviousIcon] = useState(null);
  const [selectedTime, setSelectedTime] = useState(0);
  const [devices, setDevices] = useState([]);
  const [updatedDevice, setUpdatedDevice] = useState([]);
  const [deviceFilterList, setDeviceFilterList] = useState([]);
  const [isLoadingEvents, setIsLoadingEvents] = useState(false);
  const [isLoadingEventsShowMore, setIsLoadingEventsShowMore] = useState(false);
  const [ShowMoreFooterFlag, setShowMoreFooterFlag] = useState(true);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [allCamera, setAllCameraLabel] = useState(
    constants.ANALYTICS_DASHBOARD_ALL_CAMERAS_LINK
  );
  const getPlatformDetails = useSelector(getPlatformInfo);
  const wssConnection = useSelector(getWSSConnection);
  const { fetchCustomerOrgDevices, getCustomerOrgDevices } =
    useCustomerOrgDevices();
  const [searchLabelText, setSearchLabelText] = useState(
    constants.SEARCH_EVENTS_ALL_RESULTS
  );
  const [show, setShow] = useState(false);
  const [dateTimePickerShow, setDateTimePickerShow] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const dispatch = useDispatch();
  const [timeOptions, setTimeOptions] = useState(Utils.getTimeFilters());
  const [eventCount, setEventCounts] = useState(0);
  const getCustomerOrgData = useOrganizations(
    (state) => state.getCustomerOrgData
  );
  let custOrg = getCustomerOrgData();
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const { setSelectedDeviceFromFillter, getSelectedDeviceFromFillter } =
    useCustomerOrgDevices();

  const {
    getSelectedLocation,
    setLocationSelection,
    getCustomerOrgLocationsData,
    getCustomerOrgLocations,
  } = useCustomerOrgLocations();
  const selectedLocation = getSelectedLocation(loggedInUserData?.accountId);
  const custOrgData = custOrg[0];

  const navigate = useNavigate();
  const deviceTimezone = moment.tz.guess();
  const modalContent = getModalEventContent();
  const indexContent = getModalIndexContent();

  const [incidentDate, setIncidentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [incidentDateTime, setIncidentDateTime] = useState(
    moment().format('YYYY-MM-DDTHH:mm')
  );

  const [dateIsChanged, setDateIsChanged] = useState(false);
  const [isLocationListVisible, setIsLocationListVisible] = useState(true);
  const [isAreaListVisible, setIsAreaListVisible] = useState(true);
  const [isDeviceListVisible, setIsDeviceListVisible] = useState(true);
  const [isAgeListVisible, setIsAgeListVisible] = useState(false);
  const [isFaceAccListVisible, setIsFaceAccListVisible] = useState(false);
  const [isGenderListVisible, setIsGenderListVisible] = useState(false);
  const [isAccessoriesListVisible, setIsAccessoriesListVisible] =
    useState(false);
  const [isPantColorListVisible, setIsPantColorListVisible] = useState(false);
  const [isShirtColorListVisible, setIsShirtColorListVisible] = useState(false);
  const [isVehicleColorListVisible, setIsVehicleColorListVisible] =
    useState(false);
  const [isVehicleTypeListVisible, setIsVehicleTypeListVisible] =
    useState(false);
  const [isLicensePlateListVisible, setIsLicensePlateListVisible] =
    useState(false);
  const [isSoundTypeListVisible, setIsSoundTypeListVisible] = useState(false);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString(
      'en-US',
      options
    );
    return formattedDate;
  };

  const [aiSearchLabel, setAiSearchLabel] = useState();
  const dateToday = formatDate(new Date());
  const date = new Date();
  const initialStartTime = '12:00:00';
  const initialEndTime = '11:59:59';
  let [selectedEventType, setSelectedEventType] = useState(null);
  let [searchTermForLicense, setSearchTermForLicense] = useState('');

  let selectedGenderToCheckUnCheck = null;

  let [startTimeOfEvent, setStartTimeOfEvent] = useState(initialStartTime);
  let [endTimeOfEvent, setEndTimeOfEvent] = useState(initialEndTime);
  let [startTimePeriod, setStartTimePeriod] = useState('am');
  let [endTimePeriod, setEndTimePeriod] = useState('pm');
  let [startDateTimeTimeStamp, setStartDateTimeTimeStamp] = useState();
  let [endDateTimeTimeStamp, setEndDateTimeTimeStamp] = useState();

  const calculateTimeStamps = (selectedDate, startTime, endTime) => {
    let currentSelectedDate;
    let startTimeArg, endTimeArg;
    let selectedDateString = JSON.stringify(selectedDate);

    if (selectedDateString?.includes('undefined')) {
      currentSelectedDate = yesterday;
    } else {
      currentSelectedDate = selectedDate;
    }

    if (currentSelectedDate) {
      const dateParts = JSON.stringify(currentSelectedDate)?.split(' - ');
      const startDatePart = new Date(dateParts[0]);
      const endDatePart = new Date(dateParts[1]);

      if (
        !startDatePart ||
        !endDatePart ||
        isNaN(startDatePart) ||
        isNaN(endDatePart)
      ) {
        startTimeArg = new Date(
          moment.tz(new Date(currentSelectedDate), timezone).set({
            hour: startTime[0],
            minute: startTime[1],
            second: startTime[2],
            millisecond: 0,
          })
        ).getTime();

        endTimeArg = new Date(
          moment.tz(new Date(currentSelectedDate), timezone).set({
            hour: endTime[0],
            minute: endTime[1],
            second: endTime[2],
            millisecond: 0,
          })
        ).getTime();
      } else if (startDatePart && endDatePart) {
        startTimeArg = new Date(
          moment.tz(new Date(startDatePart), timezone).set({
            hour: startTime[0],
            minute: startTime[1],
            second: startTime[2],
            millisecond: 0,
          })
        ).getTime();

        endTimeArg = new Date(
          moment.tz(new Date(endDatePart), timezone).set({
            hour: endTime[0],
            minute: endTime[1],
            second: endTime[2],
            millisecond: 0,
          })
        ).getTime();
      }
    }

    return [startTimeArg, endTimeArg];
  };

  const eventsAllWithStartAndEndDateTime = () => {
    let startTimeArg = 0,
      endTimeArg = 0;
    let calculateTimeStampsValues = calculateTimeStamps(
      selectedDateTOShow,
      startTimeToPass,
      endTimeToPass
    );
    startTimeArg = calculateTimeStampsValues[0];
    endTimeArg = calculateTimeStampsValues[1];
    setEvents(
      `timeline/orgs/${custOrgData?.orgId}/events/all?${
        startTimeArg !== 0 ? `startTime=${startTimeArg}&` : ''
      }endTime=${endTimeArg}&size=${requestedEventSize}${
        allDevciesData?.length > 0 ? getDeviceId(allDevciesData) : []
      }`
    );
  };

  useEffect(() => {
    if (process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON) {
      setAiSearchLabel(constants.EVENT_AI_SEARCH_LABEL_MEGATRON);
    } else if (process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_DUCLO) {
      setAiSearchLabel(constants.EVENT_AI_SEARCH_LABEL_DUCLO);
    }
    setLocationSelection(loggedInUserData?.accountId, AppDefaults.ALL_LOCATIONS_ID);
    const navigatedFromCameraDetail  = getNavigatedFromCameraDetail();
    // Pre selection on page load starts
    if(navigatedFromCameraDetail){
      allSetMethodsAfterRedirection();
    }else{
      resetStore();
    }    
    // Pre selection on page load ends
  }, []);

  const preSelectionAfterCameraDetail = (selectedData) => {
    const resultArray = [];
    if(selectedData?.face){
      if(selectedData?.face?.accessories?.includes('glasses')){
        const index = selectedData.face.accessories.indexOf('glasses');
        if (index !== -1) {
            selectedData.face.accessories[index] = 'opticals';
        }
      } 
      if(selectedData?.face?.genders?.includes('male')){
        const index = selectedData.face.genders.indexOf('male');
        if (index !== -1) {
            selectedData.face.genders[index] = 'male gender';
        }
        selectedGender.push('Male');       
      } 
      if(selectedData?.face?.genders?.includes('female')){
        const index = selectedData.face.genders.indexOf('female');
        if (index !== -1) {
            selectedData.face.genders[index] = 'female gender';
        }
        selectedGender.push('Female');
      } 
      if(selectedData?.face){
        for (const key in selectedData?.face) {
            if (selectedData.face?.[key]) {
              selectedData?.face[key]?.forEach(value => {
                resultArray?.push({
                    category: "face",
                    text: value
                });
            });
          }
        }
      }   
    }else if(selectedData?.person){
      if(selectedData?.person?.genders?.includes('male')){
        const index = selectedData.person.genders.indexOf('male');
        if (index !== -1) {
            selectedData.person.genders[index] = 'male gender';
        }
      } 
      if(selectedData?.person?.genders?.includes('female')){
        const index = selectedData.person.genders.indexOf('female');
        if (index !== -1) {
            selectedData.person.genders[index] = 'female gender';
        }
      } 
      for (const key in selectedData?.person) {
        if(selectedData?.person[key]){
          selectedData?.person[key]?.forEach(value => {
            if(key === 'pantsColors'){
              resultArray?.push({
                category: "person",
                text: value+' bottom'
            });
            }else if(key === 'shirtColors'){
              resultArray?.push({
                category: "person",
                text: value+' top'
            });
            }else{
              resultArray?.push({
                category: "person",
                text: value
            });
            }
          });
        }
      }
    }else if(selectedData?.vehicle){
      const vehicleExtColors = [
        'red',
        'blue',
        'yellow',
        'green',
        'black',
        'gray',
        'white',
      ];
      const vehicleType = ['car', 'bicycle', 'bus', 'motorcycle', 'truck'];
      const selectedVehicleExtColors = selectedData?.vehicle?.extColors?.length > 0 ? selectedData?.vehicle?.extColors?.filter(item => vehicleExtColors.includes(item)) : [];
      const selectedVehicleType = selectedData?.vehicle?.types?.length > 0 ? selectedData?.vehicle?.types?.filter(item => vehicleType.includes(item)) : [];
      if (selectedVehicleExtColors?.length > 0 && selectedVehicleType?.length > 0) {
        selectedVehicleExtColors?.forEach(color => {
            selectedVehicleType?.forEach(vehicle => {
                resultArray?.push({
                    category: "vehicle",
                    text: `${color} ${vehicle}`
                });
            });
        });
      }else if(selectedVehicleExtColors?.length > 0 && selectedVehicleType?.length === 0){
        selectedVehicleExtColors?.forEach(color => {
          resultArray?.push({
            category: "vehicle",
            text: color
          });
        });
      }else if(selectedVehicleExtColors?.length === 0 && selectedVehicleType?.length > 0){
        selectedVehicleType?.forEach(vehicle => {
          resultArray?.push({
            category: "vehicle",
            text: vehicle
          });
        });
      }
    }else if(selectedData?.sound){
      resultArray?.push({
        category: "sound",
        text: ''
      });
    }else if(selectedData?.licensePlate){
      resultArray?.push({
        category: "licenseplate",
        text: ''
      });
    }else if(selectedData?.motion){
      resultArray?.push({
        category: "motion",
        text: ''
      });
    } 
    if (
      selectedData &&
      typeof selectedData === 'object' &&
      Object.keys(selectedData).length === 1 &&
      selectedData.hasOwnProperty('face') &&
      typeof selectedData.face === 'object' &&
      Object.keys(selectedData.face).length === 0
    ) {
      resultArray?.push({
        category: 'face',
        text: '',
      });
    } 
    if (
      selectedData &&
      typeof selectedData === 'object' &&
      Object.keys(selectedData).length === 1 &&
      selectedData.hasOwnProperty('person') &&
      typeof selectedData.person === 'object' &&
      Object.keys(selectedData.person).length === 0
    ) {
      resultArray?.push({
        category: 'person',
        text: '',
      });
    } 
    if (
      selectedData &&
      typeof selectedData === 'object' &&
      Object.keys(selectedData).length === 1 &&
      selectedData.hasOwnProperty('vehicle') &&
      typeof selectedData.vehicle === 'object' &&
      Object.keys(selectedData.vehicle).length === 0
    ) {
      resultArray?.push({
        category: 'vehicle',
        text: '',
      });
    } 
    preSelectionOfTags(resultArray);
  }

  useEffect(() => {
    const orgDevices = getCustomerOrgDevices();
    const navigatedFromCameraDetail  = getNavigatedFromCameraDetail();
    if (orgDevices) {
      let filteredDevices = orgDevices?.filter(
        (item) =>
          item.areas && item.areas.length > 0 && item.deviceStatus === 'CLAIMED'
      );
      if(!navigatedFromCameraDetail){
        setAllDevciesData(filteredDevices);
      }
      setAllDevciesDataInitial(filteredDevices);
    } else {
      let filteredDevices = devicesDetails?.filter(
        (item) =>
          item.areas && item.areas.length > 0 && item.deviceStatus === 'CLAIMED'
      );
      if(!navigatedFromCameraDetail){
        setAllDevciesData(filteredDevices);
      }      
      setAllDevciesDataInitial(filteredDevices);
    }
    if(!navigatedFromCameraDetail){
      setSelectedDateTOShow(formatDate(yesterday));
    }else{
      lastSelectedDate['current'] = getPreSelectedLastSelectedDateSubmit();
      setSelectedDateTOShow(getPreSelectedLastSelectedDateSubmit());
      setLastSelectedDateSubmit(getPreSelectedLastSelectedDateSubmit());
    }
  }, [devicesDetails]);

  useEffect(() => {
    const formattedSoundTypes = selectedSoundType.map(type => {
      return type.toLowerCase()
      .replace('glass break', 'glass-breaking')
      .replace('gunshot', 'gun-shot');
    });
    const allTags  = getTagsData()?.tags;
    const filteredTags = allTags?.sound?.types?.filter(item => formattedSoundTypes.includes(item));
    setSelectedData(filteredTags);
  }, [selectedSoundType]);

  useEffect(() => {}, [selectedDateTOShow]);

  const handleEventTypeSelection = (item) => {
    if (selectedEventType === item) {
      // preventing unselection of event
      return;
    } else {
      applyTag(item);
      setSelectedEventType((prevSelectedEventType) =>
        prevSelectedEventType === item ? null : item
      );
    }
  };

  const handleDateChange = (value) => {
    if (!value) return;
    setIncidentDate(value);
    setSelectedDate(value);
    setShowDateTimePicker(false);
    if (
      selectedDateTOShow?.includes('undefined')
    ) {
      if(value?.selectedDate[0] &&
        value?.selectedDate[1]){
          const startDatePart = formatDate(value?.selectedDate[0]);
          const endDatePart = formatDate(value?.selectedDate[1]);
          const convertedDate = startDatePart + ' - ' + endDatePart;
          selectedDateTOShow = convertedDate;
          setSelectedDateTOShow(convertedDate);
      }else{
        const isValidDate = (new Date(value?.selectedDate)).toString() !== 'Invalid Date';
        if(isValidDate){
          setSelectedDateTOShow(formatDate(value?.selectedDate));
        }else{
          selectedDateTOShow = lastSelectedDateSubmit;
        }
      }   
    }
    let calculateTimeStampsValues = calculateTimeStamps(
      selectedDateTOShow,
      startTimeToPass,
      endTimeToPass
    );

    if (lastSelectedDate?.current) {
      lastSelectedDate.current = selectedDateTOShow;
    }

    lastSelectedDate = selectedDateTOShow;
    setLastSelectedDateSubmit(selectedDateTOShow);
    setPreSelectedLastSelectedDateSubmit(selectedDateTOShow);

    let startTimeArg = calculateTimeStampsValues[0];
    let endTimeArg = calculateTimeStampsValues[1];
    if (
      Object.keys(eventsTagData)?.includes('tags') ||
      selectedEventType !== null
    ) {
      fillterByTagApiCall(startTimeArg, eventsTagData);
    } else {
      fetchRecordsViaCalendar(startTimeArg, endTimeArg);
    }
  };

  const fetchLocations = async () => {
    const devices = getCustomerOrgDevices();
    try {
      await getCustomerOrgLocations(
        `partner/orgs/${custOrgData?.orgId}/locations`
      );

      let locations = await getCustomerOrgLocationsData();
      const navigatedFromCameraDetail  = getNavigatedFromCameraDetail();
      if(!navigatedFromCameraDetail){
        setAllLocationsData(locations);
      }
      setAllLocationsDataInitial(locations);

      let areaList = [];
      if (locations) {
        locations?.forEach((location) => {
          areaList = [...areaList, ...location?.areas];
        });
      }
      if(!navigatedFromCameraDetail){
        setAllAreasData(areaList);
      }
      setAllAreasDataInitial(areaList);
    } catch (error) {
      setUserMsg(error);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  useEffect(() => {
    lastSelectedDate.current = lastSelectedDateSubmit;
  }, [lastSelectedDateSubmit]);

  useEffect(() => {
    lastSelectedTime.current = lastSelectedTimeSubmit;
  }, [lastSelectedTimeSubmit]);

  // useEffect(() => {
  //   selectedDateTOShow = lastSelectedDate;
  //   setSelectedDateTOShow(lastSelectedDate)
  // },[showDateTimePicker]);

  // useEffect(() => {
  //   console.log("showTimePicke: ",showTimePicker);
  // },[showTimePicker]);

  const handleTimeChange = (value) => {
    if (!value) return;
    setShowTimePicker(false);
    const navigatedFromCameraDetail  = getNavigatedFromCameraDetail();
    if(navigatedFromCameraDetail){
      selectedDateTOShow = lastSelectedDateSubmit;
    }
    let calculateTimeStampsValues = calculateTimeStamps(
      selectedDateTOShow,
      startTimeToPass,
      endTimeToPass
    );

    if (lastSelectedTime?.current) {
      lastSelectedTime.current = {
        startTime: startTimeToPass,
        endTime: endTimeToPass,
      };
    }

    let startTimeArg = calculateTimeStampsValues[0];
    let endTimeArg = calculateTimeStampsValues[1];
    if (
      Object.keys(eventsTagData)?.includes('tags') ||
      selectedEventType !== null
    ) {
      fillterByTagApiCall(startTimeArg, eventsTagData);
    } else {
      fetchRecordsViaCalendar(startTimeArg, endTimeArg);
    }
  };

  useEffect(() => {
    setCDNValueInfo(cdnValue);
  }, [cdnValue]);

  useEffect(() => {
    setEventCounts(getEventTotal());
  }, [getEventTotal()]);

  useEffect(() => {
    if (getPlatformDetails && wssConnection && selectedDevice) {
      sendRegisterCVR(
        selectedDevice,
        loggedInUserData?.accountId,
        custOrgData?.orgId
      );
    }
  }, [getPlatformDetails, wssConnection, selectedDevice]);

  const fetchDevices = useCallback(async () => {
    try {
      // Get all locations for customer's organization
      // await getCustomerOrgLocations(
      //   `partner/orgs/${custOrgData?.orgId}/locations`
      // );

      // Fetch the list of devices associated with
      // the user
      let fetchResult = await fetchCustomerOrgDevices(
        `device/orgs/${custOrgData?.orgId}/devices`
      );

      const orgLocations = getCustomerOrgLocationsData();

      const locationIds = new Set();
      let deviceArea, currentArea, cameraDevice;
      if (fetchResult.status === 'success') {
        const devicesCamera = getCustomerOrgDevices();
        const cameraDevices = Utils.getTotalChildDevices(devicesCamera);
        const locationAreas = [];

        // Retrieve the location name for each device
        for (let i = 0; i < cameraDevices?.length; i++) {
          cameraDevice = cameraDevices[i];
          locationIds.add(cameraDevice?.locationId);

          currentArea = orgLocations.find(
            (orgLocation) => orgLocation.locationId === cameraDevice?.locationId
          );

          if (
            locationAreas.findIndex(
              (locationArea) =>
                locationArea.locationId === cameraDevice?.locationId
            ) === -1
          ) {
            deviceArea = {
              deviceId: cameraDevice?.deviceId,
              areas: [...currentArea?.areas],
            };

            locationAreas.push({
              locationId: cameraDevice?.locationId,
              areas: [deviceArea],
            });
          }

          cameraDevices[i].locationName = currentArea?.locationName;

          cameraDevices[i].areas = [
            ...locationAreas.find(
              (locationArea) =>
                locationArea.locationId === cameraDevices[i].locationId
            ).areas[0].areas,
          ];

          cameraDevices[i].style = null;
        }
        await new Promise((resolve) => {
          setUpdatedDevice(cameraDevices?.length > 0 ? [...cameraDevices] : []);
          dispatch(
            setAllDevicesData(
              cameraDevices?.length > 0 ? [...cameraDevices] : []
            )
          );
          resolve();
        });

        let filteredDevices;

        if (selectedLocation && isObject(selectedLocation)) {
          if (selectedLocation?.locationId !== AppDefaults.ALL_LOCATIONS_ID) {
            filteredDevices = cameraDevices?.filter(
              (device) => device?.locationId === selectedLocation?.locationId
            );
          } else {
            filteredDevices =
              cameraDevices?.length > 0 ? [...cameraDevices] : [];
          }
        }
        await new Promise((resolve) => {
          setDevices(
            filteredDevices != undefined && filteredDevices?.length > 0
              ? [...filteredDevices]
              : [...cameraDevices]
          );
        });
      } else {
        setShowToast(true);
        setUserMsg(fetchResult.msg);
      }
    } catch (err) {
      setShowToast(true);
      setUserMsg(err.msg);
    }
  }, []);

  useLayoutEffect(() => {
    fetchDevices();
  }, []);

  const filterContent = (
    <>
      <DevicesFilter
        devices={devices}
        filterDescription={
          constants.CAMERAS_VIDEO_WALL_FILTER_MODAL_DESCRIPTION
        }
        applyButtonLabel={
          constants.CAMERAS_VIDEO_WALL_FILTER_MODAL_APPLY_FILTER_LABEL
        }
        disableBackgroundColor={true}
        clearFilterPlacement="bottom"
        callback={(deviceData, status) => {
          updateList(deviceData, status);
          setShow(false);
        }}
      />
    </>
  );

  const updateList = (deviceDataList, status) => {
    if (deviceDataList?.length > 0) {
      if (status) {
        setDeviceFilterList(devices);
        setDevices(devices);
        setSelectedDeviceFromFillter([]);
      } else {
        if (deviceDataList?.length !== 0) {
          let deviceData = [];
          updatedDevice?.map((deviceItem, index) => {
            const deviIdExist = deviceDataList?.find(
              (item) => item === deviceItem?.deviceId
            );
            if (deviIdExist) {
              deviceData.push(deviceItem);
            }
          });
          setDeviceFilterList(deviceData);
          setSelectedDeviceFromFillter(deviceDataList);
        }
      }
    } else {
      let deviceData = [];
      updatedDevice?.map((deviceItem, index) => {
        deviceData.push(deviceItem?.deviceId);
      });
      setDeviceFilterList(devices);
      setSelectedDeviceFromFillter(deviceData);
    }
  };

  const getDeviceId = (deviceList) => {
    let deviceID = '';
    const orgDevice =
      deviceList && deviceList?.length === 0
        ? custOrgData?.devices
        : deviceList;
    const location = selectedLocation;
    if (location) {
      const devicesList =
        location?.locationId === AppDefaults.ALL_LOCATIONS_ID
          ? orgDevice
          : orgDevice?.filter(
              (device) => device.locationId === location?.locationId
            );
      const fillterList =
        deviceFilterList?.length > 0 ? deviceFilterList : devicesList;
      fillterList &&
        fillterList.length > 0 &&
        fillterList?.forEach((device, i) => {
          deviceID += '&deviceIds=' + device.deviceId;
        });
      return deviceID;
    } else {
      const fillterList =
        deviceFilterList?.length > 0 ? deviceFilterList : orgDevice;
      let deviceID = '';

      if (Array.isArray(fillterList) && fillterList.length > 0) {
        fillterList.forEach((device, i) => {
          deviceID += '&deviceIds=' + device.deviceId;
        });
      }
      return deviceID;
    }
  };

  const getDeviceIdForEvents = (deviceList) => {
    let deviceID = '';
    deviceList &&
      deviceList.length > 0 &&
      deviceList?.forEach((device, i) => {
        deviceID += '&deviceIds=' + device;
      });
    return deviceID;
  };

  useEffect(() => {
    // call clear all  in start when page loads
    let timeStampSelectedDateStartTime = new Date(formatDate(yesterday));
    let timeStampSelectedDateEndTime = new Date(formatDate(yesterday));
    timeStampSelectedDateStartTime?.setHours(0, 0, 0);
    timeStampSelectedDateEndTime?.setHours(23, 59, 59);
    let allFilteredDevices = Utils.getTotalChildDevices(
      getCustomerOrgDevices()
    );
    let filteredDevices = allFilteredDevices?.filter(
      (item) => item.deviceStatus === 'CLAIMED'
    );

    const navigatedFromCameraDetail  = getNavigatedFromCameraDetail();
    if(!navigatedFromCameraDetail){
      setAllDevciesData(filteredDevices);
    }   
    setAllDevciesDataInitial(filteredDevices);

    let initialDeviceList = [];
    filteredDevices?.forEach((location) => {
      initialDeviceList.push(location?.deviceId);
    });
    devicesListToPass = initialDeviceList;
    setDevicesListToPass(initialDeviceList);

    let allDevicesListInSingleLine = getDeviceIdForEvents(initialDeviceList);

    if (allDevicesListInSingleLine.length > 0 && !navigatedFromCameraDetail) {
      setEvents(
        `timeline/orgs/${custOrgData?.orgId}/events/all?${
          timeStampSelectedDateStartTime?.getTime()
            ? `startTime=${timeStampSelectedDateStartTime?.getTime()}&`
            : ''
        }endTime=${
          timeStampSelectedDateEndTime?.getTime()
            ? timeStampSelectedDateEndTime?.getTime()
            : timeStampSelectedDateStartTime?.getTime()
        }&size=${requestedEventSize}${allDevicesListInSingleLine}`
      );
    }
  }, []);

  const setStoreEvents = (startTime) => {
    if (!checkDeviceIdStatus()) {
      setEventsData([]);
      return;
    }
    setTagsData({});
    setSearchtext('');
    eventsAllWithStartAndEndDateTime();
  };

  useEffect(() => {
    const isSearch = searchtext !== null && searchtext !== '';
    if (!isSearch && getEvents() && getEvents().length > 0) {
      setIsLoadingEvents(true);
      const navigatedFromCameraDetail  = getNavigatedFromCameraDetail();
      if(!navigatedFromCameraDetail){
        setEventsData(getEvents());
      }
      setIsLoadingEvents(false);
    }
  }, [getEvents()]);

  useEffect(() => {
    const deviceList = getCurrentLocationDevices();
    if (deviceList?.length > 0) {
      setDevices(deviceList);
    } else {
      setDevices([]);
    }
  }, [selectedLocation]);

  const getCurrentLocationDevices = () => {
    const orgDevice = updatedDevice;
    const location = selectedLocation;
    if (isObject(location)) {
      const devicesList =
        location?.locationId === AppDefaults.ALL_LOCATIONS_ID
          ? orgDevice
          : orgDevice?.filter(
              (device) => device.locationId === location?.locationId
            );
      return devicesList;
    } else {
      return orgDevice;
    }
  };

  const checkDeviceIdStatus = () => {
    let isDeviceExist = false;
    if (getDeviceId(devices) !== '') {
      isDeviceExist = true;
    }
    return isDeviceExist;
  };

  const getTimeZoneFromLocation = () => {
    let timeZone = 'UTC0';
    if (selectedLocation?.locationId === AppDefaults.ALL_LOCATIONS_ID) {
      if (selectedLocation?.filteredLocationData?.length === 1) {
        timeZone = selectedLocation?.filteredLocationData[0]?.timezone;
      }
    } else {
      timeZone = selectedLocation?.filteredLocationData[0]?.timezone;
    }
    return timeZone;
  };

  const searchByParam = async (searchtext, startTime, endTime) => {
    if (!checkDeviceIdStatus()) {
      setEventsData([]);
      return;
    }

    const filteredAllDevices = allDevciesData?.filter(item => item?.deviceStatus === 'CLAIMED');
    const filteredAllDevicesIds = filteredAllDevices?.map(item => item?.deviceId);

    setIsLoadingEvents(true);
    setTagsData({});
    let response;
    response = await axios.get(
      `timeline/orgs/${
        custOrgData?.orgId
      }/events/search/v2?${`startTime=${startTime}&`}endTime=${endTime}${getDeviceIdForEvents(
        filteredAllDevicesIds
      )}&size=${requestedEventSize}&timezone=${getTimeZoneFromLocation()}&searchText=${searchtext}`
    );

    const responseData = response?.data;
    if (responseData?.meta?.code === 200) {
      // clear all locations areas and devices related checkboxes and load initial data
      setSelectedEventType(null);
      setSelectedLocations([]);
      setSelectedAreas([]);
      setSelectedDevices([]);

      allLocationsData = allLocationsDataInitial;
      allAreasData = allAreasDataInitial;
      allDevciesData = allDevciesDataInitial;
      setAllLocationsData(allLocationsDataInitial);
      setAllAreasData(allAreasDataInitial);
      setAllDevciesData(allDevciesDataInitial);

      setShowMoreFooterFlag(true);
      setEventsData(responseData?.data?.events);

      setEventTotal(responseData?.data?.total);
      setIsLoadingEvents(false);
      const tagList = responseData?.data?.tagDetails;

      // section for new tag creation starts
      preSelectionOfTags(tagList);
    }else if(responseData?.meta?.code === 401){
      redirectToLoginPage();
    }
  };

  const preSelectionOfTags = (tagList) => {
    if (tagList?.length > 0) {
      // clear pre selected categories in filter section
      if (tags.face) {
        tags.face = {};
      }
      if (tags.person) {
        tags.person = {};
      }
      if (tags.vehicle) {
        tags.vehicle = {};
      }
      if (tags.licensePlate) {
        tags.vehicle = {};
      }
      if (tags.sound) {
        tags.sound = {};
      }
      if (tags.motion) {
        tags.motion = {};
      }
      setSelectedEventType(null);

      ageListSelected = [];
      faceAccListSelected = [];
      selectedAge = [];
      selectedFaceAcc = [];

      genderListSelected = [];
      selectedGender = [];
      setSelectedGender([]);

      selectedAccessories = [];
      personAccListSelected = [];
      personPantListSelected = [];
      personShirtListSelected = [];

      selectedVehicleColor = [];
      selectedVehicleType = [];

      selectedSoundType = [];

      let allTagsDetails = tagList;

      const mainCategory = allTagsDetails[0]?.category || '';
      const ageCategories = ['young', 'adult', 'middle', 'senior'];
      const accessoriesCategories = ['opticals', 'mask'];
      const genderCategories = ['male', 'female'];
      const personAccessoriesCategories = ['hat', 'bag'];
      const personPantColors = [
        'red',
        'blue',
        'yellow',
        'green',
        'black',
        'gray',
        'white',
      ];
      const personShirtColors = [
        'red',
        'blue',
        'yellow',
        'green',
        'black',
        'gray',
        'white',
      ];

      const vehicleExtColors = [
        'red',
        'blue',
        'yellow',
        'green',
        'black',
        'gray',
        'white',
      ];

      const vehicleType = ['car', 'bicycle', 'bus', 'motorcycle', 'truck'];

      const soundType = ['glass-breaking', 'scream', 'gun-shot', 'explosion'];

      if (mainCategory === 'face') {
        const face = {};
        const ages = [];
        const accessories = [];
        const gender = [];

        // open face event related categories toggle
        setIsAgeListVisible(true);
        setIsFaceAccListVisible(true);
        setIsGenderListVisible(true);

        selectedEventType = 'Face';
        setSelectedEventType('Face');

        allTagsDetails.forEach((tag) => {
          if (tag?.category === 'face') {
            ageCategories.forEach((age) => {
              if (tag.text?.includes(age) && !ages?.includes(age)) {
                ages.push(age);
              }
            });
            accessoriesCategories.forEach((acc) => {
              if (tag.text?.includes(acc) && !accessories?.includes(acc)) {
                accessories.push(acc);
              }
            });
            if (tag.text === 'female gender' && !gender?.includes('female')) {
              gender.push('female');
            }
            if (tag.text === 'male gender' && !gender?.includes('male')) {
              gender.push('male');
            }
          }
        });
        face.ages = ages;
        face.accessories = accessories;
        face.genders = gender;
        if (face?.ages?.length === 0) {
          setSelectedAge([]);
        } else {
          setSelectedAge(face?.ages);
        }
        if (face?.accessories?.length === 0) {
          setSelectedFaceAcc([]);
        } else {
          setSelectedFaceAcc(face?.accessories);
        }
        if (face?.genders?.length === 0) {
          setSelectedGender([]);
        } else {
          setSelectedGender(face?.genders);
        }

        ageListSelected = [];
        setAgeListSelected([]);
        faceAccListSelected = [];
        setFaceAccListSelected([]);
        genderListSelected = [];
        setGenderListSelected([]);

        if(face?.ages?.length === 0 &&
          face?.accessories?.length === 0 &&
          face?.genders?.length === 0
        ){
          applyTag('Face',true, true);
        }

        if (face?.ages?.indexOf('young') !== -1) {
          handleAgeSelection('Young', true);
        }
        if (face?.ages?.indexOf('adult') !== -1) {
          handleAgeSelection('Adult', true);
        }
        if (face?.ages?.indexOf('middle') !== -1) {
          handleAgeSelection('Middle-Aged', true);
        }
        if (face?.ages?.indexOf('senior') !== -1) {
          handleAgeSelection('Senior', true);
        }
        if (face?.accessories?.indexOf('opticals') !== -1) {
          handleFaceAccSelection('Glasses', true);
        }
        if (face?.accessories?.indexOf('mask') !== -1) {
          handleFaceAccSelection('Mask', true);
        }
        if (face?.genders?.indexOf('male') !== -1) {
          handleGenderSelection('Male', true);
        }
        if (face?.genders?.indexOf('female') !== -1) {
          handleGenderSelection('Female', true);
        }
      } else if (mainCategory === 'person') {
        const person = {};
        const accessories = [];
        const gender = [];
        const pantsColors = [];
        const shirtsColors = [];
        selectedPantColor = [];
        selectedShirtColor = [];
        setSelectedPantColor([]);
        setSelectedShirtColor([]);

        // open person event related categories toggle
        setIsGenderListVisible(true);
        setIsAccessoriesListVisible(true);
        setIsPantColorListVisible(true);
        setIsShirtColorListVisible(true);

        selectedEventType = 'Person';
        setSelectedEventType('Person');

        allTagsDetails.forEach((tag) => {
          if (tag?.category === 'person') {
            if (tag.text === 'female gender' && !gender?.includes('female')) {
              gender.push('female');
            }
            if (tag.text === 'male gender' && !gender?.includes('male')) {
              gender.push('male');
            }
            personAccessoriesCategories.forEach((perAcc) => {
              if (
                tag.text?.includes(perAcc) &&
                !accessories?.includes(perAcc)
              ) {
                accessories.push(perAcc);
              }
            });
            personPantColors.forEach((pantColor) => {
              if (
                tag.text?.includes(pantColor) &&
                tag.text.endsWith('bottom') &&
                !accessories?.includes(pantColor)
              ) {
                pantsColors.push(pantColor);
              }
            });
            personShirtColors.forEach((shirtColor) => {
              if (
                tag.text?.includes(shirtColor) &&
                tag.text.endsWith('top') &&
                !accessories?.includes(shirtColor)
              ) {
                shirtsColors.push(shirtColor);
              }
            });
          }
        });
        person.genders = gender;
        person.accessories = accessories;
        person.pantsColors = pantsColors;
        person.shirtColors = shirtsColors;
        if (person?.genders?.length === 0) {
          setSelectedGender([]);
        } else {
          setSelectedGender(person?.genders);
        }
        if (person?.accessories?.length === 0) {
          setSelectedAccessories([]);
        } else {
          setSelectedAccessories(person?.accessories);
        }
        if (person?.pantsColors?.length === 0) {
          setSelectedPantColor([]);
        } else {
          setSelectedPantColor(person?.pantsColors);
        }
        if (person?.shirtColors?.length === 0) {
          setSelectedShirtColor([]);
        } else {
          setSelectedShirtColor(person?.shirtColors);
        }

        genderListSelected = [];
        setGenderListSelected([]);
        personAccListSelected = [];
        setPersonAccListSelected([]);
        personShirtListSelected = [];
        setPersonShirtListSelected([]);
        personPantListSelected = [];
        setPersonPantListSelected([]);

        if (person?.genders?.length === 0 && 
          person?.accessories?.length === 0 && 
          person?.pantsColors?.length === 0 && 
          person?.shirtColors?.length === 0) {
          applyTag('Person',true, true);
        }

        if (person?.genders?.indexOf('male') !== -1) {
          handleGenderSelection('Male', true);
        }
        if (person?.genders?.indexOf('female') !== -1) {
          handleGenderSelection('Female', true);
        }
        if (person?.accessories?.indexOf('hat') !== -1) {
          handleAccessoriesSelection('Hat', true);
        }
        if (person?.accessories?.indexOf('bag') !== -1) {
          handleAccessoriesSelection('Bag', true);
        }
        if (person?.pantsColors?.indexOf('red') !== -1) {
          if (selectedPantColor?.length < 2) {
            handlePantColorSelection('Red', true);
          }
        }
        if (person?.pantsColors?.indexOf('blue') !== -1) {
          if (selectedPantColor?.length < 2) {
            handlePantColorSelection('Blue', true);
          }
        }
        if (person?.pantsColors?.indexOf('yellow') !== -1) {
          if (selectedPantColor?.length < 2) {
            handlePantColorSelection('Yellow', true);
          }
        }
        if (person?.pantsColors?.indexOf('green') !== -1) {
          if (selectedPantColor?.length < 2) {
            handlePantColorSelection('Green', true);
          }
        }
        if (person?.pantsColors?.indexOf('black') !== -1) {
          if (selectedPantColor?.length < 2) {
            handlePantColorSelection('Black', true);
          }
        }
        if (person?.pantsColors?.indexOf('gray') !== -1) {
          if (selectedPantColor?.length < 2) {
            handlePantColorSelection('Gray', true);
          }
        }
        if (person?.pantsColors?.indexOf('white') !== -1) {
          if (selectedPantColor?.length < 2) {
            handlePantColorSelection('White', true);
          }
        }
        if (person?.shirtColors?.indexOf('red') !== -1) {
          if (selectedShirtColor?.length < 2) {
            handleShirtColorSelection('Red', true);
          }
        }
        if (person?.shirtColors?.indexOf('blue') !== -1) {
          if (selectedShirtColor?.length < 2) {
            handleShirtColorSelection('Blue', true);
          }
        }
        if (person?.shirtColors?.indexOf('yellow') !== -1) {
          if (selectedShirtColor?.length < 2) {
            handleShirtColorSelection('Yellow', true);
          }
        }
        if (person?.shirtColors?.indexOf('green') !== -1) {
          if (selectedShirtColor?.length < 2) {
            handleShirtColorSelection('Green', true);
          }
        }
        if (person?.shirtColors?.indexOf('black') !== -1) {
          if (selectedShirtColor?.length < 2) {
            handleShirtColorSelection('Black', true);
          }
        }
        if (person?.shirtColors?.indexOf('gray') !== -1) {
          if (selectedShirtColor?.length < 2) {
            handleShirtColorSelection('Gray', true);
          }
        }
        if (person?.shirtColors?.indexOf('white') !== -1) {
          if (selectedShirtColor?.length < 2) {
            handleShirtColorSelection('White', true);
          }
        }
      } else if (mainCategory === 'vehicle') {
        const vehicle = {};
        const extColors = [];
        const types = [];

        // open person event related categories toggle
        setIsVehicleColorListVisible(true);
        setIsVehicleTypeListVisible(true);

        selectedEventType = 'Vehicle';
        setSelectedEventType('Vehicle');

        allTagsDetails.forEach((tag) => {
          if (tag?.category === 'vehicle') {
            vehicleExtColors.forEach((vehColor) => {
              if (
                tag.text?.includes(vehColor) &&
                !extColors?.includes(vehColor)
              ) {
                extColors.push(vehColor);
              }
            });

            if (tag.text.endsWith('car') && !types?.includes('car')) {
              types.push('car');
            } else if (
              tag.text.endsWith('bicycle') &&
              !types?.includes('bicycle')
            ) {
              types.push('bicycle');
            } else if (tag.text.endsWith('bus') && !types?.includes('bus')) {
              types.push('bus');
            } else if (
              tag.text.endsWith('motorcycle') &&
              !types?.includes('motorcycle')
            ) {
              types.push('motorcycle');
            } else if (
              tag.text.endsWith('truck') &&
              !types?.includes('truck')
            ) {
              types.push('truck');
            }
          }
        });
        vehicle.extColors = extColors;
        vehicle.types = types;
        if (vehicle?.extColors?.length === 0) {
          setSelectedVehicleColor([]);
        } else {
          setSelectedVehicleColor(vehicle?.extColors);
        }
        if (vehicle?.types?.length === 0) {
          setSelectedVehicleType([]);
        } else {
          setSelectedVehicleType(vehicle?.types);
        } 

        if(vehicle?.extColors?.length === 0 &&
          vehicle?.types?.length === 0
        ){
          applyTag('Vehicle',true, true);
        }

        vehicleColorListSelected = [];
        setVehicleColorListSelected([]);
        vehicleTypeListSelected = [];
        setVehicleTypeListSelected([]);

        if (vehicle?.extColors?.indexOf('red') !== -1) {
          if (selectedVehicleColor?.length < 2) {
            handleVehicleColorSelection('Red', true);
          }
        }
        if (vehicle?.extColors?.indexOf('blue') !== -1) {
          if (selectedVehicleColor?.length < 2) {
            handleVehicleColorSelection('Blue', true);
          }
        }
        if (vehicle?.extColors?.indexOf('yellow') !== -1) {
          if (selectedVehicleColor?.length < 2) {
            handleVehicleColorSelection('Yellow', true);
          }
        }
        if (vehicle?.extColors?.indexOf('green') !== -1) {
          if (selectedVehicleColor?.length < 2) {
            handleVehicleColorSelection('Green', true);
          }
        }
        if (vehicle?.extColors?.indexOf('black') !== -1) {
          if (selectedVehicleColor?.length < 2) {
            handleVehicleColorSelection('Black', true);
          }
        }
        if (vehicle?.extColors?.indexOf('gray') !== -1) {
          if (selectedVehicleColor?.length < 2) {
            handleVehicleColorSelection('Gray', true);
          }
        }
        if (vehicle?.extColors?.indexOf('white') !== -1) {
          if (selectedVehicleColor?.length < 2) {
            handleVehicleColorSelection('White', true);
          }
        }
        if (vehicle?.types?.indexOf('car') !== -1) {
          handleVehicleTypeSelection('Car', true);
        }
        if (vehicle?.types?.indexOf('bicycle') !== -1) {
          handleVehicleTypeSelection('Bicycle', true);
        }
        if (vehicle?.types?.indexOf('bus') !== -1) {
          handleVehicleTypeSelection('Bus', true);
        }
        if (vehicle?.types?.indexOf('motorcycle') !== -1) {
          handleVehicleTypeSelection('Motorcycle', true);
        }
        if (vehicle?.types?.indexOf('truck') !== -1) {
          handleVehicleTypeSelection('Truck', true);
        }
      } else if (mainCategory === 'sound') {
        const sound = {};
        const types = [];

        // open person event related categories toggle
        setIsSoundTypeListVisible(true);

        selectedEventType = 'Audio';
        setSelectedEventType('Audio');
        selectedSoundType = [];
        setSelectedSoundType([]);

        allTagsDetails.forEach((tag) => {
          if (tag?.category === 'sound') {
            soundType.forEach((sound) => {
              if (tag.text?.includes(sound) && !types?.includes(sound)) {
                types.push(sound);
              }
            });
          }
        });
        sound.types = types;
        if (sound?.types?.length === 0) {
          setSelectedSoundType([]);
        } else {
          setSelectedSoundType(sound?.types);
        }

        if (sound?.types?.indexOf('glass-breaking') !== -1) {
          handleSoundTypeSelection('Glass Break', true);
        }
        if (sound?.types?.indexOf('scream') !== -1) {
          handleSoundTypeSelection('Scream', true);
        }
        if (sound?.types?.indexOf('gun-shot') !== -1) {
          handleSoundTypeSelection('Gunshot', true);
        }
        if (sound?.types?.indexOf('explosion') !== -1) {
          handleSoundTypeSelection('Explosion', true);
        }
      } else if (mainCategory === 'licenseplate') {
        setSelectedEventType('License Plate');
        applyTag('License Plate', true, true);
        setIsLicensePlateListVisible(true);
      } else if (mainCategory === 'motion') {
        setSelectedEventType('Motion');
        let types = ['motion-start'];
        motion.types = types;
        tags.motion = motion;
        applyTag('Motion', true, true);
      }
    }
  }

  useEffect(() => {
    setTimezone(deviceTimezone);
  }, [deviceTimezone]);

  const fetchRecordsForLoadMore = async (endTime) => {
    setIsLoadingEventsShowMore(true);
    if (!checkDeviceIdStatus()) {
      setEventsData([]);
      return;
    }
    let calculateTimeStampsValues = calculateTimeStamps(
      selectedDateTOShow,
      startTimeToPass,
      endTimeToPass
    );
    let startTimeArg = calculateTimeStampsValues[0];
    const allDevicesListToPass = [];
    allDevciesData?.forEach((location) => {
      allDevicesListToPass.push(location?.deviceId);
    });
    let devicesListInSingleLine = getDeviceIdForEvents(devicesListToPass);
    let allDevicesListInSingleLine = getDeviceIdForEvents(allDevicesListToPass);

    if (Object.keys(eventsTagData)?.includes('tags')) {
      const response = await axios.post(
        `timeline/orgs/${custOrgData?.orgId}/events/searchByTag/v2`,
        {
          ...getTagsData(),
          startTime: startTimeArg,
          endTime: endTime,
          size: requestedEventSize,
        },
        Utils.requestHeader()
      );
      const responseData = response?.data;
      if (responseData?.meta?.code === 200) {
        setIsLoadingEventsShowMore(false);
        if (responseData?.data?.events?.length > 0) {
          setEventsData([...eventsData, ...response?.data?.data?.events]);
        } else if (responseData?.data?.events?.length === 0) {
          setShowMoreFooterFlag(false);
        }
      } else if(responseData?.meta?.code === 401){
        setIsLoadingEventsShowMore(false);
        redirectToLoginPage();
      } else {
        setIsLoadingEventsShowMore(false);
      }
    } else {
      if (
        selectedLocations?.length > 0 ||
        selectedAreas?.length > 0 ||
        selectedDevices?.length > 0
      ) {
        let startTime = 0;
        if (devicesListToPass?.length > 0) {
          const response = await axios.get(
            `timeline/orgs/${
              custOrgData?.orgId
            }/events/all?${`startTime=${startTimeArg}&`}endTime=${endTime}&size=${requestedEventSize}${devicesListInSingleLine}`,
            Utils.requestHeader()
          );
          const responseData = response?.data;
          if (responseData?.meta?.code === 200) {
            setIsLoadingEventsShowMore(false);
            if (responseData?.data?.events?.length > 0) {
              setEventsData([...eventsData, ...responseData?.data?.events]);
            } else if (responseData?.data?.events?.length === 0) {
              setShowMoreFooterFlag(false);
            }
          } else if(responseData?.meta?.code === 401){
            setIsLoadingEventsShowMore(false);
            redirectToLoginPage();
          } else {
            setIsLoadingEventsShowMore(false);
          }
        } else {
          const response = await axios.get(
            `timeline/orgs/${
              custOrgData?.orgId
            }/events/all?${`startTime=${startTimeArg}&`}endTime=${endTime}&size=${requestedEventSize}${[]}`,
            Utils.requestHeader()
          );
          const responseData = response?.data;
          if (responseData?.meta?.code === 200) {
            setIsLoadingEventsShowMore(false);
            if (responseData?.data?.events?.length > 0) {
              setEventsData([...eventsData, ...responseData?.data?.events]);
            } else if (responseData?.data?.events?.length === 0) {
              setShowMoreFooterFlag(false);
            }
          } else if(responseData?.meta?.code === 401){
            setIsLoadingEventsShowMore(false);
            redirectToLoginPage();
          } else {
            setIsLoadingEventsShowMore(false);
          }
        }
      } else {
        const response = await axios.get(
          `timeline/orgs/${
            custOrgData?.orgId
          }/events/all?${`startTime=${startTimeArg}&`}endTime=${endTime}&size=${requestedEventSize}${allDevicesListInSingleLine}`,
          Utils.requestHeader()
        );
        const responseData = response?.data;
        if (responseData?.meta?.code === 200) {
          setIsLoadingEventsShowMore(false);
          if (responseData?.data?.events?.length > 0) {
            // setEventsData(responseData?.data?.events);
            setEventsData([...eventsData, ...response?.data?.data?.events]);
          } else if (responseData?.data?.events?.length === 0) {
            setShowMoreFooterFlag(false);
          }
        }  else if(responseData?.meta?.code === 401){
          setIsLoadingEventsShowMore(false);
          redirectToLoginPage();
        } else {
          setIsLoadingEventsShowMore(false);
        }
      }
    }
  };

  useEffect(() => {
    if (transcript != '') {
      setSearchtext(transcript);
    }
  }, [transcript]);

  const fetchRecordsViaCalendar = async (startTime, endTime) => {
    const isSearch = searchtext !== null && searchtext !== '';
    setShowToast(false);
    let keys = getTagsList();
    let devicesListToPassForCalender = devicesListToPass;
    if (
      selectedLocations?.length === 0 &&
      selectedAreas?.length === 0 &&
      selectedDevices?.length === 0 &&
      devicesListToPass?.length === 0
    ) {
      devicesListToPassForCalender = [];
      let filteredAllDevices = allDevciesData?.filter(
        (item) =>
          item.areas && item.areas.length > 0 && item.deviceStatus === 'CLAIMED'
      );
      let filteredAllDevicesIds = filteredAllDevices.map(
        (item) => item.deviceId
      );
      devicesListToPassForCalender = filteredAllDevicesIds;
    } else if (
      (selectedLocations?.length > 0 || selectedAreas?.length > 0) &&
      devicesListToPass?.length === 0
    ) {
      devicesListToPassForCalender = [];
    }
    let devicesListToPassForCalenderSingleLine = getDeviceIdForEvents(
      devicesListToPassForCalender
    );
    if (devicesListToPassForCalender.length > 0) {
      setIsLoadingEvents(true);
      let response = await axios.get(
        `timeline/orgs/${custOrgData?.orgId}/events/all?${
          startTime !== 0 ? `startTime=${startTime}&` : ''
        }endTime=${endTime}&size=${requestedEventSize}${devicesListToPassForCalenderSingleLine}`,
        Utils.requestHeader()
      );
      if (response?.data?.data && response?.data?.data?.events?.length > 0) {
        setShowMoreFooterFlag(true);
        setEventsData(response?.data?.data?.events);
        setEventTotal(response?.data?.data?.total);
        setIsLoadingEvents(false);
      } else if(response?.data?.meta?.code === 401){
        setIsLoadingEvents(false);         
        redirectToLoginPage();
      }  else {
        setEventsData([]);
        setEventTotal([]);
        setUserMsg('No events found during selected date');
        setShowToast(true);
        setIsLoadingEvents(false);
      }
    } else {
      setIsLoadingEvents(false);
    }
  };

  const addTimeStartEndRange = (startDateCal, endDateCal) => {
    let startDate = moment(new Date(startDateCal)).format('MMM DD');
    let endDate = endDateCal && moment(new Date(endDateCal)).format('MMM DD');
    const index = timeOptions.findIndex((e) => e.id === 8);
    if (index && index === 6) {
      if (endDateCal) {
        timeOptions[index] = { ...{ id: 8, value: startDate + '-' + endDate } };
      } else {
        timeOptions[index] = { ...{ id: 8, value: startDate } };
      }
    }
    setTimeOptions(timeOptions);
  };

  useEffect(() => {
    let startTime = 0;
    let endTime = 0;

    if (filterDate && filterDate[1] && filterDate[0]) {
      startTime = new Date(
        moment.tz(new Date(filterDate[0]), timezone)
      ).getTime();
      endTime = new Date(
        moment.tz(new Date(filterDate[1]), timezone)
      ).getTime();
      addTimeStartEndRange(filterDate[0], filterDate[1]);
    } else if (filterDate && filterDate[0]) {
      startTime = new Date(
        moment
          .tz(new Date(filterDate[0]), timezone)
          .format('MMM DD, YYYY 00:00:00')
      ).getTime();
      let eTime = new Date(
        moment
          .tz(new Date(filterDate[0]), timezone)
          .format('MMM DD, YYYY 23:59:59')
      ).getTime();
      if (eTime < new Date().getTime()) {
        endTime = eTime;
        addTimeStartEndRange(filterDate[0], false);
      } else {
        endTime = new Date().getTime();
        addTimeStartEndRange(new Date(), false);
      }
    }
    if (startTime && endTime && startTime !== 0 && endTime !== 0) {
      setShowCustomRange(false);
      setSelectedTime(startTime);
      fetchRecordsViaCalendar(startTime, endTime);
    }
  }, [filterDate]);

  const massagedData = (data) => {
    let finalArray = [];
    data?.map((event) => {
      const time = getActualDate(event?.eventTimestamp);
      const index = finalArray.findIndex((e) => e.eventTimestamp === time);
      if (index > -1) {
        finalArray[index]?.events.push({ ...event });
      } else {
        let eventObject = {
          eventTimestamp: '',
          events: [],
        };
        eventObject.eventTimestamp = time;
        eventObject.events.push(event);
        finalArray.push(eventObject);
      }
    });
    return finalArray;
  };

  const formattedDate = (time) => {
    if (time === moment(new Date()).format('MMM DD, YYYY')) {
      return 'Today';
    } else if (
      time === moment(new Date()).subtract({ days: 1 }).format('MMM DD, YYYY')
    ) {
      return 'Yesterday';
    } else {
      return time;
    }
  };
  

  const showItems = (posts) => {
    const updatedData = massagedData(posts);
    if (updatedData?.length) {
      return (
        <div className="">
          {updatedData?.map((event, i) => (
            <div key={i}>
              <StickyBox offsetTop={0}>
                <div className="sticky-header sticky-header-area">
                  <div className="sticky-header-scroll">
                    <span>
                      <ScrollCalendar />
                    </span>
                    <span className="sticky-header-scroll-label">
                      {formattedDate(event?.eventTimestamp)}
                    </span>
                  </div>
                </div>
              </StickyBox>
              <div className="events-all-category-wrapper">
                <EventsWrapper
                  data={event}
                  cdnValue={cdnValueInfo}
                  timezone={timezone}
                  selectedEvent={getSelectedEvent()}
                  handleNextPreviousIcon={nextPreviousIcon}
                  handleOnClick={(event, i) => {
                    allSetMethodsBeforeRedirection();
                    setSelectedDevice(event?.device?.[0]?.deviceId);
                    setSelectedEventStore(event?.timestamp);
                    setEventFromSearch(true);
                    setModalEventContent(event, i);
                    navigate(`/cameras/dashboard.html`, {
                      state: {
                        id: event?.device?.[0]?.deviceId,
                        cdnInfo: cdnValueInfo ? cdnValueInfo : {},
                      },
                    });
                  }}
                />
              </div>
            </div>
          ))}

          {!isLoadingEventsShowMore && ShowMoreFooterFlag && (
            <div className="show-more-footer">
              <Row>
                <div className="show-more-footer-inner-area" onClick={loadMore}>
                  <p className="show-more-text">
                    {constants.EVENT_FILTER_SHOW_MORE}
                  </p>
                  <ArrowNarrowDown />
                </div>
                <div className="empty-section-style"></div>
              </Row>
            </div>
          )}
          {isLoadingEventsShowMore && (
            <div className="show-more-footer">
              <SiteSpinner height="50px" width="50px" />
            </div>
          )}
        </div>
      );
    }
  };

  const CustomRangePicker = ({ openCalendar, value }) => {
    if (value === new Date()) {
      return <div onClick={openCalendar}>Custom Range Picker</div>;
    } else {
      return <div onClick={openCalendar}></div>;
    }
  };

  useEffect(() => {
    const navigatedFromCameraDetail  = getNavigatedFromCameraDetail();

    const handleClickOutside = (event) => {
      if (
        calendarRef?.current &&
        !calendarRef?.current.contains(event.target)
      ) {
        setShowDateTimePicker(false);
        if(navigatedFromCameraDetail){
          lastSelectedDate['current'] = getPreSelectedLastSelectedDateSubmit();
        }
        
        const displayValue = lastSelectedDate?.current
          ? lastSelectedDate?.current
          : yesterday;

        if(navigatedFromCameraDetail){
          setSelectedDateTOShow(getPreSelectedLastSelectedDateSubmit());
        }else{
          setSelectedDateTOShow(displayValue);
        }
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [calendarRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        timePickerRef?.current &&
        !timePickerRef?.current.contains(event.target)
      ) {
        setShowTimePicker(false);

        const displayValueStartTime = lastSelectedTime?.current
          ? lastSelectedTime?.current?.startTime
          : [0, 0, 0];
        const displayValueEndTime = lastSelectedTime?.current
          ? lastSelectedTime?.current?.endTime
          : [23, 59, 59];

        const convertedHours =
          displayValueStartTime[0] > 12
            ? displayValueStartTime[0] - 12
            : displayValueStartTime[0];
        const startTimeHour = formatTimeComponent(convertedHours);
        const startTimeMinutes = formatTimeComponent(displayValueStartTime[1]);
        const startTimeSeconds = formatTimeComponent(displayValueStartTime[2]);
        const startTime = `${startTimeHour}:${startTimeMinutes}:${startTimeSeconds}`;

        let startTimeToPass = [
          displayValueStartTime[0],
          displayValueStartTime[1],
          displayValueStartTime[2],
        ];
        setstartTimeToPass([
          displayValueStartTime[0],
          displayValueStartTime[1],
          displayValueStartTime[2],
        ]);

        let startTimeOfEvent = startTime;
        setStartTimeOfEvent(startTime);

        if (displayValueStartTime[0] >= 12) {
          startTimePeriod = 'pm';
          setStartTimePeriod('pm');
        } else {
          startTimePeriod = 'am';
          setStartTimePeriod('am');
        }

        const convertedHoursEndTime =
          displayValueEndTime[0] > 12
            ? displayValueEndTime[0] - 12
            : displayValueEndTime[0];
        const endTimeHour = formatTimeComponent(convertedHoursEndTime);
        const endTimeMinutes = formatTimeComponent(displayValueEndTime[1]);
        const endTimeSeconds = formatTimeComponent(displayValueEndTime[2]);
        const endTime = `${endTimeHour}:${endTimeMinutes}:${endTimeSeconds}`;

        let endTimeToPass = [
          displayValueEndTime[0],
          displayValueEndTime[1],
          displayValueEndTime[2],
        ];
        setEndTimeToPass([
          displayValueEndTime[0],
          displayValueEndTime[1],
          displayValueEndTime[2],
        ]);

        let endTimeOfEvent = endTime;
        setEndTimeOfEvent(endTime);

        if (displayValueEndTime[0] >= 12) {
          endTimePeriod = 'pm';
          setEndTimePeriod('pm');
        } else {
          endTimePeriod = 'am';
          setEndTimePeriod('am');
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [timePickerRef]);

  const updateTags = (tagList) => {
    const data = [...getTagsList(), ...tagList];
    const valueTags = [];
    const fillter = data?.filter(function (value, index, array) {
      let tagExist = valueTags?.includes(value.text);
      if (!tagExist) {
        valueTags.push(value.text);
        if (value.category !== 'addtag') {
          return array.indexOf(value) === index;
        }
      }
    });
    const addTag = fillter?.find((device) => device.category === 'addtag');
    if (!addTag) {
      fillter.push({ category: 'addtag', text: 'Add Tags' });
    }
    if (fillter?.length > 0) {
      setTagsList(fillter);
    }
  };

  const loadTags = (tagList) => {
    if (getTagsList()?.length > 0 && tagList?.length > 0) {
      updateTags(tagList);
    } else {
      const addTag = tagList?.find((device) => device.category === 'addtag');
      if (!addTag) {
        tagList?.push({ category: 'addtag', text: 'Add Tags' });
      }
      setTagsList(tagList);
    }
  };

  const loadMore = async (startTimeArg, endTimeArg, timeChangeFlag) => {
    fetchRecordsForLoadMore(eventsData[eventsData?.length - 1]?.eventTimestamp);
  };

  const getActualDate = (date) => {
    const time = Utils.getDate(date / 1000);
    const actualTime = moment.tz(time, timezone).format('MMM DD, YYYY');
    return actualTime;
  };

  useEffect(() => {
    let rangeDate = selectedDateTOShow?.length > 15
    if((startTimeOfEvent.includes('NaN') || endTimeOfEvent.includes('NaN')) && !rangeDate){
      setstartTimeToPass([0, 0, 0]);
      setEndTimeToPass([23, 59, 59]);
      setStartTimeOfEvent("00:00:00");
      setEndTimeOfEvent("11:59:59");
      setStartTimePeriod('am');
      setEndTimePeriod('pm');
      lastSelectedTime.current = {
        startTime: [0,0,0],
        endTime: [23,59,59],
      };  
    }
  },[startTimeOfEvent, endTimeOfEvent]);

  const resetDateAndTime = () => {
    setstartTimeToPass([0, 0, 0]);
    setEndTimeToPass([23, 59, 59]);
    setStartTimeOfEvent(initialStartTime);
    setEndTimeOfEvent(initialEndTime);
    setStartTimePeriod('am');
    setEndTimePeriod('pm');
    lastSelectedTime.current = {
      startTime: [0,0,0],
      endTime: [23,59,59],
    };
    setSelectedDateTOShow(today);
    setLastSelectedDateSubmit(today);
    lastSelectedDate = today;
  }

  const removeUrlParameter = (paramKey, skipAPICallForTagCreation) => {
    const url = window.location.href;
    var r = new URL(url);
    r.searchParams.delete(paramKey);
    const newUrl = r.href;

    let lastSelectedDateStored = lastSelectedDate;
    let lastSelectedDateSubmitStored = lastSelectedDateSubmit;
    let selectedDateTOShowStored = selectedDateTOShow;

    window.history.pushState({ path: newUrl }, '', newUrl);

    lastSelectedDate = lastSelectedDateStored;
    lastSelectedDateSubmit = lastSelectedDateSubmitStored;
    selectedDateTOShow = selectedDateTOShowStored;

    setSearchtext('');

    // clear all pre selected filter entities starts
    tags = {};
    person = {};
    face = {};
    vehicle = {};
    sound = {};
    motion = {};
    licensePlate = {};
    eventsTagData = [];

    setTags({});
    setPerson({});
    setFace({});
    setVehicle({});
    setSound({});
    setMotion({});
    setLicensePlate({});
    setEventsTagData([]);

    selectedData = selectedDataAllFalse;
    setAllCategoriesValuesAsFalse();
    setSelectedEventType(null);
    setSelectedLocations([]);
    setSelectedAreas([]);
    setSelectedDevices([]);
    allLocationsData = allLocationsDataInitial;
    allAreasData = allAreasDataInitial;
    allDevciesData = allDevciesDataInitial;
    setAllLocationsData(allLocationsDataInitial);
    setAllAreasData(allAreasDataInitial);
    setAllDevciesData(allDevciesDataInitial);
    setNavigatedFromCameraDetail(false);

    setEventTypeList([
      constants.EVENT_FILTER_FACE,
      constants.EVENT_FILTER_PERSON,
      constants.EVENT_FILTER_VEHICLE,
      constants.EVENT_FILTER_LP,
      constants.EVENT_FILTER_AUDIO,
      constants.EVENT_FILTER_MOTION,
    ]);
    setSelectedEventType(null);

    // clear all pre selected filter entities ends

    const endTime = Utils.getUnixDate(
      moment(new Date()).subtract({ hours: 0, minutes: 0 })
    );
    // for single date selection
    let timeStampSelectedDateStartTime = new Date(formatDate(yesterday));
    let timeStampSelectedDateEndTime = new Date(formatDate(yesterday));
    timeStampSelectedDateStartTime?.setHours(0, 0, 0);
    timeStampSelectedDateEndTime?.setHours(23, 59, 59);

    if (!checkDeviceIdStatus()) {
      setEventsData([]);
      return;
    }
    setTagsData({});
    setSearchtext('');
    let startTime = 0;

    if(!skipAPICallForTagCreation){
      setEvents(
        `timeline/orgs/${custOrgData?.orgId}/events/all?${
          timeStampSelectedDateStartTime?.getTime()
            ? `startTime=${timeStampSelectedDateStartTime?.getTime()}&`
            : ''
        }endTime=${
          timeStampSelectedDateEndTime?.getTime()
            ? timeStampSelectedDateEndTime?.getTime()
            : timeStampSelectedDateStartTime?.getTime()
        }&size=${requestedEventSize}${allDevciesData?.length > 0 ? getDeviceId(allDevciesData) : []}`
      );
    }    
  };
  const onKeyDownChange = (e) => {
    let calculateTimeStampsValues = calculateTimeStamps(
      selectedDateTOShow,
      startTimeToPass,
      endTimeToPass
    );
    let startTimeArg = calculateTimeStampsValues[0];
    let endTimeArg = calculateTimeStampsValues[1];

    searchByParam(e, startTimeArg, endTimeArg);
    if (e != undefined) {
      if (e.key === 'Enter') {
        if (
          searchtext !== null &&
          searchtext !== '' &&
          searchtext?.replace(/\s/g, '').length
        ) {
          searchByParam(searchtext, selectedTime);
        } else {
          removeUrlParameter('searchtext');
          setStoreEvents(selectedTime);
          setTagsList([]);
          setSelectedTags({});
        }
      }
    }
  };

  const onHandleChange = (e) => {
    if (e != undefined) {
      if (e?.target?.value === '') {
        resetTranscript();
      }
      setSearchtext(e?.target?.value);
    }
  };

  const handleListing = (e) => {
    handleReset(e);
    SpeechRecognition.startListening({
      interimResults: true,
    });
  };

  const handleReset = (e) => {
    e.preventDefault();
    e.stopPropagation();
    SpeechRecognition.stopListening();
    resetTranscript();
  };

  const onNextPreviousClick = (type) => {
    sendPauseCVR(modalContent?.device?.[0]?.deviceId);
    disconnectWithWebSocket();
    setNextPreviousIcon(type);
    setTimeout(() => {
      connectWithWebSocket(getPlatformDetails);
    }, 1500);
  };

  const onCameraDetailsClick = (content, time, cvrMode) => {
    setSelectedEventStore(Utils.getUnixDate(time) * 1000);
    setEventFromSearch(true);
    setEventCVRMode(cvrMode);
    navigate(`/cameras/dashboard.html`, {
      state: {
        id: content?.device[0]?.deviceId,
        cdnInfo: cdnValueInfo,
      },
    });
    // navigate(`/cameras/dashboard.html?id=${content?.device[0]?.deviceId}`);
  };

  useEffect(() => {
    setNextPreviousIcon(null);
  }, [nextPreviousIcon]);
  /*=== Generate selection options*/
  const generateOptions = () => {
    if (Array.isArray(timeOptions) && timeOptions?.length > 0) {
      return timeOptions?.map((item, i) => {
        return (
          <option key={i} value={item.id}>
            {item.value}
          </option>
        );
      });
    }
    return null;
  };

  const onHandleChangeTime = (e) => {
    if (e != undefined) {
      fillterByTime(e?.target?.value);
    }
  };

  const fillterByTime = async (value) => {
    let startTime = 0;
    if (
      parseInt(value) === 1 ||
      parseInt(value) === 7 ||
      parseInt(value) === 15 ||
      parseInt(value) === 30
    ) {
      setTimeOptions(Utils.getTimeFilters());
      setFilterDate(new Date());
      startTime = Utils.getHourPast(parseInt(value));
    } else if (parseInt(value) === 2) {
      setFilterDate(new Date());
      setTimeOptions(Utils.getTimeFilters());
      startTime = Utils.getHourPastByValue(1);
    } else if (parseInt(value) === 8) {
      setShowCustomRange(true);
      return;
    } else {
      setTimeOptions(Utils.getTimeFilters());
      setFilterDate(new Date());
      startTime = 0;
    }
    setSelectedTime(startTime);
    if (startTime !== 0 && startTime < new Date().getTime()) {
      const requestData = Object.keys(getTagsData()).length !== 0;
      if (requestData) {
        fillterByTagApiCall(startTime);
      } else {
        searchtext !== null && searchtext !== ''
          ? searchByParam(searchtext, startTime)
          : setStoreEvents(startTime);
      }
    } else {
      setSelectedTags({});
      setTagsList([]);
      setStoreEvents(startTime);
    }
  };

  const updateTagList = (tagList) => {
    if (tagList?.length === 0) {
      removeUrlParameter('searchtext');
      setStoreEvents(selectedTime);
      setTagsList([]);
      setSelectedTags({});
    }
    setTagsList(tagList);
    setTimeout(() => {
      setTagRemove(true);
    }, 1000);
  };

  const fillterByTagApiCall = async (startTimeStamp, tags) => {
    let calculateTimeStampsValues = calculateTimeStamps(
      selectedDateTOShow,
      startTimeToPass,
      endTimeToPass
    );
    let startTimeArg = calculateTimeStampsValues[0];
    let endTimeArg = calculateTimeStampsValues[1];

    let devicesListToPassForTag = devicesListToPass;
    if (
      selectedLocations?.length === 0 &&
      selectedAreas?.length === 0 &&
      selectedDevices?.length === 0 &&
      devicesListToPass?.length === 0
    ) {
      devicesListToPassForTag = [];
      let filteredAllDevices = allDevciesData?.filter(
        (item) =>
          item.areas && item.areas.length > 0 && item.deviceStatus === 'CLAIMED'
      );
      let filteredAllDevicesIds = filteredAllDevices.map(
        (item) => item.deviceId
      );
      devicesListToPassForTag = filteredAllDevicesIds;
    } else if (
      (selectedLocations?.length > 0 || selectedAreas?.length > 0) &&
      devicesListToPass?.length === 0
    ) {
      devicesListToPassForTag = [];
    }

    if (devicesListToPassForTag.length > 0) {
      let filteredTagByGender = tags;
      setTagsList(tags);
      if (Object.keys(tags).length === 2) {
        filteredTagByGender = tags;
        // If in gender male and female both are selected then only pass type event type
        if (
          filteredTagByGender?.tags?.face?.genders &&
          filteredTagByGender?.tags?.face?.genders.length === 2
        ) {
          delete filteredTagByGender.tags.face.genders;
        } else if (
          filteredTagByGender?.tags?.person?.genders &&
          filteredTagByGender?.tags?.person?.genders.length === 2
        ) {
          delete filteredTagByGender.tags.person.genders;
        }
        setIsLoadingEvents(true);
        let response = await axios.post(
          `timeline/orgs/${custOrgData?.orgId}/events/searchByTag/v2`,
          {
            ...filteredTagByGender,
            startTime: startTimeArg,
            endTime: endTimeArg,
            size: requestedEventSize,
          },
          Utils.requestHeader()
        );
        const responseData = response?.data;
        if (responseData?.meta?.code === 200) {
          setShowMoreFooterFlag(true);
          setEventsData(responseData?.data?.events);
          setEventTotal(responseData?.data?.total);
          setIsLoadingEvents(false);
        } else if(responseData?.meta?.code === 401){
          setIsLoadingEvents(false);
          redirectToLoginPage();
        } else {
          setIsLoadingEvents(false);
        }
      } else {
        let newTags = {};

        if (selectedEventType === 'Person') {
          newTags.person = {};
          filteredTagByGender = newTags.person;
        } else if (selectedEventType === 'Face') {
          newTags.face = {};
          filteredTagByGender = newTags.face;
        } else if (selectedEventType === 'Vehicle') {
          newTags.vehicle = {};
          filteredTagByGender = newTags.vehicle;
        } else if (selectedEventType === 'License Plate') {
          newTags.licensePlate = {};
          filteredTagByGender = newTags.licensePlate;
        } else if (selectedEventType === 'Audio') {
          newTags.sound = {};
          filteredTagByGender = newTags.sound;
        } else if (selectedEventType === 'Motion') {
          newTags.motion = {};
          filteredTagByGender = newTags.motion;
        }

        let tagsParent = {
          deviceIds: devicesListToPassForTag,
        };

        tagsParent.tags = newTags;

        setTagsData(tagsParent);
        setIsLoadingEvents(true);
        let response = await axios.post(
          `timeline/orgs/${custOrgData?.orgId}/events/searchByTag/v2`,
          {
            ...tagsParent,
            startTime: startTimeArg,
            endTime: endTimeArg,
            size: requestedEventSize,
          },
          Utils.requestHeader()
        );
        const responseData = response?.data;
        if (responseData?.meta?.code === 200) {
          setShowMoreFooterFlag(true);
          setEventsData(responseData?.data?.events);
          setEventTotal(responseData?.data?.total);
          setIsLoadingEvents(false);
        } else if(responseData?.meta?.code === 401){
          setIsLoadingEvents(false);
          redirectToLoginPage();
        } else {
          setIsLoadingEvents(false);
        }
      }
    }else{
      setEventsData([]);
      setEventTotal(0);
      setIsLoadingEvents(false);
    }
    setSelectedData(getTagsData()?.tags);
  };

  const handleChildValue = (value) => {
    if (value?.selectedDate?.length) {
      const daySelected1 = value?.selectedDate[0]?.day;
      const monthSelected1 = value?.selectedDate[0]?.month?.shortName;
      const yearSelected1 = value?.selectedDate[0]?.year;
      const daySelected2 = value?.selectedDate[1]?.day;
      const monthSelected2 = value?.selectedDate[1]?.month?.shortName;
      const yearSelected2 = value?.selectedDate[1]?.year;
      setDateIsChanged(true);
      setSelectedDateTOShow(
        monthSelected1 +
          ' ' +
          daySelected1 +
          ', ' +
          yearSelected1 +
          ' - ' +
          monthSelected2 +
          ' ' +
          daySelected2 +
          ', ' +
          yearSelected2
      );
    } else {
      const daySelected = value?.selectedDate?.day;
      const monthSelected = value?.selectedDate?.month?.shortName;
      const yearSelected = value?.selectedDate?.year;
      setDateIsChanged(true);
      setSelectedDateTOShow(
        monthSelected + ' ' + daySelected + ', ' + yearSelected
      );
    }
  };

  const formatTimeComponent = (component) => {
    return component < 10 ? '0' + component : component;
  };

  const handleTimeValue = (value) => {
    if (value && value?.selectedStartHour && !value?.fromRange) {
      // conversion of start time to show values in double digit starts
      const convertedHours =
        value?.selectedStartHour > 12
          ? value?.selectedStartHour - 12
          : value?.selectedStartHour;
      const startTimeHour = formatTimeComponent(convertedHours);
      const startTimeMinutes = formatTimeComponent(value?.selectedStartMinute);
      const startTimeSeconds = formatTimeComponent(value?.selectedStartSecond);
      const startTime = `${startTimeHour}:${startTimeMinutes}:${startTimeSeconds}`;
      // conversion of end time to show values in double digit ends

      startTimeToPass = [
        value?.selectedStartHour,
        value?.selectedStartMinute,
        value?.selectedStartSecond,
      ];
      setstartTimeToPass([
        value?.selectedStartHour,
        value?.selectedStartMinute,
        value?.selectedStartSecond,
      ]);

      startTimeOfEvent = startTime;
      setStartTimeOfEvent(startTime);

      if (value?.selectedStartHour >= 12) {
        startTimePeriod = 'pm';
        setStartTimePeriod('pm');
      } else {
        startTimePeriod = 'am';
        setStartTimePeriod('am');
      }
    }

    if (value && value?.selectedEndHour && !value?.fromRange) {
      const convertedHours =
        value?.selectedEndHour > 12
          ? value?.selectedEndHour - 12
          : value?.selectedEndHour;
      const endTimeHour = formatTimeComponent(convertedHours);
      const endTimeMinutes = formatTimeComponent(value?.selectedEndMinute);
      const endTimeSeconds = formatTimeComponent(value?.selectedEndSecond);
      const endTime = `${endTimeHour}:${endTimeMinutes}:${endTimeSeconds}`;
      endTimeToPass = [
        value?.selectedEndHour,
        value?.selectedEndMinute,
        value?.selectedEndSecond,
      ];
      setEndTimeToPass([
        value?.selectedEndHour,
        value?.selectedEndMinute,
        value?.selectedEndSecond,
      ]);

      endTimeOfEvent = endTime;
      setEndTimeOfEvent(endTime);

      if (value?.selectedEndHour >= 12) {
        endTimePeriod = 'pm';
        setEndTimePeriod('pm');
      } else {
        endTimePeriod = 'am';
        setEndTimePeriod('am');
      }
    }

    if (value && value?.selectedStartTime && value?.fromRange) {
      const convertedHours =
        value?.selectedStartTime?.hour > 12
          ? value?.selectedStartTime?.hour - 12
          : value?.selectedStartTime?.hour;
      const startTimeHour = formatTimeComponent(convertedHours);
      const startTimeMinutes = formatTimeComponent(
        value?.selectedStartTime?.minutes
      );
      const startTimeSeconds = formatTimeComponent(
        value?.selectedStartTime?.remainingSeconds
      );
      const startTime = `${startTimeHour}:${startTimeMinutes}:${startTimeSeconds}`;
      startTimeToPass = [
        value?.selectedStartTime?.hour,
        value?.selectedStartTime?.minutes,
        value?.selectedStartTime?.remainingSeconds,
      ];
      setstartTimeToPass([
        value?.selectedStartTime?.hour,
        value?.selectedStartTime?.minutes,
        value?.selectedStartTime?.remainingSeconds,
      ]);
      if (startTime) {
        startTimeOfEvent = startTime;
        setStartTimeOfEvent(startTime);
        if (value?.selectedStartTime?.hour >= 12) {
          startTimePeriod = 'pm';
          setStartTimePeriod('pm');
        } else {
          startTimePeriod = 'am';
          setStartTimePeriod('am');
        }
      }
    }

    if (value && value?.selectedEndTime && value?.fromRange) {
      const convertedHours =
        value?.selectedEndTime?.hour > 12
          ? value?.selectedEndTime?.hour - 12
          : value?.selectedEndTime?.hour;
      const endTimeHour = formatTimeComponent(convertedHours);
      const endTimeMinutes = formatTimeComponent(
        value?.selectedEndTime?.minutes
      );
      const endTimeSeconds = formatTimeComponent(
        value?.selectedEndTime?.remainingSeconds
      );
      const endTime = `${endTimeHour}:${endTimeMinutes}:${endTimeSeconds}`;
      endTimeToPass = [
        value?.selectedEndTime?.hour,
        value?.selectedEndTime?.minutes,
        value?.selectedEndTime?.remainingSeconds,
      ];
      setEndTimeToPass([
        value?.selectedEndTime?.hour,
        value?.selectedEndTime?.minutes,
        value?.selectedEndTime?.remainingSeconds,
      ]);
      if (endTime) {
        endTimeOfEvent = endTime;
        setEndTimeOfEvent(endTime);
        if (value?.selectedEndTime?.hour >= 12) {
          endTimePeriod = 'pm';
          setEndTimePeriod('pm');
        } else {
          endTimePeriod = 'am';
          setEndTimePeriod('am');
        }
      }
    }
  };

  const selectedDateTOShowFormatCheck = () => {
    const originalDate = new Date(selectedDateTOShow);
    const formattedDate = originalDate.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
    if (!formattedDate || formattedDate === 'Invalid Date') {
      return dateToday;
    } else {
      return selectedDateTOShow;
    }
  };

  const showDateSelected = () => {
    let lastSelectedDate = new Date(lastSelectedDateSubmit);

    if (typeof selectedDateTOShow === 'string') {
      if (selectedDateTOShow.includes('undefined')) {
        if (lastSelectedDateSubmit.length > 15) {
          const [startDate, endDate] = lastSelectedDateSubmit.split(' - ');

          // Parse the start and end dates
          const parsedStartDate = new Date(startDate);
          const parsedEndDate = new Date(endDate);

          // Check if both dates are valid
          if (
            !isNaN(parsedStartDate.getTime()) &&
            !isNaN(parsedEndDate.getTime())
          ) {
            // Format and return the date range
            const formattedStartDate = parsedStartDate.toLocaleDateString(
              'en-US',
              { month: 'short', day: 'numeric', year: 'numeric' }
            );
            const formattedEndDate = parsedEndDate.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            });
            return `${formattedStartDate} - ${formattedEndDate}`;
          }
        } else {
          return formatDate(lastSelectedDate);
        }
      } else {
        if (selectedDateTOShow.length > 15) {
          const [startDate, endDate] = selectedDateTOShow.split(' - ');

          // Parse the start and end dates
          const parsedStartDate = new Date(startDate);
          const parsedEndDate = new Date(endDate);

          // Check if both dates are valid
          if (
            !isNaN(parsedStartDate.getTime()) &&
            !isNaN(parsedEndDate.getTime())
          ) {
            // Format and return the date range
            const formattedStartDate = parsedStartDate.toLocaleDateString(
              'en-US',
              { month: 'short', day: 'numeric', year: 'numeric' }
            );
            const formattedEndDate = parsedEndDate.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            });
            return `${formattedStartDate} - ${formattedEndDate}`;
          }
        } else {
          return selectedDateTOShow;
        }
      }
    } else if (
      selectedDateTOShow instanceof Date &&
      !isNaN(selectedDateTOShow.getTime())
    ) {
      let formattedDate = selectedDateTOShow.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
      return formattedDate;
    } else {
      return formatDate(lastSelectedDate);
    }
  };

  let selectedData = {
    person: false,
    face: false,
    vehicle: false,
    licensePlate: false,
    sound: false,
    motion: false,
    number: false,
    male: false,
    female: false,
    hat: false,
    bag: false,
    pantsColors: Utils.getCommonColor([], 'bottom', 1),
    shirtsColors: Utils.getCommonColor([], 'top', 2),
    optical: false,
    mask: false,
    young: false,
    adult: false,
    middle: false,
    senior: false,
    car: false,
    bus: false,
    bicycle: false,
    motorcycle: false,
    truck: false,
    extColors: Utils.getCommonColor([], '', 3),
    glassBreak: false,
    gunshot: false,
    scream: false,
    explosion: false,
    fireAlarm: false,
  };

  let selectedDataAllFalse = {
    person: false,
    face: false,
    vehicle: false,
    licensePlate: false,
    sound: false,
    motion: false,
    number: false,
    male: false,
    female: false,
    hat: false,
    bag: false,
    pantsColors: Utils.getCommonColor([], 'bottom', 1),
    shirtsColors: Utils.getCommonColor([], 'top', 2),
    optical: false,
    mask: false,
    young: false,
    adult: false,
    middle: false,
    senior: false,
    car: false,
    bus: false,
    bicycle: false,
    motorcycle: false,
    truck: false,
    extColors: Utils.getCommonColor([], '', 3),
    glassBreak: false,
    gunshot: false,
    scream: false,
    explosion: false,
    fireAlarm: false,
  };

  const setAllCategoriesValuesAsFalse = () => {
    setYoung(false);
    setAdult(false);
    setMiddle(false);
    setSenior(false);
    setMale(false);
    setFemale(false);
    setHat(false);
    setBag(false);
    setOptical(false);
    setMask(false);
    setCar(false);
    setBus(false);
    setTruck(false);
    setMoterCycle(false);
    setBicycle(false);
    setGlassBreak(false);
    setGunshot(false);
    setScream(false);
    setFireAlarm(false);
    setExplosion(false);
    setLicensePlate(false);

    setPantColorRed(false);
    setPantColorBlue(false);
    setPantColorYellow(false);
    setPantColorGreen(false);
    setPantColorBlack(false);
    setPantColorGray(false);
    setPantColorWhite(false);

    setShirtColorRed(false);
    setShirtColorBlue(false);
    setShirtColorYellow(false);
    setShirtColorGreen(false);
    setShirtColorBlack(false);
    setShirtColorGray(false);
    setShirtColorWhite(false);

    setVehicleColorRed(false);
    setVehicleColorBlue(false);
    setVehicleColorYellow(false);
    setVehicleColorGreen(false);
    setVehicleColorBlack(false);
    setVehicleColorGray(false);
    setVehicleColorWhite(false);

    setAgeListSelected([]);
    setFaceAccListSelected([]);
    setPersonAccListSelected([]);
    setPersonPantListSelected([]);
    setPersonShirtListSelected([]);
    setVehicleColorListSelected([]);
    setVehicleTypeListSelected([]);
    setSoundTypeListSelected([]);
    setGenderListSelected([]);

    setSelectedAge([]);
    setSelectedFaceAcc([]);
    setSelectedGender([]);
    setSelectedAccessories([]);
    setSelectedPantColor([]);
    setSelectedShirtColor([]);
    setSelectedVehicleColor([]);
    setSelectedVehicleType([]);
    setSelectedSoundType([]);
  };

  const [eventTypeList, setEventTypeList] = useState([
    constants.EVENT_FILTER_FACE,
    constants.EVENT_FILTER_PERSON,
    constants.EVENT_FILTER_VEHICLE,
    constants.EVENT_FILTER_LP,
    constants.EVENT_FILTER_AUDIO,
    constants.EVENT_FILTER_MOTION,
  ]);

  const eventTypeListAll = [
    constants.EVENT_FILTER_FACE,
    constants.EVENT_FILTER_PERSON,
    constants.EVENT_FILTER_VEHICLE,
    constants.EVENT_FILTER_LP,
    constants.EVENT_FILTER_AUDIO,
    constants.EVENT_FILTER_MOTION,
  ];

  const eventTypeListWithFace = [constants.EVENT_FILTER_FACE];

  const eventTypeListWithPerson = [constants.EVENT_FILTER_PERSON];

  const eventTypeListWithVehicle = [constants.EVENT_FILTER_VEHICLE];

  const eventTypeListWithLP = [constants.EVENT_FILTER_LP];

  const eventTypeListWithAudio = [constants.EVENT_FILTER_AUDIO];

  const ageList = [
    constants.SEARCH_YOUNG,
    constants.SEARCH_ADULT,
    constants.SEARCH_MIDDLE_AGED,
    constants.SEARCH_SENIOR,
  ];

  const faceAccList = [constants.SEARCH_GLASSES, constants.SEARCH_MASK];

  const genderList = [constants.SEARCH_MALE, constants.SEARCH_FEMALE];

  const accessoriesList = [constants.SEARCH_HAT, constants.SEARCH_BAG];

  const pantColorlist = [
    constants.EVENT_FILTER_COLOR_RED,
    constants.EVENT_FILTER_COLOR_BLUE,
    constants.EVENT_FILTER_COLOR_YELLOW,
    constants.EVENT_FILTER_COLOR_GREEN,
    constants.EVENT_FILTER_COLOR_BLACK,
    constants.EVENT_FILTER_COLOR_GRAY,
    constants.EVENT_FILTER_COLOR_WHITE,
  ];

  const shirtColorlist = [
    constants.EVENT_FILTER_COLOR_RED,
    constants.EVENT_FILTER_COLOR_BLUE,
    constants.EVENT_FILTER_COLOR_YELLOW,
    constants.EVENT_FILTER_COLOR_GREEN,
    constants.EVENT_FILTER_COLOR_BLACK,
    constants.EVENT_FILTER_COLOR_GRAY,
    constants.EVENT_FILTER_COLOR_WHITE,
  ];

  const vehicleColorlist = [
    constants.EVENT_FILTER_COLOR_RED,
    constants.EVENT_FILTER_COLOR_BLUE,
    constants.EVENT_FILTER_COLOR_YELLOW,
    constants.EVENT_FILTER_COLOR_GREEN,
    constants.EVENT_FILTER_COLOR_BLACK,
    constants.EVENT_FILTER_COLOR_GRAY,
    constants.EVENT_FILTER_COLOR_WHITE,
  ];

  const vehicleTypelist = [
    constants.SEARCH_CAR,
    constants.SEARCH_BICYCLE,
    constants.SEARCH_BUS,
    constants.SEARCH_MOTERCYCLE,
    constants.SEARCH_TRUCK,
  ];

  const soundTypelist = [
    constants.SEARCH_GLASS_BREAK,
    constants.SEARCH_SCREAM,
    constants.EVENT_FILTER_GUNSHOT,
    constants.EVENT_FILTER_EXPLOSION,
  ];

  const removeDuplicates = (array) => {
    const seen = new Set();
    return array?.filter((obj) => {
      const key = `${obj.locationId}-${obj.areaId}`;
      if (!seen.has(key)) {
        seen.add(key);
        return true;
      }
      return false;
    });
  };

  const handleLocationSelection = (location) => {
    if (selectedLocations?.includes(location)) {
      setSelectedLocations(
        selectedLocations?.filter((item) => item !== location)
      );
    } else {
      setSelectedLocations([...selectedLocations, location]);
    }

    let currentSelectedLocation = selectedLocations;

    const index = currentSelectedLocation.indexOf(location);

    if (index !== -1) {
      currentSelectedLocation.splice(index, 1);
    } else {
      currentSelectedLocation.push(location);
    }

    allLocationsData = allLocationsDataInitial;
    allAreasData = allAreasDataInitial;
    allDevciesData = allDevciesDataInitial;
    setAllLocationsData(allLocationsDataInitial);
    setAllAreasData(allAreasDataInitial);
    setAllDevciesData(allDevciesDataInitial);

    // handle device change from location change
    let locationObjectsSelected = allLocationsDataInitial?.filter((item) =>
      currentSelectedLocation?.includes(item.locationName)
    );

    let locationsOfSlectedLocations = locationObjectsSelected?.map(
      (item) => item.locationId
    );
  
    const areaObecjtsOfSlectedLocations = allAreasDataInitial?.filter((item) =>
      locationsOfSlectedLocations?.includes(item.locationId)
    );

    const areaIdsOfSlectedLocations = areaObecjtsOfSlectedLocations?.map(
      (item) => item.areaId
    );

    const resultDevices = allDevciesDataInitial?.filter((item) =>
      areaIdsOfSlectedLocations?.includes(item.areaId)
    );

    if (currentSelectedLocation?.length === 0) {
      allDevciesData = allDevciesDataInitial;
      setAllDevciesData(allDevciesDataInitial);
    } else {
      setAllDevciesData(resultDevices);
    }

    // device list to pass
    devicesListToPass = [];
    resultDevices?.forEach((device) => {
      devicesListToPass.push(device?.deviceId);
    });
    setDevicesListToPass(devicesListToPass);

    // handle area change from location change
    let selectedAllLocationsDataInitial = allLocationsDataInitial;

    let seectedLocations = selectedAllLocationsDataInitial?.filter((item) =>
      currentSelectedLocation?.includes(item.locationName)
    );
  
    const selectedLocationIds = seectedLocations?.map(
      (item) => item.locationId
    );

    const areaResults = allAreasData?.filter((area) =>
      selectedLocationIds?.includes(area.locationId)
    );

    const uniqueAreaResults = removeDuplicates(areaResults);

    if (currentSelectedLocation?.length === 0) {
      allAreasData = allAreasDataInitial;
      setAllAreasData(allAreasDataInitial);
    } else {
      setAllAreasData(uniqueAreaResults);
    }

    // if there is any location is selected and its not in top 10 then expand the list
    if (selectedLocations?.length > 0) {
      let sortedLocationList = sortList(
        allLocationsData?.map((location) => location.locationName)
      )
        ?.filter((item) => item.toLowerCase())
        .slice(0, visibleItemCountForLocation);
      if (
        sortedLocationList.some(
          (location) => selectedLocations?.includes(location) === false
        )
      ) {
        // setVisibleItemCountForLocation(allLocationsDataInitial.length);
      }
    }
    // unselect all area and devices
    setSelectedAreas([]);
    setSelectedDevices([]);
    applyTag('Location');
  };

  const handleAreaSelection = (area) => {
    if (selectedAreas?.includes(area)) {
      setSelectedAreas(selectedAreas?.filter((item) => item !== area));
    } else {
      setSelectedAreas([...selectedAreas, area]);
    }

    let currentSelectedArea = selectedAreas;

    const index = currentSelectedArea?.indexOf(area);
    if (index !== -1) {
      currentSelectedArea?.splice(index, 1);
    } else {
      currentSelectedArea?.push(area);
    }

    allLocationsData = allLocationsDataInitial;
    allAreasData = allAreasDataInitial;
    allDevciesData = allDevciesDataInitial;
    setAllLocationsData(allLocationsDataInitial);
    setAllAreasData(allAreasDataInitial);
    setAllDevciesData(allDevciesDataInitial);

    // handle location change from area change
    let selectedLocationIdsFiltered;
    let areaObjectsSelected = allAreasDataInitial?.filter((item) =>
      currentSelectedArea?.includes(item.areaName)
    );

    let locationIdsOfAreasSelects = areaObjectsSelected?.map(
      (item) => item.locationId
    );

    const resultLocations = allLocationsData?.filter((item) =>
      locationIdsOfAreasSelects?.includes(item.locationId)
    );

    // filter location selected list after getting resultLocations values
    if (resultLocations?.length > 0) {
      let resultLocationNames = resultLocations?.map(
        (item) => item.locationName
      );
      let selectedLocationsFiltered = selectedLocations?.filter((item) =>
        resultLocationNames?.includes(item)
      );
      selectedLocationIdsFiltered = resultLocations
        ?.filter((item) =>
          selectedLocationsFiltered?.includes(item.locationName)
        )
        ?.map((item) => item.locationId);
      setSelectedLocations(selectedLocationsFiltered);
    }
    if (currentSelectedArea?.length === 0) {
      allLocationsData = allLocationsDataInitial;
      setAllLocationsData(allLocationsDataInitial);
    } else {
      setAllLocationsData(resultLocations);
    }

    // handle device change from area change
    // handle duplicate area id scenario and fetch actual area id for selected location id
    areaObjectsSelected = areaObjectsSelected?.filter((item) =>
      selectedLocationIdsFiltered?.includes(item.locationId)
    );
    let areaIdsOfAreasSelects = areaObjectsSelected?.map(
      (item) => item.locationId
    );
    const resultDevices = allDevciesData?.filter((item) =>
      areaIdsOfAreasSelects?.includes(item?.locationId)
    );
    if (currentSelectedArea?.length === 0) {
      allDevciesData = allDevciesDataInitial;
      setAllDevciesData(allDevciesDataInitial);
      setSelectedDevices([]);
      // device list to pass if area is not there
      if (selectedLocations?.length > 0) {
        // get ids of selected locations
        const selectedLocationsObjects = allLocationsData?.filter((item) =>
          selectedLocations?.includes(item?.locationName)
        );
        const selectedLocationsIds = selectedLocationsObjects?.map(
          (item) => item.locationId
        );
        // set those devices associated with selectedLocationsIds
        const selectedDeviceList = allDevciesData?.filter((item) =>
          selectedLocationsIds?.includes(item.locationId)
        );
        const selectedDeviceIds = selectedDeviceList?.map(
          (item) => item.deviceId
        );
        devicesListToPass = selectedDeviceIds;
        setDevicesListToPass(selectedDeviceIds);
      } else {
        // all device list to pass if no area is there
        setAllDevciesData(allDevciesData);
        devicesListToPass = [];
        allDevciesData?.forEach((device) => {
          devicesListToPass?.push(device?.deviceId);
        });
        setDevicesListToPass(devicesListToPass);
      }
    } else if (currentSelectedArea?.length > 0) {
      if (selectedLocations?.length === 0) {
        // show area related devices if location is not selected and area is selected
        let selectedAreaIdList = allAreasData
          ?.filter((item) => currentSelectedArea?.includes(item.areaName))
          ?.map((item) => item.areaId);
        let selectedDeviceIdList = allDevciesData?.filter((item) =>
          selectedAreaIdList?.includes(item.areaId)
        );
        setAllDevciesData(selectedDeviceIdList);
        // device list to pass if area is there
        devicesListToPass = selectedDeviceIdList?.map((item) => item.deviceId);
        setDevicesListToPass(
          selectedDeviceIdList?.map((item) => item.deviceId)
        );
      } else {
        // Location and area both are there
        const selectedAreaObjects = allAreasData?.filter((item) =>
          currentSelectedArea?.includes(item.areaName)
        );
        const selectedLocationsObjects = allLocationsData?.filter((item) =>
          selectedLocations?.includes(item.locationName)
        );
        const selectedLocationsIds = selectedLocationsObjects?.map(
          (item) => item.locationId
        );

        // filtered area objects after duplicate removal
        const filteredAreaObjects = selectedAreaObjects?.filter((item) =>
          selectedLocationsIds?.includes(item.locationId)
        );
        const filteredAreaObjectsLocationIds = filteredAreaObjects?.map(
          (item) => item.locationId
        );
        const selectedDeviceObjects = allDevciesData?.filter((item) =>
          filteredAreaObjectsLocationIds?.includes(item.locationId)
        );

        // filter final device list with area ids
        const finalAreaIds = filteredAreaObjects.map((item) => item.areaId);
        const finalDeviceObjects = selectedDeviceObjects?.filter((item) =>
          finalAreaIds?.includes(item.areaId)
        );
        setAllDevciesData(finalDeviceObjects);
        const deviceListEntry = finalDeviceObjects?.map(
          (item) => item.deviceId
        );
        devicesListToPass = deviceListEntry;
        setDevicesListToPass((prevDevicesList) => {
          prevDevicesList = [];
          const updatedDevicesList = [];
          updatedDevicesList.push(deviceListEntry);
          return updatedDevicesList;
        });
      }
    } else {
      // all device list to pass if no area and locations
      setAllDevciesData(allDevciesData);
      devicesListToPass = [];
      allDevciesData?.forEach((device) => {
        devicesListToPass?.push(device?.deviceId);
      });
      setDevicesListToPass(devicesListToPass);
    }

    // If location is already selected then only show related areas
    if (selectedLocations?.length > 0) {
      const locationSelectedObjects = allLocationsData?.filter((item) =>
        selectedLocations?.includes(item.locationName)
      );
      const locationIdsSelected = locationSelectedObjects?.map(
        (item) => item.locationId
      );
      const areasobjectSelected = allAreasDataInitial?.filter((item) =>
        locationIdsSelected?.includes(item.locationId)
      );

      if (currentSelectedArea?.length === 0) {
        // If all areas are unselected then show areas based on selected locations
        let selectedAllLocationsDataInitial = allLocationsDataInitial;
        let seectedLocations = selectedAllLocationsDataInitial?.filter((item) =>
          selectedLocations?.includes(item.locationName)
        );
        const selectedLocationIds = seectedLocations?.map(
          (item) => item.locationId
        );
        const areaResults = allAreasData?.filter((area) =>
          selectedLocationIds?.includes(area.locationId)
        );
        const uniqueAreaResults = removeDuplicates(areaResults);
        setAllAreasData(uniqueAreaResults);
      } else {
        setAllAreasData(areasobjectSelected);
      }
    }
    // uncheck all devices after area section
    setSelectedDevices([]);
    applyTag('Area');
  };

  const handleDevicesSelection = (device) => {
    if (selectedDevices?.includes(device)) {
      setSelectedDevices(selectedDevices?.filter((item) => item !== device));
    } else {
      setSelectedDevices([...selectedDevices, device]);
    }

    let currentSelectedDevices = selectedDevices;

    const index = currentSelectedDevices?.indexOf(device);
    if (index !== -1) {
      currentSelectedDevices?.splice(index, 1);
    } else {
      currentSelectedDevices?.push(device);
    }

    allLocationsData = allLocationsDataInitial;
    allAreasData = allAreasDataInitial;
    allDevciesData = allDevciesDataInitial;
    setAllLocationsData(allLocationsDataInitial);
    setAllAreasData(allAreasDataInitial);

    const allDevicesLocatinNames = [];
    const allDevicesAreaNames = [];

    allDevciesData?.forEach((device) => {
      if (currentSelectedDevices?.includes(device?.deviceName)) {
        allDevicesLocatinNames?.push(device?.locationName);
        allDevicesAreaNames?.push(device?.areas[0]?.areaName);
      }
    });

    if (currentSelectedDevices?.length > 0) {
      devicesListToPass = [];
      if (selectedAreas?.length > 0 && selectedLocations?.length === 0) {
        const selectedAreaObjects = allAreasData?.filter((device) =>
          selectedAreas?.includes(device?.areaName)
        );
        const selectedLocationIdByArea = selectedAreaObjects?.map(
          (item) => item.locationId
        );
        const filteredDeviceObjects = allDevciesData?.filter(
          (item) =>
            selectedLocationIdByArea?.includes(item.locationId) &&
            currentSelectedDevices?.includes(item?.deviceName)
        );
        const filteredDeviceIds = filteredDeviceObjects?.map(
          (item) => item.deviceId
        );
        devicesListToPass = filteredDeviceIds;
        setDevicesListToPass(devicesListToPass);
      }
      if (selectedAreas?.length === 0 && selectedLocations?.length > 0) {
        const selectedLocationObjects = allLocationsData?.filter((device) =>
          selectedLocations?.includes(device?.locationName)
        );
        const selectedLocationIds = selectedLocationObjects?.map(
          (item) => item.locationId
        );
        const filteredDeviceObjects = allDevciesData?.filter(
          (item) =>
            selectedLocationIds?.includes(item.locationId) &&
            currentSelectedDevices?.includes(item?.deviceName)
        );
        const filteredDeviceIds = filteredDeviceObjects?.map(
          (item) => item.deviceId
        );
        devicesListToPass = filteredDeviceIds;
        setDevicesListToPass(devicesListToPass);
      }
      if (selectedAreas?.length > 0 && selectedLocations?.length > 0) {
        const selectedAreaObjects = allAreasData?.filter((device) =>
          selectedAreas?.includes(device?.areaName)
        );
        const selectedLocationIdByArea = selectedAreaObjects?.map(
          (item) => item.locationId
        );

        const selectedLocationObjects = allLocationsData?.filter((device) =>
          selectedLocationIdByArea?.includes(device?.locationId)
        );
        const selectedLocationIds = selectedLocationObjects?.map(
          (item) => item.locationId
        );

        const filteredDeviceObjects = allDevciesData?.filter(
          (item) =>
            selectedLocationIdByArea?.includes(item.locationId) &&
            selectedLocationIds?.includes(item.locationId) &&
            currentSelectedDevices?.includes(item?.deviceName)
        );

        const filteredDeviceIds = filteredDeviceObjects?.map(
          (item) => item.deviceId
        );

        devicesListToPass = filteredDeviceIds;
        setDevicesListToPass(devicesListToPass);
      }
      if (selectedAreas?.length === 0 && selectedLocations?.length === 0) {
        // If devices are selected and no area or loation is selected then pass those devices ID
        const selectedDeviceObjects = allDevciesData?.filter((device) =>
          currentSelectedDevices?.includes(device?.deviceName)
        );
        const selectedDeviceIds = selectedDeviceObjects?.map(
          (item) => item.deviceId
        );
        devicesListToPass = selectedDeviceIds;
        setDevicesListToPass(devicesListToPass);
      }
    } else {
      // If all areas unselected then pass device id of selected areas
      if (selectedAreas?.length > 0) {
        const selectedAreaObjects = allAreasData?.filter((item) =>
          selectedAreas?.includes(item.areaName)
        );
        const selectedAreasLocationIds = selectedAreaObjects?.map(
          (item) => item.locationId
        );

        //filter if duplicate values are there of devices
        const selcetedLocationsObject = allLocationsData?.filter((item) =>
          selectedAreasLocationIds?.includes(item.locationId)
        );
        const selcetedLocationsIds = selcetedLocationsObject?.map(
          (item) => item.locationId
        );

        const selectedDeviceObjects = allDevciesData?.filter((device) =>
          selcetedLocationsIds?.includes(device?.locationId)
        );
        const selectedDeviceIds = selectedDeviceObjects?.map(
          (item) => item.deviceId
        );
        devicesListToPass = selectedDeviceIds;
        setDevicesListToPass(devicesListToPass);
      } else {
        // if locations are selected only
        if (selectedLocations?.length > 0) {
          const selectedLocationObjects = allLocationsData?.filter((item) =>
            selectedLocations?.includes(item.locationName)
          );
          const selectedLocationIds = selectedLocationObjects?.map(
            (item) => item.locationId
          );
          const selectedDeviceObjects = allDevciesData?.filter((device) =>
            selectedLocationIds?.includes(device?.locationId)
          );
          const selectedDeviceIds = selectedDeviceObjects?.map(
            (item) => item.deviceId
          );
          devicesListToPass = selectedDeviceIds;
          setDevicesListToPass(devicesListToPass);
        } else {
          devicesListToPass = allDevciesData?.map((item) => item.deviceId);
          setDevicesListToPass(devicesListToPass);
        }
      }
    }

    // handle location change from device change
    let deviceObjectsSelected = allDevciesDataInitial?.filter((item) =>
      currentSelectedDevices?.includes(item.deviceName)
    );
    let locationIdsOfDevciesSelects = deviceObjectsSelected?.map(
      (item) => item.locationId
    );
    const resultLocations = allLocationsData?.filter((item) =>
      locationIdsOfDevciesSelects?.includes(item.locationId)
    );
    // filter location selected list after getting resultLocations values
    if (resultLocations?.length > 0) {
      let resultLocationNames = resultLocations?.map(
        (item) => item.locationName
      );
      let selectedLocationsFiltered = selectedLocations?.filter((item) =>
        resultLocationNames?.includes(item)
      );
      setSelectedLocations(selectedLocationsFiltered);
    }
    if (currentSelectedDevices?.length === 0) {
      // If all devices are unselected, then show all locations
      allLocationsData = allLocationsDataInitial;
      setAllLocationsData(allLocationsDataInitial);
    } else {
      setAllLocationsData(resultLocations);
    }

    // handle area change from device change
    if (selectedLocations?.length > 0) {
      //check if any selected locations. if there are locations
      //selected then show areas based on selected locations.
      let selectedAreaObjects = [];
      if (currentSelectedDevices?.length > 0) {
        const selectedDevicesObjects = allDevciesData?.filter((item) =>
          currentSelectedDevices?.includes(item.deviceName)
        );
        const selectedLocationsIdsByDevices = selectedDevicesObjects?.map(
          (item) => item.locationId
        );
        selectedAreaObjects = allAreasData?.filter((item) =>
          selectedLocationsIdsByDevices?.includes(item.locationId)
        );
        const selectedLocationsObjects = allLocationsData?.filter((item) =>
          selectedLocations?.includes(item.locationName)
        );
        const selectedLocationsIdsByLocations = selectedLocationsObjects?.map(
          (item) => item.locationId
        );
        const selectedAreaObjectsFinal = selectedAreaObjects?.filter((item) =>
          selectedLocationsIdsByLocations?.includes(item.locationId)
        );
        setAllAreasData(selectedAreaObjectsFinal);
      } else {
        const selectedLocationsObjects = allLocationsData?.filter((item) =>
          selectedLocations?.includes(item.locationName)
        );
        const selectedLocationsIds = selectedLocationsObjects?.map(
          (item) => item.locationId
        );
        selectedAreaObjects = allAreasData?.filter((item) =>
          selectedLocationsIds?.includes(item.locationId)
        );
        setAllAreasData(selectedAreaObjects);
      }
    } else {
      if (currentSelectedDevices?.length > 0) {
        //If there are no locations but devices are selected, then show all areas according to devices.
        const selectedDevicesObjects = allDevciesData?.filter((item) =>
          currentSelectedDevices?.includes(item.deviceName)
        );
        const selectedLocationsIdsByDevices = selectedDevicesObjects?.map(
          (item) => item.locationId
        );
        const selectedAreaObjects = allAreasData?.filter((item) =>
          selectedLocationsIdsByDevices?.includes(item.locationId)
        );
        // filter final area objects which has area id present in selectedDevicesObjects
        const deviceObjectsAreaIds = selectedDevicesObjects?.map(
          (item) => item.areaId
        );
        const finalAreaObjectsList = selectedAreaObjects?.filter((item) =>
          deviceObjectsAreaIds?.includes(item.areaId)
        );
        setAllAreasData(finalAreaObjectsList);
      } else {
        //If there are no locations and devices selected, then show all areas.
        setAllAreasData(allAreasData);
      }
    }

    // uncheck all devices after area section
    applyTag('Device');
  };

  const handleAgeSelection = (age, tagCreation) => {
    if (selectedAge?.includes(age) && typeof selectedAge === 'string') {
      setSelectedAge([]);
    } else if (selectedAge?.includes(age) && Array.isArray(selectedAge)) {
      setSelectedAge(selectedAge?.filter((item) => item !== age));
    } else {
      setSelectedAge([...selectedAge, age]);
    }
    if (tagCreation) {
      if (age === 'Young') {
        setYoung(true);
        applyTag('Young', true, true);
      }
      if (age === 'Adult') {
        setAdult(true);
        applyTag('Adult', true, true);
      }
      if (age === 'Middle-Aged') {
        setMiddle(true);
        applyTag('Middle-Aged', true, true);
      }
      if (age === 'Senior') {
        setSenior(true);
        applyTag('Senior', true, true);
      }
    } else {
      if (age === 'Young') {
        setYoung(!young);
        applyTag('Young', !young, false);
      }
      if (age === 'Adult') {
        setAdult(!adult);
        applyTag('Adult', !adult, false);
      }
      if (age === 'Middle-Aged') {
        setMiddle(!middle);
        applyTag('Middle-Aged', !middle, false);
      }
      if (age === 'Senior') {
        setSenior(!senior);
        applyTag('Senior', !senior, false);
      }
    }
  };

  const handleFaceAccSelection = (faceAcc, tagCreation) => {
    if (
      selectedFaceAcc?.includes(faceAcc) &&
      typeof selectedFaceAcc === 'string'
    ) {
      setSelectedFaceAcc([]);
    } else if (
      selectedFaceAcc?.includes(faceAcc) &&
      Array.isArray(selectedFaceAcc)
    ) {
      setSelectedFaceAcc(selectedFaceAcc?.filter((item) => item !== faceAcc));
    } else {
      setSelectedFaceAcc([...selectedFaceAcc, faceAcc]);
    }
    if (tagCreation) {
      if (faceAcc === 'Glasses') {
        setOptical(true);
        applyTag('Glasses', true, true);
      }
      if (faceAcc === 'Mask') {
        setMask(true);
        applyTag('Mask', true, true);
      }
    } else {
      if (faceAcc === 'Glasses') {
        setOptical(!optical);
        applyTag('Glasses', !optical, false);
      }
      if (faceAcc === 'Mask') {
        setMask(!mask);
        applyTag('Mask', !mask, false);
      }
    }
  };

  const handleGenderSelection = (gender, tagCreation) => {
    if (
      selectedGender?.includes(gender) &&
      typeof selectedGender === 'string'
    ) {
      setSelectedGender([]);
    } else if (
      selectedGender?.includes(gender) &&
      Array.isArray(selectedGender)
    ) {
      setSelectedGender(selectedGender?.filter((item) => item !== gender));
    } else {
      setSelectedGender([...selectedGender, gender]);
    }

    if (tagCreation) {
      if (gender === 'Male') {
        setMale(true);
        applyTag('Male', true, true);
      }
      if (gender === 'Female') {
        setFemale(true);
        applyTag('Female', true, true);
      }
    } else {
      if (gender === 'Male') {
        setMale(!male);
        applyTag('Male', !male, false);
      }
      if (gender === 'Female') {
        setFemale(!female);
        applyTag('Female', !female, false);
      }
    }
  };

  const handleAccessoriesSelection = (acc, tagCreation) => {
    if (
      selectedAccessories?.includes(acc) &&
      typeof selectedAccessories === 'string'
    ) {
      setSelectedAccessories([]);
    } else if (
      selectedAccessories?.includes(acc) &&
      Array.isArray(selectedAccessories)
    ) {
      setSelectedAccessories(
        selectedAccessories?.filter((item) => item !== acc)
      );
    } else {
      setSelectedAccessories([...selectedAccessories, acc]);
    }

    if (tagCreation) {
      if (acc === 'Hat') {
        setHat(true);
        applyTag('Hat', true, true);
      }
      if (acc === 'Bag') {
        setBag(true);
        applyTag('Bag', true, true);
      }
    } else {
      if (acc === 'Hat') {
        setHat(!hat);
        applyTag('Hat', !hat, false);
      }
      if (acc === 'Bag') {
        setBag(!bag);
        applyTag('Bag', !bag, false);
      }
    }
  };

  const handlePantColorSelection = (pantColor, tagCreation) => {
    if (
      selectedPantColor?.includes(pantColor) &&
      typeof selectedPantColor === 'string'
    ) {
      setSelectedPantColor([]);
    } else if (
      selectedPantColor?.includes(pantColor) &&
      Array.isArray(selectedPantColor)
    ) {
      setSelectedPantColor(
        selectedPantColor?.filter((item) => item !== pantColor)
      );
    } else {
      setSelectedPantColor([...selectedPantColor, pantColor]);
    }

    if (tagCreation) {
      if (pantColor === 'Red') {
        setPantColorRed(true);
        applyTag('PantColorRed', true, true);
      }
      if (pantColor === 'Blue') {
        setPantColorBlue(true);
        applyTag('PantColorBlue', true, true);
      }
      if (pantColor === 'Yellow') {
        setPantColorYellow(true);
        applyTag('PantColorYellow', true, true);
      }
      if (pantColor === 'Green') {
        setPantColorGreen(true);
        applyTag('PantColorGreen', true, true);
      }
      if (pantColor === 'Black') {
        setPantColorBlack(true);
        applyTag('PantColorBlack', true, true);
      }
      if (pantColor === 'Gray') {
        setPantColorGray(true);
        applyTag('PantColorGray', true, true);
      }
      if (pantColor === 'White') {
        setPantColorWhite(true);
        applyTag('PantColorWhite', true, true);
      }
    } else {
      if (pantColor === 'Red') {
        setPantColorRed(!pantColorRed);
        applyTag('PantColorRed', !pantColorRed, false);
      }
      if (pantColor === 'Blue') {
        setPantColorBlue(!pantColorBlue);
        applyTag('PantColorBlue', !pantColorBlue, false);
      }
      if (pantColor === 'Yellow') {
        setPantColorYellow(!pantColorYellow);
        applyTag('PantColorYellow', !pantColorYellow, false);
      }
      if (pantColor === 'Green') {
        setPantColorGreen(!pantColorGreen);
        applyTag('PantColorGreen', !pantColorGreen, false);
      }
      if (pantColor === 'Black') {
        setPantColorBlack(!pantColorBlack);
        applyTag('PantColorBlack', !pantColorBlack, false);
      }
      if (pantColor === 'Gray') {
        setPantColorGray(!pantColorGray);
        applyTag('PantColorGray', !pantColorGray, false);
      }
      if (pantColor === 'White') {
        setPantColorWhite(!pantColorWhite);
        applyTag('PantColorWhite', !pantColorWhite, false);
      }
    }
  };

  const handleShirtColorSelection = (shirtColor, tagCreation) => {
    if (
      selectedShirtColor?.includes(shirtColor) &&
      typeof selectedShirtColor === 'string'
    ) {
      setSelectedShirtColor([]);
    } else if (
      selectedShirtColor?.includes(shirtColor) &&
      Array.isArray(selectedShirtColor)
    ) {
      setSelectedShirtColor(
        selectedShirtColor?.filter((item) => item !== shirtColor)
      );
    } else {
      setSelectedShirtColor([...selectedShirtColor, shirtColor]);
    }

    if (tagCreation) {
      if (shirtColor === 'Red') {
        setShirtColorRed(true);
        applyTag('ShirtColorRed', true, true);
      }
      if (shirtColor === 'Blue') {
        setShirtColorBlue(true);
        applyTag('ShirtColorBlue', true, true);
      }
      if (shirtColor === 'Yellow') {
        setShirtColorYellow(true);
        applyTag('ShirtColorYellow', true, true);
      }
      if (shirtColor === 'Green') {
        setShirtColorGreen(true);
        applyTag('ShirtColorGreen', true, true);
      }
      if (shirtColor === 'Black') {
        setShirtColorBlack(true);
        applyTag('ShirtColorBlack', true, true);
      }
      if (shirtColor === 'Gray') {
        setShirtColorGray(true);
        applyTag('ShirtColorGray', true, true);
      }
      if (shirtColor === 'White') {
        setShirtColorWhite(true);
        applyTag('ShirtColorWhite', true, true);
      }
    } else {
      if (shirtColor === 'Red') {
        setShirtColorRed(!shirtColorRed);
        applyTag('ShirtColorRed', !shirtColorRed, false);
      }
      if (shirtColor === 'Blue') {
        setShirtColorBlue(!shirtColorBlue);
        applyTag('ShirtColorBlue', !shirtColorBlue, false);
      }
      if (shirtColor === 'Yellow') {
        setShirtColorYellow(!shirtColorYellow);
        applyTag('ShirtColorYellow', !shirtColorYellow, false);
      }
      if (shirtColor === 'Green') {
        setShirtColorGreen(!shirtColorGreen);
        applyTag('ShirtColorGreen', !shirtColorGreen, false);
      }
      if (shirtColor === 'Black') {
        setShirtColorBlack(!shirtColorBlack);
        applyTag('ShirtColorBlack', !shirtColorBlack, false);
      }
      if (shirtColor === 'Gray') {
        setShirtColorGray(!shirtColorGray);
        applyTag('ShirtColorGray', !shirtColorGray, false);
      }
      if (shirtColor === 'White') {
        setShirtColorWhite(!shirtColorWhite);
        applyTag('ShirtColorWhite', !shirtColorWhite, false);
      }
    }
  };

  const handleVehicleColorSelection = (vehicleColor, tagCreation) => {
    if (
      selectedVehicleColor?.includes(vehicleColor) &&
      typeof selectedVehicleColor === 'string'
    ) {
      setSelectedVehicleColor([]);
    } else if (
      selectedVehicleColor?.includes(vehicleColor) &&
      Array.isArray(selectedVehicleColor)
    ) {
      setSelectedVehicleColor(
        selectedVehicleColor?.filter((item) => item !== vehicleColor)
      );
    } else {
      setSelectedVehicleColor([...selectedVehicleColor, vehicleColor]);
    }

    if (tagCreation) {
      if (vehicleColor === 'Red') {
        setVehicleColorRed(true);
        applyTag('VehicleColorRed', true, true);
      }
      if (vehicleColor === 'Blue') {
        setVehicleColorBlue(true);
        applyTag('VehicleColorBlue', true, true);
      }
      if (vehicleColor === 'Yellow') {
        setVehicleColorYellow(true);
        applyTag('VehicleColorYellow', true, true);
      }
      if (vehicleColor === 'Green') {
        setVehicleColorGreen(true);
        applyTag('VehicleColorGreen', true, true);
      }
      if (vehicleColor === 'Black') {
        setVehicleColorBlack(true);
        applyTag('VehicleColorBlack', true, true);
      }
      if (vehicleColor === 'Gray') {
        setVehicleColorGray(true);
        applyTag('VehicleColorGray', true, true);
      }
      if (vehicleColor === 'White') {
        setVehicleColorWhite(true);
        applyTag('VehicleColorWhite', true, true);
      }
    } else {
      if (vehicleColor === 'Red') {
        setVehicleColorRed(!vehicleColorRed);
        applyTag('VehicleColorRed', !vehicleColorRed, false);
      }
      if (vehicleColor === 'Blue') {
        setVehicleColorBlue(!vehicleColorBlue);
        applyTag('VehicleColorBlue', !vehicleColorBlue, false);
      }
      if (vehicleColor === 'Yellow') {
        setVehicleColorYellow(!vehicleColorYellow);
        applyTag('VehicleColorYellow', !vehicleColorYellow, false);
      }
      if (vehicleColor === 'Green') {
        setVehicleColorGreen(!vehicleColorGreen);
        applyTag('VehicleColorGreen', !vehicleColorGreen, false);
      }
      if (vehicleColor === 'Black') {
        setVehicleColorBlack(!vehicleColorBlack);
        applyTag('VehicleColorBlack', !vehicleColorBlack, false);
      }
      if (vehicleColor === 'Gray') {
        setVehicleColorGray(!vehicleColorGray);
        applyTag('VehicleColorGray', !vehicleColorGray, false);
      }
      if (vehicleColor === 'White') {
        setVehicleColorWhite(!vehicleColorWhite);
        applyTag('VehicleColorWhite', !vehicleColorWhite, false);
      }
    }
  };

  const handleVehicleTypeSelection = (vehicleType, tagCreation) => {
    if (
      selectedVehicleType?.includes(vehicleType) &&
      typeof selectedVehicleType === 'string'
    ) {
      setSelectedVehicleType([]);
    } else if (
      selectedVehicleType?.includes(vehicleType) &&
      Array.isArray(selectedVehicleType)
    ) {
      setSelectedVehicleType(
        selectedVehicleType?.filter((item) => item !== vehicleType)
      );
    } else {
      setSelectedVehicleType([...selectedVehicleType, vehicleType]);
    }

    if (tagCreation) {
      if (vehicleType === 'Car') {
        setCar(true);
        applyTag('Car', true, true);
      }
      if (vehicleType === 'Bicycle') {
        setBicycle(true);
        applyTag('Bicycle', true, true);
      }
      if (vehicleType === 'Bus') {
        setBus(true);
        applyTag('Bus', true, true);
      }
      if (vehicleType === 'Truck') {
        setTruck(true);
        applyTag('Truck', true, true);
      }
      if (vehicleType === 'Motorcycle') {
        setMoterCycle(true);
        applyTag('Motorcycle', true, true);
      }
    } else {
      if (vehicleType === 'Car') {
        setCar(!car);
        applyTag('Car', !car, false);
      }
      if (vehicleType === 'Bicycle') {
        setBicycle(!bicycle);
        applyTag('Bicycle', !bicycle, false);
      }
      if (vehicleType === 'Bus') {
        setBus(!bus);
        applyTag('Bus', !bus, false);
      }
      if (vehicleType === 'Truck') {
        setTruck(!truck);
        applyTag('Truck', !truck, false);
      }
      if (vehicleType === 'Motorcycle') {
        setMoterCycle(!moterCycle);
        applyTag('Motorcycle', !moterCycle, false);
      }
    }
  };

  const handleSoundTypeSelection = (soundType, tagCreation) => {
    if (
      selectedSoundType?.includes(soundType) &&
      typeof selectedSoundType === 'string'
    ) {
      setSelectedSoundType([]);
    } else if (
      selectedSoundType?.includes(soundType) &&
      Array.isArray(selectedSoundType)
    ) {
      setSelectedSoundType(
        selectedSoundType?.filter((item) => item !== soundType)
      );
    } else {
      setSelectedSoundType([...selectedSoundType, soundType]);
    }

    if (tagCreation) {
      if (soundType === 'Glass Break') {
        setGlassBreak(true);
        applyTag('Glass Break', true, true);
      }
      if (soundType === 'Scream') {
        setScream(true);
        applyTag('Scream', true, true);
      }
      if (soundType === 'Gunshot') {
        setGunshot(true);
        applyTag('Gunshot', true, true);
      }
      if (soundType === 'Explosion') {
        setExplosion(true);
        applyTag('Explosion', true, true);
      }
    } else {
      if (soundType === 'Glass Break') {
        setGlassBreak(!glassBreak);
        applyTag('Glass Break', !glassBreak, false);
      }
      if (soundType === 'Scream') {
        setScream(!scream);
        applyTag('Scream', !scream, false);
      }
      if (soundType === 'Gunshot') {
        setGunshot(!gunshot);
        applyTag('Gunshot', !gunshot, false);
      }
      if (soundType === 'Explosion') {
        setExplosion(!explosion);
        applyTag('Explosion', !explosion, false);
      }
    }
  };

  const toggleLocationListVisibility = () => {
    setIsLocationListVisible(!isLocationListVisible);
  };

  const toggleAreaListVisibility = () => {
    setIsAreaListVisible(!isAreaListVisible);
  };

  const toggleDeviceListVisibility = () => {
    setIsDeviceListVisible(!isDeviceListVisible);
  };

  const toggleAgeListVisibility = () => {
    setIsAgeListVisible(!isAgeListVisible);
  };

  const toggleFaceAccListVisibility = () => {
    setIsFaceAccListVisible(!isFaceAccListVisible);
  };

  const toggleGenderListVisibility = () => {
    setIsGenderListVisible(!isGenderListVisible);
  };

  const toggleAccessoriesListVisibility = () => {
    setIsAccessoriesListVisible(!isAccessoriesListVisible);
  };

  const togglePantColorListVisibility = () => {
    setIsPantColorListVisible(!isPantColorListVisible);
  };

  const toggleShirtColorListVisibility = () => {
    setIsShirtColorListVisible(!isShirtColorListVisible);
  };

  const toggleVehicleColorListVisibility = () => {
    setIsVehicleColorListVisible(!isVehicleColorListVisible);
  };

  const toggleVehicleTypeListVisibility = () => {
    setIsVehicleTypeListVisible(!isVehicleTypeListVisible);
  };

  const toggleLicensePlateListVisibility = () => {
    setIsLicensePlateListVisible(!isLicensePlateListVisible);
  };

  const toggleSoundTypeListVisibility = () => {
    setIsSoundTypeListVisible(!isSoundTypeListVisible);
  };

  const handleShowMoreForLocation = () => {
    setVisibleItemCountForLocation((prevCount) => prevCount + 10);
  };

  const handleShowMoreForArea = () => {
    setVisibleItemCountForArea((prevCount) => prevCount + 10);
  };

  const handleShowMoreForDevice = () => {
    setVisibleItemCountForDevice((prevCount) => prevCount + 10);
  };

  const applyTag = async (item, removeTagFlag, skipAPICallForTagCreation) => {
    // primary event type section starts
    if (item === 'Location') {
    }
    if (item === 'Person') {
      setPersonEventTypeIsActive(!personEventTypeIsActive);

      // when event type is selected, expand the 2nd level attribute
      setIsAccessoriesListVisible(true);
      setIsGenderListVisible(true);
      setIsPantColorListVisible(true);
      setIsShirtColorListVisible(true);

      tags = {};
      person = {};
      face = {};
      vehicle = {};
      sound = {};
      motion = {};
      licensePlate = {};

      setTags({});
      setPerson({});
      setFace({});
      setVehicle({});
      setSound({});
      setMotion({});
      setLicensePlate({});

      selectedData = selectedDataAllFalse;
      setAllCategoriesValuesAsFalse();

      selectedData.person = true;
      const accessories = [];
      if (hat) {
        selectedData.hat = true;
        accessories.push('hat');
      }
      if (bag) {
        selectedData.bag = true;
        accessories.push('bag');
      }
      if (accessories?.length > 0 && accessories?.length !== 2) {
        person.accessories = accessories;
      }

      tags.person = person;
    }
    if (item === 'Face') {
      setFaceEventTypeIsActive(!faceEventTypeIsActive);

      // when event type is selected, expand the 2nd level attribute
      setIsAgeListVisible(true);
      setIsFaceAccListVisible(true);
      setIsGenderListVisible(true);

      tags = {};
      person = {};
      face = {};
      vehicle = {};
      sound = {};
      motion = {};
      licensePlate = {};

      setTags({});
      setPerson({});
      setFace({});
      setVehicle({});
      setSound({});
      setMotion({});
      setLicensePlate({});

      selectedData = selectedDataAllFalse;
      setAllCategoriesValuesAsFalse();

      selectedData.face = true;
      const accessories = [];
      const age = [];
      const gender = [];

      if (male) {
        selectedData.male = true;
        gender.push('male');
      }
      if (female) {
        selectedData.female = true;
        gender.push('female');
      }
      if (optical) {
        selectedData.optical = true;
        accessories.push('opticals');
      }
      if (mask) {
        selectedData.mask = true;
        accessories.push('mask');
      }
      if (young) {
        selectedData.young = true;
        age.push('young');
      }
      if (adult) {
        selectedData.adult = true;
        age.push('adult');
      }
      if (middle) {
        selectedData.middle = true;
        age.push('middle');
      }
      if (senior) {
        selectedData.senior = true;
        age.push('senior');
      }
      if (age?.length > 0 && age?.length !== 4) {
        face.ages = age;
      }
      if (gender?.length > 0 && gender?.length !== 2) {
        face.genders = gender;
      }
      if (accessories?.length > 0 && accessories?.length !== 2) {
        face.accessories = accessories;
      }
      tags.face = face;
    }
    if (item === 'Vehicle') {
      setVehicleEventTypeIsActive(!vehicleEventTypeIsActive);

      // when event type is selected, expand the 2nd level attribute
      setIsVehicleColorListVisible(true);
      setIsVehicleTypeListVisible(true);

      tags = {};
      person = {};
      face = {};
      vehicle = {};
      sound = {};
      motion = {};
      licensePlate = {};

      setTags({});
      setPerson({});
      setFace({});
      setVehicle({});
      setSound({});
      setMotion({});
      setLicensePlate({});

      selectedData = selectedDataAllFalse;
      setAllCategoriesValuesAsFalse();

      selectedData.vehicle = true;
      const type = [];
      if (car) {
        selectedData.car = true;
        type.push('car');
      }
      if (bus) {
        selectedData.bus = true;
        type.push('bus');
      }
      if (truck) {
        selectedData.truck = true;
        type.push('truck');
      }
      if (bicycle) {
        selectedData.bicycle = true;
        type.push('bicycle');
      }
      if (moterCycle) {
        selectedData.motorcycle = true;
        type.push('motorcycle');
      }
      if (type?.length > 0 && type?.length !== 5) {
        vehicle.types = type;
      }
      tags.vehicle = vehicle;
    }
    if (item === 'License Plate') {
      setLPEventTypeIsActive(!lPEventTypeIsActive);

      // when event type is selected, expand the 2nd level attribute
      setIsLicensePlateListVisible(true);

      tags = {};
      person = {};
      face = {};
      vehicle = {};
      sound = {};
      motion = {};
      licensePlate = {};

      setTags({});
      setPerson({});
      setFace({});
      setVehicle({});
      setSound({});
      setMotion({});
      setLicensePlate({});

      selectedData = selectedDataAllFalse;
      setAllCategoriesValuesAsFalse();

      selectedData.number = searchTermForLicense;
      setLicensePlate(!number);

      licensePlate.number = searchTermForLicense;
      tags.licensePlate = licensePlate;
      if (searchTermForLicense === '') {
        delete licensePlate.number;
        selectedData = selectedDataAllFalse;
        setEventTypeList(eventTypeListAll);
        setAllCategoriesValuesAsFalse();
      }
    }
    if (item === 'Audio') {
      setAudioEventTypeIsActive(!audioEventTypeIsActive);

      // when event type is selected, expand the 2nd level attribute
      setIsSoundTypeListVisible(true);

      tags = {};
      person = {};
      face = {};
      vehicle = {};
      sound = {};
      motion = {};
      licensePlate = {};

      setTags({});
      setPerson({});
      setFace({});
      setVehicle({});
      setSound({});
      setMotion({});
      setLicensePlate({});

      selectedData = selectedDataAllFalse;
      setAllCategoriesValuesAsFalse();

      selectedData.sound = true;
      const type = [];
      if (gunshot) {
        selectedData.gunshot = true;
        type.push('gun-shot');
      }
      if (scream) {
        selectedData.scream = true;
        type.push('scream');
      }
      if (explosion) {
        selectedData.explosion = true;
        type.push('explosion');
      }
      if (glassBreak) {
        selectedData.glassBreak = true;
        type.push('glass-breaking');
      }
      if (type?.length > 0 && type?.length !== 4) {
        sound.types = type;
      } else {
        sound.types = ['gun-shot', 'explosion', 'glass-breaking', 'scream'];
      }
      tags.sound = sound;
    }
    if (item === 'Motion') {
      setMotionEventTypeIsActive(!motionEventTypeIsActive);

      tags = {};
      person = {};
      face = {};
      vehicle = {};
      sound = {};
      motion = {};
      licensePlate = {};

      setTags({});
      setPerson({});
      setFace({});
      setVehicle({});
      setSound({});
      setMotion({});
      setLicensePlate({});

      selectedData = selectedDataAllFalse;
      setAllCategoriesValuesAsFalse();

      selectedData.motion = true;
      const types = ['motion-start'];
      motion.types = types;
      tags.motion = motion;
    }
    // primary event type section ends

    // secondary attributes section starts
    if (item === 'Male' || item === 'Female') {
      // handle scenario to reassign genderListSelected from generated tags of search
      if (
        genderListSelected?.length === 0 &&
        tags?.face?.genders?.length > 0 &&
        !skipAPICallForTagCreation
      ) {
        genderListSelected = tags?.face?.genders;
      } else if (
        genderListSelected?.length === 0 &&
        tags?.person?.genders?.length > 0 &&
        !skipAPICallForTagCreation
      ) {
        // handle scenario to reassign genderListSelected from generated tags of search
        genderListSelected = tags?.person?.genders;
      }

      if (selectedEventType === 'Person') {
        selectedData.person = true;
        selectedData.face = false;
        if (tags?.face) {
          delete tags?.face;
        }
      } else {
        if (tags?.person) {
          delete tags?.person;
        }
        selectedData.person = false;
        selectedData.face = true;
      }

      if (item === 'Male') {
        if (removeTagFlag) {
          selectedData.male = true;
          genderListSelected.push('male');
          if (selectedEventType === null || selectedEventType === 'Face') {
            // If tags are empty then default add gender to face
            if (genderListSelected?.length > 0) {
              face.genders = genderListSelected;
            }
            tags.face = face;
            setSelectedEventType('Face');
            setEventTypeList(eventTypeListWithFace);
          } else {
            if (genderListSelected?.length > 0) {
              person.genders = genderListSelected;
            }
            tags.person = person;
            setSelectedEventType('Person');
            setEventTypeList(eventTypeListWithPerson);
          }
        } else {
          selectedData.male = false;
          let genderListSelectedFiltered = genderListSelected?.filter(
            (item) => item !== 'male'
          );
          setGenderListSelected(genderListSelectedFiltered);
          if (selectedEventType === null || selectedEventType === 'Face') {
            if (genderListSelectedFiltered?.length >= 0) {
              face.genders = genderListSelectedFiltered;
              if (face && face.genders && face.genders.length === 0) {
                delete face.genders;
              }
            }
            tags.face = face;
            if (Object.values(tags?.face).length === 0) {
              selectedData = selectedDataAllFalse;
              setEventTypeList(eventTypeListAll);
              setAllCategoriesValuesAsFalse();
            }
          } else {
            if (genderListSelectedFiltered?.length >= 0) {
              person.genders = genderListSelectedFiltered;
              if (person && person.genders && person.genders.length === 0) {
                delete person.genders;
              }
            }
            tags.person = person;
            if (Object.values(tags?.person).length === 0) {
              selectedData = selectedDataAllFalse;
              setEventTypeList(eventTypeListAll);
              setAllCategoriesValuesAsFalse();
            }
          }
        }
      }

      if (item === 'Female') {
        if (removeTagFlag) {
          selectedData.female = true;
          genderListSelected.push('female');
          if (selectedEventType === null || selectedEventType === 'Face') {
            // If tags are empty then default add gender to face
            if (genderListSelected?.length > 0) {
              face.genders = genderListSelected;
            }
            tags.face = face;
            setSelectedEventType('Face');
            setEventTypeList(eventTypeListWithFace);
          } else {
            if (genderListSelected?.length > 0) {
              person.genders = genderListSelected;
            }
            tags.person = person;
            setSelectedEventType('Person');
            setEventTypeList(eventTypeListWithPerson);
          }
        } else {
          selectedData.female = false;
          let genderListSelectedFiltered = genderListSelected?.filter(
            (item) => item !== 'female'
          );
          setGenderListSelected(genderListSelectedFiltered);
          if (selectedEventType === null || selectedEventType === 'Face') {
            if (genderListSelectedFiltered?.length >= 0) {
              face.genders = genderListSelectedFiltered;
              if (face && face.genders && face.genders.length === 0) {
                delete face.genders;
              }
            }
            tags.face = face;
            if (Object.values(tags?.face).length === 0) {
              selectedData = selectedDataAllFalse;
              setEventTypeList(eventTypeListAll);
              setAllCategoriesValuesAsFalse();
            }
          } else {
            if (genderListSelectedFiltered?.length >= 0) {
              person.genders = genderListSelectedFiltered;
              if (person && person.genders && person.genders.length === 0) {
                delete person.genders;
              }
            }
            tags.person = person;
            if (Object.values(tags?.person).length === 0) {
              selectedData = selectedDataAllFalse;
              setEventTypeList(eventTypeListAll);
              setAllCategoriesValuesAsFalse();
            }
          }
        }
      }
      // create array for pre selection of checkboxes for gender category
      if (genderListSelected?.length > 0 && skipAPICallForTagCreation) {
        const selectedGenderFromTags = genderListSelected?.map((age) => {
          return age.charAt(0).toUpperCase() + age.slice(1);
        });
        selectedGender = selectedGenderFromTags;
      }
    }
    if (
      item === 'Young' ||
      item === 'Adult' ||
      item === 'Middle-Aged' ||
      item === 'Senior'
    ) {
      // handle scenario to reassign ageListSelected from generated tags of search
      if (
        ageListSelected?.length === 0 &&
        tags?.face?.ages?.length > 0 &&
        !skipAPICallForTagCreation
      ) {
        ageListSelected = tags?.face?.ages;
      }
      selectedData.face = true;
      if (item === 'Young') {
        if (removeTagFlag) {
          selectedData.young = true;
          ageListSelected.push('young');
          if (ageListSelected?.length > 0) {
            face.ages = ageListSelected;
          }
          tags.face = face;
          setSelectedEventType('Face');
          setEventTypeList(eventTypeListWithFace);
        } else {
          selectedData.young = false;
          let ageListSelectedFiltered = ageListSelected?.filter(
            (item) => item !== 'young'
          );
          setAgeListSelected(ageListSelectedFiltered);
          if (ageListSelectedFiltered?.length >= 0) {
            face.ages = ageListSelectedFiltered;
            if (face && face.ages && face.ages.length === 0) {
              delete face.ages;
            }
          }
          tags.face = face;
          if (Object.values(tags?.face).length === 0) {
            selectedData = selectedDataAllFalse;
            setEventTypeList(eventTypeListAll);
            setAllCategoriesValuesAsFalse();
          }
        }
      }

      if (item === 'Adult') {
        if (removeTagFlag) {
          selectedData.adult = true;
          ageListSelected?.push('adult');
          if (ageListSelected?.length > 0) {
            face.ages = ageListSelected;
          }
          tags.face = face;
          setSelectedEventType('Face');
          setEventTypeList(eventTypeListWithFace);
        } else {
          selectedData.adult = false;
          let ageListSelectedFiltered = ageListSelected?.filter(
            (item) => item !== 'adult'
          );
          setAgeListSelected(ageListSelectedFiltered);
          if (ageListSelectedFiltered?.length >= 0) {
            face.ages = ageListSelectedFiltered;
            if (face && face.ages && face.ages.length === 0) {
              delete face.ages;
            }
          }
          tags.face = face;
          if (Object.values(tags?.face).length === 0) {
            selectedData = selectedDataAllFalse;
            setEventTypeList(eventTypeListAll);
            setAllCategoriesValuesAsFalse();
          }
        }
      }

      if (item === 'Middle-Aged') {
        if (removeTagFlag) {
          selectedData.middle = true;
          ageListSelected?.push('middle');
          if (ageListSelected?.length > 0) {
            face.ages = ageListSelected;
          }
          tags.face = face;
          setSelectedEventType('Face');
          setEventTypeList(eventTypeListWithFace);
        } else {
          selectedData.middle = false;
          let ageListSelectedFiltered = ageListSelected?.filter(
            (item) => item !== 'middle'
          );
          setAgeListSelected(ageListSelectedFiltered);
          if (ageListSelectedFiltered?.length >= 0) {
            face.ages = ageListSelectedFiltered;
            if (face && face.ages && face.ages.length === 0) {
              delete face.ages;
            }
          }
          tags.face = face;
          if (Object.values(tags?.face).length === 0) {
            selectedData = selectedDataAllFalse;
            setEventTypeList(eventTypeListAll);
            setAllCategoriesValuesAsFalse();
          }
        }
      }

      if (item === 'Senior') {
        if (removeTagFlag) {
          selectedData.senior = true;
          ageListSelected?.push('senior');
          if (ageListSelected?.length > 0) {
            face.ages = ageListSelected;
          }
          tags.face = face;
          setSelectedEventType('Face');
          setEventTypeList(eventTypeListWithFace);
        } else {
          selectedData.senior = false;
          let ageListSelectedFiltered = ageListSelected?.filter(
            (item) => item !== 'senior'
          );
          setAgeListSelected(ageListSelectedFiltered);
          if (ageListSelectedFiltered?.length >= 0) {
            face.ages = ageListSelectedFiltered;
            if (face && face.ages && face.ages.length === 0) {
              delete face.ages;
            }
          }
          tags.face = face;
          if (Object.values(tags?.face).length === 0) {
            selectedData = selectedDataAllFalse;
            setEventTypeList(eventTypeListAll);
            setAllCategoriesValuesAsFalse();
          }
        }
      }
      // create array for pre selection of checkboxes for age category
      if (ageListSelected?.length > 0 && skipAPICallForTagCreation) {
        const selectedAgeFromTags = ageListSelected?.map((age) => {
          if (age.toLowerCase() === 'middle') {
            return 'Middle-Aged';
          } else {
            return age.charAt(0).toUpperCase() + age.slice(1);
          }
        });
        selectedAge = selectedAgeFromTags;
      }
    }
    if (item === 'Glasses' || item === 'Mask') {
      // handle scenario to reassign faceAccListSelected from generated tags of search
      if (
        faceAccListSelected?.length === 0 &&
        tags?.face?.accessories?.length > 0 &&
        !skipAPICallForTagCreation
      ) {
        faceAccListSelected = tags?.face?.accessories;
      }
      selectedData.face = true;
      if (item === 'Glasses') {
        if (removeTagFlag) {
          selectedData.optical = true;
          faceAccListSelected?.push('opticals');
          if (faceAccListSelected?.length > 0) {
            face.accessories = faceAccListSelected;
          }
          tags.face = face;
          setSelectedEventType('Face');
          setEventTypeList(eventTypeListWithFace);
        } else {
          selectedData.optical = false;
          let faceAccListSelectedFiltered = faceAccListSelected?.filter(
            (item) => item !== 'opticals'
          );
          setFaceAccListSelected(faceAccListSelectedFiltered);
          if (faceAccListSelectedFiltered?.length >= 0) {
            face.accessories = faceAccListSelectedFiltered;
            if (face && face.accessories && face.accessories.length === 0) {
              delete face.accessories;
            }
          }
          tags.face = face;
          if (Object.values(tags?.face).length === 0) {
            selectedData = selectedDataAllFalse;
            setEventTypeList(eventTypeListAll);
            setAllCategoriesValuesAsFalse();
          }
        }
      }

      if (item === 'Mask') {
        if (removeTagFlag) {
          selectedData.mask = true;
          faceAccListSelected.push('mask');
          if (faceAccListSelected?.length > 0) {
            face.accessories = faceAccListSelected;
          }
          tags.face = face;
          setSelectedEventType('Face');
          setEventTypeList(eventTypeListWithFace);
        } else {
          selectedData.mask = false;
          let faceAccListSelectedFiltered = faceAccListSelected?.filter(
            (item) => item !== 'mask'
          );
          setFaceAccListSelected(faceAccListSelectedFiltered);
          if (faceAccListSelectedFiltered?.length >= 0) {
            face.accessories = faceAccListSelectedFiltered;
            if (face && face.accessories && face.accessories.length === 0) {
              delete face.accessories;
            }
          }
          tags.face = face;
          if (Object.values(tags?.face).length === 0) {
            selectedData = selectedDataAllFalse;
            setEventTypeList(eventTypeListAll);
            setAllCategoriesValuesAsFalse();
          }
        }
      }
      // create array for pre selection of checkboxes for face accessories category
      if (faceAccListSelected?.length > 0 && skipAPICallForTagCreation) {
        const selectedFaceAccFromTags = faceAccListSelected?.map((age) => {
          return age.charAt(0).toUpperCase() + age.slice(1);
        });
        selectedFaceAcc = selectedFaceAccFromTags;
      }
    }
    if (item === 'Hat' || item === 'Bag') {
      // handle scenario to reassign personAccListSelected from generated tags of search
      if (
        personAccListSelected?.length === 0 &&
        tags?.person?.accessories?.length > 0 &&
        !skipAPICallForTagCreation
      ) {
        personAccListSelected = tags?.person?.accessories;
      }
      selectedData.person = true;
      if (item === 'Hat') {
        if (removeTagFlag) {
          selectedData.hat = true;
          personAccListSelected?.push('hat');
          if (personAccListSelected?.length > 0) {
            person.accessories = personAccListSelected;
          }
          tags.person = person;
          setSelectedEventType('Person');
          setEventTypeList(eventTypeListWithPerson);
        } else {
          selectedData.hat = false;
          let personAccListSelectedFiltered = personAccListSelected?.filter(
            (item) => item !== 'hat'
          );
          setPersonAccListSelected(personAccListSelectedFiltered);
          if (personAccListSelectedFiltered?.length >= 0) {
            person.accessories = personAccListSelectedFiltered;
            if (
              person &&
              person.accessories &&
              person.accessories.length === 0
            ) {
              delete person.accessories;
            }
          }
          tags.person = person;
          if (Object.values(tags?.person).length === 0) {
            selectedData = selectedDataAllFalse;
            setEventTypeList(eventTypeListAll);
            setAllCategoriesValuesAsFalse();
          }
        }
      }

      if (item === 'Bag') {
        if (removeTagFlag) {
          selectedData.bag = true;
          personAccListSelected?.push('bag');
          if (personAccListSelected?.length > 0) {
            person.accessories = personAccListSelected;
          }
          tags.person = person;
          setSelectedEventType('Person');
          setEventTypeList(eventTypeListWithPerson);
        } else {
          selectedData.bag = false;
          let personAccListSelectedFiltered = personAccListSelected?.filter(
            (item) => item !== 'bag'
          );
          setPersonAccListSelected(personAccListSelectedFiltered);
          if (personAccListSelectedFiltered?.length >= 0) {
            person.accessories = personAccListSelectedFiltered;
            if (
              person &&
              person.accessories &&
              person.accessories.length === 0
            ) {
              delete person.accessories;
            }
          }
          tags.person = person;
          if (Object.values(tags?.person).length === 0) {
            selectedData = selectedDataAllFalse;
            setEventTypeList(eventTypeListAll);
            setAllCategoriesValuesAsFalse();
          }
        }
      }
      // create array for pre selection of checkboxes for person accessories category
      if (personAccListSelected?.length > 0 && skipAPICallForTagCreation) {
        const selectedPersonAccFromTags = personAccListSelected?.map((age) => {
          return age.charAt(0).toUpperCase() + age.slice(1);
        });
        selectedAccessories = selectedPersonAccFromTags;
      }
    }
    if (
      item === 'PantColorRed' ||
      item === 'PantColorBlue' ||
      item === 'PantColorYellow' ||
      item === 'PantColorGreen' ||
      item === 'PantColorBlack' ||
      item === 'PantColorGray' ||
      item === 'PantColorWhite'
    ) {
      // handle scenario to reassign personPantListSelected from generated tags of search
      if (
        personPantListSelected?.length === 0 &&
        tags?.person?.pantsColors?.length > 0 &&
        !skipAPICallForTagCreation
      ) {
        personPantListSelected = tags?.person?.pantsColors;
      }
      selectedData.person = true;
      let pantColorNameToPushAndCheck = item.slice(9).toLowerCase();
      if (removeTagFlag) {
        personPantListSelected?.push(pantColorNameToPushAndCheck);
        if (personPantListSelected?.length >= 0) {
          person.pantsColors = personPantListSelected;
        }
        tags.person = person;
        setSelectedEventType('Person');
        setEventTypeList(eventTypeListWithPerson);
      } else {
        let personPantListSelectedFiltered = personPantListSelected?.filter(
          (item) => item !== pantColorNameToPushAndCheck
        );
        setPersonPantListSelected(personPantListSelectedFiltered);
        if (personPantListSelectedFiltered?.length >= 0) {
          person.pantsColors = personPantListSelectedFiltered;
          if (person && person.pantsColors && person.pantsColors.length === 0) {
            delete person.pantsColors;
          }
        }
        tags.person = person;
        if (Object.values(tags?.person).length === 0) {
          selectedData = selectedDataAllFalse;
          setEventTypeList(eventTypeListAll);
          setAllCategoriesValuesAsFalse();
        }
      }

      // create array for pre selection of checkboxes for person pant color category
      if (personPantListSelected?.length > 0 && skipAPICallForTagCreation) {
        const selectedPersonPantColorFromTags = personPantListSelected?.map(
          (age) => {
            return age.charAt(0).toUpperCase() + age.slice(1);
          }
        );
        selectedPantColor = selectedPersonPantColorFromTags;
      }
    }
    if (
      item === 'ShirtColorRed' ||
      item === 'ShirtColorBlue' ||
      item === 'ShirtColorYellow' ||
      item === 'ShirtColorGreen' ||
      item === 'ShirtColorBlack' ||
      item === 'ShirtColorGray' ||
      item === 'ShirtColorWhite'
    ) {
      // handle scenario to reassign personShirtListSelected from generated tags of search
      if (
        personShirtListSelected?.length === 0 &&
        tags?.person?.shirtColors?.length > 0 &&
        !skipAPICallForTagCreation
      ) {
        personShirtListSelected = tags?.person?.shirtColors;
      }
      selectedData.person = true;
      let shirtColorNameToPushAndCheck = item.slice(10).toLowerCase();
      if (removeTagFlag) {
        personShirtListSelected?.push(shirtColorNameToPushAndCheck);
        if (personShirtListSelected?.length >= 0) {
          person.shirtColors = personShirtListSelected;
        }
        tags.person = person;
        setSelectedEventType('Person');
        setEventTypeList(eventTypeListWithPerson);
      } else {
        let personShirtListSelectedFiltered = personShirtListSelected?.filter(
          (item) => item !== shirtColorNameToPushAndCheck
        );
        setPersonShirtListSelected(personShirtListSelectedFiltered);
        if (personShirtListSelectedFiltered?.length >= 0) {
          person.shirtColors = personShirtListSelectedFiltered;
          if (person && person.shirtColors && person.shirtColors.length === 0) {
            delete person.shirtColors;
          }
        }
        tags.person = person;
        if (Object.values(tags?.person).length === 0) {
          selectedData = selectedDataAllFalse;
          setEventTypeList(eventTypeListAll);
          setAllCategoriesValuesAsFalse();
        }
      }
      // create array for pre selection of checkboxes for shirt pant color category
      if (personShirtListSelected?.length > 0 && skipAPICallForTagCreation) {
        const selectedPersonShirtColorFromTags = personShirtListSelected?.map(
          (age) => {
            return age.charAt(0).toUpperCase() + age.slice(1);
          }
        );
        selectedShirtColor = selectedPersonShirtColorFromTags;
      }
    }
    if (
      item === 'VehicleColorRed' ||
      item === 'VehicleColorBlue' ||
      item === 'VehicleColorYellow' ||
      item === 'VehicleColorGreen' ||
      item === 'VehicleColorBlack' ||
      item === 'VehicleColorGray' ||
      item === 'VehicleColorWhite'
    ) {
      // handle scenario to reassign vehicleColorListSelected from generated tags of search
      if (
        vehicleColorListSelected?.length === 0 &&
        tags?.vehicle?.extColors?.length > 0 &&
        !skipAPICallForTagCreation
      ) {
        vehicleColorListSelected = tags?.vehicle?.extColors;
      }
      selectedData.vehicle = true;
      let vehicleColorNameToPushAndCheck = item.slice(12).toLowerCase();
      if (removeTagFlag) {
        vehicleColorListSelected?.push(vehicleColorNameToPushAndCheck);
        if (vehicleColorListSelected?.length >= 0) {
          vehicle.extColors = vehicleColorListSelected;
        }
        tags.vehicle = vehicle;
        setSelectedEventType('Vehicle');
        setEventTypeList(eventTypeListWithVehicle);
      } else {
        let vehicleColorListSelectedFiltered = vehicleColorListSelected?.filter(
          (item) => item !== vehicleColorNameToPushAndCheck
        );
        setVehicleColorListSelected(vehicleColorListSelectedFiltered);
        if (vehicleColorListSelectedFiltered?.length >= 0) {
          vehicle.extColors = vehicleColorListSelectedFiltered;
          if (vehicle && vehicle.extColors && vehicle.extColors.length === 0) {
            delete vehicle.extColors;
          }
        }
        tags.vehicle = vehicle;
        if (Object.values(tags?.vehicle).length === 0) {
          selectedData = selectedDataAllFalse;
          setEventTypeList(eventTypeListAll);
          setAllCategoriesValuesAsFalse();
        }
      }

      // create array for pre selection of checkboxes for vehicle color category
      if (vehicleColorListSelected?.length > 0 && skipAPICallForTagCreation) {
        const selectedVehicleColorFromTags = vehicleColorListSelected?.map(
          (age) => {
            return age.charAt(0).toUpperCase() + age.slice(1);
          }
        );
        selectedVehicleColor = selectedVehicleColorFromTags;
      }
    }
    if (
      item === 'Car' ||
      item === 'Bicycle' ||
      item === 'Bus' ||
      item === 'Truck' ||
      item === 'Motorcycle'
    ) {
      // handle scenario to reassign vehicleTypeListSelected from generated tags of search
      if (
        vehicleTypeListSelected?.length === 0 &&
        tags?.vehicle?.types?.length > 0 &&
        !skipAPICallForTagCreation
      ) {
        vehicleTypeListSelected = tags?.vehicle?.types;
      }
      selectedData.vehicle = true;
      if (item === 'Car') {
        if (removeTagFlag) {
          selectedData.car = true;
          vehicleTypeListSelected?.push('car');
          if (vehicleTypeListSelected?.length > 0) {
            vehicle.types = vehicleTypeListSelected;
          }
          tags.vehicle = vehicle;
          setSelectedEventType('Vehicle');
          setEventTypeList(eventTypeListWithVehicle);
        } else {
          selectedData.car = false;
          let vehicleTypeListSelectedFiltered = vehicleTypeListSelected?.filter(
            (item) => item !== 'car'
          );
          setVehicleTypeListSelected(vehicleTypeListSelectedFiltered);
          if (vehicleTypeListSelectedFiltered?.length >= 0) {
            vehicle.types = vehicleTypeListSelectedFiltered;
            if (vehicle && vehicle.types && vehicle.types.length === 0) {
              delete vehicle.types;
            }
          }
          tags.vehicle = vehicle;
          if (Object.values(tags?.vehicle).length === 0) {
            selectedData = selectedDataAllFalse;
            setEventTypeList(eventTypeListAll);
            setAllCategoriesValuesAsFalse();
          }
        }
      }
      if (item === 'Bicycle') {
        if (removeTagFlag) {
          selectedData.bicycle = true;
          vehicleTypeListSelected?.push('bicycle');
          if (vehicleTypeListSelected?.length > 0) {
            vehicle.types = vehicleTypeListSelected;
          }
          tags.vehicle = vehicle;
          setSelectedEventType('Vehicle');
          setEventTypeList(eventTypeListWithVehicle);
        } else {
          selectedData.bicycle = false;
          let personVehicleTypeListSelectedFiltered =
            vehicleTypeListSelected?.filter((item) => item !== 'bicycle');
          setVehicleTypeListSelected(personVehicleTypeListSelectedFiltered);
          if (personVehicleTypeListSelectedFiltered?.length >= 0) {
            vehicle.types = personVehicleTypeListSelectedFiltered;
            if (vehicle && vehicle.types && vehicle.types.length === 0) {
              delete vehicle.types;
            }
          }
          tags.vehicle = vehicle;
          if (Object.values(tags?.vehicle).length === 0) {
            selectedData = selectedDataAllFalse;
            setEventTypeList(eventTypeListAll);
            setAllCategoriesValuesAsFalse();
          }
        }
      }
      if (item === 'Bus') {
        if (removeTagFlag) {
          selectedData.bus = true;
          vehicleTypeListSelected?.push('bus');
          if (vehicleTypeListSelected?.length > 0) {
            vehicle.types = vehicleTypeListSelected;
          }
          tags.vehicle = vehicle;
          setSelectedEventType('Vehicle');
          setEventTypeList(eventTypeListWithVehicle);
        } else {
          selectedData.bus = false;
          let personVehicleTypeListSelectedFiltered =
            vehicleTypeListSelected?.filter((item) => item !== 'bus');
          setVehicleTypeListSelected(personVehicleTypeListSelectedFiltered);
          if (personVehicleTypeListSelectedFiltered?.length >= 0) {
            vehicle.types = personVehicleTypeListSelectedFiltered;
            if (vehicle && vehicle.types && vehicle.types.length === 0) {
              delete vehicle.types;
            }
          }
          tags.vehicle = vehicle;
          if (Object.values(tags?.vehicle).length === 0) {
            selectedData = selectedDataAllFalse;
            setEventTypeList(eventTypeListAll);
            setAllCategoriesValuesAsFalse();
          }
        }
      }
      if (item === 'Truck') {
        if (removeTagFlag) {
          selectedData.truck = true;
          vehicleTypeListSelected?.push('truck');
          if (vehicleTypeListSelected?.length > 0) {
            vehicle.types = vehicleTypeListSelected;
          }
          tags.vehicle = vehicle;
          setSelectedEventType('Vehicle');
          setEventTypeList(eventTypeListWithVehicle);
        } else {
          selectedData.truck = false;
          let personVehicleTypeListSelectedFiltered =
            vehicleTypeListSelected?.filter((item) => item !== 'truck');
          setVehicleTypeListSelected(personVehicleTypeListSelectedFiltered);
          if (personVehicleTypeListSelectedFiltered?.length >= 0) {
            vehicle.types = personVehicleTypeListSelectedFiltered;
            if (vehicle && vehicle.types && vehicle.types.length === 0) {
              delete vehicle.types;
            }
          }
          tags.vehicle = vehicle;
          if (Object.values(tags?.vehicle).length === 0) {
            selectedData = selectedDataAllFalse;
            setEventTypeList(eventTypeListAll);
            setAllCategoriesValuesAsFalse();
          }
        }
      }
      if (item === 'Motorcycle') {
        if (removeTagFlag) {
          selectedData.motorcycle = true;
          vehicleTypeListSelected?.push('motorcycle');
          if (vehicleTypeListSelected?.length > 0) {
            vehicle.types = vehicleTypeListSelected;
          }
          tags.vehicle = vehicle;
          setSelectedEventType('Vehicle');
          setEventTypeList(eventTypeListWithVehicle);
        } else {
          selectedData.motorcycle = false;
          let personVehicleTypeListSelectedFiltered =
            vehicleTypeListSelected?.filter((item) => item !== 'motorcycle');
          setVehicleTypeListSelected(personVehicleTypeListSelectedFiltered);
          if (personVehicleTypeListSelectedFiltered?.length >= 0) {
            vehicle.types = personVehicleTypeListSelectedFiltered;
            if (vehicle && vehicle.types && vehicle.types.length === 0) {
              delete vehicle.types;
            }
          }
          tags.vehicle = vehicle;
          if (Object.values(tags?.vehicle).length === 0) {
            selectedData = selectedDataAllFalse;
            setEventTypeList(eventTypeListAll);
            setAllCategoriesValuesAsFalse();
          }
        }
      }
      // create array for pre selection of checkboxes for vehicle type category
      if (vehicleTypeListSelected?.length > 0 && skipAPICallForTagCreation) {
        const selectedVehicleTypeFromTags = vehicleTypeListSelected?.map(
          (age) => {
            return age.charAt(0).toUpperCase() + age.slice(1);
          }
        );
        selectedVehicleType = selectedVehicleTypeFromTags;
      }
    }
    if (
      item === 'Glass Break' ||
      item === 'Scream' ||
      item === 'Gunshot' ||
      item === 'Explosion'
    ) {
      // handle scenario to reassign soundTypeListSelected from generated tags of search
      if (
        soundTypeListSelected?.length === 0 &&
        tags?.sound?.types?.length > 0 &&
        !skipAPICallForTagCreation
      ) {
        soundTypeListSelected = tags?.sound?.types;
      }
      selectedData.sound = true;
      if (item === 'Glass Break') {
        if (removeTagFlag) {
          selectedData.glassBreak = true;
          soundTypeListSelected?.push('glass-breaking');
          if (soundTypeListSelected?.length > 0) {
            sound.types = soundTypeListSelected;
          }
          tags.sound = sound;
          setSelectedEventType('Audio');
          setEventTypeList(eventTypeListWithAudio);
        } else {
          selectedData.glassBreak = false;
          let soundTypeListSelectedFiltered = soundTypeListSelected?.filter(
            (item) => item !== 'glass-breaking'
          );
          setSoundTypeListSelected(soundTypeListSelectedFiltered);
          if (soundTypeListSelectedFiltered?.length >= 0) {
            sound.types = soundTypeListSelectedFiltered;
            if (sound && sound.types && sound.types.length === 0) {
              delete sound.types;
            }
          }
          tags.sound = sound;
          if (Object.values(tags?.sound).length === 0) {
            selectedData = selectedDataAllFalse;
            setEventTypeList(eventTypeListAll);
            setAllCategoriesValuesAsFalse();
          }
        }
      }
      if (item === 'Scream') {
        if (removeTagFlag) {
          selectedData.scream = true;
          soundTypeListSelected?.push('scream');
          if (soundTypeListSelected?.length > 0) {
            sound.types = soundTypeListSelected;
          }
          tags.sound = sound;
          setSelectedEventType('Audio');
          setEventTypeList(eventTypeListWithAudio);
        } else {
          selectedData.scream = false;
          let soundTypeListSelectedFiltered = soundTypeListSelected?.filter(
            (item) => item !== 'scream'
          );
          setSoundTypeListSelected(soundTypeListSelectedFiltered);
          if (soundTypeListSelectedFiltered?.length >= 0) {
            sound.types = soundTypeListSelectedFiltered;
            if (sound && sound.types && sound.types.length === 0) {
              delete sound.types;
            }
          }
          tags.sound = sound;
          if (Object.values(tags?.sound).length === 0) {
            selectedData = selectedDataAllFalse;
            setEventTypeList(eventTypeListAll);
            setAllCategoriesValuesAsFalse();
          }
        }
      }
      if (item === 'Gunshot') {
        if (removeTagFlag) {
          selectedData.gunshot = true;
          soundTypeListSelected?.push('gun-shot');
          if (soundTypeListSelected?.length > 0) {
            sound.types = soundTypeListSelected;
          }
          tags.sound = sound;
          setSelectedEventType('Audio');
          setEventTypeList(eventTypeListWithAudio);
        } else {
          selectedData.gunshot = false;
          let soundTypeListSelectedFiltered = soundTypeListSelected?.filter(
            (item) => item !== 'gun-shot'
          );
          setSoundTypeListSelected(soundTypeListSelectedFiltered);
          if (soundTypeListSelectedFiltered?.length >= 0) {
            sound.types = soundTypeListSelectedFiltered;
            if (sound && sound.types && sound.types.length === 0) {
              delete sound.types;
            }
          }
          tags.sound = sound;
          if (Object.values(tags?.sound).length === 0) {
            selectedData = selectedDataAllFalse;
            setEventTypeList(eventTypeListAll);
            setAllCategoriesValuesAsFalse();
          }
        }
      }
      if (item === 'Explosion') {
        if (removeTagFlag) {
          selectedData.explosion = true;
          soundTypeListSelected?.push('explosion');
          if (soundTypeListSelected?.length > 0) {
            sound.types = soundTypeListSelected;
          }
          tags.sound = sound;
          setSelectedEventType('Audio');
          setEventTypeList(eventTypeListWithAudio);
        } else {
          selectedData.explosion = false;
          let soundTypeListSelectedFiltered = soundTypeListSelected?.filter(
            (item) => item !== 'explosion'
          );
          setSoundTypeListSelected(soundTypeListSelectedFiltered);
          if (soundTypeListSelectedFiltered?.length >= 0) {
            sound.types = soundTypeListSelectedFiltered;
            if (sound && sound.types && sound.types.length === 0) {
              delete sound.types;
            }
          }
          tags.sound = sound;
          if (Object.values(tags?.sound).length === 0) {
            selectedData = selectedDataAllFalse;
            setEventTypeList(eventTypeListAll);
            setAllCategoriesValuesAsFalse();
          }
        }
      }
      // create array for pre selection of checkboxes for sound type category
      if (soundTypeListSelected?.length > 0 && skipAPICallForTagCreation) {
        const selectedSoundTypeFromTags = soundTypeListSelected?.map((age) => {
          return age.charAt(0).toUpperCase() + age.slice(1);
        });
        soundTypeListSelected = selectedSoundTypeFromTags;
      }
    }
    // secondary attributes section ends

    const allDevicesListToPass = [];
    allDevciesData?.forEach((location) => {
      allDevicesListToPass.push(location?.deviceId);
    });

    // tags related data starts
    let tagsParent = {
      deviceIds:
        selectedLocations?.length > 0 ||
        selectedAreas?.length > 0 ||
        selectedDevices?.length > 0
          ? devicesListToPass
          : allDevicesListToPass,
    };
    if (Object.keys(tags).length !== 0) {
      tagsParent.tags = tags;
    } else if (eventsTagData?.tags) {
      tagsParent.tags = eventsTagData?.tags;
    }
    setTagsData(tagsParent);
    setSelectedTags(selectedData);
    const keys = Object.keys(tagsParent);
    eventsTagData = tagsParent;
    setEventsTagData(tagsParent);
    // tags related data ends

    if (!skipAPICallForTagCreation) {
      const startTimeArg =
        endTimeToPass[0] && endTimeToPass[0] && endTimeToPass[0]
          ? new Date(
              moment.tz(new Date(selectedDateTOShow), timezone).set({
                hour: endTimeToPass[0],
                minute: endTimeToPass[1],
                second: endTimeToPass[2],
                millisecond: 0,
              })
            ).getTime()
          : 0;
      if (
        tagsParent &&
        tagsParent?.tags &&
        tagsParent?.tags?.person &&
        tagsParent?.tags?.person.pantsColors &&
        tagsParent?.tags?.person?.pantsColors?.length > 2
      ) {
        return;
      } else if (
        tagsParent &&
        tagsParent?.tags &&
        tagsParent?.tags?.person &&
        tagsParent?.tags?.person.shirtColors &&
        tagsParent?.tags?.person?.shirtColors?.length > 2
      ) {
        return;
      } else if (
        tagsParent &&
        tagsParent?.tags &&
        tagsParent?.tags?.vehicle &&
        tagsParent?.tags?.vehicle.vehicleColor &&
        tagsParent?.tags?.vehicle?.vehicleColor?.length > 2
      ) {
        return;
      } else if (keys?.length > 0 && keys?.includes('tags') === false) {
        let calculateTimeStampsValues = calculateTimeStamps(
          selectedDateTOShow,
          startTimeToPass,
          endTimeToPass
        );
        let startTimeArg = calculateTimeStampsValues[0];
        let endTimeArg = calculateTimeStampsValues[1];

        let allDevicesListToPassInSingleLine =
          getDeviceIdForEvents(allDevicesListToPass);
        let devicesListInSingleLine = getDeviceIdForEvents(devicesListToPass);

        if (
          selectedLocations?.length > 0 ||
          selectedAreas?.length > 0 ||
          selectedDevices?.length > 0
        ) {
          let startTime = 0;
          if (devicesListToPass?.length > 0) {
            setShowMoreFooterFlag(true);
            setIsLoadingEvents(true);
            const response = await axios.get(
              `timeline/orgs/${
                custOrgData?.orgId
              }/events/all?${`startTime=${startTimeArg}&`}endTime=${endTimeArg}&size=${requestedEventSize}${devicesListInSingleLine}`,
              Utils.requestHeader()
            );
            const responseData = response?.data;
            if (responseData?.meta?.code === 200) {
              if (responseData?.data?.events?.length > 0) {
                setEventsData(responseData?.data?.events);
                setEventTotal(responseData?.data?.total);
                setIsLoadingEvents(false);
              } else if (responseData?.data?.events?.length === 0) {
                setEventsData([]);
                setEventTotal(responseData?.data?.total);
                setShowMoreFooterFlag(false);
                setIsLoadingEvents(false);
              }
            } else if(responseData?.meta?.code === 401){
              setIsLoadingEvents(false);              
              redirectToLoginPage();
            } else {
              setIsLoadingEventsShowMore(false);
              setShowMoreFooterFlag(false);
              setIsLoadingEvents(false);
            }
          } else {
            setShowMoreFooterFlag(false);
            setIsLoadingEvents(true);
            setEventsData([]);
            setEventTotal(0);
            setIsLoadingEvents(false);
          }
        } else {
          setIsLoadingEvents(true);
          const response = await axios.get(
            `timeline/orgs/${
              custOrgData?.orgId
            }/events/all?${`startTime=${startTimeArg}&`}endTime=${endTimeArg}&size=${requestedEventSize}${allDevicesListToPassInSingleLine}`,
            Utils.requestHeader()
          );
          const responseData = response?.data;
          if (responseData?.meta?.code === 200) {
            setIsLoadingEventsShowMore(false);
            if (responseData?.data?.events?.length > 0) {
              setEventsData(responseData?.data?.events);
              setEventTotal(responseData?.data?.total);
              setIsLoadingEvents(false);
            } else if (responseData?.data?.events?.length === 0) {
              setEventsData([]);
              setEventTotal(responseData?.data?.total);
              setShowMoreFooterFlag(false);
              setIsLoadingEvents(false);
            }
          } else if(responseData?.meta?.code === 401){
            setIsLoadingEvents(false);          
            redirectToLoginPage();
          } else {
            setIsLoadingEventsShowMore(false);
            setIsLoadingEvents(false);
          }
        }
      } else {
        fillterByTagApiCall(startTimeArg, tagsParent);
      }
    }
  };

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1200);
  const [smallScreenValue, setSmallScreenValueValue] = useState(
    window.innerWidth
  );

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1200);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setSmallScreenValueValue(window.innerWidth);
  }, [smallScreenValue]);

  const [open, setOpen] = useState(true);
  const [isOpenBeforeDragOver, setIsOpenBeforeDragOver] = useState(true);

  const drawerWidth = 224;

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }));

  const handleClickOnLeftPanelToggle = () => {
    setOpen(!open);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array to run effect only once

  const isSmallScreenTrue = windowWidth >= 992 && windowWidth <= 1200;
  const isMediumScreen = windowWidth > 1200 && windowWidth <= 1500;
  const isBigScreen = windowWidth > 1500;

  const lgValue = open === true ? '10' : isBigScreen ? '11' : '12';
  const xlValue = open === true ? '10' : isBigScreen ? '11' : '12';
  let className;
  if (isSmallScreenTrue && open === true) {
    if (windowWidth === 1200) {
      className = 'right-panel-margin-aligned';
    } else {
      className = 'right-panel-margin';
    }
  } else if (isBigScreen) {
    if (open === false) {
      className = 'right-panel-margin-big-screen';
    }
  } else if (isMediumScreen) {
    if (open === false) {
      className = 'right-panel-margin-medium-screen';
    }
  } else {
    className = '';
  }
  if (isSmallScreenTrue && open === false) {
    if (windowWidth > 1080) {
      className = 'right-panel-margin-small-screen';
    } else {
      className = 'right-panel-margin-small-shift-screen';
    }
  }
  if (windowWidth > 766 && windowWidth < 817 && open === false) {
    className = 'right-panel-margin-small-shift-top-screen';
  }
  if (windowWidth < 440 && open === false) {
    className = 'right-panel-margin-extra-small-section-screen';
  }
  if (windowWidth > 766 && windowWidth < 817 && open === true) {
    className = 'right-panel-margin-small-top-section-screen';
  }

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const allSetMethodsAfterRedirection = () => {
    setSelectedLocations(getPreSelectedLocation());
    setSelectedAreas(getPreSelectedArea());
    setSelectedDevices(getPreSelectedDevice());
    const selectedDeviceListToPassData = getPreSelectedDeviceListToPass()
    devicesListToPass = selectedDeviceListToPassData;
    setDevicesListToPass(selectedDeviceListToPassData);  
    const selectedData = getSelectedData()
    setAllLocationsData(getPreSelectedAllLocationsData());
    setAllAreasData(getPreSelectedAllAreasData());
    setAllDevciesData(getPreSelectedAllDevicesData()) ;
    setIsLocationListVisible(getPreSelectedIsLocationListVisible());
    setIsAreaListVisible(getPreSelectedIsAreaListVisible());
    setIsDeviceListVisible(getPreSelectedIsDeviceListVisible());
    filteredLocations = getPreSelectedFilteredLocations();
    filteredAreas = getPreSelectedFilteredAreas();
    filteredDevices = getPreSelectedFilteredDevices();
    setVisibleItemCountForLocation(getPreSelectedVisibleItemCountForLocation());
    setVisibleItemCountForArea(getPreSelectedVisibleItemCountForArea());
    setVisibleItemCountForDevice(getPreSelectedVisibleItemCountForDevice());
    setSelectedLocations(getPreSelectedLocations());
    setSelectedAreas(getPreSelectedAreas());
    setSelectedDevices(getPreSelectedDevices());
    setEventsData(getPreSelectedEventsData());
    setPreSelectedEventTotal(eventCount);
    preSelectionAfterCameraDetail(selectedData);
    //test date and time pre selection starts
    setSelectedDateTOShow(getPreSelectedDateToShow());
    lastSelectedDate = getPreSelectedLastSelectedDate();
    setLastSelectedDateSubmit(getPreSelectedLastSelectedDateSubmit());
    setLastSelectedTimeSubmit(getPreSelectedLastSelectedTimeSubmit());
    setFilterDate(getPreSelectedFilterDate());
    setSelectedTime(getPreSelectedSelectedTime());
    setStartTimeOfEvent(getPreSelectedStartTimeOfEvent());
    setEndTimeOfEvent(getPreSelectedEndTimeOfEvent());
    setStartTimePeriod(getPreSelectedStartTimePeriod());
    setEndTimePeriod(getPreSelectedEndTimePeriod());
    setstartTimeToPass(getPreSelectedStartTimeToPass());
    setEndTimeToPass(getPreSelectedEndTimeToPass());
    //test date and time pre selection ends
  }

  const allSetMethodsBeforeRedirection = () => {
    setPreSelectedLocation(selectedLocations);
    setPreSelectedArea(selectedAreas);
    setPreSelectedDevice(selectedDevices);
    setPreSelectedDeviceListToPass(devicesListToPass);
    setPreSelectedAllLocationsData(allLocationsData);
    setPreSelectedAllAreasData(allAreasData);
    setPreSelectedAllDevicesData(allDevciesData);
    setPreSelectedIsLocationListVisible(isLocationListVisible);
    setPreSelectedIsAreaListVisible(isAreaListVisible);
    setPreSelectedIsDeviceListVisible(isDeviceListVisible);
    setPreSelectedFilteredLocations(filteredLocations);
    setPreSelectedFilteredAreas(filteredAreas);
    setPreSelectedFilteredDevices(filteredDevices);
    setPreSelectedVisibleItemCountForLocation(visibleItemCountForLocation);
    setPreSelectedVisibleItemCountForArea(visibleItemCountForArea);
    setPreSelectedVisibleItemCountForDevice(visibleItemCountForDevice);
    setPreSelectedLocations(selectedLocations);
    setPreSelectedAreas(selectedAreas);
    setPreSelectedDevices(selectedDevices);
    setPreSelectedEventsData(eventsData);
    //test date and time pre selection starts
    setPreSelectedDateToShow(selectedDateTOShow);
    setPreSelectedLastSelectedDate(lastSelectedDate);
    setPreSelectedLastSelectedDateSubmit(lastSelectedDateSubmit);
    setPreSelectedLastSelectedTimeSubmit(lastSelectedTimeSubmit);
    setPreSelectedFilterDate(filterDate);
    setPreSelectedSelectedTime(selectedTime);
    setPreSelectedStartTimeOfEvent(startTimeOfEvent);
    setPreSelectedEndTimeOfEvent(endTimeOfEvent);
    setPreSelectedStartTimePeriod(startTimePeriod);
    setPreSelectedEndTimePeriod(endTimePeriod);
    setPreSelectedStartTimeToPass(startTimeToPass);
    setPreSelectedEndTimeToPass(endTimeToPass);
    //test date and time pre selection ends

    if (selectedGender?.length > 0) {
      let selectedTags = getTagsData()?.tags;
      const genders = [];
    
      if (selectedGender.includes('Male')) {
        genders.push('male');
      }
      if (selectedGender.includes('Female')) {
        genders.push('female');
      }
    
      if (selectedTags && selectedTags.face) {
        selectedTags.face.genders = genders;
        setSelectedData(selectedTags);
      } else if (selectedTags && selectedTags.person) {
        selectedTags.person.genders = genders;
        setSelectedData(selectedTags);
      } else {
        setSelectedData(getTagsData()?.tags);
      }
    } else {
      setSelectedData(getTagsData()?.tags);
    }  
  }

  return (
    <div className="App events">
      <Header />
      <div className="container-fluid main-wrapper-events">
        <Row>
          {!isSmallScreen &&
            open &&
            !showEventPreviewModal &&
            !showSearchModal && (
              <Col lg="2" xl="2" className="left-panel">
                <div className="outer-border-left-panel">
                  <Row>
                    <div className="filter-label">
                      <div className="filter-label-container">
                        <div className="filter-label-left">
                          <FilterIconTitle />
                          <div className="filter-label-style">
                            {constants.EVENT_FILTER_LABEL}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <Row>
                    <Col className="event-scroll-section" lg="2" xl="2">
                      {(allLocationsData?.length < 2 ||
                        allAreasData?.length < 2 ||
                        allDevciesData?.length < 2) && (
                        <div className="empty-section-style"></div>
                      )}

                      {/* Locations area starts */}
                      {allLocationsData?.length > 0 && (
                        <Row>
                          <Col lg="12" xl="12">
                            {!(
                              allLocationsData?.length < 2 ||
                              allAreasData?.length < 2 ||
                              allDevciesData?.length < 2
                            ) && <Row className="empty-section-style"></Row>}
                            <Col
                              className="filter-title-container-events"
                              lg="12"
                              xl="12"
                            >
                              <Row className="full-width-section-style">
                                <Col lg="10" xl="10">
                                  <div className="event-category-style">
                                    {constants.EVENT_FILTER_LOCATION_LABEL}
                                  </div>
                                </Col>
                                <Col lg="2" xl="2">
                                  <div
                                    className="toggle-device-list"
                                    onClick={toggleLocationListVisibility}
                                  >
                                    {isLocationListVisible ? (
                                      <ChevronUp className="dark-theme-icon" />
                                    ) : (
                                      <ChevronDown className="dark-theme-icon" />
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            {/* show search location if there are more than 10 list items */}
                            {allLocationsData &&
                              allLocationsData?.length > 0 &&
                              Array.isArray(allLocationsData) &&
                              sortList(
                                allLocationsData?.map(
                                  (location) => location.locationName
                                )
                              )?.length > 10 &&
                              isLocationListVisible && (
                                <Col
                                  className="search-box-style"
                                  lg="1"
                                  xl="12"
                                >
                                  <Row className="full-width-section-style">
                                    <Col lg="10" xl="10" className='search-icon-parent-area'>
                                      <input
                                        type="text"
                                        className="location-search-style"
                                        placeholder={
                                          constants.EVENT_FILTER_SEARCH_LOCATION
                                        }
                                        value={searchTermForLocation}
                                        onChange={(e) =>
                                          setSearchTermForLocation(
                                            e.target.value
                                          )
                                        }
                                      />
                                      <span>
                                        <RxMagnifyingGlass className="search-icon-license" />
                                      </span>
                                    </Col>
                                  </Row>
                                </Col>
                              )}
                            {isLocationListVisible && (
                              <Col
                                className="filter-title-container-without-height"
                                lg="12"
                                xl="12"
                              >
                                <Row className="full-width-section-style">
                                  <ul className="custom-list">
                                    {allLocationsData &&
                                      allLocationsData?.length > 0 &&
                                      filteredLocations
                                        ?.slice(0, visibleItemCountForLocation)
                                        ?.map((item, index) => (
                                          <li
                                            className="custom-list-value-other"
                                            key={index}
                                            onClick={() =>
                                              handleLocationSelection(item)
                                            }
                                          >
                                            <div className="align-items-checkbox-label">
                                              <span>
                                                <input
                                                  type="checkbox"
                                                  value={item}
                                                  className="checkbox-style"
                                                  checked={selectedLocations?.includes(
                                                    item
                                                  )}
                                                />
                                              </span>
                                              <div
                                                className="checkbox-style-label"
                                                style={{ cursor: 'default' }}
                                                onMouseEnter={handleMouseEnter}
                                                onMouseLeave={handleMouseLeave}
                                              >
                                                {item?.trim().length > 18 ? (
                                                  <span title={item}>
                                                    {/* {item?.trim().slice(0, 22)}
                                                    {'...'} */}
                                                    {item}
                                                  </span>
                                                ) : (
                                                  <span>{item}</span>
                                                )}
                                              </div>
                                            </div>
                                          </li>
                                        ))}
                                    {allLocationsData &&
                                      allLocationsData?.length > 0 &&
                                      Array.isArray(allLocationsData) &&
                                      sortList(
                                        allLocationsData?.map(
                                          (location) => location.locationName
                                        )
                                      )?.length >
                                        visibleItemCountForLocation && (
                                        <li className="custom-list-value-other-show-more">
                                          <div className="align-items-checkbox-label">
                                            <div
                                              className="show-more-icon-style"
                                              onClick={
                                                handleShowMoreForLocation
                                              }
                                            >
                                              <ChevronDownShowMore />
                                            </div>
                                            <span
                                              className="show-more-label"
                                              onClick={
                                                handleShowMoreForLocation
                                              }
                                            >
                                              {constants.EVENT_FILTER_SHOW_MORE}
                                            </span>
                                          </div>
                                        </li>
                                      )}
                                  </ul>
                                </Row>
                              </Col>
                            )}
                            <Col
                              className="customContainer"
                              lg="12"
                              xl="12"
                            ></Col>
                          </Col>
                        </Row>
                      )}
                      {/* Locations area ends */}

                      {/* Areas sections starts */}
                      {allAreasData?.length > 0 &&
                        sortList(allAreasData)?.length > 0 && (
                          <Row>
                            <Col lg="12" xl="12">
                              <Col
                                className="filter-title-container-events"
                                lg="12"
                                xl="12"
                              >
                                <Row className="full-width-section-style">
                                  <Col lg="10" xl="10">
                                    <div className="event-category-style">
                                      {constants.EVENT_FILTER_AREAS_LABEL}
                                    </div>
                                  </Col>
                                  <Col lg="2" xl="2">
                                    <div
                                      className="toggle-device-list"
                                      onClick={toggleAreaListVisibility}
                                    >
                                      {isAreaListVisible ? (
                                        <ChevronUp className="dark-theme-icon" />
                                      ) : (
                                        <ChevronDown className="dark-theme-icon" />
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                              {/* show search area if there are more than 10 list items */}
                              {allAreasData &&
                                allAreasData?.length > 0 &&
                                Array.isArray(allAreasData) &&
                                sortList(
                                  allAreasData?.map((area) => area.areaName)
                                )?.length > 10 &&
                                isAreaListVisible && (
                                  <Col
                                    className="search-box-style"
                                    lg="1"
                                    xl="12"
                                  >
                                    <Row className="full-width-section-style">
                                      <Col lg="10" xl="10" className='search-icon-parent-area'>
                                        <input
                                          type="text"
                                          className="location-search-style"
                                          placeholder={
                                            constants.EVENT_FILTER_SEARCH_AREAS
                                          }
                                          value={searchTermForArea}
                                          onChange={(e) =>
                                            setSearchTermForArea(e.target.value)
                                          }
                                        />
                                        <span>
                                          <RxMagnifyingGlass className="search-icon-license" />
                                        </span>
                                      </Col>
                                    </Row>
                                  </Col>
                                )}
                              {isAreaListVisible && (
                                <Col
                                  className="filter-title-container-without-height"
                                  lg="1"
                                  xl="12"
                                >
                                  <Row className="full-width-section-style">
                                    <ul className="custom-list">
                                      {allAreasData &&
                                        allAreasData?.length > 0 &&
                                        Array.isArray(allAreasData) &&
                                        sortList(filteredAreas)
                                          ?.slice(0, visibleItemCountForArea)
                                          ?.map((item, index) => (
                                            <li
                                              className="custom-list-value-other"
                                              key={index}
                                              onClick={() =>
                                                handleAreaSelection(item)
                                              }
                                            >
                                              <div className="align-items-checkbox-label">
                                                <span>
                                                  <input
                                                    type="checkbox"
                                                    value={item}
                                                    className="checkbox-style"
                                                    checked={selectedAreas?.includes(
                                                      item
                                                    )}
                                                  />
                                                </span>
                                                <span
                                                  className="checkbox-style-label"
                                                  style={{ cursor: 'default' }}
                                                  onMouseEnter={
                                                    handleMouseEnter
                                                  }
                                                  onMouseLeave={
                                                    handleMouseLeave
                                                  }
                                                >
                                                  {item?.trim().length > 18 ? (
                                                    <span title={item}>
                                                      {item
                                                        ?.trim()
                                                        .slice(0, 15)}
                                                      {'...'}
                                                    </span>
                                                  ) : (
                                                    <span>{item}</span>
                                                  )}
                                                </span>
                                              </div>
                                            </li>
                                          ))}
                                      {allAreasData &&
                                        allAreasData?.length > 0 &&
                                        Array.isArray(allAreasData) &&
                                        sortList(
                                          allAreasData?.map(
                                            (area) => area.areaName
                                          )
                                        )?.length > visibleItemCountForArea && (
                                          <li className="custom-list-value-other-show-more">
                                            <div className="align-items-checkbox-label">
                                              <span
                                                className="show-more-icon-style"
                                                onClick={handleShowMoreForArea}
                                              >
                                                <ChevronDownShowMore />
                                              </span>
                                              <span
                                                className="show-more-label"
                                                onClick={handleShowMoreForArea}
                                              >
                                                {
                                                  constants.EVENT_FILTER_SHOW_MORE
                                                }
                                              </span>
                                            </div>
                                          </li>
                                        )}
                                    </ul>
                                  </Row>
                                </Col>
                              )}

                              <Col
                                className="customContainer"
                                lg="12"
                                xl="12"
                              ></Col>
                            </Col>
                          </Row>
                        )}
                      {/* Areas sections ends */}

                      {/* Devices sections starts */}
                      {allDevciesData?.length > 0 &&
                        sortList(filteredDevices)?.length > 0 && (
                          <Row>
                            <Col lg="12" xl="12">
                              <Col
                                className="filter-title-container-events"
                                lg="12"
                                xl="12"
                              >
                                <Row className="full-width-section-style">
                                  <Col lg="10" xl="10">
                                    <div className="event-category-style">
                                      {constants.EVENT_FILTER_DEVICES_LABEL}
                                    </div>
                                  </Col>
                                  <Col lg="2" xl="2">
                                    <div
                                      className="toggle-device-list"
                                      onClick={toggleDeviceListVisibility}
                                    >
                                      {isDeviceListVisible ? (
                                        <ChevronUp className="dark-theme-icon" />
                                      ) : (
                                        <ChevronDown className="dark-theme-icon" />
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                              {/* show search location if there are more than 10 list items */}
                              {allDevciesData &&
                                allDevciesData?.length > 0 &&
                                Array.isArray(allDevciesData) &&
                                sortList(
                                  allDevciesData?.map(
                                    (device) => device.deviceName
                                  )
                                )?.length > 10 &&
                                isDeviceListVisible && (
                                  <Col
                                    className="search-box-style"
                                    lg="1"
                                    xl="12"
                                  >
                                    <Row className="full-width-section-style">
                                      <Col lg="10" xl="10" className='search-icon-parent-area'>
                                        <input
                                          type="text"
                                          className="location-search-style"
                                          placeholder={
                                            constants.EVENT_FILTER_SEARCH_DEVICES
                                          }
                                          value={searchTermForDevice}
                                          onChange={(e) =>
                                            setSearchTermForDevice(e.target.value)
                                          }
                                        />
                                         <span>
                                          <RxMagnifyingGlass className="search-icon-license" />
                                        </span>
                                      </Col>
                                    </Row>
                                  </Col>
                                )}
                              {isDeviceListVisible && (
                                <Col
                                  className="filter-title-container-without-height"
                                  lg="1"
                                  xl="12"
                                >
                                  <Row className="full-width-section-style">
                                    <ul className="custom-list">
                                      {allDevciesData &&
                                        allDevciesData?.length > 0 &&
                                        Array.isArray(allDevciesData) &&
                                        sortList(filteredDevices)
                                          ?.slice(0, visibleItemCountForDevice)
                                          ?.map((item, index) => (
                                            <li
                                              className="custom-list-value-other"
                                              key={index}
                                              onClick={() =>
                                                handleDevicesSelection(item)
                                              }
                                            >
                                              <div className="align-items-checkbox-label">
                                                <span>
                                                  <input
                                                    type="checkbox"
                                                    value={item}
                                                    className="checkbox-style"
                                                    checked={selectedDevices?.includes(
                                                      item
                                                    )}
                                                  />
                                                </span>
                                                <div
                                                  className="checkbox-style-label"
                                                  style={{ cursor: 'default' }}
                                                  onMouseEnter={
                                                    handleMouseEnter
                                                  }
                                                  onMouseLeave={
                                                    handleMouseLeave
                                                  }
                                                >
                                                  {item?.trim().length > 18 ? (
                                                    <span title={item}>
                                                      {item
                                                        ?.trim()
                                                        .slice(0, 15)}
                                                      {'...'}
                                                    </span>
                                                  ) : (
                                                    <span>{item}</span>
                                                  )}
                                                </div>
                                              </div>
                                            </li>
                                          ))}
                                      {allDevciesData &&
                                        allDevciesData?.length > 0 &&
                                        Array.isArray(allDevciesData) &&
                                        sortList(
                                          allDevciesData?.map(
                                            (device) => device.deviceName
                                          )
                                        )?.length >
                                          visibleItemCountForDevice && (
                                          <li className="custom-list-value-other-show-more">
                                            <div className="align-items-checkbox-label">
                                              <span
                                                className="show-more-icon-style"
                                                onClick={
                                                  handleShowMoreForDevice
                                                }
                                              >
                                                <ChevronDownShowMore />
                                              </span>
                                              <span
                                                className="show-more-label"
                                                onClick={
                                                  handleShowMoreForDevice
                                                }
                                              >
                                                {
                                                  constants.EVENT_FILTER_SHOW_MORE
                                                }
                                              </span>
                                            </div>
                                          </li>
                                        )}
                                    </ul>
                                  </Row>
                                </Col>
                              )}

                              <Col
                                className="customContainer"
                                lg="12"
                                xl="12"
                              ></Col>
                            </Col>
                          </Row>
                        )}
                      {/* Devices sections ends */}

                      {/* Event Type Section Starts */}
                      <Row>
                        <Col lg="12" xl="12">
                          <Col
                            className="filter-title-container-event-title"
                            lg="12"
                            xl="12"
                          >
                            <div className="event-category-style">
                              {constants.EVENT_FILTER_EVENT_TYPE_LABEL}
                            </div>
                          </Col>
                          <Col
                            className="filter-title-container-without-height"
                            lg="1"
                            xl="12"
                          >
                            <ul className="custom-list event-type-list">
                              {eventTypeList?.map((item, index) => (
                                <li
                                  className={`custom-list-value ${
                                    selectedEventType === item
                                      ? 'selected-event-type-style'
                                      : ''
                                  }`}
                                  key={index}
                                  onClick={() => handleEventTypeSelection(item)}
                                >
                                  {item}
                                </li>
                              ))}
                            </ul>
                          </Col>
                          <Col
                            className="customContainer"
                            lg="12"
                            xl="12"
                          ></Col>
                        </Col>
                      </Row>
                      {/* Event Type Section ends */}

                      {/* Age sections starts */}
                      {(selectedEventType === 'Face' ||
                        selectedEventType === null) && (
                        <Row>
                          <Col lg="12" xl="12">
                            <Col
                              className="filter-title-container-events"
                              lg="12"
                              xl="12"
                            >
                              <Row className="full-width-section-style">
                                <Col lg="10" xl="10">
                                  <div className="event-category-style">
                                    {constants.EVENT_FILTER_AGE_LABEL}
                                  </div>
                                </Col>
                                <Col lg="2" xl="2">
                                  <div
                                    className="toggle-device-list"
                                    onClick={toggleAgeListVisibility}
                                  >
                                    {isAgeListVisible ? (
                                      <ChevronUp className="dark-theme-icon" />
                                    ) : (
                                      <ChevronDown className="dark-theme-icon" />
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            {isAgeListVisible && (
                              <Col
                                className="filter-title-container-without-height"
                                lg="1"
                                xl="12"
                              >
                                <Row className="full-width-section-style">
                                  <ul className="custom-list">
                                    {ageList?.map((item, index) => (
                                      <li
                                        className="custom-list-value-other"
                                        key={index}
                                        onClick={() => handleAgeSelection(item)}
                                      >
                                        <div className="align-items-checkbox-label">
                                          <span>
                                            <input
                                              type="checkbox"
                                              value={item}
                                              className="checkbox-style"
                                              checked={selectedAge?.includes(
                                                item
                                              )}
                                            />
                                          </span>
                                          <div className="checkbox-style-label">
                                            {item}
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </Row>
                              </Col>
                            )}

                            <Col
                              className="customContainer"
                              lg="12"
                              xl="12"
                            ></Col>
                          </Col>
                        </Row>
                      )}
                      {/* Age sections ends */}

                      {/* Face Accessories sections starts */}
                      {(selectedEventType === 'Face' ||
                        selectedEventType === null) && (
                        <Row>
                          <Col lg="12" xl="12">
                            <Col
                              className="filter-title-container-events"
                              lg="12"
                              xl="12"
                            >
                              <Row className="full-width-section-style">
                                <Col lg="10" xl="10">
                                  <p className="event-category-style">
                                    {constants.EVENT_FILTER_FACE_ACC_LABEL}
                                  </p>
                                </Col>
                                <Col lg="2" xl="2">
                                  <div
                                    className="toggle-device-list"
                                    onClick={toggleFaceAccListVisibility}
                                  >
                                    {isFaceAccListVisible ? (
                                      <ChevronUp className="dark-theme-icon" />
                                    ) : (
                                      <ChevronDown className="dark-theme-icon" />
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            {isFaceAccListVisible && (
                              <Col
                                className="filter-title-container-without-height"
                                lg="1"
                                xl="12"
                              >
                                <Row className="full-width-section-style">
                                  <ul className="custom-list">
                                    {faceAccList?.map((item, index) => (
                                      <li
                                        className="custom-list-value-other"
                                        key={index}
                                        onClick={() =>
                                          handleFaceAccSelection(item)
                                        }
                                      >
                                        <div className="align-items-checkbox-label">
                                          <span>
                                          <input
                                              type="checkbox"
                                              value={item}
                                              className="checkbox-style"
                                              checked={selectedFaceAcc?.includes(
                                                item
                                              ) || (item === "Glasses" && selectedFaceAcc?.includes("Opticals"))}
                                            />
                                          </span>
                                          <div className="checkbox-style-label">
                                            {item}
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </Row>
                              </Col>
                            )}

                            <Col
                              className="customContainer"
                              lg="12"
                              xl="12"
                            ></Col>
                          </Col>
                        </Row>
                      )}
                      {/* Face Accessories sections ends */}

                      {/* Gender sections starts */}
                      {(selectedEventType === 'Face' ||
                        selectedEventType === 'Person' ||
                        selectedEventType === null) && (
                        <Row>
                          <Col lg="12" xl="12">
                            <Col
                              className="filter-title-container-events"
                              lg="12"
                              xl="12"
                            >
                              <Row className="full-width-section-style">
                                <Col lg="10" xl="10">
                                  <p className="event-category-style">
                                    {constants.EVENT_FILTER_GENDER_LABEL}
                                  </p>
                                </Col>
                                <Col lg="2" xl="2">
                                  <div
                                    className="toggle-device-list"
                                    onClick={toggleGenderListVisibility}
                                  >
                                    {isGenderListVisible ? (
                                      <ChevronUp className="dark-theme-icon" />
                                    ) : (
                                      <ChevronDown className="dark-theme-icon" />
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            {isGenderListVisible && (
                              <Col
                                className="filter-title-container-without-height"
                                lg="1"
                                xl="12"
                              >
                                <Row className="full-width-section-style">
                                  <ul className="custom-list">
                                    {genderList?.map((item, index) => (
                                      <li
                                        className="custom-list-value-other"
                                        key={index}
                                        onClick={() =>
                                          handleGenderSelection(item)
                                        }
                                      >
                                        <div className="align-items-checkbox-label">
                                          <span>
                                            <input
                                              type="checkbox"
                                              value={item}
                                              className="checkbox-style"
                                              checked={selectedGender?.includes(
                                                item
                                              )}
                                            />
                                          </span>
                                          <div className="checkbox-style-label">
                                            {item}
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </Row>
                              </Col>
                            )}

                            <Col
                              className="customContainer"
                              lg="12"
                              xl="12"
                            ></Col>
                          </Col>
                        </Row>
                      )}
                      {/* Gender sections ends */}

                      {/* Accessories sections starts */}
                      {(selectedEventType === 'Person' ||
                        selectedEventType === null) && (
                        <Row>
                          <Col lg="12" xl="12">
                            <Col
                              className="filter-title-container-events"
                              lg="12"
                              xl="12"
                            >
                              <Row className="full-width-section-style">
                                <Col lg="10" xl="10">
                                  <p className="event-category-style">
                                    {constants.EVENT_FILTER_ACC_LABEL}
                                  </p>
                                </Col>
                                <Col lg="2" xl="2">
                                  <div
                                    className="toggle-device-list"
                                    onClick={toggleAccessoriesListVisibility}
                                  >
                                    {isAccessoriesListVisible ? (
                                      <ChevronUp className="dark-theme-icon" />
                                    ) : (
                                      <ChevronDown className="dark-theme-icon" />
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            {isAccessoriesListVisible && (
                              <Col
                                className="filter-title-container-without-height"
                                lg="1"
                                xl="12"
                              >
                                <Row className="full-width-section-style">
                                  <ul className="custom-list">
                                    {accessoriesList?.map((item, index) => (
                                      <li
                                        className="custom-list-value-other"
                                        key={index}
                                        onClick={() =>
                                          handleAccessoriesSelection(item)
                                        }
                                      >
                                        <div className="align-items-checkbox-label">
                                          <span>
                                            <input
                                              type="checkbox"
                                              value={item}
                                              className="checkbox-style"
                                              checked={selectedAccessories?.includes(
                                                item
                                              )}
                                            />
                                          </span>
                                          <div className="checkbox-style-label">
                                            {item}
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </Row>
                              </Col>
                            )}

                            <Col
                              className="customContainer"
                              lg="12"
                              xl="12"
                            ></Col>
                          </Col>
                        </Row>
                      )}
                      {/* Accessories sections ends */}

                      {/* Pant color sections starts */}
                      {(selectedEventType === 'Person' ||
                        selectedEventType === null) && (
                        <Row>
                          <Col lg="12" xl="12">
                            <Col
                              className="filter-title-container-events"
                              lg="12"
                              xl="12"
                            >
                              <Row className="full-width-section-style">
                                <Col lg="10" xl="10">
                                  <p className="event-category-style">
                                    {constants.SEARCH_PANT_COLOR}
                                  </p>
                                </Col>
                                <Col lg="2" xl="2">
                                  <span
                                    className="toggle-device-list"
                                    onClick={togglePantColorListVisibility}
                                  >
                                    {isPantColorListVisible ? (
                                      <ChevronUp className="dark-theme-icon" />
                                    ) : (
                                      <ChevronDown className="dark-theme-icon" />
                                    )}
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                            {isPantColorListVisible && (
                              <Col
                                className="filter-error-container-events"
                                lg="12"
                                xl="12"
                              >
                                <span>
                                  <InformationCircle />
                                </span>
                                <p className="pant-color-limit-exeed-message">
                                  {
                                    constants.EVENT_FILTER_PANT_COLOR_LIMIT_MESSAGE
                                  }
                                </p>
                              </Col>
                            )}
                            {isPantColorListVisible && (
                              <Col
                                className="filter-title-container-without-height"
                                lg="1"
                                xl="12"
                              >
                                <Row className="full-width-section-style">
                                  <ul className="custom-list">
                                    {pantColorlist?.map((item, index) => (
                                      <li
                                        className="custom-list-value-other"
                                        key={index}
                                        onClick={() =>
                                          !(
                                            selectedPantColor?.length > 1 &&
                                            !selectedPantColor?.includes(item)
                                          ) && handlePantColorSelection(item)
                                        }
                                      >
                                        <div className="align-items-checkbox-label">
                                          <span>
                                            <input
                                              type="checkbox"
                                              value={item}
                                              className="checkbox-style"
                                              checked={selectedPantColor?.includes(
                                                item
                                              )}
                                              disabled={
                                                selectedPantColor?.length > 1 &&
                                                !selectedPantColor?.includes(
                                                  item
                                                )
                                              }
                                            />
                                          </span>
                                          <div className="checkbox-style-label">
                                            {item}
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </Row>
                              </Col>
                            )}

                            <Col
                              className="customContainer"
                              lg="12"
                              xl="12"
                            ></Col>
                          </Col>
                        </Row>
                      )}
                      {/* Pant color sections ends */}

                      {/* Shirt color sections starts */}
                      {(selectedEventType === 'Person' ||
                        selectedEventType === null) && (
                        <Row>
                          <Col lg="12" xl="12">
                            <Col
                              className="filter-title-container-events"
                              lg="12"
                              xl="12"
                            >
                              <Row className="full-width-section-style">
                                <Col lg="10" xl="10">
                                  <p className="event-category-style">
                                    {constants.SEARCH_SHIRT_COLOR}
                                  </p>
                                </Col>
                                <Col lg="2" xl="2">
                                  <span
                                    className="toggle-device-list"
                                    onClick={toggleShirtColorListVisibility}
                                  >
                                    {isShirtColorListVisible ? (
                                      <ChevronUp className="dark-theme-icon" />
                                    ) : (
                                      <ChevronDown className="dark-theme-icon" />
                                    )}
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                            {isShirtColorListVisible && (
                              <Col
                                className="filter-error-container-events"
                                lg="12"
                                xl="12"
                              >
                                <span>
                                  <InformationCircle />
                                </span>
                                <p className="pant-color-limit-exeed-message">
                                  {
                                    constants.EVENT_FILTER_SHIRT_COLOR_LIMIT_MESSAGE
                                  }
                                </p>
                              </Col>
                            )}
                            {isShirtColorListVisible && (
                              <Col
                                className="filter-title-container-without-height"
                                lg="1"
                                xl="12"
                              >
                                <Row className="full-width-section-style">
                                  <ul className="custom-list">
                                    {shirtColorlist?.map((item, index) => (
                                      <li
                                        className="custom-list-value-other"
                                        key={index}
                                        onClick={() =>
                                          !(
                                            selectedShirtColor?.length > 1 &&
                                            !selectedShirtColor?.includes(item)
                                          ) && handleShirtColorSelection(item)
                                        }
                                      >
                                        <div className="align-items-checkbox-label">
                                          <span>
                                            <input
                                              type="checkbox"
                                              value={item}
                                              className="checkbox-style"
                                              checked={selectedShirtColor?.includes(
                                                item
                                              )}
                                              disabled={
                                                selectedShirtColor?.length >
                                                  1 &&
                                                !selectedShirtColor?.includes(
                                                  item
                                                )
                                              }
                                            />
                                          </span>
                                          <div className="checkbox-style-label">
                                            {item}
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </Row>
                              </Col>
                            )}

                            <Col
                              className="customContainer"
                              lg="12"
                              xl="12"
                            ></Col>
                          </Col>
                        </Row>
                      )}
                      {/* Shirt color sections ends */}

                      {/* Vehicle color sections starts */}
                      {(selectedEventType === 'Vehicle' ||
                        selectedEventType === null) && (
                        <Row>
                          <Col lg="12" xl="12">
                            <Col
                              className="filter-title-container-events"
                              lg="12"
                              xl="12"
                            >
                              <Row className="full-width-section-style">
                                <Col lg="10" xl="10">
                                  <p className="event-category-style">
                                    {constants.EVENT_FILTER_VEHICLE_COLOR_LABEL}
                                  </p>
                                </Col>
                                <Col lg="2" xl="2">
                                  <span
                                    className="toggle-device-list"
                                    onClick={toggleVehicleColorListVisibility}
                                  >
                                    {isVehicleColorListVisible ? (
                                      <ChevronUp className="dark-theme-icon" />
                                    ) : (
                                      <ChevronDown className="dark-theme-icon" />
                                    )}
                                  </span>
                                </Col>
                              </Row>
                            </Col>

                            {isVehicleColorListVisible && (
                              <Col
                                className="filter-error-container-events"
                                lg="12"
                                xl="12"
                              >
                                <span>
                                  <InformationCircle />
                                </span>
                                <p className="pant-color-limit-exeed-message">
                                  {
                                    constants.EVENT_FILTER_VEHICLE_COLOR_LIMIT_MESSAGE
                                  }
                                </p>
                              </Col>
                            )}

                            {isVehicleColorListVisible && (
                              <Col
                                className="filter-title-container-without-height"
                                lg="1"
                                xl="12"
                              >
                                <Row className="full-width-section-style">
                                  <ul className="custom-list">
                                    {vehicleColorlist?.map((item, index) => (
                                      <li
                                        className="custom-list-value-other"
                                        key={index}
                                        onClick={() =>
                                          !(
                                            selectedVehicleColor?.length > 1 &&
                                            !selectedVehicleColor?.includes(
                                              item
                                            )
                                          ) && handleVehicleColorSelection(item)
                                        }
                                      >
                                        <div className="align-items-checkbox-label">
                                          <span>
                                            <input
                                              type="checkbox"
                                              value={item}
                                              className="checkbox-style"
                                              checked={selectedVehicleColor?.includes(
                                                item
                                              )}
                                              disabled={
                                                selectedVehicleColor?.length >
                                                  1 &&
                                                !selectedVehicleColor?.includes(
                                                  item
                                                )
                                              }
                                            />
                                          </span>
                                          <div className="checkbox-style-label">
                                            {item}
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </Row>
                              </Col>
                            )}

                            <Col
                              className="customContainer"
                              lg="12"
                              xl="12"
                            ></Col>
                          </Col>
                        </Row>
                      )}
                      {/* Vehicle color sections ends */}

                      {/* Vehicle type sections starts */}
                      {(selectedEventType === 'Vehicle' ||
                        selectedEventType === null) && (
                        <Row>
                          <Col lg="12" xl="12">
                            <Col
                              className="filter-title-container-events"
                              lg="12"
                              xl="12"
                            >
                              <Row className="full-width-section-style">
                                <Col lg="10" xl="10">
                                  <p className="event-category-style">
                                    {constants.SEARCH_VEHICLE_TYPE}
                                  </p>
                                </Col>
                                <Col lg="2" xl="2">
                                  <span
                                    className="toggle-device-list"
                                    onClick={toggleVehicleTypeListVisibility}
                                  >
                                    {isVehicleTypeListVisible ? (
                                      <ChevronUp className="dark-theme-icon" />
                                    ) : (
                                      <ChevronDown className="dark-theme-icon" />
                                    )}
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                            {isVehicleTypeListVisible && (
                              <Col
                                className="filter-title-container-without-height"
                                lg="1"
                                xl="12"
                              >
                                <Row className="full-width-section-style">
                                  <ul className="custom-list">
                                    {vehicleTypelist?.map((item, index) => (
                                      <li
                                        className="custom-list-value-other"
                                        key={index}
                                        onClick={() =>
                                          handleVehicleTypeSelection(item)
                                        }
                                      >
                                        <div className="align-items-checkbox-label">
                                          <span>
                                            <input
                                              type="checkbox"
                                              value={item}
                                              className="checkbox-style"
                                              checked={selectedVehicleType?.includes(
                                                item
                                              )}
                                            />
                                          </span>
                                          <div className="checkbox-style-label">
                                            {item}
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </Row>
                              </Col>
                            )}

                            <Col
                              className="customContainer"
                              lg="12"
                              xl="12"
                            ></Col>
                          </Col>
                        </Row>
                      )}
                      {/* Vehicle type sections ends */}

                      {/* License plate sections starts */}
                      {(selectedEventType === 'License Plate' ||
                        selectedEventType === null) && (
                        <Row>
                          <Col lg="12" xl="12">
                            <Col
                              className="filter-title-container-events"
                              lg="12"
                              xl="12"
                            >
                              <Row className="full-width-section-style">
                                <Col lg="10" xl="10">
                                  <p className="event-category-style">
                                    {constants.EVENT_FILTER_LP}
                                  </p>
                                </Col>
                                <Col lg="2" xl="2">
                                  <span
                                    className="toggle-device-list"
                                    onClick={toggleLicensePlateListVisibility}
                                  >
                                    {isLicensePlateListVisible ? (
                                      <ChevronUp className="dark-theme-icon" />
                                    ) : (
                                      <ChevronDown className="dark-theme-icon" />
                                    )}
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                            {isLicensePlateListVisible && (
                              <Col className="search-box-style" lg="1" xl="12">
                                <Row className="full-width-section-style">
                                  <Col lg="10" xl="10" className='search-icon-parent-area'>
                                    <input
                                      type="text"
                                      className="location-search-style"
                                      placeholder="Search License"
                                      value={searchTermForLicense}
                                      onChange={(e) => {
                                        setEventTypeList(eventTypeListWithLP);
                                        setSelectedEventType('License Plate');
                                        searchTermForLicense = e.target.value;
                                        setSearchTermForLicense(e.target.value);
                                        if (e.target.value === '') {
                                          applyTag('License Plate');
                                          setEventTypeList(eventTypeListAll);
                                        }
                                      }}
                                      onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                          e.preventDefault();
                                          if (e.target.value !== '') {
                                            applyTag('License Plate');
                                            setSelectedEventType(
                                              'License Plate'
                                            );
                                            setEventTypeList(
                                              eventTypeListWithLP
                                            );
                                          }
                                        }
                                      }}
                                    />
                                    <span>
                                      <RxMagnifyingGlass className="search-icon-license" />
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                            )}

                            <Col
                              className="customContainer"
                              lg="12"
                              xl="12"
                            ></Col>
                          </Col>
                        </Row>
                      )}
                      {/* License plate sections ends */}

                      {/* Sound type sections starts */}
                      {(selectedEventType === 'Audio' ||
                        selectedEventType === null) && (
                        <Row>
                          <Col lg="12" xl="12">
                            <Col
                              className="filter-title-container-events"
                              lg="12"
                              xl="12"
                            >
                              <Row className="full-width-section-style">
                                <Col lg="10" xl="10">
                                  <p className="event-category-style">
                                    {constants.SEARCH_SOUND_TYPE}
                                  </p>
                                </Col>
                                <Col lg="2" xl="2">
                                  <span
                                    className="toggle-device-list"
                                    onClick={toggleSoundTypeListVisibility}
                                  >
                                    {isSoundTypeListVisible ? (
                                      <ChevronUp className="dark-theme-icon" />
                                    ) : (
                                      <ChevronDown className="dark-theme-icon" />
                                    )}
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                            {isSoundTypeListVisible && (
                              <Col
                                className="filter-title-container-without-height"
                                lg="1"
                                xl="12"
                              >
                                <Row className="full-width-section-style">
                                  <ul className="custom-list">
                                    {soundTypelist?.map((item, index) => (
                                      <li
                                        className="custom-list-value-other"
                                        key={index}
                                        onClick={() =>
                                          handleSoundTypeSelection(item)
                                        }
                                      >
                                        <div className="align-items-checkbox-label">
                                          <span>
                                            <input
                                              type="checkbox"
                                              value={item}
                                              className="checkbox-style"
                                              checked={selectedSoundType?.includes(
                                                item
                                              )}
                                            />
                                          </span>
                                          <div className="checkbox-style-label">
                                            {item}
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </Row>
                              </Col>
                            )}

                            <Col
                              className="customContainer"
                              lg="12"
                              xl="12"
                            ></Col>
                          </Col>
                        </Row>
                      )}
                      {/* Sound type sections ends */}
                    </Col>
                  </Row>
                </div>
              </Col>
            )}
          {isSmallScreen &&
            open &&
            !showEventPreviewModal &&
            !showSearchModal && (
              <Drawer
                className="device-wall-drawer-events"
                variant="permanent"
                open={true}
              >
                <Col lg="2" xl="2" className="left-panel-small">
                  <Row>
                    <div className="filter-label">
                      <Row>
                        <Col xs={1}>
                          <FilterIconTitle />
                        </Col>
                        <Col xs={1}>
                          <p className="filter-label-style">
                            {constants.EVENT_FILTER_LABEL}
                          </p>
                        </Col>
                        <Col
                          xs={10}
                          className="toggle-left-pabel-icon-small"
                        ></Col>
                      </Row>
                    </div>
                  </Row>
                  <Row>
                    <Col className="event-scroll-section" lg="2" xl="2">
                      {(allLocationsData?.length < 2 ||
                        allAreasData?.length < 2 ||
                        allDevciesData?.length < 2) && (
                        <div className="empty-section-style"></div>
                      )}

                      {/* Locations area starts */}
                      {allLocationsData?.length > 1 && (
                        <Row>
                          <Col lg="12" xl="12">
                            {!(
                              allLocationsData?.length < 2 ||
                              allAreasData?.length < 2 ||
                              allDevciesData?.length < 2
                            ) && <Row className="empty-section-style"></Row>}
                            <Col
                              className="filter-title-container-events"
                              lg="12"
                              xl="12"
                            >
                              <Row className="full-width-section-style">
                                <Col lg="10" xl="10">
                                  <p className="event-category-style">
                                    {constants.EVENT_FILTER_LOCATION_LABEL}
                                  </p>
                                </Col>
                                <Col lg="2" xl="2">
                                  <span
                                    className="toggle-device-list"
                                    onClick={toggleLocationListVisibility}
                                  >
                                    {isLocationListVisible ? (
                                      <ChevronUp className="dark-theme-icon" />
                                    ) : (
                                      <ChevronDown className="dark-theme-icon" />
                                    )}
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                            {/* show search location if there are more than 10 list items */}
                            {allLocationsData &&
                              allLocationsData?.length > 0 &&
                              Array.isArray(allLocationsData) &&
                              sortList(
                                allLocationsData?.map(
                                  (location) => location.locationName
                                )
                              )?.length > 10 &&
                              isLocationListVisible && (
                                <Col
                                  className="search-box-style"
                                  xs="12"
                                  md="12"
                                  lg="12"
                                  xl="12"
                                >
                                  <Row className="full-width-section-style">
                                    {smallScreenValue > 991 &&
                                    smallScreenValue < 1200 ? (
                                      <Col
                                        lg={windowWidth === 1199 ? '10' : '11'}
                                        xl={windowWidth === 1199 ? '10' : '11'}
                                        className='search-icon-parent-area'>
                                        <input
                                          type="text"
                                          className="location-search-style"
                                          placeholder={
                                            constants.EVENT_FILTER_SEARCH_LOCATION
                                          }
                                          value={searchTermForLocation}
                                          onChange={(e) =>
                                            setSearchTermForLocation(
                                              e.target.value
                                            )
                                          }
                                        />
                                         <span>
                                          <RxMagnifyingGlass className="search-icon-license" />
                                        </span>
                                      </Col>
                                    ) : (
                                      <Col lg="11" xl="11" className='search-icon-parent-area'>
                                        <input
                                          type="text"
                                          className="location-search-style"
                                          placeholder={
                                            constants.EVENT_FILTER_SEARCH_LOCATION
                                          }
                                          value={searchTermForLocation}
                                          onChange={(e) =>
                                            setSearchTermForLocation(
                                              e.target.value
                                            )
                                          }
                                        />
                                        <span>
                                          <RxMagnifyingGlass className="search-icon-license" />
                                        </span>
                                      </Col>
                                    )}
                                  </Row>
                                </Col>
                              )}
                            {isLocationListVisible && (
                              <Col
                                className="filter-title-container-without-height"
                                lg="12"
                                xl="12"
                              >
                                <Row className="full-width-section-style">
                                  <ul className="custom-list">
                                    {allLocationsData &&
                                      allLocationsData?.length > 0 &&
                                      filteredLocations
                                        ?.slice(0, visibleItemCountForLocation)
                                        ?.map((item, index) => (
                                          <li
                                            className="custom-list-value-other"
                                            key={index}
                                            onClick={() =>
                                              handleLocationSelection(item)
                                            }
                                          >
                                            <div className="align-items-checkbox-label">
                                              <span>
                                                <input
                                                  type="checkbox"
                                                  value={item}
                                                  className="checkbox-style"
                                                  checked={selectedLocations?.includes(
                                                    item
                                                  )}
                                                />
                                              </span>
                                              <div className="checkbox-style-label">
                                                {item}
                                              </div>
                                            </div>
                                          </li>
                                        ))}
                                    {allLocationsData &&
                                      allLocationsData?.length > 0 &&
                                      Array.isArray(allLocationsData) &&
                                      sortList(
                                        allLocationsData?.map(
                                          (location) => location.locationName
                                        )
                                      )?.length >
                                        visibleItemCountForLocation && (
                                        <li className="custom-list-value-other-show-more">
                                          <div className="align-items-checkbox-label">
                                            <span
                                              className="show-more-icon-style"
                                              onClick={
                                                handleShowMoreForLocation
                                              }
                                            >
                                              <ChevronDownShowMore />
                                            </span>
                                            <span
                                              className="show-more-label"
                                              onClick={
                                                handleShowMoreForLocation
                                              }
                                            >
                                              {constants.EVENT_FILTER_SHOW_MORE}
                                            </span>
                                          </div>
                                        </li>
                                      )}
                                  </ul>
                                </Row>
                              </Col>
                            )}
                            <Col
                              className="customContainer"
                              lg="12"
                              xl="12"
                            ></Col>
                          </Col>
                        </Row>
                      )}
                      {/* Locations area ends */}

                      {/* Areas sections starts */}
                      {allAreasData?.length > 1 &&
                        sortList(allAreasData)?.length > 1 && (
                          <Row>
                            <Col lg="12" xl="12">
                              <Col
                                className="filter-title-container-events"
                                lg="12"
                                xl="12"
                              >
                                <Row className="full-width-section-style">
                                  <Col lg="10" xl="10">
                                    <p className="event-category-style">
                                      {constants.EVENT_FILTER_AREAS_LABEL}
                                    </p>
                                  </Col>
                                  <Col lg="2" xl="2">
                                    <span
                                      className="toggle-device-list"
                                      onClick={toggleAreaListVisibility}
                                    >
                                      {isAreaListVisible ? (
                                        <ChevronUp className="dark-theme-icon" />
                                      ) : (
                                        <ChevronDown className="dark-theme-icon" />
                                      )}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                              {/* show search area if there are more than 10 list items */}
                              {allAreasData &&
                                allAreasData?.length > 0 &&
                                Array.isArray(allAreasData) &&
                                sortList(
                                  allAreasData?.map((area) => area.areaName)
                                )?.length > 10 &&
                                isAreaListVisible && (
                                  <Col
                                    className="search-box-style"
                                    xs="12"
                                    md="12"
                                    lg="12"
                                    xl="12"
                                  >
                                    <Row className="full-width-section-style">
                                      {smallScreenValue > 991 &&
                                      smallScreenValue < 1200 ? (
                                        <Col lg="12" xl="12" className='search-icon-parent-area'>
                                          <input
                                            type="text"
                                            className="location-search-style"
                                            placeholder={
                                              constants.EVENT_FILTER_SEARCH_AREAS
                                            }
                                            value={searchTermForArea}
                                            onChange={(e) =>
                                              setSearchTermForArea(
                                                e.target.value
                                              )
                                            }
                                          />
                                           <span>
                                            <RxMagnifyingGlass className="search-icon-license" />
                                          </span>
                                        </Col>
                                      ) : (
                                        <Col lg="11" xl="11" className='search-icon-parent-area'>
                                          <input
                                            type="text"
                                            className="location-search-style"
                                            placeholder={
                                              constants.EVENT_FILTER_SEARCH_AREAS
                                            }
                                            value={searchTermForArea}
                                            onChange={(e) =>
                                              setSearchTermForArea(
                                                e.target.value
                                              )
                                            }
                                          />
                                          <span>
                                            <RxMagnifyingGlass className="search-icon-license" />
                                          </span>
                                        </Col>
                                      )}
                                    </Row>
                                  </Col>
                                )}
                              {isAreaListVisible && (
                                <Col
                                  className="filter-title-container-without-height"
                                  lg="1"
                                  xl="12"
                                >
                                  <Row className="full-width-section-style">
                                    <ul className="custom-list">
                                      {allAreasData &&
                                        allAreasData?.length > 0 &&
                                        Array.isArray(allAreasData) &&
                                        sortList(filteredAreas)
                                          ?.slice(0, visibleItemCountForArea)
                                          ?.map((item, index) => (
                                            <li
                                              className="custom-list-value-other"
                                              key={index}
                                              onClick={() =>
                                                handleAreaSelection(item)
                                              }
                                            >
                                              <div className="align-items-checkbox-label">
                                                <span>
                                                  <input
                                                    type="checkbox"
                                                    value={item}
                                                    className="checkbox-style"
                                                    checked={selectedAreas?.includes(
                                                      item
                                                    )}
                                                  />
                                                </span>
                                                <div className="checkbox-style-label">
                                                  {item}
                                                </div>
                                              </div>
                                            </li>
                                          ))}
                                      {allAreasData &&
                                        allAreasData?.length > 0 &&
                                        Array.isArray(allAreasData) &&
                                        sortList(
                                          allAreasData?.map(
                                            (area) => area.areaName
                                          )
                                        )?.length > visibleItemCountForArea && (
                                          <li className="custom-list-value-other-show-more">
                                            <div className="align-items-checkbox-label">
                                              <span
                                                className="show-more-icon-style"
                                                onClick={handleShowMoreForArea}
                                              >
                                                <ChevronDownShowMore />
                                              </span>
                                              <span
                                                className="show-more-label"
                                                onClick={handleShowMoreForArea}
                                              >
                                                {
                                                  constants.EVENT_FILTER_SHOW_MORE
                                                }
                                              </span>
                                            </div>
                                          </li>
                                        )}
                                    </ul>
                                  </Row>
                                </Col>
                              )}

                              <Col
                                className="customContainer"
                                lg="12"
                                xl="12"
                              ></Col>
                            </Col>
                          </Row>
                        )}
                      {/* Areas sections ends */}

                      {/* Devices sections starts */}
                      {allDevciesData?.length > 1 &&
                        sortList(filteredDevices)?.length > 1 && (
                          <Row>
                            <Col lg="12" xl="12">
                              <Col
                                className="filter-title-container-events"
                                lg="12"
                                xl="12"
                              >
                                <Row className="full-width-section-style">
                                  <Col lg="10" xl="10">
                                    <p className="event-category-style">
                                      {constants.EVENT_FILTER_DEVICES_LABEL}
                                    </p>
                                  </Col>
                                  <Col lg="2" xl="2">
                                    <span
                                      className="toggle-device-list"
                                      onClick={toggleDeviceListVisibility}
                                    >
                                      {isDeviceListVisible ? (
                                        <ChevronUp className="dark-theme-icon" />
                                      ) : (
                                        <ChevronDown className="dark-theme-icon" />
                                      )}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                              {/* show search location if there are more than 10 list items */}
                              {allDevciesData &&
                                allDevciesData?.length > 0 &&
                                Array.isArray(allDevciesData) &&
                                sortList(
                                  allDevciesData?.map(
                                    (device) => device.deviceName
                                  )
                                )?.length > 10 &&
                                isDeviceListVisible && (
                                  <Col
                                    className="search-box-style"
                                    lg="1"
                                    xl="12"
                                  >
                                     <Row className="full-width-section-style">
                                      <Col lg="10" xl="10" className='search-icon-parent-area'>
                                        <input
                                          type="text"
                                          className="location-search-style"
                                          placeholder={
                                            constants.EVENT_FILTER_SEARCH_DEVICES
                                          }
                                          value={searchTermForDevice}
                                          onChange={(e) =>
                                            setSearchTermForDevice(e.target.value)
                                          }
                                        />
                                         <span>
                                          <RxMagnifyingGlass className="search-icon-license" />
                                        </span>
                                      </Col>
                                    </Row>
                                  </Col>
                                )}
                              {isDeviceListVisible && (
                                <Col
                                  className="filter-title-container-without-height"
                                  lg="1"
                                  xl="12"
                                >
                                  <Row className="full-width-section-style">
                                    <ul className="custom-list">
                                      {allDevciesData &&
                                        allDevciesData?.length > 0 &&
                                        Array.isArray(allDevciesData) &&
                                        sortList(filteredDevices)
                                          ?.slice(0, visibleItemCountForDevice)
                                          ?.map((item, index) => (
                                            <li
                                              className="custom-list-value-other"
                                              key={index}
                                              onClick={() =>
                                                handleDevicesSelection(item)
                                              }
                                            >
                                              <div className="align-items-checkbox-label">
                                                <span>
                                                  <input
                                                    type="checkbox"
                                                    value={item}
                                                    className="checkbox-style"
                                                    checked={selectedDevices?.includes(
                                                      item
                                                    )}
                                                  />
                                                </span>
                                                <div className="checkbox-style-label">
                                                  {item}
                                                </div>
                                              </div>
                                            </li>
                                          ))}
                                      {allDevciesData &&
                                        allDevciesData?.length > 0 &&
                                        Array.isArray(allDevciesData) &&
                                        sortList(
                                          allDevciesData?.map(
                                            (device) => device.deviceName
                                          )
                                        )?.length >
                                          visibleItemCountForDevice && (
                                          <li className="custom-list-value-other-show-more">
                                            <div className="align-items-checkbox-label">
                                              <span
                                                className="show-more-icon-style"
                                                onClick={
                                                  handleShowMoreForDevice
                                                }
                                              >
                                                <ChevronDownShowMore />
                                              </span>
                                              <span
                                                className="show-more-label"
                                                onClick={
                                                  handleShowMoreForDevice
                                                }
                                              >
                                                {
                                                  constants.EVENT_FILTER_SHOW_MORE
                                                }
                                              </span>
                                            </div>
                                          </li>
                                        )}
                                    </ul>
                                  </Row>
                                </Col>
                              )}

                              <Col
                                className="customContainer"
                                lg="12"
                                xl="12"
                              ></Col>
                            </Col>
                          </Row>
                        )}
                      {/* Devices sections ends */}

                      {/* Event Type Section Starts */}
                      <Row>
                        <Col lg="12" xl="12">
                          <Col
                            className="filter-title-container-event-title"
                            lg="12"
                            xl="12"
                          >
                            <p className="event-category-style">
                              {constants.EVENT_FILTER_EVENT_TYPE_LABEL}
                            </p>
                          </Col>
                          <Col
                            className="filter-title-container-without-height"
                            lg="1"
                            xl="12"
                          >
                            <ul className="custom-list">
                              {eventTypeList?.map((item, index) => (
                                <li
                                  className={`custom-list-value ${
                                    selectedEventType === item
                                      ? 'selected-event-type-style'
                                      : ''
                                  }`}
                                  key={index}
                                  onClick={() => handleEventTypeSelection(item)}
                                >
                                  {item}
                                </li>
                              ))}
                            </ul>
                          </Col>
                          <Col
                            className="customContainer"
                            lg="12"
                            xl="12"
                          ></Col>
                        </Col>
                      </Row>
                      {/* Event Type Section ends */}

                      {/* Age sections starts */}
                      {(selectedEventType === 'Face' ||
                        selectedEventType === null) && (
                        <Row>
                          <Col lg="12" xl="12">
                            <Col
                              className="filter-title-container-events"
                              lg="12"
                              xl="12"
                            >
                              <Row className="full-width-section-style">
                                <Col lg="10" xl="10">
                                  <p className="event-category-style">
                                    {constants.EVENT_FILTER_AGE_LABEL}
                                  </p>
                                </Col>
                                <Col lg="2" xl="2">
                                  <span
                                    className="toggle-device-list"
                                    onClick={toggleAgeListVisibility}
                                  >
                                    {isAgeListVisible ? (
                                      <ChevronUp className="dark-theme-icon" />
                                    ) : (
                                      <ChevronDown className="dark-theme-icon" />
                                    )}
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                            {isAgeListVisible && (
                              <Col
                                className="filter-title-container-without-height"
                                lg="1"
                                xl="12"
                              >
                                <Row className="full-width-section-style">
                                  <ul className="custom-list">
                                    {ageList?.map((item, index) => (
                                      <li
                                        className="custom-list-value-other"
                                        key={index}
                                        onClick={() => handleAgeSelection(item)}
                                      >
                                        <div className="align-items-checkbox-label">
                                          <span>
                                            <input
                                              type="checkbox"
                                              value={item}
                                              className="checkbox-style"
                                              checked={selectedAge?.includes(
                                                item
                                              )}
                                            />
                                          </span>
                                          <div className="checkbox-style-label">
                                            {item}
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </Row>
                              </Col>
                            )}

                            <Col
                              className="customContainer"
                              lg="12"
                              xl="12"
                            ></Col>
                          </Col>
                        </Row>
                      )}
                      {/* Age sections ends */}

                      {/* Face Accessories sections starts */}
                      {(selectedEventType === 'Face' ||
                        selectedEventType === null) && (
                        <Row>
                          <Col lg="12" xl="12">
                            <Col
                              className="filter-title-container-events"
                              lg="12"
                              xl="12"
                            >
                              <Row className="full-width-section-style">
                                <Col lg="10" xl="10">
                                  <p className="event-category-style">
                                    {constants.EVENT_FILTER_FACE_ACC_LABEL}
                                  </p>
                                </Col>
                                <Col lg="2" xl="2">
                                  <span
                                    className="toggle-device-list"
                                    onClick={toggleFaceAccListVisibility}
                                  >
                                    {isFaceAccListVisible ? (
                                      <ChevronUp className="dark-theme-icon" />
                                    ) : (
                                      <ChevronDown className="dark-theme-icon" />
                                    )}
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                            {isFaceAccListVisible && (
                              <Col
                                className="filter-title-container-without-height"
                                lg="1"
                                xl="12"
                              >
                                <Row className="full-width-section-style">
                                  <ul className="custom-list">
                                    {faceAccList?.map((item, index) => (
                                      <li
                                        className="custom-list-value-other"
                                        key={index}
                                        onClick={() =>
                                          handleFaceAccSelection(item)
                                        }
                                      >
                                        <div className="align-items-checkbox-label">
                                          <span>
                                            <input
                                              type="checkbox"
                                              value={item}
                                              className="checkbox-style"
                                              checked={selectedFaceAcc?.includes(
                                                item
                                              ) || (item === "Glasses" && selectedFaceAcc?.includes("Opticals"))}
                                            />
                                          </span>
                                          <div className="checkbox-style-label">
                                            {item}
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </Row>
                              </Col>
                            )}

                            <Col
                              className="customContainer"
                              lg="12"
                              xl="12"
                            ></Col>
                          </Col>
                        </Row>
                      )}
                      {/* Face Accessories sections ends */}

                      {/* Gender sections starts */}
                      {(selectedEventType === 'Face' ||
                        selectedEventType === 'Person' ||
                        selectedEventType === null) && (
                        <Row>
                          <Col lg="12" xl="12">
                            <Col
                              className="filter-title-container-events"
                              lg="12"
                              xl="12"
                            >
                              <Row className="full-width-section-style">
                                <Col lg="10" xl="10">
                                  <p className="event-category-style">
                                    {constants.EVENT_FILTER_GENDER_LABEL}
                                  </p>
                                </Col>
                                <Col lg="2" xl="2">
                                  <span
                                    className="toggle-device-list"
                                    onClick={toggleGenderListVisibility}
                                  >
                                    {isGenderListVisible ? (
                                      <ChevronUp className="dark-theme-icon" />
                                    ) : (
                                      <ChevronDown className="dark-theme-icon" />
                                    )}
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                            {isGenderListVisible && (
                              <Col
                                className="filter-title-container-without-height"
                                lg="1"
                                xl="12"
                              >
                                <Row className="full-width-section-style">
                                  <ul className="custom-list">
                                    {genderList?.map((item, index) => (
                                      <li
                                        className="custom-list-value-other"
                                        key={index}
                                        onClick={() =>
                                          handleGenderSelection(item)
                                        }
                                      >
                                        <div className="align-items-checkbox-label">
                                          <span>
                                            <input
                                              type="checkbox"
                                              value={item}
                                              className="checkbox-style"
                                              checked={selectedGender?.includes(
                                                item
                                              )}
                                            />
                                          </span>
                                          <div className="checkbox-style-label">
                                            {item}
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </Row>
                              </Col>
                            )}

                            <Col
                              className="customContainer"
                              lg="12"
                              xl="12"
                            ></Col>
                          </Col>
                        </Row>
                      )}
                      {/* Gender sections ends */}

                      {/* Accessories sections starts */}
                      {(selectedEventType === 'Person' ||
                        selectedEventType === null) && (
                        <Row>
                          <Col lg="12" xl="12">
                            <Col
                              className="filter-title-container-events"
                              lg="12"
                              xl="12"
                            >
                              <Row className="full-width-section-style">
                                <Col lg="10" xl="10">
                                  <p className="event-category-style">
                                    {constants.EVENT_FILTER_ACC_LABEL}
                                  </p>
                                </Col>
                                <Col lg="2" xl="2">
                                  <span
                                    className="toggle-device-list"
                                    onClick={toggleAccessoriesListVisibility}
                                  >
                                    {isAccessoriesListVisible ? (
                                      <ChevronUp className="dark-theme-icon" />
                                    ) : (
                                      <ChevronDown className="dark-theme-icon" />
                                    )}
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                            {isAccessoriesListVisible && (
                              <Col
                                className="filter-title-container-without-height"
                                lg="1"
                                xl="12"
                              >
                                <Row className="full-width-section-style">
                                  <ul className="custom-list">
                                    {accessoriesList?.map((item, index) => (
                                      <li
                                        className="custom-list-value-other"
                                        key={index}
                                        onClick={() =>
                                          handleAccessoriesSelection(item)
                                        }
                                      >
                                        <div className="align-items-checkbox-label">
                                          <span>
                                            <input
                                              type="checkbox"
                                              value={item}
                                              className="checkbox-style"
                                              checked={selectedAccessories?.includes(
                                                item
                                              )}
                                            />
                                          </span>
                                          <div className="checkbox-style-label">
                                            {item}
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </Row>
                              </Col>
                            )}

                            <Col
                              className="customContainer"
                              lg="12"
                              xl="12"
                            ></Col>
                          </Col>
                        </Row>
                      )}
                      {/* Accessories sections ends */}

                      {/* Pant color sections starts */}
                      {(selectedEventType === 'Person' ||
                        selectedEventType === null) && (
                        <Row>
                          <Col lg="12" xl="12">
                            <Col
                              className="filter-title-container-events"
                              lg="12"
                              xl="12"
                            >
                              <Row className="full-width-section-style">
                                <Col lg="10" xl="10">
                                  <p className="event-category-style">
                                    {constants.SEARCH_PANT_COLOR}
                                  </p>
                                </Col>
                                <Col lg="2" xl="2">
                                  <span
                                    className="toggle-device-list"
                                    onClick={togglePantColorListVisibility}
                                  >
                                    {isPantColorListVisible ? (
                                      <ChevronUp className="dark-theme-icon" />
                                    ) : (
                                      <ChevronDown className="dark-theme-icon" />
                                    )}
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                            {isPantColorListVisible && (
                              <Col
                                className="filter-error-container-events"
                                lg="12"
                                xl="12"
                              >
                                <span>
                                  <InformationCircle />
                                </span>
                                <p className="pant-color-limit-exeed-message">
                                  {
                                    constants.EVENT_FILTER_PANT_COLOR_LIMIT_MESSAGE
                                  }
                                </p>
                              </Col>
                            )}
                            {isPantColorListVisible && (
                              <Col
                                className="filter-title-container-without-height"
                                lg="1"
                                xl="12"
                              >
                                <Row className="full-width-section-style">
                                  <ul className="custom-list">
                                    {pantColorlist?.map((item, index) => (
                                      <li
                                        className="custom-list-value-other"
                                        key={index}
                                        onClick={() =>
                                          !(
                                            selectedPantColor?.length > 1 &&
                                            !selectedPantColor?.includes(item)
                                          ) && handlePantColorSelection(item)
                                        }
                                      >
                                        <div className="align-items-checkbox-label">
                                          <span>
                                            <input
                                              type="checkbox"
                                              value={item}
                                              className="checkbox-style"
                                              checked={selectedPantColor?.includes(
                                                item
                                              )}
                                              disabled={
                                                selectedPantColor?.length > 1 &&
                                                !selectedPantColor?.includes(
                                                  item
                                                )
                                              }
                                            />
                                          </span>
                                          <div className="checkbox-style-label">
                                            {item}
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </Row>
                              </Col>
                            )}

                            <Col
                              className="customContainer"
                              lg="12"
                              xl="12"
                            ></Col>
                          </Col>
                        </Row>
                      )}
                      {/* Pant color sections ends */}

                      {/* Shirt color sections starts */}
                      {(selectedEventType === 'Person' ||
                        selectedEventType === null) && (
                        <Row>
                          <Col lg="12" xl="12">
                            <Col
                              className="filter-title-container-events"
                              lg="12"
                              xl="12"
                            >
                              <Row className="full-width-section-style">
                                <Col lg="10" xl="10">
                                  <p className="event-category-style">
                                    {constants.SEARCH_SHIRT_COLOR}
                                  </p>
                                </Col>
                                <Col lg="2" xl="2">
                                  <span
                                    className="toggle-device-list"
                                    onClick={toggleShirtColorListVisibility}
                                  >
                                    {isShirtColorListVisible ? (
                                      <ChevronUp className="dark-theme-icon" />
                                    ) : (
                                      <ChevronDown className="dark-theme-icon" />
                                    )}
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                            {isShirtColorListVisible && (
                              <Col
                                className="filter-error-container-events"
                                lg="12"
                                xl="12"
                              >
                                <span>
                                  <InformationCircle />
                                </span>
                                <p className="pant-color-limit-exeed-message">
                                  {
                                    constants.EVENT_FILTER_SHIRT_COLOR_LIMIT_MESSAGE
                                  }
                                </p>
                              </Col>
                            )}
                            {isShirtColorListVisible && (
                              <Col
                                className="filter-title-container-without-height"
                                lg="1"
                                xl="12"
                              >
                                <Row className="full-width-section-style">
                                  <ul className="custom-list">
                                    {shirtColorlist?.map((item, index) => (
                                      <li
                                        className="custom-list-value-other"
                                        key={index}
                                        onClick={() =>
                                          !(
                                            selectedShirtColor?.length > 1 &&
                                            !selectedShirtColor?.includes(item)
                                          ) && handleShirtColorSelection(item)
                                        }
                                      >
                                        <div className="align-items-checkbox-label">
                                          <span>
                                            <input
                                              type="checkbox"
                                              value={item}
                                              className="checkbox-style"
                                              checked={selectedShirtColor?.includes(
                                                item
                                              )}
                                              disabled={
                                                selectedShirtColor?.length >
                                                  1 &&
                                                !selectedShirtColor?.includes(
                                                  item
                                                )
                                              }
                                            />
                                          </span>
                                          <div className="checkbox-style-label">
                                            {item}
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </Row>
                              </Col>
                            )}

                            <Col
                              className="customContainer"
                              lg="12"
                              xl="12"
                            ></Col>
                          </Col>
                        </Row>
                      )}
                      {/* Shirt color sections ends */}

                      {/* Vehicle color sections starts */}
                      {(selectedEventType === 'Vehicle' ||
                        selectedEventType === null) && (
                        <Row>
                          <Col lg="12" xl="12">
                            <Col
                              className="filter-title-container-events"
                              lg="12"
                              xl="12"
                            >
                              <Row className="full-width-section-style">
                                <Col lg="10" xl="10">
                                  <p className="event-category-style">
                                    {constants.EVENT_FILTER_VEHICLE_COLOR_LABEL}
                                  </p>
                                </Col>
                                <Col lg="2" xl="2">
                                  <span
                                    className="toggle-device-list"
                                    onClick={toggleVehicleColorListVisibility}
                                  >
                                    {isVehicleColorListVisible ? (
                                      <ChevronUp className="dark-theme-icon" />
                                    ) : (
                                      <ChevronDown className="dark-theme-icon" />
                                    )}
                                  </span>
                                </Col>
                              </Row>
                            </Col>

                            {isVehicleColorListVisible && (
                              <Col
                                className="filter-error-container-events"
                                lg="12"
                                xl="12"
                              >
                                <span>
                                  <InformationCircle />
                                </span>
                                <p className="pant-color-limit-exeed-message">
                                  {
                                    constants.EVENT_FILTER_VEHICLE_COLOR_LIMIT_MESSAGE
                                  }
                                </p>
                              </Col>
                            )}

                            {isVehicleColorListVisible && (
                              <Col
                                className="filter-title-container-without-height"
                                lg="1"
                                xl="12"
                              >
                                <Row className="full-width-section-style">
                                  <ul className="custom-list">
                                    {vehicleColorlist?.map((item, index) => (
                                      <li
                                        className="custom-list-value-other"
                                        key={index}
                                        onClick={() =>
                                          !(
                                            selectedVehicleColor?.length > 1 &&
                                            !selectedVehicleColor?.includes(
                                              item
                                            )
                                          ) && handleVehicleColorSelection(item)
                                        }
                                      >
                                        <div className="align-items-checkbox-label">
                                          <span>
                                            <input
                                              type="checkbox"
                                              value={item}
                                              className="checkbox-style"
                                              checked={selectedVehicleColor?.includes(
                                                item
                                              )}
                                              disabled={
                                                selectedVehicleColor?.length >
                                                  1 &&
                                                !selectedVehicleColor?.includes(
                                                  item
                                                )
                                              }
                                            />
                                          </span>
                                          <div className="checkbox-style-label">
                                            {item}
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </Row>
                              </Col>
                            )}

                            <Col
                              className="customContainer"
                              lg="12"
                              xl="12"
                            ></Col>
                          </Col>
                        </Row>
                      )}
                      {/* Vehicle color sections ends */}

                      {/* Vehicle type sections starts */}
                      {(selectedEventType === 'Vehicle' ||
                        selectedEventType === null) && (
                        <Row>
                          <Col lg="12" xl="12">
                            <Col
                              className="filter-title-container-events"
                              lg="12"
                              xl="12"
                            >
                              <Row className="full-width-section-style">
                                <Col lg="10" xl="10">
                                  <p className="event-category-style">
                                    {constants.SEARCH_VEHICLE_TYPE}
                                  </p>
                                </Col>
                                <Col lg="2" xl="2">
                                  <span
                                    className="toggle-device-list"
                                    onClick={toggleVehicleTypeListVisibility}
                                  >
                                    {isVehicleTypeListVisible ? (
                                      <ChevronUp className="dark-theme-icon" />
                                    ) : (
                                      <ChevronDown className="dark-theme-icon" />
                                    )}
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                            {isVehicleTypeListVisible && (
                              <Col
                                className="filter-title-container-without-height"
                                lg="1"
                                xl="12"
                              >
                                <Row className="full-width-section-style">
                                  <ul className="custom-list">
                                    {vehicleTypelist?.map((item, index) => (
                                      <li
                                        className="custom-list-value-other"
                                        key={index}
                                        onClick={() =>
                                          handleVehicleTypeSelection(item)
                                        }
                                      >
                                        <div className="align-items-checkbox-label">
                                          <span>
                                            <input
                                              type="checkbox"
                                              value={item}
                                              className="checkbox-style"
                                              checked={selectedVehicleType?.includes(
                                                item
                                              )}
                                            />
                                          </span>
                                          <div className="checkbox-style-label">
                                            {item}
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </Row>
                              </Col>
                            )}

                            <Col
                              className="customContainer"
                              lg="12"
                              xl="12"
                            ></Col>
                          </Col>
                        </Row>
                      )}
                      {/* Vehicle type sections ends */}

                      {/* License plate sections starts */}
                      {(selectedEventType === 'License Plate' ||
                        selectedEventType === null) && (
                        <Row>
                          <Col lg="12" xl="12">
                            <Col
                              className="filter-title-container-events"
                              lg="12"
                              xl="12"
                            >
                              <Row className="full-width-section-style">
                                <Col lg="10" xl="10">
                                  <p className="event-category-style">
                                    {constants.EVENT_FILTER_LP}
                                  </p>
                                </Col>
                                <Col lg="2" xl="2">
                                  <span
                                    className="toggle-device-list"
                                    onClick={toggleLicensePlateListVisibility}
                                  >
                                    {isLicensePlateListVisible ? (
                                      <ChevronUp className="dark-theme-icon" />
                                    ) : (
                                      <ChevronDown className="dark-theme-icon" />
                                    )}
                                  </span>
                                </Col>
                              </Row>
                            </Col>

                            {isLicensePlateListVisible && (
                              <Col
                                className="search-box-style"
                                xs="12"
                                md="12"
                                lg="12"
                                xl="12"
                              >
                                <Row className="full-width-section-style">
                                  {smallScreenValue > 991 &&
                                  smallScreenValue < 1200 ? (
                                    <Col lg="12" xl="12" className='search-icon-parent-area'>
                                      <input
                                        type="text"
                                        className="location-search-style"
                                        placeholder="Search License"
                                        value={searchTermForLicense}
                                        onChange={(e) => {
                                          setEventTypeList(eventTypeListWithLP);
                                          setSelectedEventType('License Plate');
                                          searchTermForLicense = e.target.value;
                                          setSearchTermForLicense(
                                            e.target.value
                                          );
                                          if (e.target.value === '') {
                                            applyTag('License Plate');
                                            setEventTypeList(eventTypeListAll);
                                          }
                                        }}
                                        onKeyDown={(e) => {
                                          if (e.key === 'Enter') {
                                            e.preventDefault();
                                            if (e.target.value !== '') {
                                              applyTag('License Plate');
                                              setSelectedEventType(
                                                'License Plate'
                                              );
                                              setEventTypeList(
                                                eventTypeListWithLP
                                              );
                                            }
                                          }
                                        }}
                                      />
                                      <span>
                                        <RxMagnifyingGlass className="search-icon-license" />
                                      </span>
                                    </Col>
                                  ) : (
                                    <Col lg="11" xl="11" className='search-icon-parent-area'>
                                      <input
                                        type="text"
                                        className="location-search-style"
                                        placeholder="Search License"
                                        value={searchTermForLicense}
                                        onChange={(e) => {
                                          setEventTypeList(eventTypeListWithLP);
                                          setSelectedEventType('License Plate');
                                          searchTermForLicense = e.target.value;
                                          setSearchTermForLicense(
                                            e.target.value
                                          );
                                          if (e.target.value === '') {
                                            applyTag('License Plate');
                                            setEventTypeList(eventTypeListAll);
                                          }
                                        }}
                                        onKeyDown={(e) => {
                                          if (e.key === 'Enter') {
                                            e.preventDefault();
                                            if (e.target.value !== '') {
                                              applyTag('License Plate');
                                              setSelectedEventType(
                                                'License Plate'
                                              );
                                              setEventTypeList(
                                                eventTypeListWithLP
                                              );
                                            }
                                          }
                                        }}
                                      />
                                      <span>
                                        <RxMagnifyingGlass className="search-icon-license" />
                                      </span>
                                    </Col>
                                  )}
                                </Row>
                              </Col>
                            )}

                            <Col
                              className="customContainer"
                              lg="12"
                              xl="12"
                            ></Col>
                          </Col>
                        </Row>
                      )}
                      {/* License plate sections ends */}

                      {/* Sound type sections starts */}
                      {(selectedEventType === 'Audio' ||
                        selectedEventType === null) && (
                        <Row>
                          <Col lg="12" xl="12">
                            <Col
                              className="filter-title-container-events"
                              lg="12"
                              xl="12"
                            >
                              <Row className="full-width-section-style">
                                <Col lg="10" xl="10">
                                  <p className="event-category-style">
                                    {constants.SEARCH_SOUND_TYPE}
                                  </p>
                                </Col>
                                <Col lg="2" xl="2">
                                  <span
                                    className="toggle-device-list"
                                    onClick={toggleSoundTypeListVisibility}
                                  >
                                    {isSoundTypeListVisible ? (
                                      <ChevronUp className="dark-theme-icon" />
                                    ) : (
                                      <ChevronDown className="dark-theme-icon" />
                                    )}
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                            {isSoundTypeListVisible && (
                              <Col
                                className="filter-title-container-without-height"
                                lg="1"
                                xl="12"
                              >
                                <Row className="full-width-section-style">
                                  <ul className="custom-list">
                                    {soundTypelist?.map((item, index) => (
                                      <li
                                        className="custom-list-value-other"
                                        key={index}
                                        onClick={() =>
                                          handleSoundTypeSelection(item)
                                        }
                                      >
                                        <div className="align-items-checkbox-label">
                                          <span>
                                            <input
                                              type="checkbox"
                                              value={item}
                                              className="checkbox-style"
                                              checked={selectedSoundType?.includes(
                                                item
                                              )}
                                            />
                                          </span>
                                          <div className="checkbox-style-label">
                                            {item}
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </Row>
                              </Col>
                            )}

                            <Col
                              className="customContainer"
                              lg="12"
                              xl="12"
                            ></Col>
                          </Col>
                        </Row>
                      )}
                      {/* Sound type sections ends */}
                    </Col>
                  </Row>
                </Col>
              </Drawer>
            )}

          {open === false && !showEventPreviewModal && !showSearchModal && (
            <Drawer
              className="device-wall-drawer-closed"
              variant="permanent"
              open={true}
              onMouseEnter={() => setOpen(true)}
            >
              <Col xs="1" sm="1" lg="1" xl="1">
                <Row>
                  <Col
                    xs={1}
                    className="toggle-left-pabel-icon-closed"
                    onClick={handleClickOnLeftPanelToggle}
                  >
                    <HiArrowDownTray
                      className="device-wall-drawer-events-toggle-icon toggle-open"
                      size={'24'}
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_white')}
                    />
                  </Col>
                </Row>
              </Col>
            </Drawer>
          )}

          <Col lg={lgValue} xl={xlValue} className={className}>
            <Row className="justify-content-md-center">
              <Col lg="12" className="right-panel-container">
                <>
                  {/* search area starts */}
                  <div className="search-filter-category">
                    {windowWidth > 799 && (
                      <div className="custom-div">
                        <div className="all-result-text">
                          <span className="all-events-search-icon">
                            <SearchIconEvents />
                          </span>
                          {searchLabelText}
                        </div>
                        <div className="event-count">{`${
                          constants.SEARCH_TOTAL_COUNT_SHOWING
                        } ${
                          eventCount >= 10000
                            ? `${eventCount?.toLocaleString('en-US')}+`
                            : eventCount > 0
                            ? eventCount?.toLocaleString('en-US')
                            : 0
                        } ${constants.SEARCH_TOTAL_COUNT_RESULTS}`}</div>
                      </div>
                    )}

                    {windowWidth < 800 && (
                      <div className="custom-div">
                        <div className="all-result-text">
                          <Row>
                            <div className="small-margin">
                              <span className="all-events-search-icon">
                                <SearchIconEvents />
                              </span>
                              {searchLabelText}
                            </div>
                            <div className="event-count">
                              {`${constants.SEARCH_TOTAL_COUNT_SHOWING} ${
                                eventCount >= 10000
                                  ? `${eventCount?.toLocaleString('en-US')}+`
                                  : eventCount > 0
                                  ? eventCount?.toLocaleString('en-US')
                                  : 0
                              } ${constants.SEARCH_TOTAL_COUNT_RESULTS}`}
                            </div>
                          </Row>
                        </div>
                      </div>
                    )}

                    <div className="right-filter-box">
                      {windowWidth > (open ? 1059 : 874) && (
                        <div className="time-box">
                          {/* event date section */}
                          <div
                            className={`${
                              !showDateTimePicker
                                ? 'search-event-date'
                                : 'search-event-date-active'
                            }`}
                          >
                            <div className="event-date-section"
                             onClick={() => {
                              if (!showDateTimePicker) {
                                setShowDateTimePicker(true);
                                setShowTimePicker(false);
                                setShowSearchModal(false);
                              } else {
                                setShowDateTimePicker(false);
                              }
                            }}>
                              <span className="event-calendar">
                                <CalendarEventIcon />
                              </span>
                              <div className="event-date-inner-section">
                                <Row>
                                  <span className="date-placeholder-style">
                                    {constants.EVENT_DATE_PLACEHOLER}
                                  </span>
                                </Row>
                                <Row className="date-style" id="show-date">
                                  {showDateSelected() !== undefined && (
                                    <span>{showDateSelected()}</span>
                                  )}
                                  {showDateSelected() === undefined && (
                                    <span>{formatDate(yesterday)}</span>
                                  )}
                                </Row>
                              </div>
                            </div>
                            {showDateTimePicker && (
                              <div
                                className="date-picker-events"
                                ref={calendarRef}
                              >
                                <DateTimePickerEvents
                                  date={lastSelectedDateSubmit}
                                  // time={selectedTime}
                                  enableDarkTheme={true}
                                  selectedTimeItems={[
                                    startTimeToPass,
                                    endTimeToPass,
                                  ]}
                                  onValueChange={handleChildValue}
                                  onSubmit={handleDateChange}
                                />
                              </div>
                            )}
                          </div>
                          {/* event time section */}
                          <div
                            className={`${
                              !showTimePicker
                                ? 'search-event-time'
                                : 'search-event-time-active'
                            }`}
                          >
                            <div className="event-date-section" 
                             onClick={() => {
                              if (!showTimePicker) {
                                setShowTimePicker(true);
                                setShowDateTimePicker(false);
                                setShowSearchModal(false);
                              } else {
                                setShowTimePicker(false);
                              }
                            }}>
                              <span className="event-calendar">
                                <EventTimeClock />
                              </span>
                              <div className="event-date-inner-section">
                                <Row>
                                  <span className="date-placeholder-style">
                                    {constants.EVENT_TIME_PLACEHOLER}
                                  </span>
                                </Row>
                                <Row className="date-style">
                                  <span>
                                    {(startTimeOfEvent === '00:00:00'
                                      ? '12:00:00'
                                      : startTimeOfEvent) +
                                      ' ' +
                                      startTimePeriod +
                                      ' - ' +
                                      endTimeOfEvent +
                                      ' ' +
                                      endTimePeriod}
                                  </span>
                                </Row>
                              </div>
                            </div>
                            {showTimePicker && (
                              <div className="time-picker-events" ref={timePickerRef}>
                                <TimePickerEvents
                                  selectedDateTOShow={selectedDateTOShow}
                                  startTimeToPass={startTimeToPass}
                                  endTimeToPass={endTimeToPass}
                                  onValueChange={handleTimeValue}
                                  onSubmit={handleTimeChange}
                                />
                              </div>
                            )}
                          </div>
                          {/* ai search section */}
                          <div
                            className={
                              aiSearchLabel === 'Duclo AI Search'
                                ? 'search-ai-duclo'
                                : 'search-ai-oncloud'
                            }
                            onClick={() => {
                              if (showSearchModal) {
                                setShowSearchModal(false);
                              } else {
                                setShowSearchModal(true);
                                setShowDateTimePicker(false);
                                setShowTimePicker(false);
                              }
                            }}
                          >
                            <div className="event-date-section">
                              <span className="event-calendar">
                                <SearchAI />
                              </span>
                              <div className="event-ai-inner-section">
                                <div className="ai-placeholder-container">
                                  <div
                                    className={
                                      aiSearchLabel === 'Duclo AI Search'
                                        ? 'ai-placeholder-style-duclo'
                                        : 'ai-placeholder-style-oncloud'
                                    }
                                  >
                                    {aiSearchLabel}
                                  </div>
                                  <div className="date-style">
                                    <span className="discover-placeholder-style">
                                      {constants.EVENT_AI_SEARCH_DISCOVER}
                                    </span>
                                  </div>
                                </div>
                                <div className="beta-tag-style">
                                  <Tag />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {windowWidth > 705 && windowWidth < (open ? 1060 : 875) && (
                    <div className="search-filter-category">
                      <div className="right-filter-box">
                        <div className="time-box">
                          {/* event date section */}
                          <div
                            className={`${
                              !showDateTimePicker
                                ? 'search-event-date'
                                : 'search-event-date-active'
                            }`}
                          >
                            <div className="event-date-section"
                            onClick={() => {
                              if (!showDateTimePicker) {
                                setShowDateTimePicker(true);
                                setShowTimePicker(false);
                                setShowSearchModal(false);
                              } else {
                                setShowDateTimePicker(false);
                              }
                            }}>
                              <span className="event-calendar">
                                <CalendarEventIcon />
                              </span>
                              <div className="event-date-inner-section">
                                <Row>
                                  <span className="date-placeholder-style">
                                    {constants.EVENT_DATE_PLACEHOLER}
                                  </span>
                                </Row>
                                <Row className="date-style" id="show-date">
                                  {showDateSelected() !== undefined && (
                                    <span>{showDateSelected()}</span>
                                  )}
                                  {showDateSelected() === undefined && (
                                    <span>{formatDate(yesterday)}</span>
                                  )}
                                </Row>
                              </div>
                            </div>
                            {showDateTimePicker && (
                              <div
                                className="date-picker-events"
                                ref={calendarRef}
                              >
                                <DateTimePickerEvents
                                  date={lastSelectedDateSubmit}
                                  // time={selectedTime}
                                  enableDarkTheme={true}
                                  selectedTimeItems={[
                                    startTimeToPass,
                                    endTimeToPass,
                                  ]}
                                  onValueChange={handleChildValue}
                                  onSubmit={handleDateChange}
                                />
                              </div>
                            )}
                          </div>
                          {/* event time section */}
                          <div
                            className={`${
                              !showTimePicker
                                ? 'search-event-date'
                                : 'search-event-date-active'
                            }`}
                          >
                            <div className="event-date-section" 
                            onClick={() => {
                              if (!showTimePicker) {
                                setShowTimePicker(true);
                                setShowDateTimePicker(false);
                                setShowSearchModal(false);
                              } else {
                                setShowTimePicker(false);
                              }
                            }}>
                              <span className="event-calendar">
                                <EventTimeClock />
                              </span>
                              <div className="event-date-inner-section">
                                <Row>
                                  <span className="date-placeholder-style">
                                    {constants.EVENT_TIME_PLACEHOLER}
                                  </span>
                                </Row>
                                <Row className="date-style">
                                  <span>
                                    {(startTimeOfEvent === '00:00:00'
                                      ? '12:00:00'
                                      : startTimeOfEvent) +
                                      ' ' +
                                      startTimePeriod +
                                      ' - ' +
                                      endTimeOfEvent +
                                      ' ' +
                                      endTimePeriod}
                                  </span>
                                </Row>
                              </div>
                            </div>
                            {showTimePicker && (
                              <div className="time-picker-events" ref={timePickerRef}>
                                <TimePickerEvents
                                  selectedDateTOShow={selectedDateTOShow}
                                  startTimeToPass={startTimeToPass}
                                  endTimeToPass={endTimeToPass}
                                  onValueChange={handleTimeValue}
                                  onSubmit={handleTimeChange}
                                />
                              </div>
                            )}
                          </div>
                          {/* ai search section */}
                          <div
                            className={
                              aiSearchLabel === 'Duclo AI Search'
                                ? 'search-ai-duclo'
                                : 'search-ai-oncloud'
                            }
                            onClick={() => {
                              if (showSearchModal) {
                                setShowSearchModal(false);
                              } else {
                                setShowSearchModal(true);
                                setShowDateTimePicker(false);
                                setShowTimePicker(false);
                              }
                            }}
                          >
                            <div className="event-date-section">
                              <span className="event-calendar">
                                <SearchAI />
                              </span>
                              <div className="event-ai-inner-section">
                                <Row>
                                  <Col>
                                    <Row>
                                      <span
                                        className={
                                          aiSearchLabel === 'Duclo AI Search'
                                            ? 'ai-placeholder-style-duclo'
                                            : 'ai-placeholder-style-oncloud'
                                        }
                                      >
                                        {aiSearchLabel}
                                      </span>
                                    </Row>
                                    <Row className="date-style">
                                      <span className="discover-placeholder-style">
                                        {constants.EVENT_AI_SEARCH_DISCOVER}
                                      </span>
                                    </Row>
                                  </Col>
                                  <Col className="beta-tag-style">
                                    <Tag />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {windowWidth > 442 && windowWidth < 706 && (
                    <div className="search-filter-category">
                      <div className="right-filter-box">
                        <div className="time-box">
                          {/* event date section */}
                          <div
                            className={`${
                              !showDateTimePicker
                                ? 'search-event-date'
                                : 'search-event-date-active'
                            }`}
                          >
                            <div className="event-date-section" 
                            onClick={() => {
                              if (!showDateTimePicker) {
                                setShowDateTimePicker(true);
                                setShowTimePicker(false);
                                setShowSearchModal(false);
                              } else {
                                setShowDateTimePicker(false);
                              }
                            }}>
                              <span className="event-calendar">
                                <CalendarEventIcon />
                              </span>
                              <div className="event-date-inner-section">
                                <Row>
                                  <span className="date-placeholder-style">
                                    {constants.EVENT_DATE_PLACEHOLER}
                                  </span>
                                </Row>
                                <Row className="date-style" id="show-date">
                                  {showDateSelected() !== undefined && (
                                    <span>{showDateSelected()}</span>
                                  )}
                                  {showDateSelected() === undefined && (
                                    <span>{formatDate(yesterday)}</span>
                                  )}
                                </Row>
                              </div>
                            </div>
                            {showDateTimePicker && (
                              <div
                                className="date-picker-events"
                                ref={calendarRef}
                              >
                                <DateTimePickerEvents
                                  date={lastSelectedDateSubmit}
                                  // time={selectedTime}
                                  enableDarkTheme={true}
                                  selectedTimeItems={[
                                    startTimeToPass,
                                    endTimeToPass,
                                  ]}
                                  onValueChange={handleChildValue}
                                  onSubmit={handleDateChange}
                                />
                              </div>
                            )}
                          </div>
                          {/* event time section */}
                          <div
                            className={`${
                              !showTimePicker
                                ? 'search-event-date'
                                : 'search-event-date-active'
                            }`}
                          >
                            <div className="event-date-section" 
                              onClick={() => {
                              if (!showTimePicker) {
                                setShowTimePicker(true);
                                setShowDateTimePicker(false);
                                setShowSearchModal(false);
                              } else {
                                setShowTimePicker(false);
                              }
                            }}>
                              <span className="event-calendar">
                                <EventTimeClock />
                              </span>
                              <div className="event-date-inner-section">
                                <Row>
                                  <span className="date-placeholder-style">
                                    {constants.EVENT_TIME_PLACEHOLER}
                                  </span>
                                </Row>
                                <Row className="date-style">
                                  <span>
                                    {(startTimeOfEvent === '00:00:00'
                                      ? '12:00:00'
                                      : startTimeOfEvent) +
                                      ' ' +
                                      startTimePeriod +
                                      ' - ' +
                                      endTimeOfEvent +
                                      ' ' +
                                      endTimePeriod}
                                  </span>
                                </Row>
                              </div>
                            </div>
                            {showTimePicker && (
                              <div className="time-picker-events" ref={timePickerRef}>
                                <TimePickerEvents
                                  selectedDateTOShow={selectedDateTOShow}
                                  startTimeToPass={startTimeToPass}
                                  endTimeToPass={endTimeToPass}
                                  onValueChange={handleTimeValue}
                                  onSubmit={handleTimeChange}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="time-box">
                          {/* ai search section */}
                          <div
                            className={
                              aiSearchLabel === 'Duclo AI Search'
                                ? 'search-ai-duclo'
                                : 'search-ai-oncloud'
                            }
                            onClick={() => {
                              if (showSearchModal) {
                                setShowSearchModal(false);
                              } else {
                                setShowSearchModal(true);
                                setShowDateTimePicker(false);
                                setShowTimePicker(false);
                              }
                            }}
                          >
                            <div className="event-date-section">
                              <span className="event-calendar">
                                <SearchAI />
                              </span>
                              <div className="event-ai-inner-section">
                                <Row>
                                  <Col>
                                    <Row>
                                      <span
                                        className={
                                          aiSearchLabel === 'Duclo AI Search'
                                            ? 'ai-placeholder-style-duclo'
                                            : 'ai-placeholder-style-oncloud'
                                        }
                                      >
                                        {aiSearchLabel}
                                      </span>
                                    </Row>
                                    <Row className="date-style">
                                      <span className="discover-placeholder-style">
                                        {constants.EVENT_AI_SEARCH_DISCOVER}
                                      </span>
                                    </Row>
                                  </Col>
                                  <Col className="beta-tag-style">
                                    <Tag />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {windowWidth < 443 && (
                    <div className="search-filter-category">
                      <div className="right-filter-box">
                        <div className="time-box">
                          {/* event date section */}
                          <div
                            className={`${
                              !showDateTimePicker
                                ? 'search-event-date'
                                : 'search-event-date-active'
                            }`}
                          >
                            <div className="event-date-section" 
                            onClick={() => {
                              if (!showDateTimePicker) {
                                setShowDateTimePicker(true);
                                setShowTimePicker(false);
                                setShowSearchModal(false);
                              } else {
                                setShowDateTimePicker(false);
                              }
                            }}>
                              <span className="event-calendar">
                                <CalendarEventIcon />
                              </span>
                              <div className="event-date-inner-section">
                                <Row>
                                  <span className="date-placeholder-style">
                                    {constants.EVENT_DATE_PLACEHOLER}
                                  </span>
                                </Row>
                                <Row className="date-style" id="show-date">
                                  {showDateSelected() !== undefined && (
                                    <span>{showDateSelected()}</span>
                                  )}
                                  {showDateSelected() === undefined && (
                                    <span>{formatDate(yesterday)}</span>
                                  )}
                                </Row>
                              </div>
                            </div>
                            {showDateTimePicker && (
                              <div
                                className="date-picker-events"
                                ref={calendarRef}
                              >
                                <DateTimePickerEvents
                                  date={lastSelectedDateSubmit}
                                  // time={selectedTime}
                                  enableDarkTheme={true}
                                  selectedTimeItems={[
                                    startTimeToPass,
                                    endTimeToPass,
                                  ]}
                                  onValueChange={handleChildValue}
                                  onSubmit={handleDateChange}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="time-box">
                          {/* event time section */}
                          <div
                            className={`${
                              !showTimePicker
                                ? 'search-event-date'
                                : 'search-event-date-active'
                            }`}
                          >
                            <div className="event-date-section" 
                            onClick={() => {
                              if (!showTimePicker) {
                                setShowTimePicker(true);
                                setShowDateTimePicker(false);
                                setShowSearchModal(false);
                              } else {
                                setShowTimePicker(false);
                              }
                            }}>
                              <span className="event-calendar">
                                <EventTimeClock />
                              </span>
                              <div className="event-date-inner-section">
                                <Row>
                                  <span className="date-placeholder-style">
                                    {constants.EVENT_TIME_PLACEHOLER}
                                  </span>
                                </Row>
                                <Row className="date-style">
                                  <span>
                                    {(startTimeOfEvent === '00:00:00'
                                      ? '12:00:00'
                                      : startTimeOfEvent) +
                                      ' ' +
                                      startTimePeriod +
                                      ' - ' +
                                      endTimeOfEvent +
                                      ' ' +
                                      endTimePeriod}
                                  </span>
                                </Row>
                              </div>
                            </div>
                            {showTimePicker && (
                              <div className="time-picker-events" ref={timePickerRef}>
                                <TimePickerEvents
                                  selectedDateTOShow={selectedDateTOShow}
                                  startTimeToPass={startTimeToPass}
                                  endTimeToPass={endTimeToPass}
                                  onValueChange={handleTimeValue}
                                  onSubmit={handleTimeChange}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="time-box">
                          {/* ai search section */}
                          <div
                            className={
                              aiSearchLabel === 'Duclo AI Search'
                                ? 'search-ai-duclo'
                                : 'search-ai-oncloud'
                            }
                            onClick={() => {
                              if (showSearchModal) {
                                setShowSearchModal(false);
                              } else {
                                setShowSearchModal(true);
                                setShowDateTimePicker(false);
                                setShowTimePicker(false);
                              }
                            }}
                          >
                            <div className="event-date-section">
                              <span className="event-calendar">
                                <SearchAI />
                              </span>
                              <div className="event-ai-inner-section">
                                <Row>
                                  <Col>
                                    <Row>
                                      <span
                                        className={
                                          aiSearchLabel === 'Duclo AI Search'
                                            ? 'ai-placeholder-style-duclo'
                                            : 'ai-placeholder-style-oncloud'
                                        }
                                      >
                                        {aiSearchLabel}
                                      </span>
                                    </Row>
                                    <Row className="date-style">
                                      <span className="discover-placeholder-style">
                                        {constants.EVENT_AI_SEARCH_DISCOVER}
                                      </span>
                                    </Row>
                                  </Col>
                                  <Col className="beta-tag-style">
                                    <Tag />
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="custom-container-divider"></div>
                </>
                <div className="all-events-container">
                  {isLoadingEvents || getEventsLoading() ? (
                    <div className="position-relative">
                      <div className="loading-container">
                        <SiteSpinner height="50px" width="50px" />
                        <div className="mt-2 loading-text">
                          {constants.LOADING}
                        </div>
                      </div>
                    </div>
                  ) : eventsData && eventsData?.length > 0 ? (
                    <div className="scroll-container">
                      <InfiniteScroll
                        initialLoad={false}
                        // loadMore={loadMore}
                        hasMore={hasMoreItems}
                        loader={<div className="loader"></div>}
                        useWindow={false}
                        threshold={1000}
                      >
                        {eventsData && showItems(eventsData, records)}
                      </InfiniteScroll>
                    </div>
                  ) : (
                    <div className="no-data-container">
                      <div className='no-data-content'>
                        <div className="no-data-icon">
                          <RxMagnifyingGlass
                            className="search-icon"
                            // size={'32px'}
                            fill={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--greyscale_72')}
                          />
                        </div>
                        <div className="no-result">
                          {constants.SEARCH_DASHBOARD_RESULT_NOT_FOUND}
                        </div>
                        <div className="message-no-result">
                          {constants.EVENT_FILTER_NO_DATA_MESSAGE}
                        </div>
                        <div className="all-event-back-conatiner">
                          <button
                            className="clear-all-filter"
                            onClick={() => {
                              removeUrlParameter('searchtext');
                              setIsGenderListVisible(false);
                              setIsAgeListVisible(false);
                              setIsFaceAccListVisible(false);
                              setIsAccessoriesListVisible(false);
                              setIsPantColorListVisible(false);
                              setIsShirtColorListVisible(false);
                              setIsVehicleColorListVisible(false);
                              setIsVehicleTypeListVisible(false);
                              setIsLicensePlateListVisible(false);
                              setIsSoundTypeListVisible(false);
                              resetDateAndTime();
                            }}
                          >
                            {constants.EVENT_FILTER_CLEAR_ALL_FILTER}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {/* event pop up starts */}
      <SiteModal
        modalTitle=""
        showModal={showEventPreviewModal}
        hideModal={() => {
          sendPauseCVR(modalContent?.device?.[0]?.deviceId);
          disconnectWithWebSocket();
          setShowEventPreviewModal(false);
        }}
        size="lg"
        classes="event-preview-modal"
      >
        {modalContent && modalContent?.device?.length && (
          <EventTimelineControls
            eventTimestamp={modalContent?.timestamp}
            timezone={modalContent?.device[0]?.timezone}
            deviceId={modalContent?.device[0]?.deviceId}
            onNextPreviousClick={onNextPreviousClick}
            modalContent={modalContent}
            indexContent={indexContent}
            onCameraDetailsClick={onCameraDetailsClick}
            platformDetail={getPlatformDetails}
          />
        )}
      </SiteModal>
      {/* event pop up ends */}
      {/* AI search pop up starts */}
      <SearchAiModal
        modalTitle=""
        showModal={showSearchModal}
        hideModal={(e) => {
          if (e === true) {
            setShowSearchModal(false);
          } else {
            setShowSearchModal(false);
            onKeyDownChange(e);
          }
        }}
        isMic={false}
        searchModal={showSearchModal}
        custOrgData={custOrgData}
        aiSearchLabel={aiSearchLabel}
      />
      {/* AI search pop up ends */}
    </div>
  );
};

export default Events;
