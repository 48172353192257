import { useRef, useEffect } from 'react';

const LocalVideoView = ({ remoteStream, bitmapObject }) => {
  const remoteVideoRef = useRef();
  const canvasVideoRef = useRef();
  const bitmapObjectHeight = bitmapObject ? bitmapObject?.StreamHeight : 450;
  const bitmapObjectWidth = bitmapObject ? bitmapObject?.StreamWidth : 764;

  useEffect(() => {
    if (remoteStream) {
      const remoteVideo = remoteVideoRef.current;
      const canvasVideo = canvasVideoRef.current;
      // remoteVideo.srcObject = remoteStream;
      if ('srcObject' in remoteVideo) {
        remoteVideo.srcObject = remoteStream;
      } else {
        // Avoid using this in new browsers, as it is going away.
        remoteVideo.src = URL.createObjectURL(remoteStream);
      }
      canvasVideo.srcObject = remoteStream;
    }
  }, [remoteStream]);

  return (
    <div className="remote-view-wrapper">
      <video
        id="video"
        // width="764"
        // height="450"
        ref={remoteVideoRef}
        autoPlay={true}
        playsInline={true}
        muted={true}
      />
      <canvas
        id="canvas"
        width={bitmapObjectWidth}
        height={bitmapObjectHeight}
        ref={canvasVideoRef}
        style={{ display: 'none' }}
      ></canvas>
    </div>
  );
};

export default LocalVideoView;
