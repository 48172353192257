import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Container, Col, Row } from 'react-bootstrap';
import axios from 'axios';
import {
  BreadcrumbList,
  Header,
  PrimaryButton,
  SiteSpinner,
} from '../../components/common';

import './notificationdashboard.scss';
import { AppDefaults, Utils, constants } from '../../helpers';
import InfiniteScroll from 'react-infinite-scroller';
import { HiOutlineBell } from 'react-icons/hi';
import { BsFillPeopleFill } from 'react-icons/bs';
import brandLogo from '../../assets/images/BrandFavicon.svg';
import brandLogoMegatron from '../../assets/images/megatronFavicon.png'
import { FaCarSide } from 'react-icons/fa';
import { useOrganizations } from '../../store/OrganizationsStore';
import moment from 'moment';
import useEventsStore from '../../store/EventsStore';
import StickyBox from 'react-sticky-box';
import motion from '../../assets/images/motion-notification.svg';
import { icontick,posComplete,posFailed,posInProgress } from '../../assets/images';
import { PiWarning } from 'react-icons/pi';
import {
  TbDeviceComputerCameraOff,
  TbDeviceComputerCamera,
} from 'react-icons/tb';
import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { IncidentTypeEnum } from '../../helpers/enums';
import { HiOutlineFolderOpen } from 'react-icons/hi2';
import { RxCross1 } from 'react-icons/rx';
import { IoMdCheckmark } from 'react-icons/io';
import { PiWarningCircleBold  } from 'react-icons/pi';

const NotificationDashboard = () => {
  const navigate = useNavigate();
  const getCustomerOrgData = useOrganizations(
    (state) => state.getCustomerOrgData
  );
  let custOrg = getCustomerOrgData();
  const custOrgData = custOrg[0];
  const [hasMoreItems, sethasMoreItems] = useState(true);
  //Todo Delete Later Empty List
  const [notificationData, setNotificationData] = useState([]);
  const itemsPerPage = 20;
  const [showLoader, setShowLoader] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(-1);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const { setSelectedEventStore, setEventFromSearch, setEventCVRMode } =
    useEventsStore();
  const breadList = [
    { url: '/notificationdashboard.html', title: constants.NOTIFICATION_TITLE },
  ];
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const customerOrgData = useOrganizations((state) => state.getCustomerOrgData);
  const allOrgData = customerOrgData();
  const { getSelectedLocation } = useCustomerOrgLocations();
  const selectedLocation = getSelectedLocation(loggedInUserData?.accountId);

  //TODO Update Later notification api call.
  useEffect(() => {
    setShowLoader(true);
    getNotificationData();
  }, [selectedLocation]);

  const timeToexpire = (expireToBe) => {
    const now = new Date().getTime(); //1698383540000
    const futureDate = new Date(expireToBe).getTime();
    const timeleft = futureDate - now;

    // convert milliseconds to seconds / minutes / hours etc.
    const msPerSecond = 1000;
    const msPerMinute = msPerSecond * 60;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;

    // calculate remaining time
    const days = Math.floor(timeleft / msPerDay);
    const hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / msPerHour);
    const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / msPerMinute);
    const seconds = Math.floor((timeleft % (1000 * 60)) / msPerSecond);
    let timeLeft = { time: 0, timeLabel: '' };
    if (days > 0) {
      const totalHour = hours > 0 ? days * 24 + hours : days * 24;
      timeLeft['time'] = totalHour;
      timeLeft['timeLabel'] = 'hours';
    } else if (hours > 0) {
      timeLeft['time'] = hours;
      timeLeft['timeLabel'] = 'hours';
    } else if (minutes > 0) {
      timeLeft['time'] = minutes;
      timeLeft['timeLabel'] = 'minutes';
    } else if (seconds > 0) {
      timeLeft['time'] = seconds;
      timeLeft['timeLabel'] = 'seconds';
    }
    return timeLeft;
  };

  const getDeviceLocationName = (device, isForDevice) => {
    let deviceLocation = 'N/A';
    const deviceData = custOrgData?.devices.find(
      (item) => item.deviceId === device?.deviceId
    );
    if (deviceData) {
      if (isForDevice) {
        deviceLocation = deviceData?.deviceName;
      } else {
        const location = custOrgData?.locations.find(
          (item) => item.locationId === deviceData?.locationId
        );
        if (location) {
          const areas = location?.areas.find(
            (item) => item.locationId === location?.locationId
          );
          if (areas) {
            deviceLocation = `${areas?.areaName} - ${location?.locationName}`;
          }
        }
      }
    }
    if (
      device?.eventType === IncidentTypeEnum?.INCIDENT_VIDEO_CLIP ||
      device?.eventType === IncidentTypeEnum?.INCIDENT_ZIP_CLIP
    ) {
      if (isForDevice) {
        deviceLocation = device?.title ? device?.title : 'N/A';
      }
    }
    if (
      isForDevice &&
      deviceLocation === 'N/A' &&
      device?.title != null &&
      device?.title != undefined
    ) {
      deviceLocation = device?.title;
    } else if (!isForDevice && deviceLocation === 'N/A') {
      const location = custOrgData?.locations?.find(
        (item) => item?.locationId === device?.locationId
      );
      if (location) {
        const areas = location?.areas.find(
          (item) => item?.locationId === location?.locationId
        );
        if (areas) {
          deviceLocation = `${areas?.areaName} - ${location?.locationName}`;
        }
      }
    }
    return deviceLocation;
  };

  const getNotificationData = async () => {
    let response = await axios.get(
      `partner/orgs/${
        custOrgData?.orgId
      }/notifications?endTime=${new Date().getTime()}&size=${itemsPerPage}${
        selectedLocation &&
        selectedLocation != undefined &&
        Object.keys(selectedLocation).length > 0 &&
        selectedLocation?.locationId !== AppDefaults.ALL_LOCATIONS_ID
          ? `&locationId=${selectedLocation?.locationId}`
          : ''
      }`,
      Utils.requestHeader()
    );
    const responseData = response?.data;
    if (responseData?.meta?.code === 200) {
      setNotificationData(responseData?.data);
      setShowLoader(false);
    } else {
      setShowLoader(false);
    }
  };
  
  const loadMore = async () => {
    if (
      notificationData[notificationData.length - 1]?.eventTime <
      Utils.getUnixDate(moment(new Date()).subtract({ days: 30 })) * 1000
    ) {
      sethasMoreItems(false);
    } else {
      fetchRecords(notificationData[notificationData.length - 1]?.eventTime);
    }
  };

  const fetchRecords = async (endTime) => {
    let response = await axios.get(
      `partner/orgs/${
        custOrgData?.orgId
      }/notifications?endTime=${endTime}&size=${itemsPerPage}${
        selectedLocation &&
        selectedLocation != undefined &&
        Object.keys(selectedLocation).length > 0 &&
        selectedLocation?.locationId !== AppDefaults.ALL_LOCATIONS_ID
          ? `&locationId=${selectedLocation?.locationId}`
          : ''
      }`,
      Utils.requestHeader()
    );
    const responseData = response?.data;
    if (responseData?.meta?.code === 200) {
      const newNotificationData = response?.data?.data;
      if (
        newNotificationData[newNotificationData?.length - 1]?.eventTime ===
        notificationData[notificationData.length - 1]?.eventTime
      ) {
        return;
      } else {
        setNotificationData([...notificationData, ...response?.data?.data]);
      }
    }
  };

  const gotoCameraDetail = async (data) => {
    const param = {
      notificationIds: [data?.notificationId],
    };
    let response = await axios.put(
      `partner/orgs/${custOrgData?.orgId}/notifications/status/read`,
      param,
      Utils.requestHeader()
    );    

    if (data?.eventType === IncidentTypeEnum?.INCIDENT_VIDEO_CLIP) {
      navigate(
        `/incidents/details.html?incidentId=${data?.incidentId}&activeTab=1`
      );
    } else if (data?.eventType === IncidentTypeEnum?.INCIDENT_ZIP_CLIP) {
      // navigate(`/incidents/details.html?incidentId=${data?.incidentId}&activeTab=1`);
    } 
    else if (
      (data?.eventType === AppDefaults?.POS_NOTIFICATION_STATUS_SUCCESS) ||
      (data?.eventType === AppDefaults?.POS_NOTIFICATION_STATUS_PARTIALLY) ||
      (data?.eventType === AppDefaults?.POS_NOTIFICATION_STATUS_FAILED)
    ) {
      const getOrgDetails = allOrgData?.find(
        (item) => item?.orgId === data?.orgId
      );
      if (getOrgDetails) {
        const policyOrg = getOrgDetails?.policies?.find(
          (item) => item?.policyName === AppDefaults?.POS_POLICY_ADD_UPDATE_MAPPING
        )?.policyName;
        if (policyOrg) {
          navigate(`/app/pos?orgId=${data?.orgId}`);
        }
      }
    }
    else if (
      data?.eventType !== Utils.getNotificationEventName().cameraOnline &&
      data?.eventType !== Utils.getNotificationEventName().cameraOffline &&
      data?.eventType !== IncidentTypeEnum?.INCIDENT_ZIP_CLIP &&
      data?.eventType !== IncidentTypeEnum?.INCIDENT_VIDEO_CLIP &&
      data?.eventType !== 0 && data?.eventType !== "0" &&
      data?.eventType !== 0 && data?.eventType !== "camera-add"
    ) {
      setSelectedEventStore(data?.eventTime);
      setEventFromSearch(true);
      setEventCVRMode(true);
      navigate(`/cameras/dashboard.html`, {
        state: {
          id: data?.deviceId,
        },
      });
    }
  };

  const formattedDate = (time) => {
    if (time === moment(new Date()).format('MMM DD, YYYY')) {
      return 'Today';
    } else if (
      time === moment(new Date()).subtract({ days: 1 }).format('MMM DD, YYYY')
    ) {
      return 'Yesterday';
    } else {
      return time;
    }
  };
  const getActualDate = (date) => {
    const time = Utils.getDate(date / 1000);
    const actualTime = moment(time).format('MMM DD, YYYY');
    return actualTime;
  };

  const massagedData = (data) => {
    let finalArray = [];
    data.map((event) => {
      const time = getActualDate(event?.eventTime);
      const index = finalArray.findIndex((e) => e?.eventTime === time);
      if (index > -1) {
        finalArray[index]?.notificationList?.push({ ...event });
      } else {
        let eventObject = {
          eventTime: '',
          notificationList: [],
        };
        eventObject.eventTime = time;
        eventObject.notificationList?.push(event);
        finalArray.push(eventObject);
      }
    });
    return finalArray;
  };

  const getEventDetectedDetail = (eventType) => {
    switch (eventType) {
      case Utils.getNotificationEventName().person:
        return constants.NOTIFICATION_MESSAGE_DETECTED_PERSON;
      case Utils.getNotificationEventName().vehicle:
        return constants.NOTIFICATION_MESSAGE_DETECTED_VEHICLE;
      case Utils.getNotificationEventName().cameraOnline:
        return constants.NOTIFICATION_MESSAGE_CAMERA_ONLINE;
      case Utils.getNotificationEventName().cameraOffline:
        return constants.NOTIFICATION_MESSAGE_CAMERA_OFFLINE;
      case Utils.getNotificationEventName().glassBreaking:
        return constants.NOTIFICATION_MESSAGE_DETECTED_PERSON;
      case Utils.getNotificationEventName().tamperStart:
        return constants.NOTIFICATION_MESSAGE_DETECTED_TAMPERING;
      case Utils.getNotificationEventName().loitering:
        return constants.NOTIFICATION_MESSAGE_DETECTED_LOITERING;
      case Utils.getNotificationEventName().scream:
        return constants.NOTIFICATION_MESSAGE_DETECTED_AUDIO;
      case Utils.getNotificationEventName().explosion:
        return constants.NOTIFICATION_MESSAGE_DETECTED_AUDIO;
      case Utils.getNotificationEventName().glassBreaking:
        return constants.NOTIFICATION_MESSAGE_DETECTED_AUDIO;
      case Utils.getNotificationEventName().gunShot:
        return constants.NOTIFICATION_MESSAGE_DETECTED_AUDIO;
      case Utils.getNotificationEventName().motionStart:
        return constants.NOTIFICATION_MESSAGE_DETECTED_MOTION;
      case Utils.getNotificationEventName().motionEnd:
        return constants.NOTIFICATION_MESSAGE_DETECTED_MOTION;
      default:
        return eventType;
    }
  };

  const downloadLoadLink = async (e, data, index) => {
    const splitTitle = data?.title.split(':');
    const incidentName =
      splitTitle && splitTitle[1]
        ? `${splitTitle[1]}-${new Date()}`
        : `Incident-Zip-${new Date()}`;
    setCurrentPosition(data?.eventTime);
    setDownloadLoader(true);
    let response = await axios.get(
      `incident/orgs/${data?.orgId}/incidents/${data?.incidentId}/bundle/${data?.incidentBundleId}`,
      Utils.requestHeader()
    );
    const responseData = response?.data;
    if (responseData?.meta?.code === 200) {
      setDownloadLoader(false);
      setCurrentPosition(-1);
      fetch(responseData?.data?.url)
        // check to make sure you didn't have an unexpected failure (may need to check other things here depending on use case / backend)
        .then((resp) =>
          resp.status === 200
            ? resp.blob()
            : Promise.reject('something went wrong')
        )
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          // the filename you want
          a.download = `${incidentName}.zip`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        });
    } else {
      setDownloadLoader(false);
      setCurrentPosition(-1);
    }
  };

  const isValidUrl = (url) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {return true};
      img.onerror = () => {return false};
  };

  const showItems = (posts) => {
    const dataItems = massagedData(posts);
    if (dataItems.length > 0) {
      return (
        <div className="list-item w-100">
          {dataItems?.map((dataNoti, i) => {
            return (
              <div key={dataNoti?.eventTime}>
                <StickyBox offsetTop={0}>
                  <div className="sticky-header">
                    <div className="header">
                      {dataNoti?.eventTime !== 'Invalid date'
                        ? formattedDate(dataNoti?.eventTime)
                        : ''}
                    </div>
                  </div>
                </StickyBox>
                {dataNoti?.notificationList?.map((data, i) => (
                  <div
                    onClick={() => gotoCameraDetail(data)}
                    key={data?.notificationId + i}
                    className="row-container"
                  >
                    <div className="event-conatiner">
                      <div
                        className={`image-layout ${
                          data?.eventType ==
                            IncidentTypeEnum?.INCIDENT_ZIP_CLIP &&
                          timeToexpire(data?.expire)?.time > 0
                            ? ' camera-online'
                            : data?.eventType ==
                                IncidentTypeEnum?.INCIDENT_ZIP_CLIP &&
                              timeToexpire(data?.expire)?.time <= 0
                            ? ' camera-offline'
                            : data?.eventType ==
                                IncidentTypeEnum?.INCIDENT_VIDEO_CLIP &&
                              timeToexpire(data?.expire)?.time > 0
                            ? ' camera-online'
                            : data?.eventType ==
                                IncidentTypeEnum?.INCIDENT_VIDEO_CLIP &&
                              timeToexpire(data?.expire)?.time <= 0
                            ? ' link-expire'
                            : data?.eventType ==
                              Utils.getNotificationEventName().cameraOnline
                            ? ' camera-online'
                            : data?.eventType ==
                              AppDefaults?.POS_NOTIFICATION_STATUS_FAILED
                            ? ' pos-fail'
                            : data?.eventType ==
                              AppDefaults?.POS_NOTIFICATION_STATUS_SUCCESS
                            ? ' pos-complete'
                            : data?.eventType ==
                              AppDefaults?.POS_NOTIFICATION_STATUS_PARTIALLY
                            ? ' pos-partially'
                            : data?.eventType ==
                                Utils.getNotificationEventName()
                                  .cameraOffline && ' camera-offline'
                        }`}
                      >
                        {data?.eventType ==
                        AppDefaults?.POS_NOTIFICATION_STATUS_FAILED ? (
                          <img
                            className={`pos-status-img`}
                            src={posFailed}
                            alt=""
                          ></img>
                        ) : data?.eventType ==
                          AppDefaults?.POS_NOTIFICATION_STATUS_SUCCESS ? (
                          <img
                            className={`pos-status-img`}
                            src={posComplete}
                            alt=""
                          ></img>
                        ) : data?.eventType ==
                          AppDefaults?.POS_NOTIFICATION_STATUS_PARTIALLY ? (
                          <img
                            className={`pos-status-img`}
                            src={posInProgress}
                            alt=""
                          ></img>
                        ) : data?.eventType ==
                          Utils.getNotificationEventName().cameraOnline ? (
                          <TbDeviceComputerCamera size={30} />
                        ) : data?.eventType ==
                          Utils.getNotificationEventName().cameraOffline ? (
                          <TbDeviceComputerCameraOff
                            color={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--brand_white')}
                            size={30}
                          />
                        ) : data?.eventType ==
                            IncidentTypeEnum?.INCIDENT_VIDEO_CLIP &&
                          timeToexpire(data?.expire)?.time > 0 ? (
                          <HiOutlineFolderOpen
                            color={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--success_24')}
                            size={30}
                          />
                        ) : data?.eventType ==
                            IncidentTypeEnum?.INCIDENT_VIDEO_CLIP &&
                          timeToexpire(data?.expire)?.time <= 0 ? (
                          <HiOutlineFolderOpen
                            color={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--warning_24')}
                            size={30}
                          />
                        ) : data?.eventType ==
                            IncidentTypeEnum?.INCIDENT_ZIP_CLIP &&
                          timeToexpire(data?.expire)?.time > 0 ? (
                          <HiOutlineFolderOpen
                            color={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--success_24')}
                            size={30}
                          />
                        ) : data?.eventType ==
                            IncidentTypeEnum?.INCIDENT_ZIP_CLIP &&
                          timeToexpire(data?.expire)?.time <= 0 ? (
                          <HiOutlineFolderOpen
                            color={getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--error_48')}
                            size={30}
                          />
                        ) :
                        process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON ?
                        (
                          isValidUrl(data?.imgUrl) ? (
                            <img src={data?.imgUrl} alt="" className={`${data?.imgUrl ? 'image-notification' : 'image-notification-mt'}`}/>
                          ) : (
                            <img src={brandLogoMegatron} alt="" className={`${data?.imgUrl ? 'image-notification' : 'image-notification-mt'}`}/>
                          )
                        )
                        :
                        (
                          isValidUrl(data?.imgUrl) ? (
                            <img src={data?.imgUrl} alt="" className="image-notification"/>
                          ) : (
                            <img src={brandLogo} alt="" className="image-notification"/>
                          )
                        )}
                      </div>
                      <div className="type-layout">
                        <div
                          className={`bottom-noti-type ${
                            data?.eventType ==
                              IncidentTypeEnum?.INCIDENT_VIDEO_CLIP &&
                            timeToexpire(data?.expire)?.time > 0
                              ? ' camera-online'
                              : data?.eventType ==
                                  IncidentTypeEnum?.INCIDENT_VIDEO_CLIP &&
                                timeToexpire(data?.expire)?.time <= 0
                              ? ' link-expire'
                              : data?.eventType ==
                                  IncidentTypeEnum?.INCIDENT_ZIP_CLIP &&
                                timeToexpire(data?.expire)?.time > 0
                              ? ' camera-online'
                              : data?.eventType ==
                                  IncidentTypeEnum?.INCIDENT_ZIP_CLIP &&
                                timeToexpire(data?.expire)?.time <= 0
                              ? ' camera-offline'
                              : data?.eventType ==
                                Utils.getNotificationEventName().cameraOnline
                              ? ' camera-online'
                              : data?.eventType ==
                                AppDefaults?.POS_NOTIFICATION_STATUS_FAILED
                              ? ' pos-fail'
                              : data?.eventType ==
                                AppDefaults?.POS_NOTIFICATION_STATUS_SUCCESS
                              ? ' pos-complete'
                              : data?.eventType ==
                                AppDefaults?.POS_NOTIFICATION_STATUS_PARTIALLY
                              ? ' pos-inprogress'
                              : data?.eventType ==
                                  Utils.getNotificationEventName()
                                    .cameraOffline && ' camera-offline'
                          }`}
                        >
                          {data?.eventType ==
                          Utils.getNotificationEventName().vehicle ? (
                            <FaCarSide className="icon-person" />
                          ) : data?.eventType ==
                            Utils.getNotificationEventName().person ? (
                            <BsFillPeopleFill
                              className="icon-person"
                              color={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--primary_32')}
                            />
                          ) : data?.eventType ==
                            Utils.getNotificationEventName().motionStart ? (
                            <img src={motion} className="icon-person"></img>
                          ) : data?.eventType ==
                            Utils.getNotificationEventName().motionEnd ? (
                            <img src={motion} className="icon-person"></img>
                          ) : data?.eventType ==
                            Utils.getNotificationEventName().cameraOnline ? (
                            <img src={icontick} className="icon-events"></img>
                          ) : data?.eventType ==
                            Utils.getNotificationEventName().cameraOffline ? (
                            <PiWarning
                              color={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--error_48')}
                            />
                          ) : data?.eventType ==
                              IncidentTypeEnum?.INCIDENT_VIDEO_CLIP &&
                            timeToexpire(data?.expire)?.time > 0 ? (
                            <img src={icontick} className="icon-events"></img>
                          ) : data?.eventType ==
                              IncidentTypeEnum?.INCIDENT_VIDEO_CLIP &&
                            timeToexpire(data?.expire)?.time <= 0 ? (
                            <PiWarning
                              color={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--error_24')}
                            />
                          ) : data?.eventType ==
                              IncidentTypeEnum?.INCIDENT_ZIP_CLIP &&
                            timeToexpire(data?.expire)?.time > 0 ? (
                            <img src={icontick} className="icon-events"></img>
                          ) : data?.eventType ==
                              IncidentTypeEnum?.INCIDENT_ZIP_CLIP &&
                            timeToexpire(data?.expire)?.time <= 0 ? (
                            <PiWarning
                              color={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--error_48')}
                            />
                          ) : data?.eventType ==
                            AppDefaults?.POS_NOTIFICATION_STATUS_FAILED ? (
                            <RxCross1
                              strokeWidth="2px"
                              color={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--error_24')}
                              size={16}
                            ></RxCross1>
                          ) : data?.eventType ==
                            AppDefaults?.POS_NOTIFICATION_STATUS_SUCCESS ? (
                            <IoMdCheckmark
                              strokeWidth="2px"
                              color={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--success_24')}
                              size={16}
                            ></IoMdCheckmark>
                          ) : data?.eventType ==
                            AppDefaults?.POS_NOTIFICATION_STATUS_PARTIALLY ? (
                            <PiWarningCircleBold
                              strokeWidth="2px"
                              color={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--warning_24')}
                              size={18}
                            ></PiWarningCircleBold>
                          ) : (
                            <TbDeviceComputerCamera size={30} />
                          )}
                        </div>
                      </div>

                      <div className="notification-text-layout">
                        <div className="header-text">
                          {getDeviceLocationName(data, true)}
                        </div>
                        {data?.eventType ===
                          IncidentTypeEnum.INCIDENT_VIDEO_CLIP &&
                        timeToexpire(data?.expire)?.time > 0 ? (
                          <div className="download-container">
                            <div className="link-expire">
                              {`(${constants.LINK_EXPIRE_IN} ${
                                timeToexpire(data?.expire)?.time
                              } ${timeToexpire(data?.expire)?.timeLabel})`}
                            </div>
                          </div>
                        ) : data?.eventType ===
                            IncidentTypeEnum.INCIDENT_VIDEO_CLIP &&
                          timeToexpire(data?.expire)?.time <= 0 ? (
                          <div className="download-container">
                            <div className="link-expire">
                              {constants.DOWNLOAD_LINK_EXPIRE}
                            </div>
                          </div>
                        ) : data?.eventType ===
                            IncidentTypeEnum.INCIDENT_ZIP_CLIP &&
                          timeToexpire(data?.expire)?.time > 0 ? (
                          <div className="download-container">
                            {downloadLoader &&
                              currentPosition === data?.eventTime && (
                                <div className="loader">
                                  <SiteSpinner
                                    height="40px"
                                    width="40px"
                                  ></SiteSpinner>
                                </div>
                              )}
                            <div
                              onClick={(e) => downloadLoadLink(e, data, i)}
                              className="download-text"
                            >
                              {constants.DOWNLOAD_NOW_LABEL}
                            </div>
                            <div className="link-expire">
                              {`(${constants.LINK_EXPIRE_IN} ${
                                timeToexpire(data?.expire)?.time
                              } ${timeToexpire(data?.expire)?.timeLabel})`}
                            </div>
                          </div>
                        ) : data?.eventType ===
                            IncidentTypeEnum.INCIDENT_ZIP_CLIP &&
                          timeToexpire(data?.expire)?.time <= 0 ? (
                          <div className="download-container">
                            <div className="link-expire">
                              {constants.DOWNLOAD_LINK_EXPIRE}
                            </div>
                          </div>
                        ) :
                          data?.eventType ===
                            AppDefaults.POS_NOTIFICATION_STATUS_SUCCESS ||
                          data?.eventType ===
                            AppDefaults.POS_NOTIFICATION_STATUS_PARTIALLY ? (
                          data?.recordsUploaded && (
                            <div className="title-text">{`${data?.recordsUploaded}  ${data?.totalRecords ? ` of ${data?.totalRecords} ${constants.POS_RECORDS_UPLOADED}`  : '' }`}</div>
                          )
                        ) :
                        data?.eventType ===
                            AppDefaults.POS_NOTIFICATION_STATUS_FAILED ?
                            data?.errorMessage && (
                              <div className="title-text">{`${data?.errorMessage!="" ?  data?.errorMessage: '' }`}</div>
                            )
                          : (
                          <div>
                            <div className="title-text">
                              {getDeviceLocationName(data, false)}
                            </div>
                            <div className="title-sub-text">
                              {getEventDetectedDetail(data?.eventType)}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="date-read-layout">
                      <div className="date-text">
                        {moment
                          .tz(moment(data?.eventTime), moment.tz.guess())
                          .format('hh:mm:ss A')}
                      </div>
                      {data?.notificationStatus === 0 && (
                        <div className="red-circle"></div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      );
    }
  };

  return (
    <div className="App">
      <Header />
      {showLoader ? (
            <div className='position-absolute top-50' style={{left: '47%'}}>
                <SiteSpinner height="50px" width="50px" />
                <div className='mt-2 text-dark'>{constants.LOADING}</div>
            </div>
      ) : (
        <Container className='mw-100'>
          <Row className="justify-content-md-center mt-5">
            <Col lg="12">
              <div className="page-header">
                <Container className='mw-100'>
                  <Row>
                    <Col className="text-start">
                      <BreadcrumbList list={breadList} />
                    </Col>
                    <Col
                      md={6}
                      lg={6}
                      xl={6}
                      xs={12}
                      style={{ marginTop: -15 }}
                      className="text-end page-header-buttons"
                    >
                      {
                        <PrimaryButton
                          className="btn btn-primary-outline"
                          type="button"
                          width="auto"
                          borderWidth="1.5px"
                          hoverBorderWidth="1.5px"
                          color={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--primary_40')}
                          hoverColor={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--brand_white')}
                          backgroundColor="transparent"
                          onClick={() => navigate('/notificationsetting.html')}
                        >
                          {constants.NOTIFICATION_SETTINGS}
                        </PrimaryButton>
                      }
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="noti-dashboard-container">
                <div className="notification-container">
                  {notificationData && notificationData.length > 0 ? (
                    <div className="noti-item-data scroll-container">
                      <InfiniteScroll
                        loadMore={loadMore}
                        hasMore={hasMoreItems}
                        loader={<div className="loader"></div>}
                        useWindow={false}
                        threshold={1000}
                      >
                        {notificationData && showItems(notificationData)}
                      </InfiniteScroll>
                    </div>
                  ) : (
                    <div className="no-data-container-screen">
                      <HiOutlineBell
                        size={50}
                        color={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--grayscale_32')}
                      />
                      <div className="no-result">
                        {constants.NOTIFICATION_NO_DATA_TITLE}
                      </div>
                      <div className="message-no-result">
                        {constants.NOTIFICATION_SETUP_NEW}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default NotificationDashboard;
