import { useRef } from 'react';
import { Formik, Form } from 'formik';
import { Utils, constants } from '../../../../helpers';
import { PrimaryButton } from '../../../../components/common';
import DummyImageIcon from '../../../../assets/images/dummy-snapshot.svg';
import { useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedOrganization } from '../../../../store/reducers/AccountReducer';
import {
  getDevicesListOfCurrOrg,
  setDevicesListOfCurrOrg,
} from '../../../../store/reducers/NVRDeviceReducer';

const ReClaimDeviceControl = ({
  selectedDevice,
  deviceSelectHandler,
  selectedDeviceLoaderHandler,
  errorMsgHandler,
}) => {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const orgDetails = useSelector(getSelectedOrganization);
  const devicesListOfCurrOrg = useSelector(getDevicesListOfCurrOrg);

  useEffect(() => {
    formikRef?.current?.resetForm();
  }, [selectedDevice?.channel]);

  const handleReClaimDevice = () => {
    reclaimApiCall();
  };

  const reclaimApiCall = async () => {
    try {
      errorMsgHandler('');
      selectedDeviceLoaderHandler(true);
      const res = await axios.put(
        `/device/orgs/${orgDetails?.orgId}/devices/${selectedDevice.deviceId}/reclaim`,
        {},
        Utils.requestHeader()
      );
      if (res?.data?.meta?.code === 200) {
        const data = res?.data?.data;
        const updatedDevices = devicesListOfCurrOrg.map((item) => {
          if (item.deviceId === selectedDevice.deviceId) {
            return {
              ...item,
              ...data,
            };
          } else {
            return item;
          }
        });
        dispatch(setDevicesListOfCurrOrg(updatedDevices));
        deviceSelectHandler(selectedDevice.deviceId);
      } else {
        errorMsgHandler(res?.data?.meta?.userMsg);
        console.error('ERROR: ', res?.data?.meta?.userMsg);
      }
      selectedDeviceLoaderHandler(false);
    } catch (error) {
      console.error('ERROR: ', error);
      selectedDeviceLoaderHandler(false);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{}}
      onSubmit={async () => {
        handleReClaimDevice();
      }}
      innerRef={formikRef}
    >
      {({ handleSubmit }) => (
        <Form className="modal-form" onSubmit={handleSubmit}>
          <div className="device-image-wrapper">
            <img src={DummyImageIcon} alt="DummyImageIcon" />
          </div>
          <PrimaryButton
            className="mt-4"
            fontSize="14px"
            backgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            height="44px"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--brand_white'
            )}
          >
            {constants.DEVICE_PASSWORD_TAB_RECLAIM_DEVICE_BUTTON_TITLE}
          </PrimaryButton>
        </Form>
      )}
    </Formik>
  );
};

export default ReClaimDeviceControl;
