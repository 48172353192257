import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  PrimaryButton,
  SiteModal,
  SiteToast,
  TextBlock,
} from '../../components/common';
import { SelectField, TextField } from '../../components/forms';
import { constants, Utils, roles } from '../../helpers';
import ManageAreas from './ManageAreas';
import { useSearchParams } from 'react-router-dom';
import Autocomplete from 'react-google-autocomplete';
import axios from 'axios';
import { getAllDevicesData } from '../../store/reducers/AccountReducer';
import { usePoliciesStore } from '../../store/policiesStore';
import { useSelector } from 'react-redux';
import timezones from '../../data/support/timezone.json';
import { PiWarningCircleBold } from 'react-icons/pi';
import { useOrganizations } from '../../store/OrganizationsStore';
import { moveArrayPosition } from '../../helpers/commonUtils';
import { IoClose } from "react-icons/io5";
// import './EditLocations.scss';

// Schema for yup
const validationSchema = Yup.object().shape({
  locationName: Yup.string()
    .max(32, constants.CANT_LONGER_ERROR_MESSAGE)
    .required(constants.NAME_REQUIRED_ERROR_MESSAGE),
  addressLine1: Yup.string().required(constants.ADDRESS_REQUIRED_ERROR_MESSAGE),
  timezone: Yup.string().required(constants.TIMEZONE_REQUIRED_ERROR_MESSAGE),
});

function EditLocations({
  EditLocationformRef,
  onFormValidityChange,
  onFormSubmittingChange,
  showRemoveLocationModal,
  setShowRemoveLocationModal,
  ...props
}) {
  console.log('file: EditLocations.js:35 ~ EditLocations ~ props:', props);
  const childFunc = React.useRef(null);
  const locationsData = props?.locationsData;
  const [variant, setVariant] = useState('error');
  const [userMsg, setUserMsg] = useState('');
  const [visibleLocHelperText, setVisibleLocHelperText] = useState(false);
  const [locHelperText, setLocHelperText] = useState('');
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const [newLocaAreas, setNewLocaAreas] = useState(
    locationsData?.areas?.length > 0 ? locationsData?.areas : []
  );
  const [prevAreasCount, setPrevAreasCount] = useState(
    locationsData?.areas?.length
  );
  const [showLoader, setShowLoader] = useState(false);
  const deviceListData = useSelector(getAllDevicesData);
  const deviceExists = deviceListData?.filter(
    (device) => device.locationId === locationsData?.locationId
  );
  //=== Store get/set actions
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies
  );
  const zoneTimeList = timezones?.data;
  const [currentUserTimezone, setCurrentUserTimezone] = useState(
    zoneTimeList?.find((zone) => zone.value === locationsData?.timezone)
  );

  const [orgContact, setOrgContact] = useState([]);
  const getOrganizationContact = useOrganizations(
    (state) => state.getOrganizationContact
  );
  const loggedInUserRole = useOrganizations((state) => state.loggedInUserRole);
  const customerOrgData = useOrganizations((state) => state.customerOrgData);
  const partnerOrgData = useOrganizations((state) => state.partnerOrgData);
  const partnerOrgId =
    loggedInUserRole === roles.ROLE4VMS || loggedInUserRole === roles.ROLE5VMS
      ? partnerOrgData?.orgId
      : customerOrgData[0]?.orgId;

  
  const disableInput = (event, values) => {
    if (values.addressLine1) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (partnerOrgId) {
      // get data for organization contact dropdown
      const roleParam = encodeURIComponent(roles.ROLE2VMS);
      getOrganizationContact(`/partner/orgs/${orgId}/accounts/v2?roleIds=${roleParam}`)
        .then((res) => {
          if (res?.status === 'failure') {
            setUserMsg(res?.msg);
          } else {
            let updatedContacts = res ?? [];
            if (locationsData?.contactId !== '') {
              const selectedObjIndex = res?.findIndex(
                (data) => data?.value === locationsData?.contactId
              );
              updatedContacts = moveArrayPosition(res, selectedObjIndex, 0);
            }
            setOrgContact(updatedContacts);
          }
        })
        .catch((err) => {
          setUserMsg(err?.msg);
        });
    }
  }, [orgId]);

  //=== On click remove this account button remove user and update user data
  const handleRemoveLocationClick = () => {
    setShowLoader(true);
    setUserMsg('');
    axios
      .delete(
        `partner/orgs/${orgId}/locations/${locationsData?.locationId}`,
        Utils.requestHeader()
      )
      .then((res) => {
        let response = res.data;
        if (response.meta.code === 200) {
          props.reloadData();
          props.hideModal();
          setShowRemoveLocationModal(false);
        } else {
          setVariant('error');
          setUserMsg(response?.meta?.userMsg);
        }
        setShowLoader(false);
      })
      .catch(function (error) {
        setVariant('error');
        setUserMsg(error?.message);
        setShowLoader(false);
      });
  };

  const changeTimezoneValue = (evebr, setFieldValue) => {
    let selectedVal = evebr?.target?.value;
    const getLocationSelected = zoneTimeList?.find(
      (zone) => zone.value === selectedVal
    );
    setCurrentUserTimezone(getLocationSelected);
    setFieldValue('timezone', getLocationSelected?.value);
    setVisibleLocHelperText(false);
  };

  const checkAddedAreas = () => {
    if(newLocaAreas.some((area) => area.isDefault && !area.areaName)){
      return true;
    }
    const nameEmpty = newLocaAreas.filter((area) => !area.areaName);
    if(nameEmpty.length === newLocaAreas.length){
      return true;
    }
    return false;
  }

  return (
    <section className="modal-main-content">
      <div
        className={`parent-edit-modal ${
          showRemoveLocationModal ? 'd-none' : ''
        }`}
      >
        <Formik
          innerRef={EditLocationformRef}
          initialValues={{
            locationId: locationsData?.locationId,
            locationName: locationsData?.locationName,
            displayDetails: `${locationsData.addressLine1}${locationsData.city}, ${locationsData.state}, ${locationsData.country}, ${locationsData?.zipcode}`,
            addressLine1: locationsData?.addressLine1,
            addressLine2: locationsData?.addressLine2,
            city: locationsData?.city,
            state: locationsData?.state,
            country: locationsData?.country,
            zipcode: locationsData?.zipcode,
            lat: locationsData?.lat,
            lng: locationsData?.lng,
            contactId: locationsData?.contactId ?? '',
            isDefault: locationsData?.isDefault,
            areas: locationsData?.areas?.length > 0 ? locationsData?.areas : [],
            timezone: currentUserTimezone?.value,
            // bandwidth: locationsData?.locationContact ?? ''
          }}
          enableReinitialze={true}
          validate={(values) => {
            const validation = ((getCustomerOrgPolicies()?.update_account) &&
            (newLocaAreas?.length === prevAreasCount) ||
            newLocaAreas?.length === 0 ||
            currentUserTimezone == undefined || checkAddedAreas()) &&
            (values.addressLine1?.toString()?.trim()?.length !== 0 &&
            values.city?.toString()?.trim()?.length !== 0 &&
            values.state?.toString()?.trim()?.length !== 0 &&
            values.country?.toString()?.trim()?.length !== 0 &&
            values.zipcode)
            onFormValidityChange(validation)
          }}
          validateOnChange={true}
          // Hooks up our validationSchema to Formik
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            // When button submits form and form is in the process of submitting, submit button is disabled
            const areaSelected = childFunc.current();
            setSubmitting(true);
            onFormSubmittingChange(true);
            setUserMsg('');
            if (orgId !== null && orgId !== undefined) {
              // Simulate submitting to database, shows us values submitted, resets form
              values.areas = areaSelected !== undefined ? areaSelected : [];
              axios
                .put(
                  `partner/orgs/${orgId}/locationAreas`,
                  values,
                  Utils.requestHeader()
                )
                .then((res) => {
                  let response = res.data;
                  if (response.meta.code === 200) {
                    setSubmitting(false);
                    onFormSubmittingChange(false);
                    props.reloadData();
                    props.hideModal();
                  } else {
                    setVariant('error');
                    setUserMsg(response?.meta?.userMsg);
                    setSubmitting(false);
                    onFormSubmittingChange(false);
                  }
                })
                .catch(function (error) {
                  setVariant('error');
                  setUserMsg(error?.message);
                });
            }
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            dirty,
            isValid,
            validateForm
          }) => (
            <>
              <div className='manage-height'>
                <TextField
                  required={true}
                  removebottommargin="true"
                  removetopmargin="true"
                  label=""
                  placeholder={constants.LOCATION_NAME_FIELD}
                  name="locationName"
                  type="text"
                />

                <div className="row">
                  <div className="col position-relative mt-2 address-with-reset">
                    <div>
                      <Autocomplete
                        defaultValue={values?.addressLine1}
                        name="displayDetails"
                        placeholder=""
                        apiKey={process.env.REACT_APP_GOOGLE_PLACE_URL}
                        onChange={(evt) => {
                          setFieldValue('displayDetails', evt?.target?.value);
                        }}
                        onBlur={handleBlur}
                        onKeyDown={(e) => disableInput(e, values)}
                        onFocus={
                          (e) => e.target.setAttribute("autoComplete", "none")
                        }
                        id="displayDetails"
                        options={{
                          fields: [
                            'formatted_address',
                            'geometry',
                            'name',
                            'address_components',
                          ],
                          strictBounds: false,
                          types: ['address'],
                        }}
                        onPlaceSelected={(place) => {
                          if(place?.address_components?.length){
                          setFieldValue('lat', place?.geometry?.location?.lat());
                          setFieldValue('lng', place?.geometry?.location?.lng());
  
                          let addressComponent, addressComponentType, rawzipcode, rawcountry, rawcity, rawstate, rawaddline;
                          for (
                            let i = 0;
                            i < place?.address_components?.length;
                            i++
                          ) {
                            addressComponent = place.address_components[i];
                            let j = 0;
                            addressComponentType = addressComponent.types[j];
                            if (addressComponentType === 'postal_code') {
                              rawzipcode = addressComponent.long_name ? addressComponent.long_name : '';
                            }
                            if (addressComponentType === 'country') {
                              rawcountry = addressComponent.long_name ? addressComponent.short_name : '';
                            }
                            if (addressComponentType === 'administrative_area_level_1') {
                              rawstate = addressComponent.long_name  ? addressComponent.short_name : '';
                            }
                            if (addressComponentType === 'locality') {
                              rawcity = addressComponent.long_name  ? addressComponent.long_name : '';
                            }
                            
                            if (
                              addressComponentType === 'street_number' || 
                              addressComponentType === 'route' || 
                              addressComponentType === 'neighborhood' || 
                              addressComponentType.includes('sublocality')
                            ){
                              rawaddline = `${rawaddline ? rawaddline : ''}${addressComponent.long_name}, `;
                            }
                          }
                          setFieldValue('zipcode', rawzipcode);
                          setFieldValue('country', rawcountry);
                          setFieldValue('city', rawcity);
                          setFieldValue('state', rawstate);
                          setFieldValue('addressLine1', rawaddline);
                          setFieldValue('displayDetails', 
                          (rawaddline ? rawaddline : '') + 
                          (rawcity ? rawcity + ', ' : '') + 
                          (rawstate ? rawstate + ', ' : '') + 
                          (rawcountry ? rawcountry + ', ' : '') + 
                          (rawzipcode ? rawzipcode : ''));   

                          if(place?.geometry?.location?.lat() && place?.geometry?.location?.lng()){

                            var config = {
                              method: 'get',
                              url: `https://maps.googleapis.com/maps/api/timezone/json?location=${place?.geometry?.location?.lat()},${place?.geometry?.location?.lng()}&timestamp=${
                                new Date().getTime() / 1000
                              }&language=es&key=${
                                process.env.REACT_APP_GOOGLE_TIMEZONE_API_KEY
                              }`,
                              headers: {},
                            };

                            fetch(config?.url)
                            .then(function (response) {
                              return response.json();
                            })
                            .then(function (json) {
                              if (json?.status === 'OK') {
                                const data = zoneTimeList?.find(
                                  (zone) => zone?.location === json?.timeZoneId
                                );
                                if (data) {
                                  setCurrentUserTimezone(data);
                                  setFieldValue('timezone', data?.value);
                                  setLocHelperText(
                                    constants.LOCATION_TIMEZONE_ORGANIZATION_MESSAGE
                                  );
                                } else {
                                  setLocHelperText(
                                    constants.AUTO_TIMEZONE_FAIL_MESSAGE
                                  );
                                  setFieldValue('timezone', '');
                                }
                                setVisibleLocHelperText(true);
                              } else {
                                setVisibleLocHelperText(true);
                                setLocHelperText(
                                  constants.AUTO_TIMEZONE_FAIL_MESSAGE
                                );
                              }
                            });
                          }
                        }
                        }}
                        value={values.displayDetails}
                        className={`form-control shadow-none autoaddress ${
                          touched.displayDetails &&
                          (errors.addressLine1 || !values.displayDetails) &&
                          'is-invalid'
                        } ${values.addressLine1 && 'has-value'}`}
                      />
                      {!document.getElementById("displayDetails")?.value ?
                        <span className="placeholder-wrapper required">
                          {constants.ADDRESS_FIELD}
                        </span> : null 
                      }
                      {values.addressLine1 ? 
                        <span className={`reset-address-wrapper`}>
                          <IoClose size={15}
                            onClick={()=> {  
                            setFieldValue('zipcode', '');
                            setFieldValue('country', '');
                            setFieldValue('city', '');
                            setFieldValue('state', '');
                            setFieldValue('addressLine1', '');   
                            setFieldValue('displayDetails', ''); 
                            setFieldValue('timezone', ''); 
                            document.getElementById("displayDetails").value='';
                            setFieldTouched('displayDetails', true, false);
                            validateForm({
                              values,
                              zipcode:'',
                              country:'',
                              city:'',
                              state:'',
                              addressLine1:''
                            })
                          }}/>
                        </span> : null 
                      }
                    </div>
                    <ErrorMessage
                      component="div"
                      name="addressLine1"
                      className="error"
                    />
                     {touched.displayDetails &&
                      (!values.addressLine1 ||
                      !values.city || !values.state || !values.country || !values.zipcode) && (
                      <div className="error">
                          {constants.ADDRESS_FIELD_ERROR_MESSAGE}
                        </div>
                      )}
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col position-relative">
                    <div>
                      <SelectField
                        isFromOrg={true}
                        name="timezone"
                        options={zoneTimeList}
                        label=""
                        defaultOptionLabel="Time Zone"
                        value={values.timezone}
                        onChange={(evebr) =>
                          changeTimezoneValue(evebr, setFieldValue)
                        }
                      />
                    </div>

                    {visibleLocHelperText ? (
                      <div className="info-timezone">
                        <PiWarningCircleBold
                          color={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--greyscale_56')}
                          size={16}
                        />
                        <div className="message-info">{locHelperText}</div>
                      </div>
                    ) : null}
                  </div>
                </div>

                <SelectField
                  isFromOrg={true}
                  name="contactId"
                  key="contactId"
                  options={orgContact}
                  defaultOptionLabel={constants.LOCATION_CONTACT_DROPDOWN_LABEL}
                />

                <ManageAreas
                  childFunc={childFunc}
                  disabledState={false}
                  areasData={newLocaAreas}
                  updateAreaData={(areas) => setNewLocaAreas(areas)}
                />
                </div>

                {/* TODO: will update once get some backend value <label className="pt-2 mt-4">
                  <strong className='location-modal-heading'>{constants.BANDWIDTH_USAGE_BUDGET}</strong>

                  <TextBlock
                    fontWeight="400"
                    fontSize="0.875rem"
                    lineHeight="20px"
                    color={getComputedStyle(document.documentElement).getPropertyValue('--brand_primary')}
                  >
                    {constants.BANDWIDTH_HELPER_TEXT}
                  </TextBlock>
                </label> */}
                {/* TODO: will update once get some backend value <div>
                  <div className="d-flex align-items-center flex-row mt-3">
                    <input
                      className="form-control require shadow-none"
                      placeholder='(Optional)'
                      autoComplete="off"
                      name="bandwidth"
                      type="text"
                      value={values.bandwidth}
                      onChange={(event) => setFieldValue('bandwidth', event.target.value)}
                    />

                    <TextBlock
                      className="ml-2"
                      fontWeight="500"
                      fontSize="1rem"
                      lineHeight="20px"
                      color={getComputedStyle(document.documentElement).getPropertyValue('--greyscale_40')}
                    >
                      kbps
                    </TextBlock>
                  </div>
                  <div className="info-timezone mt-1">
                    <div className="message-info">
                      {constants.BANDWIDTH_MAX_CONTENT_HELPER_TEXT}
                    </div>
                  </div>
                </div> */}
                <div>
              </div>
              {/* </Form> */}
              {/* Show error messages */}
              <SiteToast
                title={
                  variant === 'error'
                    ? constants.ERROR_TOAST_TITLE
                    : constants.SUCCESS_TOAST_TITLE
                }
                show={!!userMsg}
                body={userMsg}
                variant={variant}
                position="top-center"
              />
            </>
          )}
        </Formik>
      </div>

      {/* Remove location - confirmation modal */}
      <SiteModal
        modalTitle={
          deviceExists.length > 0
            ? Utils.replaceStringValues(
                constants.EDIT_LOCATIONS_REMOVE_TITLE,
                '$locationName',
                locationsData?.locationName
              )
            : Utils.replaceStringValues(
                constants.EDIT_LOCATIONS_REMOVE_MODAL_TITLE_MESSAGE,
                '$locationName',
                locationsData?.locationName
              )
        }
        showModal={showRemoveLocationModal}
        hideModal={() => setShowRemoveLocationModal(false)}
        classes="remove-customer-location-modal"
        isFromLocation={true}
      >
        <TextBlock
          className="mt-1 mb-4"
          fontWeight="400"
          fontSize="1rem"
          lineHeight="24px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--brand_primary'
          )}
        >
          {deviceExists.length > 0
            ? constants.EDIT_LOCATIONS_MODAL_DEVICE_EXISTS_REMOVE_CONTENT
            : constants.EDIT_LOCATIONS_MODAL_REMOVE_CONTENT}
        </TextBlock>

        <Row>
          <Col className="text-end">
            <PrimaryButton
              className={`btn btn-primary-outline ${
                deviceExists.length > 0 && 'd-none'
              }`}
              type="button"
              width="auto"
              borderWidth="1.5px"
              hoverBorderWidth="1.5px"
              borderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              backgroundColor="transparent"
              loader={showLoader}
              onClick={() => handleRemoveLocationClick()}
            >
              {constants.NEW_LOCATIONS_REMOVE_BUTTON_TITLE}
            </PrimaryButton>
            <PrimaryButton
              className="ms-2 btn btn-primary"
              type="button"
              height="44px"
              fontSize="0.875rem"
              width="auto"
              onClick={() => setShowRemoveLocationModal(false)}
            >
              {constants.EDIT_CUSTOMER_REMOVE_USER_CONFIRM_CANCEL_BUTTON_TITLE}
            </PrimaryButton>
          </Col>
        </Row>
        <SiteToast
          title={
            variant === 'error'
              ? constants.ERROR_TOAST_TITLE
              : constants.SUCCESS_TOAST_TITLE
          }
          show={!!userMsg}
          body={userMsg}
          variant={variant}
          position="top-center"
        />
      </SiteModal>
    </section>
  );
}

export default EditLocations;
