import { useState } from 'react';
import './ZoomView.scss';

const ZoomView = ({
  cellHeight = '12px',
  zoomLevel = '1x',
  clickHandler,
  ...props
}) => {
  const [numCellSelected, setNumCellSelected] = useState(0);

  const handleClick = (numOfCells) => {
    if (isNaN(numOfCells)) return;

    setNumCellSelected(numOfCells);

    if (clickHandler.constructor === Function) {
      clickHandler(numOfCells);
    }
  };

  return (
    <div className="zoom-container" style={{ height: cellHeight }}>
      <div
        className={`zoom-cell zoom-cell-3${
          zoomLevel === '3x' ? ' selected' : ''
        }`}
        onClick={() => handleClick(3)}
      ></div>
      <div
        className={`zoom-cell zoom-cell-2${
          zoomLevel === '2x' ? ' selected' : ''
        }`}
        onClick={() => handleClick(2)}
      ></div>
      <div
        className={`zoom-cell zoom-cell-1${
          zoomLevel === '1x' ? ' selected' : ''
        }`}
        onClick={() => handleClick(1)}
      ></div>
    </div>
  );
};

export default ZoomView;
