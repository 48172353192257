import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import axios from 'axios';
import moment from 'moment';

import { AppDefaults, constants, Utils } from '../../../helpers';
import {
  Avatar,
  PrimaryButton,
  TextBlock,
  SiteModal,
} from '../../../components/common';
import { ViewLogs } from '../modalForms';

import { useOrganizations } from '../../../store/OrganizationsStore';
import { useCustomerOrgDevices } from '../../../store/CustomerOrgDevicesStore';

import { GoArrowLeft } from 'react-icons/go';

import './NoteDetails.scss';

const NoteDetails = ({
  incidentId,
  incidentNoteId,
  timezone,
  setViewNoteDetails,
  showBackToEvidence = false,
  fetchTimezoneLocation,
}) => {
  const [userMsg, setUserMsg] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [currentIncidentId, setCurrentIncidentId] = useState(incidentId);
  const [currentIncidentNoteId, setCurrentIncidentNoteId] =
    useState(incidentNoteId);
  const [currentNote, setCurrentNote] = useState(null);
  const [hasClipperVideoContent, setHasClipperVideoContent] = useState(false);
  const [isVideoContent, setIsVideoContent] = useState(false);
  const [customerOrgDevices, setCustomerOrgDevices] = useState([]);
  const [viewLogEntries, setViewLogEntries] = useState([]);
  const [showViewLogsModal, setShowViewLogsModal] = useState(false);

  const { getCustomerOrgData } = useOrganizations();
  const { getCustomerOrgDevices } = useCustomerOrgDevices();

  const orgDetails = getCustomerOrgData()[0];

  useEffect(() => {
    const devices = getCustomerOrgDevices();
    setCustomerOrgDevices([...devices]);
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const noteDetails = await fetchNoteDetails();
      const viewLogEntriesPromises = noteDetails?.logs?.map(
        async (logEntry) => {
          const getAccountAvatarURLResponse = await Utils?.getAccountAvatarURL(
            orgDetails?.orgId,
            logEntry?.accountId
          );

          return {
            ...logEntry,
            avatarURL: getAccountAvatarURLResponse?.avatarURL,
          };
        }
      );
      const logEntriesWithAvatars = await Promise.all(viewLogEntriesPromises);

      if (noteDetails) {
        setCurrentNote(noteDetails);
      }

      if (Array.isArray(logEntriesWithAvatars)) {
        // Sort the log entries in descending chronological order
        logEntriesWithAvatars.sort((a, b) => {
          if (a?.createdDate < b?.createdDate) {
            return 1;
          }

          if (a?.createdDate > b?.createdDate) {
            return -1;
          }

          return 0;
        });

        setViewLogEntries([...logEntriesWithAvatars]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchNoteDetails = async () => {
    let noteDetails;

    try {
      const res = await axios.get(
        `/incident/orgs/${orgDetails?.orgId}/incidents/${currentIncidentId}/notes/${currentIncidentNoteId}`,
        {
          ...Utils.requestHeader(),
          credentials: 'include',
          withCredentials: true,
        }
      );

      const responseData = res?.data;
      let incidentNote, incidentLogs;

      if (responseData?.meta?.code === 200) {
        incidentNote = responseData?.data?.note;
        incidentLogs = responseData?.data?.logs;

        noteDetails = responseData?.data;

        // Check if the video file has a presigned URL
        if (
          incidentNote?.incidentNoteType.toUpperCase() === 'VIDEO' &&
          !noteDetails?.note?.file?.url
        ) {
          noteDetails.note.file.url = await fetchPresignedUrl(
            noteDetails?.note?.file?.name
          );
        }

        setViewLogEntries([...incidentLogs]);
        setHasClipperVideoContent(
          incidentNote?.incidentNoteType.toUpperCase() === 'VIDEO' &&
            incidentNote?.deviceId
        );
        setIsVideoContent(
          incidentNote?.incidentNoteType.toUpperCase() === 'VIDEO'
        );

        setCurrentNote(noteDetails);
      }
    } catch (error) {
      console.error(error);
    } finally {
      return noteDetails;
    }
  };

  const fetchPresignedUrl = async (fileName) => {
    let preSignedURL;

    try {
      if (!fileName) {
        throw new Error('No file name provided');
      }

      const res = await axios.get(
        `/incident/orgs/${orgDetails?.orgId}/incidents/${currentIncidentId}/notes/presignedURL`,
        {
          params: {
            fileName: fileName,
          },
          ...Utils.requestHeader(),
          credentials: 'include',
          withCredentials: true,
        }
      );

      const responseData = res?.data;

      if (responseData?.meta?.code === 200) {
        const signedURL = responseData?.data?.preSignedURL;

        if (
          AppDefaults.INCIDENT_EVIDENCE_NOTE_ORIGINAL_VIDEO_FILENAME_RETAINED
        ) {
          // Find the index of the last "/" and "?"
          const lastSlashIndex = signedURL.lastIndexOf('/');
          const questionMarkIndex = signedURL.indexOf('?');

          // Build the modified string
          const newPreSignedURL =
            signedURL.substring(0, lastSlashIndex + 1) +
            fileName +
            signedURL.substring(questionMarkIndex);

          preSignedURL = newPreSignedURL;
        } else {
          preSignedURL = signedURL;
        }
      } else {
        setUserMsg(res?.meta?.userMsg);
        setShowToast(true);
      }
    } catch (error) {
      console.error(error);
      setUserMsg(error);
      setShowToast(true);
    } finally {
      return preSignedURL;
    }
  };

  const getCameraNameByDeviceId = (deviceId) => {
    const device = customerOrgDevices.find(
      (device) => device?.deviceId === currentNote?.note?.deviceId
    );

    return (
      device?.deviceName ||
      constants?.INCIDENTS_EVIDENCE_NOTE_DETAILS_GENERIC_CAMERA
    );
  };

  return (
    <div className="note-details-container">
      {isVideoContent && (
        <div className="note-video-container">
          <ReactPlayer
            className="note-video-player"
            url={currentNote?.note?.file?.url}
            controls={true}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload',
                },
              },
            }}
            onContextMenu={(e) => e.preventDefault()}
            width="732px"
            height="426px"
          />
        </div>
      )}
      <div
        className={`note-artifact-details-content${
          !isVideoContent ? ' no-video' : ''
        }`}
      >
        <Container className="px-0 note-artifact-details-content-narrow-width">
          {showBackToEvidence && (
            <div
              className="mb-3 note-artifact-details-back-link"
              onClick={() => {
                setViewNoteDetails && setViewNoteDetails(false);
              }}
            >
              <GoArrowLeft
                className="note-artifact-details-back-link-icon"
                size={20}
              />{' '}
              <span className="note-artifact-details-back-link-label">
                {constants.INCIDENTS_EVIDENCE_NOTE_DETAILS_BACK_TO_EVIDENCE}
              </span>
            </div>
          )}
          {currentNote?.note?.incidentNoteType?.toUpperCase() !== 'COMMENT' && (
            <Row>
              <Col>
                <div className="note-artifact-details-file-wrapper">
                  <div className="mb-3 note-artifact-details-file">
                    {hasClipperVideoContent ? (
                      <span>
                        {getCameraNameByDeviceId(currentNote?.note?.deviceId)}
                      </span>
                    ) : (
                      <span>{currentNote?.note?.file?.name}</span>
                    )}
                  </div>
                  {!isVideoContent && (
                    <PrimaryButton
                      className="note-artifact-details-file-view-btn"
                      width="94px"
                      onClick={(e) => {
                        e?.preventDefault();
                        window.open(
                          `${currentNote?.note?.file?.url}`,
                          '_blank'
                        );
                      }}
                    >
                      {constants.INCIDENTS_EVIDENCE_NOTE_DETAILS_VIEW_FILE}
                    </PrimaryButton>
                  )}
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <div className="mb-3 note-artifact-details-info">
                <div className="note-artifact-details-info-date">
                  {/* {moment(currentNote?.note?.file?.createdDate).format(
                    'MMM DD, YYYY hh:mm A'
                  )} */}
                  {moment(currentNote?.note?.file?.createdDate).format(
                    'll, LTS'
                  )}
                </div>
                <div className="note-artifact-details-info-creator">
                  {constants.INCIDENTS_EVIDENCE_NOTE_DETAILS_CREATED_BY}{' '}
                  {currentNote?.note?.file?.createdBy}
                </div>
              </div>
            </Col>
          </Row>
          {/* Captured Date & Time */}
          {currentNote?.note?.incidentNoteType?.toUpperCase() !== 'COMMENT' && (
            <>
              <Row>
                <Col>
                  <div className="mt-2 mb-3 note-artifact-details-info">
                    <span className="note-artifact-details-info-date-time-title">
                      {!currentNote?.note?.clipStartTime ||
                      !currentNote?.note?.clipEndTime
                        ? constants.INCIDENTS_EVIDENCE_NOTE_DETAILS_UPLOADED_DATE_AND_TIME
                        : constants.INCIDENTS_EVIDENCE_NOTE_DETAILS_CAPTURED_DATE_AND_TIME}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="mt-2 mb-4 px-3 note-artifact-details-info">
                    {isNaN(currentNote?.note?.clipStartTime) ||
                    isNaN(currentNote?.note?.clipEndTime) ? (
                      <span className="note-artifact-details-info-date-time">
                        {moment(currentNote?.note?.file?.createdDate).format(
                          'll, LTS'
                        )}
                      </span>
                    ) : (
                      <span className="note-artifact-details-info-date-time">
                        {!isNaN(currentNote?.note?.clipStartTime) &&
                          moment(
                            currentNote?.note?.clipStartTime * 1000
                          )?.format('ll, LTS')}
                        {' - '}
                        {!isNaN(currentNote?.note?.clipEndTime) &&
                        fetchTimezoneLocation(timezone)
                          ? moment(currentNote?.note?.clipEndTime * 1000)
                              ?.tz(fetchTimezoneLocation(timezone))
                              ?.format('LTS z')
                          : moment(
                              currentNote?.note?.clipEndTime * 1000
                            )?.format('LTS z')}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
            </>
          )}
          {/* Note Details */}
          <Row>
            <Col>
              <div className="mt-1 mb-3 note-artifact-details-info">
                {currentNote?.note?.incidentNoteType?.toUpperCase() !==
                'COMMENT' ? (
                  <div className="note-artifact-details-info-file-title">
                    {constants.INCIDENTS_EVIDENCE_NOTE_DETAILS_NOTES}
                  </div>
                ) : (
                  <div className="note-artifact-details-info-comment-title">
                    {constants.INCIDENTS_EVIDENCE_NOTE_DETAILS_COMMENTS_BY}{' '}
                    {currentNote?.note?.file?.createdBy}
                  </div>
                )}
                <div className="note-artifact-details-info-description">
                  {currentNote?.note?.description}
                </div>
              </div>
            </Col>
          </Row>
          {/* View Logs */}
          <Row>
            <Col>
              <div className="mb-3 note-artifact-details-view-log">
                <div className="mb-3 note-artifact-details-view-log-header">
                  <div className="note-artifact-details-view-log-title">
                    {constants.INCIDENTS_EVIDENCE_NOTE_DETAILS_VIEW_LOG}
                  </div>
                  <div
                    className="note-artifact-details-view-log-view-all-link"
                    onClick={() => setShowViewLogsModal(true)}
                  >
                    {constants.INCIDENTS_EVIDENCE_NOTE_DETAILS_VIEW_ALL}
                  </div>
                </div>
                <div className="mb-4 note-artifact-details-logs">
                  {Array.isArray(viewLogEntries) &&
                    viewLogEntries?.slice(0, 3)?.map((log) => (
                      <div
                        key={log.createdDate}
                        className="mb-3 view-log-detail-wrapper"
                      >
                        <TextBlock className="view-log-detail-content">
                          <div className="view-log-detail-avatar">
                            <Avatar
                              valueType="icon"
                              value={log?.avatarURL}
                              size="semiLarge"
                              avatarStyle="roundedCircle"
                            />
                          </div>
                          <div className="view-log-detail-creator">
                            <div className="view-log-detail-creator-name">
                              {log?.actionBy}
                            </div>
                            <div className="view-log-detail-creator-date">
                              {moment(log?.createdDate).format(
                                'MMM DD, YYYY hh:mm:ss A'
                              )}
                            </div>
                          </div>
                        </TextBlock>
                      </div>
                    ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* MODALS */}
      {/* View Status Logs */}
      <SiteModal
        key={'view-log-detail-modal'}
        classes="view-log-detail-modal"
        wrapperClass="view-log-detail-modal-wrapper"
        modalTitle={constants.INCIDENTS_VIEW_LOG_MODAL_TITLE}
        showModal={showViewLogsModal}
        hideModal={() => setShowViewLogsModal(false)}
        modalFooter={
          <div className="status-log-button-group">
            <div className="mt-3 status-log-button-wrapper">
              <PrimaryButton
                className="status-log-back-btn"
                type="button"
                onClick={(e) => {
                  e?.preventDefault();
                  setShowViewLogsModal(false);
                }}
              >
                {constants.INCIDENTS_STATUS_LOG_MODAL_BACK_BUTTON_LABEL}
              </PrimaryButton>
            </div>
          </div>
        }
      >
        <ViewLogs viewLogEntries={viewLogEntries} />
      </SiteModal>
    </div>
  );
};

export default NoteDetails;
