import moment from 'moment';
import { useEffect, useState } from 'react';
import ImageFetch from './ImageFetch';
import useFiltersStore from '../../../store/FiltersStore';
import { Utils } from '../../../helpers';

import './EventsWrapper.scss';

const EventsWrapper = ({
  data,
  category,
  deviceId,
  cdnValue,
  timezone,
  selectedEvent,
  handleOnClick,
}) => {
  const [eventData, setEventData] = useState([]);
  const { getAppliedTags } = useFiltersStore();
  useEffect(() => {
    const allData = [];
    let eventClassFilter = 'face, person, vehicle, licensePlate, explosion, glass-breaking, scream, gun-shot, sound';

    if (getAppliedTags() !== '') {
      eventClassFilter = getAppliedTags();
      if (getAppliedTags()?.includes("sound")) {
        eventClassFilter = getAppliedTags()?.replace("sound", "explosion, glass-breaking, scream, gun-shot, sound");
      }
    }

    data?.events?.map((insideEvent, i) => {
      insideEvent?.eventMeta?.events?.map((actualEvent, ij) => {
        actualEvent?.event?.map((newEvent, index) => {
          if (
            category.includes(newEvent.eventType.toLowerCase()) &&
            eventClassFilter !== '' &&
            eventClassFilter
              ?.toLowerCase()
              ?.includes(newEvent.eventClass.toLowerCase()) &&
            insideEvent.deviceId === deviceId
          ) {
            const eData = {
              id: `${i}-${ij}-${index}`,
              class: newEvent.eventClass,
              timestamp: insideEvent?.eventTimestamp,
            };
            allData.push(eData);
          }
        });
      });
    });
    allData.sort((a, b) => {
      return b.timestamp - a.timestamp;
    });
    setEventData(allData);
  }, [data]);

  return (
    <div
      key={`scrollable-area-${deviceId}`}
      className="event-all-category-scrollable-area"
    >
      {eventData?.map((event, i) => {
        if (event) {
          return (
            <div
              key={`event-${event.timestamp}-${deviceId}-${i}`}
              className={`${
                selectedEvent === event.timestamp ||
                selectedEvent ===
                  Utils.getUnixDate(Utils.getDate(event.timestamp / 1000)) *
                    1000
                  ? 'selectedEvent'
                  : ''
              } event-wrapper-list`}
              onClick={() => {
                handleOnClick(event.timestamp);
              }}
            >
              <div className="event-list-image">
                <ImageFetch
                  time={event?.timestamp}
                  cdnValue={cdnValue}
                  deviceId={deviceId}
                />
              </div>
              <div className="event-list-content">
                <div className="event-list-content-details">
                  <div className="event-name">{event.class}</div>
                  <div className="event-timestamp">
                    {moment
                      .tz(moment(event.timestamp), timezone)
                      .format('hh:mm:ss A z')}
                  </div>
                </div>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default EventsWrapper;
