import { useState } from 'react';
import { RxCross1 } from 'react-icons/rx';
import { PrimaryButton } from '../../../components/common';
import { Radio, RadioGroup } from '@mui/material';
import { constants } from '../../../helpers';

const DiscoveredDevicesReclaim = ({
  cancelReclaim,
  reclaimDiscoveredDevices,
  requestedReclaimDevices,
  addDiscoveredDevices,
}) => {
  const [reclaimOption, setReclaimOption] = useState('');

  return (
    <>
      <div className="device-setup-modal-wrapper">
        <div className="header-container">
          <div className="device-setup-modal-title">
            {constants.DISCOVERED_DEVICES_RECLAIM_TITLE}
          </div>

          <RxCross1
            className="site-modal-close"
            onClick={() => cancelReclaim()}
          />
        </div>
        <div className="discription mt-2">
          {constants.DISCOVERED_DEVICES_RECLAIM_MESSAGE}
        </div>
        <div className="d-flex mt-4">
          <RadioGroup
            aria-labelledby="base-details-radio-buttons"
            name="controlled-radio-buttons-group"
          >
            <Radio
              sx={{
                color: getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--greyscale_80'),
              }}
              checked={reclaimOption === 'Reclaim'}
              onChange={(e) => {
                setReclaimOption('Reclaim');
              }}
              name="radio-buttons"
              inputProps={{ 'aria-label': 'A' }}
            />
          </RadioGroup>

          <div className="mx-2">
            <div className="device-setup-modal-subTitle">
              {constants.DISCOVERED_DEVICES_RECLAIM_OPT1_TITLE}
            </div>
            <div className="discription mt-1">
              {constants.DISCOVERED_DEVICES_RECLAIM_OPT1_INFO}
            </div>
          </div>
        </div>
        <div className="d-flex mt-4 mb-2">
          <RadioGroup
            aria-labelledby="base-details-radio-buttons"
            name="controlled-radio-buttons-group"
          >
            <Radio
              sx={{
                color: getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--greyscale_80'),
              }}
              checked={reclaimOption === 'Add'}
              onChange={(e) => {
                setReclaimOption('Add');
              }}
              name="radio-buttons"
              inputProps={{ 'aria-label': 'A' }}
            />
          </RadioGroup>

          <div className="mx-2">
            <div className="device-setup-modal-subTitle">
              {constants.DISCOVERED_DEVICES_RECLAIM_OPT2_TITLE}
            </div>
            <div className="discription mt-1">
              {constants.DISCOVERED_DEVICES_RECLAIM_OPT2_INFO}
            </div>
          </div>
        </div>
        <div className="w-100  mt-4">
          <PrimaryButton
            className="btn"
            type="submit"
            width="100%"
            backgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--brand_white'
            )}
            height="56px"
            fontSize="18px"
            lineHeight="20px"
            fontWeight="600"
            onClick={() => {
              if (reclaimOption === 'Add') {
                addDiscoveredDevices(requestedReclaimDevices);
              } else {
                reclaimDiscoveredDevices(requestedReclaimDevices);
              }
            }}
            disabled={!reclaimOption}
          >
            {constants.DISCOVERED_DEVICES_RECLAIM_CONFIRM}
          </PrimaryButton>
          <PrimaryButton
            className="btn btn-outline-secondary w-100 mt-2"
            type="submit"
            loaderClassName="delete-view-loader"
            height="56px"
            fontSize="18px"
            lineHeight="20px"
            fontWeight="600"
            backgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--brand_white')}
            borderColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--primary_40'
            )}
            borderWidth="1.5px"
            hoverBorderWidth="1.5px"
            hoverColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            hoverBackgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_96')}
            hoverBorderColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            onClick={() => cancelReclaim()}
          >
            {constants.DISCOVERED_DEVICES_CANCEL}
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};

export default DiscoveredDevicesReclaim;
