import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cdnInfo: '',
  metadata: '',
  mqttConnection: '',
  platform: null,
  snapshotImage: null,
  localStream: null,
  liveStream: null,
  remoteStream: null,
  isRemoteStreamPlay: false,
  remoteStreamAudio: null,
  deviceStartDate: '',
  loadingStream: false,
  wssConnection: '',
  liveCameraIds: [],
  reloadAsFilterTriggered: false,
  wssConnections: null,
  rtcPeerConnections: null,
  liveStreams: null,
  snapshotImages: null,
  remoteStreams: null,
  offerRecieved: false,
  remoteStreamsAudio: null,
  mqttPeerConnections: null,
  isLiveViewEntitlementExist: false,
  isLiveViewEntitlementExpired: false,
  channelTimeStamp: false,
  cvrStartDate: '',
  isReloadedStream: true,
  searchNoVideo: false,
  isOnMultiCameraViewPage: false,
  bitmapObject: null,
  endOfStream: false,
  bitmapObjects: null,
  receivedOffers: null,
};
// TODO: This file needs to be changed to zustand once priorities tasks get finished.
export const streaming = createSlice({
  name: 'streaming',
  initialState,
  reducers: {
    setPlatformInfo: (state, action) => {
      state.platform = action.payload;
    },
    resetPlatformInfo: (state) => {
      state.platform = null;
    },
    setCDNInfo: (state, action) => {
      state.cdnInfo = action.payload;
    },
    resetCDNInfo: (state) => {
      state.cdnInfo = '';
    },
    setStartDate: (state, action) => {
      state.deviceStartDate = action.payload;
    },
    setMetaData: (state, action) => {
      state.metadata = action.payload;
    },
    setLocalStream: (state, action) => {
      state.localStream = action.payload;
    },
    setLiveStream: (state, action) => {
      state.liveStream = action.payload;
    },
    setRemoteStream: (state, action) => {
      state.remoteStream = action.payload;
    },
    setIsRemoteStreamPlay: (state, action) => {
      state.isRemoteStreamPlay = action.payload;
    },
    setLiveCameraIds: (state, action) => {
      state.liveCameraIds = action.payload;
    },
    setRemoteStreamAudio: (state, action) => {
      state.remoteStreamAudio = action.payload;
    },
    setStremBitmapData: (state, action) => {
      state.bitmapObject = action.payload;
    },
    setStremBitmapsData: (state, action) => {
      state.bitmapObjects = {
        ...state.bitmapObjects,
        [action.payload.id]: action.payload.bitmapObject,
      };
    },
    setSnapshotImage: (state, action) => {
      state.snapshotImage = action.payload;
    },
    setMQTTConnection: (state, action) => {
      state.mqttConnection = action.payload;
    },
    setWSSConnection: (state, action) => {
      state.wssConnection = action.payload;
    },
    setLoadingStream: (state, action) => {
      state.loadingStream = action.payload;
    },
    resetStreaming: () => initialState,
    setReloadAsFilterTriggered: (state, action) => {
      state.reloadAsFilterTriggered = action.payload;
    },
    setWSSConnections: (state, action) => {
      state.wssConnections = {
        ...state.wssConnections,
        [action.payload.id]: action.payload.client,
      };
    },
    removeWSSConnections: (state, action) => {
      const updatedConnections = { ...state.wssConnections };
      delete updatedConnections[action.payload];
      state.wssConnections = updatedConnections;
    },
    clearWSSConnections: (state) => {
      state.wssConnections = null;
    },
    setRTCPeerConnections: (state, action) => {
      state.rtcPeerConnections = {
        ...state.rtcPeerConnections,
        [action.payload.id]: action.payload.client,
      };
    },
    removeRTCPeerConnections: (state, action) => {
      const updatedConnections = { ...state.rtcPeerConnections };
      delete updatedConnections[action.payload];
      state.rtcPeerConnections = updatedConnections;
    },
    clearRTCPeerConnections: (state) => {
      state.rtcPeerConnections = null;
    },
    setLiveStreams: (state, action) => {
      state.liveStreams = {
        ...state.liveStreams,
        [action.payload.id]: action.payload.stream,
      };
    },
    removeLiveStreams: (state, action) => {
      const updatedStreams = { ...state.liveStreams };
      delete updatedStreams[action.payload];
      state.liveStreams = updatedStreams;
    },
    clearLiveStreams: (state) => {
      state.liveStreams = null;
    },
    setSnapshotImages: (state, action) => {
      state.snapshotImages = {
        ...state.snapshotImages,
        [action.payload.id]: action.payload.snapshotImage,
      };
    },
    setRemoteStreams: (state, action) => {
      state.remoteStreams = {
        ...state.remoteStreams,
        [action.payload.id]: action.payload.stream,
      };
    },
    setRemoteStreamsAudio: (state, action) => {
      state.remoteStreamsAudio = {
        ...state.remoteStreamsAudio,
        [action.payload.id]: action.payload.audio,
      };
    },
    removeRemoteStreams: (state, action) => {
      const updatedStreams = { ...state.remoteStreams };
      delete updatedStreams[action.payload];
      state.remoteStreams = updatedStreams;
    },
    removeRemoteStreamsAudio: (state, action) => {
      const updatedStreamsAudio = { ...state.remoteStreamsAudio };
      delete updatedStreamsAudio[action.payload];
      state.remoteStreamsAudio = updatedStreamsAudio;
    },
    clearRemoteStreams: (state) => {
      state.remoteStreams = null;
    },
    clearRemoteStreamsAudio: (state) => {
      state.remoteStreamsAudio = null;
    },
    setMQTTPeerConnections: (state, action) => {
      state.mqttPeerConnections = {
        ...state.mqttPeerConnections,
        [action.payload.id]: action.payload.client,
      };
    },
    removeMQTTPeerConnections: (state, action) => {
      const updatedConnections = { ...state.mqttPeerConnections };
      delete updatedConnections[action.payload];
      state.mqttPeerConnections = updatedConnections;
    },
    clearMQTTPeerConnections: (state) => {
      state.mqttPeerConnections = null;
    },
    setIsLiveViewEntitlementExist: (state, action) => {
      state.isLiveViewEntitlementExist = action.payload;
    },
    setOfferrecived: (state, action) => {
      state.offerRecieved = action.payload;
    },
    setIsLiveViewEntitlementExpired: (state, action) => {
      state.isLiveViewEntitlementExpired = action.payload;
    },
    setChannelTimeStamp: (state, action) => {
      state.channelTimeStamp = action.payload;
    },
    setCVRStartDate: (state, action) => {
      state.cvrStartDate = action.payload;
    },
    setIsReloadedStream: (state, action) => {
      state.isReloadedStream = action.payload;
    },
    setSearchNoVideo: (state, action) => {
      state.searchNoVideo = action.payload;
    },
    setIsOnMultiCameraViewPage: (state, action) => {
      state.isOnMultiCameraViewPage = action.payload;
    },
    setEndOfStream: (state, action) => {
      state.endOfStream = action.payload;
    },
    setRecievedOffers: (state, action) => {
      state.receivedOffers = {
        ...state.receivedOffers,
        [action.payload.id]: action.payload.value,
      };
    },
    resetRecievedOffers: (state, action) => {
      state.receivedOffers = null;
    },
  },
});

export const {
  setPlatformInfo,
  resetPlatformInfo,
  setCDNInfo,
  resetCDNInfo,
  setMetaData,
  setLiveStream,
  setLocalStream,
  setRemoteStream,
  setIsRemoteStreamPlay,
  setRemoteStreamAudio,
  setSnapshotImage,
  setMQTTConnection,
  resetStreaming,
  setLoadingStream,
  setStartDate,
  setWSSConnection,
  setLiveCameraIds,
  setReloadAsFilterTriggered,
  setWSSConnections,
  removeWSSConnections,
  clearWSSConnections,
  setRTCPeerConnections,
  removeRTCPeerConnections,
  clearRTCPeerConnections,
  setLiveStreams,
  removeLiveStreams,
  clearLiveStreams,
  setSnapshotImages,
  setRemoteStreams,
  setRemoteStreamsAudio,
  removeRemoteStreams,
  removeRemoteStreamsAudio,
  clearRemoteStreams,
  clearRemoteStreamsAudio,
  setMQTTPeerConnections,
  removeMQTTPeerConnections,
  clearMQTTPeerConnections,
  setIsLiveViewEntitlementExist,
  setIsLiveViewEntitlementExpired,
  setChannelTimeStamp,
  setCVRStartDate,
  setIsReloadedStream,
  setSearchNoVideo,
  setIsOnMultiCameraViewPage,
  setStremBitmapData,
  setEndOfStream,
  setStremBitmapsData,
  setOfferrecived,
  setRecievedOffers,
  resetRecievedOffers,
} = streaming.actions;
export const getPlatformInfo = (state) => state.streaming.platform;
export const getCDNInfo = (state) => state.streaming.cdnInfo;
export const getMetaData = (state) => state.streaming.metadata;
export const getLocalStream = (state) => state.streaming.localStream;
export const getLiveStream = (state) => state.streaming.liveStream;
export const getRemoteStream = (state) => state.streaming.remoteStream;
export const getIsRemoteStreamPlay = (state) =>
  state.streaming.isRemoteStreamPlay;
export const getRemoteStreamAudio = (state) =>
  state.streaming.remoteStreamAudio;
export const getStreamBitmapData = (state) => state.streaming.bitmapObject;
export const getSnapshotImage = (state) => state.streaming.snapshotImage;
export const getMQTTConnection = (state) => state.streaming.mqttConnection;
export const getWSSConnection = (state) => state.streaming.wssConnection;
export const getStartDate = (state) => state.streaming.deviceStartDate;
export const getLoadingStream = (state) => state.streaming.loadingStream;
export const getLiveCameraIds = (state) => state.streaming.liveCameraIds;
export const getReloadAsFilterTriggered = (state) =>
  state.streaming.reloadAsFilterTriggered;
export const getWSSConnections = (state) => state.streaming.wssConnections;
export const getRTCPeerConnections = (state) =>
  state.streaming.rtcPeerConnections;
export const getLiveStreams = (state) => state.streaming.liveStreams;
export const getSnapshotImages = (state) => state.streaming.snapshotImages;
export const getRemoteStreams = (state) => state.streaming.remoteStreams;
export const getOfferRecivedStatus = (state) => state.streaming.offerRecieved;
export const getRemoteStreamsAudio = (state) =>
  state.streaming.remoteStreamsAudio;
export const getMQTTPeerConnections = (state) =>
  state.streaming.mqttPeerConnections;
export const getIsLiveViewEntitlementExist = (state) =>
  state.streaming.isLiveViewEntitlementExist;
export const getIsLiveViewEntitlementExpired = (state) =>
  state.streaming.isLiveViewEntitlementExpired;
export const getChannelTimeStamp = (state) => state.streaming.channelTimeStamp;
export const getCVRStartDate = (state) => state.streaming.cvrStartDate;
export const getIsReloadedStream = (state) => state.streaming.isReloadedStream;
export const getSearchNoVideo = (state) => state.streaming.searchNoVideo;
export const getIsOnMultiCameraViewPage = (state) =>
  state.streaming.isOnMultiCameraViewPage;
export const getEndOfStream = (state) => state.streaming.endOfStream;
export const getStremBitmapsData = (state) => state.streaming.bitmapObjects;
export const getRecievedOffers = (state) => state.streaming.receivedOffers;
export default streaming.reducer;
