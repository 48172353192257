import { useState } from 'react';
import { Button, Container, Col, Row } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { getSelectedOrganization } from '../../../../store/reducers/AccountReducer';

import { PrimaryButton, SiteToast } from '../../../../components/common';
import { Utils, constants } from '../../../../helpers';
import useLicensesStore from '../../../../store/LicensesStore';

import './DetachLicenseForm.scss';

const DetachLicenseForm = ({ deviceId, licenseKey, orgId, callBack }) => {
  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');

  const { setLicenses } = useLicensesStore();
  const orgDetails = useSelector(getSelectedOrganization);

  const detachLicenseFromDevice = async () => {
    try {
      if (!deviceId || !licenseKey) {
        return;
      }

      const reqBody = {
        deviceId: deviceId,
        licenseKey: licenseKey,
      };

      const res = await axios.put(
        `/user/orgs/${orgDetails?.orgId}/licenses/detach`,
        reqBody,
        Utils.requestHeader()
      );

      if (res?.data?.meta?.code === 200) {
        await setLicenses(`/user/orgs/${orgId}/licenses`);
        callBack();
      } else {
        setShowToast(true);
        setUserMsg(res?.data?.data.msg);
      }
    } catch (error) {
      setShowToast(true);
      setUserMsg(error.msg);
    }
  };

  return (
    <Container>
      <Row>{constants.ORG_DASHBOARD_LICENSES_DETACH_LICENSE_DESCRIPTION}</Row>
      <Row>
        <Col>
          <div className="detach-license-button-wrapper">
            <Button
              variant="outline-danger"
              className="detach-license-button"
              onClick={() => detachLicenseFromDevice()}
            >
              {constants.ORG_DASHBOARD_LICENSES_DETACH_LICENSE_LABEL}
            </Button>
            <PrimaryButton
              className="cancel-button"
              width="78px"
              onClick={() => {
                callBack && callBack();
              }}
            >
              {constants.ORG_DASHBOARD_LICENSES_CANCEL_LABEL}
            </PrimaryButton>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default DetachLicenseForm;
