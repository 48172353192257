import React from 'react';
import { useState, useEffect } from 'react';
import { useTable, useSortBy } from 'react-table';
import { VscChevronDown, VscChevronUp } from 'react-icons/vsc';
import { Tooltip, tooltipClasses } from '@mui/material';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import CameraWallSnapshot from './CameraWallSnapshot';
import { MdOutlineDragIndicator } from 'react-icons/md';

import '../../assets/css/base.scss';

const CameraWallTable = ({
  columns,
  data,
  viewDevices,
  viewId,
  newViewDragMode,
  defaultSortByData = [],
  viewDevicesDetails,
  cdnInfo,
  getDeviceStatus,
  editView,
  isDragging,
}) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [draggingDeviceId, setDraggingDeviceId] = useState(null);
  // Use the useTable Hook to send the columns and data to build the table
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        viewDevices,
        viewId,
        newViewDragMode,
        viewDevicesDetails,
        cdnInfo,
        getDeviceStatus,
        editView,
        isDragging,
        disableSortRemove: true,
        autoResetSortBy: false,
        initialState: {
          sortBy: defaultSortByData,
        },
      },
      useSortBy
    );

  useEffect(() => {
    if (document.readyState === 'complete') {
       const rowWidth = document.getElementsByClassName("camera-device-item")?.[0]?.clientWidth;
       const elements = document.querySelectorAll(".full-line-border");
       elements.forEach(elem => {
        elem.style.width = rowWidth + 'px';
       })
    }
  }, [document.readyState, document.getElementsByClassName("camera-device-item")?.[0]?.clientWidth])
  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    <div className="table-responsive">
      <table className="table table-borderless" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              <th></th>
              {headerGroup.headers.map((column, index) => (
                <th
                  className={`${column.id} ${
                    column.isSorted
                      ? column.isSortedDesc
                        ? 'columnheader-asc'
                        : 'columnheader-desc'
                      : ''
                  }`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={index}
                >
                  {column.render('Header')}
                  {column.id !== 'cameraOptions' &&
                  column.id !== 'viewChecked' &&
                  column.id !== 'snapshot' ? (
                    <span className="sort-icon">
                      {column.isSortedDesc ? (
                        <VscChevronDown size={16} strokeWidth="1px" />
                      ) : (
                        <VscChevronUp size={16} strokeWidth="1px" />
                      )}
                    </span>
                  ) : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <Droppable droppableId="table-body" isDropDisabled={isDragging}>
          {(provided, snapshot) => (
            <tbody
              {...getTableBodyProps()}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {rows?.map((row, index) => {
                prepareRow(row);
                return (
                  <>
                    <Draggable
                      draggableId={row.original.deviceId}
                      key={row.original.deviceId}
                      index={row.index}
                      isDragDisabled={
                        !viewDevices?.includes(row?.original.deviceId)
                      }
                    >
                      {(provided, snapshot) => {
                        return (
                          <tr
                            {...row.getRowProps()}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            key={`${index}-index`}
                            className={
                              `${viewDevices?.includes(row?.original.deviceId) &&
                              !snapshot.isDragging
                                ? 'selected-row'
                                : selectedRow === index && !snapshot.isDragging
                                ? 'active-row non-Selected-row-hover'
                                : row?.original.deviceId === draggingDeviceId &&
                                  snapshot.isDragging
                                ? 'dragging-row'
                                : 'non-Selected-row-hover'} camera-device-item`
                            }
                            onClick={() => setSelectedRow(index)}
                          >
                            <td className='full-line-border'></td>
                            {/* {snapshot.isDragging && viewDevices?.length > 1 ? (
                            <div className="drag-count">
                              {viewDevices?.length}
                            </div>
                          ) : null} */}
                            {row.cells.map((cell, cellIndex) => {
                              return (
                                !snapshot.isDragging && (
                                  <>
                                    {cell.column.id === 'deviceName' ? (
                                      <td
                                        className={cell.column.id}
                                        {...cell.getCellProps()}
                                      >
                                        {cell.render('Cell')}
                                      </td>
                                    ) : cell.column.id !== 'cameraOptions' &&
                                      cell.column.id !== 'displayDeviceStatus' &&
                                      cell.column.id !== 'conStatus' &&
                                      cell.column.id !== 'viewChecked' &&
                                      cell.column.id !== 'snapshot' ? (
                                      <Tooltip
                                        placement="top-start"
                                        arrow
                                        title={cell.render('Cell')}
                                        slotProps={{
                                          popper: {
                                            modifiers: [
                                              {
                                                name: 'offset',
                                                options: {
                                                  offset: [0, -25],
                                                },
                                              },
                                            ],
                                            sx: {
                                              [`& .${tooltipClasses.tooltip}`]:
                                                {
                                                  fontSize: 12,
                                                  background: `var(--brand_white)`,
                                                  padding: '10px',
                                                  color: `var(--brand_black)`,
                                                  fontWeight: 600
                                                },
                                              [`& .${tooltipClasses.arrow}`]: {
                                                color: `var(--brand_white)`,
                                              },
                                            },
                                          },
                                        }}
                                      >
                                        <td
                                          className={cell.column.id}
                                          {...cell.getCellProps()}
                                        >
                                          {cell.render('Cell')}
                                        </td>
                                      </Tooltip>
                                    ) : (
                                      <td
                                        {...cell.getCellProps()}
                                        className={`${cell.column.id}
                                        ${
                                          viewDevices?.includes(
                                            row?.original?.deviceId
                                          ) &&
                                          (cell?.column?.id ===
                                            'cameraOptions' ||
                                            cellIndex ===
                                              row?.cells?.length - 1)
                                            ? 'selected-cell-right'
                                            : ''
                                        }
                                        ${
                                          viewDevices?.includes(
                                            row?.original?.deviceId
                                          ) &&
                                          (cell?.column?.id === 'viewChecked' ||
                                            cellIndex === 0)
                                            ? 'selected-cell-left'
                                            : ''
                                        }
                                      `}
                                      >
                                        {cell.render('Cell')}
                                      </td>
                                    )}
                                  </>
                                )
                              );
                            })}
                            {snapshot.isDragging ? (
                              <div className="drag-details">
                                {viewDevices?.length &&
                                  viewDevices.map((deviceId, idIndex) => {
                                    setDraggingDeviceId(row?.original.deviceId);
                                    const data = viewDevicesDetails[deviceId];
                                    return idIndex < 6 ? (
                                      <div
                                        className={`drag-details-wrapper drag-opacity-${idIndex}`}
                                      >
                                        <div className="viewChecked">
                                          <MdOutlineDragIndicator
                                            className="drag-icon"
                                            color={getComputedStyle(
                                              document.documentElement
                                            ).getPropertyValue('--brand_white')}
                                          />
                                        </div>
                                        <div className="snapshot">
                                          <span className="snapshot-wrapper">
                                            <CameraWallSnapshot
                                              key={data?.deviceId}
                                              deviceElemId={`camera${data?.deviceId}`}
                                              orgId={data?.orgId}
                                              cdnInfo={cdnInfo}
                                              locationAreaName={
                                                data?.locationAreaNames
                                              }
                                              isHighlighted={false}
                                              showZoomOptions={false}
                                              conStatus={
                                                data?.connectionStatus ||
                                                'offline'
                                              }
                                              hubId={data?.gatewayId}
                                              {...data}
                                            />
                                          </span>
                                        </div>
                                        <div className="deviceName drag-clmn-padding">
                                          {data?.deviceName}
                                        </div>
                                        <div className="locationName drag-clmn-padding">
                                          {data?.locationName}
                                        </div>
                                        <div className="areaName drag-clmn-padding">
                                          {data?.areaName}
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    );
                                  })}
                              </div>
                            ) : null}
                          </tr>
                        );
                      }}
                    </Draggable>
                    {isDragging &&
                      draggingDeviceId === row?.original.deviceId && (
                        <tr
                          {...row.getRowProps()}
                          key={`${index}-index`}
                          className={
                            viewDevices?.includes(row?.original.deviceId)
                              ? 'selected-row'
                              : selectedRow === index
                              ? 'active-row non-Selected-row-hover'
                              : 'non-Selected-row-hover'
                          }
                          onClick={() => setSelectedRow(index)}
                        >
                          <td className='full-line-border'></td>
                          {row.cells.map((cell, cellIndex) => {
                            return (
                              <>
                                {cell.column.id === 'deviceName' ? (
                                  <td
                                    className={cell.column.id}
                                    {...cell.getCellProps()}
                                  >
                                    {cell.render('Cell')}
                                  </td>
                                ) : (
                                  <td
                                    {...cell.getCellProps()}
                                    className={`${cell.column.id}
                                  ${
                                    viewDevices?.includes(
                                      row?.original?.deviceId
                                    ) &&
                                    (cell?.column?.id === 'cameraOptions' ||
                                      cellIndex === row?.cells?.length - 1)
                                      ? 'selected-cell-right'
                                      : ''
                                  }
                                  ${
                                    viewDevices?.includes(
                                      row?.original?.deviceId
                                    ) &&
                                    (cell?.column?.id === 'viewChecked' ||
                                      cellIndex === 0)
                                      ? 'selected-cell-left'
                                      : ''
                                  }
                                `}
                                  >
                                    {cell.render('Cell')}
                                  </td>
                                )}
                              </>
                            );
                          })}
                        </tr>
                      )}
                  </>
                );
              })}
            </tbody>
          )}
        </Droppable>
      </table>
    </div>
  );
};

export default CameraWallTable;
