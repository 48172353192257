import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Col, Row } from 'react-bootstrap';
import { PrimaryButton } from '../../../components/common';
import { constants } from '../../../helpers';

import './modalForms.scss';

const UpdateView = ({
  currentViewLayout,
  saveAsNewCallBack,
  updateViewCallback,
  openModal,
}) => {
  const [showLoader, setShowLoader] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const saveNewViewHandler = async () => {
    if (saveAsNewCallBack) {
      openModal && openModal(false);
      saveAsNewCallBack && saveAsNewCallBack(true);
    }
  };

  const updateViewHandler = async () => {
    if (updateViewCallback) {
      let result = await updateViewCallback(setShowLoader);
      if (!!result) {
        setFormSubmitted(true);
        openModal && openModal(false);
      } else {
        // TODO: generate error message
      }
    } else {
      setShowLoader(false);
    }
  };

  return (
    <>
      <Row>
        <div className="mb-3 update-view-modal-description">
          {constants.CAMERAS_VIDEO_WALL_UPDATE_VIEW_MODAL_DESCRIPTION}
        </div>
      </Row>
      <Row>
        <Col>
          <div className="update-view-button-wrapper">
            <Button
              variant="outline-primary"
              className="update-btn"
              type="button"
              onClick={() => saveNewViewHandler()}
            >
              {
                constants.CAMERAS_VIDEO_WALL_UPDATE_VIEW_MODAL_SAVE_NEW_BUTTON_LABEL
              }
            </Button>
            <PrimaryButton
              className="update-btn"
              width={'150px'}
              type="button"
              loader={showLoader}
              onClick={() => updateViewHandler()}
            >
              {
                constants.CAMERAS_VIDEO_WALL_UPDATE_VIEW_MODAL_UPDATE_BUTTON_LABEL
              }
            </PrimaryButton>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UpdateView;
