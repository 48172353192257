import { RxCross1 } from 'react-icons/rx';
import { BiArrowBack } from 'react-icons/bi';
import './stepsnotification.scss';
import { useState } from 'react';
import { AppDefaults, constants, roles } from '../../../helpers';
import { useOrganizations } from '../../../store/OrganizationsStore';

const StepIndicator = (props) => {
  const title = localStorage.getItem('titleNotification') !== null;
  const [searchInput, setSearchInput] = useState(
    title ? localStorage.getItem('titleNotification') : ''
  );
  const loggedInUserRole = useOrganizations((state) => state.loggedInUserRole);

  const onHandleChange = (e) => {
    if (e != undefined) {
      setSearchInput(e?.target?.value);
      localStorage.setItem('titleNotification', e?.target?.value);
      if (props?.stepSix) {
        props.onInputChange(e?.target?.value);
      }
    }
  };

  return (
    <div>
      <div className="steps-header-conatiner">
        <div className="header-container">
          <div className="left-section-layout">
            {props.step != 1 && (
              <div
                onClick={() => props.prevstep()}
                className="back-btn-conatiner"
              >
                <BiArrowBack
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--primary_40')}
                  size={20}
                />
                <div className="back-text">
                  {constants.NOTIFICATION_CREATE_NOTIFICATION_BACK}
                </div>
              </div>
            )}

            <div className="header-title">
              {props?.isEdit
                ? constants.NOTIFICATION_EDIT_NOTIFICATION
                : constants.NOTIFICATION_CREATE_NOTIFICATION}
            </div>
          </div>
          <RxCross1 size={24} onClick={() => props.hideModal()} />
        </div>
        <div className="textbox">
          <input
            value={searchInput}
            onChange={(e) => onHandleChange(e)}
            style={{ border: 'none', width: '100%' }}
            className={`form-control require shadow-none ${
              searchInput ? 'has-value' : ''
            }`}
            placeholder={'Notification Title'}
            autoComplete="off"
            beforeinputicon=""
            afterinputicon=""
            maxLength={AppDefaults.NOTIFICATION_CHAR_LIMIT}
          />
        </div>
      </div>
      <div className="step-indicator-conatiner">
        {[...Array(loggedInUserRole === roles.ROLE2VMS ? 6 : 4)].map(
          (item, rowIndex) => {
            return (
              <div
                key={rowIndex}
                className={`step-indicator ${
                  loggedInUserRole === roles.ROLE3VMS ? 'custmer-user' : ''
                } ${props.step >= rowIndex + 1 && 'selected'}`}
              ></div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default StepIndicator;
