import React, { useState, useRef, useEffect } from 'react';
import { Calendar } from 'react-multi-date-picker';
import moment from 'moment';
import { PrimaryButton } from '.';
import DatePicker from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import { LuClock5 } from 'react-icons/lu';
import { constants } from '../../helpers';
import { Row, Col } from 'react-bootstrap';
import Tabs from './Tabs';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { TbCloudLock } from 'react-icons/tb';
import './DateTimePickerEvents.scss';

const DateTimePickerEvents = ({
  date,
  minDate,
  time,
  enableDarkTheme = false,
  onSubmit,
  onValueChange,
}) => {
  // Custom variables starts
  const today = new Date();
  const yesterday = new Date(today);
  const minDateCalendar = new Date('1990-01-01');
  const latestDate = new Date(date);
  let lastSelectedDate = new Date(latestDate);

  if (
    !lastSelectedDate ||
    JSON.stringify(lastSelectedDate)?.includes('undefined') ||
    isNaN(lastSelectedDate.getTime())
  ) {
    lastSelectedDate = yesterday;
  }
  // Custom variables ends

  const [activeSection, setActiveSection] = useState(
    date?.length > 15 && !JSON.stringify(date)?.includes(undefined) ? 1 : 0
  );
  const [activeSectionCustom, setActiveSectionCustom] = useState(0);
  let [selectedDate, setSelectedDate] = useState(lastSelectedDate);
  let [selectedTime, setSelectedTime] = useState(time);
  const timePickerRef = useRef();

  let [formattedStartDate, setFormattedStartDate] = useState(0);
  let [formattedEndDate, setFormattedEndDate] = useState(0);
  let [latestSelectedDateRangeStart, setLatestSelectedDateRangeStart] =
    useState(0);
  let [latestSelectedDateRangeEnd, setLatestSelectedDateRangeEnd] = useState(0);

  useEffect(() => {
    if (activeSection === 1) {
      const yesterday = new Date();

      const startDate = new Date(yesterday);
      startDate.setDate(startDate.getDate() - 3);

      const endDate = new Date(yesterday);
      endDate.setDate(endDate.getDate() - 1);

      const parsedStartDate = startDate;
      const parsedEndDate = endDate;

      if (
        !isNaN(parsedStartDate.getTime()) &&
        !isNaN(parsedEndDate.getTime())
      ) {
        const formattedStartDate = parsedStartDate.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });
        const formattedEndDate = parsedEndDate.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });

        setFormattedStartDate(formattedStartDate);
        setFormattedEndDate(formattedEndDate);

        const latestDateRangeStart = new Date(formattedStartDate);
        const latestDateRangeEnd = new Date(formattedEndDate);

        setLatestSelectedDateRangeStart(latestDateRangeStart);
        setLatestSelectedDateRangeEnd(latestDateRangeEnd);

        setSelectedDate([latestDateRangeStart, latestDateRangeEnd]);
      }
    } else {
      const yesterday = new Date(today);
      lastSelectedDate = yesterday;
    }
  }, [activeSection]);

  useEffect(() => {
    if (date?.length > 15 && !date?.includes('undefined')) {
      const [startDate, endDate] = date.split(' - ');
      const parsedStartDate = new Date(startDate);
      const parsedEndDate = new Date(endDate);
      if (
        !isNaN(parsedStartDate.getTime()) &&
        !isNaN(parsedEndDate.getTime())
      ) {
        formattedStartDate = parsedStartDate.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });
        setFormattedStartDate(formattedStartDate);
        formattedEndDate = parsedEndDate.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });
        setFormattedEndDate(formattedEndDate);
      }
      const latestDateRangeStart = new Date(formattedStartDate);
      latestSelectedDateRangeStart = new Date(latestDateRangeStart);
      setLatestSelectedDateRangeStart(latestSelectedDateRangeStart);

      const latestDateRangeEnd = new Date(formattedEndDate);
      latestSelectedDateRangeEnd = new Date(latestDateRangeEnd);
      setLatestSelectedDateRangeEnd(latestSelectedDateRangeEnd);
      // pre selection starts
      setSelectedDate([
        latestSelectedDateRangeStart,
        latestSelectedDateRangeEnd,
      ]);
      // Initially pre select single day as yesterday if selected date is less than minimum date
      //  let minDate = new Date(moment(new Date(yesterday)).subtract({ days: 90 }))
      if (!latestSelectedDateRangeStart) {
        setSelectedDate(yesterday);
      }
    }
  }, [date]);

  useEffect(() => {
    onValueChange({
      selectedDate: selectedDate,
      selectedTime: selectedTime,
      filterdate: date,
    });
  }, [selectedDate]);

  const onApply = () => {
    const time = new Date(selectedTime);
    const date = new Date(selectedDate);
    date.setHours(time.getHours());
    date.setMinutes(time.getMinutes());
    date.setSeconds(time.getSeconds());
    onSubmit({
      selectedDate: selectedDate,
      selectedTime: selectedTime,
      filterdate: date,
    });
  };

  const onClickTimePicker = () => {
    if (!timePickerRef.current.isOpen) {
      timePickerRef.current.openCalendar();
    } else {
      timePickerRef.current.closeCalendar();
    }
  };

  const onTimeChange = (e) => {
    setSelectedTime(e ? e : new Date().setHours(0, 0, 0));
  };

  const setSectionBlock = (index) => {
    setActiveSection(index);
  };

  const tabs = [
    {
      label: constants.EVENT_SIGNLE_DAY_BUTTON,
      clickHandler: (index) => {
        setSectionBlock(index);
        setSelectedDate(new Date(lastSelectedDate));
      },
    },
    {
      label: constants.EVENT_DATE_RANGE_BUTTON,
      clickHandler: (index) => {
        setSectionBlock(index);
        setSelectedDate([
          latestSelectedDateRangeStart,
          latestSelectedDateRangeEnd,
        ]);
      },
    },
  ];

  const isButtonDisabled = () => {
    if (activeSection === 0) {
      return !selectedDate;
    } else {
      let selectedDateInvalid = false;
      if (!selectedDate[0] || !selectedDate[1]) {
        selectedDateInvalid = true;
      }
      return !(selectedDate?.length === 2 && !selectedDateInvalid);
    }
  };

  return (
    <div
      className={`date-time-picker ${enableDarkTheme === true ? 'dark' : ''}`}
    >
      <div>
        <Row className="channel-details device-tabs">
          <Col>
            <Tabs defaultActive={activeSection} tabCollection={tabs} />
          </Col>
        </Row>
      </div>
      <div>
        {activeSection === 0 ? (
          <Calendar
            value={selectedDate}
            onChange={setSelectedDate}
            minDate={new Date(moment(new Date(minDateCalendar)))}
            maxDate={new Date()}
            shadow={false}
            highlightToday={false}
            weekStartDayIndex={1}
            headerOrder={['LEFT_BUTTON', 'MONTH_YEAR', 'RIGHT_BUTTON']}
            className="custom-calendar"
          />
        ) : (
          <Calendar
            value={selectedDate}
            onChange={setSelectedDate}
            minDate={new Date(moment(new Date(minDateCalendar)))}
            maxDate={new Date()}
            shadow={false}
            highlightToday={false}
            weekStartDayIndex={1}
            headerOrder={['LEFT_BUTTON', 'MONTH_YEAR', 'RIGHT_BUTTON']}
            className="custom-calendar"
            range
          />
        )}
      </div>
      <div className="margin-event-calender">
        <PrimaryButton
          className="btn btn-primary mt-4"
          type="submit"
          width="100%"
          height="44px"
          fontSize="0.875rem"
          lineHeight="24px"
          onClick={onApply}
          disabled={isButtonDisabled()}
        >
          {constants.CAMERA_APPLY_DATE_FILTER}
        </PrimaryButton>
      </div>
    </div>
  );
};
export default DateTimePickerEvents;
