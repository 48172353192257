import { generatePlaybackPayload, getUnixDate } from '../../../../../helpers/commonUtils';
import * as webRTCHandler from '../webRTCHandler/webRTCwsHandler';
import { v4 as uuidv4 } from 'uuid';
import {
  clearWSSConnections,
  getWSSConnections,
  removeWSSConnections,
  setWSSConnection,
  setWSSConnections,
} from '../../../../../store/reducers/StreamingReducer';
import { getNoPlaybackContentAvailable, setNoPlaybackContentAvailable } from '../../../../../store/reducers/ViewsReducer';
import store from '../../../../../store/Store';
import { constants } from '../../../../../helpers';

const handleIncomingError = (error) => {
  console.log('Playback: error', {
    "cid": error?.cid ?? '',
    "dev_id": error?.msg?.properties?.dev_id ?? '',
    "org_id": error?.msg?.properties?.org_id ?? '',
    "code": error?.msg?.properties?.code ?? '',
    "description": error?.msg?.properties?.desc ?? ''
  });
};

const multiLiveDevices = [];

export const connectWithWebSocket = (cdnDetails, deviceId, orgId, accountId) => {
  const wssConnections =
    store.getState(getWSSConnections)?.streaming?.wssConnections;
  let wsClient;
  if (wssConnections?.[deviceId]) {
    wsClient = wssConnections[deviceId];
  } else {
    let serverDetails = cdnDetails;
    const playbackServer = serverDetails?.timeline_server;
    const jwt_token = playbackServer?.token;
    wsClient = new WebSocket(
      `${playbackServer?.protocol}://${playbackServer?.host}:${playbackServer?.port}`
    );
    // TODO: Delete later - Added below static URL for testing purpose
    // wsClient = new WebSocket(`wss://playback-json.duclo.net`);
    wsClient.binaryType = 'arraybuffer';
    wsClient.onerror = function (error) {
      // TODO: Delete later
      console.log('Playback: Connection Error', error);
    };

    wsClient.onopen = function () {
      store.dispatch(setWSSConnection(true));
      if (wsClient.readyState === 1) {
        const playback_uuid = uuidv4();
        const correlation_id = uuidv4();
        const register_data = {
          playback_uuid,
          correlation_id,
          device_id: deviceId,
          accountId: accountId,
          type: constants.WEBSOCKET_PLAYBACK_EVENT_REGISTER,
          org_id: orgId,
          jwt_token
        };
        multiLiveDevices.push(register_data);
        const register_payload = generatePlaybackPayload(register_data);
        wsClient.send(JSON.stringify(register_payload));
      }
    };

    wsClient.onclose = function (e) {
      store.dispatch(setWSSConnection(false));
      store.dispatch(clearWSSConnections());
      sendPauseCVR(deviceId);
    };

    wsClient.onmessage = function (event) {
      const data = JSON.parse(event.data);

      switch (data?.msg?.properties?.type) {
        case 'REGISTERED':
          console.log("Playback: multi live registered");
          return;

        case 'READY':
          webRTCHandler.getLocalStream(serverDetails.p2p_server, deviceId);
          return;

        case 'answer':
            webRTCHandler.handleAnswer(data.msg.properties, deviceId);
            return;
    
        case 'ice' :
            webRTCHandler.handleCandidate(data.msg.properties, deviceId);
            return;
            
        default:
          if (data.msg.properties.type === 'ERROR') {
            handleIncomingError(data);
            if (data?.msg?.properties?.code === 1004) {
              const noData = [];
              noData.push(data?.msg?.properties?.dev_id);
              const mergedArr = [...store.getState(getNoPlaybackContentAvailable)?.views?.noPlaybackContentAvailable, ...noData]
              store.dispatch(
                setNoPlaybackContentAvailable(mergedArr)
              );
            }
            return;
          }
          // Handle incoming JSON SDP and ICE messages
          let msg;
          try {
            msg = JSON.parse(event.data);
          } catch (e) {
            if (e instanceof SyntaxError) {
              handleIncomingError('Error parsing incoming JSON: ' + event.data);
            } else {
              handleIncomingError(
                'Unknown error parsing response: ' + event.data
              );
            }
            return;
          }

          // if (msg.sdp != null) {
          //   webRTCHandler.handleAnswer(msg, deviceId);
          // } else if (msg.ice != null) {
          //   webRTCHandler.handleCandidate(msg, deviceId);
          // } else {
          //   handleIncomingError('Unknown incoming JSON: ' + msg);
          // }
          // return;
      }
    };
    store.dispatch(setWSSConnections({ id: deviceId, client: wsClient }));
  }
};

export const sendPlayCVR = (time, deviceId) => {
  const selectedDevice = multiLiveDevices.find((device) => device.device_id === deviceId);
  const actualTime = getUnixDate(time);
  const data = {
    playback_uuid: selectedDevice?.playback_uuid,
    correlation_id: selectedDevice?.correlation_id,
    device_id: selectedDevice?.device_id,
    accountId: selectedDevice?.accountId,
    actualTime,
    type: constants.WEBSOCKET_PLAYBACK_EVENT_PLAY,
    orgId: selectedDevice?.org_id
  };
  const play_payload = generatePlaybackPayload(data);
  const wssConnections = store.getState(getWSSConnections)?.streaming?.wssConnections;
  const wsClient = wssConnections[deviceId];
  if (wsClient && wsClient.readyState === 1) {
    wsClient.send(JSON.stringify(play_payload));
  }
};

export const sendPauseCVR = (deviceId) => {
  const selectedDevice = multiLiveDevices.find((device) => device.device_id === deviceId);
  const data = {
    playback_uuid: selectedDevice?.playback_uuid,
    correlation_id: selectedDevice?.correlation_id,
    device_id: selectedDevice?.device_id,
    accountId: selectedDevice?.accountId,
    type: constants.WEBSOCKET_PLAYBACK_EVENT_STOP,
    orgId: selectedDevice?.org_id
  };
  const pause_payload = generatePlaybackPayload(data);
  const wssConnections = store.getState(getWSSConnections)?.streaming?.wssConnections;
  const wsClient = wssConnections?.[deviceId];
  webRTCHandler.handleLeaveCall(deviceId);
  if (wsClient && wsClient.readyState === 1) {
    wsClient.send(JSON.stringify(pause_payload));
  }
};

export const sendWebRTCOffer = (data, deviceId) => {
  const selectedDevice = multiLiveDevices.find((device) => device.device_id === deviceId);
  const offer_data = {
    playback_uuid: selectedDevice?.playback_uuid,
    correlation_id: selectedDevice?.correlation_id,
    device_id: selectedDevice?.device_id,
    accountId: selectedDevice?.accountId,
    type: constants.WEBSOCKET_PLAYBACK_EVENT_OFFER,
    orgId: selectedDevice?.org_id,
    sdp: data.sdp
  };
  const offer_payload = generatePlaybackPayload(offer_data);
  const wssConnections = store.getState(getWSSConnections)?.streaming?.wssConnections;
  const wsClient = wssConnections?.[deviceId];
  if(wsClient) {
    wsClient.send(JSON.stringify(offer_payload));
  }
};

export const sendWebRTCCandidate = (event, deviceId) => {
  const selectedDevice = multiLiveDevices.find((device) => device.device_id === deviceId);
  const candidate_data = {
    playback_uuid: selectedDevice?.playback_uuid,
    correlation_id: selectedDevice?.correlation_id,
    device_id: selectedDevice?.device_id,
    accountId: selectedDevice?.accountId,
    type: constants.WEBSOCKET_PLAYBACK_EVENT_ICE,
    orgId: selectedDevice?.org_id,
    candidate: event.candidate
  };
  const candidate_payload = generatePlaybackPayload(candidate_data);
  const wssConnections = store.getState(getWSSConnections)?.streaming?.wssConnections;
  const wsClient = wssConnections?.[deviceId];
  if(wsClient) {
    wsClient.send(JSON.stringify(candidate_payload));
  }
};

export const disconnectWithWebSocket = (deviceId) => {
  const wssConnections =
    store.getState(getWSSConnections)?.streaming?.wssConnections;
  const wsClient = wssConnections?.[deviceId];
  if (wsClient && wsClient.close) {
    // TODO: will remove later wsClient.send('STOP');
    wsClient.close();
  }
  store.dispatch(removeWSSConnections(deviceId));
  webRTCHandler.handleLeaveCall(deviceId);
};

export const checkWSSConnection = (deviceId) => {
  const wssConnections =
    store.getState(getWSSConnections)?.streaming?.wssConnections;
  const wsClient = wssConnections?.[deviceId];
  if (wsClient) return true;
};
