import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import { TextField } from '../../../components/forms';
import { PrimaryButton } from '../../../components/common';
import { constants } from '../../../helpers';

import './modalForms.scss';

const SaveAsNewView = ({ setViewName, callBack, openModal }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [showLoader, setShowLoader] = useState(false);
  const [viewNameValue, setViewNameValue] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const saveName = (e) => {
    const val = e?.target?.value;

    setViewNameValue(val);
    // Send the name of the view back to ViewWall parent component
    setViewName && setViewName(val);
  };

  const saveViewHandler = async () => {
    setShowLoader(true);

    if (callBack) {
      try {
        let result = await callBack(setShowLoader);

        if (!!result) {
          setFormSubmitted(true);
          openModal && openModal(false);
        } else {
          // TODO: generate error message
        }
      } catch (err) {
        // TODO: generate error message
        console.error(err);
      } finally {
        setShowLoader(false);
      }
    } else {
      setShowLoader(false);
    }
  };

  return (
    <>
      {!formSubmitted && (
        <form onSubmit={handleSubmit(saveViewHandler)}>
          <Row>
            <div className="mb-3 save-view-modal-description">
              {constants.CAMERAS_VIDEO_WALL_SAVE_VIEW_MODAL_DESCRIPTION}
            </div>
          </Row>
          <Row>
            <Col>
              <div className="mb-3 view-name-input-wrapper">
                <input
                  {...register('viewName', { required: true })}
                  onChange={(e) => saveName(e)}
                  placeholder={
                    constants.CAMERAS_VIDEO_WALL_SAVE_VIEW_MODAL_DEFAULT_VIEW_NAME_TITLE
                  }
                  className="view-name-input"
                />
                {errors.viewName && (
                  <div>
                    {constants.CAMERAS_VIDEO_WALL_SPECIFY_VIEW_NAME_ERROR}
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="save-view-button-wrapper">
                <PrimaryButton
                  className="save-btn"
                  loader={showLoader}
                  type="submit"
                  disabled={!viewNameValue}
                >
                  {constants.CAMERAS_VIDEO_WALL_SAVE_VIEW_BUTTON_LABEL}
                </PrimaryButton>
              </div>
            </Col>
          </Row>
        </form>
      )}
    </>
  );
};

export default SaveAsNewView;
