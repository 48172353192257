import { useSearchParams } from 'react-router-dom';
import {
  BreadcrumbList,
  ContextMenu,
  Header,
  PrimaryButton,
  SiteModal,
} from '../../components/common';
import { Col, Row, Container, Form } from 'react-bootstrap';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import { AppDefaults, Utils, constants, roles } from '../../helpers';
import { useOrganizations } from '../../store/OrganizationsStore';
import { useEffect, useMemo, useRef, useState } from 'react';
import { UploadCsvIcon } from '../../assets/images';
import { PosStore } from '../../store/PosStore';
import StepsPos from './SetupPos/StepsPos';
import axios from 'axios';
import './posintegration.scss';
import InfiniteScroll from 'react-infinite-scroller';
import PosListTable from './SetupPos/PosListTable';
import DeletePosFileModal from './SetupPos/DeletePosFileModal';
import { RxCrossCircled, RxCheckCircled } from 'react-icons/rx';
import md5 from 'crypto-js/md5';
import CryptoJS from 'crypto-js';
import EditStepPos from './SetupPos/EditStepPos';
import EditStoreLocation from './SetupPos/EditStoreLocation';
import { PiWarningCircle } from 'react-icons/pi';
import { getAllMqttDataFromResponse } from '../../../src/store/reducers/AccountReducer';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { RxCross2 } from 'react-icons/rx';
import UploadWarning from './SetupPos/UploadWarning';

function PosIntegration() {
  const intervalRef = useRef();
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  const [searchParams] = useSearchParams();
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [itemSelected, setItemSelected] = useState({});
  const [currentOrg, setCurrentOrg] = useState({});
  const [currentOrgPosList, setCurrentOrgPosList] = useState([]);
  const [hasMoreItems, sethasMoreItems] = useState(true);
  const [editMappingModal, setEditMappingModal] = useState(false);
  const [uploadWarningModal, setUploadWarningModal] = useState(false);
  const [editStoreLocationModal, setEditStoreLocationModal] = useState(false);
  const [isFromEdit, setIsFromEdit] = useState(false);
  const [mappingData, setMappingData] = useState([]);
  const [locationStoreData, setLocationStoreData] = useState([]);
  const orgId = searchParams.get('orgId');
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData
  );
  const setSelfStatus = useOrganizations((state) => state.setSelfStatus);
  const loggedInUser = getLoggedInUserData();
  const getCustomerOrgData = useOrganizations(
    (state) => state.getCustomerOrgData
  );
  const setAtrributeList = PosStore((state) => state.setAtrributeList);
  const resetPosDataList = PosStore((state) => state.resetPosDataList);
  const setIsheaderChecked = PosStore((state) => state.setIsheaderChecked);
  const [showLoader, setShowLoader] = useState(false);
  const [showFailedMqttData, setShowFailedMqttData] = useState({});
  const uploadStatus = localStorage.getItem('uploadStatus');
  //localStorage.setItem('uploadStatus',0)
  const [replaceData, setReplaceData] = useState(false);

  const [showUploadLoader, setShowUploadLoader] = useState(
    uploadStatus == null || uploadStatus == '0'
      ? false
      : uploadStatus != null && uploadStatus == '1'
      ? true
      : false
  );
  const breadList = [
    {
      url: '/customers/manage.html',
      title:
        loggedInUser.role !== roles.ROLE2VMS
          ? constants.MANAGE_ORG_PAGE_TITLE
          : constants.MANAGE_ORG_PAGE_ORG_TITLE,
    },
    {
      url: `/customers/dashboard.html?orgId=${orgId}`,
      title: currentOrg?.orgName,
    },
    {
      url: `/customers/editOrganization.html?orgId=${orgId}`,
      title: 'Edit Organization',
    },
    {
      url: `/app/pos?orgId=${orgId}`,
      title: 'POS Integration',
    },
  ];

  useEffect(() => {
    localStorage.removeItem('posOrgId');
    setSelfStatus('1');
    const bacgroundTaskCheck = setInterval(() => {
      const uploadStatus = localStorage.getItem('uploadStatus');
      if (uploadStatus == null || uploadStatus == '0') {
        setShowUploadLoader(false);
      } else if (uploadStatus != null && uploadStatus == '1') {
        setShowUploadLoader(true);
      }
    }, 500);
    intervalRef.current = bacgroundTaskCheck;

    return () => {
      if (intervalRef?.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const handleCloseModal = () => {
    setIsFromEdit(false);
    resetPosDataList();
    setIsheaderChecked(0);
    setShowModal(false);
  };
  const handleCloseModalDelete = () => {
    setShowModalDelete(false);
  };
  const deleteRecords = () => {
    handleCloseModalDelete();
    getMapping();
  };
  const handleCloseModalEdit = () => {
    setEditMappingModal(false);
  };
  const handleCloseModalStore = () => {
    setEditStoreLocationModal(false);
  };
  const handleCloseUploadWarningModal = () => {
    setUploadWarningModal(false);
  };
  const goToSetupAgain = () => {
    setEditMappingModal(false);
    setIsFromEdit(true);
  };

  useEffect(() => {
    if (isFromEdit) {
      setShowModal(true);
    }
  }, [isFromEdit]);

  useEffect(() => {
    resetPosDataList();
    setIsheaderChecked(0);
  }, []);

  useEffect(() => {
    const currentOrg = getCustomerOrgData()?.find(
      (user) => user?.orgId === orgId
    );
    setCurrentOrg(currentOrg);
    getMapping();
  }, [getCustomerOrgData()]);

  const getMapping = async () => {
    setReplaceData(false);
    let response = await axios.get(
      `/partner/orgs/${orgId}/pos`,
      Utils.requestHeader()
    );
    let resData = response?.data;
    if (resData?.meta.code === 200) {
      if (resData?.data?.records?.length > 0) {
        setCurrentOrgPosList(resData?.data?.records);
      } else {
        setCurrentOrgPosList([]);
      }
    }
  };
  useEffect(() => {
    updateStatusFileRecord(allMqttData);
  }, [allMqttData]);

  const updateStatusFileRecord = async (allMqttData) => {
    if (allMqttData?.msg?.action === 'deleted') {
      return;
    }
    const responseDeviceId = allMqttData?.msg?.resource
      ?.toString()
      ?.split('/')?.[1];
    const isPos = allMqttData?.msg?.resource?.toString()?.split('/')?.[0];
    console.log('allMqttData', allMqttData);//TODO DELETE LATER
    if (
      isPos &&
      isPos === 'pos' &&
      responseDeviceId &&
      responseDeviceId != undefined &&
      responseDeviceId !== ''
    ) {
      let posData = currentOrgPosList?.map((posItem) => {
        if (posItem?.id === responseDeviceId) {
          if (
            allMqttData?.msg?.properties?.data?.status ===
            AppDefaults?.POS_STATUS_COMPLETED
          ) {
            return {
              ...posItem,
              status: allMqttData?.msg?.properties?.data?.status,
              errorMessage: '',
              totalSucceeded:
                allMqttData?.msg?.properties?.data?.totalSucceeded,
              totalRecords: allMqttData?.msg?.properties?.data?.totalRecords,
            };
          } else if (
            allMqttData?.msg?.properties?.data?.status ===
            AppDefaults?.POS_STATUS_FAILED
          ) {
            return {
              ...posItem,
              status: allMqttData?.msg?.properties?.data?.status,
              errorMessage: allMqttData?.msg?.properties?.data?.errorMessage,
              totalSucceeded:
                allMqttData?.msg?.properties?.data?.totalSucceeded,
              totalRecords: allMqttData?.msg?.properties?.data?.totalRecords,
            };
          } else {
            return {
              ...posItem,
              status: allMqttData?.msg?.properties?.data?.status,
              errorMessage: allMqttData?.msg?.properties?.data?.errorMessage,
            };
          }
        } else {
          return { ...posItem };
        }
      });
      let updatedPosList = await Promise.all(posData);
      if (updatedPosList?.length > 0) {
        setCurrentOrgPosList(updatedPosList);
        if (
          allMqttData?.msg?.properties?.data?.status ===
          AppDefaults?.POS_STATUS_FAILED
        ) {
          setShowFailedMqttData(allMqttData?.msg?.properties?.data);
        }
      }
    }
  };

  const loadMore = () => {};

  const showItems = (dataList) => {
    return <PosListTable columns={columnsData} data={dataList} />;
  };

  const getMenuItems = (status) => {
    if (status === AppDefaults?.POS_STATUS_STALLED) {
      return menuItems;
    } else if (
      status === AppDefaults?.POS_STATUS_COMPLETED ||
      status === AppDefaults?.POS_STATUS_FAILED
    ) {
      return menuItems?.filter((item) => item?.text != 'Resume');
    } else if (status === AppDefaults?.POS_STATUS_IN_PROGRESS) {
      return menuItems?.filter(
        (item) => item?.text != 'Resume' && item?.text != 'Replace'
      );
    }
  };

  const menuItems = [
    {
      text: 'Resume',
      clickHandler: (e, data, i) => {
        if (data?.cell?.row?.original?.id) {
          setReplaceData(data?.cell?.row?.original);
          setUploadWarningModal(true);
        }
      },
    },
    {
      text: 'Replace',
      clickHandler: (e, data, i) => {
        if (data?.cell?.row?.original?.id) {
          setReplaceData(data?.cell?.row?.original);
          setUploadWarningModal(true);
        }
      },
    },
    {
      text: constants.DEVICES_TAB_NETWORK_MAXIMUM_DELETE,
      clickHandler: (e, data, i) => {
        setShowFailedMqttData({});
        setItemSelected(data?.cell?.row?.original);
        setShowModalDelete(true);
      },
    },
  ];

  const columnsData = useMemo(
    () => [
      {
        Header: 'File Name',
        accessor: 'fileName',
        Cell: (props) => {
          if (props.value) {
            return <div className="table-cell-file-name">{props.value}</div>;
          } else return '-';
        },
      },
      {
        Header: 'Date & Time of Upload',
        accessor: 'createdDate',
        Cell: (props) => {
          if (props.value) {
            return (
              <div className="table-cell-file-name">
                {moment
                  .tz(moment(new Date(props.value)), moment.tz.guess())
                  .format('DD MMMM YYYY h:mm A z')}
              </div>
            );
          } else return '-';
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (props) => {
          if (props.value) {
            return (
              <div className="table-cell-status-pos">
                <div
                  className={`status-icon-layout ${
                    props.value === AppDefaults?.POS_STATUS_COMPLETED
                      ? 'complete'
                      : (props.value === AppDefaults?.POS_STATUS_STALLED ||
                          props.value ===
                            AppDefaults?.POS_STATUS_IN_PROGRESS) &&
                        'stalled'
                  }`}
                >
                  {props.value === AppDefaults?.POS_STATUS_COMPLETED ? (
                    <RxCheckCircled strokeWidth="0.2" size={18} />
                  ) : props.value === AppDefaults?.POS_STATUS_STALLED ||
                    props.value === AppDefaults?.POS_STATUS_IN_PROGRESS ? (
                    <PiWarningCircle strokeWidth="0.2" size={18} />
                  ) : (
                    <RxCrossCircled strokeWidth="0.2" size={18} />
                  )}
                </div>
                <div className="status-message-layout">
                  <div className="title-status">
                    {props.value === AppDefaults?.POS_STATUS_IN_PROGRESS
                      ? 'In Progress'
                      : props.value.charAt(0).toUpperCase() +
                        props.value.slice(1).toLowerCase()}
                  </div>
                  {props.value !== AppDefaults?.POS_STATUS_IN_PROGRESS &&
                    props?.cell?.row?.original?.errorMessage &&
                    props?.cell?.row?.original?.errorMessage != '' && (
                      <div className="title-status-message">
                        {props?.cell?.row?.original?.errorMessage}
                      </div>
                    )}
                </div>
              </div>
            );
          } else return '-';
        },
      },

      {
        Header: 'Records Uploaded',
        accessor: 'totalSucceeded',
        Cell: (props) => {
          if (props?.value) {
            return (
              <div className="table-cell-file-name">
                {props.value} of {props?.cell?.row?.original?.totalRecords}
              </div>
            );
          } else if (
            props?.value == 0 &&
            props?.cell?.row?.original?.totalRecords
          ) {
            return (
              <div className="table-cell-file-name">
                {0} of {props?.cell?.row?.original?.totalRecords}
              </div>
            );
          } else {
            return 'N.A.';
          }
        },
      },
      {
        Header: '',
        accessor: 'menu',
        Cell: (props) => {
          return (
            props?.cell?.row?.original?.status !==
              AppDefaults?.POS_STATUS_IN_PROGRESS && 
              (
              <div key={`action-field-${orgId}`} className="action-field">
                <ContextMenu
                  menuId={orgId}
                  menuItems={getMenuItems(props?.cell?.row?.original?.status)}
                  customData={props}
                />
              </div>
            )
          );
        },
      },
    ],
    []
  );

  const getMappingData = async () => {
    try {
      const res = await axios.get(`/partner/orgs/${orgId}/pos/attributes`, {
        ...Utils.requestHeader(),
      });
      const responseData = res?.data;
      if (responseData?.meta?.code === 200) {
        if (responseData?.data?.attributes?.length > 0) {
          setAtrributeList(responseData?.data?.attributes);
        }
      }
    } catch (error) {}
    let response = await axios.get(
      `/partner/orgs/${orgId}/pos/mapping/fields`,
      Utils.requestHeader()
    );

    let resData = response?.data;

    if (resData?.meta.code === 200) {
      setMappingData(resData?.data?.fields);
      if (resData?.data?.header) {
        setIsheaderChecked(1);
      } else {
        setIsheaderChecked(0);
      }
      setShowLoader(false);
      setShowFailedMqttData({});
      setTimeout(() => {
        setEditMappingModal(true);
      }, 100);
    } else {
      setMappingData([]);
      setShowLoader(false);
    }
  };

  const getStoreLocationData = async () => {
    let response = await axios.get(
      `/partner/orgs/${orgId}/stores`,
      Utils.requestHeader()
    );

    let resData = response?.data;

    if (resData?.meta.code === 200) {
      if (resData?.data?.stores?.length > 0) {
        updateItemAtIndex(resData?.data?.stores);
        setShowFailedMqttData({});
        setTimeout(() => {
          setEditStoreLocationModal(true);
        }, 100);
      }
    } else {
      setLocationStoreData([]);
    }
  };

  const updateItemAtIndex = async (locationStoreList) => {
    let posData = locationStoreList?.map((posItem, index) => {
      if (index === 0) {
        return {
          ...posItem,
          storeUniqeKey: Utils.generateUUID(),
          storeIdCheck: AppDefaults?.POS_DEFUALT_STORE_ID,
        };
      } else {
        return { ...posItem, storeUniqeKey: Utils.generateUUID() };
      }
    });
    let updatedPosList = await Promise.all(posData);
    setLocationStoreData(updatedPosList);
  };
  const uploadStart = async (data) => {
    localStorage.setItem('uploadStatus', '1');
    let response = await axios.get(`/partner/orgs/${orgId}/pos/start`, {
      params: {
        size: data?.size,
        fileName: data?.fileName,
      },
      ...Utils.requestHeader(),
    });
    let resData = response?.data;

    if (resData?.meta.code === 200) {
      uploadFile(resData?.data, data);
    } else {
      localStorage.setItem('uploadStatus', '0');
    }
  };
  const uploadReplace = async (data) => {
    localStorage.setItem('uploadStatus', '1');
    let response = await axios.get(
      `/partner/orgs/${orgId}/pos/${replaceData?.id}/replace`,
      {
        params: {
          size: data?.size,
          fileName: data?.fileName,
        },
        ...Utils.requestHeader(),
      }
    );
    let resData = response?.data;

    if (resData?.meta.code === 200) {
      uploadFile(resData?.data, data);
    } else {
      localStorage.setItem('uploadStatus', '0');
    }
  };

  const completeUploadApiCall = async (uploadData, partsData, checkSumHash) => {
    let response = await axios.put(
      `/partner/orgs/${orgId}/pos/${partsData?.fileId}/complete`,
      {
        sessionId: partsData?.sessionId,
        checksum: checkSumHash,
        parts: uploadData,
      }
    );
    let resData = response?.data;

    if (resData?.meta.code === 200) {
      getMapping();
      localStorage.setItem('uploadStatus', '0');
    } else {
      localStorage.setItem('uploadStatus', '0');
    }
  };

  const calculateMD5 = async (data) => {
    const wordArray = CryptoJS.lib.WordArray.create(data);
    return md5(wordArray).toString(CryptoJS.enc.Hex);
  };

  const readChunk = (file, start, end) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsArrayBuffer(file.slice(start, end));
    });
  };

  const uploadFile = async (partsData, data) => {
    if (!data?.file) return;
    localStorage.setItem('uploadStatus', '1');
    let CHUNK_SIZE;
    const fileSize = data?.file?.size;

    for (let i = 1; i <= Object.keys(partsData?.parts).length; i++) {
      const minChunkSizeInKB =
        partsData?.parts?.[i]?.minChunkSizeInKB === 1
          ? 5000
          : partsData?.parts?.[i]?.minChunkSizeInKB;
      const CHUNK_SIZE_ONE_MB = minChunkSizeInKB / 1000;
      CHUNK_SIZE = CHUNK_SIZE_ONE_MB * 1024 * 1024;
    }

    const reader = new FileReader();
    let checkSumHash = '';
    reader.onload = async (event) => {
      const arrayBuffer = event.target.result;
      checkSumHash = await calculateMD5(arrayBuffer);
    };

    reader.readAsArrayBuffer(data?.file);
    const totalChunks = Math.ceil(fileSize / CHUNK_SIZE);
    let currentChunk = 0;
    let parts = {};
    while (currentChunk < totalChunks) {
      const start = currentChunk * CHUNK_SIZE;
      const end = Math.min(start + CHUNK_SIZE, fileSize);
      const chunk = await readChunk(data?.file, start, end);
      const requestOptions = {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/octet-stream', // or 'text/csv' if required
        },
        body: chunk,
      };

      try {
        const urlIndex = currentChunk + 1;
        const url = partsData?.parts?.[urlIndex]?.url;
        fetch(url, requestOptions)
          .then((response) => {
            if (response.status == 200) {
              const etag = response.headers.get('ETag');
              const etags = etag.replace(/['"]+/g, '');
              let urlObj = new URL(response?.url);
              const searchParams = new URLSearchParams(urlObj.search);
              if (searchParams.has('partNumber')) {
                parts[searchParams.get('partNumber')] = etags;
              }
              if (Object.keys(parts)?.length === totalChunks) {
                completeUploadApiCall(parts, partsData, checkSumHash);
              }
              currentChunk++;
            } else {
              localStorage.setItem('uploadStatus', '0');
              return;
            }
          })
          .catch((error) => {
            localStorage.setItem('uploadStatus', '0');
          });

        currentChunk++;
      } catch (error) {
        localStorage.setItem('uploadStatus', '0');
        // Handle error
        break;
      }
    }
  };

  const handleOpenFile = () => {
    // Check if the ref is assigned and trigger click on the input element
    setUploadWarningModal(false);
    setShowFailedMqttData({});
    if (!replaceData) {
      const fileInput = document.getElementById('fileInput');
      fileInput.click();
    } else {
      const fileInputReplace = document.getElementById('fileInputReplace');
      fileInputReplace.click();
    }
  };

  const handleFileChangeReplace = (event) => {
    event?.preventDefault();
    const file = event.target.files[0];
    event.target.value = null;

    if (file) {
      const fileSizeInBytes = file?.size;
      const fileSizeInKB = fileSizeInBytes / 1024;
      const fileSizeInMB = fileSizeInKB / 1024;
      const fileSizeInGB = fileSizeInMB / 1024;

      if (fileSizeInBytes < 1024 || fileSizeInKB < 1024) {
        const fileData = {
          size: 1,
          fileName: file?.name,
          file: file,
        };
        uploadReplace(fileData);
      } else if (fileSizeInMB < 1024) {
        const fileData = {
          size: Math.ceil(fileSizeInMB),
          fileName: file?.name,
          file: file,
        };
        uploadReplace(fileData);
      } else {
        const convertToMb = Math.ceil(fileSizeInGB) * 1024;
        const fileData = {
          size: Math.ceil(convertToMb),
          fileName: file?.name,
          file: file,
        };
        uploadReplace(fileData);
      }
    }
  };

  const handleFileChange = (event) => {
    event?.preventDefault();
    //event?.stopPropagation();
    const file = event.target.files[0]; // Access the selected file
    event.target.value = null;
    if (file) {
      const fileSizeInBytes = file?.size;
      const fileSizeInKB = fileSizeInBytes / 1024;
      const fileSizeInMB = fileSizeInKB / 1024;
      const fileSizeInGB = fileSizeInMB / 1024;

      if (fileSizeInBytes < 1024 || fileSizeInKB < 1024) {
        const fileData = {
          size: 1,
          fileName: file?.name,
          file: file,
        };
       uploadStart(fileData);
      } else if (fileSizeInMB < 1024) {
        const fileData = {
          size: Math.ceil(fileSizeInMB),
          fileName: file?.name,
          file: file,
        };
        uploadStart(fileData);
      } else {
        const convertToMb = Math.ceil(fileSizeInGB) * 1024;
        const fileData = {
          size: Math.ceil(convertToMb),
          fileName: file?.name,
          file: file,
        };
       uploadStart(fileData);
      }
    }
  };

  return (
    <div className="org-detail-page">
      <Header />
      <Container className="container-top-offset mw-100 container">
        <Row className="w-100 row">
          <div className="page-header">
            <Container className="container-top-offset mw-100 container">
              <Row>
                <Col className="text-start">
                  <BreadcrumbList isFromOrgDetails={false} list={breadList} />
                </Col>
              </Row>
              <div className="header-main-layout-pos">
                <div className="header-title-pos">
                  {constants.POS_INTEGRATION_LABEL}
                </div>
                {currentOrg?.posEnabled && (
                  <div className="right-layout">
                    <PrimaryButton
                      className="btn btn-outline-danger btn-data-mapping"
                      type="button"
                      height="44px"
                      width="212px"
                      fontSize="0.875rem"
                      backgroundColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_white')}
                      borderColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_primary')}
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_primary')}
                      borderWidth="1.5px"
                      hoverBorderWidth="1.5px"
                      hoverColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_white')}
                      hoverBackgroundColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_primary')}
                      hoverBorderColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_primary')}
                      loaderClassName="delete-view-loader"
                      onClick={() => {
                        getStoreLocationData();
                      }}
                    >
                      {constants.POS_LOCATION_MAPPING}
                    </PrimaryButton>
                    <PrimaryButton
                      className="btn btn-outline-danger btn-data-mapping"
                      type="button"
                      height="44px"
                      width="212px"
                      fontSize="0.875rem"
                      backgroundColor={
                        showLoader
                          ? getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--brand_primary')
                          : getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--brand_white')
                      }
                      borderColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_primary')}
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_primary')}
                      borderWidth="1.5px"
                      hoverBorderWidth="1.5px"
                      hoverColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_white')}
                      hoverBackgroundColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_primary')}
                      hoverBorderColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_primary')}
                      loader={showLoader}
                      loaderClassName="delete-view-loader"
                      onClick={() => {
                        setShowLoader(true);
                        getMappingData();
                      }}
                    >
                      {constants.EDIT_DATA_MAPPING}
                    </PrimaryButton>

                    <input
                      id="fileInput"
                      type="file"
                      accept=".csv"
                      onChange={handleFileChange}
                      style={{ display: 'none' }} // Hide the input element
                    />
                    <input
                      id="fileInputReplace"
                      type="file"
                      accept=".csv"
                      onChange={handleFileChangeReplace}
                      style={{ display: 'none' }} // Hide the input element
                    />

                    <PrimaryButton
                      className="btn btn-outline-danger"
                      type="button"
                      height="44px"
                      width="212px"
                      fontSize="0.875rem"
                      backgroundColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_primary')}
                      borderColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_primary')}
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_white')}
                      borderWidth="1.5px"
                      hoverBorderWidth="1.5px"
                      hoverColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_white')}
                      hoverBackgroundColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--primary_32')}
                      hoverBorderColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--primary_32')}
                      disabled={showUploadLoader}
                      onClick={() => {
                        setReplaceData(false);
                        setTimeout(() => {
                          setUploadWarningModal(true);
                        }, 100);
                      }}
                    >
                      {constants.UPLOAD_POS_DATA}
                    </PrimaryButton>
                  </div>
                )}
              </div>
              {!currentOrg?.posEnabled && (
                <div className="layout-no-setup">
                  <img alt="" src={UploadCsvIcon}></img>
                  <div className="message-content-pos">
                    {constants.POS_INTEGRATION_MESSAGE.replace(
                      '${appname}',
                      `${
                        process.env.REACT_APP_PROJECT ===
                        AppDefaults.PROJECT_MEGATRON
                          ? constants.POS_ON_CLOUD_CONNECT
                          : constants.POS_DUCLO_CONNECT
                      }`
                    )}
                  </div>
                  <PrimaryButton
                    className="btn btn-outline-danger btn-margin"
                    type="button"
                    height="44px"
                    width="212px"
                    fontSize="0.875rem"
                    backgroundColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--brand_primary')}
                    borderColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--brand_primary')}
                    color={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--brand_white')}
                    borderWidth="1.5px"
                    hoverBorderWidth="1.5px"
                    hoverColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--brand_white')}
                    hoverBackgroundColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--primary_32')}
                    hoverBorderColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--primary_32')}
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    {constants.SET_UP_POS_INTEGRATION}
                  </PrimaryButton>
                </div>
              )}
              {currentOrgPosList?.length > 0 &&
                Object.keys(showFailedMqttData).length > 0 && (
                  <div className="file-failed-layout">
                    <div className="left-file-layout">
                      <HiOutlineInformationCircle
                        size={20}
                        color={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--error_24')}
                      />
                      <div className="title-file-fail">
                        {`${showFailedMqttData?.fileName} ${constants.POS_FILE_FAILED}`}
                      </div>
                    </div>
                    <RxCross2
                      onClick={() => setShowFailedMqttData({})}
                      className="icon-cancel-pos"
                      size={20}
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--error_24')}
                    />
                  </div>
                )}
              {currentOrgPosList?.length <= 0 && currentOrg?.posEnabled && (
                <div className="layout-no-setup">
                  <div className="message-content-pos">
                    {constants.NO_RECORDS_FOUND_TITLE}
                  </div>
                </div>
              )}
              {currentOrgPosList?.length > 0 && (
                <InfiniteScroll
                  key={`infinite-scroll-${orgId}`}
                  loadMore={loadMore}
                  hasMore={hasMoreItems}
                  loader={
                    <div
                      key={`infinite-scroll-loader-${orgId}`}
                      className="loader"
                    ></div>
                  }
                  useWindow={false}
                >
                  {showItems(currentOrgPosList)}
                </InfiniteScroll>
              )}
            </Container>
          </div>
        </Row>
      </Container>
      <SiteModal
        classes={'manage-pos-setup'}
        modalHeader={true}
        showModal={showModal}
        hideModal={() => handleCloseModal()}
      >
        <StepsPos
          isFromEdit={isFromEdit}
          hideModal={() => handleCloseModal()}
        />
      </SiteModal>
      <SiteModal
        classes={'manage-pos-setup'}
        modalHeader={true}
        showModal={showModalDelete}
        hideModal={() => handleCloseModalDelete()}
      >
        <DeletePosFileModal
          itemSelected={itemSelected}
          deleteRecords={() => deleteRecords()}
          hideModal={() => handleCloseModalDelete()}
        />
      </SiteModal>
      <SiteModal
        classes={'manage-pos-setup'}
        modalHeader={true}
        showModal={editMappingModal}
        hideModal={() => handleCloseModalEdit()}
      >
        <EditStepPos
          mappingData={mappingData}
          goTOSetup={() => goToSetupAgain()}
          hideModal={() => handleCloseModalEdit()}
        />
      </SiteModal>
      <SiteModal
        classes={'manage-pos-setup'}
        modalHeader={true}
        showModal={editStoreLocationModal}
        hideModal={() => handleCloseModalStore()}
      >
        <EditStoreLocation
          locationStoreData={locationStoreData}
          hideModal={() => handleCloseModalStore()}
        />
      </SiteModal>
      <SiteModal
        classes={'manage-pos-setup'}
        modalHeader={true}
        showModal={uploadWarningModal}
        hideModal={() => handleCloseUploadWarningModal()}
      >
        <UploadWarning
          hideModal={() => handleCloseUploadWarningModal()}
          uploadStart={() => handleOpenFile()}
        />
      </SiteModal>
    </div>
  );
}
export default PosIntegration;
