import { useState, useEffect } from 'react';
import axios from 'axios';
import { isEmpty, isObject } from 'lodash';

import { useCustomerOrgLocations } from '../../store/CustomerOrgLocationsStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';

import { CommonBlock, ProgressBar, SiteSpinner } from '../../components/common';
import { AppDefaults, Utils, constants } from '../../helpers';

import { BsArrowUp, BsArrowDown } from 'react-icons/bs';

const TopEvents = (custOrgData) => {
  const [todayEvents, setTodayEvents] = useState([]);
  const [yesterdayEvents, setYesterdayEvents] = useState([]);
  const [eventsDiffrence, setEventsDiffrence] = useState([]);
  const [isVsYesterday, setIsVsYesterday] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [showLoaderTopEvents, setShowLoaderTopEvents] = useState(false);
  const startTimeToday = Utils.getTimeStampFromDate('0:0:0:000');
  const startTimeYesterday = Utils.getPastDaysTime('00:00:00', 1);
  const endTimeYesterday = Utils.getPastDaysTime('23:59:59', 1);
  const custOrganization = custOrgData?.custOrgData;
  const { getSelectedLocation } = useCustomerOrgLocations();
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const selectedLocation = getSelectedLocation(loggedInUserData?.accountId);
  useEffect(() => {
    if (custOrganization) {
      setShowLoaderTopEvents(true);
      callEventCount(true);
      callEventCount(false);
    }
  }, [selectedLocation]);

  const getTopEventDeviceId = () => {
    let deviceID = '';
    const orgDevices =  custOrganization?.devices;
    const orgDevice = Utils.getTotalChildDevices(orgDevices)
    const location = selectedLocation;

    if (isObject(location)) {
      const devicesList =
        location?.locationId === AppDefaults.ALL_LOCATIONS_ID
          ? orgDevice
          : orgDevice?.filter(
              (device) => device.locationId === location?.locationId
            );
      devicesList && devicesList.length > 0
        ? devicesList?.forEach((device, i) => {
            deviceID += '&deviceIds=' + device.deviceId;
          })
        : (deviceID = '&deviceIds=');
      return deviceID;
    } else {
      orgDevice &&
        orgDevice.length > 0 &&
        orgDevice?.forEach((device, i) => {
          deviceID += '&deviceIds=' + device.deviceId;
        });
      return deviceID;
    }
  };

  useEffect(() => {
    if (
      todayEvents &&
      yesterdayEvents &&
      todayEvents.length > 0 &&
      yesterdayEvents.length > 0
    ) {
      let newmodelList = [];
      for (let index = 0; index < todayEvents.length; index++) {
        const deviceEvent = yesterdayEvents?.find(
          (device) => device.deviceId === todayEvents[index].deviceId
        );
        const deviceData = custOrganization?.devices?.find(
          (device) => device.deviceId === todayEvents[index].deviceId
        );
        if (deviceEvent) {
          let diff =
            ((todayEvents[index].count - deviceEvent.count) * 100) /
            deviceEvent.count;
          let sortVarriance = diff < 0 ? diff * -1 : diff;
          newmodelList.push({
            ...todayEvents[index],
            yesterDayCount: deviceEvent.count,
            varience: diff,
            sortVarriance: sortVarriance,
            deviceName: deviceData?.deviceName
              ? deviceData?.deviceName
              : 'Device',
          });
        }
      }
      const sortedArry = newmodelList.sort(
        (a, b) => b.sortVarriance - a.sortVarriance
      );
      setEventsDiffrence(sortedArry);
    }
  }, [todayEvents, yesterdayEvents]);

  const callEventCount = async (isToday, isWeek) => {
    setShowLoader(true);
    let startTimeWeek = 0;
    let endTimeWeek = 0;
    if (isWeek && isWeek === 1) {
      startTimeWeek = Utils.getPastDaysTime('00:00:00', 7);
      endTimeWeek = Utils.getPastDaysTime('23:59:59', 7);
    }
    let response = await axios.get(
      `/timeline/orgs/${custOrganization?.orgId}/events/count?startTime=${
        isToday ? startTimeToday : isWeek ? startTimeWeek : startTimeYesterday
      }&endTime=${
        isToday ? new Date().getTime() : isWeek ? endTimeWeek : endTimeYesterday
      }&size=15${getTopEventDeviceId()}`,
      Utils.requestHeader()
    );
    const responseData = response?.data;
    if (responseData?.meta?.code === 200) {
      setShowLoader(false);
      if (isEmpty(responseData?.data)) {
        setTodayEvents([]);
        setYesterdayEvents([]);
      } else if (isToday) {
        setTodayEvents(responseData?.data?.devices);
      } else {
        setYesterdayEvents(responseData?.data?.devices);
      }
      setShowLoaderTopEvents(false);
    } else {
      setShowLoader(false);
      setShowLoaderTopEvents(false);
    }
  };

  const setComparisonList = (status) => {
    setIsVsYesterday(status);
    if (status) {
      callEventCount(true);
      callEventCount(false);
    } else {
      callEventCount(false, 1);
    }
  };

  return (
    <div className="top-camera-events">
      <div className="top-events-header">
        <div className="top-events-label">
          {constants.SEARCH_DASHBOARD_TOP_EVENTS}
        </div>
      </div>
      <div className="top-event-conatiner">
        {eventsDiffrence && eventsDiffrence.length > 0 ? (
          <div className="today-yesterday-container">
            <div
              onClick={() => setComparisonList(true)}
              className={`vs-yesterday-btn ${isVsYesterday ? 'selected' : ''}`}
            >
              {constants.SEARCH_DASHBOARD_VS_YSETERDAY}
            </div>
            {showLoader && (
              <SiteSpinner height="30px" width="30px"></SiteSpinner>
            )}
            <div
              onClick={() => setComparisonList(false)}
              className={`vs-lastday-btn ${!isVsYesterday ? 'selected' : ''}`}
            >
              {`${
                constants.SEARCH_DASHBOARD_VS_LAST
              } ${Utils.getPastWeekDayName(7)} `}
            </div>
          </div>
        ) : (
          <div className="no-event-conatiner">
            {showLoaderTopEvents ? (
              <SiteSpinner height="50px" width="50px" />
            ) : (
              constants.SEARCH_DASHBOARD_NOT_ENOUGH_DATA
            )}
          </div>
        )}
        <div className="today-yesterday-layout">
          {eventsDiffrence &&
            eventsDiffrence.length > 0 &&
            todayEvents?.length > 0 &&
            yesterdayEvents?.length > 0 &&
            eventsDiffrence.slice(0, 5).map((data, index) => (
              <div key={index} className="ty-container-body">
                <div className="left-container">
                  <div className="progress-bar-events">
                    <CommonBlock
                      width={60}
                      height={60}
                      blockType="error"
                      backgroundColor="transparent"
                      contentWrapperClass="text-start"
                      icon={
                        <ProgressBar
                          strokeWidth={25}
                          width="40px"
                          trailStroke={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--primary_96')}
                          background={getComputedStyle(
                            document.documentElement
                          ).getPropertyValue('--primary_48')}
                          percentage={
                            data?.varience < 0
                              ? data?.varience * -1
                              : data?.varience
                          }
                        />
                      }
                    />
                  </div>

                  <div className="device-name-event-count-conatiner">
                    <div className="device-name">{data?.deviceName}</div>
                    <div className="device-count-container">
                      <div className="count-label">
                        {constants.SEARCH_DASHBOARD_TODAY_LABEL}
                      </div>
                      <div className="count-color">{data.count}</div>
                    </div>
                  </div>
                </div>
                <div
                  className={`right-container ${
                    data?.varience < 0 ? 'diff-minus' : ''
                  }`}
                >
                  {data?.varience < 0 ? (
                    <BsArrowDown
                      size={18}
                      fill={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--error_24')}
                    />
                  ) : (
                    <BsArrowUp
                      size={18}
                      fill={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--diff_text_positive')}
                    />
                  )}
                  {Math.round(
                    data?.varience < 0 ? data?.varience * -1 : data?.varience
                  )}
                  %
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default TopEvents;
