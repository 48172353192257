import { useEffect, useState } from 'react';
import './stepspos.scss';
import { useSearchParams } from 'react-router-dom';
import { AppDefaults, constants, Utils } from '../../../helpers';
import { PrimaryButton } from '../../../components/common';
import { PosStore } from '../../../store/PosStore';
import EditPosIndicator from './EditPosIndicator';
import axios from 'axios';
import {checkUnslect} from '../../../assets/images'
import { ImCheckboxChecked } from "react-icons/im";

const EditUniqeIdentiFier = (props) => {
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const [disableMapBtn, setDisableMapBtn] = useState(true);
  const [posDataListFromCSV, setPosDataListFromCSV] = useState(
    props?.mappingData
  );
  const getIsHeaderChecked = PosStore((state) => state.getIsHeaderChecked);
  const [showLoader, setShowLoader] = useState(false);
  const onHandleChange = async (item) => {
    let posData = posDataListFromCSV?.map((posItem, index) => {
      if (posItem?.order === item?.order) {
        return {
          ...posItem,
          keyComponent: posItem?.keyComponent ? false: true
        };
      } else {
        return { ...posItem };
      }
    });
    let updatedPosList = await Promise.all(posData);
    setPosDataListFromCSV(updatedPosList);
    props.updateList(updatedPosList)

  };

  useEffect(() => {
    const keyComponentList = posDataListFromCSV?.filter(
      (itemAttribute) => itemAttribute?.keyComponent
    );
    if (keyComponentList?.length > 0) {
      setDisableMapBtn(false);
    } else {
      setDisableMapBtn(true);
    }
  }, [posDataListFromCSV]);

  const postMapping = async () => {
    setShowLoader(true)
    let fieldsList = [];
    const posAtributeList = posDataListFromCSV?.filter(
      (itemAttribute) => itemAttribute?.id
    );
    posAtributeList?.map((item) => {
      if (item?.type === AppDefaults?.DATA_TYPE_DATE) {
        fieldsList.push({
          cName: item?.cName,
          id: item?.id,
          order: item?.order,
          format: item?.format,
          columnDescription: item?.columnDescription,
          keyComponent:item?.keyComponent
        });
      } else if (item?.custom) {
        fieldsList.push({
          cName: item?.cName,
          id: item?.id,
          order: item?.order,
          columnDescription: item?.columnDescription,
          customAttributeName: item?.customAttributeName,
          keyComponent:item?.keyComponent
        });
      } else {
        fieldsList.push({
          cName: item?.cName,
          id: item?.id,
          order: item?.order,
          columnDescription: item?.columnDescription,
          keyComponent:item?.keyComponent
        });
      }
    });
    const reqBody = {
      header: getIsHeaderChecked() === 1 ? true : false,
      fields: fieldsList,
    };
    let response = await axios.put(
      `/partner/orgs/${orgId}/pos/mapping/fields`,
      reqBody,
      Utils.requestHeader()
    );

    let resData = response?.data;

    if (resData?.meta.code === 200) {
      setShowLoader(false);
      props.hideModal()
    } else {
      setShowLoader(false);
    }
  };

  return (
    <div className="pos-integration">
      <EditPosIndicator showMapPostStatus={0} {...props} />
      <div className={`manage-pos-height`}>
        <div className="step-four-unique-identifier">
          <div className="lable-upload-file">
            {constants.POS_SELECT_UNIQUE_IDENTIFIER}
          </div>
          <div className="extract-message">
            {constants.POS_SELECT_UNIQUE_IDENTIFIER_MESSAGE}
          </div>
          {posDataListFromCSV &&
            posDataListFromCSV?.map((item) => {
              return (
                item?.id && (
                  <div className={`list-unique-identifier`}>
                    <div className={`left-layout-col`}>
                      <div className="col-name-heading">{item.cName}</div>
                      <div className="sub-content-label">{item.columnDescription}</div>
                    </div>
                    <div className="middle-layout">
                      <div className="unique-text">{item.label}</div>
                    </div>
                    <div className="right-layout-col">
                      {!item?.keyComponent && (
                        <img
                          onClick={() => {
                            onHandleChange(item);
                          }}
                          alt=""
                          className="img-unselected"
                          src={checkUnslect}
                        ></img>
                      )}
                      {item?.keyComponent && (
                        <ImCheckboxChecked className="img-selected" color={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--primary_40')} onClick={()=>onHandleChange(item)} size={20} />
                      )}
                    </div>
                  </div>
                )
              );
            })}
        </div>
      </div>
      <PrimaryButton
        className="adddevice-btn btn-margin-try"
        fontSize="14px"
        backgroundColor={getComputedStyle(
          document.documentElement
        ).getPropertyValue('--primary_40')}
        height="44px"
        color="#FFFFFF"
        type="submit"
        disabled={disableMapBtn}
        loader={showLoader}
        onClick={() => {
            setShowLoader(true)
            postMapping()
        }}
      >
        {constants.CLIP_DETAILS_COMMENT_EDIT_FORM_SAVE_BUTTON_LABEL}
      </PrimaryButton>
      <PrimaryButton
        className="adddevice-btn btn-margin-try"
        fontSize="14px"
        backgroundColor={getComputedStyle(
          document.documentElement
        ).getPropertyValue('--brand_white')}
        height="44px"
        color={getComputedStyle(document.documentElement).getPropertyValue(
          '--primary_40'
        )}
        borderColor={getComputedStyle(
          document.documentElement
        ).getPropertyValue('--primary_40')}
        type="submit"
        onClick={() => {
          props.prevStepBack()
        }}
        
      >
        {constants.ORGANIZATION_LDAP_GO_BACK}
      </PrimaryButton>
    </div>
  );
};
export default EditUniqeIdentiFier;
