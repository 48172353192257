import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, PrimaryButton } from '../../../components/common';
import { RxCross1 } from 'react-icons/rx';
import {
  Utils,
  constants,
  DeviceStatusEnum,
  DeviceTypeEnum,
} from '../../../helpers';
import { Tab, Tabs } from 'react-bootstrap';
import { mqttPublish } from '../../../utils/connection/mqttConnection';
import { useLoggedInUserData } from '../../../store/LoggedInAccountStore';
import { devicesMQTTStore } from '../../../store/DevicesMQTTStore';
import { ReactComponent as RefreshIcon } from '../../../assets/images/refresh.svg';
import { ReactComponent as InfoCircleIcon } from '../../../assets/images/Info-circle.svg';
import { ReactComponent as NoDeviceIcon } from '../../../assets/images/no-device.svg';
import { ReactComponent as ExclaimationIcon } from '../../../assets/images/expired.svg';
import { TickOutlined } from '../../../assets/images';
import MoveDeviceControl from './NVR/MoveDeviceControl';
import AddAndUpdateDeviceControl from './NVR/AddAndUpdateDeviceControl';
import NotAuthenticatedControl from './NVR/NotAuthenticatedControl';
import ReClaimDeviceControl from './NVR/ReClaimDeviceControl';
import {
  getNVRDeviceData,
  getNVRScannedDevices,
  setNVRScannedDevices,
  getDevicesListOfCurrOrg,
  setDevicesListOfCurrOrg,
  getAddedAndClaimedDeviceId,
  setAddedAndClaimedDeviceId,
  setAllScannedDevices,
  getAllScannedDevices,
} from '../../../store/reducers/NVRDeviceReducer';
import {
  getAllMqttDataFromResponse,
  getSelectedOrganization,
} from '../../../store/reducers/AccountReducer';

const filterTabs = ['All', 'Added', 'Unauthenticated', 'Already Assigned'];

const DiscoveredDevices = ({ hideModalHandler }) => {
  const expireTime = 60;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const resourceList = Utils.getDeviceSettingResource(11);
  const nvrDeviceData = useSelector(getNVRDeviceData);
  const orgDetails = useSelector(getSelectedOrganization);
  const devicesListOfCurrOrg = useSelector(getDevicesListOfCurrOrg);
  const addedAndClaimedDeviceId = useSelector(getAddedAndClaimedDeviceId);
  const allScannedDevices = useSelector(getAllScannedDevices);
  const maxDeviceCount = nvrDeviceData?.channelCount || 0;
  const nvrScannedDevices = useSelector(getNVRScannedDevices);
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  const { getState } = devicesMQTTStore;
  const state = getState();
  const sessionId = state.getSessionId();
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );
  const tid = Math.floor(new Date().getTime() / 1000.0);
  const accountId = loggedInUserData.accountId;
  const hubId = nvrDeviceData?.gatewayId;
  const appTopic = `a/notify/${hubId}`;
  const [showLoader, setShowLoader] = useState(true);
  const [loaderForSelectedDevice, setLoaderForSelectedDevice] = useState(false);
  const [scannedDevices, setScannedDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [addedDeviceCount, setAddedDeviceCount] = useState(0);
  const [isReInitiateScanNetworkReq, setIsReInitiateScanNetworkReq] =
    useState(true);
  const [selectedTab, setSelectedTab] = useState([]);
  const [actualDevices, setActualDevices] = useState([]);
  const [isReloadSelectedDevice, setIsReloadSelectedDevice] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isFetchScannedDevices, setIsFetchScannedDevices] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (remainingTime > 0) {
        setRemainingTime((prevTime) => prevTime - 1);
      } else {
        setIsFetchScannedDevices(false);
        clearInterval(timerInterval);
      }
    }, 1000);
    return () => {
      clearInterval(timerInterval);
    };
  }, [remainingTime]);

  useEffect(() => {
    const updatedDevices = nvrScannedDevices.map((item) => ({
      ...item,
      ...getDeviceProperties(item),
    }));
    const devicesWithStatus = updatedDevices.filter(
      (item) => item.deviceAuthStatus
    );
    setActualDevices(devicesWithStatus);
    const filteredDevicesByTab =
      selectedTab.length > 0
        ? devicesWithStatus.filter((item) =>
            selectedTab.includes(item.deviceAuthStatus)
          )
        : devicesWithStatus;
    setScannedDevices(filteredDevicesByTab);
    if (selectedTab.length > 0) {
      setSelectedDevice(filteredDevicesByTab?.[0]);
    } else {
      setSelectedDevice(selectedDevice ? selectedDevice : updatedDevices?.[0]);
    }
    setIsReloadSelectedDevice(!isReloadSelectedDevice);
    setAddedDeviceCount(
      devicesWithStatus?.filter(
        (item) =>
          item.deviceAuthStatus === DeviceStatusEnum.ADDED_N_CLAIMED ||
          item.deviceAuthStatus === DeviceStatusEnum.ADDED_NOT_CLAIMED
      )?.length || 0
    );
  }, [devicesListOfCurrOrg, nvrScannedDevices, selectedTab]);

  useEffect(() => {
    if (addedAndClaimedDeviceId && scannedDevices.length > 0) {
      handleDeviceClickByDeviceId(addedAndClaimedDeviceId);
    }
  }, [scannedDevices]);

  useEffect(() => {
    setShowLoader(true);
    setIsFetchScannedDevices(true);
    getPublishRequest();
    fetchDeviceListForOrg();
  }, [isReInitiateScanNetworkReq]);

  useEffect(() => {
    handleDeviceClick(selectedDevice?.channel);
  }, [isReloadSelectedDevice]);

  const getPublishRequest = useCallback(() => {
    if (!accountId) {
      return;
    }
    const context = {
      topic: appTopic,
      payload: JSON.stringify({
        tid: tid,
        to: hubId,
        from: accountId,
        msg: {
          action: 'get',
          resource: resourceList[0],
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };
    setRemainingTime(expireTime);
    mqttPublish(context);
  }, []);

  useEffect(() => {
    const resource = allMqttData?.msg?.resource;
    setErrorMsg('');
    if (resource?.includes('hub/channels/auth-creds')) {
      const properties = { ...allMqttData?.msg?.properties };
      // TODO: To Be Removed
      console.log('Authenticate Device Status', properties);
      if (!properties?.device?.authStatus) {
        setErrorMsg(constants.AUTHENTICATE_INVALID);
      }
      const channelDevices = properties?.device?.channels;
      const allUpdatedChannels = [];
      if (channelDevices && channelDevices.length > 0) {
        const updatedDevices = nvrScannedDevices.map((item) => {
          const foundChannel = channelDevices.find(
            (chd) => chd.channel === item.channel
          );
          if (foundChannel) {
            const updatedChannel = {
              ...item,
              authStatus:
                foundChannel?.authStatus?.toString()?.toLowerCase() === 'true'
                  ? true
                  : false,
              serialNumber: foundChannel?.serialNumber,
              macAddress: foundChannel?.macAddress,
            };
            allUpdatedChannels.push(updatedChannel);
            return updatedChannel;
          } else {
            return item;
          }
        });
        dispatch(setNVRScannedDevices(updatedDevices));
      }

      const foundChannel = channelDevices?.find(
        (chd) => chd.channel === selectedDevice?.channel
      );
      if (foundChannel) {
        setIsReloadSelectedDevice(!isReloadSelectedDevice);
      }
      const respDevice = properties?.device;
      if (respDevice) {
        const updatedAllScannedDevices = allScannedDevices.map((dvc) => {
          if (dvc.channel === respDevice.channel) {
            return {
              ...dvc,
              serialNumber: respDevice.serialNumber,
              macAddress: respDevice.macAddress,
              channels: allUpdatedChannels,
            };
          } else {
            return dvc;
          }
        });
        dispatch(setAllScannedDevices(updatedAllScannedDevices));
      }
    } else if (resource?.includes('hub/channels')) {
      const properties = { ...allMqttData?.msg?.properties };
      // TODO: To Be Removed
      console.log('Scanned Channel List', properties?.devices);
      if (properties?.devices?.length > 0) {
        const allDevices = properties?.devices?.map((d) => d.device);
        dispatch(setAllScannedDevices(allDevices));
        if (nvrDeviceData.deviceType.toUpperCase() === DeviceTypeEnum.NVR) {
          const list = getChannelsList(properties?.devices);
          dispatch(setNVRScannedDevices(list));
        } else {
          const list = properties?.devices?.map((d) => d.device);
          dispatch(setNVRScannedDevices(list));
        }
      } else {
        dispatch(setNVRScannedDevices([]));
        dispatch(setAllScannedDevices([]));
      }
      setIsFetchScannedDevices(false);
      setRemainingTime(0);
    } else if (resource?.includes('/camera/system/device-status')) {
      const properties = { ...allMqttData?.msg?.properties };
      // TODO: To Be Removed
      console.log('Connection Status', properties);
      const deviceAttributes = resource.split('/');
      const updatedDevices = devicesListOfCurrOrg.map((item) => {
        if (item.deviceId === deviceAttributes[1]) {
          return {
            ...item,
            connectionStatus: properties.online
              ? properties.online
              : item.connectionStatus,
          };
        } else {
          return item;
        }
      });
      dispatch(setDevicesListOfCurrOrg(updatedDevices));
    } else if (resource?.includes('device/')) {
      const properties = { ...allMqttData?.msg?.properties };
      // TODO: To Be Removed
      console.log('Device Status', properties);
      if (!properties.deviceStatus) return;
      const deviceAttributes = resource.split('/');
      const updatedDevices = devicesListOfCurrOrg.map((item) => {
        if (item.deviceId === deviceAttributes[1]) {
          return {
            ...item,
            deviceStatus: properties.deviceStatus
              ? properties.deviceStatus
              : item.deviceStatus,
          };
        } else {
          return item;
        }
      });
      dispatch(setDevicesListOfCurrOrg(updatedDevices));
    }
  }, [allMqttData]);

  const getDeviceProperties = (passedItem) => {
    const device = devicesListOfCurrOrg.find(
      (item) =>
        item.macAddress &&
        passedItem.macAddress &&
        item.macAddress.toUpperCase() === passedItem.macAddress.toUpperCase() &&
        item.serialNo === passedItem.serialNumber
    );
    return {
      ...device,
      deviceAuthStatus: getDeviceStatus({ ...passedItem, ...device }),
    };
  };

  const getDeviceStatus = (device) => {
    if (!device.authStatus) {
      return DeviceStatusEnum.NOT_AUTHENTICATED;
    } else {
      if (device.gatewayId === hubId) {
        if (device.deviceStatus === constants.DEVICES_CLAIMED_DEVICE_STATUS) {
          return DeviceStatusEnum.ADDED_N_CLAIMED;
        } else if (
          device.deviceStatus === constants.DEVICES_PENDING_CLAIM_DEVICE_STATUS
        ) {
          return DeviceStatusEnum.ADDED_NOT_CLAIMED;
        } else if (
          device.deviceStatus === constants.DEVICES_DEACTIVATED_DEVICE_STATUS
        ) {
          return DeviceStatusEnum.DEACTIVATED;
        } else {
          return DeviceStatusEnum.AUTHENTICATED;
        }
      } else {
        if (!device.deviceId) {
          return DeviceStatusEnum.AUTHENTICATED;
        } else {
          return DeviceStatusEnum.ALREADY_ASSIGNED;
        }
      }
    }
  };

  const handleCompleteClick = () => {
    hideModalHandler();
  };

  const handleDeviceClick = (deviceId) => {
    const activeDevice = scannedDevices.find(
      (device) => device.channel === deviceId
    );
    setSelectedDevice(activeDevice);
  };

  const handleDeviceClickByDeviceId = (deviceId) => {
    const activeDevice = scannedDevices.find(
      (device) => device.deviceId === deviceId
    );
    setSelectedDevice(activeDevice);
    setIsReloadSelectedDevice(!isReloadSelectedDevice);
  };

  const handleScanNetworkClick = () => {
    setIsReInitiateScanNetworkReq(!isReInitiateScanNetworkReq);
  };

  const getChannelsList = (data) => {
    const list = [];
    data.forEach((d) => {
      d.device && d.device.channels && list.push(...d.device.channels);
    });
    return list;
  };

  const DiscoveredDevicesModalFooter = () => (
    <div className="footer-container">
      <div className="left-section">
        <img
          src={nvrDeviceData.imageURL}
          alt="Device"
          className="device-icon"
        />
        <div className="device-details">
          <div className="device-name">{nvrDeviceData.deviceName}</div>
          <div className="device-added-status">
            {addedDeviceCount} / {maxDeviceCount}
            {constants.DEVICES_ADDED_TEXT}
          </div>
        </div>
      </div>
      <div className="right-section">
        <div className="scan-network-button">
          <RefreshIcon
            className={`${
              showLoader || isFetchScannedDevices ? 'disabled-icon' : ''
            }`}
          />
          <PrimaryButton
            className="scan-button"
            type="button"
            width="fit-content"
            borderWidth="0"
            hoverBorderWidth="0"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--primary_40'
            )}
            hoverColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            disabledBackgroundColor="transparent"
            disabledColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--greyscale_72')}
            hoverBackgroundColor="transparent"
            backgroundColor="transparent"
            disabled={showLoader || isFetchScannedDevices}
            onClick={handleScanNetworkClick}
          >
            {constants.DISCOVERED_DEVICES_SCAN_BUTTON_TEXT}
          </PrimaryButton>
        </div>
        <PrimaryButton
          fontSize="0.875rem"
          backgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--brand_white')}
          height="44px"
          width="169px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--primary_40'
          )}
          type="submit"
          onClick={handleCompleteClick}
        >
          {constants.COMPLETE_TEXT}
        </PrimaryButton>
      </div>
    </div>
  );

  const handleTabChange = (tab) => {
    switch (tab) {
      case 'Added':
        setSelectedTab([
          DeviceStatusEnum.ADDED_N_CLAIMED,
          DeviceStatusEnum.ADDED_NOT_CLAIMED,
        ]);
        break;
      case 'Unauthenticated':
        setSelectedTab([DeviceStatusEnum.NOT_AUTHENTICATED]);
        break;
      case 'Already Assigned':
        setSelectedTab([DeviceStatusEnum.ALREADY_ASSIGNED]);
        break;

      default:
        setSelectedTab([]);
        setSelectedDevice(undefined);
        break;
    }
  };

  const getChannelStatus = (status) => {
    switch (status) {
      case DeviceStatusEnum.AUTHENTICATED:
        return (
          <div className="status-section">
            <>
              <img src={TickOutlined} alt="TickOutlined" />
              {constants.AUTHENTICATED_STATUS}
            </>
          </div>
        );
      case DeviceStatusEnum.ADDED_N_CLAIMED:
        return (
          <div className="status-section">
            <>
              <img src={TickOutlined} alt="TickOutlined" />
              {constants.ADDED_CLAIMED_STATUS}
            </>
          </div>
        );
      case DeviceStatusEnum.ADDED_NOT_CLAIMED:
        return (
          <div className="pending-status-section">
            <>
              <ExclaimationIcon />
              {constants.PENDING_CLAIMED_STATUS}
            </>
          </div>
        );

      default:
        break;
    }
  };

  const fetchDeviceListForOrg = async () => {
    try {
      axios
        .get(`device/orgs/${orgDetails?.orgId}/devices`, Utils.requestHeader())
        .then((response) => {
          const resultData = response.data;
          if (resultData) {
            const responseMeta = resultData.meta;
            const responseData = resultData.data;
            if (
              responseMeta &&
              (responseMeta.code === 200 || responseMeta.code === '200')
            ) {
              dispatch(setDevicesListOfCurrOrg(responseData));
            }
          } else if (response?.response?.data?.error) {
            setErrorMsg(response?.response?.data?.error);
          }
          setShowLoader(false);
          setTimeout(() => {
            dispatch(setAddedAndClaimedDeviceId(null));
          }, 1000);
        });
    } catch (error) {
      setShowLoader(false);
      console.error('ERROR: ', error);
    }
  };

  const handleErrorMsgClose = () => {
    setErrorMsg('');
  };

  return (
    <div className={`device-container ${errorMsg && 'errorMsg'}`}>
      {showLoader || isFetchScannedDevices ? (
        <div className="loading-screen">
          <Loader />
          <div className="mt-4">{constants.DISCOVERED_ONVIF_DEVICES_TEXT}</div>
        </div>
      ) : actualDevices.length === 0 ? (
        <div className="loading-screen">
          <NoDeviceIcon />
          <div className="mt-4">{constants.DEVICES_NOT_FOUND_TEXT}</div>
        </div>
      ) : (
        <>
          {errorMsg ? (
            <div className="mb-4 warning-container">
              <div className="warning-message">
                <InfoCircleIcon />
                {errorMsg}
              </div>
              <RxCross1
                className="site-modal-close"
                onClick={() => handleErrorMsgClose()}
              />
            </div>
          ) : maxDeviceCount === addedDeviceCount ? (
            <div className="mb-4 warning-container">
              <div className="warning-message">
                <InfoCircleIcon />
                {constants.DEVICES_REACHED_MAX_LIMIT.replace(
                  '${count}',
                  maxDeviceCount
                )}
              </div>
              <RxCross1 className="site-modal-close" />
            </div>
          ) : (
            ''
          )}
          <div className="mt-2 mb-4 filter-container">
            <Tabs
              defaultActiveKey={filterTabs[0]}
              className="filter-tabs"
              onSelect={(e) => {
                handleTabChange(e);
              }}
            >
              {filterTabs.map((tab) => (
                <Tab key={tab} eventKey={tab} title={tab} />
              ))}
            </Tabs>
          </div>
          {scannedDevices?.length > 0 ? (
            <div className="devices-section mb-4">
              <div className="devices-list">
                {scannedDevices.map((device) => (
                  <div
                    key={device.channel}
                    className={`device-item ${
                      device.channel === selectedDevice?.channel
                        ? 'active-device'
                        : ''
                    }`}
                    onClick={() => handleDeviceClick(device.channel)}
                  >
                    <div className="item-details">
                      <div className="item-name">{device.modelNumber}</div>
                      <div className="seconday-text">
                        {t('DEVICE_HARDWARE')}
                      </div>
                      <div className="mt-2 seconday-text">
                        {constants.IP_LABEL} {device.ipAddress}
                      </div>
                      <div className="seconday-text">
                        {constants.PORT_LABEL} {device.port}
                      </div>
                      {device.serialNumber && (
                        <div className="seconday-text">
                          {constants.SERIAL_NUMBER_LABEL} {device.serialNumber}
                        </div>
                      )}
                    </div>
                    <div
                      className={`item-status ${
                        device.deviceAuthStatus ===
                        DeviceStatusEnum.ALREADY_ASSIGNED
                          ? 'already-assigned-status'
                          : ''
                      } ${
                        device.deviceAuthStatus ===
                          DeviceStatusEnum.AUTHENTICATED &&
                        maxDeviceCount === addedDeviceCount
                          ? 'disabled'
                          : ''
                      }`}
                    >
                      {device.deviceAuthStatus !==
                        DeviceStatusEnum.ADDED_N_CLAIMED &&
                        device.deviceAuthStatus !==
                          DeviceStatusEnum.ADDED_NOT_CLAIMED &&
                        device.deviceAuthStatus}
                    </div>
                  </div>
                ))}
              </div>
              <div className="device-details">
                {loaderForSelectedDevice && <Loader />}
                <div className="device-details-container">
                  <div className="header-container">
                    <div className="title-section">
                      <div className="item-name">
                        {selectedDevice?.modelNumber}
                      </div>
                      <div className="seconday-text">
                        {t('DEVICE_HARDWARE')}
                      </div>
                    </div>
                    {getChannelStatus(selectedDevice?.deviceAuthStatus)}
                  </div>
                  <div>
                    {selectedDevice?.deviceAuthStatus ===
                    DeviceStatusEnum.ALREADY_ASSIGNED ? (
                      <MoveDeviceControl
                        errorMsgHandler={setErrorMsg}
                        selectedDevice={selectedDevice}
                        nvrDeviceData={nvrDeviceData}
                        hubId={hubId}
                        deviceSelectHandler={handleDeviceClickByDeviceId}
                        selectedDeviceLoaderHandler={setLoaderForSelectedDevice}
                        fetchDeviceListForOrg={fetchDeviceListForOrg}
                      />
                    ) : selectedDevice?.deviceAuthStatus ===
                      DeviceStatusEnum.DEACTIVATED ? (
                      <ReClaimDeviceControl
                        errorMsgHandler={setErrorMsg}
                        selectedDevice={selectedDevice}
                        deviceSelectHandler={handleDeviceClickByDeviceId}
                        selectedDeviceLoaderHandler={setLoaderForSelectedDevice}
                      />
                    ) : selectedDevice?.deviceAuthStatus ===
                        DeviceStatusEnum.AUTHENTICATED ||
                      selectedDevice?.deviceAuthStatus ===
                        DeviceStatusEnum.ADDED_N_CLAIMED ||
                      selectedDevice?.deviceAuthStatus ===
                        DeviceStatusEnum.ADDED_NOT_CLAIMED ? (
                      <AddAndUpdateDeviceControl
                        errorMsgHandler={setErrorMsg}
                        selectedDevice={selectedDevice}
                        nvrDeviceData={nvrDeviceData}
                        hubId={hubId}
                        isAddButtonDisabled={
                          selectedDevice?.deviceAuthStatus ===
                            DeviceStatusEnum.AUTHENTICATED &&
                          maxDeviceCount === addedDeviceCount
                        }
                        deviceSelectHandler={handleDeviceClickByDeviceId}
                        selectedDeviceLoaderHandler={setLoaderForSelectedDevice}
                        fetchDeviceListForOrg={fetchDeviceListForOrg}
                      />
                    ) : (
                      <NotAuthenticatedControl
                        selectedDevice={selectedDevice}
                        scannedDevices={scannedDevices}
                        hubId={hubId}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="no-device-found loading-screen">
              <NoDeviceIcon />
              <div className="mt-4">{constants.DEVICES_NOT_FOUND_TEXT}</div>
            </div>
          )}
        </>
      )}
      {DiscoveredDevicesModalFooter()}
    </div>
  );
};
export default DiscoveredDevices;
