import { useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  AssignLocations,
  TextField,
  ToggleInput,
} from '../../components/forms';
import { constants, Utils, roles } from '../../helpers';
import {
  Avatar,
  PrimaryButton,
  SiteModal,
  SiteToast,
  SiteSpinner,
  TextBlock,
} from '../../components/common';
import { useOrganizations } from '../../store/OrganizationsStore';
import { usePartnerOrgUsers } from '../../store/PartnerOrgUsersStore';
import { usePoliciesStore } from '../../store/policiesStore';
import axios from 'axios';
import {
  HiOutlinePencil,
  HiCheckCircle,
  HiArrowLeft,
  HiUserCircle,
} from 'react-icons/hi2';
import { FaCircle } from 'react-icons/fa';
import './EditPartnerUsers.scss';

// Schema for yup
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(32, constants.CANT_LONGER_ERROR_MESSAGE)
    .matches(/^[a-zA-Z ]+$/, constants.ONLY_ALPHABETS_ERROR_MESSAGE)
    .required(constants.FIRST_NAME_REQUIRED_ERROR_MESSAGE),
  lastName: Yup.string()
    .max(32, constants.CANT_LONGER_ERROR_MESSAGE)
    .matches(/^[a-zA-Z ]+$/, constants.ONLY_ALPHABETS_ERROR_MESSAGE)
    .required(constants.LAST_NAME_REQUIRED_ERROR_MESSAGE),
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      constants.EMAIL_VALID_ERROR_MESSAGE
    )
    .required(constants.EMAIL_REQUIRED_ERROR_MESSAGE),
  phoneNumber: Yup.string().matches(
    /^[0-9]{10}$/,
    constants.VALID_PHONE_NUMBER_ERROR_MESSAGE
  )
});

function EditPartnerUsers(props) {
  const accountData = props.accountData;
  const policyEditStatus = props?.policyEditStatus;
  const [variant, setVariant] = useState('error');
  const [userMsg, setUserMsg] = useState('');
  const [roleChange, setRolechange] = useState(
    accountData?.role === roles.ROLE4VMS ? true : false
  );
  const [accountStatus, setAccountStatus] = useState(
    accountData?.accountStatus === 'ACTIVE' ? true : false
  );
  const [fetchCustOrgsStatus, setFetchCustOrgsStatus] = useState(false);
  const [resetPwdStatus, setResetPwdStatus] = useState(false);
  const [showPwdLoader, setShowPwdLoader] = useState(false);
  const [invitationStatus, setInvitationStatus] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showDeactivateUserModal, setShowDeactivateUserModal] = useState(false);
  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
  const [disableResetButton, setDisableResetButton] = useState(false);
  const [profileImgURL, setProfileImgURL] = useState(accountData?.image?.url);
  //=== Store get/set actions
  const customerOrgData = useOrganizations((state) => state.customerOrgData);
  const partnerOrgData = useOrganizations((state) => state.partnerOrgData);
  const partnerOrgId = partnerOrgData?.orgId;
  const getPartnerAssignedCustomerData = usePartnerOrgUsers(
    (state) => state.getPartnerAssignedCustomerData
  );
  const [assignCustomerData, setAssignCustomerData] = useState(
    getPartnerAssignedCustomerData(partnerOrgId)?.length ? getPartnerAssignedCustomerData(partnerOrgId) : []
  );
  const [assignCustomerChangedFlag, setAssignCustomerChangedFlag] =
    useState(false);
  const getLoggedInUserPolicies = usePoliciesStore(
    (state) => state.getLoggedInUserPolicies
  );
  const setEditPartnerAssignedCustomer = usePartnerOrgUsers(
    (state) => state.setEditPartnerAssignedCustomer
  );


  useEffect(() => {
    if (accountData?.isLocked) {
      setDisableResetButton(true);
    } else {
      setDisableResetButton(false);
    }
  }, [accountData]);

  useEffect(() => {
    if (fetchCustOrgsStatus) {
      let modalTitleHtml = (
        <>
          <PrimaryButton
            className="btn btn-primary-outline p-0 mb-1"
            type="button"
            width="auto"
            height="20px"
            borderWidth="0"
            hoverBorderWidth="0"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--primary_40'
            )}
            hoverColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            hoverBackgroundColor="transparent"
            backgroundColor="transparent"
            onClick={() => {
              handleBackButtonClick();
            }}
          >
            <HiArrowLeft size={15} /> {constants.BACK_BUTTON_TITLE}
          </PrimaryButton>
          {constants.PARTNER_NEW_USER_ASSIGN_CUSTOMERS_POPUP_TITLE}
        </>
      );
      props.handleModalTitle(modalTitleHtml);
    }
  }, [fetchCustOrgsStatus]);

  const handleBackButtonClick = () => {
    setFetchCustOrgsStatus(!fetchCustOrgsStatus);
    props.handleModalTitle(props.modalTitle);
  };

  // === Get Location and Areas HTML
  const getAssignCustomerFields = (customers) => {
    if (customers?.length >= 1) {
      let customersHtml = customers.map((customer) => {
        return (
          <div className="partner-customers-list-item" key={customer.orgId}>
            <label
              className="partner-customers-list-item-label"
              htmlFor={`customer-${customer.orgId}`}
            >
              {customer.orgName}
            </label>
            <input
              className="partner-customers-list-item-input"
              type="checkbox"
              checked={
                getCustomersExists(customer.orgId)?.toString() === 'true'
              }
              value=""
              id={`customer-${customer.orgId}`}
              onChange={(event) => {
                handleAddRemoveCustomers(event, customer.orgId);
              }}
            />
          </div>
        );
      });
      return customersHtml;
    }
  };

  //=== Check if Area is checked or not
  const getCustomersExists = (orgId) => {
    let assignedCustomers = [...assignCustomerData];
    let doesCustomerExist;

    if (assignedCustomers?.length > 0) {
      doesCustomerExist = assignedCustomers?.find(
        (customer) =>
          customer.orgId === orgId && customer.checked?.toString() === 'true'
      );
    }
    return !!doesCustomerExist;
  };

  useEffect(() => {
    const assignedData = getPartnerAssignedCustomerData(partnerOrgId);
    const customerOrgsData = [];
    if (assignedData.length > 0) {
      assignedData.forEach((customer) => {
        customerOrgsData.push(customer.orgId);
      });
    }
    const newAssignedData = [];
    if (assignCustomerData.length > 0) {
      assignCustomerData.forEach((customer) => {
        newAssignedData.push(customer.orgId);
      });
    }
    if(newAssignedData.length >= 0 && customerOrgsData?.length >= 0) {
      if (newAssignedData.length != customerOrgsData.length)
        setAssignCustomerChangedFlag(true);
    else {
      const found = newAssignedData.some((r) => customerOrgsData.every((cd) => r !== cd));
        if(found) {
          setAssignCustomerChangedFlag(true);
        } else {
          setAssignCustomerChangedFlag(false);
        }
    }
    }
  }, [JSON.stringify(getPartnerAssignedCustomerData(partnerOrgId)), JSON.stringify(assignCustomerData)]);

  //=== Check/uncheck areas
  const handleAddRemoveCustomers = (event, orgId) => {
    let chkdValue = event.target.checked;
    let assignedCustomers = [...assignCustomerData];

    if (chkdValue) {
      //=== If checked
      if (assignedCustomers.length === 0) {
        //=== If blank
        assignedCustomers.push({ orgId: orgId, checked: true });
        setAssignCustomerData(assignedCustomers);
      } else {
        //=== If not blank
        const foundLoc = assignedCustomers.some((el) => el.orgId === orgId);
        if (foundLoc) {
          //=== if location already exits - add area into it
          setAssignCustomerData(assignedCustomers);
        } else {
          //=== if location not exits - add locaton & area both
          assignedCustomers.push({ orgId: orgId, checked: true });
          setAssignCustomerData(assignedCustomers);
        }
      }
    } else {
      //=== If checked off - remove value from array
      assignedCustomers = assignedCustomers.filter((obj) => {
        if (obj.orgId !== orgId) {
          return obj;
        }
      });
      setAssignCustomerData(assignedCustomers);
    }
  };

  //=== handle role change event
  const handleRoleChangeClick = (event, setFieldValue) => {
    let statusValue = event.target.checked;
    setRolechange(statusValue);
    setFieldValue('role', statusValue ? roles.ROLE4VMS : roles.ROLE5VMS);
  };

  //=== On click handle resend invitation button send invitation
  const handleResendInvitationClick = () => {
    setShowLoader(true);
    setUserMsg('');
    axios
      .post(`partner/resendAccountActivation`, {
        accountId: accountData.accountId,
      })
      .then((res) => {
        let response = res.data;
        if (response.meta.code === 200) {
          setInvitationStatus(true);
        } else {
          setVariant('success');
          setUserMsg(response.data.userMsg);
        }
        setShowLoader(false);
      })
      .catch(function (error) {
        setVariant('error');
        setUserMsg(error?.message);
        setShowLoader(false);
      });
  };

  //=== On click remove this account button remove user and update user data
  const handleResetPasswordUserClick = () => {
    setShowPwdLoader(true);
    setUserMsg('');
    axios
      .post(
        `partner/orgs/${partnerOrgId}/accounts/${accountData.accountId}/resetPwd`
      )
      .then((res) => {
        let response = res.data;
        if (response.meta.code === 200) {
          setResetPwdStatus(true);
        } else {
          setVariant('error');
          setUserMsg(response?.meta?.userMsg);
        }
        setShowPwdLoader(false);
      })
      .catch(function (error) {
        setVariant('error');
        setUserMsg(error?.message);
        setShowPwdLoader(false);
      });
  };

  //=== On click remove this account button remove user and update user data
  const handleRemoveUserClick = () => {
    setShowLoader(true);
    setUserMsg('');
    axios
      .delete(
        `partner/orgs/${partnerOrgId}/accounts/${accountData.accountId}`,
        Utils.requestHeader()
      )
      .then((res) => {
        let response = res.data;
        if (response.meta.code === 200) {
          props.reloadData();
          props.hideModal();
          setShowRemoveUserModal(false);
        } else {
          setVariant('success');
          setUserMsg(response?.meta?.userMsg);
          props.reloadData();
          props.hideModal();
        }
        setShowLoader(false);
      })
      .catch(function (error) {
        setVariant('error');
        setUserMsg(error?.message);
        setShowLoader(false);
      });
  };

  //=== On click show deactivate status popup
  const handleChangeAccountStatus = (event) => {
    setShowDeactivateUserModal(true);
    let statusValue = event.target.checked;
    setAccountStatus(statusValue);
  };

  //=== Deactivate user status and show save button
  const handleDeactivateUserClick = (setFieldValue) => {
    setAccountStatus(false);
    setFieldValue('accountStatus', 'INACTIVE');
    setShowDeactivateUserModal(false);
  };

  const handleActivateUserClick = (setFieldValue) => {
    setAccountStatus(true);
    setFieldValue('accountStatus', 'ACTIVE');
    setShowDeactivateUserModal(false);
  };

  const handleAccountProfilePictureChange = async (event) => {
    var file = event?.target?.files[0];
    const fileStr = await Utils.encodeImageFileToBase64String(file);
    setUserMsg('');

    if (fileStr && partnerOrgId && accountData?.accountId) {
      axios
        .put(
          `partner/orgs/${partnerOrgId}/accounts/${accountData?.accountId}/image`,
          {
            image: fileStr.split(',')[1],
          },
          Utils.requestHeader()
        )
        .then((res) => {
          let response = res.data;
          if (response.meta.code === 200) {
            setProfileImgURL(fileStr);
            setVariant('success');
            setUserMsg(constants.ACCOUNT_SETTINGS_PROFILE_IMAGE_SUCCESS_TEXT);
            props.reloadData();
          } else {
            setVariant('error');
            setUserMsg(response?.meta?.userMsg);

          }
        })
        .catch(function (error) {
          setVariant('error');
          setUserMsg(error?.message);
        });
    }
  };

  const handleUnlockAccount = async () => {
    setShowLoader(true);
    axios
      .put(
        `/partner/orgs/${partnerOrgId}/accounts/${accountData?.accountId}/unlock`,
        {},
        Utils.requestHeader()
      )
      .then(async (response) => {
        setShowLoader(false);
        await props.reloadData();
        props.hideModal();
        props.showUnlockModal();
      })
      .catch((error) => {
        setShowLoader(false);
        setVariant('error');
        setUserMsg(error?.message);
      });
  };

  //=== TODO: We can manage the site modal part using state and props using a single site modal. We will work on this after demo. */

  return (
    <section
      style={{ pointerEvents: !policyEditStatus ? 'none' : 'auto' }}
      className="modal-main-content"
    >
      <div
        className={`parent-edit-modal ${
          resetPwdStatus ||
          invitationStatus ||
          showDeactivateUserModal ||
          showRemoveUserModal
            ? 'd-none'
            : ''
        }`}
      >
        <Row
          className={`${
            accountData?.accountStatus !== 'ACTIVE' ? 'mt-2' : ''
          } mb-4 status-info`}
        >
          <Col className="text-start">
            <span className="activeIcon">
              {accountData?.accountStatus === 'ACTIVE' &&
              accountData?.isLocked ? (
                <>
                  <Col className="text-start">
                    <span className="activeIcon">
                      <FaCircle size={10} className="status-icon-offline" />
                      {constants.LOCKED_ACCOUNT_TEST}
                    </span>
                  </Col>
                  {policyEditStatus ? (
                    <Col className="text-end">
                      <PrimaryButton
                        className={`btn btn-primary-outline p-0 ${
                          showLoader ? 'd-inline-flex' : ''
                        }`}
                        type="button"
                        height="20px"
                        width="auto"
                        borderWidth="0"
                        hoverBorderWidth="0"
                        fontWeight={600}
                        color={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--primary_40')}
                        hoverColor={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--primary_40')}
                        hoverBackgroundColor="transparent"
                        backgroundColor="transparent"
                        onClick={() => handleUnlockAccount()}
                      >
                        {constants.UNLOCK_ACCOUNT_BUTTON_TEXT}
                        {showLoader && (
                          <div className="spinner-wrapper">
                            <SiteSpinner width="15px" height="15px" />
                          </div>
                        )}
                      </PrimaryButton>
                    </Col>
                  ) : null}
                </>
              ) : accountData?.accountStatus === 'ACTIVE' ? (
                <Col className="text-start">
                  <span className="activeIcon">
                    <FaCircle size={10} className="status-icon-active" />
                    {constants.PARTNER_EDIT_ACTIVE_STATUS_TITLE}
                  </span>
                </Col>
              ) : accountData?.accountStatus === 'PENDING_ACTIVATION' ? (
                <>
                  <Col className="text-start">
                    <span className="activeIcon">
                      <FaCircle size={10} className="status-icon-invited" />{' '}
                      {constants.EDIT_CUSTOMER_INACTIVE_STATUS_TITLE}
                    </span>
                  </Col>
                  <Col className="text-end">
                    <PrimaryButton
                      className="btn btn-primary-outline p-0"
                      type="button"
                      height="20px"
                      width="auto"
                      borderWidth="0"
                      hoverBorderWidth="0"
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--primary_40')}
                      hoverColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--primary_40')}
                      hoverBackgroundColor="transparent"
                      backgroundColor="transparent"
                      onClick={() => handleResendInvitationClick()}
                    >
                      {constants.EDIT_CUSTOMER_RESEND_INVITATION_BUTTON_TITLE}
                      {showLoader && (
                        <>
                          &nbsp;
                          <SiteSpinner width="15px" height="15px" />
                        </>
                      )}
                    </PrimaryButton>
                  </Col>
                </>
              ) : (
                <>
                  <Col className="text-start">
                    <span className="activeIcon">
                      <FaCircle size={10} className="status-icon-disabled" />
                      {constants.EDIT_CUSTOMER_INACTIVE_STATUS_TITLE}
                    </span>
                  </Col>
                </>
              )}
            </span>
          </Col>
        </Row>

        <Formik
          initialValues={{
            accountId: accountData.accountId,
            accountStatus: accountData.accountStatus,
            firstName: accountData?.firstName,
            lastName: accountData?.lastName,
            phoneNumber: accountData?.phoneNumber,
            email: accountData?.email,
            role: accountData?.role,
            accountLanguage: accountData?.accountLanguage,
          }}
          // Hooks up our validationSchema to Formik
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setUserMsg('');
            // When button submits form and form is in the process of submitting, submit button is disabled
            setSubmitting(true);
            if (partnerOrgId !== null) {
              values.role = roleChange ? roles.ROLE4VMS : roles.ROLE5VMS;
              const customerOrgsData = [];
              if (assignCustomerData.length > 0) {
                assignCustomerData.forEach((customer) => {
                  customerOrgsData.push(customer.orgId);
                });
              }
              values.orgIds = customerOrgsData;

              axios
                .put(
                  `partner/orgs/${partnerOrgId}/accounts/${accountData?.accountId}`,
                  values,
                  Utils.requestHeader()
                )
                .then((res) => {
                  let response = res.data;
                  if (response.meta.code === 200) {
                    axios
                      .put(
                        `partner/orgs/${partnerOrgId}/accounts/${accountData?.accountId}/assignOrgs`,
                        {
                          orgIds: values.orgIds ?? [],
                        },
                        Utils.requestHeader()
                      )
                      .then((res) => {
                        let response = res?.data;
                        if (response?.meta?.code === 200) {
                          if (response?.data?.organizations?.length > 0) {
                            setEditPartnerAssignedCustomer(response?.data?.organizations)
                          }
                          props.reloadData();
                          props.hideModal();
                          setSubmitting(false);
                        } else {
                          setVariant('error');
                          setUserMsg(response?.meta?.userMsg);
                          setSubmitting(false);
                        }
                      })
                      .catch(function (error) {
                        setVariant('error');
                        setUserMsg(error?.message);
                        setSubmitting(false);
                      });
                  } else {
                    setVariant('error');
                    setUserMsg(response?.meta?.userMsg);
                    setSubmitting(false);
                  }
                })
                .catch(function (error) {
                  setVariant('error');
                  setUserMsg(error?.message);
                  setSubmitting(false);
                });
            }
            // setSubmitting(false);
          }}
        >
          {({
            values,
            setFieldValue,
            handleSubmit,
            isSubmitting,
            isValid,
            dirty,
          }) => (
            <>
              <div className="text-start mb-3 account-profile-icon">
                {profileImgURL ? (
                  <Avatar
                    valueType="icon"
                    value={profileImgURL}
                    size="extraLarge"
                    avatarStyle="roundedCircle"
                  />
                ) : (
                  <HiUserCircle size={88} />
                )}
                <div className="edit-pencil-icon">
                  <label htmlFor="inputGroupFile">
                    <HiOutlinePencil />
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    className="form-control"
                    id="inputGroupFile"
                    onChange={(event) =>
                      handleAccountProfilePictureChange(event)
                    }
                  />
                </div>
              </div>
              <Form className="modal-form" onSubmit={handleSubmit}>
                {!fetchCustOrgsStatus ? (
                  <>
                    <TextField
                      required={true}
                      removebottommargin="true"
                      removetopmargin="true"
                      label=""
                      placeholder={constants.FIRST_NAME_FIELD}
                      name="firstName"
                      type="text"
                      disabled={!policyEditStatus}
                    />
                    <TextField
                      required={true}
                      removebottommargin="true"
                      removetopmargin="true"
                      label=""
                      placeholder={constants.LAST_NAME_FIELD}
                      name="lastName"
                      type="text"
                      disabled={!policyEditStatus}
                    />
                    <TextField
                      removebottommargin="true"
                      removetopmargin="true"
                      label=""
                      placeholder={constants.PHONE_NUMBER_FIELD}
                      name="phoneNumber"
                      type="text"
                      disabled={!policyEditStatus}
                    />
                    <TextField
                      required={true}
                      removebottommargin="true"
                      removetopmargin="true"
                      label=""
                      placeholder={constants.EMAIL_ADDRESS_FIELD}
                      name="email"
                      type="text"
                      disabled={true}
                    />

                    <ToggleInput
                      label={constants.ADMINISTRATOR_FIELD}
                      name="role"
                      value={roleChange}
                      changeHandler={(evebr) =>
                        handleRoleChangeClick(evebr, setFieldValue)
                      }
                    />

                    {accountData?.accountStatus !== 'PENDING_ACTIVATION' &&<ToggleInput
                      classes="mt-2"
                      label={constants.ACCOUNT_STATUS_FIELD}
                      name="accountStatus"
                      value={accountStatus}
                      changeHandler={(evebr) =>
                        handleChangeAccountStatus(evebr)
                      }
                    />}

                    {customerOrgData &&
                      customerOrgData.length > 1 && (
                        <AssignLocations
                          hideIcon={true}
                          label={constants.ASSIGN_CUSTOMERS_FIELD}
                          clickHandler={(evebr) => {
                            setFetchCustOrgsStatus(!fetchCustOrgsStatus);
                          }}
                        />
                      )}
                  </>
                ) : (
                  <>
                    <TextBlock
                      className="mb-4"
                      fontWeight="400"
                      fontSize="14px"
                      lineHeight="20px"
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_primary')}
                    >
                      {constants.PARTNER_EDIT_ASSIGN_CUSTOMER_CONTENT}
                    </TextBlock>
                    <div className="partner-customers-list-wrap">
                      {customerOrgData?.length >= 1 &&
                        getAssignCustomerFields(customerOrgData)}
                    </div>
                  </>
                )}

                <Form.Group as={Row} className="mt-3">
                  <Col md={4} className="text-start">
                    <PrimaryButton
                      className="btn btn-primary-outline"
                      type="button"
                      width="auto"
                      borderWidth="0"
                      hoverBorderWidth="0"
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--error_48')}
                      hoverColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--error_48')}
                      hoverBackgroundColor="transparent"
                      backgroundColor="transparent"
                      onClick={() => setShowRemoveUserModal(true)}
                      disabled={
                        roleChange
                          ? !getLoggedInUserPolicies()
                              ?.partnerorg_remove_partner_admin
                          : !getLoggedInUserPolicies()
                              ?.partnerorg_remove_partner_user
                      }
                    >
                      {constants.EDIT_CUSTOMER_REMOVE_TECH_BUTTON_TITLE}
                    </PrimaryButton>
                  </Col>
                  <Col md={8} className="text-end">
                    {accountData?.accountStatus === 'ACTIVE' && (
                      <PrimaryButton
                        className="btn btn-primary-outline"
                        type="button"
                        width="auto"
                        borderWidth="1.5px"
                        hoverBorderWidth="1.5px"
                        hoverBorderColor={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--primary_40')}
                        color={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--primary_40')}
                        hoverColor={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--brand_white')}
                        hoverBackgroundColor={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--primary_40')}
                        backgroundColor={
                          showPwdLoader
                            ? getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--primary_40')
                            : 'transparent'
                        }
                        loader={showPwdLoader}
                        onClick={() => handleResetPasswordUserClick()}
                        disabled={
                          disableResetButton
                            ? true
                            : roleChange
                            ? !getLoggedInUserPolicies()
                                ?.partnerorg_reset_password_partner_admin
                            : !getLoggedInUserPolicies()
                                ?.partnerorg_reset_password_partner_user
                        }
                      >
                        {constants.EDIT_CUSTOMER_RESET_PASSWORD_BUTTON_TITLE}
                      </PrimaryButton>
                    )}

                    <PrimaryButton
                      className="ms-2 btn btn-primary"
                      type="submit"
                      width="auto"
                      disabled={
                        !(
                          ((dirty && isValid) || assignCustomerChangedFlag) &&
                          getLoggedInUserPolicies()?.update_account
                        )
                      }
                      loader={isSubmitting}
                      height="44px"
                      fontSize="14px"
                    >
                      {constants.PARTNER_EDIT_USER_SUBMIT_BUTTON_TITLE}
                    </PrimaryButton>
                  </Col>
                </Form.Group>
              </Form>

              {/* Show error messages */}
              <SiteToast
                title={
                  variant === 'error'
                    ? constants.ERROR_TOAST_TITLE
                    : constants.SUCCESS_TOAST_TITLE
                }
                show={!!userMsg}
                body={userMsg}
                variant={variant}
                position="top-center"
              />

              {/* Deactivate/Activate user - confirmation modal */}
              <SiteModal
                modalTitle={
                  accountStatus ?
                  Utils.replaceStringValues(
                  constants.EDIT_CUSTOMER_ACTIVATE_POPUP_CONTENT,
                  '$firstName $lastName',
                  `${accountData?.firstName} ${accountData?.lastName}`
                ):
                Utils.replaceStringValues(
                  constants.EDIT_CUSTOMER_DEACTIVATE_POPUP_CONTENT,
                  '$firstName $lastName',
                  `${accountData?.firstName} ${accountData?.lastName}`
                )
              }
                showModal={showDeactivateUserModal}
                hideModal={() => {
                  setShowDeactivateUserModal(false);
                  values?.accountStatus === 'ACTIVE' ?  setAccountStatus(true): setAccountStatus(false)
                }}
                classes="deactivate-partner-user-modal"
              >
                <TextBlock
                  className="mt-1 mb-4"
                  fontWeight="400"
                  fontSize="16px"
                  lineHeight="24px"
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--brand_primary')}
                >
                  {constants.EDIT_CUSTOMER_DEACTIVATE_USER_ACCESS_CONTENT}
                </TextBlock>

                <Row>
                  <Col className="text-end">
                    {accountStatus ? (
                      <PrimaryButton
                        className="btn btn-primary-outline"
                        type="button"
                        width="auto"
                        borderWidth="1.5px"
                        hoverBorderWidth="1.5px"
                        onClick={() => handleActivateUserClick(setFieldValue)}
                      >
                        {
                          constants.EDIT_CUSTOMER_ACTIVATE_USER_CONFIRM_BUTTON_TITLE
                        }
                      </PrimaryButton>
                    ) : (
                      <PrimaryButton
                        className="btn btn-primary-outline"
                        type="button"
                        width="auto"
                        borderWidth="1.5px"
                        hoverBorderWidth="1.5px"
                        borderColor={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--error_64')}
                        hoverBorderColor={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--error_64')}
                        color={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--error_64')}
                        hoverColor={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--brand_white')}
                        hoverBackgroundColor={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--error_64')}
                        backgroundColor="transparent"
                        onClick={() => handleDeactivateUserClick(setFieldValue)}
                      >
                        {
                          constants.EDIT_CUSTOMER_DEACTIVATE_USER_CONFIRM_BUTTON_TITLE
                        }
                      </PrimaryButton>
                    )}

                    <PrimaryButton
                      className="ms-2 btn btn-primary"
                      type="button"
                      height="44px"
                      fontSize="14px"
                      width="auto"
                      onClick={() => {
                        setShowDeactivateUserModal(false);
                        values?.accountStatus === 'ACTIVE' ?  setAccountStatus(true): setAccountStatus(false)                      }}
                    >
                      {
                        constants.EDIT_CUSTOMER_REMOVE_USER_CONFIRM_CANCEL_BUTTON_TITLE
                      }
                    </PrimaryButton>
                  </Col>
                </Row>
                {/* Show error messages */}
                <SiteToast
                  title={
                    variant === 'error'
                      ? constants.ERROR_TOAST_TITLE
                      : constants.SUCCESS_TOAST_TITLE
                  }
                  show={!!userMsg}
                  body={userMsg}
                  variant={variant}
                  position="top-center"
                />
              </SiteModal>
            </>
          )}
        </Formik>
      </div>

      {/* Send invitation - confirmation modal */}
      <SiteModal
        modalTitle={constants.EDIT_CUSTOMER_INVITATION_TITLE}
        showModal={invitationStatus}
        hideModal={() => setInvitationStatus(false)}
        classes="send-invitation-partner-user-modal"
      >
        <div className="text-center">
          <TextBlock
            fontWeight="400"
            fontSize="16px"
            lineHeight="24px"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--brand_primary'
            )}
          >
            {Utils.replaceStringValues(
              constants.EDIT_CUSTOMER_RESEND_INVITATION_POPUP_CONTENT,
              '$email',
              accountData?.email
            )}
          </TextBlock>

          <HiCheckCircle className="mt-4 mb-4 icon-succuess-color" size={96} />

          <PrimaryButton
            className="btn btn-primary w-100 mb-2"
            type="submit"
            height="44px"
            fontSize="14px"
            width="auto"
            onClick={() => props.hideModal()}
          >
            {constants.EDIT_CUSTOMER_BACK_TO_USERS_LIST_BUTTON_TITLE}
          </PrimaryButton>
          <PrimaryButton
            className="btn btn-primary-outline w-100"
            type="button"
            width="auto"
            borderWidth="1.5px"
            hoverBorderWidth="1.5px"
            hoverBorderColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--primary_40'
            )}
            hoverColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--brand_white')}
            hoverBackgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            backgroundColor="transparent"
            onClick={() => {
              setInvitationStatus(false);
            }}
          >
            {Utils.replaceStringValues(
              constants.EDIT_CUSTOMER_RESET_PASSWORD_EDIT_PROFILE_BUTTON,
              '$firstName',
              accountData?.firstName
            )}
          </PrimaryButton>
        </div>
        {/* Show error messages */}
        <SiteToast
          title={
            variant === 'error'
              ? constants.ERROR_TOAST_TITLE
              : constants.SUCCESS_TOAST_TITLE
          }
          show={!!userMsg}
          body={userMsg}
          variant={variant}
          position="top-center"
        />
      </SiteModal>

      {/* Reset password - confirmation modal */}
      <SiteModal
        modalTitle={constants.PARTNER_EDIT__RESET_PASSWORD_POPUP_CONTENT}
        showModal={resetPwdStatus}
        hideModal={() => setResetPwdStatus(false)}
        classes="reset-password-partner-user-modal"
      >
        <div className="text-center">
          <TextBlock
            fontWeight="400"
            fontSize="16px"
            lineHeight="24px"
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--brand_primary'
            )}
          >
            {Utils.replaceStringValues(
              constants.EDIT_CUSTOMER_RESET_PASSWORD_POPUP_CONTENT,
              '$email',
              accountData?.email
            )}
          </TextBlock>

          <HiCheckCircle className="mt-4 mb-4 icon-succuess-color" size={96} />

          <PrimaryButton
            className="btn btn-primary w-100 mb-2"
            type="submit"
            height="44px"
            fontSize="14px"
            width="auto"
            onClick={() => props.hideModal()}
          >
            {constants.EDIT_CUSTOMER_BACK_TO_USERS_LIST_BUTTON_TITLE}
          </PrimaryButton>

          <PrimaryButton
            className="btn btn-primary-outline w-100"
            type="button"
            width="auto"
            borderWidth="1.5px"
            hoverBorderWidth="1.5px"
            hoverBorderColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            color={getComputedStyle(document.documentElement).getPropertyValue(
              '--primary_40'
            )}
            hoverColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--brand_white')}
            hoverBackgroundColor={getComputedStyle(
              document.documentElement
            ).getPropertyValue('--primary_40')}
            backgroundColor="transparent"
            onClick={() => {
              setResetPwdStatus(false);
            }}
          >
            {Utils.replaceStringValues(
              constants.EDIT_CUSTOMER_RESET_PASSWORD_EDIT_PROFILE_BUTTON,
              '$firstName',
              accountData?.firstName
            )}
          </PrimaryButton>
        </div>
        {/* Show error messages */}
        <SiteToast
          title={
            variant === 'error'
              ? constants.ERROR_TOAST_TITLE
              : constants.SUCCESS_TOAST_TITLE
          }
          show={!!userMsg}
          body={userMsg}
          variant={variant}
          position="top-center"
        />
      </SiteModal>

      {/* Remove user - confirmation modal */}
      <SiteModal
        modalTitle={Utils.replaceStringValues(
          constants.EDIT_CUSTOMER_REMOVE_USER_POPUP_CONTENT,
          '$firstName $lastName',
          `${accountData?.firstName} ${accountData?.lastName}`
        )}
        showModal={showRemoveUserModal}
        hideModal={() => setShowRemoveUserModal(false)}
        classes="remove-customer-user-modal"
      >
        <TextBlock
          className="mt-1 mb-4"
          fontWeight="400"
          fontSize="16px"
          lineHeight="24px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--brand_primary'
          )}
        >
          {constants.EDIT_CUSTOMER_REMOVE_USER_MODAL_REMOVE_CONTENT}
        </TextBlock>

        <Row>
          <Col className="text-end">
            <PrimaryButton
              className="btn btn-primary-outline"
              type="button"
              width="auto"
              borderWidth="1.5px"
              hoverBorderWidth="1.5px"
              borderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              hoverBorderColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              hoverColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_white')}
              hoverBackgroundColor={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--error_64')}
              backgroundColor="transparent"
              onClick={() => handleRemoveUserClick()}
            >
              {constants.EDIT_CUSTOMER_REMOVE_USER_CONFIRM_BUTTON_TITLE}
            </PrimaryButton>
            <PrimaryButton
              className="ms-2 btn btn-primary"
              type="button"
              height="44px"
              fontSize="14px"
              width="auto"
              onClick={() => setShowRemoveUserModal(false)}
            >
              {constants.EDIT_CUSTOMER_REMOVE_USER_CONFIRM_CANCEL_BUTTON_TITLE}
            </PrimaryButton>
          </Col>
        </Row>

        {/* Show error messages */}
        <SiteToast
          title={
            variant === 'error'
              ? constants.ERROR_TOAST_TITLE
              : constants.SUCCESS_TOAST_TITLE
          }
          show={!!userMsg}
          body={userMsg}
          variant={variant}
          position="top-center"
        />
      </SiteModal>
    </section>
  );
}

export default EditPartnerUsers;
