import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';

import { TextBlock, SiteSpinner } from '../../../components/common';
import { constants, Utils } from '../../../helpers';

import { useOrganizations } from '../../../store/OrganizationsStore';

import './StatusLog.scss';

const StatusLog = ({
  incidentId,
  incidentStatus,
  incidentResolution,
  incidentTimezone,
  fetchTimezoneLocation,
  openModal,
}) => {
  const [showLoader, setShowLoader] = useState(false);
  const [statusLogs, setStatusLogs] = useState(null);
  const [hasData, setHasData] = useState(false);

  const getCustomerOrgData = useOrganizations(
    (state) => state.getCustomerOrgData
  );

  const orgDetails = getCustomerOrgData()[0];

  useEffect(() => {
    const fetchCurrentLogByIncidentId = async () => {
      try {
        const res = await axios.get(
          `/incident/orgs/${orgDetails?.orgId}/incidents/${incidentId}/status`,
          Utils.requestHeader()
        );
        const responseData = res?.data;
        let currentLogs;

        if (responseData?.meta?.code === 200) {
          currentLogs = responseData?.data?.statuses;

          if (!Array.isArray(currentLogs) || currentLogs?.length < 1) return;

          setStatusLogs([...currentLogs]);
          setHasData(true);
        } else {
        }
      } catch (error) {
        console.error(error);
      } finally {
      }
    };

    if (!incidentId) return;

    fetchCurrentLogByIncidentId();
  }, []);

  const statusContent =
    incidentStatus?.toUpperCase() !== 'CLOSED'
      ? `Incident ${incidentStatus}`
      : `Incident ${incidentStatus} - ${incidentResolution}`;

  const formatStatus = (status, resolution) => {
    let displayStatus;

    if (!status) return;

    switch (status?.toUpperCase()) {
      case 'CLOSED':
        if (!resolution) {
          displayStatus = constants.INCIDENTS_MODAL_STATUS_CLOSED;
        } else {
          displayStatus =
            resolution.toUpperCase() === 'RESOLVED'
              ? constants.INCIDENTS_MODAL_STATUS_CLOSED_RESOLVED
              : constants.INCIDENTS_MODAL_STATUS_CLOSED_UNRESOLVED;
        }
        break;

      case 'REOPENED':
        displayStatus = constants.INCIDENTS_MODAL_STATUS_REOPENED;
        break;

      default:
        displayStatus = constants.INCIDENTS_MODAL_STATUS_OPEN;
    }

    return displayStatus;
  };

  return (
    <>
      <div className="description">
        {constants.INCIDENTS_MODAL_STATUS_LOG_DESCRIPTION}
      </div>
      <Container>
        {hasData ? (
          Array.isArray(statusLogs) &&
          statusLogs.map((statusLog) => (
            <Row>
              <Col>
                <div className="mb-3 log-detail-wrapper">
                  <TextBlock>
                    <div key={statusLog?.id}>
                      <div className="log-detail-status bold">
                        {constants.INCIDENTS_MODAL_STATUS_LOG_STATUS_PREFIX}{' '}
                        <span className="log-detail-status-value">
                          {formatStatus(statusLog.status, statusLog.resolution)}
                        </span>
                      </div>
                      <div className="log-detail-date-time">
                        {`${moment(statusLog?.time).format(
                          'MMM DD, YYYY h:mm:ss A'
                        )} ${
                          incidentTimezone &&
                          moment
                            .tz(
                              statusLog?.time,
                              fetchTimezoneLocation(incidentTimezone)
                            )
                            .zoneAbbr()
                        }`}
                      </div>
                      <div className="log-detail-creator">
                        By {statusLog?.actorName} - {statusLog?.actorEmailId}
                      </div>
                      <div className="log-detail-note">{statusLog?.note}</div>
                    </div>
                  </TextBlock>
                </div>
              </Col>
            </Row>
          ))
        ) : (
          <Row>
            <Col>
              <div className="mb-3 log-detail-wrapper centered">
                <SiteSpinner height="50px" width="50px" />
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default StatusLog;
