import { useEffect, useState } from 'react';
import './stepspos.scss';
import { AppDefaults, constants } from '../../../helpers';
import { PrimaryButton } from '../../../components/common';
import PosIndicator from './PosIndicator';
import { useCSVReader } from 'react-papaparse';
import { PosStore } from '../../../store/PosStore';
import { checkUnslect } from '../../../assets/images';
import { ImCheckboxChecked } from 'react-icons/im';

const StepOneUploadFile = (props) => {
  const [step, setstep] = useState(props?.step);
  const hideModal = () => props.hideModal();
  const [showLoader, setShowLoader] = useState(false);
  const { CSVReader } = useCSVReader();
  const setPosDataList = PosStore((state) => state.setPosDataList);
  const setIsheaderChecked = PosStore((state) => state.setIsheaderChecked);
  const getIsHeaderChecked = PosStore((state) => state.getIsHeaderChecked);
  const [isHeaderChecked, setHeaderChecked] = useState(getIsHeaderChecked());

  const setAtrributeList = PosStore((state) => state.setAtrributeList);
  const resetPosDataList = PosStore((state) => state.resetPosDataList);

  const onHandleChange = () => {
    setHeaderChecked(isHeaderChecked === 0 ? 1 : 0);
    setIsheaderChecked(isHeaderChecked === 0 ? 1 : 0);
  };

  useEffect(() => {
    setAtrributeList([]);
  }, []);

  const parseList = (csvData) => {
    if (csvData?.length === 0) {
      return;
    }
    const listHeader = csvData?.[0];
    let newList = [];
    let araay = [];
    if (isHeaderChecked === 1) {
      const sliceArrayCsv =
        csvData?.length >= 4 ? csvData?.slice(1, 4) : csvData?.slice(1);
      if (sliceArrayCsv?.length >= 0) {
        for (let index = 0; index < sliceArrayCsv?.length; index++) {
          newList.push({ ...newList, ...sliceArrayCsv[index] });
        }
      }
      listHeader?.map((itemHeader, hederIndex) => {
        let itemNames = '';
        for (let i = 0; i < newList?.length; i++) {
          if (newList[i]?.[hederIndex] && newList[i]?.[hederIndex] != '') {
            itemNames += `${newList[i]?.[hederIndex]} |`;
          }
        }
        araay.push({
          Column: itemHeader,
          value: itemNames?.substring(0, itemNames.length - 1),
          isSelected: false,
          order: hederIndex + 1,
        });
      });
      const csvList = araay?.filter(
        (item) => item?.Column != '' && item?.value != ''
      );
      setPosDataList(csvList);
      props.nextStep();
    } else {
      const sliceArrayCsv =
        csvData?.length >= 3 ? csvData?.slice(0, 3) : csvData;
      if (sliceArrayCsv?.length >= 0) {
        for (let index = 0; index < sliceArrayCsv?.length; index++) {
          newList.push({ ...newList, ...sliceArrayCsv[index] });
        }
      }
      listHeader?.map((item, index) => {
        let itemNames = '';
        for (let i = 0; i < newList?.length; i++) {
          if (newList[i]?.[index] && newList[i]?.[index] != '') {
            itemNames += `${newList[i]?.[index]} |`;
          }
        }
        araay.push({
          Column: `Column ${String.fromCharCode(index + 65)}`,
          value: itemNames?.substring(0, itemNames.length - 1),
          isSelected: false,
          order: index + 1,
        });
      });
      const csvList = araay?.filter((item) => item?.value != '');
      setPosDataList(csvList);
      props.nextStep();
    }
  };
  return (
    <div className="pos-integration">
      <PosIndicator showMapPostStatus={0} {...props} />
      <div className="step-one-upload-file-container">
        <div className="lable-upload-file">
          {constants.POS_UPLOAD_SAMPLE_FILE}
        </div>
        <div className="extract-message">
          {constants.POS_UPLOAD_SAMPLE_MESSAGE.replace(
            '${appname}',
            `${
              process.env.REACT_APP_PROJECT === AppDefaults.PROJECT_MEGATRON
                ? constants.POS_ON_CLOUD_CONNECT
                : constants.POS_DUCLO_CONNECT
            }`
          )}
        </div>
        {showLoader && (
          <div
            className="site-spinner-download"
            style={{
              backgroundColor: getComputedStyle(
                document.documentElement
              ).getPropertyValue('--brand_primary'),
            }}
          ></div>
        )}
        <div
          onClick={() => setIsheaderChecked(isHeaderChecked === 0 ? 1 : 0)}
          className="check-row-header-container"
        >
          {/* <input
            checked={isHeaderChecked === 1}
            //name={item.name}
            className="input-check-events"
            type="checkbox"
            onChange={() => onHandleChange()}
          /> */}
          {isHeaderChecked !== 1 && (
            <img
              onClick={() => {
                onHandleChange();
              }}
              alt=""
              className="img-unselected"
              src={checkUnslect}
            ></img>
          )}
          {isHeaderChecked === 1 && (
            <ImCheckboxChecked
              className="img-selected"
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue('--primary_40')}
              onClick={() => onHandleChange()}
              size={20}
            />
          )}

          <div className="file-have-header">
            {constants.POS_MY_FILE_HEADER_TEXT}
          </div>
        </div>
      </div>

      <CSVReader
        validator={(file) => {
          setShowLoader(true);
        }}
        disabled={showLoader}
        onUploadRejected={(file) => {
          setShowLoader(false);
        }}
        onUploadAccepted={(results) => {
          resetPosDataList([]);
          setShowLoader(false);
          parseList(results?.data);
        }}
      >
        {({ getRootProps }) => (
          <>
            <div {...getRootProps()} className="setupPos">
              {constants.POS_MY_FILE_HEADER_TEXT_SELECT}{' '}
            </div>
          </>
        )}
      </CSVReader>
    </div>
  );
};
export default StepOneUploadFile;
