import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { HiOutlineX } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { Header, SiteToast } from '../../components/common';
import { EntitlementsTypeEnum, Utils, constants } from '../../helpers';
import { useOrganizations } from '../../store/OrganizationsStore';
import timezones from '../../data/support/timezone.json';

import useEventsStore from '../../store/EventsStore';
import { useCustomerOrgDevices } from '../../store/CustomerOrgDevicesStore';
import { devicesMQTTStore } from '../../store/DevicesMQTTStore';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';
import useFiltersStore from '../../store/FiltersStore';
import useEventSelectionStore from '../../store/EventSelectionStore';
import {
  getLatestEventsForDots,
  getMetaDataForEvents,
  resetEvents,
  setLatestEventsForDots,
  setMetaDataForEvents,
} from '../../store/reducers/EventsReducer';
import {
  getAllDevicesData,
  getDeviceInformation,
  setDeviceInformation,
  getAllMqttDataFromResponse,
  setAllDevicesData,
  resetAllMqttDataFromResponse,
  setKeepAliveRequest,
} from '../../store/reducers/AccountReducer';
import {
  getPlatformInfo,
  getWSSConnection,
  getCDNInfo,
  setCVRStartDate,
  setChannelTimeStamp,
  setLiveCameraIds,
  setLiveStream,
  setLoadingStream,
  setMetaData,
  setRemoteStream,
  setStartDate,
  setEndOfStream,
  getMQTTConnection,
  setPlatformInfo,
} from '../../store/reducers/StreamingReducer';

import TimelineControls from '../../components/streaming/TimelineControls';
import {
  disconnectWithMQTT,
  mqttPublish,
  mqttSubscribe,
  mqttUnsubscribe,
  publishWithMQTT,
} from '../../utils/connection/mqttConnection';
import EventsList from './EventsList';

import './CameraDashboard.scss';
import {
  checkWSSConnection,
  connectWithWebSocket,
  disconnectWithWebSocket,
  sendPauseCVR,
  sendRegisterCVR,
} from '../../utils/connection/wssConnection';
import IdleTimer from 'react-idle-timer';

const CameraDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let fullscreenRef = useRef(0);

  const [showFetchImageGrid, setShowFetchImageGrid] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [show, setShow] = useState(null);
  const [date, setDate] = useState(null);
  const [internalEventFromSearch, setInternalEventFromSearch] = useState(false);
  const [showFilteredTitle, setShowFilteredTitle] = useState(false);
  const [timezone, setTimezone] = useState(moment.tz.guess());
  const [categoryDashboard, setCategoryDashboard] = useState([
    'objectclass',
    'soundrecognition',
  ]);
  const [eventTimestamp, setEventTimestamp] = useState(null);
  const [listHeight, setListHeight] = useState('100%');
  const [deviceLocalTimezone, setDeviceLocalTimezone] = useState('');
  const deviceDetails = useSelector(getDeviceInformation);
  const devicesDetails = useSelector(getAllDevicesData);
  const platformDetails = useSelector(getPlatformInfo);
  const deviceId = location?.state?.id
    ? location?.state?.id
    : deviceDetails?.deviceId;
  const handle = useFullScreenHandle();
  const { setDeviceTimezone } = useEventsStore();
  const { getAppliedTags } = useFiltersStore();
  const dispatch = useDispatch();
  const getCustomerOrgData = useOrganizations(
    (state) => state.getCustomerOrgData
  );
  const { getEventCVRMode } = useEventsStore();
  const eventCategory = useEventsStore((state) => state.eventCategory);
  const selectedEvent = useEventsStore((state) => state.selectedTimestamp);
  const deviceTimezone = useEventsStore((state) => state.deviceTimezone);
  const eventFromSearch = useEventsStore((state) => state.eventFromSearch);
  const orgDetails = getCustomerOrgData()[0];
  const { getSnapshotCoordinates } = useEventsStore();
  const wssConnection = useSelector(getWSSConnection);
  const getPlatformDetails = useSelector(getPlatformInfo);
  const [hubDetails, setHubDetails] = useState(null);
  const [digitalZoomScale, setDigitalZoomScale] = useState(1);
  const { getCustomerOrgDevices } = useCustomerOrgDevices();

  const customerOrgDevices = getCustomerOrgDevices();
  const { getState } = devicesMQTTStore;
  const state = getState();
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );

  const accountId = loggedInUserData.accountId;
  const eventsData = useSelector(getLatestEventsForDots);
  const deviceMetaData = useSelector(getMetaDataForEvents);
  const { setSelectedRegion, setRegion } = useEventsStore();
  const {
    setSnapshotCoordinate,
    setSelectedEventStore,
    setEventCVRMode,
    setEventFromSearch,
  } = useEventsStore();
  const allMqttData = useSelector(getAllMqttDataFromResponse);
  const setPingApiCallTime = useLoggedInUserData(
    (state) => state.setPingApiCallTime
  );
  const { setNavigatedFromCameraDetail } = useEventSelectionStore();
  const MQTTConnectionStatus = useSelector(getMQTTConnection);
  let keepAliveTimer = null;
  const isTimerRunning = useRef(false);

  const handleClick = (detail) => {
    if (detail === constants.DEVICES_SWITCH_TIMELINE_TITLE) {
      setShow(constants.DEVICES_SWITCH_TIMELINE_TITLE);
    } else if (detail === constants.DEVICES_SWITCH_LIVE_TITLE) {
      dispatch(setRemoteStream(null));
      publishWithMQTT(
        platformDetails.mqtt,
        platformDetails.p2p_server,
        deviceDetails,
        accountId
      );
    }
  };

  const showErrorToaster = (mesg) => {
    setShowToast(true);
    setUserMsg(mesg);
    setTimeout(() => {
      setShowToast(false);
      setUserMsg('');
    }, 5500);
  };

  useEffect(() => {
    const filters = getAppliedTags();
    setShowFilteredTitle(filters && filters.length > 0 ? true : false);
  }, [getAppliedTags()]);

  useEffect(() => {
    if (deviceId && wssConnection && checkWSSConnection()) {
      checkTokenSendWebSocketRequest();
    }
  }, [deviceId, wssConnection]);

  useEffect(() => {
    if (!wssConnection && getPlatformDetails) {
      connectWithWebSocket(getPlatformDetails);
    }
  }, [wssConnection, getPlatformDetails]);

  useEffect(() => {
    dispatch(setKeepAliveRequest(false));
    isTimerRunning.current = true;
    return () => {
      sendPauseCVR(deviceId);
      disconnectWithWebSocket();
      isTimerRunning.current = false;
      dispatch(setKeepAliveRequest(false));
      if (deviceDetails) {
        deleteRequest(deviceDetails);
      }
      clearTimeout(keepAliveTimer);
    };
  }, []);

  useEffect(() => {
    let device = null;
    if (MQTTConnectionStatus && devicesDetails.length) {
      device = devicesDetails?.find((device) => device.deviceId === deviceId);
      subscribeRequest(device);
      dispatch(setKeepAliveRequest(true));
    }
  }, [MQTTConnectionStatus, devicesDetails]);

  const checkTokenSendWebSocketRequest = () => {
    const token_expiry = getPlatformDetails?.timeline_server?.expiry;
    if (token_expiry < new Date() / 1000) {
      axios
        .get(`/partner/platform`, {
          withCredentials: true,
          ...Utils.requestHeader(),
        })
        .then((response) => {
          if (response?.data?.data) {
            dispatch(setPlatformInfo(response?.data?.data));
            // if (!checkMQTTConnection() && accountIdRes) {
            //   connectWithMQTT(accountIdRes);
            // }
            const updated_token = response?.data?.data?.timeline_server?.token;
            sendRegisterCVR(
              deviceId,
              accountId,
              orgDetails?.orgId,
              updated_token
            );
          }
        });
    } else {
      const token = getPlatformDetails?.timeline_server?.token;
      sendRegisterCVR(deviceId, accountId, orgDetails?.orgId, token);
    }
  };

  const subscribeRequest = (device) => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const sessionId = state.getSessionId();
    if (!accountId) {
      return;
    }

    console.log('add request subscribe', device.deviceId);
    // Send the request
    const context = {
      topic: `a/rt-events/${device.gatewayId}`,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${device.gatewayId}`,
        from: `${accountId}`,
        msg: {
          resource: `ch/${device.deviceId}/camera/events/live-events`,
          action: 'add',
          properties: {
            sessionId: sessionId,
            events: [
              {
                detection: [
                  'motion',
                  'person',
                  'vehicle',
                  'lp',
                  'face',
                  'audio',
                  'SoundRecognition',
                ],
                notifications: [
                  'queue-threshold',
                  'tamper',
                  'defocus',
                  'shock',
                ],
                stats: ['queue-count', 'person-count', 'vehicle-count'],
              },
            ],
          },
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
    keepAliveSetTimer(device);
  };

  const keepAliveSetTimer = (device) => {
    //Retrieve keep alive session every 90 seconds
    keepAliveTimer = setTimeout(() => {
      if (isTimerRunning.current) {
        keepAliveRequest(device);
      }
    }, 90000);
  };

  const keepAliveRequest = (device) => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const sessionId = state.getSessionId();

    if (!accountId) {
      return;
    }

    console.log('keep alive request2', device.deviceId);

    // Send the request
    const context = {
      topic: `a/rt-events/${device.gatewayId}`,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${device.gatewayId}`,
        from: `${accountId}`,
        msg: {
          action: 'set',
          resource: `ch/${device.deviceId}/camera/events/live-events`,
          properties: {
            sessionId: sessionId,
          },
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
    if (isTimerRunning.current) {
      keepAliveSetTimer(device);
    }
  };

  const deleteRequest = (device) => {
    const tid = Math.floor(new Date().getTime() / 1000.0);
    const sessionId = state.getSessionId();

    if (!accountId) {
      return;
    }

    // Send the request
    const context = {
      topic: `a/rt-events/${device?.gatewayId}`,
      payload: JSON.stringify({
        tid: `${tid}`,
        to: `${device?.gatewayId}`,
        from: `${accountId}`,
        msg: {
          resource: `ch/${device?.deviceId}/camera/events/live-events`,
          action: 'delete',
          properties: {
            sessionId: sessionId,
          },
        },
        publish: `d/notify/${accountId}/${sessionId}`,
      }),
      qos: 0,
    };
    mqttPublish(context);
  };

  useEffect(() => {
    setSelectedEventStore('null');
    setEventCVRMode('null');
    setEventFromSearch(false);
    dispatch(setChannelTimeStamp(false));
    dispatch(setEndOfStream(false));

    const handleResize = () => {
      let fullscreenEl = fullscreenRef.current;

      if (!fullscreenEl) {
        return;
      }

      setListHeight(
        fullscreenEl.querySelector('.fullscreen')?.clientHeight - 4 + 'px'
      );
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    dispatch(setLiveCameraIds([]));

    return () => {
      sendPauseCVR(deviceId);
      disconnectWithWebSocket();
      window.removeEventListener('resize', handleResize);
      disconnectWithMQTT();
      setSelectedEventStore('null');
      dispatch(setChannelTimeStamp(false));
      setSelectedRegion(null);
      setSnapshotCoordinate({});
      setRegion([]);
    };
  }, []);

  useEffect(() => {
    setInternalEventFromSearch(eventFromSearch);
  }, [eventFromSearch]);

  useEffect(() => {
    if (devicesDetails?.length) {
      const deviceIndex = devicesDetails?.find(
        (device) => device.deviceId === deviceId
      );

      dispatch(setDeviceInformation(deviceIndex));
      dispatch(setLoadingStream(true));
      dispatch(setLiveStream(null));
      dispatch(setRemoteStream(null));
      dispatch(setLatestEventsForDots(null));
      dispatch(setMetaDataForEvents(null));
      dispatch(setMetaData(null));
      //handleClick('LIVE');
      //subscribeRequest(deviceIndex?.areaId);

      if (state.getAccountId() !== accountId) {
        state.setAccountId(accountId);
      }

      if (!state.getSessionId()) {
        state.setSessionId(uuidv4());
      }

      const subscribeForMetaData = {
        topic: `b/streams/${deviceId}`,
        qos: 0,
      };

      const subscribeForEvents = {
        topic: `d/rt-events/${deviceIndex?.gatewayId}`,
        qos: 0,
      };

      // Subscribe to the app topic
      mqttSubscribe(subscribeForMetaData);
      // Subscribe  to the device topic
      mqttSubscribe(subscribeForEvents);

      return () => {
        mqttUnsubscribe(subscribeForMetaData);
        mqttUnsubscribe(subscribeForEvents);
        dispatch(resetEvents);
        disconnectWithMQTT();
      };
    }
  }, [deviceId]);

  useEffect(() => {
    if (deviceDetails) {
      dispatch(setCVRStartDate(''));
      const url = deviceDetails?.entitlement?.url;

      if (url) {
        fetch(url, {
          withCredentials: true,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data) {
              const expiryDate = data?.term?.expiry;
              const durationInDays = data?.term?.data?.durationInDays;
              const startDate =
                expiryDate - (durationInDays + 1) * 24 * 60 * 60 * 1000;
              setDate(startDate);
              dispatch(setStartDate(startDate));
              const cloudStorageCVR = data.entitlements?.find(
                (item) => item.type === EntitlementsTypeEnum.CLOUD_STORAGE_CVR
              );
              if (cloudStorageCVR && cloudStorageCVR?.data?.durationInDays) {
                const cvrStartDate =
                  Date.parse(new Date()) -
                  cloudStorageCVR.data?.durationInDays * 24 * 60 * 60 * 1000;
                dispatch(setCVRStartDate(cvrStartDate));
              }
            }
          });
      }
    }
    const parentDevices = Utils.getTotalParentDevices(customerOrgDevices);
    const parentDevice = parentDevices.find(
      (device) => device.gatewayId === deviceDetails?.gatewayId
    );
    setHubDetails(parentDevice);
    setDeviceLocalTimezone(parentDevice?.properties?.timezone);
    setTimezone(parentDevice?.properties?.timezone);
    const getLocationSelected = timezones?.data?.find(
      (zone) => zone.value === parentDevice?.properties?.timezone
    );
    const zone = getLocationSelected?.location || moment.tz.guess();
    setDeviceTimezone(zone);

    //TODO UPDATE LATER
    // return () => {
    //   dispatch(setDeviceInformation(null));
    // };
  }, [deviceDetails, deviceDetails?.properties?.timezone]);

  useEffect(() => {
    if (eventCategory.length > 0) {
      setCategoryDashboard(eventCategory);
    }
  }, [eventCategory]);

  useEffect(() => {
    setEventTimestamp(selectedEvent);
  }, [selectedEvent]);

  useEffect(() => {
    const responseDeviceId = allMqttData?.msg?.resource
      ?.toString()
      ?.split('/')?.[1];
    const resource = allMqttData?.msg?.resource
      ?.toString()
      ?.split(`${deviceId}/`)?.[1];
    if (resource === 'camera/system/ptz' && deviceId === responseDeviceId) {
      let ptzprops = JSON.parse(JSON.stringify(allMqttData?.msg?.properties));
      if (
        allMqttData?.msg?.properties?.['ptz-pt'] ||
        allMqttData?.msg?.properties?.['ptz-zoom-reset']
      ) {
        ptzprops['ptz-zoom'] = Math.random();
      }
      updateDeviceStore(ptzprops, true, allMqttData?.msg?.action !== 'error');
    }
  }, [allMqttData]);

  const updateDeviceStore = async (properties, isFromMqtt, mqttStatus) => {
    let openDeviceDetails = JSON.parse(JSON.stringify(deviceDetails));
    let deficeInfoProperty = openDeviceDetails?.properties;
    mqttStatus &&
      localStorage.setItem(
        'localStoreProperty',
        JSON.stringify({ ...deficeInfoProperty })
      );
    let localStoreProperty = JSON.parse(
      localStorage.getItem('localStoreProperty')
    );
    deficeInfoProperty = mqttStatus
      ? { ...deficeInfoProperty, ...properties }
      : localStoreProperty && {
          ...deficeInfoProperty,
          ...localStoreProperty,
        };
    openDeviceDetails = {
      ...openDeviceDetails,
      properties: deficeInfoProperty,
    };
    let updatedList = devicesDetails?.map((alldevice, index) => {
      if (alldevice.deviceId === openDeviceDetails.deviceId) {
        return {
          ...openDeviceDetails,
        };
      } else {
        return { ...alldevice };
      }
    });
    let dList = await Promise.all(updatedList);
    dispatch(setAllDevicesData(dList));
    dispatch(setDeviceInformation(openDeviceDetails));
    isFromMqtt && dispatch(resetAllMqttDataFromResponse());
  };
  // Actions to perform when the user is idle
  const handleOnIdle = () => {
    if (loggedInUserData?.sessionTimeout > 1) {
      setPingApiCallTime(new Date().getTime());
      Utils.OnIdleTrack(orgDetails?.orgId, accountId);
    }
  };
  return (
    <div className="App video-wall">
      {loggedInUserData?.sessionTimeout > 1 && (
        <IdleTimer
          timeout={Utils.OnIdleTrackTime(loggedInUserData?.sessionTimeout)}
          onIdle={handleOnIdle}
        ></IdleTimer>
      )}
      <Header showCart={false} />
      <div className="main-wrapper">
        <div className="video-wall-background">
          <div className="custom-container-fluid">
            <div className="page-header mt-4">
              <Row>
                <div className="toast-wrapper">
                  <SiteToast
                    customCss="licenses-list-toast"
                    position="top-end"
                    show={showToast}
                    title="Uh-oh!"
                    body={userMsg}
                    delay={5000}
                  />
                  <div>
                    <div className="device-title">
                      {deviceDetails?.deviceName}
                      {showFilteredTitle && (
                        <span className="filtered-title"> (filtered)</span>
                      )}
                    </div>
                    <div className="device-sub-title">
                      {deviceDetails?.locationAreaNames}
                    </div>
                  </div>
                  <div className="button-nav">
                    <Button
                      className="button-nav-item close-button"
                      onClick={() => {
                        sendPauseCVR(deviceId);
                        disconnectWithWebSocket();
                        setNavigatedFromCameraDetail(true);
                        navigate(-1);
                      }}
                    >
                      <HiOutlineX size={16} className="close-icon" />{' '}
                      {constants.CLOSE_BUTTON}
                    </Button>
                  </div>
                </div>
              </Row>
              <div className="cameras-container video-detail-wrapper">
                <Row className="devices-row">
                  <Col md={9} lg={9} xl={9} xs={12} ref={fullscreenRef}>
                    <FullScreen handle={handle}>
                      <div className="timeline-controls-main-container">
                        {deviceId && (
                          <TimelineControls
                            internalEventFromSearch={eventFromSearch}
                            category={categoryDashboard}
                            eventTimestamp={selectedEvent}
                            eventCVRMode={getEventCVRMode()}
                            timezone={deviceLocalTimezone}
                            startDate={date}
                            deviceId={deviceId}
                            hubId={deviceDetails?.gatewayId}
                            hubDetails={hubDetails}
                            enterFullScreen={() => handle.enter()}
                            exitFullScreen={() => handle.exit()}
                            handleClick={handleClick}
                            customMetaData={deviceMetaData}
                            eventDotsData={eventsData}
                            showFetchImageGrid={showFetchImageGrid}
                            setShowFetchImageGrid={() =>
                              setShowFetchImageGrid(true)
                            }
                            setHideFetchImageGrid={() =>
                              setShowFetchImageGrid(false)
                            }
                            sessionId={state.getSessionId()}
                            snapshotCoordinates={getSnapshotCoordinates()}
                            appliedFilter={getAppliedTags()}
                            showErrorToaster={showErrorToaster}
                            setSnapshotCoordinate={() => {
                              setSelectedRegion({});
                              setSnapshotCoordinate({});
                              setRegion([]);
                            }}
                            setDigitalZoomScale={(value) =>
                              setDigitalZoomScale(value)
                            }
                          />
                        )}
                      </div>
                    </FullScreen>
                  </Col>
                  <Col
                    className="events-list-panel"
                    md={3}
                    lg={3}
                    xl={3}
                    xs={12}
                  >
                    <div className="events-list-wrapper">
                      <div className="event-list-container">
                        <EventsList
                          key={deviceId}
                          deviceId={deviceId}
                          listHeight={listHeight}
                          showFetchImageGrid={() => setShowFetchImageGrid(true)}
                          hideFetchImageGrid={() =>
                            setShowFetchImageGrid(false)
                          }
                          showGridOnImage={showFetchImageGrid}
                          digitalZoomScale={digitalZoomScale}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CameraDashboard;
