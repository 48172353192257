import { useEffect, useRef, useState } from 'react';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import {
  RangeInputField,
  SelectField,
  TextField,
  ToggleInput,
} from '../../../../components/forms';
import { Formik } from 'formik';
import { constants, Utils } from '../../../../helpers';
// TODO : use later once have the aPI
// import axios from 'axios';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import publishDeviceSetting from '../shared/PublishDeviceSettings';
import './AudioSettingsBlock.scss';

const AudioSettingsBlock = ({
  orgId,
  orgName,
  deviceId,
  deviceName,
  saveAudioSettingsFlag,
  saveAudioSettingsCallback,
  deviceInfos,
  accountId,
  devicePublishId,
  uuid,
  updateStore,
}) => {
  const formRef = useRef();
  const [deviceSettings, setDeviceSettings] = useState([]);
  const [variant, setVariant] = useState('danger');
  const [userMsg, setUserMsg] = useState('');
  const [showMsg, setShowMsg] = useState(true);
  const capabilityList = deviceInfos?.capDetails?.capabilities;
  const deviceState = deviceInfos?.deviceStatus;
  const connectionState = deviceInfos?.connectionStatus;
  const curDeviceStatus = Utils.getDeviceStatus(deviceState, connectionState);
  const resourceList = Utils.getDeviceSettingResource(3);
  const capAudioInput = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[0]
  );
  const audioInSource = Utils.getDropDownPair(
    capAudioInput?.['audio-in-source']
  );
  const capAudioOutput = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[1]
  );
  const publishPayload = {
    deviceId: deviceId,
    devicePublishId: devicePublishId,
    uuid: uuid,
    accountId: accountId,
    hubId: deviceInfos.gatewayId,
  };

  useEffect(() => {
    if (formRef?.current && saveAudioSettingsFlag) {
      formRef?.current?.handleSubmit();
    }
  }, [saveAudioSettingsFlag]);

  const getCheckPropertyStatus = (value, key) => {
    return {
      [key]: value
        ? constants.PROPERTY_STATUS_TRUE
        : constants.PROPERTY_STATUS_FALSE,
    };
  };

  return (
    <div
      className={`text-start audio-settings-block ${
        curDeviceStatus !== constants.DEVICES_RETURN_ONLINE_STATUS
          ? ' editable-off-offline'
          : ''
      }`}
    >
      <div className="tab-title">
        <label>{constants.DEVICES_TAB_AUDIO_SETTINGS}</label>
      </div>

      {showMsg &&
        curDeviceStatus !== constants.DEVICES_RETURN_ONLINE_STATUS && (
          <Alert
            variant={variant}
            onClose={() => setShowMsg(false)}
            dismissible
          >
            <Row>
              <Col md={1}>
                <HiOutlineExclamationCircle
                  size={15}
                  stroke={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--error_24')}
                />
              </Col>
              <Col>
                {userMsg ? (
                  <p>{userMsg}</p>
                ) : (
                  <>
                    <Alert.Heading>
                      {constants.UNABLE_TO_SAVE_AUDIO_SETTINGS_HEADING}
                    </Alert.Heading>
                    <p>{constants.UNABLE_TO_SAVE_AUDIO_SETTINGS_MESSAGE}</p>
                  </>
                )}
              </Col>
            </Row>
          </Alert>
        )}
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{
          audioInSource: deviceInfos?.properties?.['audio-in-source']
            ? deviceInfos?.properties?.['audio-in-source']
            : '',
          volume: Utils.getPropertyValueIfExist(
            deviceInfos?.properties?.['audio-in-gain'],
            false
          ),
          sensitivity: Utils.getPropertyValueIfExist(
            deviceInfos?.properties?.['audio-in-sensitivity'],
            false
          ),
          noiseReduction:
            deviceInfos?.properties?.['audio-in-noise-reduction'] ===
            constants.PROPERTY_STATUS_TRUE,
          audioOutVolume: Utils.getPropertyValueIfExist(
            deviceInfos?.properties?.['audio-out-gain'],
            false
          ),
        }}
        onSubmit={(values, { setSubmitting }) => {
          // TODO : Delete later
          // console.log(values);
          setSubmitting(true);
          setVariant('danger');
          setUserMsg('');
          setSubmitting(false);
          saveAudioSettingsCallback(false);
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <Form className="form" onSubmit={handleSubmit}>
            <Row className="settings-block audio-in-title">
              <Col>
                <label className="settings-block-title">
                  {constants.DEVICES_TAB_AUDIO_SETTINGS_AUDIO_IN_LABEL}
                </label>
              </Col>
            </Row>
            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'audio-in-source',
              capAudioInput
            ) && (
              <Row className="settings-block">
                <Col>
                  <SelectField
                    name="audioInSource"
                    options={audioInSource}
                    label=""
                    defaultOptionLabel={
                      constants.DEVICES_TAB_AUDIO_SETTINGS_AUDIO_IN_SEARCH_LABEL
                    }
                    onChange={(evebr) => {
                      let selectedVal = evebr?.target?.value;
                      setFieldValue('audioInSource', selectedVal);
                      const property = {
                        'audio-in-source': selectedVal,
                      };
                      updateStore(property);
                      publishDeviceSetting(
                        property,
                        resourceList[0],
                        publishPayload
                      );
                    }}
                    placeholderOptionClass='d-none'
                  />
                </Col>
              </Row>
            )}
            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'audio-in-gain',
              capAudioInput
            ) && (
              <Row className="settings-block">
                <Col>
                  <RangeInputField
                    label={constants.DEVICES_TAB_AUDIO_SETTINGS_VOLUME_LABEL}
                    fieldName="volume"
                    minValueLabel={constants.MIN_VALUE_LABEL}
                    maxValueLabel={constants.MAX_VALUE_LABEL}
                    maxValue={Utils.getPropertyValueIfExist(
                      capAudioInput?.['audio-in-gain']?.max,
                      true
                    )}
                    minValue={Utils.getPropertyValueIfExist(
                      capAudioInput?.['audio-in-gain']?.min,
                      false
                    )}
                    currentValue={values.volume}
                    changeHandler={(value) => {
                      setFieldValue('volume', value);
                      const property = {
                        'audio-in-gain': value.toString(),
                      };
                      updateStore(property);
                      publishDeviceSetting(
                        property,
                        resourceList[0],
                        publishPayload
                      );
                    }}
                    thumbLabelHide={true}
                  />
                </Col>
              </Row>
            )}
            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'audio-in-sensitivity',
              capAudioInput
            ) && (
              <Row className="settings-block">
                <Col>
                  <RangeInputField
                    label={
                      constants.DEVICES_TAB_AUDIO_SETTINGS_SENSITIVITY_LABEL
                    }
                    fieldName="sensitivity"
                    minValueLabel={constants.MIN_VALUE_LABEL}
                    maxValueLabel={constants.MAX_VALUE_LABEL}
                    maxValue={Utils.getPropertyValueIfExist(
                      capAudioInput?.['audio-in-sensitivity']?.max,
                      true
                    )}
                    minValue={Utils.getPropertyValueIfExist(
                      capAudioInput?.['audio-in-sensitivity']?.min,
                      false
                    )}
                    currentValue={values.sensitivity}
                    thumbLabelHide={true}
                    changeHandler={(value) => {
                      setFieldValue('sensitivity', value);
                      const property = {
                        'audio-in-sensitivity': value.toString(),
                      };
                      updateStore(property);
                      publishDeviceSetting(
                        property,
                        resourceList[0],
                        publishPayload
                      );
                    }}
                  />
                </Col>
              </Row>
            )}
            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'audio-in-noise-reduction',
              capAudioInput
            ) && (
              <Row className="settings-block">
                <Col>
                  <ToggleInput
                    label={
                      constants.DEVICES_TAB_AUDIO_SETTINGS_NOISE_REDUCTION_LABEL
                    }
                    name="noiseReduction"
                    value={values.noiseReduction}
                    changeHandler={() => {
                      setFieldValue('noiseReduction', !values.noiseReduction);
                      const status = getCheckPropertyStatus(
                        !values.noiseReduction,
                        'audio-in-noise-reduction'
                      );
                      updateStore(status);
                      publishDeviceSetting(
                        status,
                        resourceList[0],
                        publishPayload
                      );
                    }}
                  />
                </Col>
              </Row>
            )}
            <Row className="settings-block">
              <Col>
                <label className="settings-block-title">
                  {constants.DEVICES_TAB_AUDIO_SETTINGS_AUDIO_OUT_LABEL}
                </label>
              </Col>
            </Row>
            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'audio-out-enable',
              capAudioOutput
            ) && (
              <Row className="settings-block">
                <Col>
                  <RangeInputField
                    label={constants.DEVICES_TAB_AUDIO_SETTINGS_VOLUME_LABEL}
                    fieldName="audioOutVolume"
                    minValueLabel={constants.MIN_VALUE_LABEL}
                    maxValueLabel={constants.MAX_VALUE_LABEL}
                    maxValue={Utils.getPropertyValueIfExist(
                      capAudioOutput?.['audio-out-gain']?.max,
                      true
                    )}
                    minValue={Utils.getPropertyValueIfExist(
                      capAudioOutput?.['audio-out-gain']?.min,
                      false
                    )}
                    currentValue={values.audioOutVolume}
                    changeHandler={(value) => {
                      setFieldValue('audioOutVolume', value);
                      const property = {
                        'audio-out-gain': value.toString(),
                      };
                      updateStore(property);
                      publishDeviceSetting(
                        property,
                        resourceList[1],
                        publishPayload
                      );
                    }}
                    thumbLabelHide={true}
                  />
                </Col>
              </Row>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AudioSettingsBlock;
