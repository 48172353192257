import { useEffect, useRef, useState } from 'react';
import { ArrowRight, XCircleFill } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { constants, Utils } from '../../../helpers';
import {
  getSnapshotImage,
  getSnapshotImages,
  setIsRemoteStreamPlay,
} from '../../../store/reducers/StreamingReducer';
import RegionSelect from 'react-region-select';
import { getAllDevicesData } from '../../../store/reducers/AccountReducer';
import useEventsStore from '../../../store/EventsStore';
import nosnapshot from '../../../assets/images/nosnapshot.svg';

const ImageFetch = ({
  customClass,
  time,
  imageURL = null,
  cdnValue,
  deviceId,
  liveSnapshot,
  moveTimeline,
  camera,
  isFromSearchDashboard = false,
  deviceName,
  onLiveClick,
  showFetchImageGrid,
  hideFetchImageGrid,
  callCountAPI,
}) => {
  const dispatch = useDispatch();
  const [newSrc, setNewSrc] = useState('');
  const [selectedSnapSrc, setSelectedSnapSrc] = useState('');
  const [snapshot, setSnapshot] = useState(null);
  const snapshotImage = useSelector(getSnapshotImage);
  const snapshotImages = useSelector(getSnapshotImages);
  const [moveTimelineInt, setMovetimelineInt] = useState(false);
  const [hovered, setHovered] = useState(false);
  const toggleHover = () => setHovered(!hovered);
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegions] = useState();
  const devicesDetails = useSelector(getAllDevicesData);
  const [refResolution, setRefResolution] = useState();
  const [finalCoordinates, setFinalCoordinates] = useState({});
  const [isRegionChanging, setIsRegionChanging] = useState(true);
  const [imgWidth, setImagWidth] = useState();

  const { getSelectedEvent } = useEventsStore();
  const {
    setSnapshotCoordinate,
    setSelectedRegion,
    getSelectedRegion,
    setRegion,
    getRegion,
  } = useEventsStore();

  const imageRef = useRef();

  const resetObjects = () => {
    hideFetchImageGrid();
    setSelectedRegions(null);
    setSelectedRegion(null);
    setRegions([]);
    setRegion([]);
    setFinalCoordinates({});
    setSnapshotCoordinate({});
  };
  const onChange = (regions) => {
    setRegions(regions);
    setSelectedRegions(regions);
    setSelectedRegion(regions);
    setRegion(regions);
    handleRemoveSelected();
  };

  const handleRemoveSelected = () => {
    if (selectedRegion) {
      const updatedRegions = regions.filter(
        (region) => region !== selectedRegion
      );
      setRegions(updatedRegions);
      setSelectedRegions(null);
    }
  };

  const regionRenderer = (regionProps) => {
    if (!regionProps.isChanging) {
      if (isRegionChanging) {
        setIsRegionChanging(false);
      }
      return (
        <div class="iconset">
          <XCircleFill
            size={24}
            onClick={resetObjects}
            style={{ cursor: 'pointer' }}
          />
        </div>
      );
    } else {
      setIsRegionChanging(true);
    }
  };

  useEffect(() => {
    if (!isRegionChanging && Object.keys(finalCoordinates).length > 0) {
      setSnapshotCoordinate(finalCoordinates);
      callCountAPI();
    } else {
      if (!isRegionChanging) {
        callCountAPI();
      }
    }
  }, [isRegionChanging, finalCoordinates]);

  useEffect(() => {
    if (selectedRegion && refResolution) {
      const divResolutionWidth =
        document.getElementById('img-snapshot').offsetWidth;
      const divResolutionHeight =
        document.getElementById('img-snapshot').offsetHeight;
      const proportionWidth = parseInt(refResolution[0]) / divResolutionWidth; // guess ref resolution is 2592 x 1520
      const proportionHeight = parseInt(refResolution[1]) / divResolutionHeight; // guess ref resolution is 2592 x 1520

      const bottomLeftX =
        proportionWidth * ((divResolutionWidth / 100) * selectedRegion[0].x);
      const bottomLeftY =
        proportionHeight *
        ((divResolutionHeight / 100) *
          (selectedRegion[0].y + selectedRegion[0].height));
      const topRightX =
        proportionWidth *
        ((divResolutionWidth / 100) *
          (selectedRegion[0].x + selectedRegion[0].width));
      const topRightY =
        proportionHeight * ((divResolutionHeight / 100) * selectedRegion[0].y);

      const finalCoordinateObj = {
        bottomLeftX,
        bottomLeftY,
        topRightX,
        topRightY,
      };
      setFinalCoordinates(finalCoordinateObj);
    }
  }, [selectedRegion, imgWidth]);

  useEffect(() => {
    if (deviceId && devicesDetails.length) {
      const selectedChildDevice = devicesDetails.find(
        (device) => device?.deviceId === deviceId
      );
      if (selectedChildDevice) {
        const referenceResolution =
          selectedChildDevice?.properties?.['reference-resolution'];
        if (referenceResolution) {
          setRefResolution(referenceResolution.split('x'));
        }
      }
    }
  }, [devicesDetails]);

  useEffect(() => {
    if (showFetchImageGrid === false) {
      setSelectedRegions(null);
      setRegions([]);
      setFinalCoordinates({});
    } else {
      const ele = document.getElementById('img-snapshot');
      if (ele) {
        const divResolutionWidth = ele.offsetWidth;
        setImagWidth(divResolutionWidth);
      }
    }
  }, [showFetchImageGrid, selectedSnapSrc, newSrc]);

  const handleWindowSizeChange = () => {
    try {
      const divElement = document.getElementById('img-snapshot');
      if (divElement) {
        const divResolutionWidth = divElement.offsetWidth;
        setImagWidth(divResolutionWidth);
      }
    } catch (err) {
      console.log(err)
    }
  };

  useEffect(() => {
    const checkAspectRatio = () => {
      const imageSnapshot = imageRef.current;

      if (liveSnapshot && imageSnapshot) {
        imageSnapshot.onload = () => {
          const w = imageSnapshot.naturalWidth;
          const h = imageSnapshot.naturalHeight;
          const r = Utils?.getGreatestCommonDivisor(w, h);

          // TODO: investigate this later to address the
          // 1:1 aspect ratio
          // if (w / r === 382 && h / r === 225) {
          //   imageSnapshot.classList.add('square-image');
          // }
        };
      }
    };

    dispatch(setIsRemoteStreamPlay(false));
    window.addEventListener('resize', handleWindowSizeChange);

    checkAspectRatio();

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setMovetimelineInt(moveTimeline);
  }, [moveTimeline]);

  useEffect(() => {
    const newTime = Utils.getUnixDate(Utils.getDate(time / 1000));

    if (
      deviceId &&
      newTime &&
      !imageURL &&
      cdnValue?.protocol &&
      cdnValue?.host
    ) {
      const bucket = (cdnValue?.bucket).replace('${deviceId}', deviceId);
      const date = Utils.fetchDateInUnix(newTime);

      fetch(
        `${cdnValue?.protocol}://${cdnValue?.host}/${bucket}/${date}/${newTime}.jpg`,
        {
          credentials: 'include',
        }
      )
        .then((response) => response.blob())
        .then((blob) => {
          if (
            getSelectedEvent() &&
            Utils.getUnixDate(Utils.getDate(getSelectedEvent() / 1000)) ===
              newTime
          ) {
            setSelectedSnapSrc(URL.createObjectURL(blob));
          } else {
            setNewSrc(URL.createObjectURL(blob));
          }
        });
      // TODO: Below code is kept for observation
      // .then((response) => {
      //   if (response.ok) {
      //     setNewSrc(response?.url);
      //     if (!moveTimelineInt) {
      //       setTimeout(() => {
      //         setNewSrc(response?.url);
      //       }, 200);
      //     }
      //   } else {
      //     if (!moveTimelineInt) {
      //       setTimeout(() => {
      //         setNewSrc(noVideo);
      //       }, 100);
      //     }
      //   }
      // })
      // .catch(() => null);
    } else if (imageURL) {
      setNewSrc(imageURL);
    }
  }, [time, deviceId]);

  useEffect(() => {
    if (camera) {
      setSnapshot(snapshotImages[deviceId]);
    } else {
      setSnapshot(snapshotImage);
    }
  }, [snapshotImage, deviceId, camera]);

  return (
    <div
      id="image_wrapper"
      className={`image-wrapper ${customClass ? customClass : ''}`}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
    >
      {deviceName && (
        <>
          <span className={hovered ? 'hovered device-name' : 'device-name'}>
            {deviceName}
          </span>
          <span
            onClick={() => onLiveClick()}
            className={hovered ? 'hovered detail-camera' : 'detail-camera'}
          >
            {constants.CAMERA_DETAIL}
            <ArrowRight width={18} />
          </span>
        </>
      )}
      {liveSnapshot ? (
        <img
          ref={imageRef}
          id="img-snapshot"
          src={snapshot}
          alt=""
          className="live-snapshot"
          onLoad={(event) => {
            dispatch(setIsRemoteStreamPlay(true));
          }}
          onError={(event) => (event.target.style.display = 'none')}
        />
      ) : (
        <>
          {selectedSnapSrc || newSrc ? (
            <img
              ref={imageRef}
              id="img-snapshot"
              className={`${isFromSearchDashboard ? ' img-event' : ''}`}
              src={
                Utils.getUnixDate(Utils.getDate(getSelectedEvent() / 1000)) ===
                  Utils.getUnixDate(Utils.getDate(time / 1000)) &&
                selectedSnapSrc
                  ? selectedSnapSrc
                  : newSrc
                  ? newSrc
                  : nosnapshot
              }
              alt=""
              onLoad={(event) => {
                event.target.style.display = 'inline-block';
                dispatch(setIsRemoteStreamPlay(true));
              }}
              onError={(event) => {
                isFromSearchDashboard
                  ? (event.target.src = nosnapshot)
                  : (event.target.style.display = 'none');
              }}
            />
          ) : null}
        </>
      )}
      {showFetchImageGrid && (selectedSnapSrc || newSrc) && (
        <div className="region-select-main">
          <RegionSelect
            id="region-select"
            maxRegions={1}
            regions={getRegion()}
            selectedRegion={getSelectedRegion()}
            regionStyle={{
              background: '#ffffff70',
              zIndex: 70,
            }}
            onChange={onChange}
            regionRenderer={regionRenderer}
            style={{
              border: '0px solid black',
              position: 'absolute',
              width: imgWidth,
              height: '-webkit-fill-available',
              display: 'inline-block',
            }}
            constraint={true}
          >
            <div class="grid-image-fetch"></div>
          </RegionSelect>
        </div>
      )}
    </div>
  );
};

export default ImageFetch;
