import { useState } from 'react';
import EditMappingModal from './EditMappingModal';
import EditUniqeIdentiFier from './EditUniqeIdentiFier';

const EditStepPos = (props) => {
  const [step, setstep] = useState(1);
  const hideModal = () => props.hideModal();
  const[editListData,setEditListData]=useState(props?.mappingData)
  const nextStep = () => {
    setstep(step + 1);
  };
  const prevStep = () => {
    setstep(step - 1);
  };

  const updateList = (data) => {
    setEditListData(data);
  };

  switch (step) {
    case 1:
      return (
        <>
          <EditMappingModal
            hideModal={() => hideModal()}
            nextStep={nextStep}
            step={step}
            mappingData={editListData}
            goTOSetup={() => props.goTOSetup()}
            updateList={(data) => {
              updateList(data);
            }}

          />
        </>
      );
    case 2:
      return (
        <>
        <EditUniqeIdentiFier
            hideModal={() => hideModal()}
            prevStepBack={prevStep}
            step={step}
            mappingData={editListData}
            updateList={(data) => {
              updateList(data);
            }}
          />
        </>
      );
    default:
      return null;
  }
};
export default EditStepPos;
