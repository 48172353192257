import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  MarkerClusterer,
  Marker,
  useJsApiLoader
} from '@react-google-maps/api';
import { PrimaryButton } from '../../components/common';
import { constants } from '../../helpers';
import { ReactComponent as MarkerHeaderImg } from '../../assets/images/icons/MarkerHeaderImg.svg';
import './ManageLocations.scss';
import { usePoliciesStore } from '../../store/policiesStore';

// Create svg for Marker Icon
const getCustomSvgMarkerIcon = (areas, devices) =>
  `<svg width="100" height="42" viewBox="0 0 100 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="3.12891" y="2" width="86" height="36" rx="18" fill='${getComputedStyle(
    document.documentElement
  ).getPropertyValue('--primary_40')}'/>
<path d="M26.9014 23.1046C26.3797 23.6263 25.3821 24.6239 24.544 25.462C23.763 26.243 22.4975 26.2431 21.7164 25.462C20.894 24.6396 19.9133 23.6589 19.359 23.1046C17.2762 21.0218 17.2762 17.6449 19.359 15.5621C21.4418 13.4793 24.8186 13.4793 26.9014 15.5621C28.9842 17.6449 28.9842 21.0218 26.9014 23.1046Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.1302 19.3333C25.1302 20.4379 24.2348 21.3333 23.1302 21.3333C22.0256 21.3333 21.1302 20.4379 21.1302 19.3333C21.1302 18.2288 22.0256 17.3333 23.1302 17.3333C24.2348 17.3333 25.1302 18.2288 25.1302 19.3333Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<text x="35" y="25" fill="#fff" font-family="Noto Sans">${areas}</text>
<path fill-rule="evenodd" clip-rule="evenodd" d="M58.1276 14C58.8305 14 59.4064 14.5439 59.4573 15.2338L59.4609 15.3333C59.4609 15.6752 59.7183 15.957 60.0499 15.9955L60.1276 16H60.7943C61.8594 16 62.73 16.8326 62.7909 17.8825L62.7943 18V24C62.7943 25.0651 61.9617 25.9358 60.9118 25.9966L60.7943 26H51.4609C50.3958 26 49.5252 25.1674 49.4643 24.1175L49.4609 24V18C49.4609 16.9349 50.2936 16.0642 51.3434 16.0034L51.4609 16H52.1276C52.4958 16 52.7943 15.7015 52.7943 15.3333C52.7943 14.6304 53.3382 14.0546 54.0281 14.0037L54.1276 14H58.1276ZM58.1276 15.3333H54.1276L54.1242 15.4508C54.0656 16.4618 53.2561 17.2714 52.2451 17.3299L52.1276 17.3333H51.4609C51.119 17.3333 50.8373 17.5907 50.7988 17.9223L50.7943 18V24C50.7943 24.3419 51.0516 24.6237 51.3832 24.6622L51.4609 24.6667H60.7943C61.1362 24.6667 61.4179 24.4093 61.4565 24.0777L61.4609 24V18C61.4609 17.6581 61.2036 17.3763 60.872 17.3378L60.7943 17.3333H60.1276C59.023 17.3333 58.1276 16.4379 58.1276 15.3333ZM56.1276 18C54.6548 18 53.4609 19.1939 53.4609 20.6667C53.4609 22.1394 54.6548 23.3333 56.1276 23.3333C57.6004 23.3333 58.7943 22.1394 58.7943 20.6667C58.7943 19.1939 57.6004 18 56.1276 18ZM56.1276 19.3333C56.864 19.3333 57.4609 19.9303 57.4609 20.6667C57.4609 21.403 56.864 22 56.1276 22C55.3912 22 54.7943 21.403 54.7943 20.6667C54.7943 19.9303 55.3912 19.3333 56.1276 19.3333Z" fill="white"/>
<text x="68" y="25" fill="#fff" font-family="Noto Sans">${devices}</text>
</svg>`;

const LocationsMap = ({ locationPositions, handleEditLocation, ...props }) => {
  const [activeMarker, setActiveMarker] = useState(null);
  const navigate = useNavigate();
  const [map, setMap] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACE_URL,
    libraries: ["places"]

  })

  //=== Store get/set actions
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies
  );

  const center = {
    lat: locationPositions?.length > 0 ? locationPositions[0]?.lat : 0,
    lng: locationPositions?.length > 0 ? locationPositions[0]?.lng : 180,
  };

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const onLoad = useCallback((map) => {
    displayWithBounds(map);
  }, []);

  const displayWithBounds = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    for (let i = 0; i < locationPositions.length; i++) {
      bounds.extend(locationPositions[i]);
    }

    map.fitBounds(bounds);
    setMap(map);
  }

  const onUnmount = useCallback((map) => {
    setMap(null);
  }, []);

  return (
    isLoaded &&
      <GoogleMap
        id='map'
        mapContainerStyle={{
          height: 'calc(100vh - 192px)',
          width: '100%',
        }}
        options={{
          styles: [{ stylers: [{ saturation: -100 }] }],
          streetViewControl: false,
          fullscreenControl: false,
        }}
        center={center}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <MarkerClusterer clusterClass="marker-cluster">
          {(clusterer) =>
            Array.isArray(locationPositions) &&
            locationPositions.map((location, index) => {
              let curLat = location?.lat;
              let curLng = location?.lng;
              // If having duplicate Markers change the position a bit to the nearer location
              let ifDataExists = locationPositions?.find(
                (row) =>
                  row?.lat === curLat &&
                  row?.lng === curLng &&
                  row?.locationId !== location?.locationId
              );
              if (ifDataExists) {
                curLat = curLat + (Math.random() - 0.5) / 1500;
                curLng = curLng + (Math.random() - 0.5) / 1500;
              }
              return (
                <Marker
                  key={index}
                  position={{ lat: curLat, lng: curLng }}
                  clusterer={clusterer}
                  icon={
                    'data:image/svg+xml;base64,' +
                    window.btoa(
                      getCustomSvgMarkerIcon(1, location?.deviceCount)
                    )
                  }
                  onClick={() => handleActiveMarker(index)}
                >
                  {activeMarker === index && (
                    <InfoWindow onCloseClick={() => 
                      {
                        setActiveMarker(null);
                        displayWithBounds(map);
                      }}>
                      <div className="marker-info-wrapper">
                        <MarkerHeaderImg />
                        <div className="d-flex justify-content-between mt-2">
                          <span className="marker-info-org-title">
                            {location?.orgName}
                          </span>
                          {getCustomerOrgPolicies().update_location && (
                            <PrimaryButton
                              className="btn btn-primary-outline p-0"
                              type="button"
                              height="20px"
                              width="auto"
                              borderWidth="0"
                              hoverBorderWidth="0"
                              color={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--primary_40')}
                              hoverColor={getComputedStyle(
                                document.documentElement
                              ).getPropertyValue('--primary_40')}
                              hoverBackgroundColor="transparent"
                              backgroundColor="transparent"
                              onClick={() =>
                                handleEditLocation(location?.locationId)
                              }
                            >
                              {constants.NEW_LOCATIONS_MARKER_EDIT_BUTTON_TITLE}
                            </PrimaryButton>
                          )}
                        </div>
                        <div className="marker-info-item d-flex flex-column align-items-start">
                          <div className="marker-info-item-label">
                            {constants.NEW_LOCATIONS_MARKER_LOCATION_NAME_LABEL}
                          </div>
                          <span className="marker-info-item-value">
                            {location?.locationName}
                          </span>
                        </div>
                        <div className="marker-info-item d-flex flex-column align-items-start">
                          <div className="marker-info-item-label">
                            {
                              constants.NEW_LOCATIONS_MARKER_LOCATION_ADDRESS_LABEL
                            }
                          </div>
                          <span className="marker-info-item-value">
                            {`${location?.address}${location?.city}, ${location?.state}, ${location?.country}, ${location?.zipcode}`}
                          </span>
                        </div>
                        {getCustomerOrgPolicies().view_device && (
                          <PrimaryButton
                            className="mt-3 btn btn-primary w-100"
                            type="submit"
                            height="44px"
                            fontSize="0.875rem"
                            onClick={() =>
                              navigate(
                                `/devices/listing.html?orgId=${location?.orgId}`
                              )
                            }
                          >
                            {constants.NEW_LOCATIONS_MARKER_BUTTON_TITLE}
                          </PrimaryButton>
                        )}
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              );
            })
          }
        </MarkerClusterer>
      </GoogleMap>
  );
};
export default LocationsMap;
