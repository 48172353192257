import { RxCross1 } from 'react-icons/rx';
import { BiArrowBack } from 'react-icons/bi';
import './stepspos.scss';
import { useState } from 'react';
import { constants, roles } from '../../../helpers';
import { useOrganizations } from '../../../store/OrganizationsStore';

const EditPosIndicator = (props) => {
  return (
    <div>
      <div className="steps-header-conatiner">
        <div className="header-container">
          <div className="left-section-layout">
            <div className="header-title">
              {constants.EDIT_DATA_MAPPING}
            </div>
          </div>
          <RxCross1 size={24} onClick={() => props.hideModal()} />
        </div>
      </div>
      {
        (props?.showMapPostStatus === 0 && (
          <div className="pos-step-indicator-conatiner">
            {[...Array(2)].map((item, rowIndex) => {
              return (
                <div
                  key={rowIndex}
                  className={`step-indicator ${
                    rowIndex !== 0 ? 'margin-other' : ''
                  } ${props.step >= rowIndex + 1 && 'selected'}`}
                ></div>
              );
            })}
          </div>
        ))}
      {
        (props?.showMapPostStatus === 0 && (
          <div className="step-one-to-three">
            <div className="text-content">
              {constants.STEP_ONE_OF_TWO.replace('${step}', props.step)}{' '}
            </div>
          </div>
        ))}
    </div>
  );
};

export default EditPosIndicator;
