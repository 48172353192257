import { useKeycloak } from '@react-keycloak/web';
import { Navigate } from 'react-router';
import { SetAuthToken } from './SetAuthToken';
import { useEffect } from 'react';
import { SiteSpinner } from '../components/common';
import { constants} from '../helpers';

const PrivateRoute = ({ children }) => {
  var authenticatedStatus = localStorage.getItem('authenticated');
  var token = localStorage.getItem('vmsAuthToken');

  if (token) {
    /* If token is exists pass this into the header */
    SetAuthToken(token);
  }

  const isLoggedIn = authenticatedStatus;
  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return (
      <div
        className='position-absolute top-50 text-center'
        style={{
          left: '47%'
        }}
      >
        <SiteSpinner height="50px" width="50px"></SiteSpinner>
        <div className='mt-2 text-dark'>{constants.LOADING}</div>
      </div>
    );
  }

  if (!keycloak.authenticated && window.location.pathname !== "/accountVerify"  ) {
    return <Navigate to="/" />;
  }

  if (isLoggedIn !== 'undefined' && isLoggedIn !== null) {
    return isLoggedIn
      ? children
      : token != 'null' && token != 'undefined' && (
          <Navigate to="/customers/manage.html" push={true} />
        );
  } else {
    localStorage.setItem('authenticated', true);
  }
};

export default PrivateRoute;
