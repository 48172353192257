import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { Utils } from '../../../helpers';
import { useOrganizations } from '../../../store/OrganizationsStore';
import timezones from '../../../data/support/timezone.json';
import {
  getAllAreas,
  getAllDevicesData,
  getDeviceInformation,
} from '../../../store/reducers/AccountReducer';
import {
  clearLiveStreams,
  clearMQTTPeerConnections,
  clearRTCPeerConnections,
  clearRemoteStreams,
  clearWSSConnections,
  getPlatformInfo,
  setLiveCameraIds,
  setMetaData,
  setStartDate,
  removeRemoteStreams,
  removeMQTTPeerConnections,
  getCDNInfo,
  removeRemoteStreamsAudio,
  clearRemoteStreamsAudio,
} from '../../../store/reducers/StreamingReducer';
import {
  disconnectWithMQTT,
  mqttSubscribe,
  mqttUnsubscribe,
} from '../../../utils/connection/mqttConnection';
import useEventsStore from '../../../store/EventsStore';
import '../../cameras/VideoWall.scss';
import './Dashboard.scss';
import { devicesMQTTStore } from '../../../store/DevicesMQTTStore';
import { useLoggedInUserData } from '../../../store/LoggedInAccountStore';
import {
  getLatestEventsForDots,
  getMetaDataForEvents,
  resetEvents,
  setLatestEventsForDots,
  setMetaDataForEvents,
} from '../../../store/reducers/EventsReducer';
import MultiTimelineControls from '../components/timeline/MultiTimelineControls';
import { useCustomerOrgDevices } from '../../../store/CustomerOrgDevicesStore';
import {
  connectWithWebSocket,
  disconnectWithWebSocket,
} from './playback/wssConnection/wssConnection';
import useFiltersStore from '../../../store/FiltersStore';

const Dashboard = () => {
  const location = useLocation();
  let fullscreenRef = useRef(0);
  const { getAppliedTags } = useFiltersStore();

  const [showToast, setShowToast] = useState(false);
  const [userMsg, setUserMsg] = useState('');
  const [show, setShow] = useState(null);
  const [date, setDate] = useState(null);
  const [allStartDate, setAllStartDate] = useState([]);
  const cdnInfo = useSelector(getCDNInfo);
  const [internalEventFromSearch, setInternalEventFromSearch] = useState(false);
  const [timezone, setTimezone] = useState(moment.tz.guess());
  const [categoryDashboard, setCategoryDashboard] = useState([]);
  const [eventTimestamp, setEventTimestamp] = useState(null);
  const [listHeight, setListHeight] = useState('100%');
  const deviceId = '87bf6038-c153-4e2e-a594-6b771cfed785';
  const deviceDetails = useSelector(getDeviceInformation);
  const devicesDetails = useSelector(getAllDevicesData);
  const handle = useFullScreenHandle();
  const { setDeviceTimezone } = useEventsStore();
  const dispatch = useDispatch();
  const getCustomerOrgData = useOrganizations(
    (state) => state.getCustomerOrgData
  );
  const { getEventCVRMode } = useEventsStore();
  const eventCategory = useEventsStore((state) => state.eventCategory);
  const selectedEvent = useEventsStore((state) => state.selectedTimestamp);
  const eventFromSearch = useEventsStore((state) => state.eventFromSearch);
  const orgDetails = getCustomerOrgData()[0];
  const getPlatformDetails = useSelector(getPlatformInfo);
  const navigate = useNavigate();
  const allAreasStore = useSelector(getAllAreas);
  const { getState } = devicesMQTTStore;
  const state = getState();
  const loggedInUserData = useLoggedInUserData(
    (state) => state.loggedInUserData
  );

  const accountId = loggedInUserData.accountId;

  const eventsData = useSelector(getLatestEventsForDots);
  const deviceMetaData = useSelector(getMetaDataForEvents);

  const liveDevices = useCustomerOrgDevices((state) => state.liveViewDevices);
  const { setSelectedEventStore, setEventCVRMode, setEventFromSearch } =
    useEventsStore();

  useEffect(() => {
    const handleResize = () => {
      let fullscreenEl = fullscreenRef.current;

      if (!fullscreenEl) {
        return;
      }

      setListHeight(
        fullscreenEl.querySelector('.fullscreen')?.clientHeight + 'px'
      );
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      disconnectWithMQTT();
    };
  }, []);

  useEffect(() => {
    setInternalEventFromSearch(eventFromSearch);
  }, [eventFromSearch]);

  const getStartDateOFAllDevices = () => {
    const dateArray = liveDevices?.map(async (deviceId) => {
      const device = devicesDetails?.find(
        (device) => device.deviceId === deviceId
      );
      const url = device?.entitlement?.url;

      const date = await fetch(url, {
        withCredentials: true,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            const expiryDate = data?.term?.expiry;
            const durationInDays = data?.term?.data?.durationInDays;
            const startDate =
              expiryDate - (durationInDays + 1) * 24 * 60 * 60 * 1000;
            return startDate;
          }
        });
      return date;
    });
    return dateArray;
  };

  useEffect(() => {
    dispatch(setLiveCameraIds(liveDevices));
    if (liveDevices?.length) {
      const dateArray = liveDevices?.map(async (deviceId) => {
        const device = devicesDetails?.find(
          (device) => device.deviceId === deviceId
        );
        const url = device?.entitlement?.url;

        const date = await fetch(url, {
          withCredentials: true,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data) {
              const expiryDate = data?.term?.expiry;
              const durationInDays = data?.term?.data?.durationInDays;
              const startDate =
                expiryDate - (durationInDays + 1) * 24 * 60 * 60 * 1000;
              return startDate;
            }
          });
        return date;
      });
      Promise.all(dateArray).then((values) => {
        const sortValue = Math.min(...values);
        setAllStartDate(sortValue);
      });

      liveDevices?.forEach((liveDeviceId) => {
        connectWithWebSocket(
          getPlatformDetails,
          liveDeviceId,
          orgDetails?.orgId,
          accountId
        );
      });
    }

    return () => {
      dispatch(clearRTCPeerConnections());
      dispatch(clearLiveStreams());
      dispatch(clearMQTTPeerConnections());
      dispatch(clearRemoteStreams());
      dispatch(clearRemoteStreamsAudio());
      disconnectWithMQTT();
      setEventCVRMode('null');
      setEventFromSearch(false);
      liveDevices?.forEach((liveDeviceId) => {
        dispatch(removeRemoteStreams(liveDeviceId));
        dispatch(removeRemoteStreamsAudio(liveDeviceId));
        dispatch(removeMQTTPeerConnections(liveDeviceId));
        disconnectWithWebSocket(liveDeviceId);
      });
    };
  }, [liveDevices]);

  // useEffect(() => {
  //   // const sortValue = allStartDate.sort(function (x, y) {
  //   //   return x - y;
  //   // });
  //   const sortValue = Math.min(...allStartDate);
  //   console.log('startDate in all devices', sortValue);
  //   return null;
  // }, [allStartDate]);

  const getTopic = (areas) => {
    return areas?.map((area) => {
      return `d/rt-events/${area}`;
    });
  };

  useEffect(() => {
    if (liveDevices?.length) {
      dispatch(setLatestEventsForDots(null));
      dispatch(setMetaDataForEvents(null));
      dispatch(setMetaData(null));

      liveDevices.map((device) => {
        const subscribeForMetaData = {
          topic: `b/streams/${device}`,
          qos: 0,
        };
        // Subscribe to the app topic
        mqttSubscribe(subscribeForMetaData);
      });

      let subscribeForEvents = {};

      if (allAreasStore.length) {
        subscribeForEvents = {
          topic: getTopic(allAreasStore),
          qos: 0,
        };
        // Subscribe  to the device topic
        mqttSubscribe(subscribeForEvents);
      }

      return () => {
        mqttUnsubscribe(subscribeForEvents);
        liveDevices.map((device) => {
          const subscribeForMetaData = {
            topic: `b/streams/${device}`,
            qos: 0,
          };
          // Subscribe to the app topic
          mqttUnsubscribe(subscribeForMetaData);
        });

        //deleteRequest(deviceIndex?.areaId);
        dispatch(resetEvents);
      };
    }
  }, [liveDevices]);

  useEffect(() => {
    if (deviceDetails) {
      const url = deviceDetails?.entitlement?.url;

      fetch(url, {
        withCredentials: true,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            const expiryDate = data?.term?.expiry;
            const durationInDays = data?.term?.data?.durationInDays;
            const startDate =
              expiryDate - (durationInDays + 1) * 24 * 60 * 60 * 1000;
            setDate(startDate);
            dispatch(setStartDate(startDate));
          }
        });
    }
    setTimezone(deviceDetails?.properties?.timezone);
    const getLocationSelected = timezones?.data?.find(
      (zone) => zone.value === deviceDetails?.properties?.timezone
    );
    const zone = getLocationSelected?.location || moment.tz.guess();
    setDeviceTimezone(zone);
  }, [deviceDetails, deviceDetails?.properties?.timezone]);

  useEffect(() => {
    setCategoryDashboard(eventCategory);
  }, [eventCategory]);

  useEffect(() => {
    setEventTimestamp(selectedEvent);
  }, [selectedEvent]);

  const onCameraDetailsClick = (deviceId, time, cvrMode, isLive = false) => {
    if (!isLive) {
      setSelectedEventStore(Utils.getUnixDate(time) * 1000);
      setEventFromSearch(true);
      setEventCVRMode(cvrMode);
    }
    navigate(`/cameras/dashboard.html`, {
      state: {
        id: deviceId,
        cdnInfo: cdnInfo,
      },
    });
  };

  return (
    <div ref={fullscreenRef}>
      <FullScreen handle={handle}>
        <div className="timeline-controls-main-container">
          <MultiTimelineControls
            internalEventFromSearch={false}
            category={categoryDashboard}
            eventTimestamp={null}
            eventCVRMode={getEventCVRMode()}
            timezone={timezone}
            startDate={allStartDate}
            deviceId={deviceId}
            enterFullScreen={() => handle.enter()}
            exitFullScreen={() => handle.exit()}
            // handleClick={handleClick}
            customMetaData={deviceMetaData}
            eventDotsData={eventsData}
            allDeviceIds={liveDevices}
            onCameraDetailsClick={onCameraDetailsClick}
            appliedFilter={getAppliedTags()}
          />
        </div>
      </FullScreen>
    </div>
  );
};

export default Dashboard;
