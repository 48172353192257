import { useState } from 'react';
import StepOneUploadFile from './StepOneUploadFile';
import StepTwoCsvDataList from './StepTwoCsvDataList';
import StepThreeAtributes from './StepThreeAtributes';
import StepFourUniqeIdentifier from './StepFourUniqeIdentifier';
import StepFiveLocation from './StepFiveLocation';

const StepsPos = (props) => {
  const [step, setstep] = useState(1);
  const hideModal = () => props.hideModal();
  const nextStep = () => {
    setstep(step + 1);
  };
  const prevStep = () => {
    setstep(step - 1);
  };

  switch (step) {
    case 1:
      return (
        <>
          <StepOneUploadFile
            hideModal={() => hideModal()}
            nextStep={nextStep}
            step={step}
          />
        </>
      );
    case 2:
      return (
        <>
          <StepTwoCsvDataList
            hideModal={() => hideModal()}
            nextStep={nextStep}
            step={step}
            prevstep={prevStep}
          />
        </>
      );
    case 3:
      return (
        <>
          <StepThreeAtributes
            hideModal={() => hideModal()}
            nextStep={nextStep}
            step={step}
            prevstep={prevStep}
            isFromEdit={props?.isFromEdit}
          />
        </>
      );
      case 4:
      return (
        <>
          <StepFourUniqeIdentifier
            hideModal={() => hideModal()}
            nextStep={nextStep}
            step={step}
            prevstep={prevStep}
            isFromEdit={props?.isFromEdit}
          />
        </>
      );
      case 5:
        return (
          <>
            <StepFiveLocation
              hideModal={() => hideModal()}
              nextStep={nextStep}
              step={step}
              prevstep={prevStep}
              isFromEdit={props?.isFromEdit}
            />
          </>
        );
    default:
      return null;
  }
};
export default StepsPos;
