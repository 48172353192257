import { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { constants } from '../../../helpers';
import './stepspos.scss';

const AttributeSelector = ({updatePosStoreWithAttribute,posItem, getAtrributeList,posDataListFromCSV,isFromEdit = false}) => {
  const attribute = getAtrributeList;
  const [selectedAttribute, setSelectedAttribute] = useState(
    constants.POS_SELECT_ATTRIBUTE_LABEL
  );
  const [currentList, setCurrentList] = useState(
    []
  );

  useEffect(()=>{
    if(posItem?.label){
      setSelectedAttribute(posItem?.label)
    }
  },[])

  const onSelectAreaHandler = (evtKey) => {
    if (!evtKey) return;
    if(evtKey === constants.POS_SELECT_ATTRIBUTE_LABEL) return;
    let selectedAT = attribute.find((atItem) => atItem?.id === evtKey);
    const obj = {...posItem, ...selectedAT}
    const checkDuplicate = currentList?.find((item)=>item?.id === evtKey)
    updatePosStoreWithAttribute(obj)
    setSelectedAttribute(selectedAT.label);
  };

  useEffect(()=>{
    setCurrentList(posDataListFromCSV)
  },[posDataListFromCSV])

  const attributeContent =
    attribute?.length > 0 &&
    attribute?.map((atItems) => (
      <Dropdown.Item
        key={`area-${atItems.id}`}
        className={
          `area-selector-menu-item` +
          (selectedAttribute === atItems.label ? ' active' : '')
        }
        eventKey={atItems.id}
      >
        {`${atItems.label} ${atItems?.optional ? constants.POS_OPTIONAL_ATTRIBUTE : ''}`}
      </Dropdown.Item>
    ));

  return (
    <div className="selection-wrapper">
      <Dropdown className="area-selector" onSelect={onSelectAreaHandler}>
        <Dropdown.Toggle
          variant="outline-secondary"
          id="dropdown-area"
          className="area-selector-toggle"
        >
          <div className="area-selected-wrapper">
            <span className="area-selected-name text-selected">{selectedAttribute}</span>
          </div>
          <HiOutlineChevronDown size={20} />
        </Dropdown.Toggle>
        <Dropdown.Menu className="area-selector-menu">
         
          {attributeContent}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default AttributeSelector;
