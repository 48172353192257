import { Col, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BreadcrumbList, Header } from '../../components/common';
import { constants, roles } from '../../helpers';
import { useOrganizations } from '../../store/OrganizationsStore';
import {
  getSelectedOrganization,
  setAllDevicesData,
} from '../../store/reducers/AccountReducer';
import { useLoggedInUserData } from '../../store/LoggedInAccountStore';

import PageWrapper from '../PageWrapper';
function CameraList() {
  const dispatch = useDispatch();
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData
  );
  const loggedInUser = getLoggedInUserData();
  const getCustomerOrgData = useOrganizations(
    (state) => state.getCustomerOrgData
  );
  const orgDetails = getCustomerOrgData()[0];
  console.log('devices----', orgDetails?.devices);
  dispatch(setAllDevicesData([...orgDetails?.devices]));
  const breadList = [
    {
      url: '/customers/manage.html',
      title: loggedInUser.role !== roles.ROLE2VMS ?  
            constants.MANAGE_ORG_PAGE_TITLE : 
            constants.MANAGE_ORG_PAGE_ORG_TITLE,
    },
    {
      url: `/customers/dashboard.html?orgId=${orgDetails?.orgId}`,
      title: `${orgDetails?.orgName}`,
    },
    { url: '/devices/', title: constants.DEVICES_PAGE_TITLE },
  ];
  return (
    <div className="App">
      <Header breadcrumbData={breadList} />
      <div className="App">
        <PageWrapper>
          <div className="page-header mb-3">
            <Container className="container-top-offset" lg={8}>
              <Col md={6} lg={6} xl={6} xs={12}>
                <BreadcrumbList list={breadList} />
              </Col>
            </Container>
          </div>
          <div>
            {Array.isArray(orgDetails?.devices) &&
              orgDetails?.devices.length > 0 && (
                <>
                  <div>
                    {orgDetails?.devices.map((device, id) => (
                      <div key={id}>
                        <Link
                          to={`/cameras/dashboard.html?id=${device.deviceId}`}
                        >
                          {device.deviceName}
                        </Link>
                      </div>
                    ))}
                  </div>
                </>
              )}
          </div>
        </PageWrapper>
      </div>
    </div>
  );
}
export default CameraList;
