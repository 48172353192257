import React, { useState, useRef } from 'react';
import { Calendar } from 'react-multi-date-picker';
import moment from 'moment';
import { PrimaryButton } from '.';
import DatePicker from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import { LuClock5 } from 'react-icons/lu';
import { constants } from '../../helpers/';

const DateTimePicker = ({ date, minDate, time, timeZone, onSubmit }) => {
  let [selectedDate, setSelectedDate] = useState(timeZone ? moment
    .tz(moment(date, 'YYYY/MM/DD hh:mm:ss A'), timeZone)
    .format('YYYY/MM/DD hh:mm:ss A') : date);
  let [selectedTime, setSelectedTime] = useState(time);
  const timePickerRef = useRef();

  const onApply = () => {
    const time = new Date(selectedTime);
    const originalSelectedDate = new Date(selectedDate);
    originalSelectedDate.setHours(time.getHours()); // set selected hours in selected date
    originalSelectedDate.setMinutes(time.getMinutes()); // set selected minutes in selected date
    originalSelectedDate.setSeconds(time.getSeconds()); // set selected seconds in selected date

    /*
      1. parse date and time according to selected timezone (i.e device timezone) without changing selected date and time.
      2. get date as per the current timezone 
    */
    const convertedDate = new Date(moment(originalSelectedDate).parseZone().tz(timeZone, true).toISOString(true));
    onSubmit({ selectedDate: convertedDate, selectedTime: time.getTime(), filterdate: convertedDate });
  };

  const onClickTimePicker = () => {
    if (!timePickerRef.current.isOpen) {
      timePickerRef.current.openCalendar();
    } else {
      timePickerRef.current.closeCalendar();
    }
  };

  const onTimeChange = (e) => {
    setSelectedTime(e ? e : new Date().setHours(0, 0, 0));
  };
  
  var date = timeZone ? moment
    .tz(moment(new Date(), 'YYYY/MM/DD hh:mm:ss A'), timeZone)
    .format('YYYY/MM/DD hh:mm:ss A') : new Date();

  return (
    <div className="date-time-picker">
      <div className='date-time-picker-main'>
        <Calendar
          value={selectedDate}
          onChange={setSelectedDate}
          minDate={
            minDate
              ? new Date(minDate)
              : new Date(moment(new Date()).subtract({ days: 90 }))
          }
          monthYearSeparator=" "
          maxDate={date}
          shadow={false}
          highlightToday={false}
          weekStartDayIndex={1}
          headerOrder={['MONTH_YEAR', 'LEFT_BUTTON', 'RIGHT_BUTTON']}
          className="custom-calendar timeline-calendar"
        />
      </div>
      <div className="select-time-label">{constants.CAMERA_CALENDER_SELECT_TIME_LABEL}</div>
      <div className="time-container">
        <DatePicker
          value={selectedTime}
          onChange={(e, { input, isTyping }) => {
            // console.log(input.value, isTyping)
            // const time = input.value.split(' ')?.[0];
            // const ampm = input.value.split(' ')?.[1];

            // const string = time.split(':');
            // const numbers = string.map(Number);
            // const [hr, min, sec] = numbers;
            // if(ampm.length > 2 || ampm !== 'AM' || ampm !== 'PM'){
            //   return false;
            // }
            // if(hr > 12 || hr.toString().length > 2 || min> 60 || min.toString().length > 2 || sec> 60 || sec.toString().length > 2){
            //   return false;
            // }
            // if (input.value && numbers.some((number) => isNaN(number))) {
            //   return false; //in case user enter something other than digits
            // }
            // console.log(e)
            onTimeChange(e)
          }}
          disableDayPicker
          format="hh:mm:ss A"
          plugins={[<TimePicker />]}
          className="custom-timepicker"
          ref={timePickerRef}
          multiple={false}
          editable={false}
        />
        <LuClock5
          size={16}
          color={'black'}
          onClick={() => onClickTimePicker()}
        />
      </div>

      <div>
        <PrimaryButton
          className="btn btn-primary mt-4"
          type="submit"
          width="100%"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          onClick={onApply}
          disabled={!selectedDate || !selectedTime}
        >
          {constants.CAMERA_APPLY_DATE_FILTER}
        </PrimaryButton>
      </div>
    </div>
  );
};
export default DateTimePicker;
