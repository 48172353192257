import { useEffect, useRef, useState } from 'react';
import { Alert, Col, Form, Row } from 'react-bootstrap';
import {
  RangeInputField,
  SelectField,
  ToggleInput,
} from '../../../../components/forms';
import {
  PrimaryButton,
  SiteModal,
  TextBlock,
} from '../../../../components/common';
import { Formik, Field } from 'formik';
import { constants, Utils } from '../../../../helpers';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import publishDeviceSetting from '../shared/PublishDeviceSettings';
import './DeviceSettingsBlock.scss';

const DeviceSettingsBlock = ({
  orgId,
  orgName,
  deviceId,
  deviceName,
  saveDeviceSettingsFlag,
  saveDeviceSettingsCallback,
  deviceInfos,
  accountId,
  updateStore,
  devicePublishId,
  uuid,
  storeUpdateFromHalfway
}) => {
  const formRef = useRef();
  const [variant, setVariant] = useState('danger');
  const [userMsg, setUserMsg] = useState('');
  const [showMsg, setShowMsg] = useState(true);
  const [recordCodecList, setRecordCodecList] = useState([
    { label: 'H264', value: 1 },
  ]);
  const [defaultSelected, setDefaultSelected] = useState(1);
  const [showDeviceRestartModal, setShowDeviceRestartModal] = useState(false);
  const capabilityList = deviceInfos?.capDetails?.capabilities;
  const deviceState = deviceInfos?.deviceStatus;
  const connectionState = deviceInfos?.connectionStatus;
  const curDeviceStatus = Utils.getDeviceStatus(deviceState, connectionState);
  const resourceList = Utils.getDeviceSettingResource(1);
  const capFlipRotate = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[0]
  );
  const deviceDegreeRotation = Utils.getDropDownPair(
    capFlipRotate?.['rotation-angle']
  );
  const capWdrLevel = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[1]
  );
  const capWdrEnable = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[1]
  );
  const capVideoProfile = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[2]
  );
  const deviceResolution = Utils.getDropDownPair(capVideoProfile?.resolution);
  const capWisestream = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[3]
  );
  const capIrMode = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[4]
  );
  const capEnhacement = Utils.getCurrentTabProperty(
    capabilityList,
    resourceList[5]
  );

  const publishPayload = {
    deviceId: deviceId,
    devicePublishId: devicePublishId,
    uuid: uuid,
    accountId: accountId,
    hubId: deviceInfos.gatewayId,
  };

  useEffect(() => {
    if (formRef?.current && saveDeviceSettingsFlag) {
      formRef?.current?.handleSubmit();
    }
  }, [saveDeviceSettingsFlag]);
  

  const getCheckPropertyStatus = (value, key) => {
    return {
      [key]: value
        ? constants.PROPERTY_STATUS_TRUE
        : constants.PROPERTY_STATUS_FALSE,
    };
  };

  return (
    <div
      className={`text-start device-settings-block ${
        curDeviceStatus !== constants.DEVICES_RETURN_ONLINE_STATUS
          ? ' editable-off-offline'
          : ''
      }`}
    >
      <div className="tab-title">
        <label>{constants.DEVICES_TAB_VIDEO_SETTINGS}</label>
      </div>

      {showMsg &&
        curDeviceStatus !== constants.DEVICES_RETURN_ONLINE_STATUS && (
          <Alert
            variant={variant}
            onClose={() => setShowMsg(false)}
            dismissible
          >
            <Row>
              <Col md={1}>
                <HiOutlineExclamationCircle
                  size={15}
                  stroke={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue('--error_24')}
                />
              </Col>
              <Col>
                {userMsg ? (
                  <p>{userMsg}</p>
                ) : (
                  <>
                    <Alert.Heading>
                      {constants.UNABLE_TO_SAVE_DEVICE_SETTINGS_HEADING}
                    </Alert.Heading>
                    <p>{constants.UNABLE_TO_SAVE_DEVICE_SETTINGS_MESSAGE}</p>
                  </>
                )}
              </Col>
            </Row>
          </Alert>
        )}

      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{
          deviceResolution: deviceInfos?.properties?.['resolution']
            ? deviceInfos?.properties?.['resolution']
            : '',
          flip:
            deviceInfos?.properties?.['flip-enable'] ===
            constants.PROPERTY_STATUS_TRUE,
          mirror:
            deviceInfos?.properties?.['mirror-enable'] ===
            constants.PROPERTY_STATUS_TRUE,
          wdrLevel:
            deviceInfos?.properties?.['wdr-level']?.toLowerCase() ===
            constants.MEDIUM_VALUE_LABEL.toLowerCase()
              ? 2
              : deviceInfos?.properties?.['wdr-level']?.toLowerCase() ===
                constants.MAX_VALUE_LABEL.toLowerCase()
              ? 3
              : 1,
          deviceDegrees: deviceInfos?.properties?.['rotation-angle']
            ? deviceInfos?.properties?.['rotation-angle']
            : '',
          wdrEnable :deviceInfos?.properties?.['wdr-enable'] ===  constants.PROPERTY_STATUS_TRUE,
          wisestream:
            deviceInfos?.properties?.['wisestream-enable'] ===
            constants.PROPERTY_STATUS_TRUE,
          dynamicgov:
            deviceInfos?.properties?.['dynamic-gov-enable'] ===
            constants.PROPERTY_STATUS_TRUE,
          dynamicfps:
            deviceInfos?.properties?.['dynamic-fps-enable'] ===
            constants.PROPERTY_STATUS_TRUE,
          minimumfps: Utils.getPropertyValueIfExist(
            deviceInfos?.properties?.['minimum-dynamic-fps'],
            false
          ),
          brightness: Utils.getPropertyValueIfExist(
            deviceInfos?.properties?.['brightness-level'],
            false
          ),
          sharpness: Utils.getPropertyValueIfExist(
            deviceInfos?.properties?.['sharpness-level'],
            false
          ),
          contrast: Utils.getPropertyValueIfExist(
            deviceInfos?.properties?.['contrast-level'],
            false
          ),
          autoirmode:
            deviceInfos?.properties?.['ir-mode-enable'] ===
            constants.PROPERTY_STATUS_TRUE,
        }}
        onSubmit={(values, { setSubmitting }) => {
          // TODO : Handle form submit use this later
          setSubmitting(true);
          setVariant('danger');
          setUserMsg('');
          setSubmitting(false);
          saveDeviceSettingsCallback(false);
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <Form className="form" onSubmit={handleSubmit}>
            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'encoding-type',
              capVideoProfile
            ) &&
              capVideoProfile?.['encoding-type']?.length > 0 && (
                <div className="recording-video-codec">
                  <div className="label-recording">
                    {constants.DEVICE_VIDEO_CODEC}
                  </div>
                  {recordCodecList.length > 0 &&
                    recordCodecList.map((record) => {
                      return (
                        Utils.getCodecAvailability(
                          record.label,
                          capVideoProfile?.['encoding-type']
                        ) && (
                          <div
                            key={record.value}
                            className={`codec-selection ${
                              record.value !== 1 ? ' selection-disable' : ''
                            }`}
                          >
                            <div className="label-codec">{record.label}</div>
                            <Field
                              type="radio"
                              name="codecId"
                              value={record.value}
                              checked={defaultSelected === record.value}
                              onClick={(event) => {
                                setDefaultSelected(1);
                              }}
                            />
                          </div>
                        )
                      );
                    })}
                </div>
              )}
            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'resolution',
              capVideoProfile
            ) && (
              <Row className="settings-block">
                <Col>
                  {/* TODO: The options label is going to change with the API response data once API is available */}
                  <SelectField
                    name="deviceResolution"
                    options={deviceResolution}
                    label=""
                    defaultOptionLabel={
                      constants.DEVICES_TAB_VIDEO_SETTINGS_RESOLUTION_PLACEHOLDER
                    }
                    onChange={(evebr) => {
                      let selectedVal = evebr?.target?.value;
                      setFieldValue('deviceResolution', selectedVal);
                      const resolutionObj = { resolution: selectedVal };
                      updateStore(resolutionObj);
                      publishDeviceSetting(
                        resolutionObj,
                        resourceList[2],
                        publishPayload
                      );
                    }}
                    placeholderOptionClass='d-none'
                  />
                </Col>
              </Row>
            )}
            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'flip-enable',
              capFlipRotate
            ) && (
              <Row className="settings-block">
                <Col>
                  <ToggleInput
                    label={constants.DEVICES_TAB_VIDEO_SETTINGS_FLIP_LABEL}
                    name="flip"
                    value={values.flip}
                    changeHandler={() => {
                      setFieldValue('flip', !values.flip);
                      const status = getCheckPropertyStatus(
                        !values.flip,
                        'flip-enable'
                      );
                      updateStore(status);
                      publishDeviceSetting(
                        status,
                        resourceList[0],
                        publishPayload
                      );
                    }}
                  />
                </Col>
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'mirror-enable',
              capFlipRotate
            ) && (
              <Row className="settings-block">
                <Col>
                  <ToggleInput
                    label={constants.DEVICES_TAB_VIDEO_SETTINGS_MIRROR_LABEL}
                    name="mirror"
                    value={values.mirror}
                    changeHandler={() => {
                      setFieldValue('mirror', !values.mirror);
                      const status = getCheckPropertyStatus(
                        !values.mirror,
                        'mirror-enable'
                      );
                      updateStore(status);
                      publishDeviceSetting(
                        status,
                        resourceList[0],
                        publishPayload
                      );
                    }}
                  />
                </Col>
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'rotation-angle',
              capFlipRotate
            ) && (
              <Row className="settings-block">
                <Col>
                  {/* TODO: The options label is going to change with the API response data once API is available */}
                  <SelectField
                    name="deviceDegrees"
                    options={deviceDegreeRotation}
                    label=""
                    defaultOptionLabel={
                      constants.DEVICES_TAB_VIDEO_SETTINGS_DEGREES_PLACEHOLDER
                    }
                    value={values.deviceDegrees}
                    onChange={(evebr) => {
                      let selectedVal = evebr?.target?.value;
                      setFieldValue('deviceDegrees', selectedVal);
                      const property = { 'rotation-angle': selectedVal };
                      updateStore(property);
                      publishDeviceSetting(
                        property,
                        resourceList[0],
                        publishPayload
                      );
                      // setShowDeviceRestartModal(true);
                    }}
                    placeholderOptionClass='d-none'
                  />
                </Col>
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'wdr-enable',
              capWdrEnable
            ) && (
              <Row className="settings-block wdr-view">
                <Col>
                <ToggleInput
                    label={
                      constants.DEVICES_TAB_VIDEO_SETTINGS_WDR_LEVEL_LABEL
                    }
                    classes={values.wdrEnable ?'mixed-lower' :''}
                    name="wdrEnable"
                    value={values.wdrEnable}
                    changeHandler={() => {
                      setFieldValue('wdrEnable', !values.wdrEnable);
                      const status = getCheckPropertyStatus(
                        !values.wdrEnable,
                        'wdr-enable'
                      );
                      updateStore(status);
                      publishDeviceSetting(
                        status,
                        resourceList[1],
                        publishPayload
                      );
                    }}
                  />
                  {
                    values.wdrEnable && <RangeInputField
                    classes={'mixed'}
                    label={''}
                    thumbLabelHide={true}
                    fieldName="wdrLevel"
                    minValueLabel={constants.MIN_VALUE_LABEL}
                    maxValueLabel={constants.MAX_VALUE_LABEL}
                    curValueLabel={constants.MEDIUM_VALUE_LABEL}
                    maxValue={3}
                    currentValue={values.wdrLevel}
                    changeHandler={(value) => {
                      setFieldValue('wdrLevel', value);
                      const property = {
                        'wdr-level':
                          value === 1
                            ? constants.MIN_VALUE_LABEL.toLowerCase()
                            : value === 2
                            ? constants.MEDIUM_VALUE_LABEL.toLowerCase()
                            : constants.MAX_VALUE_LABEL.toLowerCase(),
                      };
                      updateStore(property);
                      publishDeviceSetting(
                        property,
                        resourceList[1],
                        publishPayload
                      );
                    }}
                  />
                  }
                  
                </Col>
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'wisestream-enable',
              capWisestream
            ) && (
              <Row className="settings-block">
                <Col>
                  <ToggleInput
                    label={
                      constants.DEVICES_TAB_VIDEO_SETTINGS_WISE_STREAM_LABEL
                    }
                    name="wisestream"
                    value={values.wisestream}
                    changeHandler={() => {
                      setFieldValue('wisestream', !values.wisestream);
                      const status = getCheckPropertyStatus(
                        !values.wisestream,
                        'wisestream-enable'
                      );
                      updateStore(status);
                      publishDeviceSetting(
                        status,
                        resourceList[3],
                        publishPayload
                      );
                    }}
                  />
                </Col>
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'dynamic-gov-enable',
              capVideoProfile
            ) && (
              <Row className="settings-block">
                <Col>
                  <ToggleInput
                    label={
                      constants.DEVICES_TAB_VIDEO_SETTINGS_DYNAMIC_GOV_LABEL
                    }
                    name="dynamicgov"
                    value={values.dynamicgov}
                    changeHandler={() => {
                      setFieldValue('dynamicgov', !values.dynamicgov);
                      const status = getCheckPropertyStatus(
                        !values.dynamicgov,
                        'dynamic-gov-enable'
                      );
                      updateStore(status);
                      publishDeviceSetting(
                        status,
                        resourceList[2],
                        publishPayload
                      );
                    }}
                  />
                </Col>
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'dynamic-fps-enable',
              capVideoProfile
            ) && (
              <Row
                className={`settings-block ${
                  values.dynamicfps ? 'grouped-settings-block' : ''
                }`}
              >
                <Col md={12}>
                  <ToggleInput
                    label={
                      constants.DEVICES_TAB_VIDEO_SETTINGS_DYNAMIC_FPS_LABEL
                    }
                    name="dynamicfps"
                    value={values.dynamicfps}
                    changeHandler={() => {
                      setFieldValue('dynamicfps', !values.dynamicfps);
                      const status = getCheckPropertyStatus(
                        !values.dynamicfps,
                        'dynamic-fps-enable'
                      );
                      updateStore(status);
                      publishDeviceSetting(
                        status,
                        resourceList[2],
                        publishPayload
                      );
                    }}
                  />
                </Col>
                {values.dynamicfps && (
                  <Col md={12}>
                    <RangeInputField
                      label={
                        constants.DEVICES_TAB_VIDEO_SETTINGS_MINIMUM_FPS_LABEL
                      }
                      fieldName="minimumfps"
                      maxValue={Utils.getPropertyValueIfExist(
                        capVideoProfile?.['minimum-dynamic-fps']?.max,
                        true
                      )}
                      minValue={Utils.getPropertyValueIfExist(
                        capVideoProfile?.['minimum-dynamic-fps']?.min,
                        false
                      )}
                      currentValue={values.minimumfps}
                      changeHandler={(value) => {
                        setFieldValue('minimumfps', value);
                        const property = {
                          'minimum-dynamic-fps': value.toString(),
                        };
                        updateStore(property);
                        publishDeviceSetting(
                          property,
                          resourceList[2],
                          publishPayload
                        );
                      }}
                    />
                  </Col>
                )}
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'brightness-level',
              capEnhacement
            ) && (
              <Row className="settings-block">
                <Col>
                  <RangeInputField
                    label={
                      constants.DEVICES_TAB_VIDEO_SETTINGS_BRIGHTNESS_LABEL
                    }
                    maxValue={Utils.getPropertyValueIfExist(
                      capEnhacement?.['brightness-level']?.max,
                      true
                    )}
                    minValue={Utils.getPropertyValueIfExist(
                      capEnhacement?.['brightness-level']?.min,
                      false
                    )}
                    fieldName="brightness"
                    currentValue={values.brightness}
                    changeHandler={(value) => {
                      setFieldValue('brightness', value);
                      const property = {
                        'brightness-level': value.toString(),
                      };
                      updateStore(property);
                      publishDeviceSetting(
                        property,
                        resourceList[5],
                        publishPayload
                      );
                    }}
                  />
                </Col>
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'sharpness-level',
              capEnhacement
            ) && (
              <Row className="settings-block">
                <Col>
                  <RangeInputField
                    label={constants.DEVICES_TAB_VIDEO_SETTINGS_SHARPNESS_LABEL}
                    fieldName="sharpness"
                    maxValue={Utils.getPropertyValueIfExist(
                      capEnhacement?.['sharpness-level']?.max,
                      true
                    )}
                    minValue={Utils.getPropertyValueIfExist(
                      capEnhacement?.['sharpness-level']?.min,
                      false
                    )}
                    currentValue={values.sharpness}
                    changeHandler={(value) => {
                      setFieldValue('sharpness', value);
                      const property = {
                        'sharpness-level': value.toString(),
                      };
                      updateStore(property);
                      publishDeviceSetting(
                        property,
                        resourceList[5],
                        publishPayload
                      );
                    }}
                  />
                </Col>
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'contrast-level',
              capEnhacement
            ) && (
              <Row className="settings-block">
                <Col>
                  <RangeInputField
                    label={constants.DEVICES_TAB_VIDEO_SETTINGS_CONTRAST_LABEL}
                    fieldName="contrast"
                    maxValue={Utils.getPropertyValueIfExist(
                      capEnhacement?.['contrast-level']?.max,
                      true
                    )}
                    minValue={Utils.getPropertyValueIfExist(
                      capEnhacement?.['contrast-level']?.min,
                      false
                    )}
                    currentValue={values.contrast}
                    changeHandler={(value) => {
                      setFieldValue('contrast', value);
                      const property = {
                        'contrast-level': value.toString(),
                      };
                      updateStore(property);
                      publishDeviceSetting(
                        property,
                        resourceList[5],
                        publishPayload
                      );
                    }}
                  />
                </Col>
              </Row>
            )}

            {Utils.getPropertyShowStatus(
              curDeviceStatus,
              'ir-mode-enable',
              capIrMode
            ) && (
              <Row className="settings-block">
                <Col>
                  <ToggleInput
                    label={
                      constants.DEVICES_TAB_VIDEO_SETTINGS_AUTO_IR_MODE_LABEL
                    }
                    name="autoirmode"
                    value={values.autoirmode}
                    changeHandler={() => {
                      setFieldValue('autoirmode', !values.autoirmode);
                      const status = getCheckPropertyStatus(
                        !values.autoirmode,
                        'ir-mode-enable'
                      );
                      updateStore(status);
                      publishDeviceSetting(
                        status,
                        resourceList[4],
                        publishPayload
                      );
                    }}
                  />
                </Col>
              </Row>
            )}
          </Form>
        )}
      </Formik>

      <SiteModal
        modalTitle={constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_TITLE}
        showModal={showDeviceRestartModal}
        hideModal={() => {
          setShowDeviceRestartModal(false);
        }}
        classes="device-settings-restart-required"
      >
        <TextBlock
          className="mb-4"
          fontWeight="400"
          fontSize="1rem"
          lineHeight="24px"
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--greyscale_40'
          )}
        >
          {constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CONTENT}
        </TextBlock>
        <PrimaryButton
          className="btn btn-primary mb-4"
          type="button"
          width="100%"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
        >
          {
            constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CONFIRM_BUTTON_TITLE
          }
        </PrimaryButton>
        <PrimaryButton
          className="btn btn-primary-outline"
          type="button"
          width="100%"
          borderWidth="1.5px"
          hoverBorderWidth="1.5px"
          hoverBorderColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_40')}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            '--primary_40'
          )}
          hoverColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--brand_white')}
          hoverBackgroundColor={getComputedStyle(
            document.documentElement
          ).getPropertyValue('--primary_40')}
          backgroundColor="transparent"
          height="56px"
          fontSize="1.125rem"
          lineHeight="24px"
          onClick={() => {
            setShowDeviceRestartModal(false);
          }}
        >
          {
            constants.DEVICES_TAB_VIDEO_SETTINGS_RESTART_MODAL_CANCEL_BUTTON_TITLE
          }
        </PrimaryButton>
      </SiteModal>
    </div>
  );
};

export default DeviceSettingsBlock;
