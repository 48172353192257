import { Col, Row, Container } from 'react-bootstrap';
import { useState, useMemo, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import PageWrapper from '../../PageWrapper';
import { Radio, RadioGroup } from '@mui/material';
import {
  Header,
  BreadcrumbList,
  PrimaryButton,
  SiteModal,
  SiteSpinner,
} from '../../../components/common';
import { constants, Utils, roles, DeviceTypeEnum } from '../../../helpers/';
import { useLoggedInUserData } from '../../../store/LoggedInAccountStore';
import { getSelectedOrganization } from '../../../store/reducers/AccountReducer';
import { getLocationsData } from '../../../store/reducers/AccountReducer';
import { usePoliciesStore } from '../../../store/policiesStore';
import { useSelector } from 'react-redux';
import { IoIosSearch } from 'react-icons/io';
import { FaCircle } from 'react-icons/fa';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { dotsVertical } from '../../../assets/images';
import { FiVideoOff } from 'react-icons/fi';
import { RxCross2 } from 'react-icons/rx';
import { RxCrossCircled } from 'react-icons/rx';
import { FaArrowDown } from 'react-icons/fa6';
import axios from 'axios';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './DiscoveredDevices.scss';
import DiscoveredDevicesTable from './DiscoveredDevicesTable';
import DiscoveredDevicesSetup from './DiscoveredDevicesSetup';
import DiscoveredDevciesDelete from './DiscoveredDevciesDelete';
import DiscoveredDevicesReclaim from './DiscoveredDevicesReclaim';
import { Tooltip, tooltipClasses } from '@mui/material';

const DiscoveredDevices = () => {
  const getLoggedInUserData = useLoggedInUserData(
    (state) => state.getLoggedInUserData
  );
  const loggedInUser = getLoggedInUserData();
  const orgDetails = useSelector(getSelectedOrganization);
  const [devices, setDevices] = useState(null);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [showDeviceSetupModal, setShowDeviceSetupModal] = useState(false);
  const [showDeviceDeleteModal, setShowDeviceDeleteModal] = useState(false);
  const [showDeviceReclaimModal, setShowDeviceReclaimModal] = useState(false);
  const [renameId, setRenameId] = useState(0);
  const [requestedDeleteDevices, setRequestedDeleteDevices] = useState(null);
  const [requestedReclaimDevices, setRequestedReclaimDevices] = useState(null);
  const [inProcessFields, setInProcessFields] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedArea, setSelectedArea] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showLocationErrorMessage, setShowLocationErrorMessage] =
    useState(false);
  const [showSetupErrorMessage, setShowSetupErrorMessage] = useState(false);
  const [pageNo, setPageNo] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [sortDetails, setSortDetails] = useState({
    orderBy: 'deviceName',
    isAsc: true,
  });
  const [pageDetails, setPageDetails] = useState({
    page: 0,
  });
  const [isLoadMorecalled, setIsLoadMorecalled] = useState(false);
  const navigate = useNavigate();
  const locationsData = useSelector(getLocationsData);
  const [searchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const getCustomerOrgPolicies = usePoliciesStore(
    (state) => state.getCustomerOrgPolicies
  );

  const breadList = [
    {
      url: '/customers/manage.html',
      title:
        loggedInUser.role !== roles.ROLE2VMS
          ? constants.MANAGE_ORG_PAGE_TITLE
          : constants.MANAGE_ORG_PAGE_ORG_TITLE,
    },
    {
      url: `/customers/dashboard.html?orgId=${orgDetails?.orgId}`,
      title: `${orgDetails?.orgName}`,
    },
    {
      url: `/devices/listing.html?orgId=${orgDetails?.orgId}`,
      title: constants.DEVICES_PAGE_TITLE,
    },
    {
      url: `/devices/discovered-devices.html?orgId=${orgDetails?.orgId}`,
      title: constants.DISCOVERED_DEVICES_SETUP_BUTTON,
    },
  ];

  useEffect(() => {
    if (devices) {
      getDiscoveredDevciesWithLoader();
    }
  }, [devices, searchText, JSON.stringify(sortDetails)]);

  useEffect(() => {
    setIsLoading(true);
    fetchDeviceData();
  }, []);

  useEffect(() => {
    if (pageNo) {
      setIsLoadMorecalled(true);
      getDiscoveredDevices(false, pageNo);
    }
  }, [pageNo]);

  const fetchDeviceData = async () => {
    await axios
      .get(`device/orgs/${orgId}/devices`, Utils.requestHeader())
      .then((response) => {
        const resultData = response.data;
        if (resultData) {
          const responseMeta = resultData.meta;
          const responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            setDevices(responseData);
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        // Todo: Delete later
        console.log('error comes in catch', error);
        setIsLoading(false);
      });
  };

  const getDiscoveredDevciesWithLoader = () => {
    setIsLoading(true);
    getDiscoveredDevices(true);
  };

  const getDiscoveredDevices = async (IsResetPageNo, nextPageNo) => {
    setShowLocationErrorMessage(false);
    setShowSetupErrorMessage(false);
    const pNo = IsResetPageNo ? 0 : nextPageNo;
    await axios
      .get(
        `/device/orgs/${orgId}/devices/discover?search=${searchText}&page=${pNo}&size=${10}&orderBy=${
          sortDetails.orderBy
        }&isAsc=${sortDetails.isAsc}`,
        Utils.requestHeader()
      )
      .then((response) => {
        const resultData = response.data;
        if (resultData) {
          const responseMeta = resultData.meta;
          const responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            setPageDetails(responseData);
            setPageNo(responseData?.pageNumber);
            ProcessDiscoveredDevices(responseData?.devices, IsResetPageNo);
            setSelectedDevices([]);
            if (!responseData?.devices?.length) {
              setIsLoading(false);
              setFilteredDevices([]);
            }
          } else {
            setInProcessFields([]);
            setIsLoading(false);
          }
        } else {
          setInProcessFields([]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        // Todo: Delete later
        console.log('error comes in catch', error);
        setInProcessFields([]);
        setIsLoading(false);
        setIsLoadMorecalled(false);
      });
  };

  const ProcessDiscoveredDevices = (responseData, IsResetPageNo) => {
    responseData?.forEach((device) => {
      const deviceCopy = devices?.find(
        (d) =>
          d.serialNo === device.serialNo && d.macAddress === device.macAddress
      );
      if (deviceCopy) {
        device.status = 'RECLAIM';
        if (!device.locationId) {
          device.locationId = deviceCopy.locationId;
          device.areaId = deviceCopy.areaId;
        }
      } else {
        device.status = 'ENTER';
      }
      if (device.locationId) {
        const location = locationsData.find(
          (loc) => loc.locationId === device.locationId
        );
        if (location) {
          device.timezone = location.timezone;
          device.areas = location?.areas;
          if (deviceCopy) {
            device.locationName = location?.locationName;
            if (device?.areaId) {
              const areaCopy = location?.areas?.find(
                (area) => area.areaId === device?.areaId
              );
              device.areaName = areaCopy?.areaName;
            }
          }
        }
      }
      device.requestedApps = ['vms'];
    });
    if (IsResetPageNo) {
      setFilteredDevices(responseData);
    } else {
      setFilteredDevices([...filteredDevices, ...responseData]);
    }
    setIsLoading(false);
    setIsLoadMorecalled(false);
  };

  const deleteDiscoveredDevices = async () => {
    const requestDeviceData = requestedDeleteDevices
      ? requestedDeleteDevices
      : selectedDevices;
    await axios
      .delete(`device/orgs/${orgId}/devices/discover`, {
        headers: {
          'X-Correlation-ID': Utils.generateUUID(),
        },
        data: { deviceIds: requestDeviceData },
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData) {
          const responseMeta = resultData.meta;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            getDiscoveredDevciesWithLoader();
            setInProcessFields([]);
            setRenameId(0);
            setRequestedDeleteDevices(null);
            setShowDeviceDeleteModal(false);
            setSelectedDevices([]);
          } else {
            setInProcessFields([]);
            setIsLoading(false);
            setRenameId(0);
            setShowDeviceDeleteModal(false);
            setRequestedDeleteDevices(null);
          }
        } else {
          setInProcessFields([]);
          setIsLoading(false);
          setRenameId(0);
          setShowDeviceDeleteModal(false);
          setRequestedDeleteDevices(null);
        }
      })
      .catch((error) => {
        // Todo: Delete later
        console.log('error comes in catch', error);
        setInProcessFields([]);
        setIsLoading(false);
        setRenameId(0);
      });
  };

  const updateDiscoveredDevices = async (obj, isFromBulk) => {
    setShowLocationErrorMessage(false);
    await axios
      .put(`device/orgs/${orgId}/devices/discover`, obj, Utils.requestHeader())
      .then((response) => {
        const resultData = response.data;
        if (resultData) {
          const responseMeta = resultData.meta;
          const responseData = resultData.data;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            setRenameId(0);
            updateLocationAndArea(responseData, isFromBulk);
          } else {
            setRenameId(0);
            setInProcessFields([]);
            setShowLocationErrorMessage(true);
          }
        } else {
          setRenameId(0);
          setInProcessFields([]);
          setShowLocationErrorMessage(true);
        }
      })
      .catch((error) => {
        // Todo: Delete later
        console.log('error comes in catch', error);
        setInProcessFields([]);
        setRenameId(0);
        setShowLocationErrorMessage(true);
      });
  };

  const updateLocationAndArea = (response, isFromBulk) => {
    const addObject = [];
    response.forEach((responseDevice) => {
      filteredDevices.forEach((device) => {
        if (responseDevice.deviceId === device.deviceId) {
          if (responseDevice.locationId) {
            const location = locationsData.find(
              (loc) => loc.locationId === responseDevice.locationId
            );
            device.locationId = location.locationId;
            device.locationName = location.locationName;
            device.timezone = location.timezone;
            device.areas = location?.areas;
            if (responseDevice.areaId) {
              const area = location?.areas.find(
                (area) => area.areaId === responseDevice.areaId
              );
              if (area) {
                device.areaId = area.areaId;
                device.areaName = area.areaName;
              }
            } else {
              device.areaId = null;
              device.areaName = null;
            }
          }
          if (responseDevice.deviceName) {
            device.deviceName = responseDevice.deviceName;
          }
          addObject.push(device);
        }
      });
    });
    setFilteredDevices(filteredDevices);
    if (isFromBulk) {
      addDiscoveredDevices(addObject, isFromBulk);
    } else {
      setInProcessFields([]);
      setSelectedDevices([]);
    }
  };

  const addDiscoveredDevices = async (addRequestedDevices, isFromBulk) => {
    setShowSetupErrorMessage(false);
    if (!isFromBulk) {
      const list = [];
      addRequestedDevices?.forEach((device) => {
        list.push(`${device.deviceId}-options`);
      });
      setInProcessFields(list);
    }
    setShowDeviceReclaimModal(false);
    setShowDeviceSetupModal(false);
    await axios
      .post(
        `device/orgs/${orgId}/devices/v3`,
        { devices: addRequestedDevices },
        Utils.requestHeader()
      )
      .then((response) => {
        const resultData = response.data;
        if (resultData) {
          const responseMeta = resultData.meta;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            setInProcessFields([]);
            setSelectedDevices([]);
            navigate(`/devices/listing.html?orgId=${orgDetails?.orgId}`);
          } else {
            setInProcessFields([]);
            setShowSetupErrorMessage(true);
          }
        } else {
          setInProcessFields([]);
          setShowSetupErrorMessage(true);
        }
      })
      .catch((error) => {
        // Todo: Delete later
        console.log('error comes in catch', error);
        setInProcessFields([]);
        setRenameId(0);
        setShowSetupErrorMessage(true);
      });
  };

  const reclaimDiscoveredDevices = async (reclaimRequestedDevices) => {
    setInProcessFields([`${reclaimRequestedDevices[0].deviceId}-options`]);
    setShowDeviceReclaimModal(false);
    await axios
      .put(
        `device/orgs/${orgId}/devices`,
        { devices: reclaimRequestedDevices },
        Utils.requestHeader()
      )
      .then((response) => {
        const resultData = response.data;
        setRenameId(0);
        if (resultData) {
          const responseMeta = resultData.meta;
          if (
            responseMeta &&
            (responseMeta.code === 200 || responseMeta.code === '200')
          ) {
            setInProcessFields([]);
            setRequestedReclaimDevices(null);
            navigate(`/devices/listing.html?orgId=${orgDetails?.orgId}`);
          } else {
            setInProcessFields([]);
            setRequestedReclaimDevices(null);
            setShowSetupErrorMessage(true);
          }
        } else {
          setInProcessFields([]);
          setRequestedReclaimDevices(null);
          setShowSetupErrorMessage(true);
        }
      })
      .catch((error) => {
        // Todo: Delete later
        console.log('error comes in catch', error);
        setInProcessFields([]);
        setRenameId(0);
        setShowSetupErrorMessage(true);
      });
  };

  const onChangeSearchText = (e) => {
    setSearchText(e?.target?.value);
  };

  const onSetLocation = (device, location) => {
    const obj = {
      deviceId: device.deviceId,
      locationId: location.locationId,
    };
    if (device.deviceType !== DeviceTypeEnum.IPCam.toLowerCase()) {
      const defaultArea = location?.areas.find((area) => area?.isDefault);
      obj.areaId = defaultArea?.areaId;
    }
    setInProcessFields([`${device.deviceId}-location`]);
    updateDiscoveredDevices([obj]);
  };
  const onSetArea = (device, area) => {
    const obj = {
      deviceId: device.deviceId,
      locationId: area.locationId,
      areaId: area.areaId,
    };
    setInProcessFields([`${device.deviceId}-area`]);
    updateDiscoveredDevices([obj]);
  };

  const onSetDeviceName = (device) => {
    const obj = {
      deviceId: device.deviceId,
      deviceName: document.getElementById(device.deviceId).value,
    };
    setInProcessFields([`${device.deviceId}-name`]);
    updateDiscoveredDevices([obj]);
  };

  const onChangeCheckBox = (e, device) => {
    const { checked } = e.target;
    let deviceList = JSON.parse(JSON.stringify(selectedDevices));
    if (checked) {
      deviceList.push(device?.deviceId);
    } else {
      const index = deviceList.indexOf(device?.deviceId, 0);
      if (index > -1) {
        deviceList.splice(index, 1);
      }
    }
    setSelectedDevices(deviceList);
  };
  const onSelectAllDevices = () => {
    const deviceList = [];
    filteredDevices.forEach((device) => {
      if (
        device.connectionStatus !==
          constants.DEVICES_OFFLINE_CONNECTION_STATUS &&
        device.status === 'ENTER'
      ) {
        deviceList.push(device.deviceId);
      }
    });
    setSelectedDevices(deviceList);
  };
  const onUnselectAllDevices = () => {
    setSelectedDevices([]);
  };

  const onCompleteBulkSetUp = (data, operation) => {
    const list = [];
    data?.forEach((device) => {
      list.push(`${device.deviceId}-location`);
      if (
        device.deviceType === DeviceTypeEnum.IPCam.toLowerCase() &&
        device.areaId
      ) {
        list.push(`${device.deviceId}-area`);
      }
      if (operation === 'add') {
        list.push(`${device.deviceId}-options`);
      }
    });
    setInProcessFields(list);
    if (operation === 'add') {
      updateDiscoveredDevices(data, true);
    } else {
      updateDiscoveredDevices(data);
    }
  };
  const getTooltipData = (device) => {
    if (
      device.deviceType === DeviceTypeEnum.IPCam.toLowerCase() &&
      (!device.areaName || !device.locationName)
    ) {
      return constants.DISCOVERED_DEVICES_SELECT_LOCATION_AREA_TOOLTIP;
    } else if (
      device.deviceType !== DeviceTypeEnum.IPCam.toLowerCase() &&
      !device.locationName
    ) {
      return constants.DISCOVERED_DEVICES_SELECT_LOCATION_NVR_TOOLTIP;
    } else if (
      device.connectionStatus === constants.DEVICES_OFFLINE_CONNECTION_STATUS
    ) {
      return constants.DISCOVERED_DEVICES_OFFLINE_DEVICE_TOOLTIP;
    }
  };

  const columnsData = useMemo(
    () => [
      {
        Header: '',
        accessor: 'deviceChecked',
        disableSortBy: true,
        Cell: ({ row }) => {
          let device = row.original;
          return (
            <div className="viewCheckbox">
              <input
                id={`checkbox-${device.deviceId}`}
                type="checkbox"
                checked={selectedDevices.includes(device?.deviceId)}
                disabled={
                  device.connectionStatus ===
                    constants.DEVICES_OFFLINE_CONNECTION_STATUS ||
                  device.status === 'RECLAIM'
                }
                className={
                  device.connectionStatus ===
                    constants.DEVICES_OFFLINE_CONNECTION_STATUS ||
                  device.status === 'RECLAIM'
                    ? 'disabled'
                    : ''
                }
                onChange={(e) => onChangeCheckBox(e, device)}
              />
            </div>
          );
        },
      },
      {
        Header: 'Name',
        accessor: 'deviceName',
        disableSortBy: true,
        sortType: (sortPrev, sortAfter) => {
          if (
            sortPrev.values['deviceName']?.toLowerCase() >
            sortAfter.values['deviceName']?.toLowerCase()
          ) {
            return -1;
          } else if (
            sortPrev.values['deviceName']?.toLowerCase() <
            sortAfter.values['deviceName']?.toLowerCase()
          ) {
            return 1;
          } else {
            return 0;
          }
        },
        Cell: ({ row }) => {
          let device = row.original;
          return renameId !== device.deviceId ? (
            <div
              className="location-area-name"
              onClick={() => {
                if (renameId !== device.deviceId) {
                  setRenameId(device.deviceId);
                }
              }}
            >
              {device.deviceName ? device.deviceName : `<${device.serialNo}>`}
            </div>
          ) : inProcessFields.includes(`${device.deviceId}-name`) ? (
            <div>
              <SiteSpinner
                height="24px"
                width="24px"
                backgroundColor={getComputedStyle(
                  document.documentElement
                ).getPropertyValue('--primary_40')}
              />
            </div>
          ) : (
            <input
              id={device.deviceId}
              type="text"
              placeholder={constants.DISCOVERED_DEVICES_NAME_PLACEHOLDER}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onSetDeviceName(device);
                }
                if (e.key === 'Escape') {
                  setRenameId(0);
                  setInProcessFields([]);
                }
              }}
              defaultValue={
                device.deviceName ? device.deviceName : device.serialNo
              }
              onBlur={() => setRenameId(0)}
              autoFocus={true}
              maxLength={40}
            />
          );
        },
      },
      {
        Header: 'Model Number',
        accessor: 'modelNumber',
        disableSortBy: true,
        sortType: 'basic',
      },
      {
        Header: 'IP',
        accessor: 'ipAddress',
        disableSortBy: true,
        sortType: 'basic',
      },
      {
        Header: 'Date Added',
        accessor: 'createdDate',
        disableSortBy: true,
        sortType: 'basic',
        Cell: ({ row }) => {
          let device = row.original;
          const date = new Date(device?.createdDate);
          const month = date.getMonth() + 1;
          var formatted =
            ('0' + date.getDate()).slice(-2) +
            '/' +
            ('0' + month).slice(-2) +
            '/' +
            date.getFullYear();
          return <>{formatted}</>;
        },
      },
      {
        Header: 'Status',
        accessor: 'connectionStatus',
        disableSortBy: true,
        sortType: 'basic',
        Cell: ({ row }) => {
          let device = row.original;
          return (
            <div className="text-capitalize">
              {device?.connectionStatus ===
              constants.DEVICES_OFFLINE_CONNECTION_STATUS ? (
                <div>
                  <FaCircle className="status-indicator status-offline" />
                  {device?.connectionStatus}
                </div>
              ) : device?.connectionStatus ===
                constants.DEVICES_ONLINE_CONNECTION_STATUS ? (
                <div>
                  <FaCircle className="status-indicator status-online" />
                  {device?.connectionStatus}
                </div>
              ) : null}
            </div>
          );
        },
      },
      {
        Header: 'Location',
        accessor: 'locationName',
        disableSortBy: true,
        sortType: 'basic',
        Cell: ({ row }) => {
          let device = row.original;
          return (
            <>
              {inProcessFields.includes(`${device.deviceId}-location`) ? (
                <div>
                  <SiteSpinner
                    height="24px"
                    width="24px"
                    backgroundColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--primary_40')}
                  />
                </div>
              ) : (
                <NavDropdown
                  className="table-action-dropdown"
                  title={
                    device.locationName ? (
                      <div className="location-area-name">
                        {device.locationName}
                      </div>
                    ) : (
                      <div className="set-location-area">
                        {constants.DISCOVERED_DEVICES_SET_LOCATION}
                      </div>
                    )
                  }
                >
                  {locationsData.map((location) => (
                    <NavDropdown.Item
                      onClick={() => onSetLocation(device, location)}
                    >
                      <RadioGroup
                        aria-labelledby="base-details-radio-buttons"
                        name="controlled-radio-buttons-group"
                      >
                        <Radio
                          sx={{
                            color: getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--greyscale_80'),
                          }}
                          checked={device.locationId === location.locationId}
                          onChange={(e) => {
                            setSelectedLocation(location);
                          }}
                          name="radio-buttons"
                          inputProps={{ 'aria-label': 'A' }}
                        />
                      </RadioGroup>
                      <Tooltip
                        placement="right-start"
                        arrow
                        title={location.locationName}
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [0, 0],
                                },
                              },
                            ],
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                fontSize: '12px',
                                fontWeight: '500',
                                lineHeight: '16px',
                                color: `var(--brand_white)`,
                                background: `var(--primary_08)`,
                                padding: '10px',
                                fontFamily: 'Noto Sans',
                                borderRadius: '8px',
                              },
                              [`& .${tooltipClasses.arrow}`]: {
                                color: `var(--primary_08)`,
                              },
                            },
                          },
                        }}
                      >
                        <div className="location-area-dropdown-name">
                          {location.locationName}
                        </div>
                      </Tooltip>
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              )}
            </>
          );
        },
      },
      {
        Header: 'Area',
        accessor: 'areaName',
        disableSortBy: true,
        sortType: 'basic',
        Cell: ({ row }) => {
          let device = row.original;
          return (
            <>
              {inProcessFields.includes(`${device.deviceId}-area`) ? (
                <div>
                  <SiteSpinner
                    height="24px"
                    width="24px"
                    backgroundColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--primary_40')}
                  />
                </div>
              ) : !device.locationName &&
                device.deviceType === DeviceTypeEnum.IPCam.toLowerCase() ? (
                '-'
              ) : device.deviceType === DeviceTypeEnum.IPCam.toLowerCase() ? (
                <NavDropdown
                  className="table-action-dropdown"
                  title={
                    device.areaName ? (
                      <div className="location-area-name">
                        {device.areaName}
                      </div>
                    ) : (
                      <div className="set-location-area">
                        {constants.DISCOVERED_DEVICES_SET_AREA}
                      </div>
                    )
                  }
                >
                  {device?.areas?.map((area) => (
                    <NavDropdown.Item onClick={() => onSetArea(device, area)}>
                      <RadioGroup
                        aria-labelledby="base-details-radio-buttons"
                        name="controlled-radio-buttons-group"
                      >
                        <Radio
                          sx={{
                            color: getComputedStyle(
                              document.documentElement
                            ).getPropertyValue('--greyscale_80'),
                          }}
                          checked={device.areaId === area.areaId}
                          onChange={(e) => {
                            setSelectedArea(area);
                          }}
                          name="radio-buttons"
                          inputProps={{ 'aria-label': 'A' }}
                        />
                      </RadioGroup>
                      <Tooltip
                        placement="right-start"
                        arrow
                        title={area.areaName}
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [0, 0],
                                },
                              },
                            ],
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                fontSize: '12px',
                                fontWeight: '500',
                                lineHeight: '16px',
                                color: `var(--brand_white)`,
                                background: `var(--primary_08)`,
                                padding: '10px',
                                fontFamily: 'Noto Sans',
                                borderRadius: '8px',
                              },
                              [`& .${tooltipClasses.arrow}`]: {
                                color: `var(--primary_08)`,
                              },
                            },
                          },
                        }}
                      >
                        <div className="location-area-dropdown-name">
                          {area.areaName}
                        </div>
                      </Tooltip>
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              ) : null}
            </>
          );
        },
      },
      {
        Header: '',
        accessor: 'deviceOptions',
        disableSortBy: true,
        Cell: ({ row }) => {
          let device = row.original;
          return (
            <div className="option-container">
              {inProcessFields.includes(`${device.deviceId}-options`) ? (
                <div>
                  <SiteSpinner
                    height="24px"
                    width="24px"
                    backgroundColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--primary_40')}
                  />
                </div>
              ) : (
                <Tooltip
                  placement={'left'}
                  arrow
                  title={getTooltipData(device)}
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -5],
                          },
                        },
                      ],
                      sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '16px',
                          color: `var(--brand_white)`,
                          background: `var(--primary_08)`,
                          padding: '10px',
                          fontFamily: 'Noto Sans',
                          borderRadius: '8px',
                          width: '166px',
                        },
                        [`& .${tooltipClasses.arrow}`]: {
                          color: `var(--primary_08)`,
                        },
                      },
                    },
                  }}
                >
                  <div>
                    <PrimaryButton
                      className={`device-btn-list`}
                      type="button"
                      width={device.status === 'ENTER' ? '56px' : '79px'}
                      backgroundColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--brand_white')}
                      color={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--primary_40')}
                      height="32px"
                      disabled={
                        (device.deviceType ===
                          DeviceTypeEnum.IPCam.toLowerCase() &&
                          (!device.locationName || !device.areaName)) ||
                        device.connectionStatus ===
                          constants.DEVICES_OFFLINE_CONNECTION_STATUS ||
                        (device.deviceType !==
                          DeviceTypeEnum.IPCam.toLowerCase() &&
                          !device.locationName) ||
                        !getCustomerOrgPolicies()?.install_device
                      }
                      disabledBackgroundColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--greyscale_96')}
                      disabledColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--greyscale_56')}
                      disabledBorderColor={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue('--greyscale_72')}
                      fontSize="12px"
                      lineHeight="16px"
                      fontWeight="600"
                      borderRadius="8px"
                      onClick={() => {
                        if (device.status !== 'ENTER') {
                          setShowDeviceReclaimModal(true);
                          setRequestedReclaimDevices([device]);
                        } else {
                          addDiscoveredDevices([device]);
                        }
                      }}
                    >
                      {device.status === 'ENTER' ? (
                        <>{constants.DISCOVERED_DEVICES_ADD}</>
                      ) : (
                        <>{constants.DISCOVERED_DEVICES_RECLAIM}</>
                      )}
                    </PrimaryButton>
                  </div>
                </Tooltip>
              )}

              <NavDropdown
                className="table-action-dropdown"
                title={
                  <div>
                    <img src={dotsVertical} alt="" />
                  </div>
                }
              >
                <NavDropdown.Item
                  onClick={() => {
                    setShowDeviceDeleteModal(true);
                    setRequestedDeleteDevices([device.deviceId]);
                  }}
                  disabled={!getCustomerOrgPolicies()?.delete_device}
                  className={
                    !getCustomerOrgPolicies()?.delete_device ? 'disabled' : ''
                  }
                >
                  {constants.DISCOVERED_DEVICES_REMOVE_DEVICE}
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          );
        },
      },
    ],
    [
      renameId,
      selectedDevices,
      inProcessFields,
      filteredDevices,
      searchText,
      selectedLocation,
      selectedArea,
    ]
  );

  return (
    <div className="App">
      <Header breadcrumbData={breadList} />

      <div className="discovered-devices">
        <PageWrapper>
          <div className="page-header mb-4">
            <Container className="container-top-offset" lg={8}>
              <Col md={6} lg={6} xl={6} xs={12}>
                <BreadcrumbList list={breadList} />
              </Col>
              <Row>
                <Col
                  md={6}
                  lg={6}
                  xl={6}
                  xs={12}
                  className="page-title text-start"
                >
                  {constants.DISCOVERED_DEVICES_SETUP_BUTTON}
                </Col>

                <Col md={6} lg={6} xl={6} xs={12} className="end-align">
                  <div className="search-container">
                    <IoIosSearch className="search-icon" />
                    <input
                      id="deviceSearch"
                      type={'text'}
                      placeholder={
                        constants.DISCOVERED_DEVICES_SEARCH_PLACEHOLDER
                      }
                      className="search-input"
                      // onChange={(e) => onChangeSearchText(e)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          onChangeSearchText(e);
                        }
                      }}
                    />
                    {document?.getElementById('deviceSearch')?.value ? (
                      <RxCrossCircled
                        className="search-close"
                        onClick={() => {
                          setSearchText('');
                          document.getElementById('deviceSearch').value = '';
                        }}
                      />
                    ) : null}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="list-block">
            <Container className="h-100">
              {showLocationErrorMessage ? (
                <div className="error-container">
                  <div className="message-box">
                    <HiOutlineInformationCircle size={20} />
                    {constants.DISCOVERED_DEVICES_ERROR_MESSAGE}
                  </div>
                  <RxCross2
                    size={20}
                    role="button"
                    onClick={() => setShowLocationErrorMessage(false)}
                  />
                </div>
              ) : null}
              {showSetupErrorMessage ? (
                <div className="error-container">
                  <div className="message-box">
                    <HiOutlineInformationCircle size={20} />
                    {constants.DISCOVERED_DEVICES_SETUP_ERROR_MESSAGE}
                  </div>
                  <RxCross2
                    size={20}
                    role="button"
                    onClick={() => setShowSetupErrorMessage(false)}
                  />
                </div>
              ) : null}
              <div className="table-scroll">
                {isLoading ? (
                  <SiteSpinner
                    height="100px"
                    width="100px"
                    backgroundColor={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue('--primary_40')}
                  />
                ) : !isLoading && filteredDevices?.length ? (
                  <>
                    <DiscoveredDevicesTable
                      columns={columnsData}
                      data={filteredDevices}
                      setShowDevcieSetupModal={setShowDeviceSetupModal}
                      setShowDeviceDeleteModal={setShowDeviceDeleteModal}
                      setRenameId={setRenameId}
                      renameId={renameId}
                      selectedDevices={selectedDevices}
                      setSortDetails={setSortDetails}
                      onSelectAllDevices={onSelectAllDevices}
                      onUnselectAllDevices={onUnselectAllDevices}
                      sortDetails={sortDetails}
                      addDiscoveredDevices={addDiscoveredDevices}
                    />
                    {pageDetails.totalPages > pageDetails.pageNumber + 1 ? (
                      <div
                        className={
                          isLoadMorecalled ? 'load-more-disabled' : 'load-more'
                        }
                        disabled={isLoadMorecalled}
                      >
                        <div
                          onClick={() => {
                            if (isLoadMorecalled) {
                              return;
                            }
                            setPageNo(pageNo + 1);
                          }}
                        >
                          {constants.DISCOVERED_DEVICES_LOAD_MORE}
                          <FaArrowDown className="mx-2" />
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : (
                  <div className="no-cameras-container">
                    <div className="no-cameras-icon-container">
                      <FiVideoOff
                        stroke={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue('--greyscale_08')}
                        size={32}
                      />
                    </div>
                    <div className="no-cameras-notice">
                      {constants.DISCOVERED_DEVICES_NO_DEVICE_MESSAGE}
                    </div>
                  </div>
                )}
              </div>
              <SiteModal
                modalHeader={true}
                key={'device-setup-modal'}
                classes="device-setup-modal"
                wrapperClass="device-setup-modal"
                showModal={showDeviceSetupModal}
                hideModal={setShowDeviceSetupModal}
              >
                <DiscoveredDevicesSetup
                  setShowDevcieSetupModal={setShowDeviceSetupModal}
                  selectedDevices={selectedDevices}
                  filteredDevices={filteredDevices}
                  onCompleteBulkSetUp={onCompleteBulkSetUp}
                />
              </SiteModal>
              <SiteModal
                key={'delete-view-modal'}
                classes="save-view-modal"
                wrapperClass="save-view-modal"
                modalTitle="Remove Selected Devices"
                showModal={showDeviceDeleteModal}
                hideModal={() => setShowDeviceDeleteModal(false)}
              >
                <DiscoveredDevciesDelete
                  cancelDeleting={() => {
                    setShowDeviceDeleteModal(false);
                    setRequestedDeleteDevices(null);
                  }}
                  deleteDiscoveredDevices={deleteDiscoveredDevices}
                />
              </SiteModal>
              <SiteModal
                modalHeader={true}
                key={'device-setup-modal1'}
                classes="device-setup-modal"
                wrapperClass="device-setup-modal"
                showModal={showDeviceReclaimModal}
                hideModal={() => setShowDeviceReclaimModal(false)}
              >
                <DiscoveredDevicesReclaim
                  cancelReclaim={() => {
                    setShowDeviceReclaimModal(false);
                    setRequestedReclaimDevices(null);
                  }}
                  reclaimDiscoveredDevices={reclaimDiscoveredDevices}
                  requestedReclaimDevices={requestedReclaimDevices}
                  addDiscoveredDevices={addDiscoveredDevices}
                />
              </SiteModal>
            </Container>
          </div>
        </PageWrapper>
      </div>
    </div>
  );
};

export default DiscoveredDevices;
